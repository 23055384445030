// Mui Components
import { Box, Typography } from "@mui/material"

//Our Components
import InfinityLoader from "./InfinityLoader"

export default function ButtonInfinityLoader(props) {
  //PROPS
  let { buttonTitle } = props
  return (
    <>
      <Box sx={{ width: "100%", position: "absolute" }}>
        <InfinityLoader
          style={{
            zIndex: 5,
            height: "34px",
          }}
        />
      </Box>
      <Typography sx={{ opacity: 0 }}>{buttonTitle}</Typography>
    </>
  )
}
