import React, {
  useEffect,
  useLayoutEffect,
  useState,
  useContext,
  Suspense,
  lazy,
} from 'react';
import { useHistory, useLocation } from 'react-router';
import { Route } from 'react-router-dom';

//Assets
import whiteLogo from '../../../assets/images/WhiteLogoWithText.svg';

//Muicons
import Close from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import AttachMoney from '@mui/icons-material/AttachMoney';
import BeachAccess from '@mui/icons-material/BeachAccess';
import StarBorder from '@mui/icons-material/StarBorder';
import Home from '@mui/icons-material/Home';

//MuiComponents
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Drawer from '@mui/material/Drawer';
import Paper from '@mui/material/Paper';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';

//Services
// import enrollmentService from "../../../services/enrollment-service";
// import attachmentService from "../../../services/attachment-service";
import memberService from '../../../services/member-service';
import authService from '../../../services/auth-service';
import attachmentService from '../../../services/attachment-service';
import userService from '../../../services/user-service';
import enrollmentService from '../../../services/enrollment-service';
import walletService from '../../../services/wallet-service';
import paymentsService from '../../../services/payments-service';
import optionService from '../../../services/optionService';

//Our Components
import { ColorModeContext } from '../../../ExternalApp';
import PoweredBy from '../external-enrollments/PoweredBy';
import InfinityLoader from '../../UH-loading-animation/InfinityLoader';

import PortalHome from './portal-home/PortalHome';
import DrawerMenuItem from './portal-home/DrawerMenuItem';
import PortalSupport from './portal-support/PortalSupport';
import PortalSettings from './portal-settings/PortalSettings';
import PortalPayment from './portal-payment/PortalPayment';
import PortalBenefits from './portal-benefits/PortalBenefits';

// const DrawerMenuItem = lazy(() => import('./DrawerMenuItem'));

// const DashHome = React.lazy(() => import('../../external-dashboard/dash-home/DashHome'));
// const DashPersonal = React.lazy(() => import('../../external-dashboard/dash-personal/DashPersonal'));
// // const DashBenefits = React.lazy(() => import('../../external-dashboard/dash-benefits/DashBenefits'));
// const DashWallet = React.lazy(() => import('../../external-dashboard/dash-wallet/DashWallet'));
// const DashDocuments = React.lazy(() => import('../../external-dashboard/dash-documents/DashDocuments'));
// const DashClaims = React.lazy(() => import('../../external-dashboard/dash-claims/DashClaims'));
// const DashSettings = React.lazy(() => import('../../external-dashboard/dash-settings/DashSettings'));
// const DashHelp = React.lazy(() => import('../../external-dashboard/dash-help/DashHelp'));

export default function MemberPortal(props) {
  const history = useHistory();
  let location = useLocation();
  let route = location.pathname;
  let colorMode = useContext(ColorModeContext);
  const componentStyles = {
    imageContainer: { display: 'flex', justifyContent: 'center' },
    imageStyles: {
      maxHeight: '100px',
      maxWidth: '100%',
      objectFit: 'contain',
    },
    infoContainer: {
      display: 'flex',
      flexDirection: 'column',
      width: '100vw',
      margin: '0 auto',
      overflowY: 'auto',
      height: '100vh ',
      paddingBottom: '56px',
    },
    loginButton: {
      height: 50,
      borderRadius: 100,
      width: '152px',
      fontWeight: '700!important',
      fontSize: '15px',
      margin: '20px auto',
    },
    //'What you will need' section
    stepsContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      maxWidth: '270px',
      margin: '0px auto',
    },
    stepsTitle: {
      textAlign: 'center',
    },
    iconListContainer: {
      display: 'flex',
      flexDirection: 'column',
      margin: '10px auto',
    },
    iconRow: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      margin: '6px 0px',
      // maxWidth: "280px",
    },
    iconText: {
      fontFamily: 'Archivo',
      fontSize: '14px',
      fontWeight: 400,
      color: 'text.primary',
      lineHeight: '20px',
    },
    icon: { height: '22px', marginRight: '30px', color: 'text.secondary' },
    //Large Blue Button
    largeButtonContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      width: '100%',
    },
    //Secure Message
    secureMessageContainer: {
      display: 'flex',
      flexDirecton: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      margin: '25px auto 10px',
    },
    secureMessageIcon: {
      height: '24px',
      color: 'primary.secureDataMsg',
      marginRight: '6px',
    },
    secureMessageText: {
      color: 'primary.secureDataMsg',
      fontSize: '12px',
      lineHeight: '32px',
      letterSpacing: '1px',
    },
    //Text Description/Paragraph
    textDescription: {
      textAlign: 'center',
      margin: '20px auto',
      fontFamily: 'Archivo',
      fontWeight: 500,
      color: 'text.secondary',
      fontSize: '14px',
    },
    mobileStepper: { padding: '0px', backgroundColor: 'transparent' },
  };

  const [bankAccountTypes, setBankAccountTypes] = useState([]);
  const getBankAccountTypes = async () => {
    let _types = await optionService.getBankAccountTypes();
    setBankAccountTypes(_types);
  };
  const [paymentScheduleTypes, setPaymentScheduleTypes] = useState([]);
  const getPaymentScheduleTypes = async () => {
    let _types = await optionService.getPaymentFrequencies();
    setPaymentScheduleTypes(_types);
  };

  const [isAuthenticated, setIsAuthenticated] = useState();
  useEffect(() => {
    let _isAuthenticated = props.checkAuthentication();
    setIsAuthenticated(_isAuthenticated);
  }, []);
  //potentially we can do one call to get all the data we need for the dashboard, so
  //actively covered benefits | some personal info | payment info | claims and more

  const [thisUser, setThisUser] = useState({});
  const getThisUser = async () => {
    try {
      let _thisUser = await userService.getMeExternal();
      // let _thisUser = await userService.getMe();
      if (_thisUser) setThisUser(_thisUser);
    } catch (error) {
      console.log('error getting user', error);
    }
  };

  const [thisMember, setThisMember] = useState({});
  const getThisMember = async () => {
    try {
      let _thisMember = await memberService.getMe();
      if (_thisMember) setThisMember(_thisMember);
    } catch (error) {
      console.log('error getting member', error);
    }
  };
  const [thisMemberPhone, setThisMemberPhone] = useState({});
  const getThisMemberPhone = async () => {
    try {
      let _thisMemberPhone = await memberService.getPhones();
      if (_thisMemberPhone) setThisMemberPhone(_thisMemberPhone);
    } catch (error) {
      console.log('error getting member phone', error);
    }
  };

  const [thisMembersDocuments, setThisMembersDocuments] = useState([]);
  const getThisMembersDocuments = async () => {
    try {
      let _thisMembersDocuments = await enrollmentService.getAttachments();
      if (_thisMembersDocuments) setThisMembersDocuments(_thisMembersDocuments);
    } catch (error) {
      console.log('error getting member documents', error);
    }
  };

  const [activeCoverage, setActiveCoverage] = useState({});
  const getActiveCoverage = async () => {
    try {
      let _activeCoverage = await memberService.getActiveCoverage();
      if (_activeCoverage) setActiveCoverage(_activeCoverage);
    } catch (error) {
      console.log('error getting active coverage', error);
    }
  };

  const [enrollments, setEnrollments] = useState([]);
  const getEnrollments = async () => {
    try {
      let _enrollments = await enrollmentService.getMe();
      if (_enrollments) setEnrollments(_enrollments);
    } catch (error) {
      console.log('error getting enrollments', error);
    }
  };

  const [mostRecentEnrollment, setMostRecentEnrollment] = useState(null);
  const [mostRecentEnrollmentCommLabels, setMostRecentEnrollmentCommLabels] =
    useState([]);

  const getEnrollmentCommunicationLabels = async (enrollmentId) => {
    // let _labels = await enrollmentService.getEnrollmentCommunicationLabels(enrollmentId);
    let _labels = await enrollmentService.getCommunicationLabelsByMemberId(
      thisMember.id
    );
    if (_labels) {
      setMostRecentEnrollmentCommLabels(_labels);
    }
  };
  useEffect(() => {
    if (enrollments?.length > 0) {
      for (let i = 0; i < enrollments.length; i++) {
        if (enrollments[i].isEnrolled && enrollments[i].isEnrollmentOpen) {
          setMostRecentEnrollment(enrollments[i]);
          break;
        }
      }
    }
  }, [thisMember, enrollments]);

  useEffect(() => {
    if (thisMember?.id > 0) {
      getEnrollmentCommunicationLabels(thisMember.id);
    }
  }, [thisMember?.id]);

  const [allPayments, setAllPayments] = useState([]);
  const getAllPayments = async () => {
    try {
      let _allPayments = await paymentsService.getAllPaymentsByMemberId(
        thisMember.id
      );
      // console.log("ALL PAYMENTS", _allPayments);
      setAllPayments(_allPayments);
    } catch (error) {
      console.error('error getting all payments', error);
    }
  };

  //get any one-time payments that have been scheduled
  const [oneTimePayments, setOneTimePayments] = useState([]);
  const getOneTimePayments = async () => {
    try {
      let _oneTimePayments = await paymentsService.getOneTimePaymentsByMemberId(
        thisMember.id
      );

      setOneTimePayments(_oneTimePayments);
    } catch (error) {
      console.error('error getting one-time payments', error);
    }
  };

  const [payment, setPayment] = useState({});
  const getPayment = async () => {
    let _pay = await walletService.getAllPaymentSchedules();
    // console.log('payment', _pay);
    setPayment(_pay);
  };

  const [bank, setBank] = useState({});
  const [paySchedule, setPaySchedule] = useState({});

  //get bank - if one exists, does not mean a schedule automatically exists so check for schedule
  const getBank = async () => {
    let _bank;
    let _schedule;
    try {
      _bank = await walletService.getBankAccount();
    } catch (error) {
      console.error('error getting primary bank account', error);
    }
    //if a bank exists
    if (_bank?.id) {
      try {
        _schedule = await walletService.getEnrollmentScheduledPayment(
          props?.enrollmentId
        );
      } catch (error) {
        console.error('error getting pay schedule', error);
      }
    }

    setPaySchedule(_schedule);

    setBank(_bank);
  };

  console.log('member', thisMember);
  const [loading, setLoading] = useState(false);

  const [dashPageState, setDashPageState] = useState({
    home: true,
    payment: false,
    benefits: false,
    claims: false,
  });
  const handleBottomNavChange = (event, newValue) => {
    // setDashPageState(newValue);
    history.push('/dashboard/' + newValue);
  };

  const [pageSummaryObj, setPageSummaryObj] = useState({
    thisMember: {},
    thisMemberBenefits: {},
    thisMemberPayment: {},
  });

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  // useEffect(() => {
  //    setLoading(true);
  //    setTimeout(() => {
  //       setLoading(false);
  //    }, 2000);
  //    // return () => {
  //    //    setLoading(false);
  //    // }
  // }, [anchorEl]);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const goTo = (page) => {
    setDashPageState({
      // ...dashPageState,
      [page]: true,
    });
    handleClose();
  };

  const swapTheme = () => {
    colorMode.ToggleColorMode();
  };

  // const [collapse, setCollapse] = useState(false);
  const [enrollmentAlert, setEnrollmentAlert] = useState(true);
  const onEnrollmentAlertClose = () => {
    setEnrollmentAlert(false);
  };

  const handleLogoutClick = async () => {
    // console.log('logout clicked');
    await authService.signout();
    history.push('/login');
  };

  useEffect(() => {
    getBankAccountTypes();
    getPaymentScheduleTypes();
    getThisMember();
    getThisMemberPhone();
    getEnrollments();
    getPayment();
    getBank();
    getThisUser();
  }, []);
  useEffect(() => {
    if (thisMember.id) {
      getThisMembersDocuments();
      // getActiveCoverage();
      // getAllPayments();
      // getOneTimePayments();
    }
  }, [thisMember]);

  //   console.log('thisMember', thisMember);
  // console.log('thisMemberPhone', thisMemberPhone);
  // console.log('activeCoverage', activeCoverage);
  //   console.log('enrollments', enrollments);
  //   console.log('allPayments', allPayments);
  //   console.log('oneTimePayments', oneTimePayments);
  // console.log('Docs', thisMembersDocuments);

  return (
    <Box sx={componentStyles.infoContainer}>
      {!isAuthenticated && (
        <>
          <Typography sx={componentStyles.textDescription}>
            Not Authenticated, please login.
          </Typography>
          <Button
            variant="contained"
            sx={componentStyles.loginButton}
            onClick={() => history.push('/login')}
          >
            Login
          </Button>
        </>
      )}
      {isAuthenticated && (
        <>
          {loading && (
            <>
              <InfinityLoader
                style={{
                  position: 'absolute',
                  zIndex: 5,
                  width: '100%',
                  height: '100vh',
                  backgroundColor: '#00000050',
                  backdropFilter: 'blur(10px)',
                }}
              />
              <style>
                {`
                           div.lottie-div > svg {
                              height:55px!important;
                              position:absolute;
                              top:50%;
                           }
                        `}
              </style>
            </>
          )}
          <AppBar
            component={'nav'}
            sx={{
              display: 'flex',
              backgroundColor: 'primary.main',
              minHeight: 64,
              '& .MuiToolbar-root': {
                minHeight: 64,
              },
            }}
            elevation={2}
          >
            <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <IconButton
                size="large"
                edge="start"
                // sx={{ color: 'background.paper', mr: 2 }}
                aria-label="menu"
                onClick={handleMenu}
                // disableRipple={true}
                sx={{
                  color: 'background.paper',
                  ml: 0,
                  svg: { height: '35px', width: '35px' },
                  padding: '0px',
                  // transition: 'transform 0.2s ease-in-out',
                  // transform: collapse ? 'rotate(-180deg)' : 'rotate(0deg)',
                }}
              >
                <MenuIcon />
              </IconButton>

              <Button
                edge="end"
                size="large"
                // aria-label="account of current user"
                // aria-controls="menu-appbar"
                // aria-haspopup="true"
                sx={{
                  color: 'background.paper',
                  pr: 0,
                  // height: '35px', width: '35px' ,
                  // position: 'absolute', right: '0px',bottom:'-29px'
                }}
                disableRipple={true}
                onClick={swapTheme}
              >
                <img style={{ height: '25px' }} src={whiteLogo} />
              </Button>
            </Toolbar>
          </AppBar>
          <Drawer open={open} onClose={handleClose} anchor={'left'}>
            <AppBar
              position="static"
              sx={{ display: 'flex', backgroundColor: 'primary.main' }}
            >
              <Toolbar
                sx={{ display: 'flex', justifyContent: 'space-between' }}
              >
                <IconButton
                  size="large"
                  edge="start"
                  color="inherit"
                  // aria-label="menu"
                  onClick={handleClose}
                  sx={{ color: 'background.paper', mr: 2 }}
                  disableRipple={true}
                >
                  <Close />
                </IconButton>

                <Box>
                  <Button
                    edge="end"
                    size="large"
                    // aria-label="account of current user"
                    // aria-controls="menu-appbar"
                    // aria-haspopup="true"
                    sx={{
                      color: 'background.paper',
                      pr: 0,
                      // height: '35px', width: '35px' ,
                      // position: 'absolute', right: '0px',bottom:'-29px'
                    }}
                    disableRipple={true}
                    onClick={swapTheme}
                  >
                    <img style={{ height: '25px' }} src={whiteLogo} />
                  </Button>
                </Box>
              </Toolbar>
            </AppBar>
            <Box
              className="flex-container"
              flex={1}
              overflow="auto"
              style={{
                flexWrap: 'wrap',
                minHeight: 'calc(100vh)',
                margin: 'auto',
                padding: '8px',
                width: '100vw',
                // maxWidth: '500px'
              }}
              sx={{
                bgcolor: 'background.default',
                color: 'text.primary',
              }}
            >
              <DrawerMenuItem NavigateClick={() => goTo('home')} text="Home" />
              <DrawerMenuItem
                NavigateClick={() => goTo('planInfo')}
                text="Plan Information"
              />
              <DrawerMenuItem
                NavigateClick={() => goTo('support')}
                text="Support"
              />
              <DrawerMenuItem
                NavigateClick={() => goTo('settings')}
                text="Settings"
              />
              <DrawerMenuItem NavigateClick={handleLogoutClick} text="Logout" />
            </Box>
          </Drawer>

          <Route
            path={['/dashboard/home', '/dashboard']}
            exact={true}
            render={() => (
              <>
                <PortalHome
                  isAuthenticated={isAuthenticated}
                  thisMember={thisMember}
                  getThisMember={getThisMember}
                  thisMemberPhone={thisMemberPhone}
                  enrollments={enrollments}
                  mostRecentEnrollment={mostRecentEnrollment}
                  mostRecentEnrollmentCommLabels={
                    mostRecentEnrollmentCommLabels
                  }
                  payment={payment}
                  thisMembersDocuments={thisMembersDocuments}
                />
              </>
            )}
          />
          <Route
            path={'/dashboard/benefits'}
            exact={true}
            render={() => (
              <>
                <PortalBenefits
                  enrollments={enrollments}
                  thisMember={thisMember}
                  payment={payment}
                  mostRecentEnrollment={mostRecentEnrollment}
                  mostRecentEnrollmentCommLabels={
                    mostRecentEnrollmentCommLabels
                  }
                />
              </>
            )}
          />
          <Route
            path={'/dashboard/payment'}
            render={() => (
              <>
                <PortalPayment
                  isAuthenticated={isAuthenticated}
                  thisMember={thisMember}
                  getThisMember={getThisMember}
                  thisMemberPhone={thisMemberPhone}
                  enrollments={enrollments}
                  mostRecentEnrollment={mostRecentEnrollment}
                  mostRecentEnrollmentCommLabels={
                    mostRecentEnrollmentCommLabels
                  }
                  payment={payment}
                  bank={bank}
                  bankAccountTypes={bankAccountTypes}
                  paymentScheduleTypes={paymentScheduleTypes}
                />
              </>
            )}
          />
          <Route
            path={'/dashboard/support'}
            render={() => (
              <>
                <PortalSupport isAuthenticated={isAuthenticated} />
              </>
            )}
          />
          <Route
            path={'/dashboard/settings'}
            render={() => (
              <>
                <PortalSettings
                  isAuthenticated={isAuthenticated}
                  thisMember={thisMember}
                  getThisMember={getThisMember}
                  thisUser={thisUser}
                />
              </>
            )}
          />

          {/* <PoweredBy
            containerStyle={{ paddingTop: '10px', marginBottom: '60px' }}
          /> */}

          <Paper
            sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }}
            elevation={3}
          >
            <BottomNavigation
              showLabels
              // value={dashPageState}
              onChange={handleBottomNavChange}
            >
              <BottomNavigationAction
                sx={{
                  color:
                    route.includes('home') || route == '/dashboard'
                      ? 'primary.main'
                      : 'text.primary',
                }}
                value={'home'}
                label="My Info"
                icon={<Home />}
              />
              <BottomNavigationAction
                sx={{
                  color: route.includes('payment')
                    ? 'primary.main'
                    : 'text.primary',
                }}
                value={'payment'}
                label="Payments"
                icon={<AttachMoney />}
              />
              <BottomNavigationAction
                sx={{
                  color: route.includes('benefits')
                    ? 'primary.main'
                    : 'text.primary',
                }}
                value={'benefits'}
                label="Benefits"
                icon={<StarBorder />}
              />
              <BottomNavigationAction
                sx={{
                  color: route.includes('claims')
                    ? 'primary.main'
                    : 'text.primary',
                }}
                value={'claims'}
                label="Claims"
                icon={<BeachAccess />}
              />
            </BottomNavigation>
          </Paper>

          {/* {!isAuthenticated && history.push('/login')} */}
        </>
      )}
    </Box>
  );
}
