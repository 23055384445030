import React, { useState, useEffect } from 'react';

//MuiIcons
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonIcon from '@mui/icons-material/RadioButtonUnchecked';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

//MuiComponents
import Collapse from '@mui/material/Collapse';
import Button from '@mui/material/Button';
import Slider from '@mui/material/Slider';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Divider, { dividerClasses } from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import { Tooltip } from '@mui/material';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Box from '@mui/material/Box';

//Our icons
import CustomCheckIcon from '../../../assets/images/CustomCheckIcon.svg';
import { Typography } from '@mui/material';

//Our Services

import optionService from '../../../services/optionService';

import enrollmentPlanStyles from '../../app-external/external-enrollments/enrollment-plans/enrollmentPlanStyles';

export default function CalculatorMembership(props) {
  //Component Styling
  const componentStyles = {
    membershipSelectInput: {
      width: '48%',
    },
    radioButtonItem: {
      margin: '20px 0px 0px',
      padding: '0px',
      paddingRight: '10px',
      textAlign: 'left',
      display: 'flex',
      alignItems: 'center',
      width: '100%',
    },
    radioButtonSingle: {
      paddingBottom: '8px',
      marginLeft: '-10px',
      marginRight: '8px',
    },
    sliderDisplayContainer: {
      padding: '0px 0px',
      margin: '0px auto',
      display: 'flex',
      justifyContent: 'space-between',
      alignContent: 'center',
      width: '96%',
    },
    sliderLowAmount: {
      flex: 1,
      fontFamily: 'Archivo',
      fontWeight: 500,
      fontSize: '12px',
      position: 'relative',
      alignSelf: 'center',
      top: '-2px',
      textAlign: 'center',
    },
    sliderHighAmount: {
      flex: 1,
      fontFamily: 'Archivo',
      fontWeight: 500,
      fontSize: '12px',
      position: 'relative',
      alignSelf: 'center',
      textAlign: 'center',
      top: '-2px',
    },
  };

  /////USD formatting
  let UsdFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
  });
  let UsdFormatterDec = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
  });

  const [expanded, setExpanded] = useState(true);
  const handleExpandClick = () => {
    setExpanded((prev) => !prev);
  };
  // useEffect(() => {
  //     if (props.scrollClose) {
  //         setExpanded(false)
  //     }
  // },[props.scrollClose])
  useEffect(() => {
    if (!expanded) {
      // document.getElementById('membership-calc-wrapper').scrollIntoView()
    }
  }, [expanded]);
  let expandIcon = expanded ? (
    <RemoveCircleIcon
      fontSize="large"
      sx={{ position: 'absolute', right: '14%', top: '20%', color: '#195ffb' }}
    />
  ) : (
    <AddCircleIcon
      fontSize="large"
      sx={{ position: 'absolute', right: '14%', top: '20%', color: '#195ffb' }}
    />
  );

  // INCREMENTAL DISABILITY

  //Data from Membership plan from props:

  let results = props.results;
  let membershipPlan = results?.membershipPlan;

  let membershipDeclinePlan = membershipPlan?.options?.find(
    (option) => option.isDecline
  );
  let previousPlanChosen =
    props.selections.membershipVal > 0
      ? membershipPlan?.options?.find(
          (plan) => plan.memberOptionId == props.selections.membership
        )
      : null;

  let membershipHelpText = membershipPlan?.helpText
    ? membershipPlan.helpText
    : '';

  ///// ELIMINATION PERIOD & BENEFIT DURATION LOGIC /////

  //Data from services
  const [eliminationPeriodTypes, setEliminationPeriodTypes] = useState();
  const [benefitDurTypes, setBenefitDurTypes] = useState();
  // calling to services for elim period and benefit option type ids
  const getElimPeriodList = async () => {
    let elimPeriodTypeList;
    try {
      elimPeriodTypeList = await optionService.getEliminationPeriodTypes();
    } catch (error) {
      console.log('error getting elimination periods', error);
    }

    // console.log("ElilminationPeriod List \n\n", elimPeriodTypeList);
  };

  const getBenefitDurationList = async () => {
    let benefitDurTypeList;
    try {
      benefitDurTypeList = await optionService.getBenefitDurationTypes();
    } catch (error) {
      console.log('error getting elimination periods', error);
    }

    // console.log("BenefitDuration List \n\n", benefitDurTypeList);
  };

  useEffect(() => {
    //getting lists from option service
    getElimPeriodList();
    getBenefitDurationList();
  }, []);

  //Data from a member's options

  //selected elimination period
  const [selectedEliminationPeriod, setSelectedEliminationPeriod] =
    useState(null);

  //get only unique values of elimination period from member options excluding duplicates
  const getUniqueEliminationPeriodList = () => {
    let elimPeriodInfo = [];

    for (let option of membershipPlan?.options) {
      if (option.eliminationPeriod > 0 && option.benefitTypeId != 3) {
        let object = {
          eliminationPeriod: option.eliminationPeriod,
          type: option.eliminationPeriodType,
          typeId: option.eliminationPeriodTypeId,
        };
        elimPeriodInfo.push(object);
      }
    }

    let uniqueElimPeriodArr = [
      ...new Set(elimPeriodInfo.map((obj) => obj.eliminationPeriod)),
    ];

    let uniqueElimPeriodOptions = uniqueElimPeriodArr.map(
      (eliminationPeriod) => {
        return elimPeriodInfo.find(
          (obj) => obj.eliminationPeriod === eliminationPeriod
        );
      }
    );

    setEliminationPeriodTypes(uniqueElimPeriodOptions);
    setSelectedEliminationPeriod(uniqueElimPeriodOptions[0]?.eliminationPeriod);
  };

  ////// selected benefit duration /////
  const [selectedBenefitDuration, setSelectedBenefitDuration] = useState();

  const getUniqueBenefitDurationList = () => {
    let benefitDurationInfo = [];

    for (let option of membershipPlan?.options) {
      if (option.benefitDuration > 0 && option.benefitTypeId != 3) {
        let object = {
          duration: option.benefitDuration,
          type: option.benefitDurationType,
          typeId: option.benefitDurationTypeId,
        };
        benefitDurationInfo.push(object);
      }
    }

    let uniqueBenefitDurList = [
      ...new Set(benefitDurationInfo.map((obj) => obj.duration)),
    ];

    let uniqueBenefitDurOptions = uniqueBenefitDurList.map((duration) => {
      return benefitDurationInfo.find((obj) => obj.duration === duration);
    });

    //set member benefit options off of unique options only
    setBenefitDurTypes(uniqueBenefitDurOptions);
    setSelectedBenefitDuration(uniqueBenefitDurOptions[0]?.duration);
  };

  const handleSelectBenefitDuration = (e) => {
    console.log('benefit e.target', e.target);
    if (eliminationPeriodTypes?.length > 1) {
      let indexOfDuration = 0;
      for (let i = 0; i < benefitDurTypes.length; i++) {
        if (benefitDurTypes[i].duration == e.target.value) {
          indexOfDuration = i;
        }
      }
      setSelectedEliminationPeriod(
        eliminationPeriodTypes[indexOfDuration]?.eliminationPeriod
      );
      setSelectedBenefitDuration(e.target.value);
    } else {
      setSelectedBenefitDuration(e.target.value);
    }

    // setSelectedBenefitDuration(e.target.value);

    //console.log('benefit duration handler', e.target.value, eliminationPeriodTypes, benefitDurTypes )
    setRadioSelected(null);
  };

  useEffect(() => {
    if (membershipPlan?.options?.length > 0) {
      getUniqueEliminationPeriodList();
      getUniqueBenefitDurationList();
    }
  }, [membershipPlan.options]);

  const [
    membershipOptionsExcludingDecline,
    setMembershipOptionsExcludingDecline,
  ] = useState([]);

  //does membershipPlan list exist? if yes, find index to start amount and slider
  useEffect(() => {
    if (membershipOptionsExcludingDecline && expanded) {
      initialPlanAmountDisplay();
    }
  }, [
    props.recommendations,
    props.results,
    expanded,
    membershipOptionsExcludingDecline,
  ]);

  useEffect(() => {
    if (membershipPlan?.options?.length > 0) {
      //array of all options excluding the $0 decline amount & benefit duration selected
      let filteredMembershipOptions = membershipPlan?.options.filter(
        (option) => {
          return (
            option.isDecline === false &&
            option.benefitDuration === selectedBenefitDuration &&
            option.eliminationPeriod === selectedEliminationPeriod &&
            option.benefitTypeId != 3
          );
        }
      );

      setMembershipOptionsExcludingDecline(filteredMembershipOptions);
    }
  }, [
    membershipPlan.options,
    selectedBenefitDuration,
    selectedEliminationPeriod,
  ]);

  //change value of elim period w/ select drop down
  const handleSelectEliminationPeriod = (e) => {
    // console.log("elimination period clicked", e.target.value);
    //update value w/ selected elimination period value
    setSelectedEliminationPeriod(e.target.value);
  };
  const [loading, setLoading] = useState(false);
  const [membershipPlanChosen, setMembershipPlanChosen] =
    useState(previousPlanChosen);
  const [currRec, setCurrRec] = useState(0); //hold the rec position in state to render radio w/ recommended option
  const [membershipSlider, setMembershipSlider] = useState(0);
  const [radioSelected, setRadioSelected] = useState(null);

  useEffect(() => {
    if (
      membershipSlider > membershipOptionsExcludingDecline?.length - 1 &&
      membershipOptionsExcludingDecline?.length > 0
    ) {
      setMembershipSlider(membershipOptionsExcludingDecline?.length - 1);
    }
  }, [membershipOptionsExcludingDecline?.length, membershipSlider]);

  // console.log("SELECTED PLAN \n\n", membershipPlanChosen);
  // console.log("Slider POsition \n\n", membershipSlider);
  // console.log("Benefit Duration \n\n", selectedBenefitDuration);

  //set initial rec of either lowest amount above $0 or recommended amount based on calculator

  const initialPlanAmountDisplay = () => {
    let previousSelectedOption;
    let previousSelectedOptionValid = false;
    if (props.selections.membershipVal > 0) {
      //need to look at all options, and not just filtered
      previousSelectedOption = membershipPlan?.options?.find(
        (option) => option.memberOptionId === props.selections.membership
      );
      previousSelectedOptionValid = membershipOptionsExcludingDecline.includes(
        previousSelectedOption
      );
      //filter by benefit duration before displaying previous option selected
      if (previousSelectedOptionValid) {
        setSelectedBenefitDuration(previousSelectedOption?.benefitDuration);
      }
    }
    if (previousSelectedOptionValid) {
      if (previousSelectedOption?.benefitAmount > 0) {
        for (let i = 0; i < membershipOptionsExcludingDecline.length; i++) {
          let optionId = membershipOptionsExcludingDecline[i]?.memberOptionId;
          if (optionId == previousSelectedOption.memberOptionId) {
            setMembershipSlider(i);
            setMembershipPlanChosen(membershipOptionsExcludingDecline[i]);
            setRadioSelected(
              membershipOptionsExcludingDecline[i].memberOptionId
            );
          }
        }
      }
    } else {
      let recommendationPosition;
      if (membershipOptionsExcludingDecline?.length > 1) {
        props.recommendations.Lowest
          ? (recommendationPosition = 1)
          : (recommendationPosition =
              membershipOptionsExcludingDecline.length - 2);
      }
      //set recommendation to display green check

      setCurrRec(recommendationPosition);
      setMembershipSlider(recommendationPosition);
      // }
    }

    //IF a user has already saved a selection and is going back to this section, display saved selection
  };

  ////// SLIDER LOGIC

  const handleMembershipSlider = (e) => {
    setMembershipSlider(e.target.value);
    setMembershipPlanChosen(membershipOptionsExcludingDecline[e.target.value]);
    setRadioSelected(
      membershipOptionsExcludingDecline[e.target.value]?.memberOptionId
    );
    //update sticky progress total
    if (
      membershipOptionsExcludingDecline[e.target.value].memberPremiumAmount &&
      membershipOptionsExcludingDecline[e.target.value].memberOptionId
    ) {
      props.setSelections({
        ...props.selections,
        membershipVal:
          membershipOptionsExcludingDecline[e.target.value].memberPremiumAmount,
        membership:
          membershipOptionsExcludingDecline[e.target.value].memberOptionId,
      });
    }
  };

  /////// RADIO BUTTON LOGIC

  const handleRadioSelection = (e) => {
    //handle selection to update sticky progress bar
    props.selectionHandler(e);
    //check if selection is the recommended or 'not now' option w/ the e.target
    // console.log("RADIO e.target.value", e.target.value);
    //set value of radioButton state to update checked value
    let radioAmount = Number(e.target.getAttribute('data-amount'));
    let radioValue = Number(e.target.value);
    setRadioSelected(Number(e.target.value));

    //LOOP TO TEST E.TARGET.VALUE?
    let chosenPlan = membershipPlan?.options?.find((option) => {
      return option.memberOptionId == radioValue;
    });

    //set chosen plan to selected
    setMembershipPlanChosen(chosenPlan);

    //find index of chosenPlan to update slider value
    if (radioAmount > 0) {
      let index = membershipOptionsExcludingDecline.indexOf(chosenPlan);
      setMembershipSlider(index);
    } else {
      let index = membershipPlan?.options?.indexOf(chosenPlan);
      setMembershipSlider(index);
    }
  };

  ///// BENEFIT DROPDOWN SELECT LOGIC

  const handleDropdownSelection = (e) => {
    //set radio button state to true if slider is moved - radioSelected value is only false if Not Now radio is clicked

    //handle the string value from dropdown list
    let tagName = e.target.tagName.toLowerCase();
    if (tagName === 'li') {
      let value = e.target.innerHTML.slice(1).replace(',', '');
      if (value.includes('(max)')) value = value.replace('(max)', '');
      for (let i = 0; i < membershipOptionsExcludingDecline.length; i++) {
        let benefitAmount = membershipOptionsExcludingDecline[i]?.benefitAmount;
        if (benefitAmount == value) {
          setMembershipSlider(i);
          setMembershipPlanChosen(membershipOptionsExcludingDecline[i]);
          setRadioSelected(membershipOptionsExcludingDecline[i].memberOptionId);
          //update sticky progress total
          if (
            membershipOptionsExcludingDecline[i].memberPremiumAmount &&
            membershipOptionsExcludingDecline[i].memberOptionId
          ) {
            props.setSelections({
              ...props.selections,
              membershipVal:
                membershipOptionsExcludingDecline[i].memberPremiumAmount,
              membership: membershipOptionsExcludingDecline[i].memberOptionId,
            });
          }
        }
      }
    } else if (tagName === 'input') {
      let value = e.target.value.slice(1).replace(',', '');
      if (value.includes('(max)')) value = value.replace('(max)', '');
      for (let i = 0; i < membershipOptionsExcludingDecline.length; i++) {
        let benefitAmount = membershipOptionsExcludingDecline[i]?.benefitAmount;
        if (benefitAmount == value) {
          setMembershipSlider(i);
          setMembershipPlanChosen(membershipOptionsExcludingDecline[i]);
          setRadioSelected(membershipOptionsExcludingDecline[i].memberOptionId);
          //update sticky progress total
          if (
            membershipOptionsExcludingDecline[i].memberPremiumAmount &&
            membershipOptionsExcludingDecline[i].memberOptionId
          ) {
            props.setSelections({
              ...props.selections,
              membershipVal:
                membershipOptionsExcludingDecline[i].memberPremiumAmount,
              membership: membershipOptionsExcludingDecline[i].memberOptionId,
            });
          }
        }
      }
    }
  };

  let maxBenefit =
    membershipOptionsExcludingDecline[
      membershipOptionsExcludingDecline.length - 1
    ]?.benefitAmount;

  const handleNonIncrementalRadioSelection = (e) => {
    //handle selection to update sticky progress bar
    props.selectionHandler(e);
    //check if selection is the recommended or 'not now' option w/ the e.target
    //set value of radioButton state to update checked value
    let radioAmount = Number(e.target.getAttribute('data-amount'));
    let radioValue = Number(e.target.value);
    setRadioSelected(Number(e.target.value));

    //LOOP TO TEST E.TARGET.VALUE?
    let chosenPlan = membershipPlan?.options?.find((option) => {
      return option.memberOptionId == radioValue;
    });

    //set chosen plan to selected
    setMembershipPlanChosen(chosenPlan);

    // //find index of chosenPlan to update slider value
    // if (radioAmount > 0) {
    //   let index = ltdOptionsExcludingDecline.indexOf(chosenPlan);
    //   setLtdSlider(index);
    // } else {
    //   let index = ltdPlan?.options?.indexOf(chosenPlan);
    //   setLtdSlider(index);
    // }

    //update selection in db
    // props?.handleEnrollClick(chosenPlan?.memberOptionId)
  };

  return (
    <div id="membership-calc-wrapper">
      <div
        className=""
        style={{
          backgroundColor: '#1B78AF12',
          minHeight: '57px',
          fontSize: '17px',
          fontWeight: 700,
          padding: '16px 38px 17px 16px',
          position: 'relative',
        }}
      >
        <Typography
          variant="h6"
          fontSize="16px"
          fontWeight="600"
          sx={{ cursor: 'pointer', position: 'relative' }}
          onClick={handleExpandClick}
        >
          {results?.membershipPlan?.title || 'Union Membership'}
          <div
            style={{
              position: 'absolute',
              width: '100%',
              height: '300%',
              top: '-100%',
            }}
          />{' '}
          {/* bigger clickable area */}
        </Typography>

        <div
          onClick={handleExpandClick}
          style={{
            position: 'absolute',
            right: '3%',
            top: '0%',
            color: '#195ffb',
            width: '18%',
            height: '100%',
            cursor: 'pointer',
          }}
        >
          {expandIcon}
        </div>
      </div>
      <Collapse in={expanded}>
        <div style={{ padding: '20px 20px 0px 20px' }}>
          <span dangerouslySetInnerHTML={{ __html: membershipHelpText }}></span>
        </div>
        {props.calculated && (
          <RadioGroup
            onChange={props.selectionHandler}
            value={props.selections.membership || currRec}
          >
            {results && results.membershipPlan.isIncremental != true ? (
              membershipPlan.options.map((option, index) => {
                let isSelected =
                  props.selections.membership == option.memberOptionId ||
                  (!props.selections.membership &&
                    currRec == option.memberOptionId);

                if (!option.isDecline) {
                  return (
                    <>
                      <div
                        key={option.memberPremiumAmount}
                        className="flex-row container option-container"
                        style={{
                          padding: '0px 0px 0px 3vw',
                          margin: '15px 0px 0px',
                        }}
                      >
                        <Radio
                          value={option.memberOptionId}
                          id={option.memberOptionId}
                          name="membership"
                          className="rate-calculator-radio"
                          onClick={() => setExpanded(false)}
                          inputProps={{
                            'data-amount': option.memberPremiumAmount,
                          }}
                        />

                        <div
                          className="col-10"
                          style={{ fontSize: '16px', letterSpacing: '.15px' }}
                        >
                          <span
                            style={{
                              fontSize: '28px',
                              fontWeight: isSelected ? 700 : 700,
                              color: '#195ffb',
                            }}
                          >
                            {!props.dummyDisplay &&
                              option.benefitAmount > 1 &&
                              UsdFormatter.format(
                                Math.floor(option.benefitAmount)
                              )}
                            {/* {!props.dummyDisplay && option.benefitAmount<=1 && Intl.NumberFormat("en-US",{style:"percent"}).format(option.benefitAmount)} */}

                            {props.dummyDisplay &&
                              option.name.replace(' Weekly Benefit', '')}
                          </span>
                          <span
                            className="mobile-md-benefit-schedule"
                            style={{
                              fontWeight: isSelected ? 700 : 500,
                              paddingLeft: '5px',
                            }}
                          >
                            weekly benefit
                          </span>
                          <span
                            className="mobile-sm-benefit-schedule"
                            style={{
                              whiteSpace: 'nowrap',
                              fontWeight: isSelected ? 700 : 500,
                              paddingLeft: '5px',
                            }}
                          >
                            weekly benefit
                          </span>
                          {props.calculated &&
                            props.selectionRec(
                              membershipPlan.options,
                              index
                            ) && (
                              <img
                                style={{
                                  marginLeft: '5px',
                                  position: 'relative',
                                  top: '-2px',
                                }}
                                src={CustomCheckIcon}
                              />
                            )}
                          <span
                            style={{
                              fontSize: '18px',
                              fontWeight: isSelected ? 700 : 500,
                              color: props.dummyFontColor,
                              position: 'absolute',
                              bottom: '1vh',
                              right: '8vw',
                            }}
                          >
                            {isSelected && '+'}
                            {!props.dummyDisplay &&
                              UsdFormatterDec.format(
                                option.memberPremiumAmount
                              )}
                            {props.dummyDisplay && '$0.00'}
                          </span>
                        </div>
                      </div>

                      <div
                        className="flex-row "
                        style={{
                          padding: '0px 0px 0px 4vw',
                          paddingLeft: 'clamp(45px,8vw,150px)',
                          paddingRight: 'clamp(45px,8vw,150px)',
                        }}
                      >
                        {option.explanationOfBenefits}
                      </div>
                    </>
                  );
                } else {
                  return (
                    <div
                      key={option.memberPremiumAmount}
                      className="flex-row container option-container"
                      style={{ padding: '0px 0px 0px 3vw', margin: '15px 0px' }}
                    >
                      <Radio
                        value={option.memberOptionId}
                        name="membership"
                        className="rate-calculator-radio"
                        onClick={() => setExpanded(false)}
                        inputProps={{
                          'data-amount': option.memberPremiumAmount,
                        }}
                      />
                      <div
                        style={{
                          alignSelf: 'center',
                          fontWeight: isSelected ? 700 : 400,
                        }}
                      >
                        Not Now
                      </div>
                    </div>
                  );
                }
              })
            ) : (
              <div style={{ padding: '0px 20px 20px 20px' }}>
                {/* Elimination Period & Benefit Duration select*/}
                <div
                  className="flex-row"
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    padding: '20px 0 0 0',
                    textAlign: 'left',
                    marginBottom: '15px',
                  }}
                >
                  <TextField
                    // disabled={eliminationPeriodTypes?.length <= 1}
                    disabled={true}
                    select
                    variant="outlined"
                    label="Elimination Period"
                    InputLabelProps={{
                      sx: {
                        '&.Mui-disabled': {
                          color: 'unset',
                        },
                      },
                    }}
                    InputProps={{
                      sx: {
                        pointerEvents:
                          eliminationPeriodTypes?.length <= 1 ? 'none' : '',
                      },
                    }}
                    value={selectedEliminationPeriod || ''}
                    sx={componentStyles.membershipSelectInput}
                    onChange={handleSelectEliminationPeriod}
                    SelectProps={{
                      IconComponent: () =>
                        eliminationPeriodTypes?.length <= 1 ? null : (
                          <ArrowDropDownIcon
                            sx={{ position: 'absolute', right: 7 }}
                          />
                        ),
                    }}
                  >
                    {/* select options base on unique elimination period options */}
                    {eliminationPeriodTypes?.map((option) => (
                      <MenuItem
                        key={option.eliminationPeriod}
                        value={option.eliminationPeriod}
                      >
                        {option.eliminationPeriod} {option.type}
                      </MenuItem>
                    ))}
                  </TextField>

                  <TextField
                    // disabled={benefitDurTypes?.length <= 1}
                    select
                    variant="outlined"
                    label="Benefit Duration"
                    InputLabelProps={{
                      sx: {
                        '&.Mui-disabled': {
                          color: 'unset',
                        },
                      },
                    }}
                    InputProps={{
                      sx: {
                        pointerEvents:
                          benefitDurTypes?.length <= 1 ? 'none' : '',
                      },
                    }}
                    value={selectedBenefitDuration || ''}
                    sx={componentStyles.membershipSelectInput}
                    onChange={handleSelectBenefitDuration}
                    SelectProps={{
                      IconComponent: () =>
                        benefitDurTypes?.length <= 1 ? null : (
                          <ArrowDropDownIcon
                            sx={{ position: 'absolute', right: 7 }}
                          />
                        ),
                    }}
                  >
                    {benefitDurTypes?.map((option) => (
                      <MenuItem key={option.duration} value={option.duration}>
                        {option.duration} {option.type}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>

                {/* //////// Benefit amount select and slider /////////// */}

                <div
                  className="flex-row container"
                  style={componentStyles.radioButtonItem}
                >
                  <Radio
                    value={
                      membershipOptionsExcludingDecline[membershipSlider]
                        ?.memberOptionId
                    }
                    name="membership"
                    sx={componentStyles.radioButtonSingle}
                    icon={<RadioButtonIcon sx={{ height: '19px' }} />}
                    checkedIcon={
                      <RadioButtonCheckedIcon sx={{ height: '19px' }} />
                    }
                    onClick={handleRadioSelection}
                    checked={
                      radioSelected !== membershipDeclinePlan.memberOptionId &&
                      radioSelected != null &&
                      radioSelected ==
                        membershipOptionsExcludingDecline[membershipSlider]
                          ?.memberOptionId
                    }
                    inputProps={{
                      'data-amount':
                        membershipOptionsExcludingDecline[membershipSlider]
                          ?.memberPremiumAmount,
                    }}
                  />

                  <div
                    className="col"
                    style={{
                      fontSize: '15px',
                      textAlign: 'left',
                      marginLeft: '-10px',
                      fontWeight:
                        radioSelected ==
                        membershipOptionsExcludingDecline[membershipSlider]
                          ?.memberOptionId
                          ? 700
                          : 500,
                    }}
                  >
                    <span>
                      {`Member (${UsdFormatter.format(
                        membershipOptionsExcludingDecline[membershipSlider]
                          ?.benefitAmount || 0
                      )})`}
                    </span>

                    {/* show green check mark next to current recommended amount */}
                    {currRec === membershipSlider && (
                      <img
                        alt="check mark"
                        src={CustomCheckIcon}
                        style={{ marginLeft: '5px' }}
                      />
                    )}

                    <span
                      style={{
                        float: 'right',
                        fontWeight:
                          radioSelected ==
                          membershipOptionsExcludingDecline[membershipSlider]
                            ?.memberOptionId
                            ? 700
                            : 500,
                      }}
                    >
                      {radioSelected ==
                        membershipOptionsExcludingDecline[membershipSlider]
                          ?.memberOptionId && '+'}
                      {!props.dummyDisplay &&
                        UsdFormatterDec.format(
                          membershipOptionsExcludingDecline[membershipSlider]
                            ?.memberPremiumAmount || 0
                        )}
                      {props.dummyDisplay && '$0.00'}
                    </span>
                  </div>
                </div>
                <div
                  className="flex-row"
                  style={{
                    paddingLeft: '0rem',
                    fontFamily: 'Archivo',
                    fontWeight: 500,
                    fontSize: '15px',
                    lineHeight: '20px',
                    textAlign: 'left',
                    margin: '0 15px 20px 15px',
                  }}
                >
                  {
                    membershipOptionsExcludingDecline[membershipSlider]
                      ?.explanationOfBenefits
                  }
                </div>

                {membershipOptionsExcludingDecline[membershipSlider]
                  ?.benefitOffsets && (
                  <div
                    className="flex-row"
                    style={{
                      paddingLeft: '0rem',
                      fontFamily: 'Archivo',
                      fontWeight: 500,
                      fontSize: '15px',
                      lineHeight: '25px',
                      textAlign: 'left',
                      marginLeft: '15px',
                      marginRight: '15px',
                    }}
                  >
                    {
                      membershipOptionsExcludingDecline[membershipSlider]
                        ?.benefitOffsets
                    }
                  </div>
                )}

                <div
                  className="flex-row"
                  style={{ marginBottom: '10px', padding: '0px' }}
                >
                  <Autocomplete
                    disablePortal
                    fullWidth
                    autoComplete
                    // options={membershipOptionsExcludingDecline?.map((option) =>
                    //   UsdFormatter.format(option.benefitAmount)
                    // )}
                    options={membershipOptionsExcludingDecline.map(
                      (option, i) => {
                        let isLast =
                          i == membershipOptionsExcludingDecline.length - 1;
                        // console.log('isLAst',isLast)
                        if (isLast) {
                          return `${UsdFormatter.format(
                            option.benefitAmount
                          )} (max)`;
                        } else {
                          return UsdFormatter.format(option.benefitAmount);
                        }
                      }
                    )}
                    // value={UsdFormatter.format(
                    //   membershipOptionsExcludingDecline[membershipSlider]?.benefitAmount || 0
                    // )}
                    value={
                      membershipOptionsExcludingDecline[membershipSlider]
                        ?.benefitAmount == maxBenefit
                        ? `${UsdFormatter.format(
                            membershipOptionsExcludingDecline[membershipSlider]
                              ?.benefitAmount
                          )} (max)`
                        : UsdFormatter.format(
                            membershipOptionsExcludingDecline[membershipSlider]
                              ?.benefitAmount
                          )
                    }
                    onChange={handleDropdownSelection}
                    renderInput={(params) => (
                      <TextField {...params} label="Benefit Amount" />
                    )}
                  />
                </div>

                {/* SLIDER */}

                <div
                  className="flex-row"
                  style={componentStyles.sliderDisplayContainer}
                >
                  <div
                    className="col-1"
                    style={componentStyles.sliderLowAmount}
                  >
                    {/* {`$${membershipOptionsExcludingDecline[0]?.benefitAmount}`} */}
                    {UsdFormatter.format(
                      membershipOptionsExcludingDecline[0]?.benefitAmount || 0
                    )}
                  </div>
                  <div className="col-9">
                    <Slider
                      className=""
                      value={membershipSlider || 0}
                      onChange={handleMembershipSlider}
                      step={1}
                      min={0}
                      max={membershipOptionsExcludingDecline.length - 1}
                      sx={{
                        width: '90%',
                        marginLeft: '5%',
                        marginBottom: '0px',
                      }}
                      valueLabelDisplay="auto"
                      valueLabelFormat={UsdFormatter.format(
                        membershipOptionsExcludingDecline[membershipSlider]
                          ?.benefitAmount
                      )}
                    />
                  </div>
                  <div
                    className="col-1"
                    style={componentStyles.sliderHighAmount}
                  >
                    {/* {`$${membershipOptionsExcludingDecline[membershipOptionsExcludingDecline.length - 1]?.benefitAmount}`} */}
                    {UsdFormatter.format(
                      membershipOptionsExcludingDecline[
                        membershipOptionsExcludingDecline.length - 1
                      ]?.benefitAmount || 0
                    )}
                  </div>
                </div>

                {membershipPlan.options
                  .sort((a, b) => a.displayOrder - b.displayOrder)
                  .map((option, index) => {
                    let isSelected =
                      props.selections.membership == option.memberOptionId ||
                      (!props.selections.membership &&
                        currRec == option.memberOptionId);

                    if (!option.isDecline && option.benefitTypeId == 3) {
                      return (
                        <React.Fragment key={`${JSON.stringify(option)}`}>
                          <Box sx={enrollmentPlanStyles.optionRow}>
                            {/* radio button, benefit amount, type */}
                            <Box
                              sx={
                                enrollmentPlanStyles.optionRadioAndTextContainer
                              }
                            >
                              <Radio
                                sx={enrollmentPlanStyles.planSelectRadioButton}
                                value={option.memberOptionId}
                                id={`${option.memberOptionId}`}
                                name="membership"
                                icon={
                                  <RadioButtonIcon sx={{ height: '19px' }} />
                                }
                                checkedIcon={
                                  <RadioButtonCheckedIcon
                                    sx={{ height: '19px' }}
                                  />
                                }
                                onClick={
                                  handleNonIncrementalRadioSelection
                                  //   // props.setExpanded({...props.expanded,  ladd: true })
                                }
                                inputProps={{
                                  'data-amount': option.memberPremiumAmount,
                                }}
                              />

                              <Box
                                sx={
                                  enrollmentPlanStyles.singleOptionTextContainer
                                }
                              >
                                <Typography
                                  sx={enrollmentPlanStyles.singleOptionText(
                                    isSelected
                                  )}
                                >
                                  {option?.name && (
                                    <>
                                      {option.name}{' '}
                                      {option.name.includes('%') &&
                                        option?.benefitAmount &&
                                        `(Max ${UsdFormatter.format(
                                          option.benefitAmount
                                        )})`}
                                    </>
                                  )}
                                </Typography>

                                {option?.benefitAmount == currRec && (
                                  <Typography
                                    sx={enrollmentPlanStyles.recommendedText}
                                  >{` (recommended)`}</Typography>
                                )}
                              </Box>
                            </Box>

                            {/* premium amount */}
                            <Box
                              sx={enrollmentPlanStyles.singleOptionText(
                                isSelected
                              )}
                            >
                              {isSelected && '+'}
                              {!props.dummyDisplay &&
                                UsdFormatterDec.format(
                                  option.memberPremiumAmount
                                )}
                              {props.dummyDisplay && '$0.00'}
                            </Box>
                          </Box>

                          {/* explanation of benefits */}
                          {option.explanationOfBenefits && (
                            <Box
                              sx={
                                enrollmentPlanStyles.optionExplanationOfBenefits
                              }
                            >
                              {option.explanationOfBenefits}
                            </Box>
                          )}

                          {/* benefit offsets */}
                          {option.benefitOffsets && (
                            <Box
                              sx={
                                enrollmentPlanStyles.optionExplanationOfBenefits
                              }
                            >
                              {option.benefitOffsets}
                            </Box>
                          )}
                        </React.Fragment>
                      );
                    }
                  })}
                {/* NOT NOW */}
                <div
                  className="flex-row container"
                  style={componentStyles.radioButtonItem}
                >
                  <Radio
                    value={membershipDeclinePlan?.memberOptionId || 0}
                    name="membership"
                    className=""
                    checked={
                      radioSelected == membershipDeclinePlan.memberOptionId
                    }
                    sx={componentStyles.radioButtonSingle}
                    icon={<RadioButtonIcon sx={{ height: '19px' }} />}
                    checkedIcon={
                      <RadioButtonCheckedIcon sx={{ height: '19px' }} />
                    }
                    onClick={handleRadioSelection}
                    inputProps={{
                      'data-amount': membershipDeclinePlan?.memberPremiumAmount,
                    }}
                  />
                  <div
                    className="col"
                    style={{
                      fontSize: '15px',
                      textAlign: 'left',
                      marginLeft: '-10px',
                      fontWeight:
                        radioSelected === membershipDeclinePlan.memberOptionId
                          ? 700
                          : 500,
                    }}
                  >
                    <div
                      className="flex-row"
                      style={{
                        display: 'flex',
                      }}
                    >
                      Not Now
                      <Tooltip
                        title={
                          membershipPlan?.infoTipText ||
                          'You will be able to come back to enroll in this benefit during a future enrollment window.'
                        }
                        placement="bottom-end"
                        arrow
                        enterTouchDelay={100}
                      >
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <InfoOutlinedIcon
                            sx={{
                              height: '20px',
                              color: '#717171',
                              marginLeft: '5px',
                            }}
                          />
                        </div>
                      </Tooltip>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </RadioGroup>
        )}
      </Collapse>
      {!expanded && (
        <div
          style={{
            padding: '0px 0px 0px 15px',
            margin: '15px 0px',
            fontSize: '18px',
            fontWeight: '500',
          }}
        >
          {props.selections.membership &&
            props.selections.membershipVal > 0 && (
              <>
                <span>
                  {
                    membershipPlan.options.find(
                      (option) =>
                        option.memberOptionId == props.selections.membership
                    )?.name
                  }
                </span>
                {!props.dummyDisplay && (
                  <span
                    style={{
                      float: 'right',
                      marginRight: '15px',
                      fontWeight: '700',
                    }}
                  >
                    + {UsdFormatterDec.format(props.selections.membershipVal)}
                  </span>
                )}
                {props.dummyDisplay && (
                  <span
                    style={{
                      float: 'right',
                      marginRight: '15px',
                      fontWeight: '700',
                      color: '#00000033',
                    }}
                  >
                    + $0.00
                  </span>
                )}
              </>
            )}
          {props.selections.membership &&
            props.selections.membershipVal == 0 && (
              <>
                <span>
                  {membershipPlan.title} <em>waived</em>
                </span>
                <span
                  style={{
                    float: 'right',
                    marginRight: '15px',
                    color: '#00000033',
                  }}
                >
                  $0.00
                </span>
              </>
            )}
          {!props.selections.membership && 'No plan selected'}
        </div>
      )}
    </div>
  );
}
