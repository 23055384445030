import { format } from "date-fns"
import { formatUTCToShortDate } from '../../../utils';

//MuiIcons
import AccessTimeIcon from "@mui/icons-material/AccessTime"

//Mui Components
import Chip from "@mui/material/Chip"

export default function NextPaymentChip(props) {
    const formatDate = (rawDate) => {
        //date comes in as a utc string w/ no timezone offset
        //need to add timezone offset and then format to mm/dd/yyyy(plan to do this in utils folder eventually)
        console.log('rawDate',rawDate)
        // console.log('formatDate',rawDate)
        // if (rawDate?.length < 11) rawDate = new Date(rawDate).toISOString();
        let [yyyy, mm, dd, hh, m, s] = rawDate.split(/[^\d]+/);
        let date = new Date();
        date.setUTCFullYear(+yyyy);
        date.setUTCDate(+dd);
        date.setUTCMonth(mm - 1);
        date.setUTCHours(+hh);
        date.setUTCMinutes(+m + date.getTimezoneOffset());
        date.setUTCSeconds(+s);
        // console.log('formatDate after',date)
        date = format(date, "MM/dd/yyyy");
        return date;
      };
  return (
    <>
      <Chip
        icon={
          <AccessTimeIcon sx={{ height: "24px", color: "#1B5E20!important" }} />
        }
        sx={{
          minWidth: "200px",
          backgroundColor: "background.activeWorking",
          color: "primary.activeChip",
          fontFamily: "Archivo",
          margin: "0px 10px 0px 20px",
          fontWeight: "500",
          alignItems: "center",
          fontSize: "13px",
          alignSelf: "self-end",
        }}
        label={
            `Next Payment ${formatUTCToShortDate(
            props?.paymentSchedule[0]?.calculatedNextOccurrence
          )}` || ""
        }
      />
    </>
  )
}
