import React, { useState } from 'react';

//Services
import registerService from '../../../services/register-service';

//MuiComponents
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Link from '@mui/material/Link';
import { Box, Divider, Typography } from '@mui/material';

//shared styles
import externalEnrollmentSharedStyles from '../externalEnrollmentSharedStyles';

//component styles
const componentStyles = {
  componentContainer: {
    margin: '20px auto',
  },
  sectionContainer: {
    marginBottom: '25px',
  },
};

function ExternalForgot(props) {
  // const handleValueChange = (e) => {
  //     let inputVal = e.target.value;
  //     let inputProperty = e.target.getAttribute('data-property')
  //     console.log(inputProperty,inputVal)
  //     setValues({
  //         ...values,
  //         [inputProperty]:inputVal
  //     })
  // }

  const [forgotState, setForgotState] = useState({
    verificationCode: (Math.round(Math.random(0) * 1000) + 1000).toString(),
    emailSent: false,
  });

  const [credentialState, setCredentialState] = useState({
    email: '',
    code: '',
    newPassword: '',
  });

  const sendResetLink = async () => {
    let emailValue = document.getElementById('email-forgot').value;

    setForgotState({
      ...forgotState,
      emailSent: true,
    });

    setCredentialState({
      ...credentialState,
      email: emailValue,
      code: forgotState.verificationCode,
    });
    await registerService.forgotPassword(
      emailValue,
      forgotState.verificationCode
    );
  };

  const tryOtherEmail = () => {
    setForgotState({
      ...forgotState,
      emailSent: false,
    });
  };

  const backToLogin = () => {
    props.setForgotPassword(false);
  };

  const isValidEmail = (email) => {
    if (!/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(email)) {
      return false;
    } else return true;
  };

  return (
    <>
      {!forgotState.emailSent && (
        <Box sx={componentStyles.componentContainer}>
          <Box sx={componentStyles.sectionContainer}>
            <Typography variant="body2">
              Please enter the email address associated with your UnionHub
              account to receive a password reset link.{' '}
            </Typography>
          </Box>

          <Box sx={componentStyles.sectionContainer}>
            <TextField
              fullWidth
              id="email-forgot"
              label="Email"
              //   value={values.userName} onChange={handleValueChange}
              inputProps={{ 'data-property': 'userName' }}
            />
          </Box>

          <Button
            variant="contained"
            fullWidth
            disabled={isValidEmail(credentialState.email)}
            sx={externalEnrollmentSharedStyles.largeBlueButton}
            onClick={sendResetLink}
          >
            Send link
          </Button>

          <Button
            fullWidth
            sx={{
              ...externalEnrollmentSharedStyles.largeBlueButton,
              marginTop: '15px',
            }}
            onClick={backToLogin}
          >
            Back to Log in
          </Button>
        </Box>
      )}

      {forgotState.emailSent && (
        <>
          <Box>
            <Alert severity="success">
              <AlertTitle>Email Sent</AlertTitle>
              Check your email and open the link we sent to begin resetting your
              password.
            </Alert>
          </Box>
          <Box>
            Did not receive the email? Check your spam filter or
            <Link onClick={tryOtherEmail}> try another email address.</Link>
          </Box>
        </>
      )}
    </>
  );
}

export default ExternalForgot;
