import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from 'react-router-dom';


//MuiIcons
import VisibilityIcon from '@mui/icons-material/Visibility';
import NoVisibilityIcon from '@mui/icons-material/VisibilityOff';

//Services

import registerService from '../../../services/register-service';

//MuiComponents
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Alert from '@mui/material/Alert';

//Assets 
import fullColorLogo from '../../../assets/images/FullColorOnlyLogo.png'


export default function ResetPassword(props) {

   const location = useLocation();

   const [values, setValues] = useState({
      passwordOne: "",
      passwordTwo: "",
   })

   const handlePasswordOneChange = (event) => {
      event.persist();
      setValues((values) => ({
         ...values,
         passwordOne: event.target.value,
      }));
   }

   const handlePasswordTwoChange = (event) => {
      event.persist();
      setValues((values) => ({
         ...values,
         passwordTwo: event.target.value,
      }));
   }

   const handleResetPassword = async(event) => {
      if(values.passwordOne != values.passwordTwo){
         setAuthError(true)
         handleAuthError()
      } else {
         const guid = location.pathname.replace("/resetlink/", "");
         await registerService.updatePassword(guid, values.password);
      }
   }

   //Password Visibility
   const [displayPassword, setDisplayPassword] = useState(false)

   const swapIcon = (e) => {
      //e.preventDefault();
      let inputElement = e.target.parentNode.children[0];
      if (displayPassword) {
         inputElement.setAttribute("type", "password");
         setDisplayPassword(false);
      } else {
         inputElement.setAttribute("type", "text");
         setDisplayPassword(true);
      }
   }

      // Password Icon
      const visibilityIcon = <div style={{ position: "absolute", right: "5%" }} type="button" onMouseDown={swapIcon} onMouseUp={swapIcon} onTouchStart={swapIcon} onTouchEnd={swapIcon}><VisibilityIcon sx={{ color: "#7D7987", cursor: "pointer" }} /></div>
      const noVisibilityIcon = <div style={{ position: "absolute", right: "5%" }} type="button" onClick={swapIcon}><NoVisibilityIcon sx={{ color: "#7D7987", cursor: "pointer" }} /></div>
      const passwordIcon = displayPassword ? noVisibilityIcon : visibilityIcon;
   
  //Authentication Error
  const [authError, setAuthError] = useState(false)

  const handleAuthError = () => {
   const timeId = setTimeout(() => {
      // After 5 seconds set the show value to false
      setAuthError(false)
   }, 5000)

   return () => {
      clearTimeout(timeId)
   }
}
   return (
      <>
         <div className="flex-row" style={{ backgroundColor: "white", height: "100vh" }}>
            <div style={{ margin: "auto", textAlign: "center" }}>
               <img src={fullColorLogo} style={{ maxWidth: "250px" }} />
                  <>
                     <h3>Set new password</h3>
                     <p>Your new password must be different to previously used passwords.</p>

                     <TextField
                        sx={{ borderRadius: "100px", paddingBottom: "20px" }}
                        value={values.passwordOne}
                        onChange={handlePasswordOneChange}
                        id="outlined-basic"
                        label="Password"
                        variant="outlined"
                        placeholder="Enter your password"
                        type="password"
                        
                        InputProps={{
                           disableUnderline: true,
                           sx: { borderRadius: "100px" },
                           endAdornment: passwordIcon 
                        }}
                        fullWidth
                     />
                     
                     <TextField
                        sx={{ borderRadius: "100px", paddingBottom: "20px" }}
                        value={values.passwordTwo}
                        onChange={handlePasswordTwoChange}
                        id="outlined-basic"
                        label="Password"
                        variant="outlined"
                        placeholder="Enter your password"
                        type="password"
                        
                        InputProps={{
                           disableUnderline: true,
                           sx: { borderRadius: "100px" },
                           endAdornment: passwordIcon 
                        }}
                        fullWidth
                     />

                     <br />
                     <Button
                        onClick={handleResetPassword}
                        sx={{ color: '195FFB', float: 'right', borderRadius: '33px' }}
                        variant='contained'
                        fullWidth>
                        <span style={{ fontSize: 16, fontWeight: 400 }}>Reset Password</span>
                     </Button>
                  </>
                  {authError &&
                        <Alert severity="error">Passwords do not match</Alert>
                     }
            </div>
         </div>

      </>
   )
}