import React, { useContext, useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";

//services
import enrollmentService from "../../../../../../../services/enrollment-service";
import attachmentService from '../../../../../../../services/attachment-service';

//Mui Components
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import ButtonGroup from "@mui/material/ButtonGroup";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";

//Mui Icons
import UploadFileIcon from "@mui/icons-material/UploadFile";
import CircleIcon from "@mui/icons-material/Circle";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

//our components
import DocumentDrawer from "../../member-documents-tab/DocumentDrawer";
import { thisUserContext } from "../../../../../nav-container/NavContainer";

export default function MemberPlanDocuments(props) {
  const componentStyles = {
    componentBody: {
      width: "100%",
      borderRadius: "0px",
      fontFamily: "Poppins",
      backgroundColor: "background.default",
      overflowY: "auto",
      paddingBottom: "16px",
      borderBottom: "1px solid rgba(217, 217, 217, 1)",
    },
    sectionContainer: {
      padding: "16px 16px 8px",
      display: "flex",
      flexWrap: "wrap",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "top",
      margin: "0 auto",
      position:'sticky',
      top:'0px',
      backgroundColor: "background.default",
      zIndex: 5,
      // border: "2px solid blue"
    },
    title: {
      color: "text.primary",
      fontFamily: "Poppins",
      fontSize: "20px",
      fontWeight: "500",
    },
    description: {
      color: "text.secondary",
      fontFamily: "Archivo",
      fontSize: "14px",
      fontWeight: "400",
      paddingBottom: "6px",
    },
    documentListContainer: {
      // display: "flex",
      // flexWrap: "wrap",
      // flexDirection: "column",
      // justifyContent: "flex-start",
      // margin: "0px 16px",
      maxHeight: "calc(100vh - 685px)",
    },
    singleDocumentRow: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      margin: "0px 0px 12px 16px",
      maxWidth: "700px",
    },
    documentIconContainer: {
      margin: "0px 16px",
    },
    documentData: {
      display: "flex",
      flexDirection: "column",
      padding: "0px 8px",
    },
    documentDownloadMenu: {
      textAlign: "center",
      padding: "0px 14px",
      margin: "0 0",
      marginLeft: "auto",
      position: "relative",
      // border: "2px solid red"
    },
  };

  const [thisUser, setThisUser] = useState();
  let _thisUser = useContext(thisUserContext)
  let getThisUser = async () => {
    setThisUser(_thisUser.thisUser)
  }
  useEffect(() => {
    if (_thisUser) getThisUser()
  }, [_thisUser])

  const [enrollmentDocuments, setEnrollmentDocuments] = useState([]);
  const getAllDocuments = async (memberId) => {
    await props.getAllDocuments()
  };

  //get all documents, filter to show only enrollment documents, and update doc list if new member is selected
  useEffect(() => {
    if (props.documents){
      let filteredDocuments = props.documents.filter(
        (doc) => doc.attachmentTypeId === 1
      );
      setEnrollmentDocuments(filteredDocuments);
    }
  }, [props.documents,props.documents?.length]);

  //open document menu to download
  //need to update anchor element (anchorEl) to the current document for each row
  const [anchorEl, setAnchorEl] = useState({});

  const handleOpenRowMenu = (e, document) => {
    setAnchorEl({
      ...anchorEl,
      [e.currentTarget.value]: e.currentTarget,
    });
    setSelectedDocument(document);
  };

  const handleCloseRowMenu = () => {
    setAnchorEl({});
  };

  const [attachmentTypes, setAttachmentTypes] = useState([])
  useEffect(() => {
    if (props.attachmentTypes) setAttachmentTypes(props.attachmentTypes)
  },[props.attachmentTypes])

  const uploadAnchorEl = useRef(null);

   

  const [uploadDocument,setUploadDocument] = useState({
    file:null,
    attachmentTypeId:1,
    fileName:'',
    base64:''
  })
  const handleUploadFinalize = async () => {
    try {
        await attachmentService.uploadAttachment(props.member.id, uploadDocument.attachmentTypeId, uploadDocument.fileName, uploadDocument.base64)
        setMessage({
          ...message,
          messageDisplay: true,
          autoHideDuration: "1600",
          severity: "success",
          backgroundColor: "",
          message: "Successfully uploaded file"
        })
        getAllDocuments(props.member.id)
    } catch (error) {
        setMessage({
          ...message,
          messageDisplay: true,
          autoHideDuration: "1600",
          severity: "error",
          backgroundColor: "",
          message: "Error uploading file"
        })
    }
    let blankState = {
        file:null,
        attachmentTypeId:1,
        fileName:'',
        base64:''
    }
    setUploadDocument(blankState)
  }


  //download a document
  //need to pass in the document info of whichever document is selected
  const [selectedDocument, setSelectedDocument] = useState({});
  const [documentDrawerOpen, setDocumentDrawerOpen] = useState(false);
  const handleSendClick = () => {
    setDocumentDrawerOpen(true);
    setAnchorEl(null);
  }

  const downloadSingleDocument = (selectedDocument) => {
    console.log("selectedDocument", selectedDocument);
    if(selectedDocument.uniqueId){
      const link = document.createElement("a");
      link.download = `/api/attachment/${selectedDocument.uniqueId}`;
      link.href = `./api/attachment/${selectedDocument.uniqueId}`;
      link.click();
      //clear it from the dom
      link.remove();
    }
  };

  const [message, setMessage] = useState({
  })

  useEffect(() => {
     const timeId = setTimeout(() => {
        // After 3 seconds set the show value to false
        setMessage({
           ...message,
           messageDisplay: false,
           autoHideDuration: "",
           severity: "info",
           backgroundColor: "",
           message: ""
        })
     }, 5000)

     return () => {
        clearTimeout(timeId)
     }
  }, [message.messageDisplay])

  const handleMessageClose = (event, reason) => {
     if (reason === 'clickaway') {
        return;
     }
     setMessage({
        ...message,
        messageDisplay: false,
        autoHideDuration: "",
        severity: "info",
        backgroundColor: "",
        message: ""
     })
  };

  let getFileAsBase64 = (file, cb) => {
    let reader = new FileReader()
    //reader is async, so we have to handle promise below
    reader.readAsDataURL(file)

    //callback to use result elsewhere
    reader.onload = function () {
       cb(reader.result)
    }
    reader.onerror = function (err) {
       console.log(err)
    }
 }

  let handleUploadClick = async (e) => {
    if (e.target.files) {
       for (let file of e.target.files) {

          getFileAsBase64(file, async (result) => {
             //just the string, not the encoding
             let base64 = result.replace(`data:${file.type};base64,`, '')
             setUploadDocument({
              ...uploadDocument,
              file:file,
              fileName:file.name,
              base64:base64
           })
          })
       }
    }
 }

  return (
    <Paper className="" elevation={0} sx={componentStyles.componentBody}>
      <Box sx={componentStyles.sectionContainer} ref={uploadAnchorEl}>
        <Box className="col-9">
          <Typography variant="h6" sx={componentStyles.title}>
            Documents
          </Typography>
          <Typography variant="body2" sx={componentStyles.description}>
            List of all completed enrollment documents.
          </Typography>
        </Box>
        <Box className="col-3" sx={{textAlign:'end'}}>
          <Button variant='contained' component='label'  size='medium' sx={{ fontSize: '14px', height: '30px' }}>
            UPLOAD
            <input hidden accept='image/svg,image/png,image/jpeg,image/gif,audio/*,image/*,video/*,.pdf,.doc,.xlsx' type='file' onChange={handleUploadClick} />
          </Button>
        </Box>
      </Box>

      {uploadDocument?.file && 
        <Menu open={uploadDocument?.file} anchorEl={uploadAnchorEl.current} MenuListProps={{sx:{padding:'20px'}}}>
            <Typography>Selected File: {uploadDocument?.file?.name}</Typography>
            <br/>
            <FormControl fullWidth sx={{ margin:'auto', marginTop:'6px', marginBottom:'6px', height:'45px', '& fieldset':{height:'45px'}}}>
              <InputLabel id='document-type-label' >Document Type</InputLabel>
              <Select
                  sx={{height:'45px', '& fieldset':{height:'45px'}}}
                  value={uploadDocument?.attachmentTypeId}
                  onChange={(e) => setUploadDocument({...uploadDocument,attachmentTypeId:e.target.value})}
                  label="Document Type"
                  labelId='document-type-label'
              >
                  {attachmentTypes?.length > 0 && attachmentTypes.map(type => {
                    return (
                        <MenuItem value={type.id}>{type.name}</MenuItem>
                    )
                  })}
              </Select>
            </FormControl>
            <br/>
            <TextField fullWidth sx={{ margin:'auto', marginTop:'6px', marginBottom:'6px'}} label='File Name' value={uploadDocument?.fileName} onChange={(e) => setUploadDocument({...uploadDocument,fileName:e.target.value})} />
            <br/>
            <div style={{display:'flex',justifyContent:'space-between'}}>
              <Button variant='contained' onClick={handleUploadFinalize}>Upload</Button>
              <Button variant='outlined' onClick={()=>setUploadDocument({...uploadDocument,file:null})}>Cancel</Button>
            </div>
        </Menu>
        }

      {/* document list */}
      <Box sx={componentStyles.documentListContainer}>
        {enrollmentDocuments.length > 0 &&
          enrollmentDocuments.map((document) => {
            return (
              <Box sx={componentStyles.singleDocumentRow}>
                <Box sx={componentStyles.documentIconContainer}>
                  <UploadFileIcon
                    sx={{
                      height: "40px",
                      width: "40px",
                      color: "primary.main",
                      backgroundColor: "primary.light",
                      padding: "5px",
                      borderRadius: "100%",
                    }}
                  />
                </Box>
                <Box sx={componentStyles.documentData}>
                  <Typography sx={{ fontSize: "14px", fontWeight: 500 }}>
                    {document?.filename || "Unnamed document"}
                  </Typography>
                  <Typography
                    sx={{ fontSize: "14px", color: "text.secondary" }}
                  >
                    {document?.fileType || "PDF"}{" "}
                    <CircleIcon
                      sx={{ width: "5px", margin: "0 5px", padding: "0px" }}
                    />
                    {document?.uploadDate?.slice(0, 10) || "Upload Date"}
                  </Typography>
                </Box>

                {/* download pdf dropdown menu */}
                <Box sx={componentStyles.documentDownloadMenu}>
                  <>
                    <IconButton
                      onClick={(e) => handleOpenRowMenu(e, document)}
                      value={document?.id}
                    >
                      <MoreVertIcon
                        sx={{
                          color: "text.secondary",
                          // float: "right",
                        }}
                      />
                    </IconButton>
                    <Menu
                      id="download-enrollment-pdf"
                      anchorEl={anchorEl?.[document.id]}
                      open={Boolean(anchorEl?.[document.id])}
                      onClose={handleCloseRowMenu}
                      // sx={{position: 'absolute', zIndex: 5}}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "left",
                        
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                        

                      }}
                    >
                      <MenuItem
                        value={document}
                        sx={{ fontSize: "14px"}}
                        onClick={() => downloadSingleDocument(document)}
                      >
                        Download PDF
                      </MenuItem>

                      <MenuItem
                        value={document}
                        sx={{ fontSize: "14px"}}
                        onClick={handleSendClick}
                      >
                        Send attachment
                      </MenuItem>
                    </Menu>
                  </>
                </Box>
              </Box>
            );
          })}
      </Box>
      <Snackbar open={message.messageDisplay} autoHideDuration={message.autoHideDuration} onClose={handleMessageClose} sx={{ opacity: .8 }}>
            <MuiAlert
               elevation={6} variant="filled"
               // onClose={handleClose} 
               severity={message.severity} sx={{ width: '100%', backgroundColor: message.backgroundColor }}
            >
               {message.message}
            </MuiAlert>
         </Snackbar>

      <DocumentDrawer documentDrawerOpen={documentDrawerOpen} setDocumentDrawerOpen={setDocumentDrawerOpen} member={props.member} thisUser={thisUser} selectedDocument={selectedDocument}  setMessage={setMessage} message={message} />
    </Paper>
  );
}
