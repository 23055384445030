import axios from "axios";
import authService, { authHeader } from "./auth-service";

export class baseService
{
    async get(url) {
        const result = await axios.get(url, { headers: authHeader() });
        return result.data;
    };

    async post(url, data) {
        const result = await axios.post(url, data, { headers: authHeader() });
        return result.data;
    }

    async put(url, data) {
        const result = await axios.put(url, data, { headers: authHeader() });
        return result.data;
    }

    async delete(url) {
        const result = await axios.delete(url, { headers: authHeader() });
        return result.data;
    }
}

