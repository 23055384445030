import React, { useEffect, useState } from "react"
import { format } from "date-fns"

//Services

//Mui Components
import { Box, Button, Card, Chip, Paper, Typography } from "@mui/material"
import Alert from "@mui/material/Alert"

//Mui icons
import AccountBalanceIcon from "@mui/icons-material/AccountBalance"
import AccessTimeIcon from "@mui/icons-material/AccessTime"
import CheckIcon from "@mui/icons-material/Check"
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline"
import CloseIcon from "@mui/icons-material/Close"

//Our components
import EditWalletDrawer from "../../member-payments-tab/payment-drawers/EditWalletDrawer"
import NextPaymentChip from "../../../../DisplayChips/NextPaymentChip"
import DisabledPaymentScheduleChip from "../../../../DisplayChips/DisabledPaymentScheduleChip"

const componentStyles = {
  componentBody: {
    width: "100%",
    borderRadius: "0px",
    fontFamily: "Poppins",
    backgroundColor: "background.default",
    overflowY: "auto",
    paddingBottom: "16px",
    borderBottom: "1px solid rgba(217, 217, 217, 1)",
  },
  headerContainer: {
    padding: "16px 8px 0px 16px",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    alignItems: "top",
    margin: "0 auto",
  },
  title: {
    color: "text.primary",
    fontFamily: "Poppins",
    fontSize: "20px",
    fontWeight: "500",
  },
  description: {
    color: "text.secondary",
    fontFamily: "Archivo",
    fontSize: "14px",
    fontWeight: "400",
    paddingBottom: "6px",
  },
  paymentSchedule: {
    border: "background.border",
    backgroundColor: "background.default",
    //   marginLeft: "10px",
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    justifyContent: "left",
    alignItems: "top",
    fontFamily: "Poppins",
    paddingLeft: "16px",
  },
  nextPaymentChipContainer: {
    // marginLeft: "16px",
  },
  chipDesign: {
    backgroundColor: "background.paymentScheduleChip",
    color: "primary.paymentScheduleChip",
    height: "24px",
    fontWeight: "500",
    lineHeight: "18px",
    fontFamily: "Archivo",
    fontSize: "14px",
    borderRadius: "4px",
    marginRight: "5px",
  },
  accountCard: {
    padding: "8px 10px 8px 8px",
    width: "92%",
    maxWidth: "700px",
    minHeight: "96px",
    margin: "10px 16px 0px",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    alignItems: "center",
    fontFamily: "Poppins",
  },
  iconContainer: {
    display: "flex",
    flex: "0.5",
    margin: "0 auto",
  },
  accountInfo: {
    flex: "3",
    paddingLeft: "6px",
  },
  defaultOrMenuContainer: {
    fontFamily: "Archivo",
    fontSize: "15px",
    fontWeight: 500,
    flex: 1.25,
    justifyContent: "flex-end",
    marginRight: "20px",
  },
}
export default function MemberPlanPaymentSchedule(props) {
  
  //need - payment schedule data and logic AND wallet service data and logic

  const allowView = props.permissionsEnabler.viewScheduledPayments
  const memberOnPeakPay = props?.paymentSchedule[0]?.isPeakPay
  const memberHasActiveCoverage =props?.paymentSchedule[0]?.id > 0

  //PREVIOUS PAYMENET SCHEDULE LOGIC FOR DISPLAYING SINGLE PAYMENT SCHEDULE - HIDING FOR NOW AND ONLY SHOWING NEXT PAYMENT DATE CHIP OR DISABLED CHIP

  //determine Chip fill (monthly vs semi-monthly based on paymentFrequencyId)
  // let memberPaymentFrequency = props.paymentFrequencies?.find((id) => {
  //   //if member paymentFrequencyId = 1, frequency is monthly, if 2, semi-monthly
  //   return id.id === props.paymentSchedule.paymentFrequencyId;
  // });

  //list of payment dates (ex. [1, 15] would be 1st and 15th of month, add suffix for display)
  const getDaySuffix = (day) => {
    if (typeof day !== "number" || isNaN(day)) {
      return `No date(s) selected`
    }
    if (day >= 4 && day <= 20) {
      return `${day}th`
    }
    switch (day % 10) {
      case 1:
        return `${day}st`
      case 2:
        return `${day}nd`
      case 3:
        return `${day}rd`
      default:
        return `${day}th`
    }
  }

  let memberPaymentDates = props.paymentSchedule?.scheduledPaymentDates?.map(
    (date) => {
      return getDaySuffix(date.dayOfMonth)
    }
  )

  //account for UTC
  const formatDate = (rawDate) => {
    // console.log('formatDate',rawDate)
    // if (rawDate?.length < 11) rawDate = new Date(rawDate).toISOString();
    let [yyyy, mm, dd, hh, m, s] = rawDate.split(/[^\d]+/)
    let date = new Date()
    date.setUTCFullYear(+yyyy)
    date.setUTCDate(+dd)
    date.setUTCMonth(mm - 1)
    date.setUTCHours(+hh)
    date.setUTCMinutes(+m + date.getTimezoneOffset())
    date.setUTCSeconds(+s)
    // console.log('formatDate after',date)
    date = format(date, "MM/dd/yyyy")
    return date
  }

  //BANK ACCOUNT LOGIC

  //sort out accounts w/ prenoteStatusId=5 - ones that have deleted
  let filteredAccounts = props.bankAccount?.filter((account) => {
    //return accounts that do not have prenoteStatusId=5
    return account.prenoteStatusId !== 5
  })

  //sort bank accounts and display default account at top - displayed in edit wallet drawer
  let sortedAccounts = filteredAccounts?.sort((a, b) => {
    if (a.isPrimary) {
      return -1
    }
    if (b.isPrimary) {
      return 1
    } else return 0
  })

  // find primary bank account
  const [defaultAccount, setDefaultAccount] = useState()

  useEffect(() => {
    setDefaultAccount(props.bankAccount?.find((account) => account.isPrimary))
  }, [props.bankAccount])

  //bank account type name
  const getMemberAccountType = (accountId) => {
    let type = props.bankAccountTypes?.find((type) => {
      return type?.id === accountId
    })

    return type?.name.toLowerCase()
  }

  // Edit Wallet Drawer
  const [editWalletDrawerOpen, setEditWalletDrawerOpen] = useState(false)
  const handleOpenWalletDrawer = () => {
    console.log("Edit Wallet Opened")
    setEditWalletDrawerOpen(true)
  }
  // console.log(props.permissionsEnabler)

  const [permissions, setPermissions] = useState({
    viewWallet: false,
    editWallet: false,
    viewScheduledPayments: false,
  })
  useEffect(() => {
    if (props.permissionsEnabler) {
      setPermissions({
        viewWallet: props.permissionsEnabler.viewBankAccounts,
        editWallet: props.permissionsEnabler.editBankAccounts,
        viewScheduledPayments: props.permissionsEnabler.viewScheduledPayments,
      })
    }
  }, [props.permissionsEnabler])

  //logic to determine if the next payment chip should be displayed based on current payment schedules, dates,  if they are disabled/enabled, and if coverage is active (calculatedNextOccurrence < endPaymentDate)
  let displayNextPaymentChip =
    props?.paymentSchedule?.length > 0 &&
    ((props?.paymentSchedule[0]?.calculatedNextOccurrence &&
      props?.paymentSchedule[0]?.calculatedNextOccurrence <
        props?.paymentSchedule[0]?.endPaymentDate &&
      props?.paymentSchedule[0]?.isEnabled) ||
      (props?.paymentSchedule[1]?.calculatedNextOccurrence &&
        props?.paymentSchedule[1]?.calculatedNextOccurrence <
          props?.paymentSchedule[1]?.endPaymentDate &&
        props?.paymentSchedule[1]?.isEnabled) ||
      (props?.paymentSchedule[1]?.calculatedNextOccurrence &&
        props?.paymentSchedule[0]?.calculatedNextOccurrence <
          props?.paymentSchedule[0]?.endPaymentDate &&
        props?.paymentSchedule[1]?.calculatedNextOccurrence <
          props?.paymentSchedule[1]?.endPaymentDate))

  return (
    <Paper className="" elevation={0} sx={componentStyles.componentBody}>
      <Box sx={componentStyles.headerContainer}>
        <Box>
          <Typography variant="h6" sx={componentStyles.title}>
            Method
          </Typography>
        </Box>
        <Box>
          <Button
            disabled={!permissions.editWallet}
            fullWidth
            variant="contained"
            size="medium"
            onClick={handleOpenWalletDrawer}
            sx={{
              fontSize: 14,
              fontWeight: 400,
              height: "30px",
              borderColor: "primary.main",
              borderRadius: "8px",
            }}
          >
            EDIT
          </Button>
        </Box>
      </Box>

      {/* Payment Schedule */}
      {props?.paymentSchedule && props?.paymentSchedule?.length > 0 && (
        <Box sx={componentStyles.nextPaymentChipContainer}>
          {displayNextPaymentChip && props?.paymentSchedule[0]?.isPeakPay && (
            <NextPaymentChip paymentSchedule={props.paymentSchedule} />
          )}

          {/* Check if multiple payment schedules exist and if BOTH are disabled and member on PeakPay, show schedule disabled */}

          {!props?.paymentSchedule[0]?.isEnabled &&
            !props?.paymentSchedule[1]?.isEnabled &&
            props?.paymentSchedule[0]?.isPeakPay && (
              <DisabledPaymentScheduleChip />
            )}
        </Box>
      )}

      {permissions.viewWallet ? (
        <>
          {defaultAccount && (
            <Card
              key={defaultAccount.id}
              className="flex-row"
              elevation={0}
              variant="outlined"
              sx={{
                ...componentStyles.accountCard,
                border: "1px solid",
                borderColor:
                  defaultAccount.prenoteStatusId === 4
                    ? "rgba(211, 47, 47)"
                    : "background.border",
                backgroundColor:
                  defaultAccount.prenoteStatusId === 4
                    ? "rgba(211, 47, 47, 0.04)"
                    : "background.default",
              }}
            >
              {defaultAccount.prenoteStatusId === 4 ? (
                <Box style={componentStyles.iconContainer}>
                  <ErrorOutlineIcon
                    sx={{
                      color: "rgba(211, 47, 47)",
                      margin: "6px auto",
                    }}
                  />
                </Box>
              ) : (
                <Box style={componentStyles.iconContainer}>
                  <AccountBalanceIcon
                    sx={{
                      color: "primary.main",
                      margin: "6px auto",
                    }}
                  />
                </Box>
              )}

              {/* Account description OR Member name and Account Type */}
              <Box style={componentStyles.accountInfo}>
                <Typography
                  variant="body2"
                  sx={{ fontFamily: "Poppins", fontWeight: "500" }}
                >
                  {defaultAccount?.description
                    ? `${defaultAccount?.description} - `
                    : `${props?.member?.firstName}  ${props?.member?.lastName} - `}{" "}
                  {getMemberAccountType(defaultAccount.bankAccountTypeId)}{" "}
                  account
                </Typography>

                {/* Bank Information*/}
                <Typography variant="body2" sx={{ color: "text.secondary" }}>
                  {defaultAccount.bankName || "Account"} *
                  {defaultAccount.accountNumber?.slice(-4)}
                </Typography>
              </Box>
              <Box
                className="flex-row"
                sx={componentStyles.defaultOrMenuContainer}
              >
                {defaultAccount?.isPrimary && (
                  <>
                    <CheckIcon sx={{ color: "#4CAF50", marginRight: "5px" }} />
                    <Typography variant="body2">Default</Typography>
                  </>
                )}
              </Box>
            </Card>
          )}

          {/* default display if no bank account info exists */}
          {!defaultAccount && (
            <Card
              className="flex-row"
              elevation={0}
              variant="outlined"
              sx={componentStyles.accountCard}
            >
              <Typography variant="h6" sx={{ padding: "10px" }}>
                No Bank Information
              </Typography>
            </Card>
          )}

          {/* Show Failed Prenote Message if account has failed prenote */}
          {defaultAccount?.prenoteStatusId === 4 && (
            <Typography
              variant="body2"
              sx={{
                color: "rgba(211, 47, 47)",
                fontSize: "12px !important",
                marginLeft: "28px",
              }}
            >
              Failed Prenote
            </Typography>
          )}
        </>
      ) : (
        <Box sx={componentStyles.paymentSchedule}>
          <Alert
            sx={{ width: "95%", margin: "0 auto", marginTop: "10px" }}
            severity="error"
          >
            You do not have the required permissions to view bank accounts.
          </Alert>
        </Box>
      )}

      <EditWalletDrawer
        editWalletDrawerOpen={editWalletDrawerOpen}
        setEditWalletDrawerOpen={setEditWalletDrawerOpen}
        bankAccount={props.bankAccount}
        setBankAccount={props.setBankAccount}
        member={props.member}
        getBankAccount={props.getBankAccount}
        getMemberAccountType={getMemberAccountType}
        // bankInfoFromRouting={bankInfoFromRouting}
        defaultAccount={defaultAccount}
        setDefaultAccount={setDefaultAccount}
        editWalletFromPlans={props.editWalletFromPlans}
      />
    </Paper>
  )
}
