import React from "react"

//Mui components
import { useTheme } from "@mui/material"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import { PieChart, Pie } from "recharts"

export default function PlaceHolderReport(props) {
  const data = [
    { name: "A", value: 45 },
    { name: "B", value: 55 },
  ]
  let theme = useTheme()
  let isDarkMode = theme.palette.mode === "dark"
  let textColor = isDarkMode ? "rgba(255,255,255,1)" : "rgba(79,79,79,1)"
  let textShade = isDarkMode ?  "rgba(0,0,0,0.38)" : "rgba(255,255,255,0.6)"

  return (    
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        textAlign: "center",
        padding: "20px 0px",
        height: "100%",
        position: "relative",
      }}
    >
      <PieChart width={285} height={200} transform="rotate(45)">
        <Pie
          isAnimationActive={true}
          data={data}
          cx="50%"
          cy="50%"
          outerRadius={80}
          fill={isDarkMode ? "rgba(130,130,130,1)" : "rgba(189,189,189,1)"}
          dataKey="value"
          paddingAngle={1}
        />
      </PieChart>
      <Typography
        sx={{
          position: "absolute",
          padding: "5px 8px ",
          color: textColor,
          backgroundColor: textShade,
          textAlign: "center",
          alignItems: "center",
          zIndex: 25,
          top: "42%",
          fontSize: 12, 
          fontWeight: 400,
        }}
      >
        THERE IS CURRENTLY NO DATA TO SHOW
      </Typography>
    </Box>    
  )
}
