import React from "react"

//Mui cons
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"

//Mui Components
import Typography from "@mui/material/Typography"
import { Box } from "@mui/system"
import FormControl from "@mui/material/FormControl"
import InputLabel from "@mui/material/InputLabel"
import MenuItem from "@mui/material/MenuItem"
import Select from "@mui/material/Select"

//Our styles
import activeCoverageStyles from "../activeCoverageStyles"

export default function AccAddCanOrHospitalCoverage(props) {
  let {
    edit,
    plan,
    planOptions,
    selectedOption,
    UsdFormatterDec,
    handlePlanEdit,
  } = props

  return (
    <>
      {/* Benefit Amount Row */}
      {plan?.benefitAmount && plan?.benefitAmount > 0 ? (
        <Box sx={activeCoverageStyles.planInfoRow}>
          <Typography variant="body1">Benefit Amount</Typography>
          {/* Non-edit view */}
          {!edit && (
            <Typography variant="body1" sx={activeCoverageStyles.planInfoText}>
              {UsdFormatterDec.format(plan?.benefitAmount)}
            </Typography>
          )}

          {/* EDIT view corresponding benefit amount with any changes to covered lives*/}
          {edit && (
            <Typography variant="body1" sx={activeCoverageStyles.planInfoText}>
              {UsdFormatterDec.format(selectedOption?.benefitAmount)}
            </Typography>
          )}
        </Box>
      ) : null}

      {/* Covered Lives - show for accident, add, hospital, and cancer plans*/}
      {plan?.personType ? (
        <Box sx={activeCoverageStyles.planInfoRow}>
          <Typography variant="body1">Covered Lives</Typography>
          {!edit && (
            <Typography variant="body1" sx={activeCoverageStyles.planInfoText}>
              {plan?.personType === 0 ? "Member" : plan?.personType}
            </Typography>
          )}

          {/* edit covered lives */}
          {edit && (
            <Box sx={activeCoverageStyles.editInputContainer}>
              <FormControl fullWidth sx={{ marginBottom: "10px" }}>
                <InputLabel id="coveredLives">Covered Lives</InputLabel>
                <Select
                  variant="outlined"
                  size="small"
                  fullWidth
                  label="Covered Lives"
                  labelId="coveredLives"
                  sx={activeCoverageStyles.selectDropDown}
                  IconComponent={() => (
                    <KeyboardArrowDownIcon
                      sx={activeCoverageStyles.selectDownArrowIcon}
                    />
                  )}
                  value={planOptions.selectedOptionId}
                  onChange={(e) => handlePlanEdit(e, "accAddCanOrHosp")}
                >
                  {planOptions &&
                    planOptions?.options?.sort((a, b) => a.displayOrder - b.displayOrder).map((option) => {
                        if (!option.isDecline) {
                          let itemList = []
                          if (option.memberPremiumAmount > 0)
                            itemList.push(
                              <MenuItem
                                value={option.memberOptionId}
                                key={option.memberOptionId}
                              >
                                Member{" "}
                                {/* show benefit name if ADD coverage only */}
                                {option?.explanationOfBenefits === 'AD&D Coverage' ? ` (${option?.name}) ` : ''} 
                                {props.UsdFormatterDec.format(
                                  option?.memberPremiumAmount || 0
                                )}
                              </MenuItem>
                            )
                          if (option.memberAndSpousePremiumAmount > 0)
                            itemList.push(
                              <MenuItem
                                value={option.memberAndSpouseOptionId}
                                key={option.memberAndSpouseOptionId}
                              >
                                Member and Spouse{" "}
                                {option?.explanationOfBenefits === 'AD&D Coverage' ? ` (${option?.name}) ` : ''} 
                                {props.UsdFormatterDec.format(
                                  option?.memberAndSpousePremiumAmount || 0
                                )}
                              </MenuItem>
                            )
                          if (option.memberAndDependentsPremiumAmount > 0)
                            itemList.push(
                              <MenuItem
                                value={option.memberAndDependentsOptionId}
                                key={option.memberAndDependentsOptionId}
                              >
                                Member and Dependent{" "}
                                {option?.explanationOfBenefits === 'AD&D Coverage' ? ` (${option?.name}) ` : ''} 
                                {props.UsdFormatterDec.format(
                                  option?.memberAndDependentsPremiumAmount || 0
                                )}
                              </MenuItem>
                            )
                          if (option.familyPremiumAmount > 0)
                            itemList.push(
                              <MenuItem
                                value={option.familyOptionId}
                                key={option.familyOptionId}
                              >
                                Family{" "}
                                {option?.explanationOfBenefits === 'AD&D Coverage' ? ` (${option?.name}) ` : ''} 
                                {props.UsdFormatterDec.format(
                                  option?.familyPremiumAmount || 0
                                )}
                              </MenuItem>
                            )
                          return itemList.map((item) => item)
                        } else {
                          return (
                            <MenuItem
                              value={option.memberOptionId}
                              key={option.memberOptionId}
                            >
                              Decline
                            </MenuItem>
                          )
                        }
                      })}
                </Select>
              </FormControl>
            </Box>
          )}
        </Box>
      ) : null}
    </>
  )
}
