const reportingDashboardStyles = {
  individualReportInfinityLoader: {
    position: "absolute",
    zIndex: 5,
    width: "100%",
    height: "100%",
    backgroundColor: "#00000001",
    backdropFilter: "blur(6px)",
  },
  smallReportContainer: {
    position: "relative",
    minHeight: "250px",
  },
}

export default reportingDashboardStyles
