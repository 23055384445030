import './notfound.css';

import React from "react";
import { useHistory } from 'react-router-dom'

//Assets
import NotFoundAliens from '../../assets/images/404Aliens.svg';

//Services
import authService from '../../services/auth-service';

//Our Components
import DashTabTopBar from '../app-external/external-dashboard/dashtab-topbar/DashTabTopBar'


// Mui
import { Divider, Link, Button, Typography, Box } from "@mui/material";



function NotFound() {

    let isAuthenticated = authService.authenticated;
    console.log(isAuthenticated)

    let history = useHistory();

    const handleBackClick = () => {
        console.log("hello")
        history.goBack();
    }


    return (
        // <React.Fragment>
        //     <div type="button" onClick={hello}>
        //         <DashTabTopBar />
        //     </div>
        //     <h1 style={{
        //         textAlign: "center",
        //         fontSize: "24px",
        //         fontWeight: "700",
        //         marginBottom: "40px"
        //     }}>
        //         404
        //     </h1>
        //     <h1 style={{
        //         textAlign: "center",
        //         fontSize: "36px",
        //         fontWeight: "700",
        //         color: "#80CBC4",
        //         marginBottom: "50px"
        //     }}>
        //         Oops!
        //     </h1>
        //     <img src={NotFoundAliens} alt="Not Found Aliens" style={{ display: "block", margin: "auto", }} />
        //     <div className="notfound-blurb"
        //         style={{
        //             marginTop: "30px",
        //             fontSize: "14px",
        //             fontWeight: "500"
        //         }}
        //     >
        //         <p>This is not the right page. <br></br>Head on back to the dashboard.</p>
        //     </div>
        // </React.Fragment>
        <Box className="flex-container" id='enrollment-container'  style={{ height: '100vh'}} sx={{
            bgcolor: 'background.default',
            color: 'text.primary'
         }}>
                     <div className='row' style={{maxWidth: '600px', margin: 'auto', width: '100%', padding:'40px', maxHeight: '600px'}}>
            <div className='flex-row' style={{marginBottom:'15px'}}>
                <Typography variant='blueAccent'>404 Error</Typography>
            </div>
            <Divider style={{marginBottom:'15px'}} />
            <div className='flex-row' style={{marginBottom:'15px'}}>
                <Typography variant='subtitle1'>We can't find that page</Typography>
            </div>
            <div className='flex-row' style={{marginBottom:'15px'}}>
                <Typography variant='body1'>Sorry, the page you are looking for doesn’t exist or has been moved. </Typography>
            </div>
            <div className='flex-row'>
                <Button variant='contained' sx={{height:50}} fullWidth onClick={handleBackClick}>Go Back</Button>
            </div>
            </div>
        </Box>
    )
}

export default NotFound