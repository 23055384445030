import React, {
  useEffect,
  useLayoutEffect,
  useState,
  useContext,
  Suspense,
  lazy,
} from 'react';
import { useHistory } from 'react-router';

//Assets
import defaultLogo from '../../../../assets/images/FullColorOnlyLogo.png';

//Muicons

//MuiComponents
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

//Services
import enrollmentService from '../../../../services/enrollment-service';

//Our Components
import InfinityLoader from '../../../UH-loading-animation/InfinityLoader';

import PortalPaymentSummary from '../portal-home/PortalPaymentSummary';

//utils
import PortalBankAccounts from './PortalBankAccounts';

export default function PortalPayment(props) {
  const [thisMember, setThisMember] = useState(props?.thisMember);

  const [enrollments, setEnrollments] = useState(props?.enrollments);

  const [loading, setLoading] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  // useEffect(() => {
  //    setLoading(true);
  //    setTimeout(() => {
  //       setLoading(false);
  //    }, 2000);
  //    // return () => {
  //    //    setLoading(false);
  //    // }
  // }, [anchorEl]);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [mostRecentEnrollment, setMostRecentEnrollment] = useState(
    props.mostRecentEnrollment
  );
  const [mostRecentEnrollmentCommLabels, setMostRecentEnrollmentCommLabels] =
    useState(props.mostRecentEnrollmentCommLabels);

  const getMemberAccountType = (accountId) => {
    let type = props.bankAccountTypes?.find((type) => {
      return type?.id === accountId;
    });
    return type?.name.toLowerCase();
  };

  return (
    <>
      {loading && (
        <>
          <InfinityLoader
            style={{
              position: 'absolute',
              zIndex: 5,
              width: '100%',
              height: '100vh',
              backgroundColor: '#00000050',
              backdropFilter: 'blur(10px)',
            }}
          />
          <style>
            {`
                 div.lottie-div > svg {
                   height:55px!important;
                   position:absolute;
                   top:50%;
                 }
             `}
          </style>
        </>
      )}
      <Box
        className="flex-container"
        flex={1}
        style={{
          flexWrap: 'wrap',
          margin: 'auto',
        }}
        sx={{
          bgcolor: 'background.default',
          color: 'text.primary',
          paddingTop: '64px',
          height: '100%',
          alignContent: 'flex-start',
        }}
      >
        <Box
          style={{
            maxWidth: '575px',
            margin: '0 auto',
            height: '100%',
          }}
        >
          <Box
            className="col-12"
            sx={{
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignContent: 'flex-start',
              flex: 1,
              flexWrap: 'wrap',
              backgroundColor: '#fafafa',
              gap: '6px',
            }}
          >
            <Box sx={{ width: '100%', padding: '0px 14px', paddingTop: '6px' }}>
              <Typography variant="h4">Payments</Typography>
              <Typography variant="h6">Overview of your costs</Typography>
            </Box>
            <Box
              sx={{
                width: '100%',
                padding: '16px',
                backgroundColor: 'white',
                border: '1px #E0E0E0 solid',
              }}
            >
              <PortalPaymentSummary
                payment={props.payment}
                enrollment={mostRecentEnrollment}
                onPaymentPage={true}
              />
            </Box>

            <Box sx={{ width: '100%', padding: '0px 14px' }}>
              <Typography variant="h6">Information</Typography>
            </Box>

            <Box
              sx={{
                width: '100%',
                padding: '8px 16px',
                backgroundColor: 'white',
                border: '1px #E0E0E0 solid',
              }}
            >
              <PortalBankAccounts
                bankAccount={[props?.bank]}
                payment={props.payment}
                member={thisMember}
                getMemberAccountType={getMemberAccountType}
                editWalletFromPlans={props?.editWalletFromPlans}
                permissionsEnabler={props?.permissionsEnabler}
                paymentScheduleTypes={props.paymentScheduleTypes}
              />
            </Box>
          </Box>
        </Box>
      </Box>

      {/* {!isAuthenticated && history.push('/login')} */}
    </>
  );
}
