import React, { useState, useEffect, useLayoutEffect } from 'react';
import {useHistory} from 'react-router-dom'
// import './StandaloneRateCalculatorMCL.css'

import WebFont from 'webfontloader'


//Assets
import mclLogo from '../../assets/images/MCL.png'

//Services
import calculatorService from '../../services/calculator-service';

//MuiIcons
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

//MuiComponents
import TextField from '@mui/material/TextField';
import Collapse from '@mui/material/Collapse';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';

//MuiX
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';

//Our components



export default function StandaloneRateCalculatorMCL(props) {
    //Plan info displayed to user
    const [results, setResults] = useState(null)
    useEffect(async () =>{
        //This is providing our dummy data with available plans
        let mockInfo = {
            groupPlanId:3,
            Salary: 80000,
            DOB:"1984-08-10T18:25:43-05:00"
        }
        let _results = await calculatorService.calculateRates(mockInfo);
        setResults(_results)
    },[])

    //Loading these here both for dynamic font, and this is the alternative to a font download in index.html
    useLayoutEffect(() => {
        WebFont.load({
            google: {
                families: ['Archivo:300,400,500,600,700',"Roboto"]
            }
        })
        
    },[])

    //This is swapping out the ugly Archivo ampersands
    useEffect(()=>{
        const planOptionNames = document.getElementsByClassName("plan-option-title");

        for (const span of planOptionNames) {
            span.innerHTML = span.innerHTML.replace(/&/g,"<span style='font-family: Roboto, sans-serif'>&</span>").replace(/amp;/g,"")
        }
        console.log(results)
        console.log(window.innerWidth)
        console.log(document.documentElement.clientWidth)
    },[results])
    
    //Currency formatting
    let UsdFormatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits:0 });
    let UsdFormatterDec = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits:2 });
    

    //User info state that we'll send to back
    const [userInfo,setUserInfo] = useState({
        GroupPlanId: 3,
        Salary: 0,
        DOB: "",
    })

    //Salary handler, dynamic error styling
    const handleSalaryChange = (e) => {
        let newValue = e.target.value;
        console.log(newValue)
        if (newValue !== "" && newValue !== NaN && newValue !== "$Na" && newValue !== "$") {
            newValue = newValue.replace("$", "").replaceAll(",", "")
            newValue = parseInt(newValue)
        } else if (newValue == "" || newValue == NaN || newValue=="$Na" || newValue == "$") {
            newValue = 0;
        }
        
        setUserInfo({
            ...userInfo,
            Salary: newValue
        })
    }
    const [salaryErrorMsg,setSalaryErrorMsg] = useState()
    let salaryErrorBorder = salaryErrorMsg ? "solid red" : ""
    const salaryBlur = () => {
        if (userInfo.Salary > results.maximumSalary){
            setSalaryErrorMsg("")
        }
        else if (userInfo.Salary < results.minimumSalary){
            setSalaryErrorMsg("Salary must be greater than " + results.minimumSalary)
        }
        else setSalaryErrorMsg()
    }
    //Dob handler (desktop & mobile), dynamic error styling
    const [dobErrorMsg,setDobErrorMsg] = useState()
    let dobErrorBorder = dobErrorMsg ? "solid red" : ""
    const handleDobChangeMobile = (e) => {
        console.log(e)
        let dateWithinRange = ((new Date(e).getFullYear() >= new Date(dateMin).getFullYear()) && (new Date(e).getFullYear() <= new Date(dateMax).getFullYear()))
        let digitCheck = e?.getFullYear() > 1000
        console.log(digitCheck)
        if (dateWithinRange) {
            setUserInfo({
                ...userInfo,
                DOB: e
            })
            setDobErrorMsg()
        } 
        else if (digitCheck) setDobErrorMsg("Date not in range")
    }
    const handleDobChange = (e) => {
        let newValue = e.target.value;
        let dateWithinRange = ((new Date(newValue).getFullYear() >= new Date(dateMin).getFullYear()) && (new Date(newValue).getFullYear() <= new Date(dateMax).getFullYear()))
        if (dateWithinRange){
            setUserInfo({
                ...userInfo,
                DOB: newValue
            })
            setDobErrorMsg()
        } 
        else {
            setUserInfo({
                ...userInfo,
                DOB: newValue
            })
            setDobErrorMsg("Please select a valid date")
        } 
            
    }
    


    //Displaying greyed out on load
    const [dummyDisplay,setDummyDisplay] = useState(true)
    let dummyFontColor = dummyDisplay ? "#00000033" : "black"

    //////Plan variables
    //STD
    let stdPlan = results?.stdPlan;
    let stdHelpText = stdPlan?.helpText.split(`\r\n`);
    // LTD
    let ltdPlan = results?.ltdPlan;
    let ltdHelptext = ltdPlan?.helpText.split('\n');
    // ACCIDENT
    // CI
    // HOSPITAL
    // LADD
    // Ladd gets results passed to it directly
    // console.log(lifeADDPlan)
    

    //Collapse logic, dynamic buttons, Ladd handles it's own collapse currently
    const [expanded, setExpanded] = useState({
        std: false,
        ltd: false,
        accident: false,
        ci: false,
        hospital: false,
    });
    const handleExpandClick = (key) => {
        setExpanded({
            ...expanded,
            [key]: !expanded[key]
        })
    };
    let stdExpandIcon = expanded.std ? <RemoveCircleIcon fontSize="large" sx={{position:"absolute", right:"14%", top:"20%",color:"#195ffb"}} /> : <AddCircleIcon fontSize="large" sx={{position:"absolute", right:"14%", top:"20%",color:"#195ffb"}} />;
    let ltdExpandIcon = expanded.ltd ? <RemoveCircleIcon fontSize="large" sx={{position:"absolute", right:"14%", top:"20%",color:"#195ffb"}} /> : <AddCircleIcon fontSize="large" sx={{position:"absolute", right:"14%", top:"20%",color:"#195ffb"}} />;

    //
    const goToEnroll = () => {
        // window.location.href = results.enrollmentURL;
        // window.open(results.enrollmentURL)
    }

    const handleCalculateClick = () => {
        setDummyDisplay(false)
        calculateNewRates()
    }
    const calculateNewRates = async() => {
        let _userInfo = {
            GroupPlanId: 3,
            Salary: Math.min(userInfo.Salary,400000), 
            DOB: new Date(userInfo.DOB),
        }
        // _userInfo.DOB += "T00:00:00"
        let _results = await calculatorService.calculateRates(_userInfo)
        setResults(_results)
    }
    

    ////////////Dynamic variables

    //Date logic
    let today = new Date();
    let todayMilli = today.getTime();
    
    let openEnrollmentEnd = results ? results.openEnrollmentEnd : null
    let openEnrollmentEndMilli = null

    if (openEnrollmentEnd) {
        openEnrollmentEndMilli = new Date(openEnrollmentEnd).getTime()
        openEnrollmentEnd = new Date(openEnrollmentEnd).toLocaleDateString()
    }
    
    let enrollmentDaysLeft = results ? Math.round((openEnrollmentEndMilli - todayMilli) / (1000*60*60*24)) : null;

    let openEnrollmentStart = results ? results.openEnrollmentStart : null
    if (openEnrollmentStart) {
        today = new Date(openEnrollmentStart)
        openEnrollmentStart = new Date(openEnrollmentStart).toLocaleDateString()
    }

    let day = today.getDate();
    let month = today.getMonth() + 1;
    let year = today.getFullYear();
    day = day < 10 ? '0' + day : day;
    month = month < 10 ? '0' + month : month;
    let dateMax = `${year - 18}-${month}-${day}`;
    let dateMin = `${year - 100}-${month}-${day}`;

    //Dynamic style
    let salaryFontColor = userInfo.Salary == 0 ? "#195ffb54" : "#195ffb";
    let dobFontColor = userInfo.DOB == "" ? "#195ffb54" : "#195ffb";


    

    

    return (

        <>
        <div className='desktop-width-limiter'>
        <div className='flex-container row rate-calculator-container'>
            <div className='calculator-topbar' >
                <h1 className='calculator-header'>Benefit Calculator</h1>
                <div className='header-info'>
                    <span >Enter your annual earnings and date of birth to see your monthly costs.</span>
                </div>
                <img className='calculator-logo-img' src={mclLogo} width="85px" alt='union logo'/>
            </div>
            <div className='enrollment-countdown-date col-6'>
                <h6>ENROLLMENT ENDS:</h6>
                <span>{(results && openEnrollmentEnd) || '--/--/----'}</span>
            </div>
            <div className='enrollment-countdown col-6'>
                <div style={{lineHeight:"3.2rem"}}>{(results && enrollmentDaysLeft) || "0"}</div>
                <span >days left</span>
            </div>
        </div>
        <hr />
        <div className='row'>
            <div className='container user-input-container' >
                <CardContent className='row' sx={{paddingBottom:"0px"}}>
                    <div className='col-6' style={{fontSize:"14px", lineHeight:"15px"}}>
                        Estimated annual earnings
                    </div>
                    <div className='col-6' style={{fontSize:"14px", lineHeight:"15px", padding:"0px", alignSelf:"flex-end"}}>
                        <div style={{paddingLeft:"8%"}}>Date of Birth</div>
                    </div>
                </CardContent>
                <CardContent className='row' sx={{paddingTop:"5px"}}>
                    <div className='col-6' style={{paddingRight:"0px"}}>
                        <TextField onChange={handleSalaryChange} onBlur={salaryBlur}

                        id="SalaryUpdater" className='filled-text' 
                        variant='filled' hiddenLabel size='small'
                        helperText={salaryErrorMsg} error={salaryErrorMsg}
                        value={UsdFormatter.format(userInfo.Salary)}

                        sx={{width:"92%"}}
                        InputProps={{
                            inputProps: {
                                style: { textAlign: "center" },
                                "aria-label": "Salary"
                            },
                            disableUnderline: true,
                            sx: {
                                backgroundColor: "#EFF5F9", 
                                color: salaryFontColor, 
                                fontWeight: 600,
                                borderRadius:"5px",
                                border: salaryErrorBorder,
                                height:"45px"
                            } 
                        }} 
                        />
                    </div>
                    <div className='col-6 mobile-picker' style={{paddingLeft:"0px"}}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker 
                            // ToolbarComponent={() => <PickersToolbar onClick={(e) => console.log(e)} />}
                            // inputFormat='mm/dd/yyyy'
                            // disableOpenPicker
                            // open={false}
                            disableFuture
                            openTo='year'
                            views={['year','month','day']}
                            value={userInfo.DOB} onChange={handleDobChangeMobile}
                            
                            renderInput={({...params}) =>
                            <TextField {...params} id="DOBUpdater" className='filled-text' 
                            variant='filled' hiddenLabel size='small' 
                            // value={userInfo.DOB} onChange={handleDobChange} 
                            placeholder="mm/dd/yyyy"
                            // type="date"
                            helperText={dobErrorMsg} error={dobErrorMsg}
                            sx={{width:"92%", float:"right"}}
                            InputProps={{
                                        disableUnderline: true,
                                        sx: {
                                            backgroundColor: "#EFF5F9", 
                                            color: dobFontColor,
                                            fontWeight: 600,
                                            borderRadius:"5px",
                                            border:dobErrorBorder,
                                            height:"45px"
                                        },
                                    }}
                                    />
                                }
                                />
                        </LocalizationProvider>
                        {/* <DateMaskInput userInfo={userInfo} setUserInfo={setUserInfo} 
                        dobErrorMsg={dobErrorMsg} setDobErrorMsg={setDobErrorMsg}
                        dobFontColor={dobFontColor} dobErrorBorder={dobErrorBorder} 
                    /> */}
                    </div>
                    <div className='col-6 desktop-picker' style={{paddingLeft:"0px"}}>
                       
                        <TextField id="DOBUpdater" className='filled-text' 
                            variant='filled' hiddenLabel size='small' 
                            value={userInfo.DOB} onChange={handleDobChange} 
                            placeholder="mm/dd/yyyy"
                            helperText={dobErrorMsg} error={dobErrorMsg}
                            type="date"
                            sx={{width:"92%", float:"right"}}
                            InputProps={{
                                inputProps: {
                                    min: dateMin, 
                                    max: dateMax,
                                    style: {textAlign:"center"},
                                },
                                disableUnderline: true,
                                sx: {
                                    backgroundColor: "#EFF5F9", 
                                    color: dobFontColor,
                                    fontWeight: 600,
                                    borderRadius:"5px",
                                    border: dobErrorBorder,
                                    height:"45px"
                                },
                            }}
                            />
                    </div>
                </CardContent>
                <CardContent className='row' sx={{paddingTop:"0px"}}>
                    <div className='col-12'>
                        <Button onClick={handleCalculateClick} className="calculate-btn"
                            sx={{display:"block", margin: "auto", borderRadius:"18px", width:"100%",height:"45px", fontWeight:700, letterSpacing:".65px"}} 
                            variant='contained' size="large" disabled={userInfo.Salary==0||userInfo.DOB==""||salaryErrorMsg}>
                                Calculate
                        </Button>
                    </div>
                </CardContent>
            </div>
        </div>
        <div>
            {/* STD */}
            {results?.stdPlan &&
            <div style={{marginBottom:"40px"}}>
                <div className="" style={{backgroundColor:"#1B78AF12", minHeight:"57px",fontSize:"17px",fontWeight:700, padding:"16px 38px 17px 30px", position:"relative"}}>
                    <span className='plan-option-title' style={{cursor:"pointer", position:"relative"}} onClick={() => handleExpandClick("std")}>
                        {results.stdPlan.title}<div style={{position:"absolute",width:"100%",height:"300%", top:"-100%"}}></div>
                    </span>
                    <div onClick={() => handleExpandClick("std")} style={{position:"absolute", right:"3%", top:"0%",color:"#195ffb", width:"18%", height:"100%", cursor:"pointer"}}>
                        {stdExpandIcon}
                    </div>
                </div>
                <div style={{padding:"0px 8%"}}>
                <Collapse sx={{marginBottom:"31px"}} in={expanded.std}>
                    <ul>
                        {stdHelpText.map(text => {
                            if (text.includes("<b>")){
                                return <li className='std-helptext' key={text}><span dangerouslySetInnerHTML={{__html: text}}></span></li>
                            }
                            return <li className='std-helptext' key={text}><span>{text}</span></li>
                        })}
                    </ul>
                    <hr style={{margin:"0px -10%"}}/>
                </Collapse>
                </div>
                {/* HANDLING UNLOADED STATE, ONE OF MANY WAYS */}
                {/* COULD ALSO USE OPTIONAL CHAIN results?.stdPlan */}
                {!results && <h1>not loaded</h1>}
                {results && stdPlan.options.map((option,i) => {
                    if (!option.isDecline){
                        return (
                            <>
                            <div key={option.memberPremiumAmount} className='flex-row container option-container' >
                                <div className='' style={{fontSize:"16px"}} >
                                    <span style={{fontSize:"28px", fontWeight:600, color:"#195ffb"}}>
                                        {option.name.replace(" Weekly Benefit","")} 
                                        </span>
                                        {i < 3 && <span className='mobile-md-benefit-schedule'> weekly benefit for 24 wks</span>}
                                        {i < 3 && <p className='mobile-sm-benefit-schedule'>weekly benefit for 24 wks</p>}
                                        {i > 2 && <span className='mobile-md-benefit-schedule'> weekly benefit for 50 wks</span>}
                                        {i > 2 && <p className='mobile-sm-benefit-schedule'>weekly benefit for 50 wks</p>}
                                </div>
                                <div className='monthly-cost'>
                                    <span style={{fontSize:"18px", fontWeight:500, color:dummyFontColor,position:"absolute", bottom:"1vh", right:"5vw"}}>{!dummyDisplay && UsdFormatterDec.format(option.memberPremiumAmount)}{dummyDisplay && "$0.00"} </span> 
                                </div>
                            </div>

                            <div className='flex-row container helptext-container' >
                                <Collapse sx={{marginBottom:"20px"}} in={expanded.std}>
                                    <ul>
                                        <li className='std-helptext' key={option.explanationOfBenefits}><span>{option.explanationOfBenefits}</span></li>
                                        {/* <li className='std-helptext' key={option.benefitOffsets}><span>{option.benefitOffsets}</span></li> */}
                                    </ul>
                                </Collapse>

                            </div>
                            </>
                        )
                    }
                })}
            </div>
            }
        </div>
        <hr />
        <CardContent>
            <Button 
                onClick={goToEnroll} 
                className="calculate-btn" 
                sx={{display:"block", margin: "auto", marginTop:"26px", marginBottom:"30px", borderRadius:"18px", width:"100%", fontWeight:700, letterSpacing:"1.3px", fontSize:"16px"}} 
                variant='contained' size='large'
                >
                    ENROLL NOW
            </Button>
        </CardContent>
        
        
        {/* THIS IS HERE FOR SPACING IN DEVTOOLS */}
        {/* <div style={{height:"400px"}}>

</div> */}
        </div>
        </>
        )
    }