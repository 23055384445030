import './topbar-back.css';
import {useHistory} from "react-router-dom";
import ArrowBackIosNewTwoToneIcon from '@mui/icons-material/ArrowBackIosNewTwoTone';

function TopbarBack(){
    const history = useHistory();

    return(
        <div type="button" className="topbar-back" onClick={(e) => { history.goBack(); e.preventDefault(); }}>
            <ArrowBackIosNewTwoToneIcon />
            <a href="" >Back</a>
        </div>
    )
}

export default TopbarBack;