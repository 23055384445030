import React from "react";

import {
  PieChart,
  Pie,
  Cell,
  LabelList,
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Bar,
  Label,
  ResponsiveContainer,
  Legend,
  Tooltip,
  PolarAngleAxis,
  RadialBarChart,
  RadialBar,
} from "recharts";

//Mui components
import { Card, CardHeader, Slide } from "@mui/material";
import { Tooltip as MuiTooltip } from "@mui/material/";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import MobileStepper from "@mui/material/MobileStepper";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

//Mui icons
import InfoIcon from "@mui/icons-material/Info";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";

export default function EnrollmentsByCoverage(props) {
  const { theme, coverageType } = props;

  const CustomTooltip = ({ active, payload, label }) => {
    // console.log('CustomTooltip', active, payload, label)
    if (active && payload && payload.length) {
      return (
        <Paper sx={{ padding: "20px" }}>
          <p className="label">{`${label}`}</p>
          <p>{`${props?.formatNumWithCommas(payload[0]?.payload?.count)}`} Enrollment(s)</p>
        </Paper>
      );
    }
    return null;
  };

  const renderBarShape = (props) => {
    console.log("renderBarShape", props);
    const getPath = (x, y, width, height) => {
      return `M${x},${y}
         v${height}
         h${width - 4}
         a4,4 0 0 0 4,-4
         v${-height + 8}
         a4,4 0 0 0 -4,-4
         h${-width}
         z
         `;
    };
    return (
      <path
        {...props}
        d={getPath(props.x, props.y, props.width, props.height)}
        stroke={props.stroke}
        fill={props.fill}
        strokeLinejoin="round"
        strokeWidth={"3px"}
        strokeLinecap="round"
      />
    );
  };

  return (
    <>
      {coverageType?.length > 0 ? (
        <ResponsiveContainer minWidth={600} minHeight={300}>
          <BarChart
            label={{ fill: theme?.palette?.text?.primary }}
            layout="vertical"
            data={coverageType}
            height={"100%"}
            maxBarSize={200}
            margin={{ top: 60, bottom: 40, left: 40, right: 55 }}
          >
            {/* <CartesianGrid strokeDasharray={'3'} /> */}
            <XAxis
              height={50}
              type="number"
              dataKey={"count"}
              interval={1}
              //  tickCount={6}
              label={{
                value: "Submitted Enrollments",
                angle: 0,
                position: "bottom",
                fill: theme?.palette?.text?.primary,
                offset: "2",
              }}
            ></XAxis>
            <YAxis
              width={180}
              type="category"
              dataKey={"lineOfCoverage"}
              tick={{ fill: theme?.palette?.text?.primary }}
            ></YAxis>
            <Tooltip
              position={{ x: 600 }}
              isAnimationActive={false}
              cursor={{ fill: "transparent" }}
              content={<CustomTooltip />}
            />
            <Bar
              dataKey={"count"}
              fill={theme?.palette?.primary?.main}
              shape={renderBarShape}
            >
              <LabelList
                dataKey="count"
                position="insideRight"
                content={(props) => {
                  if (props.value == 0) return null;
                  return (
                    <g>
                      <text
                        x={props.x + props.width - 14}
                        y={props.y + props.height / 2}
                        style={{
                          stroke: "#FFF",
                          fill: "#FFF",
                          fontSize: "14px",
                          fontFamily: "Poppins",
                          fontWeight: 500,
                        }}
                        textAnchor="end"
                        dominantBaseline={"middle"}
                      >
                        {props.value}
                      </text>
                    </g>
                  );
                }}
              />
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      ) : (
        <Typography
          variant="body2"
          sx={{
            textAlign: "center",
            marginTop: "50px",
            fontSize: "16px!important",
            color: "#4F4F4F",
          }}
        >
          No enrollment data found.
        </Typography>
      )}
    </>
  );
}
