import React from "react";






export default function TestPage(props) {

   function iOS() {
      return [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ].includes(navigator.platform)
      // iPad on iOS 13 detection
      || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    }
    
    const isIOS = iOS();
   return (
      <div>
         <h1><div style={{fontSize:'25px',fontWeight:'600'}}>IOS? {isIOS.toString()}</div></h1>
         <h1><div style={{fontSize:'25px',fontWeight:'600'}}>UserAgent: {navigator.userAgent}</div></h1>
         <h1><div style={{fontSize:'25px',fontWeight:'600'}}>Platform: {navigator.platform}</div></h1>
         <h1><div style={{fontSize:'25px',fontWeight:'600'}}>innerWidth: {window.innerWidth}</div></h1>
         <h1><div style={{fontSize:'25px',fontWeight:'600'}}>outerWidth: {window.outerWidth}</div></h1>
         <h1><div style={{fontSize:'25px',fontWeight:'600'}}>innerHeight: {window.innerHeight}</div></h1>
         <h1><div style={{fontSize:'25px',fontWeight:'600'}}>outerHeight: {window.outerHeight}</div></h1>
         <h1><div style={{fontSize:'25px',fontWeight:'600'}}>screen.width: {window.screen.width}</div></h1>
         <h1><div style={{fontSize:'25px',fontWeight:'600'}}>screen.height: {window.screen.height}</div></h1>
         <h1><div style={{fontSize:'25px',fontWeight:'600'}}>screen.availWidth: {window.screen.availWidth}</div></h1>
         <h1><div style={{fontSize:'25px',fontWeight:'600'}}>screen.availHeight: {window.screen.availHeight}</div></h1>
         {/* <h1><div style={{fontSize:'25px',fontWeight:'600'}}>screen.orientation: {window.screen.orientation}</div></h1> */}
         <h1><div style={{fontSize:'25px',fontWeight:'600'}}>screen.orientation.type: {window.screen.orientation.type}</div></h1>
         <h1><div style={{fontSize:'25px',fontWeight:'600'}}>screen.orientation.angle: {window.screen.orientation.angle}</div></h1>
         {/* <h1><div style={{fontSize:'25px',fontWeight:'600'}}>screen.orientation.lock: {window.screen.orientation.lock}</div></h1> */}
         {/* <h1><div style={{fontSize:'25px',fontWeight:'600'}}>screen.orientation.unlock: {window.screen.orientation.unlock}</div></h1> */}
         
      </div>
   );
}