import React, { useState } from 'react';

//MUI Components
import { Box, display } from '@mui/system';

//MUI Icons
import VisibilityIcon from '@mui/icons-material/Visibility';
import NoVisibilityIcon from '@mui/icons-material/VisibilityOff';

//Component Styles

const componentStyles = {
  visibilityIconContainer: {
    position: 'absolute',
    right: '5%',
  },
  visibilityIcon: {
    color: '#7D7987',
    cursor: 'pointer',
  },
};

const PasswordVisibilityIcon = (props) => {
  //Password Visibility
  const [displayPassword, setDisplayPassword] = useState(false);

  const swapIcon = (e) => {
    //e.preventDefault();
    let inputElement = e.target.parentNode.children[0];
    if (displayPassword) {
      inputElement.setAttribute('type', 'password');
      setDisplayPassword(false);
    } else {
      inputElement.setAttribute('type', 'text');
      setDisplayPassword(true);
    }
  };

  return (
    <>
      {displayPassword ? (
        <Box
          sx={componentStyles.visibilityIconContainer}
          type="button"
          onClick={swapIcon}
        >
          <NoVisibilityIcon sx={componentStyles.visibilityIcon} />
        </Box>
      ) : (
        <Box
          sx={componentStyles.visibilityIconContainer}
          type="button"
          onMouseDown={swapIcon}
          onMouseUp={swapIcon}
          onTouchStart={swapIcon}
          onTouchEnd={swapIcon}
        >
          <VisibilityIcon sx={componentStyles.visibilityIcon} />
        </Box>
      )}
    </>
  );
};

export default PasswordVisibilityIcon;
