//Mui Components
import Chip from "@mui/material/Chip"

export default function DisabledPaymentScheduleChip(props) {
  return (
    <>
      <Chip
        sx={{
          color: "primary.payChips.disabledChip",
          minWidth: "150px",
          backgroundColor: "secondary.payChips.disabledChip",
          fontFamily: "Archivo",
          margin: "auto 0",
          fontWeight: "500",
          alignItems: "center",
          fontSize: "13px",
          alignSelf: "self-end",
        }}
        label={"Schedule Disabled"}
      />
    </>
  )
}
