import React, { useEffect, useState } from "react";

//Mui components
import { Card, CardHeader, Slide, Tooltip, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import MobileStepper from "@mui/material/MobileStepper";
import Button from "@mui/material/Button";

//Mui icons
import InfoIcon from "@mui/icons-material/Info";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";

//Services
import dashboardService from "../../../../services/dashboard-service";

//Our components
import InfinityLoader from "../../../UH-loading-animation/InfinityLoader";

//Our Styles
import reportingDashboardStyles from "../reportingDashboardStyles";

export default function PaymentActivity(props) {
  let UsdFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  const [results, setResults] = useState({});
  const getReportResults = async () => {
    setLoading(true);
    try {
      let _results = await dashboardService.getPayments(
        props.groupPlanId,
        props.enrollmentWindowId
      );
      setResults(_results);
    } catch (error) {
      console.log("error getting PaymentActivity", error);
    }
    setLoading(false);
  };
  useEffect(() => {
    if (props.groupPlanId && props.enrollmentWindowId) {
      if (props.enrollmentWindows?.length > 0) {
        let validId = false
        for (let window of props.enrollmentWindows) {
          if (window.id == props.enrollmentWindowId) {
            validId = true
            break
          }
        }
        if (validId) getReportResults()
      }
    }
  }, [props.enrollmentWindowId,props.enrollmentWindows]);
  // console.log('results for PaymentActivity', results)

  const [loading, setLoading] = useState(true);

  return (
    <Box sx={reportingDashboardStyles.smallReportContainer}>
      {loading && (
        <>
          <InfinityLoader
            style={reportingDashboardStyles.individualReportInfinityLoader}
          />
           <style>
              {`
                  div.lottie-div > svg {
                     height:55px!important;
                     position:absolute;
                     top:30%;
                  }
               `}
            </style>
          </>
      )}
      <div style={{ position: "relative" }}>
        <div
          className="flex-row"
          style={{
            height: "100%",
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            flexWrap: "wrap",
          }}
        >
          <div className="col-12">
            <Typography
              variant="h4"
              sx={{ color: "#5421C9", marginTop: "23px", marginBottom: "5px" }}
            >
              {results[0]?.monthlyPremium &&
                UsdFormatter.format(results[0].monthlyPremium)}
            </Typography>
            <Typography
              variant="overline"
              style={{ letterSpacing: "1px", fontSize: "12px" }}
            >
              TOTAL MONTHLY PREMIUM
            </Typography>
          </div>

          <div className="col-12">
            <Divider
              className="col-12 flex-row"
              sx={{
                marginTop: "7px",
                marginBottom: "7px",
                width: "50%",
                marginLeft: "25%",
                color: "background.border",
              }}
            />
          </div>

          <div className="col-12">
            <Typography
              variant="h4"
              sx={{ color: "#5421C9", marginBottom: "5px" }}
            >
              {results[0]?.monthlyAvgCost &&
                UsdFormatter.format(results[0].monthlyAvgCost)}
            </Typography>
            <Typography
              variant="overline"
              style={{ letterSpacing: "1px", fontSize: "12px" }}
            >
              AVERAGE MONTHLY COST
            </Typography>
          </div>
        </div>
      </div>
    </Box>
  );
}
