import Settings from "../settings";
import authService, { authHeader } from "./auth-service";
import { baseService } from "./baseService";

class internalPaymentsService extends baseService {
  getAllPaymentsByMemberId(memberId) {
    const url = Settings.API_URL + "payments/transactions/" + memberId;
    return this.get(url);
  }

  voidPaymentByTransactionId(paymentTransactionId) {
   const url = Settings.API_URL + "payments/voidpayment/" + paymentTransactionId;
   return this.put(url);
  }

  refundPaymentByTransactionId(paymentTransactionId, amount) {
    const url = Settings.API_URL + "payments/refund/" + paymentTransactionId + `?amount=${amount}`;
    return this.post(url, amount);
  }

  //one time payment
  // {
  //    memberId:'',
  //    paymentAmount:'',
  //    occurance:'' ---dateTime
  //    bankAccountId:'',
  //    enrollmentId
  // }
  insertOneTimePayment(oneTimePayment) {
    const url = Settings.API_URL + "payments/onetimepayment";
    return this.post(url, oneTimePayment);
  }

  postPayout(payoutObj) {
    const url = Settings.API_URL + "payments/payout/" + payoutObj.memberId
    const payout = {
      memberId: payoutObj.memberId,
      bankAccountId: payoutObj.bankAccountId,
      payoutAmount: payoutObj.paymentAmount,
      description: payoutObj.description
    }
    return this.post(url, payout)
  }

  getOneTimePaymentsByMemberId(memberId) {
    const url = Settings.API_URL + "payments/onetimepayments/" + memberId;
    return this.get(url);
  }

  cancelOneTimePayment(oneTimePaymentId, paymentTransactionTypeId) {
      const url = Settings.API_URL + "payments/cancelscheduledonetimepayment/" + oneTimePaymentId + "/" + paymentTransactionTypeId;
    return this.put(url);
  }

  getPaymentBalanceByMemberId(memberId) {
    const url = Settings.API_URL + "payments/getbalance/" + memberId;
    return this.get(url);
  }
}

const paymentsService = new internalPaymentsService();

export default paymentsService;
