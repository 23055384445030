import React from 'react';

//Mui icons
import AddIcon from '@mui/icons-material/Add';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import { Typography } from '@mui/material';

export default function EnrollmentSummaryMembership(props) {
  let UsdFormatterDec = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
  });

  return (
    <>
      {/* membership */}
      <div
        className="flex-row"
        style={{
          minHeight: '57px',
          fontSize: '20px',
          fontWeight: 600,
          lineHeight: '30px',
          position: 'relative',
          justifyContent: 'flex-start',
          alignItems: 'center',
          padding: '0px',
        }}
      >
        <span className="" style={{ cursor: 'pointer', position: 'relative' }}>
          {/* {props.results?.membershipPlan?.title}  */}

          <Typography variant="subtitle2" fontWeight="600">
            Membership
          </Typography>

          {/* bigger clickable area */}
          {/* <div style={{ position: "absolute", width: "100%", height: "300%", top: "-100%" }} />   */}
        </span>

        <div
          onClick={() => props.handleExpandClick(props.results.membershipPlan)}
          style={{
            position: 'absolute',
            right: '0%',
            top: '0%',
            color: '#195ffb',
            width: '18%',
            height: '100%',
            cursor: 'pointer',
          }}
        >
          <CreateOutlinedIcon
            fontSize="large"
            sx={{
              position: 'absolute',
              right: '14%',
              top: '20%',
              color: 'primary.main',
              borderRadius: '100px',
              boxShadow: '-1px 1px 4px 0px #00000040',
              padding: '6px',
            }}
          />
        </div>
      </div>

      <hr
        style={{
          marginLeft: '-2%',
          marginBottom: '10px',
          minWidth: '104%',
          backgroundColor: '#F0F0F0',
          opacity: 1,
        }}
      />

      <div
        className="flex-row"
        style={{
          margin: '0px',
          fontSize: '15px',
          fontWeight: '500',
          padding: '0px',
          color: '#646464',
        }}
      >
        {props.results.membershipPlan &&
          props.results.membershipPlan?.carrierName}
      </div>

      <div
        className="flex-row"
        style={{
          margin: '0px',
          fontSize: '15px',
          fontWeight: '500',
          padding: '0px',
          color: '#646464',
        }}
      >
        Coverage Effective:{' '}
        {props.results.membershipPlan &&
          props.formatDate(props.results.membershipPlan?.effectiveDate)}
      </div>

      <div
        className="flex-row"
        style={{
          margin: '0px',
          marginBottom: '24px',
          fontSize: '15px',
          fontWeight: '500',
          padding: '0px',
          justifyContent: 'space-between',
          color: '#646464',
          alignItems: 'center',
        }}
      >
        {props.selections.membership && props.selections.membershipVal > 0 && (
          <>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                width: '80%',
              }}
            >
              {props.results.membershipPlan &&
                props.results.membershipPlan.options.find(
                  (option) =>
                    option.memberOptionId == props.selections.membership
                )?.name}
            </div>

            <div style={{ marginRight: '10px', fontWeight: '700' }}>
              + {UsdFormatterDec.format(props.selections.membershipVal)}
            </div>
          </>
        )}
        {props.selections.membership && props.selections.membershipVal == 0 && (
          <>
            <span>
              {props.results?.membershipPlan?.title} <em>not elected</em>
            </span>
            <span style={{ float: 'right', marginRight: '10px' }}>$0.00</span>
          </>
        )}
        {!props.selections.membership && (
          <>
            <span>
              <em>No plan selected</em>
            </span>
            <span style={{ float: 'right', marginRight: '10px' }}>$0.00</span>
          </>
        )}
      </div>
    </>
  );
}
