import React, { useState, useEffect } from 'react';

import Chip from '@mui/material/Chip';
import CheckIcon from '@mui/icons-material/Check';
import Box from '@mui/material/Box';

//ComponentStyles
const componentStyles = {
  componentContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    margin: '30px 10px 50px',
  },
  chipColumnContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    // width: '50%',
  },
  chip: (validation) => ({
    color: validation ? '#4E9A51' : '',
    backgroundColor: validation ? '#07FB1124' : '',
    border: validation ? '2px solid #4E9A51' : '',
    borderRadius: '8px',
    fontWeidht: '600px',
    fontSize: '14px',
    letterSpacing: '0.1px',
    width: '150px',
    margin: '4px 5px',
    fontFamily: 'Archivo',
    fontWeight: 500,
  }),
  checkIcon: { color: '#4E9A51!important', width: '15px' },
};

export function PasswordStrength(props) {
  const [lengthCheck, setLengthCheck] = useState(0);
  const [lowercaseCheck, setLowercaseCheck] = useState(0);
  const [uppercaseCheck, setUppercaseCheck] = useState(0);
  const [numberCheck, setNumberCheck] = useState(0);
  const [passwordStrength, setPasswordStrength] = useState(0);

  const passInput = props.passwordValue;

  useEffect(() => {
    //lengthCheck
    if (passInput.length >= 8) setLengthCheck(1);
    if (passInput.length <= 7) setLengthCheck(0);

    //caseCheck
    const caseLowRx = new RegExp('[a-z]+');
    if (caseLowRx.test(passInput)) setLowercaseCheck(1);
    if (!caseLowRx.test(passInput)) setLowercaseCheck(0);
    const caseHighRx = new RegExp('[A-Z]+');
    if (caseHighRx.test(passInput)) setUppercaseCheck(1);
    if (!caseHighRx.test(passInput)) setUppercaseCheck(0);

    //numberCheck
    const numRx = new RegExp('[0-9]+');
    if (numRx.test(passInput)) setNumberCheck(1);
    if (!numRx.test(passInput)) setNumberCheck(0);
  }, [passInput]);

  useEffect(() => {
    setPasswordStrength(
      lengthCheck + lowercaseCheck + uppercaseCheck + numberCheck
    );
  }, [lengthCheck, lowercaseCheck, uppercaseCheck, numberCheck]);

  //^^^ [changes] trigger vvv

  useEffect(() => {
    if (passwordStrength === 4) props.strengthPassed();
    else props.strengthNotPassed();
  }, [passwordStrength]);

  return (
    <Box sx={componentStyles.componentContainer}>
      <Box sx={componentStyles.chipColumnContainer}>
        <Chip
          label="One upper case"
          sx={componentStyles.chip(uppercaseCheck)}
          icon={
            uppercaseCheck ? <CheckIcon sx={componentStyles.checkIcon} /> : null
          }
        />
        <Chip
          label="8 characters"
          sx={componentStyles.chip(lengthCheck)}
          icon={
            lengthCheck ? <CheckIcon sx={componentStyles.checkIcon} /> : null
          }
        />
      </Box>

      <Box sx={componentStyles.chipColumnContainer}>
        <Chip
          label="One lower case"
          sx={componentStyles.chip(lowercaseCheck)}
          icon={
            lowercaseCheck ? <CheckIcon sx={componentStyles.checkIcon} /> : null
          }
        />

        <Chip
          label="One number"
          sx={componentStyles.chip(numberCheck)}
          icon={
            numberCheck ? <CheckIcon sx={componentStyles.checkIcon} /> : null
          }
        />
      </Box>
    </Box>
  );
}
export default PasswordStrength;
