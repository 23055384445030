import React, { useEffect, useState } from "react";

//MuiIcons
import WorkOutlineOutlinedIcon from "@mui/icons-material/WorkOutlineOutlined";
import WorkOffOutlinedIcon from "@mui/icons-material/WorkOffOutlined";
import CheckIcon from "@mui/icons-material/Check";
import Healing from "@mui/icons-material/Healing";
// import WorkHistoryOutlinedIcon from '@mui/icons-material/WorkHistoryOutlined';
import WorkOffIcon from "@mui/icons-material/WorkOff";
// import WorkHistoryIcon from "@mui/icons-material/WorkHistory";

//Mui Components

import Chip from "@mui/material/Chip";
import optionService from "../../../../services/optionService";

export default function WorkingStatusChips(props) {

  let selectedStatus = props?.employmentStatuses?.find(status => status.id === props.workingStatusId);

  const [chip, setChip] = useState({
    icon: null,
    backgroundColor: "",
    color: "",
    border: "",
    borderColor: "",
    label: "",
  });

  const buildChip = () => {
    switch (props.workingStatusId) {
      case 0:
        setChip({
          icon: (
            <WorkOutlineOutlinedIcon
              sx={{ height: 20, color: "text.primary" }}
            />
          ),
          backgroundColor: "action.selected",
          color: "text.primary",
          label: "No Working Status",
        });
        break;

      case 1:
        setChip({
          icon: <CheckIcon sx={{ height: 20, color: `#1c491e!important` }} />,
          backgroundColor: "background.activeWorking",
          color: "primary.activeChip",
          label: "Actively Working",
        });

        break;

      case 2:
        setChip({
          icon: (
            <Healing
              sx={{ height: 20, color: `rgb(50, 100, 155, 1)!important` }}
            />
          ),
          backgroundColor: "background.onMedicalLeaveChip",
          color: "primary.onMedicalLeaveChip",
          border: "solid 1px",
          borderColor: "secondary.onMedicalLeaveBorder",
          label: "On Medical Leave",
        });
        break;

      case 3:
        setChip({
          icon: <Healing sx={{ height: 20, color: `#4f1468!important` }} />,
          backgroundColor: "background.onDisabilityChip",
          color: "primary.onDisabilityChip",
          label: "On Disability",
        });
        break;

      case 4:
        setChip({
          icon: (
            <WorkOffIcon
              sx={{ height: 20, color: `rgba(229, 29, 96, .8)!important` }}
            />
          ),
          backgroundColor: "background.furloughedChip",
          border: "solid 1px",
          borderColor: "secondary.furloughedChipBorder",
          color: "primary.furloughedChip",
          label: "Furloughed",
        });
        break;

      case 5:
        setChip({
          icon: <WorkOffIcon sx={{ height: 20, color: `rgba(1, 87, 155, 0.8)!important` }} />,
          backgroundColor: "background.retiredChip",
          color: "primary.retiredChip",
          label: "Retired",
        });
        break;

      case 6:
        setChip({
          icon: (
            <WorkOffOutlinedIcon sx={{ height: 20, color: `text.primary` }} />
          ),
          backgroundColor: "background.notActivelyWorking",
          color: "primary.notActivelyWorking",
          label: "Not Actively Working",
        });
        break;
        //all other types and or undefined will have the same design below 
      default:
        setChip({
          icon: (
            <WorkOutlineOutlinedIcon
              sx={{ height: 20, color: "text.primary" }}
            />
          ),
          // icon: "",
          backgroundColor: "action.selected",
          color: "text.primary",
          label: selectedStatus?.name || "No Working Status",
        });
    }
  };

  useEffect(() => {
    buildChip();
  }, [props.workingStatusId, selectedStatus?.id]);

  return (
    <>
      <Chip
        icon={chip.icon}
        sx={{
          backgroundColor: chip.backgroundColor,
          color: chip.color,
          border: chip.border,
          borderColor: chip.borderColor,
          fontWeight: "500",
          fontFamily: "Archivo",
          fontSize: ".9rem",
        }}
        label={chip.label}
      />
    </>
  );
}
