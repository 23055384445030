import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useHistory } from 'react-router';
// import { format, differenceInCalendarYears } from "date-fns";

//Assets
// import PlaceHolderLogo from '../../../assets/images/placeHolder.svg';
import FullColorBlackText from '../../../assets/images/FullColorBlackText.svg';
import defaultLogo from '../../../assets/images/FullColorOnlyLogo.png';

//Muicons
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import PersonIcon from '@mui/icons-material/Person';
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import SecurityIcon from '@mui/icons-material/Security';

//MuiComponents
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Fade from '@mui/material/Fade';
import MobileStepper from '@mui/material/MobileStepper';

//Services
import enrollmentService from '../../../services/enrollment-service';
import attachmentService from '../../../services/attachment-service';

//Our Components
import ExternalEnrollmentHeader from './ExternalEnrollmentHeader';
import PoweredBy from './PoweredBy';
import InfinityLoader from '../../UH-loading-animation/InfinityLoader';

//shared styles
import externalEnrollmentSharedStyles from '../externalEnrollmentSharedStyles';

//Component Styles

const componentStyles = {
  imageContainer: { display: 'flex', justifyContent: 'center' },
  imageStyles: {
    maxHeight: '100px',
    maxWidth: '100%',
    objectFit: 'contain',
  },
  infoContainer: {
    display: 'flex',
    flexDirection: 'column',
    // maxWidth: '575px',
    margin: '0 auto',
    overflowY: 'none',
  },
  loginButton: {
    height: 50,
    borderRadius: 100,
    width: '152px',
    fontWeight: '700!important',
    fontSize: '15px',
    margin: '20px auto',
  },
  //'What you will need' section
  stepsContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    maxWidth: '270px',
    margin: '0px auto',
  },
  stepsTitle: {
    textAlign: 'center',
  },
  iconListContainer: {
    display: 'flex',
    flexDirection: 'column',
    margin: '10px auto',
  },
  iconRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: '6px 0px',
    // maxWidth: "280px",
  },
  iconText: {
    fontFamily: 'Archivo',
    fontSize: '14px',
    fontWeight: 400,
    color: 'text.primary',
    lineHeight: '20px',
  },
  icon: { height: '22px', marginRight: '30px', color: 'text.secondary' },
  //Large Blue Button
  largeButtonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    width: '100%',
  },
  //Secure Message
  secureMessageContainer: {
    display: 'flex',
    flexDirecton: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '25px auto 10px',
    flexWrap: 'wrap',
  },
  secureMessageIcon: {
    height: '24px',
    color: 'primary.secureDataMsg',
    marginRight: '6px',
  },
  secureMessageText: {
    color: 'primary.secureDataMsg',
    fontSize: '12px',
    lineHeight: '32px',
    letterSpacing: '1px',
    textAlign: 'center',
  },
  //Text Description/Paragraph
  textDescription: {
    textAlign: 'center',
    margin: '20px auto',
    fontFamily: 'Archivo',
    fontWeight: 500,
    color: 'text.secondary',
    fontSize: '14px',
  },
  mobileStepper: { padding: '0px', backgroundColor: 'transparent' },
};

export default function ExternalEnrollments(props) {
  const getCookie = (cookieName) => {
    let name = `${cookieName}=`;
    let cookieArray = document.cookie.split(';');
    let value = '';
    cookieArray.forEach((cookie) => {
      if (cookie.includes(name)) value = cookie.replace(name, '').trim();
    });
    return value;
  };
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [errorGettingPlans, setErrorGettingPlans] = useState(false);
  const [availableEnrollments, setAvailableEnrollments] = useState([]);
  const [submittedEnrollments, setSubmittedEnrollments] = useState([]); //temp check for not allowing member side re-enroll
  const [selectedEnrollment, setSelectedEnrollment] = useState(null);

  const [documents, setDocuments] = useState([]);
  const [mostRecentDoc, setMostRecentDoc] = useState({});

  const grabMyEnrollments = async () => {
    setLoading(true);
    let myDomain = getCookie('subdomain').toString();
    let thisDomain = window.location.host.toString();
    if (myDomain !== thisDomain) {
      history.push('/login');
    }
    let info = false;
    try {
      info = await enrollmentService.getMe();
      // console.log('INFO', info);
    } catch (error) {
      console.error('error getting enrollments', error);
      setErrorGettingPlans(true);
    }

    // check if enrollment is submitted - currently only most recent enrollment record is beign sent to front, if inProg or cancelled, member is allowed to enroll, if submitted, no re-enrollment allowed
    let submittedEnrollments = [];
    let openEnrollments = [];
    if (info?.length > 0) {
      info.forEach((enrollment) => {
        if (enrollment.enrollmentStateId === 2) {
          submittedEnrollments.push(enrollment);
        } else {
          openEnrollments.push(enrollment);
        }
      });

      setAvailableEnrollments(openEnrollments);
      setSubmittedEnrollments(submittedEnrollments); //temp check for not allowing member side re-enroll
      setSelectedEnrollment(openEnrollments[0]);

      //no enrollments, get documents
      if (openEnrollments?.length < 1 || !info) {
        let docs;
        try {
          docs = await attachmentService.getAttachments();
          setDocuments(docs);
        } catch (error) {
          console.log('error getting attachments', error);
        }
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    if (documents?.length > 0) {
      setMostRecentDoc(documents[0]);
    }
  }, [documents]);

  const downloadEnrollmentPdf = async () => {
    const link = document.createElement('a');
    let uniqueId = mostRecentDoc?.uniqueId;

    link.download = `/api/attachment/${uniqueId}`;
    link.href = `./api/attachment/${uniqueId}`;

    link.click();
    link.remove();
  };

  useEffect(() => {
    grabMyEnrollments();
  }, []);

  const startEnrollment = async (e) => {
    setLoading(true);
    //temp member-side enrollment bug fix - if enrollment is in cancelled state (enrollmentStateId=3), reopen Enrollment
    if (selectedEnrollment?.enrollmentStateId === 3) {
      let signatureObj = {
        enrollmentId: selectedEnrollment?.enrollmentId,
        signatureText: '',
        signature: '',
        signatureDate: Date.toLocaleString().slice(0, 9),
        groupPlanId: selectedEnrollment?.groupPlanId,
        isMail: false,
        isVerbal: false,
      };
      try {
        await enrollmentService.openEnrollment(signatureObj);
      } catch (error) {
        console.log('error reopening enrollment', error);
      }
    }
    setTimeout(() => {
      history.push(`/enrollments/${e.target.value}/`, {
        ...history.location.state,
        currentGroupPlanId: e.target.value,
      });
      setLoading(false);
    }, 250);
  };

  const [isAuthenticated, setIsAuthenticated] = useState();
  useEffect(() => {
    let _isAuthenticated = props.checkAuthentication();
    setIsAuthenticated(_isAuthenticated);
  }, []);

  const [imgFetchAttempts, setImgFetchAttempts] = useState(0);
  const handleLogoError = (e) => {
    setImgFetchAttempts((prev) => prev++);
    if (imgFetchAttempts <= 10) e.target.src = defaultLogo;
  };

  const [activeStep, setActiveStep] = useState(0);
  const maxSteps = availableEnrollments?.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSelectedEnrollment(availableEnrollments[activeStep + 1]);
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setSelectedEnrollment(availableEnrollments[activeStep - 1]);
  };

  return (
    <>
      <Box
        sx={externalEnrollmentSharedStyles.externalPreEnrollmentParentContainer}
      >
        {/* CAN HAVE MULTIPLE ENROLLMENTS HERE, ONLY SHOWING ONE RIGHT NOW */}

        {loading && (
          <>
            <InfinityLoader
              style={externalEnrollmentSharedStyles.infinityLoader}
            />
            <style>
              {`
                  div.lottie-div > svg {
                     height:55px!important;
                     position:absolute;
                     top:50%;
                  }
               `}
            </style>
          </>
        )}
        <Box
          id="enrollment-container"
          sx={externalEnrollmentSharedStyles.componentContainer(false)} //false since not displayed on agent enrollment
        >
          <Box sx={componentStyles.infoContainer}>
            {isAuthenticated && (
              <>
                {availableEnrollments?.length > 0 ? (
                  <>
                    {availableEnrollments?.length > 0 &&
                      availableEnrollments.map((enrollment, i) => {
                        return (
                          <Box
                            key={enrollment?.enrollmentId}
                            sx={{
                              height: '95%',
                              display: activeStep == i ? '' : 'none',
                            }}
                          >
                            <Fade in={activeStep == i}>
                              <Box>
                                <ExternalEnrollmentHeader
                                  selectedEnrollment={enrollment}
                                  isLandingPage={true}
                                />

                                {/* What you'll need to know icon list*/}
                                <Box sx={componentStyles.stepsContainer}>
                                  <Typography
                                    variant="h6"
                                    sx={componentStyles.stepsTitle}
                                  >
                                    What you will need
                                  </Typography>

                                  <Box sx={componentStyles.iconListContainer}>
                                    <Box sx={componentStyles.iconRow}>
                                      <PersonIcon sx={componentStyles.icon} />
                                      <Typography sx={componentStyles.iconText}>
                                        Step 1: Basic Information
                                      </Typography>
                                    </Box>
                                    <Box sx={componentStyles.iconRow}>
                                      <AssignmentTurnedInOutlinedIcon
                                        sx={componentStyles.icon}
                                      />
                                      <Typography sx={componentStyles.iconText}>
                                        Step 2: Choose Benefit Options
                                      </Typography>
                                    </Box>
                                    <Box sx={componentStyles.iconRow}>
                                      <CalendarTodayIcon
                                        sx={componentStyles.icon}
                                      />
                                      <Typography sx={componentStyles.iconText}>
                                        Step 3: Select Payment Schedule
                                      </Typography>
                                    </Box>
                                    <Box sx={componentStyles.iconRow}>
                                      <RemoveRedEyeIcon
                                        sx={componentStyles.icon}
                                      />
                                      <Typography sx={componentStyles.iconText}>
                                        Step 4: Review & Submit
                                      </Typography>
                                    </Box>
                                  </Box>
                                </Box>

                                <Box sx={componentStyles.largeButtonContainer}>
                                  <Button
                                    variant="contained"
                                    fullwidth
                                    sx={
                                      externalEnrollmentSharedStyles.largeBlueButton
                                    }
                                    onClick={startEnrollment}
                                    value={enrollment?.groupPlanId}
                                    disabled={!enrollment?.groupPlanId}
                                  >
                                    Start Enrollment
                                  </Button>
                                </Box>

                                <Box
                                  sx={componentStyles.secureMessageContainer}
                                >
                                  <SecurityIcon
                                    sx={componentStyles.secureMessageIcon}
                                  />
                                  <Typography
                                    sx={componentStyles.secureMessageText}
                                  >
                                    YOUR DATA IS PRIVATE AND PROTECTED
                                  </Typography>
                                </Box>

                                <Box>
                                  <Typography
                                    variant="body1"
                                    sx={componentStyles.textDescription}
                                  >
                                    {enrollment?.description
                                      ? enrollment.description
                                      : "This plan offers financial support for expenses like mortgage, car payments, and other obligations if you're unable to work due to disability or death. Enrollment is quick, your information is secure, and you can cancel anytime."}
                                  </Typography>
                                </Box>

                                {errorGettingPlans && (
                                  <Alert
                                    severity="error"
                                    sx={{ margin: '20px auto' }}
                                  >
                                    Error finding enrollment information.
                                  </Alert>
                                )}
                              </Box>
                            </Fade>
                          </Box>
                        );
                      })}
                    {/* // Display below if multiple enrollments */}
                    {availableEnrollments?.length > 1 && (
                      <Box>
                        <Typography
                          variant="body1"
                          sx={componentStyles.textDescription}
                        >
                          You have additional enrollments available! <br />{' '}
                          Click the arrows to view them.
                        </Typography>
                        <MobileStepper
                          steps={maxSteps}
                          position="static"
                          activeStep={activeStep}
                          sx={componentStyles.mobileStepper}
                          nextButton={
                            <Button
                              size="small"
                              onClick={handleNext}
                              disabled={activeStep === maxSteps - 1}
                              sx={{ justifyContent: 'flex-end' }}
                            >
                              <KeyboardArrowRight />
                            </Button>
                          }
                          backButton={
                            <Button
                              size="small"
                              onClick={handleBack}
                              disabled={activeStep === 0}
                              sx={{ justifyContent: 'flex-start' }}
                            >
                              <KeyboardArrowLeft />
                            </Button>
                          }
                        />
                      </Box>
                    )}
                  </>
                ) : (
                  // Display if no open enrollment
                  <Box sx={componentStyles.infoContainer}>
                    <Box sx={componentStyles.imageContainer}>
                      <img
                        alt="logo"
                        src={
                          props.agentEnroller &&
                          props.fullMemberObj?.organizationId > 0
                            ? `api/image/organization/${props.fullMemberObj?.organizationId}`
                            : '/api/image/organization'
                        }
                        style={componentStyles.imageStyles}
                        onError={handleLogoError}
                      />
                    </Box>

                    <Typography
                      variant="body1"
                      sx={componentStyles.textDescription}
                    >
                      There aren't any open enrollments available for you at
                      this time.
                      {mostRecentDoc?.uniqueId &&
                        `Click below to download your most recent enrollment
                          pdf.`}
                    </Typography>

                    {mostRecentDoc?.uniqueId && (
                      <>
                        <Box sx={componentStyles.largeButtonContainer}>
                          <Button
                            variant="contained"
                            fullWidth
                            sx={externalEnrollmentSharedStyles.largeBlueButton}
                            onClick={downloadEnrollmentPdf}
                            value={mostRecentDoc?.uniqueId}
                            disabled={!mostRecentDoc?.uniqueId}
                          >
                            Download enrollment pdf
                          </Button>
                        </Box>
                      </>
                    )}
                  </Box>
                )}
              </>
            )}
            {/* //Display if user not logged in */}
            {!isAuthenticated && (
              <>
                <Typography sx={componentStyles.textDescription}>
                  Not Authenticated, please login.
                </Typography>
                <Button
                  variant="contained"
                  sx={componentStyles.loginButton}
                  onClick={() => history.push('/login')}
                >
                  Login
                </Button>
              </>
            )}
          </Box>
          <PoweredBy />
        </Box>

        {/* {!isAuthenticated && history.push('/login')} */}
      </Box>
    </>
  );
}
