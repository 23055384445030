import React, { useState, useEffect } from 'react';
import { format, set } from 'date-fns';

//Our services
import dashboardService from '../../../../../services/dashboard-service';

//Mui components
import { IconButton, Tooltip, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

//Mui icons
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

//Our components
import ButtonInfinityLoader from '../../../../UH-loading-animation/ButtonInfinityLoader';

const componentStyles = {
  componentBody: {
    height: '200px',
    margin: '0px auto',
    padding: '23px 0px 0px 0px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    position: 'relative',
  },
  viewAndSetGoalContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
  },
  actualValueText: {
    color: '#5421C9',
    marginBottom: '8px',
  },
  buttonContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    margin: '10px auto 0px',
  },
  setNewGoalButton: {
    padding: '8px 20px',
    fontSize: '14px!important',
  },
  goalInput: {
    width: '70%',
    margin: '10px auto',
    '& .MuiOutlinedInput-input': {
      fontSize: '14px', // Adjust the font size
    },
  },
  confirmSetAndCancelGoalButtons: {
    padding: '6px 16px',
    fontSize: '14px!important',
  },
  noGoalSetTextContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    // border: '1px solid red'
  },
  noGoalSetText: (showGoalEntry) => ({
    letterSpacing: '1px',
    fontSize: '12px!important',
    margin: showGoalEntry ? '5px auto 0px' : '0px auto',
  }),
  infoTipContainer: {
    padding: '0px',
  },
  infoTip: { color: 'primary.text', height: '.9rem' },
  divider: (showGoalEntry) => ({
    margin: '10px auto',
    width: '80%',
    color: 'background.border',
    display: !showGoalEntry ? 'block' : 'none',
  }),
};

export default function ZeroDashboardGoalView(props) {
  // console.log("ZeroDashboardGoalView props", props)
  //PROPS
  const {
    //single bool state to show goal entry input
    showGoalEntry,
    setShowEnrollGoalEntry,
    setShowPremGoalEntry,
    handleDisplayEditGoal,
    handleCancelEditGoal,
    getReportResults,
    allowEdit,
  } = props;

  const [loading, setLoading] = useState(false);
  const [localGoal, setLocalGoal] = useState(false);

  useEffect(() => {
    // console.log("zeroView", localGoal)
    if (props?.enrollmentGoal) {
      setLocalGoal(props?.enrollmentGoal);
    } else if (props?.premiumGoal) {
      setLocalGoal(props?.premiumGoal);
    }
  }, [props?.enrollmentGoal, props?.premiumGoal, showGoalEntry]);

  const handleChangeGoal = (e) => {
    console.log('new goal value', e.target.value);
    setLocalGoal({ ...localGoal, goal: e.target.value });
  };

  //SUBMIT new goal to db
  const submitNewGoal = async () => {
    setLoading(true);
    console.log('submitting new goal', localGoal);
    if (localGoal?.goal > 0) {
      try {
        await dashboardService.updateDashboardGoal(localGoal);
      } catch (error) {
        console.error('error updating goal', error);
      }
    }
    //resfresh goals from db and rest state
    setTimeout(() => {
      getReportResults();
      setLoading(false);
      setShowEnrollGoalEntry(false);
      setShowPremGoalEntry(false);
    }, 500);
  };

  //
  return (
    <Box sx={componentStyles.componentBody}>
      {/* show enrollment goal OR entry input */}
      <Box sx={componentStyles.viewAndSetGoalContainer}>
        {!showGoalEntry ? (
          <Typography variant="h4" sx={componentStyles.actualValueText}>
            {localGoal?.name === 'Total Enrollments'
              ? props?.formatNumWithCommas(localGoal?.actual) || 0
              : props?.UsdFormatter?.format(localGoal?.actual || 0)}
          </Typography>
        ) : (
          <TextField
            variant="outlined"
            label={
              localGoal?.name === 'Total Enrollments'
                ? 'Enrollment Goal'
                : 'Premium Goal'
            }
            sx={componentStyles.goalInput}
            value={localGoal?.goal}
            onChange={(e) => handleChangeGoal(e, localGoal?.name)}
          />
        )}

        <Typography
          variant="overline"
          sx={componentStyles.noGoalSetText(showGoalEntry)}
        >
          {localGoal?.name === 'Total Enrollments'
            ? 'COMPLETED ENROLLMENTS'
            : 'TOTAL PREMIUM'}
        </Typography>
      </Box>

      <Divider sx={componentStyles.divider(showGoalEntry)} />

      <Box sx={componentStyles.buttonContainer}>
        {/* If Permissions allow AND enrollmentGoal.Id != 0, show set goal button.  If not, show  message.  Either will take the full 12 width */}
        {allowEdit && localGoal?.id > 0 ? (
          <>
            {!showGoalEntry ? (
              <Button
                variant="contained"
                onClick={(e) => handleDisplayEditGoal(e, localGoal)}
                sx={componentStyles.setNewGoalButton}
              >
                SET A GOAL
              </Button>
            ) : (
              <>
                <Button
                  variant="outlined"
                  sx={{
                    ...componentStyles.confirmSetAndCancelGoalButtons,
                    marginRight: '14px',
                  }}
                  onClick={(e) => handleCancelEditGoal(e, localGoal)}
                >
                  CANCEL
                </Button>
                <Button
                  variant="contained"
                  sx={componentStyles.confirmSetAndCancelGoalButtons}
                  onClick={submitNewGoal}
                >
                  {loading ? (
                    <ButtonInfinityLoader buttonTitle={'SET GOAL'} />
                  ) : (
                    'SET GOAL'
                  )}
                </Button>
              </>
            )}
          </>
        ) : (
          <Box sx={componentStyles.noGoalSetTextContainer}>
            <Typography sx={componentStyles.noGoalSetText(false)}>
              NO GOAL SET
            </Typography>
            <Tooltip
              // title='Goals can be set by users with Administrator level permissions.'
              title="Goal setting functionality coming soon."
            >
              <IconButton sx={componentStyles.infoTipContainer}>
                <InfoOutlinedIcon sx={componentStyles.infoTip} />
              </IconButton>
            </Tooltip>
          </Box>
        )}
      </Box>
    </Box>
  );
}
