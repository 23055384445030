import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

//services
import enrollmentService from '../../../../../../../services/enrollment-service';
import activeCoverageService from '../../../../../../../services/activeCoverage-service';

// Assets

//Mui Components
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { Alert, Box, Typography, Checkbox } from '@mui/material';

//Mui icons
import CloseIcon from '@mui/icons-material/Close';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import VisibilityIcon from '@mui/icons-material/Visibility';
import NoVisibilityIcon from '@mui/icons-material/VisibilityOff';

//Our Components
import InfinityLoader from '../../../../../../UH-loading-animation/InfinityLoader';
import { set, format } from 'date-fns';

//component styles
const componentStyles = {
  componentBody: {
    fontFamily: 'Poppins',
    minWidth: '538px',
    height: '100%',
    backgroundColor: 'background.default',
    overflowY: 'auto',
  },
  headerContainer: {
    width: '100%',
    padding: '10px 30px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '0 auto',
    borderBottom: '1px solid',
    borderColor: 'primary.main',
  },
  drawerTitle: {
    color: 'text.primary',
    fontFamily: 'Poppins',
    fontSize: '22px',
    fontWeight: '400',
  },
  infoContainer: {
    width: '100%',
    padding: '10px 30px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    margin: '0 auto',
  },
  policyDetails: {
    fontFamily: 'Archivo',
    fontSize: '13px!important',
    fontWeight: 400,
    color: 'text.secondary',
    lineHeight: '20px',
  },
  description: {
    color: 'text.primary',
    fontFamily: 'Archivo',
    fontSize: '14px',
    fontWeight: '500',
    padding: '10px 16px 10px 20px',
  },
  closeDrawerButton: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
};
export default function EditCoverageEffectiveDrawer(props) {
  console.log('props in cancel coverage drawer', props);

  //Plan to cancel title:
  const displayPlanName = (displayName) => {
    console.log('displayNameCheck running', displayName);
    let name = displayName;
    switch (displayName) {
      case 'LADD-S':
        name = 'LADD-Spouse';
        break;
      case 'LADD-D':
        name = 'LADD-Dependent';
        break;
      default:
        return name;
    }
    return name;
  };
  //Drawer Logic
  const handleCloseDrawer = () => {
    props?.setEditCoverageEffectiveDateDrawerOpen(false);
  };

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  //submit change
  const submitEffectiveDateChange = async () => {
    setLoading(true);
    //Cancel all coverage
    if (props?.cancelAllCoverage) {
      props?.handleCancelAllCoverage();
    } else {
      //cancel single line of coverage
      console.log('plan to cancel', props?.planToCancel);
      let activeCoverageId = props?.planToCancel?.id;
      if (!props?.cancelEnrollment) {
        if (activeCoverageId) {
          try {
            //cancel coverage
            await activeCoverageService.cancelActiveCoverageForMember(
              activeCoverageId,
              props?.reasonForCancelation,
              props?.dateOfCancelation
            );
          } catch (error) {
            console.error('error canceling single line of coverage', error);
          }
        }
      } else {
        //cancel enrollment during open enrollment
        console.log('Cancel Enrollment');
        let memberId = props?.member?.id;
        let enrollmentId = props?.planEnrollmentId;
        let errorCanceling = false;

        try {
          let cancelAllCoverage = await enrollmentService.cancelEnrollment(
            enrollmentId,
            memberId
          );
        } catch (error) {
          console.error('error canceling all coverage', error);
          props?.setCancelAllError(error);
          //local error value for conditional rendering of modal
          errorCanceling = true;
          setLoading(false);
        }
        setTimeout(() => {
          //refresh enrollment data

          props?.getThisPlan();
          //refresh payment schedules
          // props?.getPaymentSchedule()
          setLoading(false);
          //close modal if no error, otherwise display error
          if (!errorCanceling) {
            handleCloseDrawer();
          }
        }, 500);
      }
    }

    //following cancel coverage, refresh
    //refresh payment schedule plan and cancellation list
    setTimeout(() => {
      props?.getPaymentSchedule();
      props?.getActiveCoverage(props?.member?.id);
      props?.getMemberHistory();
      handleCloseDrawer();
      setLoading(false);
    }, 500);
  };

  return (
    <>
      <Drawer
        anchor="right"
        open={props?.editCoverageEffectiveDateDrawerOpen}
        onClose={handleCloseDrawer}
      >
        {loading && (
          <>
            <InfinityLoader
              style={{
                // position: "fixed",
                position: 'absolute',
                zIndex: 5,
                width: '100%',
                height: '100%',
                backdropFilter: 'blur(10px)',
              }}
            />
            <style>
              {`
                      div.lottie-div > svg {
                         height:55px!important;
                         position:absolute;
                         top:50%;
                      }
                   `}
            </style>
          </>
        )}
        <Box sx={componentStyles.componentBody}>
          <Box sx={componentStyles.headerContainer}>
            <Typography variant="h5" sx={componentStyles.drawerTitle}>
              Coverage Effective Date
            </Typography>
            <Box
              sx={componentStyles.closeDrawerButton}
              role="button"
              onClick={handleCloseDrawer}
            >
              <CloseIcon sx={{ height: '34px' }} />
            </Box>
          </Box>

          {/* Edit fields */}
          {console.log('props in edit coverage effective drawer', props)}
          <Box sx={componentStyles.infoContainer}>
            {!props?.cancelEnrollment && (
              <>
                <TextField
                  // disabled //temp disabled while we currently only allow cancelling on the first of the month - remove this and it will allow for 'now' or 'next month'
                  select
                  fullWidth
                  id="dateOfEffective"
                  sx={{
                    '& fieldset': { borderRadius: '4px' },
                    marginBottom: '15px',
                  }}
                  label="Coverage Effective Date"
                  // value={props?.dateOfCancelation || ''}
                  // onChange={props?.handleCancelDateChange}
                  InputLabelProps={{ shrink: true }}
                >
                  {props?.currentAvailableWindows?.length > 0 &&
                    props?.currentAvailableWindows.map((date) => {
                      return (
                        <MenuItem key={date.value} value={date.value}>
                          {`${date.paymentStart}`}
                          ding
                        </MenuItem>
                      );
                    })}
                </TextField>
              </>
            )}
          </Box>
          {/* Buttons */}
          <Box sx={componentStyles.buttonContainer}>
            <Button sx={{ marginRight: '10px' }} onClick={handleCloseDrawer}>
              CANCEL
            </Button>
            <Button
              variant="contained"
              //   disabled={
              //     (!props?.cancelEnrollment &&
              //       (props?.reasonForCancelation === '' ||
              //         !props?.reasonForCancelation)) ||
              //     props?.cancelActiveCoverageForMemberancelDateError
              //   }
              disabled={true}
              onClick={submitEffectiveDateChange}
            >
              SUBMIT DATE CHANGE
            </Button>
          </Box>
        </Box>
      </Drawer>
    </>
  );
}
