import React, { cloneElement, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { format, formatDate, formatDistance } from "date-fns";
import InfiniteScroll from "react-infinite-scroller";

//SignalR
import signalRConnection from "../../../../signalr";

//Services
import memberService from "../../../../services/member-service";
// import accountService from '../../../../services/account-service';
import authService from "../../../../services/auth-service";
import userService from "../../../../services/user-service";
import optionService from "../../../../services/optionService";

//Custom css for inner html
import "./NotificationsTray.css";

//Assets
import trayArrow from "../../../../assets/images/trayArrow.svg";

//MuiIcons
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import NotificationsIcon from "@mui/icons-material/Notifications";
import CircleIcon from "@mui/icons-material/Circle";
import PersonIcon from "@mui/icons-material/Person";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import LocalActivityIcon from "@mui/icons-material/LocalActivity";

//MuiComponents
import {
  Box,
  Paper,
  Button,
  Menu,
  MenuItem,
  IconButton,
  MenuList,
  Typography,
} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Badge from "@mui/material/Badge";
import { styled } from "@mui/material/styles";

//Context
import { NotificationContext } from "../NavContainer";

export default function NotificationsTray(props) {
  const componentStyles = {
    notificationTabs: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "left",
      borderBottom: "1px solid #0000001F",
      paddingBottom: "0px",
    },
    singleTabContainer: {
      display: "flex",
      minWidth: "70px",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      padding: "10px 8px",
    },
    singleTabTitle: {
      alignSelf: "center",
      fontWeight: 500,
      fontSize: "14px",
      fontFamily: "Archivo",
      cursor: "pointer",
    },
    alertInTab: {
      borderRadius: "2px",
      fontWeight: 500,
      fontSize: 10,
      fontFamily: "Archivo",
      padding: "0px 4px",
      lineHeight: "14px",
    },
    messageBody: {
      fontSize: "12px!important",
      // border: "1px solid #0000001F",
      padding: "0px",
      margin: "0",
      textAlign: "left",
      lineHeight: "18px",
    },
    messageTimeAndType: {
      marginBottom: "3px",
      color: "#646464B2",
      fontSize: "12px",
    },
    buttonContainer: {
      display: "flex",
      justifyContent: "space-around",
      alignItems: "center",
      minHeight: "50px",
      borderBottom: "1px solid #0000001F",
    },
    handleNotificationButtons: {
      margin: "0px auto",
      width: "45%",
      height: "28px",
      padding: "10px 0px",
      fontSize: "13px",
    },
    singleNotificationMenu: {
      position: "absolute",
      zIndex: 7000,
    },
    singleNotificationMenuItem: {
      fontSize: "12px",
      // border: "1px solid red",
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      alignItems: "center",
    },
    singleNotificationMenuItemIcon: {
      color: "text.secondary",
      width: "16px",
      padding: "0px",
      // border: "1px solid blue",
      alignSelf: "left",
    },
    singleNotificationMenuItemText: {
      padding: "0px 4px 0px 12px",
      fontSize: "12px",
    },
    singleNotificationMenuItemArrow: {
      color: "text.secondary",
      height: "10px",
      padding: "0px",
      // border: "1px solid blue",
      alignSelf: "left",
    },
  };

  let history = useHistory();

  const [notificationsTab, setNotificationsTab] = useState({
    all: true,
    unread: false,
    read: false,
  });

  //local state to render top 100 notifications on notification tray open
  const [mostRecentNotifications, setMostRecentNotifications] = useState(
    props?.notifications
  );

  useEffect(() => {
    //if tray is open, set load more notifications to true
    if (props?.notificationsAnchor) {
      props?.setLoadMoreNotifications(true);
    }
  }, [props.notificationsAnchor]);

  /////////////////// BUILD OUT INDIVIDUAL NOTIFICAITON STRUCTURE BASED ON NOTIFICAITON TYPE ///////////////////////

  //get notification types from notificationTypeId
  const buildNotificationDisplay = (notification) => {
    let builtNotification;
    // changes made to tickets based on notificationTypeId
    switch (notification?.notificationTypeId) {
      case 0:
        return "Notification error";
      case 1:
        //new ticket created
        builtNotification = (
          <Typography variant="body2" sx={componentStyles.messageBody}>
            A new ticket was created{" "}
            {(notification?.lastModifiedBy && "by") || ""}{" "}
            <strong>
              {notification?.lastModifiedBy
                ? getLastModifiedByUser(notification?.lastModifiedBy)
                : ""}{" "}
            </strong>
            Ticket # <strong>{notification?.entityId || "N/A"}</strong>
            {" | "}{" "}
            <strong>
              Priority:{" "}
              {determineTicketPriority(notification?.priorityId) || "N/A"}
            </strong>
          </Typography>
        );
        break;
      case 2:
        //modified ticket/generic ticket - use changeDescription for this
        //changeDescription only exists for generic tickets - need to figure this one out
        builtNotification = (
          <Typography variant="body2" sx={componentStyles.messageBody}>
            <strong>
              {notification?.changeDescription ||
                getLastModifiedByUser(notification?.lastModifiedBy) +
                  " modified"}{" "}
            </strong>
            Ticket # <strong>{notification?.entityId || "N/A"}</strong> |{" "}
            <strong>
              Priority:{" "}
              {determineTicketPriority(notification?.priorityId) || "N/A"}
            </strong>
          </Typography>
        );
        break;
      case 3:
        //added communitation to ticket
        builtNotification = (
          <Typography variant="body2" sx={componentStyles.messageBody}>
            {(notification?.lastModifiedBy &&
              getLastModifiedByUser(notification?.lastModifiedBy) +
                " added a new communication to ") ||
              "New communication added to "}{" "}
            Ticket # <strong> {notification?.entityId || "N/A"}</strong>
            {" | "}
            <strong>
              {notification?.priorityId &&
                " Priority: " +
                  determineTicketPriority(notification?.priorityId)}
            </strong>
          </Typography>
        );
        break;
      case 4:
        //ticket priority changed
        builtNotification = (
          <Typography variant="body2" sx={componentStyles.messageBody}>
            <strong>
              {notification?.changeDescription || "Ticket priority updated"}{" "}
            </strong>
            Ticket # <strong>{notification?.entityId || "N/A"}</strong> |{" "}
            <strong>
              Priority:{" "}
              {determineTicketPriority(notification?.priorityId) || "N/A"}
            </strong>
          </Typography>
        );
        break;
      case 5:
        //ticket asignee changed
        builtNotification = (
          <Typography variant="body2" sx={componentStyles.messageBody}>
            <strong>
              {notification?.changeDescription || "Assignee updated"}
            </strong>{" "}
            for Ticket #<strong> {notification?.entityId || "N/A"}</strong>
            {" | "}
            <strong>
              {notification?.priorityId &&
                " Priority: " +
                  determineTicketPriority(notification?.priorityId)}
            </strong>
          </Typography>
        );
        break;
      case 6:
        //ticket due date changed
        builtNotification = (
          <Typography variant="body2" sx={componentStyles.messageBody}>
            <strong>
              {notification?.changeDescription || "Due date updated"}{" "}
            </strong>
            Ticket #<strong> {notification?.entityId || "N/A"}</strong>
            <strong>
              {" | "}
              {notification?.priorityId &&
                " Priority: " +
                  determineTicketPriority(notification?.priorityId)}
            </strong>
          </Typography>
        );
        break;
      case 7:
        //ticket status changed
        builtNotification = (
          <Typography variant="body2" sx={componentStyles.messageBody}>
            <strong>
              {notification?.changeDescription || "Ticket status updated"}{" "}
            </strong>
            Ticket # <strong>{notification?.entityId || "N/A"}</strong> |{" "}
            <strong>
              Priority:{" "}
              {determineTicketPriority(notification?.priorityId) || "N/A"}
            </strong>
          </Typography>
        );
        break;
      case 8:
        //entry added
        builtNotification = (
          <Typography variant="body2" sx={componentStyles.messageBody}>
            <strong>
              {notification?.changeDescription || "New entry added"}{" "}
            </strong>
            Ticket # <strong>{notification?.entityId || "N/A"}</strong> |{" "}
            <strong>
              Priority:{" "}
              {determineTicketPriority(notification?.priorityId) || "N/A"}
            </strong>
          </Typography>
        );
        break;
      case 9:
        //ticket type changed
        builtNotification = (
          <Typography variant="body2" sx={componentStyles.messageBody}>
            <strong>
              {notification?.changeDescription || "Ticket type changed"}{" "}
            </strong>
            Ticket # <strong>{notification?.entityId || "N/A"}</strong> |{" "}
            <strong>
              Priority:{" "}
              {determineTicketPriority(notification?.priorityId) || "N/A"}
            </strong>
          </Typography>
        );
        break;
      default:
        builtNotification = (
          <Typography variant="body2" sx={componentStyles.messageBody}>
            <strong>
              {notification?.changeDescription || "New notification"}{" "}
            </strong>
            Ticket # <strong>{notification?.entityId || "N/A"}</strong> |{" "}
            <strong>
              Priority:{" "}
              {determineTicketPriority(notification?.priorityId) || "N/A"}
            </strong>
          </Typography>
        );
    }
    return builtNotification;
  };
  ///////////////

  let notificationBell = document.getElementById("notifications-bell");
  const [pushTray, setPushTray] = useState(0);

  useEffect(() => {
    if (notificationBell) {
      setPushTray(
        window.innerWidth - notificationBell.getBoundingClientRect().right
      );
    }
  }, [notificationBell, window.innerWidth]);

  const [signalRData, setSignalRData] = useState(null);

  //handle new incoming notifications from signalR, not from get call
  let handleIncomingNotification = (message) => {
    //updates state of notifications displayed in notificatoin tray
    // console.log("incoming notification: ", message);
  
    props.setNotifications((previousNotifications) => [
      ...previousNotifications,
      message,
    ])

    //updates notification load count by adding an additional 1 to the current count

    props.setNotificationLoadCount((prevCount) => prevCount + 1);

    //POP-UP NOTIFICATIONS DISPLAY for new incomming w/ Priority Critical
    let _newNotificationAlert = {
      messageDisplay: true,
      notificationId: message?.id,
      // autoHideDuration: 7000,
      backgroundColor: "background.notification.critical",
      severity: "warning",
      message: message?.subjectDescription,
      subjectId: message?.subjectId,
      changeDescription: message?.changeDescription,
      entityId: message?.entityId,
      dateCreated: message?.dateCreated,
      notificationTypeId: message.notificationTypeId,
      lastModifiedByDescription: message?.lastModifiedByDescription,
      priorityId: message?.priorityId,
      statusId: message?.statusId,
    };
    if (message.priorityId > 3) {
      props?.setNewNotificationAlerts((prevNotificationAlerts) => [
        ...prevNotificationAlerts,
        _newNotificationAlert,
      ]);
    }

  };


  const [mounted, setMounted] = useState(false);
  useEffect(() => {
    if (!mounted && signalRConnection?.connection) {
      // signalRConnection.connection.off("Notification", (data) => {
      //   console.log("SignalR change - notification data off", data);
      //   setSignalRData(null);
      // });
      signalRConnection.connection.on("Notification", (message) => {
        console.log("SignalR change - notification data on", message);
        setSignalRData(message);
      });
      // signalRConnection.onNotification((message) => {
      //   console.log(
      //     "SignalR change - In Event Notification + " + JSON.stringify(message)
      //   );
      //   console.log("signalRConnection", signalRConnection.connection);
      //   //  console.log('incoming',input)
      //   setSignalRData(message);
      // });
      // signalRConnection.onChat((message) => {
      //   console.log(
      //     "SignalR change - In Chat Notification " + JSON.stringify(message)
      //   );
      // });
      // signalRConnection.onChat((message) => {
      //    console.log('SignalR change - On Chat Me Too! + ' + JSON.stringify(message));
      // });
      // signalRConnection.onReportNotification((message) => {
      //   console.log(
      //     "SignalR change - On Report Notification:" + JSON.stringify(message)
      //   );
      // });
      // return () => {
      //   signalRConnection.connection.stop();
      // };
      setMounted(true);
    }
  }, [signalRConnection?.connection]);

  //if a new notification comes in, add it to the notifications array, triggered by new notitication
  useEffect(() => {
    if (signalRData) {
      console.log("NEW signalRData: ", signalRData);
      handleIncomingNotification(signalRData);
    }
  }, [signalRData]);

  //ALL NOTIFICATIONS sort and filter for notification tray
  const [sortedAllNotifications, setSortedAllNotifications] = useState([]);
  const [unreadNotifications, setUnreadNotifications] = useState([]);
  const [readNotifications, setReadNotifications] = useState([]);

  //sort all notifications and update list if unread notifications change
  useEffect(() => {
    //sort all notifications by unread and date
    const sortedMessages = mostRecentNotifications?.sort((a, b) => {
      const aIsUnread =
        a.dateViewed === "0001-01-01T00:00:00" || a.dateViewed === null || !a.dateViewed;
      const bIsUnread =
        b.dateViewed === "0001-01-01T00:00:00" || b.dateViewed === null || !b.dateViewed;

      if (aIsUnread && !bIsUnread) {
        return -1; // a comes before b
      } else if (!aIsUnread && bIsUnread) {
        return 1; // b comes before a
      } else {
        // Sort by dateCreated in descending order if both are unread or both are read
        return (
          new Date(b.dateCreated).getTime() - new Date(a.dateCreated).getTime()
        );
      }
    });

    setSortedAllNotifications(sortedMessages);
  }, [
    props?.notifications?.length,
    props?.notifications,
    unreadNotifications?.length,
    unreadNotifications,
    readNotifications?.length,
    mostRecentNotifications,
    mostRecentNotifications?.length,
  ]);

  //set local state notifications to render on notification tray open and whenever read or unread lengths change
  useEffect(() => {
    if (props?.notifications?.length > 0) {
      setMostRecentNotifications(props?.notifications);
    }
  }, [
    props.notifications,
    props.notifications?.length,
    unreadNotifications?.length,
    readNotifications?.length,
  ]);

  //READ/UNREAD NOTIFICATIONS sort and filter
  useEffect(() => {
    if (mostRecentNotifications?.length > 0) {
      //set read notifications
      let read = mostRecentNotifications?.filter((notification) => {
        if (
          notification.dateViewed === "0001-01-01T00:00:00" ||
          notification.dateViewed === null ||
          !notification.dateViewed
        ) {
          return;
        } else return notification;
      });

      //sort read notifications by date
      const sortedRead = read.sort((a, b) => {
        return new Date(b.dateCreated) - new Date(a.dateCreated);
      });
      setReadNotifications(sortedRead);

      //set unread notifications
      let unread = mostRecentNotifications?.filter((notification) => {
        if (
          notification.dateViewed === "0001-01-01T00:00:00" ||
          notification.dateViewed === null ||
          !notification.dateViewed
        ) {
          return notification;
        }
      });

      //sort unread notifications by date
      const sortedUnread = unread.sort((a, b) => {
        return new Date(b.dateCreated) - new Date(a.dateCreated);
      });
      setUnreadNotifications(sortedUnread);

      //set unreadMessageExist value
      if (unread.length > 0) {
        props.setUnreadMessageExist(true);
      } else props.setUnreadMessageExist(false);
    }
  }, [
    props?.notifications?.length,
    props?.notifications,
    signalRData,
    mostRecentNotifications?.length,
    mostRecentNotifications,
  ]);

  //get last modified by user w/ userId from lastModifiedBy
  const getLastModifiedByUser = (userId) => {
    let findMatchingUser = props.allUsers?.find((user) => {
      return user.id === userId;
    });
    return `${findMatchingUser?.firstName} ${findMatchingUser?.lastName}`;
  };

  //Get user initials from userId
  const getInitials = (userId) => {
    let fullName = getLastModifiedByUser(userId);

    if (fullName && fullName.indexOf(" ") !== -1) {
      return fullName
        .split(" ")
        .map((name) => name[0])
        .join("");
    }
  };

  //determine single notification ticket priority
  const determineTicketPriority = (priorityId) => {
    let findMatchingPriority = props.ticketPriorityList?.find((priority) => {
      return priority.id === priorityId;
    });

    //return only the name of the matching priority exists - currently only have low, normal, high
    //placeholder for critical (priorityId = 4)
    if (findMatchingPriority?.name) {
      return findMatchingPriority?.name;
    } else return "Critical";
  };

  //determine single notification ticket status
  const determineTicketStatus = (statusId) => {
    let findMatchingStatus = props?.ticketStatusList?.find((status) => {
      return status.id === statusId;
    });
    //return only the name of the matching status exists
    if (findMatchingStatus?.name) {
      return findMatchingStatus?.name;
    } else return "N/A";
  };

  useEffect(() => {
    if (props?.ticketPriorityList?.length == 0) {
      props.getTicketPriority();
    }
    if (props?.ticketStatusList?.length == 0) {
      props.getTicketStatus();
    }
  }, []);


  //MARK NOTIFICATION AS READ LOGIC
  //single notification mark as read
  const handleSingleNotificationRead = (notificationId) => {
    //mark as read in db
    props.markNotificationAsRead(notificationId);
    let readDate = new Date().toISOString();

    // add read date to notification and update state locally for rerender without another api call
    let updatedNotifications = props?.notifications.map((notification) => {
      if (notification.id == notificationId) {
        return { ...notification, dateViewed: readDate };
      } else {
        return notification;
      }
    });

    props.setNotifications(updatedNotifications);

    handleCloseRowMenu();
  };

  //mark ALL notifications as read button logic
  function handleAllNotificationsRead() {
    //check unread notification list length
    let readDate = new Date().toISOString();
    if (unreadNotifications.length > 0) {
      // Mark all unread notifications as read in the db
      unreadNotifications.forEach((notification) => {
        props.markNotificationAsRead(notification.id);
      });

      // Update the dateViewed property for matched notifications
      const updatedNotifications = unreadNotifications.map((notification) => {
        if (props.notifications.some((note) => note.id === notification.id)) {
          // Matching notification found, update the dateViewed property
          return { ...notification, dateViewed: readDate };
        }
        return notification;
      });

      // Update the state with the updatedNotifications array
      props.setNotifications((prevNotifications) => {
        // Create a new array with the updated dateViewed property
        return prevNotifications.map((notification) => {
          const updatedNotification = updatedNotifications.find(
            (updatedNotif) => updatedNotif.id === notification.id
          );
          return updatedNotification || notification;
        });
      });
    }
  }

  //MARK NOTIFICATION AS UNREAD LOGIC

  const handleSingleNotificationUnread = (notificationId) => {
    props.markNotificationAsUnread(notificationId);

    //filter out newly unread notification from readNotification list
    let updateReadList = readNotifications?.filter(
      (notification) => notification.id !== notificationId
    );
    setReadNotifications(updateReadList);

    handleCloseRowMenu();
  };

  //OPEN SINGLE NOTIFICATION MENU
  //need to update anchor element (anchorEl) to the current notification for each row
  const [singleMenuAnchorEl, setSingleMenuAnchorEl] = useState({});

  const handleOpenRowMenu = (e) => {
    setSingleMenuAnchorEl({
      ...singleMenuAnchorEl,
      [e.currentTarget.value]: e.currentTarget,
    });
  };

  const handleCloseRowMenu = () => {
    setSingleMenuAnchorEl({});
  };

  //on notification click, send user to notification ticket or member

  const goToNotificationTicket = (notification) => {
    //check if notification is unread and mark as read before navigating to ticket
    let _unreadNotification = unreadNotifications?.find(
      (note) => note.entityId === notification.entityId
    );
    if (_unreadNotification) {
      handleSingleNotificationRead(notification.id);
    }

    //currently all notification types are associated with tickets
    //plan to update checks for other notification types outside of tickets in future
    history.push({
      pathname: `/tickets/${notification.entityId}`,
      state: notification?.entityId,
    });

    //if accessed from notification menu, close menu AND close full notification tray
    handleCloseRowMenu();
    props.handleNotificationsClose();
  };

  const goToNotificationMember = (notification) => {
    //check if notification is unread and mark as read before navigating to member
    let _unreadNotification = unreadNotifications?.find(
      (note) => note.entityId === notification.entityId
    );
    if (_unreadNotification) {
      handleSingleNotificationRead(notification.id);
    }

    history.push({
      pathname: `/members/${notification.memberId}`,
      state: notification?.memberId,
    });

    //if accessed from notification menu, close menu AND close full notification tray
    handleCloseRowMenu();
    props.handleNotificationsClose();
  };

  //LOAD ADDITIONAL 20 NOTIFICATIONS ON SCROLL

  const containerRef = useRef(null); // Create a ref to hold the container reference

  const handleLoadNotificiationsOnScroll = () => {
    //stop from continuous loading
    props.setLoadMoreNotifications(false);

    //increase count of load attempts by 20 each time function is called
    let _loadAttempts = 20;
    //calculate total number of additional notifications to load (next 20 + additional notificationLoadCount from incoming signalR notifications)
    let totalNotificationLoadCount =
      _loadAttempts + props?.notificationLoadCount;

    //// increase load count by 20 only when all 20 most recent notifications have been loaded
    if( mostRecentNotifications?.length >= totalNotificationLoadCount) {

      props.setNotificationLoadCount(totalNotificationLoadCount);
    }
    //fetch additional notifications based on offset total
    props.getMyNotifications(totalNotificationLoadCount);
  };

  //scrollbar to top of recently loaded notifications
  const scrollToTop = () => {
    // Scroll the container to the top
    if (containerRef.current) {
      containerRef.current.scrollTo(0, 0);
    }
  };

  return (
    <Box style={{ position: "relative", cursor: "auto", height: "400px" }}>
      <Menu
        anchorEl={props.notificationsAnchor}
        open={Boolean(props.notificationsAnchor)}
        elevation={0}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        PaperProps={{
          sx: {
            left: "auto!important",
            right: `${pushTray - 16}px!important`,
            top: "45px!important",
            overflow: "visible",
            boxShadow: "rgb(189 189 189 / 75%) -1px 4px 3px 1px",
            borderRadius: "5px",
            border: "0px 0px 0px 0px",
            width: "393px",
            // minHeight: "400px",
            marginTop: "15px",
            paddingBottom: "0px",

            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              boxShadow: "-3px -3px 7px -5px rgb(189 189 189 / 60%)",
              top: 6,
              right: 8,
              width: 40,
              height: 40,
              bgcolor: "inherit",
              transform: "translateY(-50%) rotate(45deg)",
              // zIndex: -1,
              zIndex: 0,
            },
          },
        }}
        onClose={props.handleNotificationsClose}
      >
        {/* TABS GO HERE */}
        {/* all notifications */}
        <Box sx={componentStyles.notificationTabs}>
          <Box
            sx={{
              ...componentStyles.singleTabContainer,
              borderBottom: notificationsTab.all ? "2px solid" : "",
              borderBottomColor: notificationsTab.all ? "primary.main" : "",
            }}
          >
            <Box
              onClick={() => setNotificationsTab({ all: true })}
              sx={{
                ...componentStyles.singleTabTitle,
                color: notificationsTab.all ? "primary.main" : "text.primary",
              }}
            >
              All &nbsp;
            </Box>
            <Box
              sx={{
                ...componentStyles.alertInTab,
                backgroundColor: notificationsTab.all ? "#EF5350" : " ",
                color: notificationsTab.all ? "#FFFFFF" : "text.secondary",
                // border: notificationsTab.all ? "none" : "1px solid",
                // borderColor: notificationsTab.all ? "none" : "text.secondary",
              }}
            >
              {unreadNotifications?.length || ""}
            </Box>
          </Box>

          {/* unread notifications */}
          <Box
            sx={{
              ...componentStyles.singleTabContainer,
              borderBottom: notificationsTab.unread ? "2px solid" : "",
              borderBottomColor: notificationsTab.unread ? "primary.main" : "",
            }}
          >
            <Box
              onClick={() => setNotificationsTab({ unread: true })}
              sx={{
                ...componentStyles.singleTabTitle,
                color: notificationsTab.unread
                  ? "primary.main"
                  : "text.primary",
              }}
            >
              Unread &nbsp;
            </Box>
            <Box
              sx={{
                ...componentStyles.alertInTab,
                backgroundColor: notificationsTab.unread ? "#EF5350" : " ",
                color: notificationsTab.unread ? "#FFFFFF" : "text.secondary",
                // border: notificationsTab.unread ? "none" : "1px solid",
                // borderColor: notificationsTab.unread
                //   ? "none"
                //   : "text.secondary",
              }}
            >
              {unreadNotifications?.length || ""}
            </Box>
          </Box>

          {/* read notifications */}
          <Box
            sx={{
              ...componentStyles.singleTabContainer,
              borderBottom: notificationsTab.read ? "2px solid" : "",
              borderBottomColor: notificationsTab.read ? "primary.main" : "",
            }}
          >
            <Box
              onClick={() => setNotificationsTab({ read: true })}
              sx={{
                ...componentStyles.singleTabTitle,
                color: notificationsTab.read ? "primary.main" : "text.primary",
              }}
            >
              Read &nbsp;
            </Box>
            {/* <Box
              sx={{
                ...componentStyles.alertInTab,
                backgroundColor: notificationsTab.read ? "#EF5350" : "#F0F0F0",
                color: notificationsTab.read ? "#FFFFFF" : "",
              }}
            >
            
            </Box> */}
          </Box>
          
        </Box>

        {/* MARK ALL AS READ BUTTON */}
        <Box sx={componentStyles.buttonContainer}>
          <Button
            variant="outlined"
            sx={componentStyles.handleNotificationButtons}
            disabled={
              unreadNotifications?.length === 0 || notificationsTab.read
            }
            onClick={() => handleAllNotificationsRead()}
          >
            Mark all as read
          </Button>
          <Button
            variant="outlined"
            sx={componentStyles.handleNotificationButtons}
            disabled={true}
          >
            Archive all
          </Button>
        </Box>

        {/* NOTIFICATIONS GO HERE */}
        <MenuList
          sx={{ overflow: "auto", height: "325px", padding: "0px 8px" }}
          ref={containerRef}
        >
          <InfiniteScroll
            pageStart={0}
            initialLoad={false}
            loadMore={() => {
             handleLoadNotificiationsOnScroll();
            }}
            hasMore={props?.loadMoreNotifications}
            // loader={
            //   <div className="loader" key={0}>
            //     Loading ...
            //   </div>
            // }
            useWindow={false}
            onScroll={() => {
              scrollToTop();
            }}
          >
            {/* ALL NOTIFICATIONS */}
            {notificationsTab.all &&
              sortedAllNotifications?.length > 0 &&
              sortedAllNotifications.map((notification) => {
                return (
                  <MenuItem
                    key={notification.id}
                    className="flex-row"
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      flexWrap: "wrap",
                      whiteSpace: "normal",
                      alignItems: "center",
                      borderBottom: "1px solid #0000001F",
                      // border: "1px solid red",
                      minHeight: "72px",
                      padding: "8px 4px",
                    }}
                  >
                    <Box
                      sx={{
                        // border: "1px solid red",
                        display: "flex",
                        justifyContent: "center",
                        position: "relative",
                      }}
                    >
                      {/* only show blue dot if unread (date viewed is null or doesn't exist) */}
                      {(!notification.dateViewed ||
                        notification?.dateViewed === "0001-01-01T00:00:00") && (
                        <CircleIcon
                          sx={{
                            color: "primary.main",
                            position: "absolute",
                            height: "8px",
                            alignSelf: "center",
                            width: "8px",
                            margin: "0 auto",
                            left: 0,
                          }}
                        />
                      )}
                      <Avatar
                        sx={{
                          bgcolor: "primary.light",
                          color: "primary.main",
                          fontFamily: "Archivo",
                          fontWeight: "600",
                          width: 30,
                          height: 30,
                          fontSize: 12,
                          margin: "0px 10px",
                        }}
                        // alt={`${notification?.lastModifiedBy} ${notification?.lastName}`}
                        src={`api/image/user/${notification?.lastModifiedBy}`}
                      >
                        {getInitials(notification?.lastModifiedBy) || ""}
                      </Avatar>
                    </Box>

                    {/* meat and potatoes of notification */}
                    <Box
                      className="col-9"
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        fontSize: "11px",
                        lineHeight: "14px",
                        justifyContent: "center",
                        paddingLeft: "8px",
                      }}
                      onClick={() => {
                        goToNotificationTicket(notification);
                      }}
                    >
                      {/* Display ticket details based on notification type - structure determined in function below */}
                      {buildNotificationDisplay(notification)}
                      {/* Notification time and type */}
                      <Typography
                        // className="col-12"
                        sx={componentStyles.messageTimeAndType}
                      >
                        {notification?.dateCreated &&
                          formatDistance(
                            new Date(),
                            new Date(notification.dateCreated)
                          )}{" "}
                        ago{" "}
                        <CircleIcon
                          sx={{ width: "5px", margin: "0 5px", padding: "0px" }}
                        />{" "}
                        {"Ticket "} 
                      </Typography>
                    </Box>

                    {/* three dot vertical menu icon */}
                    <Box className="col-1">
                      <IconButton
                        onClick={(e) => handleOpenRowMenu(e, notification)}
                        value={notification?.id}
                      >
                        <MoreVertIcon
                          sx={{
                            color: "text.secondary",
                            // float: "right",
                          }}
                        />
                      </IconButton>
                      <Menu
                        id="notification-menu"
                        anchorEl={singleMenuAnchorEl?.[notification.id]}
                        open={Boolean(singleMenuAnchorEl?.[notification.id])}
                        onClose={handleCloseRowMenu}
                        sx={componentStyles.singleNotificationMenu}
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                      >
                        {notification.dateViewed === "0001-01-01T00:00:00" ||
                        notification.dateViewed === null ||
                        !notification.dateViewed ? (
                          <MenuItem
                            value={notification.id}
                            sx={componentStyles.singleNotificationMenuItem}
                            onClick={() =>
                              handleSingleNotificationRead(notification.id)
                            }
                          >
                            Mark as read
                          </MenuItem>
                        ) : (
                          <MenuItem
                            value={notification.id}
                            sx={componentStyles.singleNotificationMenuItem}
                            onClick={() =>
                              handleSingleNotificationUnread(notification.id)
                            }
                          >
                            Mark as unread
                          </MenuItem>
                        )}
                        {/* {(notification.dateViewed !== "0001-01-01T00:00:00" ||
                        notification.dateViewed !== null ||
                        !notification.dateViewed) && (
                        <MenuItem
                          value={notification.id}
                          sx={componentStyles.singleNotificationMenuItem}
                          onClick={() =>
                            handleSingleNotificationUnread(notification.id)
                          }
                        >
                          Mark as unread
                        </MenuItem>
                      )} */}
                        <MenuItem
                          value={notification.id}
                          sx={componentStyles.singleNotificationMenuItem}
                          disabled
                        >
                          Archive
                        </MenuItem>
                        <MenuItem
                          value={notification.id}
                          sx={componentStyles.singleNotificationMenuItem}
                          onClick={() => {
                            goToNotificationMember(notification);
                          }}
                        >
                          <PersonIcon
                            sx={componentStyles.singleNotificationMenuItemIcon}
                          />
                          <Typography
                            sx={componentStyles.singleNotificationMenuItemText}
                          >
                            Go to member
                          </Typography>

                          <ArrowForwardIosIcon
                            sx={componentStyles.singleNotificationMenuItemArrow}
                          />
                        </MenuItem>
                        <MenuItem
                          value={notification.id}
                          sx={componentStyles.singleNotificationMenuItem}
                          onClick={() => {
                            goToNotificationTicket(notification);
                          }}
                        >
                          <LocalActivityIcon
                            sx={componentStyles.singleNotificationMenuItemIcon}
                          />
                          <Typography
                            sx={componentStyles.singleNotificationMenuItemText}
                          >
                            Go to ticket
                          </Typography>

                          <ArrowForwardIosIcon
                            sx={componentStyles.singleNotificationMenuItemArrow}
                          />
                        </MenuItem>
                      </Menu>
                    </Box>
                  </MenuItem>
                );
              })}

            {/* UNREAD NOTIFICATIONS */}
            {notificationsTab.unread &&
              unreadNotifications?.length > 0 &&
              unreadNotifications.map((notification, i) => {
                return (
                  <MenuItem
                    key={notification.id}
                    className="flex-row"
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      flexWrap: "wrap",
                      whiteSpace: "normal",
                      alignItems: "center",
                      borderBottom: "1px solid #0000001F",
                      // border: "1px solid red",
                      minHeight: "72px",
                      padding: "8px 4px",
                    }}
                  >
                    <Box
                      sx={{
                        // border: "1px solid red",
                        display: "flex",
                        justifyContent: "center",
                        position: "relative",
                      }}
                    >
                      {/* only show blue dot if unread (date viewed is null or doesn't exist) */}
                      {(!notification.dateViewed ||
                        notification?.dateViewed === "0001-01-01T00:00:00") && (
                        <CircleIcon
                          sx={{
                            color: "#195FFB",
                            position: "absolute",
                            height: "8px",
                            alignSelf: "center",
                            width: "8px",
                            margin: "0 auto",
                            left: 0,
                          }}
                        />
                      )}
                      <Avatar
                        sx={{
                          bgcolor: "primary.light",
                          color: "primary.main",
                          fontFamily: "Archivo",
                          fontWeight: "600",
                          width: 30,
                          height: 30,
                          fontSize: 12,
                          margin: "0px 10px",
                        }}
                        // alt={`${notification?.lastModifiedBy} ${notification?.lastName}`}
                        src={`api/image/user/${notification?.lastModifiedBy}`}
                      >
                        {getInitials(notification?.lastModifiedBy) || ""}
                      </Avatar>
                    </Box>
                    <Box
                      className="col-9"
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        fontSize: "11px",
                        lineHeight: "14px",
                        justifyContent: "center",
                        paddingLeft: "8px",
                      }}
                      onClick={() => {
                        goToNotificationTicket(notification);
                      }}
                    >
                      {/* Display ticket details based on notification type - structure determined in function below */}
                      {buildNotificationDisplay(notification)}

                      {/* Notification time and type */}
                      <Typography
                        // className="col-12"
                        sx={componentStyles.messageTimeAndType}
                      >
                        {notification?.dateCreated &&
                          formatDistance(
                            new Date(),
                            new Date(notification.dateCreated)
                          )}{" "}
                        ago{" "}
                        <CircleIcon
                          sx={{ width: "5px", margin: "0 5px", padding: "0px" }}
                        />{" "}
                        Ticket
                      </Typography>
                    </Box>

                    {/* three dot vertical menu icon */}
                    <Box className="col-1">
                      <IconButton
                        onClick={(e) => handleOpenRowMenu(e, notification)}
                        value={notification?.id}
                      >
                        <MoreVertIcon
                          sx={{
                            color: "text.secondary",
                            // float: "right",
                          }}
                        />
                      </IconButton>
                      <Menu
                        id="notification-menu"
                        anchorEl={singleMenuAnchorEl?.[notification.id]}
                        open={Boolean(singleMenuAnchorEl?.[notification.id])}
                        onClose={handleCloseRowMenu}
                        sx={componentStyles.singleNotificationMenu}
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                      >
                        <MenuItem
                          value={notification.id}
                          sx={componentStyles.singleNotificationMenuItem}
                          onClick={() =>
                            handleSingleNotificationRead(notification.id)
                          }
                        >
                          Mark as read
                        </MenuItem>
                        <MenuItem
                          value={notification.id}
                          sx={componentStyles.singleNotificationMenuItem}
                          disabled
                        >
                          Archive
                        </MenuItem>
                        <MenuItem
                          value={notification.id}
                          sx={componentStyles.singleNotificationMenuItem}
                          onClick={() => {
                            goToNotificationMember(notification);
                          }}
                        >
                          <PersonIcon
                            sx={componentStyles.singleNotificationMenuItemIcon}
                          />
                          <Typography
                            sx={componentStyles.singleNotificationMenuItemText}
                          >
                            Go to member
                          </Typography>

                          <ArrowForwardIosIcon
                            sx={componentStyles.singleNotificationMenuItemArrow}
                          />
                        </MenuItem>
                        <MenuItem
                          value={notification.id}
                          sx={componentStyles.singleNotificationMenuItem}
                          onClick={() => {
                            goToNotificationTicket(notification);
                          }}
                        >
                          <LocalActivityIcon
                            sx={componentStyles.singleNotificationMenuItemIcon}
                          />
                          <Typography
                            sx={componentStyles.singleNotificationMenuItemText}
                          >
                            Go to ticket
                          </Typography>

                          <ArrowForwardIosIcon
                            sx={componentStyles.singleNotificationMenuItemArrow}
                          />
                        </MenuItem>
                      </Menu>
                    </Box>
                  </MenuItem>
                );
              })}

            {/* READ NOTIFICATIONS */}
            {notificationsTab.read &&
              readNotifications?.length > 0 &&
              readNotifications.map((notification, i) => {
                return (
                  <MenuItem
                    key={notification.id}
                    className="flex-row"
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      flexWrap: "wrap",
                      whiteSpace: "normal",
                      alignItems: "center",
                      borderBottom: "1px solid #0000001F",
                      // border: "1px solid red",
                      minHeight: "72px",
                      padding: "8px 4px",
                    }}
                  >
                    <Box
                      sx={{
                        // border: "1px solid red",
                        display: "flex",
                        justifyContent: "center",
                        position: "relative",
                      }}
                    >
                      <Avatar
                        sx={{
                          bgcolor: "primary.light",
                          color: "primary.main",
                          fontFamily: "Archivo",
                          fontWeight: "600",
                          width: 30,
                          height: 30,
                          fontSize: 12,
                          margin: "0px 10px",
                        }}
                        // alt={`${notification?.lastModifiedBy} ${notification?.lastName}`}
                        src={`api/image/user/${notification?.lastModifiedBy}`}
                      >
                        {getInitials(notification?.lastModifiedBy) || ""}
                      </Avatar>
                    </Box>
                    <Box
                      className="col-9"
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        fontSize: "11px",
                        lineHeight: "14px",
                        justifyContent: "center",
                        paddingLeft: "8px",
                      }}
                      onClick={() => {
                        goToNotificationTicket(notification);
                      }}
                    >
                      {/* Display ticket details based on notification type - structure determined in function below */}
                      {buildNotificationDisplay(notification)}

                      {/* Notification time and type */}
                      <Typography
                        // className="col-12"
                        sx={componentStyles.messageTimeAndType}
                      >
                        {notification?.dateCreated &&
                          formatDistance(
                            new Date(),
                            new Date(notification.dateCreated)
                          )}{" "}
                        ago{" "}
                        <CircleIcon
                          sx={{ width: "5px", margin: "0 5px", padding: "0px" }}
                        />{" "}
                        Ticket
                      </Typography>
                    </Box>

                    {/* three dot vertical menu icon */}
                    <Box className="col-1">
                      <IconButton
                        onClick={(e) => handleOpenRowMenu(e, notification)}
                        value={notification?.id}
                      >
                        <MoreVertIcon
                          sx={{
                            color: "text.secondary",
                            // float: "right",
                          }}
                        />
                      </IconButton>
                      <Menu
                        id="notification-menu"
                        anchorEl={singleMenuAnchorEl?.[notification.id]}
                        open={Boolean(singleMenuAnchorEl?.[notification.id])}
                        onClose={handleCloseRowMenu}
                        sx={componentStyles.singleNotificationMenu}
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                      >
                        <MenuItem
                          value={notification.id}
                          sx={componentStyles.singleNotificationMenuItem}
                          onClick={() =>
                            handleSingleNotificationUnread(notification.id)
                          }
                        >
                          Mark as unread
                        </MenuItem>
                        <MenuItem
                          value={notification.id}
                          sx={componentStyles.singleNotificationMenuItem}
                          disabled
                        >
                          Archive
                        </MenuItem>
                        <MenuItem
                          value={notification.id}
                          sx={componentStyles.singleNotificationMenuItem}
                          onClick={() => {
                            goToNotificationMember(notification);
                          }}
                        >
                          <PersonIcon
                            sx={componentStyles.singleNotificationMenuItemIcon}
                          />
                          <Typography
                            sx={componentStyles.singleNotificationMenuItemText}
                          >
                            Go to member
                          </Typography>

                          <ArrowForwardIosIcon
                            sx={componentStyles.singleNotificationMenuItemArrow}
                          />
                        </MenuItem>
                        <MenuItem
                          value={notification.id}
                          sx={componentStyles.singleNotificationMenuItem}
                          onClick={() => {
                            goToNotificationTicket(notification);
                          }}
                        >
                          <LocalActivityIcon
                            sx={componentStyles.singleNotificationMenuItemIcon}
                          />
                          <Typography
                            sx={componentStyles.singleNotificationMenuItemText}
                          >
                            Go to ticket
                          </Typography>

                          <ArrowForwardIosIcon
                            sx={componentStyles.singleNotificationMenuItemArrow}
                          />
                        </MenuItem>
                      </Menu>
                    </Box>
                  </MenuItem>
                );
              })}
          </InfiniteScroll>
        </MenuList>
        {/* <MenuItem 
            sx={{ color: "#646464", fontFamily: "Archivo", fontWeight: "500", padding: "10px", 
            '&:hover': {backgroundColor: '#195FFB10'} }} >
               <SettingsOutlinedIcon /><span style={{ paddingLeft: "10px" }}>Settings</span>
            </MenuItem>
            <MenuItem divider
            sx={{ color: "#646464", fontFamily: "Archivo", fontWeight: "500", padding: "10px", 
            '&:hover': {backgroundColor: '#195FFB10'} }}>
               <PersonOutlineIcon /><span style={{ paddingLeft: "10px" }}>Status</span>
            </MenuItem>
            <MenuItem 
            sx={{ color: "#195FFB", fontFamily: "Archivo", fontWeight: "500", padding: "10px", 
            '&:hover': {backgroundColor: '#195FFB10'} }} >
               <LogoutOutlinedIcon /><span style={{ paddingLeft: "10px" }}>Log Out</span>
            </MenuItem> */}
      </Menu>
    </Box>
  );
}
