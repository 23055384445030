import React, { useEffect, useState } from 'react';
import Lottie from 'lottie-react';
import LightModeAnimation from './Light static simbols.json';
import DarkModeAnimation from './Dark static simbols.json';
import { useMediaQuery, useTheme } from '@mui/material';

export default function InfinityLoader(props) {
  let theme = useTheme();
  const [colorMode, setColorMode] = useState();
  useEffect(() => {
    setColorMode(theme.palette.mode);
  }, [theme.palette.mode]);

  //true if <= md
  // let medBreakpointHit = useMediaQuery(theme.breakpoints.down('sm'))
  // console.log('loader props',props)

  return (
    <Lottie
      className="lottie-div"
      animationData={
        colorMode == 'dark' ? DarkModeAnimation : LightModeAnimation
      }
      loop={true}
      autoPlay={true}
      style={props.style ? props.style : {}}
    />
  );
}
