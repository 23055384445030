import Settings from "../settings";
import { baseService } from "./baseService";

// These routes aren't hooked up yet, but admin CRUD will touch pretty much everything, so it might make sense/ be pragmatic to have it's own frontend service, and it's own backend controller and service

class internalAdminService extends baseService {
   markAsDuplicate(memberId) {
      const url = Settings.API_URL + "admin/markduplicate/" + memberId;
      return this.post(url);
   }

   mergeMembers(dupeMemberObjWithFields,trueMemberObjWithFields) {
      const url = Settings.API_URL + "admin/mergemembers/" + dupeMemberObjWithFields?.id + "/" + trueMemberObjWithFields?.id;
      return this.post(url);
   }
}

const adminService = new internalAdminService();

export default adminService;