import Settings from "../settings";
import { baseService } from "./baseService";

class multiAccountService extends baseService {
    getMe() {
        const url = Settings.API_URL + "account/me";
        return this.get(url);
    }
    getAccount() {
        const url = Settings.API_URL + "account";
        return this.get(url);
    }
    /*
{
        "name": "Cornerstone",
        "subDomain": "cornerstone",
        "street1": "123 Main Street",
        "street2": "",
        "city": "Lake Barrington",
        "state": "IL",
        "zipCode": "12345",
        "phone": "8013186861",
        "fax": "8013186861"
}
     * 
     * 
     * 
     */
    updateAccount(accountId, account) {
        const url = Settings.API_URL + "account/" + accountId;
        return this.put(url, account);
    }
}

const accountService = new multiAccountService();

export default accountService;