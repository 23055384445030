export const permissionConstants = {
    ViewMember: 1,
    EditMember: 2,
    ViewEnrollment: 4,
    EditEnrollment: 5,
    SearchMembers: 6,
    EmulateEnrollment: 7,
    ViewGroupPlans: 8,
    EditGroupPlans: 9,
    ViewClaims: 10,
    EditClaims: 11,
    ViewUsers: 12,
    EditUsers: 13,
    DeleteUsers: 14,
    ViewRoles: 15,
    EditRoles: 16,
    ViewEnrollmentWindows: 18,
    EditEnrollmentWindows: 19,
    ViewOrganizations: 21,
    EditOrganizations: 22,
    ViewPayments: 25,
    EditPayments: 26,
    ViewScheduledPayments: 27,
    EditScheduledPayments: 28,
    ModifyEnrollment: 29,
    ViewMemberSocial: 30,
    ViewBankAccounts: 33,
    EditBankAccounts: 34,
    ViewFullBankAccounts: 35,
    ViewFullSocial: 36,
}

//const permissions =
//    [
//        {
//            "id": 1,
//            "name": "Member (View)"
//        },
//        {
//            "id": 3,
//            "name": "Member (Edit)"
//        },
//        {
//            "id": 4,
//            "name": "Enrollment (View)"
//        },
//        {
//            "id": 5,
//            "name": "Enrollment (Edit)"
//        },
//        {
//            "id": 6,
//            "name": "Member (Search)"
//        },
//        {
//            "id": 7,
//            "name": "Enrollment (Emulate)"
//        },
//        {
//            "id": 8,
//            "name": "Group Plans (View)"
//        },
//        {
//            "id": 9,
//            "name": "Group Plans (Edit)"
//        },
//        {
//            "id": 10,
//            "name": "Claims (View)"
//        },
//        {
//            "id": 11,
//            "name": "Claims (Edit)"
//        },
//        {
//            "id": 12,
//            "name": "Users (View)"
//        },
//        {
//            "id": 13,
//            "name": "Users (Edit)"
//        },
//        {
//            "id": 14,
//            "name": "Users (Delete)"
//        },
//        {
//            "id": 15,
//            "name": "Users (View Roles)"
//        },
//        {
//            "id": 17,
//            "name": "Users (Edit Roles)"
//        },
//        {
//            "id": 18,
//            "name": "Enrollment Windows (View)"
//        },
//        {
//            "id": 19,
//            "name": "Enrollment Windows (Edit)"
//        },
//        {
//            "id": 21,
//            "name": "Organizations (View)"
//        },
//        {
//            "id": 22,
//            "name": "Organizations (Edit)"
//        },
//        {
//            "id": 29,
//            "name": "Enrollment (Modify)"
//        }
//    ];

//let userPermissions = permissions.map(function(element){
//    return element.id;
//});