import Settings from "../settings";
import { baseService } from "./baseService";

class internalOrganizationService extends baseService {
    getEmployers() {
        const url = Settings.API_URL + "organization/employer";
        return this.get(url);
    }

    getOrganizations() {
        const url = Settings.API_URL + "organization";
        return this.get(url);
    }

    getCurrentOrganization() {
        const url = Settings.API_URL + "organization/current";
        return this.get(url);
    }

    getOrganizationsByGroupPlan(groupPlanId) {
        const url = Settings.API_URL + "organization/groupplan/" + groupPlanId;
        return this.get(url);
    }

    getEmployersByOrganization(organizationId) {
        const url = Settings.API_URL + "organization/employer/" + organizationId;
        return this.get(url);
    }

    getAffiliatedEmployersByOrganization(organizationId) {
        const url = Settings.API_URL + "organization/parent/employer/" + organizationId;
        return this.get(url);
    }

    updateOrganization(organizationId, organization) {
        const url = Settings.API_URL + "organization/" + organizationId;
        return this.put(url, organization);
    }

    /* Takes the form of below
     * {
     *   "image": "base64"
     * } 
     */
    updateOrganizationImage(organizationId, organizationImage) {
        const url = Settings.API_URL + "organization/" + organizationId + "/image";
        return this.put(url, organizationImage);
    }
}

const organizationService = new internalOrganizationService();

export default organizationService;