import React, { useEffect, useState } from "react"

//Services

//Mui Components
import { Box, Button, Collapse, Paper, Typography } from "@mui/material"

//Mui icons
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import ExpandLessIcon from "@mui/icons-material/ExpandLess"

const componentStyles = {
  componentBody: {
    width: "100%",
    borderRadius: "0px",
    fontFamily: "Poppins",
    backgroundColor: "background.default",
    overflowY: "auto",
    paddingBottom: "16px",
    borderBottom: "1px solid rgba(217, 217, 217, 1)",
    padding: "16px",
  },
  headerContainer: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "top",
    margin: "0 auto",
  },
  title: {
    color: "text.primary",
    fontFamily: "Poppins",
    fontSize: "20px",
    fontWeight: "500",
  },
  description: {
    color: "text.secondary",
    fontFamily: "Archivo",
    fontSize: "14px",
    fontWeight: "400",
    paddingBottom: "6px",
  },
  plansListContainer: {
    display: "flex",
    flexDirection: "column",
    margin: "0 auto",
  },
  singleLineItem: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  lineItemName: { fontSize: "14px", paddingBottom: "18px" },
  lineItemValue: { fontSize: "14px" },
  transFee: (isPeakPayTransFee) => ({
    fontSize: "14px",
    color: isPeakPayTransFee ? "text.primary" : "text.disabled",
  }),
  transFeeValue: (isPeakPayTransFee) => ({
    fontSize: "14px",
    color: isPeakPayTransFee ? "text.primary" : "text.disabled",
  }),
  monthlyTotalRow: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "18px auto",
  },
  monthlyTotalValue: {
    fontSize: "20px",
    fontFamily: "Poppins",
    fontWeight: "500",
  },
  subtotalMessage: { fontSize: "12px!important" },
}
export default function MemberPlanPaymentSummary(props) {
  const [expanded, setExpanded] = useState()
  //open/close collapsible content
  const handleExpandClick = (e) => {
    setExpanded((prev) => !prev)
  }

  let isPeakPay =
    props?.paymentSchedule?.length > 0
      ? props?.paymentSchedule[0]?.isPeakPay
      : false


  //organize cost data from single obj to array of objs for each {line of coverage: cost per line of coverage}
  const [totalPlanCostArr, setTotalPlanCostArr] = useState([])
  useEffect(() => {
    if (props?.totalPlanCosts) {
      const _totalPlanCostArr = Object.entries(props.totalPlanCosts)
        .filter(([key]) => key !== "Monthly Total" && key !== "Transaction Fee")
        .map(([key, value]) => ({ [key]: value }))
      setTotalPlanCostArr(_totalPlanCostArr)
    }
  }, [props.totalPlanCosts])

  const formatLineOfcoverageName = (lineOfCoverageName) => {
    switch (lineOfCoverageName) {
      case "Short Term Disability":
        return "STD"
      case "Long Term Disability":
        return "LTD"
      case "Life and Death":
        return "LADD"
      case "Life and Death Dependent":
        return "LADD - Dependent"
      default:
        return lineOfCoverageName
    }
  }

  return (
    <Paper className="" elevation={0} sx={componentStyles.componentBody}>
      <Box sx={componentStyles.headerContainer}>
        <Box>
          <Typography variant="h6" sx={componentStyles.title}>
            Payment Summary
          </Typography>
          <Typography variant="body2" sx={componentStyles.description}>
            View payment breakdown for all plans.
          </Typography>
        </Box>
        <Box>
          <Button
            // disabled={!allowEdit}
            fullWidth
            variant="text"
            onClick={handleExpandClick}
            sx={{
              fontSize: "13px",
              fontWeight: 400,
              padding: "30px 0px",
              height: "20px",
              color: "primary.main",
              borderRadius: "100%",
              textAlign: "right",
            }}
          >
            {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </Button>
        </Box>
      </Box>
      <Collapse sx={{}} in={expanded} unmountOnExit>
        <Box sx={componentStyles.plansListContainer}>
          {/* rename plan names to display abbreviations */}
          {props?.totalPlanCosts &&
            totalPlanCostArr.map((plan, index) => {
              let planName = Object.keys(plan)[0]
              let value = plan[planName]
              planName = formatLineOfcoverageName(planName)

              if (planName) {
                return (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      sx={{ fontSize: "14px", paddingBottom: "12px" }}
                    >
                      {planName}{" "}
                    </Typography>
                    <Typography sx={componentStyles.lineItemValue}>
                      {value && props.UsdFormatterDec.format(value)}{" "}
                    </Typography>
                  </Box>
                )
              }
            })}
          <Box sx={componentStyles.singleLineItem}>
            <Typography sx={componentStyles.transFee(isPeakPay)}>
              Transaction Fee
            </Typography>
            <Typography sx={componentStyles.transFeeValue(isPeakPay)}>
              {props?.totalPlanCosts?.["Transaction Fee"] && isPeakPay
                ? props.UsdFormatterDec.format(
                    props?.totalPlanCosts["Transaction Fee"]
                  )
                : "-"}
            </Typography>
          </Box>
        </Box>
      </Collapse>
      <Box sx={componentStyles.monthlyTotalRow}>
        <Typography>{isPeakPay ? "Monthly Total" : "Subtotal*"}</Typography>
        <Typography sx={componentStyles.monthlyTotalValue}>
          {isPeakPay
            ? props.UsdFormatterDec.format(
                props?.totalPlanCosts?.["Monthly Total"] +
                  props?.totalPlanCosts?.["Transaction Fee"] || 0
              )
            : props.UsdFormatterDec.format(
                props?.totalPlanCosts?.["Monthly Total"] || 0
              )}
        </Typography>
      </Box>

      {/* subtotal disclosure for non- peak pay */}
      {!isPeakPay && props?.memberHasActiveCoverage && (
        <Typography sx={componentStyles.subtotalMessage}>
          *Subtotal reflects payment balance without transaction fees
        </Typography>
      )}
    </Paper>
  )
}
