import React, { useEffect, useState, useRef } from 'react';

//MuiComponents
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Paper from '@mui/material/Paper';
import Alert from '@mui/material/Alert';
import Link from '@mui/material/Link';
import MenuItem from '@mui/material/MenuItem';

//MuiIcons
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';

//our components
import ButtonInfinityLoader from '../../../../../UH-loading-animation/ButtonInfinityLoader';

//Component Styles
const componentStyles = {
  modalBody: {
    position: 'absolute',
    borderRadius: '4px 4px 4px 4px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: '444px',
    margin: '0 auto',
  },
  messageContainer: {
    borderRadius: '12px 12px 12px 12px',
    padding: '16px 16px 24px 36px',
    bgcolor: 'background.default',
    color: 'text.primary',
    minWidth: '425px',
    minHeight: '152px',
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '10px',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  messageBody: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignSelf: 'flex-end',
    flexWrap: 'wrap',
    margin: '20px 0px 0px auto',
  },
};

export default function PilotStatusModal(props) {

  const confirmUpdatePilotStatus = async () => {
    let _updatedSureveyResponses = await props.updateSurveyResponse(
      'isPilot',
      props?.surveyResponses?.isPilot ? false : true
    );
    console.log('updatedSureveyResponses', _updatedSureveyResponses);

  };
  return (
    <Modal
      open={props?.openPilotModal}
      onClose={props?.handleClosePilotModal}
      sx={componentStyles.modalBody}
      disableScrollLock={true}
    >
      <Paper sx={componentStyles.messageContainer}>
        <Box sx={componentStyles.headerContainer}>
          <Typography sx={{ fontSize: '24px' }}>Are you sure?</Typography>
        </Box>
        <Box sx={componentStyles.messageBody}>
          <Box>
            <Typography>
              {props?.surveyResponses?.isPilot
                ? `Disabling pilot enrollment will switch the member's plan to the Teamsters Voluntary Income Protection VIP+ Plan. Do you want to continue?`
                : `Enabling pilot enrollment will switch the member's plan to the IBT
              VIP Air Plan. Do you want to continue?`}
            </Typography>
          </Box>
        </Box>

        {/* Buttons */}
        <Box sx={componentStyles.buttonContainer}>
          <Button
            sx={{ marginRight: '10px' }}
            onClick={props?.handleClosePilotModal}
          >
            NEVERMIND
          </Button>
          <Button
            variant="contained"
            disabled={props?.loading}
            onClick={() => confirmUpdatePilotStatus()}
          >
            {props?.loading ? (
              <ButtonInfinityLoader
                buttonTitle={`YES, ${
                  props?.surveyResponses?.isPilot ? 'DISABLE' : 'ENABLE'
                }`}
              />
            ) : (
              `YES, ${props?.surveyResponses?.isPilot ? 'DISABLE' : 'ENABLE'}`
            )}
          </Button>
        </Box>
      </Paper>
    </Modal>
  );
}
