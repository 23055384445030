import { Box, Button } from '@mui/material';

//component styles
const componentStyles = {
  componentContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    paddingTop: '20px',
    marginTop: 'auto',
    gap: '8px',
  },
  buttonStyles: {
    height: '50px',
  },
};
export default function AgentNavigation(props) {
  const backClickHandler = () => {
    props.setAgentEnrollmentPageState({ [props.lastLocation]: true });
  };

  return (
    <>
      <Box sx={componentStyles.componentContainer}>
        {props.allowBack && (
          <Button
            onClick={backClickHandler}
            sx={componentStyles.buttonStyles}
            variant="outlined"
          >
            Back
          </Button>
        )}

        {/* Next Button disabled IF validations not met on member self enrollment OR on agent side if LADD selected w/ NO bennies added */}

        <Button
          // onClick={(e) => {
          //   props.nextClickHandler(props.nextLocation);
          // }}
          //   onClick={() => {
          //     if (!props?.notActivelyWorking){
          //        props.nextClickHandler(props.nextLocation)
          //     } else {
          //        if (props.handleNawExit) props.handleNawExit()
          //     }
          //  }}
          onClick={() => props.nextClickHandler(props.nextLocation)}
          sx={{ height: 50 }}
          variant="contained"
          disabled={
            props.nextValidation
              ? props.nextValidation
              : false ||
                (props.primaryBeneficiaries?.length < 1 && !props.addedBenny) ||
                props.primaryTotalError || props?.contingentTotalError
          }
        >
          {/* {(props?.notActivelyWorking && props?.nextLocation) == 'payment' ? 'Save & Exit' : 'Next'} */}
          Next
        </Button>
      </Box>
    </>
  );
}
