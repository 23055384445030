import './ExternalResetPassword.css';

import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

//Assets
import Logo from '../../../assets/images/LogoVector.svg';
import Aliens from '../../../assets/images/Group 5.svg';
import defaultLogo from "../../../assets/images/FullColorOnlyLogo.png";


//Services
import registerService from '../../../services/register-service';

//MuiComponents
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Box from '@mui/material/Box';

//MuiIcons
import VisibilityIcon from '@mui/icons-material/Visibility';
import NoVisibilityIcon from '@mui/icons-material/VisibilityOff';

//Our Components
import PasswordStrength from '../password-strength/PasswordStrength';


function ExternalResetPassword(props) {
    const [values, setValues] = useState({
        email: '',
        password: '',
        isReset: false,
    });

    const history = useHistory();
    const location = useLocation();

    //Password Visibility
    const [displayPassword, setDisplayPassword] = useState(false);
    const swapIcon = (e) => {
        //e.preventDefault();

        let inputElement = e.target.parentNode.children[0];
        if (displayPassword) {
            inputElement.setAttribute("type", "password");
            setDisplayPassword(false);
        } else {
            inputElement.setAttribute("type", "text");
            setDisplayPassword(true);
        }
    }

    const visibilityIcon = <div style={{ position: "absolute", right: "5%" }} type="button" onMouseDown={swapIcon} onMouseUp={swapIcon} onTouchStart={swapIcon} onTouchEnd={swapIcon}><VisibilityIcon sx={{ color: "#7D7987", cursor: "pointer" }} /></div>
    const noVisibilityIcon = <div style={{ position: "absolute", right: "5%" }} type="button" onClick={swapIcon}><NoVisibilityIcon sx={{ color: "#7D7987", cursor: "pointer" }} /></div>

    let PasswordIcon = displayPassword ? noVisibilityIcon : visibilityIcon;

    const [disableButton, setDisableButton] = useState(true)

    //Handlers
    const handlePasswordChange = (event) => {
        event.persist();
        setValues({
            ...values,
            password: event.target.value,
        })
    };

    const passwordKeyPressHandler = (e) => {
        if (e.key === 'Enter' && !disableButton) resetPassword()
        if (e.key === ' ') e.preventDefault()
    }

    //Password strength props
    const strengthPassed = (e) => {
        setDisableButton(false)
    }
    const strengthNotPassed = (e) => {
        setDisableButton(true)
    }

    const resetPassword = async () => {
        const guid = location.pathname.replace("/resetlink/", "");

        setValues({
            ...values,
            isReset: true,
        })

        await registerService.updatePassword(guid, values.password);
    }

    const returnToLogin = () => {
        history.push("/login")
    }


    return (

            <Box className="col-12 " 
            style={{ display:'block', flexWrap: 'wrap', height: '100vh', margin: 'auto',overflowY: 'hidden', overflowX: 'hidden', maxWidth:'575px' }} 
            sx={{
                bgcolor: 'background.default',
                color: 'text.primary'
             }}>
                {!values.isReset &&
                <form className="container">
                    <div className="reset-password-req">
                        <h5>Password Must Contain:</h5>
                        <ul className="reset-password-ul">
                            <li>At least 8 characters</li>
                            <li>A mixture of uppercase and lowercase letters</li>
                            <li>A mixture of letters and numbers</li>
                        </ul>
                    </div>
                    <div className="flex-row" style={{ marginBottom: '30px', width: '100%' }}>
                        <TextField type="password" label="Password" placeholder="Password" 
                        onChange={handlePasswordChange} 
                        onKeyPress={passwordKeyPressHandler}
                        sx={{marginBottom:'10px'}}
                        fullWidth
                        InputProps={{ endAdornment: PasswordIcon }} 
                        />
                    </div>
                    <div className="flex-row" style={{ marginBottom: '30px', width: '100%' }}>
                        <PasswordStrength passwordValue={values.password} strengthPassed={strengthPassed} strengthNotPassed={strengthNotPassed} />
                    </div>
                        
                    <div style={{display:'flex',justifyContent:'center'}}>
                        <Button  variant="contained" disabled={disableButton} onClick={resetPassword} >RESET PASSWORD</Button>
                    </div>
                </form>
                }
                {values.isReset &&
                <React.Fragment>
                    <div className="col-xs-12" style={{display:'flex', justifyContent:'center',alignItems:'center',height:'200px'}}>
                        <img
                            alt="logo"
                            src={defaultLogo}
                            style={{
                            maxHeight: "100px",
                            maxWidth: "100%",
                            objectFit: "contain",
                            }}
                        />
                    </div>
                    <div className="reset-alert-container">
                        <Alert className="reset-password-alert" severity="success">
                            <AlertTitle className="reset-password-title">Password reset</AlertTitle>
                        </Alert>
                        <div style={{display:'flex',justifyContent:'center'}}>
                            <Button  variant="contained"  onClick={returnToLogin} >RETURN TO LOGIN</Button>
                        </div>
                    </div>
                </React.Fragment>

                }
            </Box>

    )

}

export default ExternalResetPassword;