import React, { useEffect, useState } from "react"
import './CalculatorLadd.css'

//MuiIcons
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';


//MuiComponents
import Collapse from '@mui/material/Collapse';
import Slider from '@mui/material/Slider'
import Checkbox from '@mui/material/Checkbox';
import Radio from '@mui/material/Radio'
import RadioGroup from "@mui/material/RadioGroup";
import Typography from "@mui/material/Typography";

//Our Components
import CalculatorLaddMember from "./calculator-ladd-member/CalculatorLaddMember";
import CalculatorLaddSpouse from "./calculator-ladd-spouse/CalculatorLaddSpouse";
import CalculatorLaddDependent from "./calculator-ladd-dependent/CalculatorLaddDependent";


export default function CalculatorLadd(props) {
    let UsdFormatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0 });
    let UsdFormatterDec = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 2 });
    let percentFormatter = new Intl.NumberFormat('en-US', { style: 'percent' })

    let results = props.results
    let lifeADDPlan = results?.lifeADDPlan
    let lifeADDHelptext = lifeADDPlan?.helpText
    let recommendations = props.recommendations
    // console.log("recommendations",recommendations)
    //Alert
    const [displayAlert, setDisplayAlert] = useState(false);

    //member
    const [memberSlider, setMemberSlider] = useState(lifeADDPlan.memberOptions.length - 1)
    // const memberValuetext = (value) => `${UsdFormatter.format(lifeADDPlan?.memberOptions[value].coverageAmount)}`

    //spouse
    const [spouseSlider, setSpouseSlider] = useState(results?.lifeADDPlan.spouseOptions.length - 1)
    // const spouseValuetext = (value) => `${UsdFormatter.format(lifeADDPlan?.spouseOptions[value].coverageAmount)}`

    //Variable and states to handle capping the spouse/dependent coverage
    let spouseMax = lifeADDPlan?.memberOptions[memberSlider]?.coverageAmount * lifeADDPlan?.spousePercentageCap;
    const [spouseMaxIndex, setSpouseMaxIndex] = useState(results?.lifeADDPlan.spouseOptions.length - 1)
    const [dependentMax, setDependentMax] = useState(results?.lifeADDPlan.dependentMaximumAmount)

    //Changes in our member slider have effects on our spouse and dependent
    useEffect(() => {
        spouseMax = lifeADDPlan?.memberOptions[memberSlider]?.coverageAmount * lifeADDPlan?.spousePercentageCap;
        let currentSpouse = lifeADDPlan?.spouseOptions[spouseSlider]?.coverageAmount;

        //Matching the capped coverage in the options arr, then setting the states accordingly
        if (spouseMax <= lifeADDPlan?.spouseMaximumAmount) {
            let count = 0;
            for (let option of lifeADDPlan?.spouseOptions) {
                if (option.coverageAmount <= spouseMax) {
                    setSpouseMaxIndex(count)
                    if (spouseMax < currentSpouse) {
                        setSpouseSlider(count)
                    }
                }
                count += 1;
            }
        } else {
            //THIS IS WHERE THE SPOUSE SLIDER IS BEING MAXED
            setSpouseMaxIndex(results?.lifeADDPlan.spouseOptions.length - 1)
            // setSpouseSlider(results?.lifeADDPlan.spouseOptions.length-1)
        }
        let dependentEnabled = lifeADDPlan?.dependentMaximumAmount <= lifeADDPlan?.memberOptions[memberSlider]?.coverageAmount * lifeADDPlan.dependentPercentageCap;
        if (!dependentEnabled) setDependentMax(0)
        else setDependentMax(lifeADDPlan?.dependentMaximumAmount)
    }, [memberSlider])

    //Selection handler, state and effects
    const [checkboxes, setCheckboxes] = useState({
        member: "truthy", //This truthy value lets us display as if member was selected, but still requires user interaction
        spouse: false,
        dependent: false
    })
    //unchecking member clears the values
    useEffect(() => {
        if (!checkboxes.member) {
            props.setSelections({
                ...props.selections,
                laddVal: [0, 0, 0]
            })
        }
    }, [checkboxes.member])
    const laddSelectionHandler = (e, bool) => {
        let checkedBool = Boolean(bool)
        let value = parseFloat(e.target.getAttribute('data-amount'))
        let person = e.target.getAttribute('data-person')
        let laddIndex = parseInt(e.target.name)

        //Member needs to be selected for spouse and dependent to be avail
        if (person == "member" && !value) {
            setCheckboxes({
                member: false,
                spouse: false,
                dependent: false
            })
            return //exit and trigger useEffect above
        }
        else {
            setCheckboxes({
                ...checkboxes,
                [person]: checkedBool
            })
        }
        setCheckboxes({
            ...checkboxes,
            [person]: checkedBool
        })
        if (person == "dependent" && dependentMax == 0) value = 0
        if (bool) {
            let _laddVal = props.selections.laddVal.map(x => x)
            _laddVal[laddIndex] = value
            props.setSelections({
                ...props.selections,
                laddVal: _laddVal
            })
        } else {
            let _laddVal = props.selections.laddVal.map(x => x)
            _laddVal[laddIndex] = 0
            props.setSelections({
                ...props.selections,
                laddVal: _laddVal
            })
        }
    }

    //As the sliders slide, if they're checked, let the value ride
    useEffect(() => {
        let _laddVal = props.selections.laddVal.map(x => x)
        if (checkboxes.member === true) {
            _laddVal[0] = lifeADDPlan?.memberOptions[memberSlider].premiumAmount
        }
        if (checkboxes.spouse) {
            _laddVal[1] = lifeADDPlan?.spouseOptions[spouseSlider].premiumAmount
        }
        if (checkboxes.dependent) {
            _laddVal[2] = results?.lifeADDPlan.dependentOptions[0].premiumAmount
        }
        if (checkboxes.dependent && dependentMax == 0) {
            _laddVal[2] = 0
        }
        else if (dependentMax == 0) {
            _laddVal[2] = 0
        }
        props.setSelections({
            ...props.selections,
            laddVal: _laddVal
        })
    }, [memberSlider, spouseSlider, dependentMax])

    //Slider handlers
    const memberSliderChange = (e) => setMemberSlider(e.target.value)
    const spouseSliderChange = (e) => {
        if (e.target.value <= spouseMaxIndex) setSpouseSlider(e.target.value)
        else setSpouseSlider(spouseMaxIndex)
    }

    const [expanded, setExpanded] = useState(true)
    const handleExpandClick = () => {
        setExpanded(prev => !prev)
    };
    let expandIcon = expanded ? <RemoveCircleIcon fontSize="large" sx={{ position: "absolute", right: "14%", top: "20%", color: "#195ffb" }} /> : <AddCircleIcon fontSize="large" sx={{ position: "absolute", right: "14%", top: "20%", color: "#195ffb" }} />;


    let marks = (0 < spouseMaxIndex && spouseMaxIndex != lifeADDPlan?.spouseOptions.length - 1) ? [
        { value: 0, label: "$" + results?.lifeADDPlan.spouseOptions[0]?.coverageAmount / 1000 + "K" },
        { value: spouseMaxIndex, label: "$" + results?.lifeADDPlan.spouseOptions[spouseMaxIndex]?.coverageAmount / 1000 + "K" },
        { value: results?.lifeADDPlan.spouseOptions.length - 1, label: "$" + results?.lifeADDPlan.spouseOptions[results?.lifeADDPlan.spouseOptions.length - 1]?.coverageAmount / 1000 + "K" },
    ] : [
        { value: 0, label: "$" + results?.lifeADDPlan.spouseOptions[0]?.coverageAmount / 1000 + "K" },
        { value: results?.lifeADDPlan.spouseOptions.length - 1, label: "$" + results?.lifeADDPlan.spouseOptions[results?.lifeADDPlan.spouseOptions.length - 1]?.coverageAmount / 1000 + "K" },
    ]

    //Showing spouse/dependent alert; setting label styles for slider
    useEffect(() => {
        let collection = document.getElementsByClassName("MuiSlider-markLabel")

        if (spouseMax < lifeADDPlan.spouseMaximumAmount) setDisplayAlert(true)
        else setDisplayAlert(false)
        for (let i = 0; i < collection.length; i++) {

            let sliderLabel = collection[i];
            let dataIndex = sliderLabel.getAttribute("data-index");
            let leftLabelStyle = "left:0%; color:#195ffb; transform: translateX(0%); top:-50%; font-weight:600; font-size:16px;";
            let rightLabelStyle = "left:100%; color:#195ffb; transform: translateX(-100%); top:-50%; font-weight:600; font-size:16px;";
            let disabledRightLabelStyle = "left:100%; color:#D3D3D3; transform: translateX(-100%); top:-50%; font-weight:600; font-size:16px;";

            let setStyle = (style) => {
                sliderLabel.setAttribute("style", style)
            }

            if (dataIndex == 0) {
                setStyle(leftLabelStyle)
            }
            if (dataIndex == 1 && collection.length == 4) {
                setStyle(rightLabelStyle)
            }
            if (i > 1 && dataIndex == 1 && collection.length == 5) {
                // let _style = sliderLabel.getAttribute("style")
                // setStyle(_style)
            }
            if (i > 1 && dataIndex == 1 && collection.length == 4 && spouseSlider == 0 && spouseMax < lifeADDPlan.spouseMaximumAmount) {
                setStyle(disabledRightLabelStyle)
            }
            if (i > 1 && dataIndex == 2 && displayAlert) {
                setStyle(disabledRightLabelStyle)
            }
            if (i > 1 && dataIndex == 2 && collection.length == 5 && spouseMax > lifeADDPlan.spouseMaximumAmount) {
                setStyle(rightLabelStyle)
            }
            if ((dataIndex == 2 && spouseMax == lifeADDPlan.spouseMaximumAmount)) {
                setStyle(rightLabelStyle)
            }
            if (dataIndex == 1 && sliderLabel.getAttribute("style") == "left: 11.1111%; color: rgb(25, 91, 191); top: -50%; font-weight:600; font-size:16px;") {
                setStyle("left: 16.1111%; color: rgb(25, 91, 191); top:-50%; font-weight:600; font-size:16px;")
                //16.1111 seems to be the limit for no overlap
                //Could do this through a class to make it easier for media queries to adjust the percentage
                // sliderLabel.className += " left-crowded-label"
            }
        }
        // console.log(lifeADDPlan)
    }, [memberSlider, spouseSlider, displayAlert])

    useEffect(() => {
        let recommendedSpousePosition = checkSpouseSliderAtRec()
        setSpouseSlider(recommendedSpousePosition)

        let recommendedMemberPosition = checkMemberSliderAtRec()
        setMemberSlider(recommendedMemberPosition)

    }, [recommendations])

    const checkMemberSliderAtRec = () => {
        let recommendationPosition = 0;
        if (results?.lifeADDPlan.memberOptions.length > 1) {
            recommendations.Lowest ? recommendationPosition = 0 :
                !recommendations.Lowest && recommendations.ShowSpouse && recommendations.ShowDependent ? recommendationPosition = (results?.lifeADDPlan.memberOptions.length - 2) :
                    recommendationPosition = (results?.lifeADDPlan.memberOptions.length - 1)
        }
        return recommendationPosition
    }
    const checkSpouseSliderAtRec = () => {
        let recommendationPosition = 0;
        if (results?.lifeADDPlan.spouseOptions.length > 1) {
            recommendations.SpouseLowest ? recommendationPosition = 0 :
                recommendations.SpouseSecondHighest ? recommendationPosition = (results?.lifeADDPlan.spouseOptions.length - 2)
                    : recommendationPosition = (results?.lifeADDPlan.spouseOptions.length - 1)
        }
        return recommendationPosition
    }

    // console.log(lifeADDPlan)

    // console.log(memberSlider)

    return (
        <>
            <div style={{ marginBottom: "10px" }} id="ladd-calc-wrapper">
                <div className="" style={{ backgroundColor: "#1B78AF12", minHeight: "57px", fontSize: "17px", fontWeight: 700, padding: "16px 38px 17px 16px", position: "relative" }}>
                    <Typography variant='h6' fontSize='16px' fontWeight='600' sx={{ cursor: "pointer", position: "relative" }} onClick={handleExpandClick}>
                        {/* {results.lifeADDPlan.title} */}
                        Life Insurance and AD&D
                        <div style={{ position: "absolute", width: "100%", height: "300%", top: "-100%" }}></div>
                    </Typography>
                    <div onClick={handleExpandClick} style={{ position: "absolute", right: "3%", top: "0%", color: "#195ffb", width: "18%", height: "100%", cursor: "pointer" }}>
                        {expandIcon}
                    </div>
                </div>
                <Collapse in={expanded}>
                    <div style={{ padding: "20px 20px 0px 20px" }}>
                        <span dangerouslySetInnerHTML={{ __html: lifeADDHelptext }}></span>
                    </div>
                    {/* HANDLING UNLOADED STATE, ONE OF MANY WAYS */}
                    {/* COULD ALSO USE OPTIONAL CHAIN results?.stdPlan */}
                    {!results && <h1>not loaded</h1>}
                    {results && props.calculated &&
                        <RadioGroup value={checkboxes.member} onChange={laddSelectionHandler}>

                            {/* MEMBER */}
                            <CalculatorLaddMember
                                memberSlider={memberSlider} memberSliderChange={memberSliderChange}
                                checkboxes={checkboxes} results={results}
                                dummyDisplay={props.dummyDisplay} dummyFontColor={props.dummyFontColor}
                                recCheck={checkMemberSliderAtRec} calculated={props.calculated}
                            />

                            {/* SPOUSE */}
                            {/* {props.survey.IsMarried &&  */}
                            <CalculatorLaddSpouse
                                spouseSlider={spouseSlider} spouseSliderChange={spouseSliderChange}
                                checkboxes={checkboxes} results={results}
                                dummyDisplay={props.dummyDisplay} dummyFontColor={props.dummyFontColor}
                                displayAlert={displayAlert} laddSelectionHandler={laddSelectionHandler}
                                marks={marks} expanded={expanded}
                                survey={props.survey} recCheck={checkSpouseSliderAtRec}
                                calculated={props.calculated}
                            />
                            {/* } */}

                            {/* DEPENDENT */}
                            {/* {props.survey.HaveDependents && */}
                            <CalculatorLaddDependent
                                checkboxes={checkboxes} results={results}
                                dummyDisplay={props.dummyDisplay} dummyFontColor={props.dummyFontColor}
                                displayAlert={displayAlert} laddSelectionHandler={laddSelectionHandler}
                                expanded={expanded} dependentMax={dependentMax}
                                survey={props.survey} calculated={props.calculated}
                            />
                            {/* } */}

                            {/* NOT NOW */}
                            <div className='flex-row container' style={{ padding: "0px 0px 0px 0vw", position: "relative", marginBottom: "0px" }}>
                                <Radio
                                    value={false}
                                    name={0}
                                    onClick={() => setExpanded(false)}
                                    inputProps={{
                                        'data-amount': 0,
                                        'data-person': "member"
                                    }}
                                />
                                <div style={{ alignSelf: "center", fontWeight: (!checkboxes.member ? 800 : 400) }}>Not Now</div>
                            </div>
                        </RadioGroup>
                    }
                </Collapse>
                {!expanded &&
                    <>
                        <div className="memberPreview">
                            <div style={{ padding: "0px 0px 0px 15px", margin: "15px 0px", fontSize: "18px", fontWeight: "500" }}>
                                {checkboxes.member === true &&
                                    <>
                                        <span>Member : {UsdFormatter.format(results?.lifeADDPlan.memberOptions[memberSlider].coverageAmount)}</span>
                                        <span style={{ float: "right", marginRight: "15px", fontWeight: "800" }}>+ {UsdFormatterDec.format(props.selections.laddVal[0])}</span>
                                    </>
                                }
                                {checkboxes.member !== true &&
                                    <>
                                        <span >Member : <em>waived</em></span>
                                        <span style={{ float: "right", marginRight: "15px", color: "#00000033" }}>$0.00</span>
                                    </>
                                }
                            </div>
                        </div>
                        <div className="memberPreview">
                            <div style={{ padding: "0px 0px 0px 15px", margin: "15px 0px", fontSize: "18px", fontWeight: "500" }}>
                                {checkboxes.spouse &&
                                    <>
                                        <span>Spouse : {UsdFormatter.format(results?.lifeADDPlan.spouseOptions[spouseSlider].coverageAmount)}</span>
                                        <span style={{ float: "right", marginRight: "15px", fontWeight: "800" }}>+ {UsdFormatterDec.format(props.selections.laddVal[1])}</span>
                                    </>
                                }
                                {!checkboxes.spouse &&
                                    <>
                                        <span>Spouse : <em>waived</em></span>
                                        <span style={{ float: "right", marginRight: "15px", color: "#00000033" }}>$0.00</span>
                                    </>
                                }
                            </div>
                        </div>
                        <div className="memberPreview">
                            <div style={{ padding: "0px 0px 0px 15px", margin: "15px 0px", fontSize: "18px", fontWeight: "500" }}>
                                {checkboxes.dependent &&
                                    <>
                                        <span>Dependent(s) : {UsdFormatter.format(results?.lifeADDPlan.dependentOptions[0].coverageAmount)}</span>
                                        <span style={{ float: "right", marginRight: "15px", fontWeight: "800" }}>+ {UsdFormatterDec.format(props.selections.laddVal[2])}</span>
                                    </>
                                }
                                {!checkboxes.dependent &&
                                    <>
                                        <span>Dependent(s) : <em>waived</em></span>
                                        <span style={{ float: "right", marginRight: "15px", color: "#00000033" }}>$0.00</span>
                                    </>
                                }
                            </div>
                        </div>
                    </>

                }
            </div>
        </>
    )
}