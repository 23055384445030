import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

// Assets

//Mui Components

import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';

import { FormControl, InputLabel, Typography, Divider, Box, TextField } from '@mui/material';


//Mui icons
import CloseIcon from '@mui/icons-material/Close';

//Our Components

export default function SingleviewCloseTicketDrawer(props) {



    //Drawer Logic
    const handleDrawerClose = (e) => {
        props.setCloseTicketDrawerOpen(false)
    }


    const [resolved, setResolved] = useState(0)

    const [text, setText] = useState('')
    const [disabled, setDisabled] = useState(true)


    useEffect(() => {
        if (text != '' && resolved != 0 && props.status == 1){
            setDisabled(false)
        } else if(props.status == 2){
            setDisabled(false)
        } else {
            setDisabled(true)
        }


    }, [text,resolved,props.status])


    


    return (
        <>
            <Drawer
                PaperProps={{
                    sx: { marginTop: '56px!important' }

                }}
                hideBackdrop={true}
                anchor='right'
                open={props.closeTicketDrawerOpen}
                onClose={handleDrawerClose}
            >
                <div style={{ width: '560px', padding: '19px 38px' }}>
                    <div className='flex-row' >
                        <div className='col-6'>
                            <Typography variant="body1" fontFamily="Poppins" fontWeight="600">{props.status === 2 ? 'Reopen' : 'Close'} Ticket</Typography>
                        </div>

                        <div className='col-6' style={{ display: 'flex', justifyContent: 'flex-end' }} role='button' onClick={handleDrawerClose}>
                            <CloseIcon />
                        </div>
                    </div>
                    {props.status != 2 &&
                                        <Box sx={{ paddingTop: '24px' }}>
                        <Typography sx={{ marginBottom: '16px' }} variant="body1">Is this member's issue resolved?</Typography>
                        <div className="flex-row">
                            <div className='col-6'>
                                <Button fullWidth sx={{ marginRight: '16px' }} variant={resolved == 1 ? 'contained' : 'outlined'} onClick={(e) => setResolved(1)}>YES</Button>
                            </div>
                            <div className='col-6'>
                                <Button fullWidth sx={{ marginLeft: '16px' }} variant={resolved == 2 ? 'contained' : 'outlined'} onClick={(e) => setResolved(2)}>NO</Button>
                            </div>
                        </div>
                        <TextField variant='outlined' multiline fullWidth
                            placeholder="Description"
                            value={text} onChange={e => setText(e.target.value)}
                            sx={{ marginTop: '24px' }}
                            rows={4}
                            InputProps={{
                                // inputProps:{'data-uid':inputUid},
                                // disableUnderline: true,
                                // sx: { padding: '11px 15px', height: 'calc(20vh - 14px)', display: 'block', fontSize: 15, borderRadius: '30px', fontFamily: 'Archivo' }
                            }}
                        />
                    </Box>

                    }

                    <Box sx={{}}>
                        <Button onClick={(e) => props.closeOrReopenTicket(resolved,text)} disabled={disabled} variant="contained" fullWidth sx={{ marginTop:'24px'}}>
                        {props.status === 2 ? 'Reopen' : 'Close'} Ticket
                        </Button>
                    </Box>

                </div>
            </Drawer>

        </>
    )
}