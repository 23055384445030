import React, {
  useEffect,
  useLayoutEffect,
  useState,
  useContext,
  Suspense,
  lazy,
} from 'react';
// import { useHistory } from "react-router-dom";
import { useHistory } from 'react-router';
// import { format, differenceInCalendarYears } from "date-fns";

//Assets
// import PlaceHolderLogo from '../../../assets/images/placeHolder.svg';
// import FullColorBlackText from "../../../assets/images/FullColorBlackText.svg";
// import defaultLogo from "../../../assets/images/FullColorOnlyLogo.png";

//Muicons
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import Close from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';

//MuiComponents
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import AlerTitle from '@mui/material/AlertTitle';
import Fade from '@mui/material/Fade';
import MobileStepper from '@mui/material/MobileStepper';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Drawer from '@mui/material/Drawer';
import Home from '@mui/icons-material/Home';
import Icon from '@mui/material/Icon';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import { Collapse, Link, ListItem } from '@mui/material';
import Divider from '@mui/material/Divider';

//Services
// import enrollmentService from "../../../services/enrollment-service";
// import attachmentService from "../../../services/attachment-service";

//Our Components
// import ExternalEnrollmentHeader from "./ExternalEnrollmentHeader";
// import PoweredBy from "../../external-enrollments/PoweredBy";
import InfinityLoader from '../../../UH-loading-animation/InfinityLoader';
// import DrawerMenuItem from "./DrawerMenuItem";
// import HeaderCollapseContainer from "./HeaderCollapseContainer";

import { ColorModeContext } from '../../../../ExternalApp';
import SupportCollapse from './SupportCollapse';

// const DrawerMenuItem = lazy(() => import('./DrawerMenuItem'));

// const DashHome = React.lazy(() => import('../../external-dashboard/dash-home/DashHome'));
// const DashPersonal = React.lazy(() => import('../../external-dashboard/dash-personal/DashPersonal'));
// // const DashBenefits = React.lazy(() => import('../../external-dashboard/dash-benefits/DashBenefits'));
// const DashWallet = React.lazy(() => import('../../external-dashboard/dash-wallet/DashWallet'));
// const DashDocuments = React.lazy(() => import('../../external-dashboard/dash-documents/DashDocuments'));
// const DashClaims = React.lazy(() => import('../../external-dashboard/dash-claims/DashClaims'));
// const DashSettings = React.lazy(() => import('../../external-dashboard/dash-settings/DashSettings'));
// const DashHelp = React.lazy(() => import('../../external-dashboard/dash-help/DashHelp'));

export default function PortalSupport(props) {
  const [loading, setLoading] = useState(false);

  return (
    <>
      {loading && (
        <>
          <InfinityLoader
            style={{
              position: 'absolute',
              zIndex: 5,
              width: '100%',
              height: '100vh',
              backgroundColor: '#00000050',
              backdropFilter: 'blur(10px)',
            }}
          />
          <style>
            {`
                     div.lottie-div > svg {
                        height:55px!important;
                        position:absolute;
                        top:50%;
                     }
                  `}
          </style>
        </>
      )}
      <Box
        // component={'main'}
        className="flex-container"
        flex={1}
        // overflow="auto"
        style={{
          flexWrap: 'wrap',
          // minHeight: '700px',
          //  margin: 'auto',
        }}
        sx={{
          bgcolor: 'background.default',
          color: 'text.primary',
          // paddingBottom: '66px',
          padding: '66px 0px',
          paddingTop: '74px',
        }}
      >
        <Box
          // className="row"
          // component={'main'}
          style={{
            maxWidth: '575px',
            margin: '0 auto',
            // maxHeight: "1000px",
            // height:'10000px',
            // paddingLeft: '18px',
          }}
        >
          <Typography
            variant="subtitle1"
            sx={{ fontWeight: 600, paddingLeft: '18px' }}
          >
            Support
          </Typography>

          <Divider
            sx={{
              // width: 'calc(100% + 32px)',
              // marginBottom: '16px',
              marginTop: '16px',
              // marginLeft: '-16px',
              color: 'background.border',
              opacity: 0.5,
            }}
          />

          <SupportCollapse
            title="What happens if I am not actively working?"
            content="It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English."
            contact="+1(393)767-0987"
          />

          <SupportCollapse
            title="How do I change my beneficiaries?"
            content="It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English."
            contact="+1(393)767-0987"
          />

          <SupportCollapse
            title="When do plan changes take place?"
            content="It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English."
            contact="+1(393)767-0987"
          />

          <SupportCollapse
            title="How do I change my password?"
            content="It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English."
            contact="+1(393)767-0987"
          />
        </Box>
      </Box>

      {/* {!isAuthenticated && history.push('/login')} */}
    </>
  );
}
