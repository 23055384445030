import React, { useState, useEffect } from 'react';

//MuiIcons
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';

//MuiComponents
import Collapse from '@mui/material/Collapse';
import RadioGroup from '@mui/material/RadioGroup';
import Divider from '@mui/material/Divider';
import { Box, Typography } from '@mui/material';

//Our icons

//Our Components
import EnrollmentLaddMember from './ladd-components/EnrollmentLaddMember';
import EnrollmentLaddSpouse from './ladd-components/EnrollmentLaddSpouse';
import EnrollmentLaddDependent from './ladd-components/EnrollmentLaddDependent';
import ExpandAndCollapseIcon from '../../enrollment-custom-components/ExpandAndCollapseIcon';
import CurrentAcLineChipDisplay from '../CurrentAcLineChipDisplay';

//Shared Styles
import enrollmentPlanStyles from '../enrollmentPlanStyles';

export default function EnrollmentLadd(props) {
  const componentStyles = {
    selectionSummaryPoints: {
      display: 'flex',
      flexDirection: 'row',
      margin: '0px 0px',
      fontSize: '15px',
      fontWeight: '500',
      padding: '0px',
      justifyContent: 'space-between',
      alignItems: 'center',
      color: 'text.lightGray',
      fontFamily: 'Archivo',
    },
    collapseSectionSummary: {
      marginBottom: '15px',
    },
  };

  function iOS() {
    return (
      [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod',
      ].includes(navigator.platform) ||
      // iPad on iOS 13 detection
      (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
    );
  }

  const isIOS = iOS();
  // console.log(navigator)

  let UsdFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
  });
  let UsdFormatterDec = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
  });

  let results = props.results;
  let lifeADDPlan = results?.lifeADDPlan;
  let lifeADDHelptext = lifeADDPlan?.helpText;
  let recommendations = props.recommendations;

  // const storeLocalDecline = (bool) => {
  //   console.log('storing local decline',bool, checkboxes.member)
  //   let storedDecline = localStorage.setItem("laddDecline", bool);

  //   if (bool){
  //     props.setLaddSelectionMade(true)
  //     setCheckboxes({
  //       ...checkboxes,
  //       member: false
  //     })
  //   } else if (!bool && !checkboxes.member){
  //     // props.setLaddSelectionMade(false)
  //   }
  // };

  // const getLocalDecline = () => {
  //   let storedDecline = localStorage.getItem("laddDecline");
  //   if (storedDecline) {
  //     //get the opposite, cause we'll be setting checkboxes.member
  //     let _declinedLadd = !Boolean(storedDecline);
  //     setCheckboxes({
  //       ...checkboxes,
  //       member: _declinedLadd,
  //     });
  //   }
  // };
  // useEffect(() => {
  //   getLocalDecline();
  // }, []);

  //Alert
  const [displayAlert, setDisplayAlert] = useState(false);

  //member
  const [memberSlider, setMemberSlider] = useState(
    lifeADDPlan.memberOptions.length - 1
  );
  // console.log('memberSlider',memberSlider)
  // const memberValuetext = (value) => `${UsdFormatter.format(lifeADDPlan?.memberOptions[value].coverageAmount)}`

  //spouse
  const [spouseSlider, setSpouseSlider] = useState(
    results?.lifeADDPlan.spouseOptions.length - 1
  );
  // const spouseValuetext = (value) => `${UsdFormatter.format(lifeADDPlan?.spouseOptions[value].coverageAmount)}`

  //Variable and states to handle capping the spouse/dependent coverage
  let spouseMax =
    lifeADDPlan?.memberOptions[memberSlider]?.coverageAmount *
    lifeADDPlan.spousePercentageCap;

  const [spouseMaxIndex, setSpouseMaxIndex] = useState(
    results?.lifeADDPlan.spouseOptions.length - 1
  );

  //dependent
  const [dependentMax, setDependentMax] = useState(
    results?.lifeADDPlan.dependentMaximumAmount
  );
  const [dependentMaxIndex, setDependentMaxIndex] = useState(
    results?.lifeADDPlan?.dependentOptions.length - 1
  );

  //dep slider if more than one plan option exists
  const [dependentSlider, setDependentSlider] = useState(
    results?.lifeADDPlan?.dependentOptions
      ? results?.lifeADDPlan?.dependentOptions?.length - 1
      : 0
  );

  //Changes in our member slider have effects on our spouse and dependent
  useEffect(() => {
    spouseMax =
      lifeADDPlan?.memberOptions[memberSlider]?.coverageAmount *
      lifeADDPlan.spousePercentageCap;
    let currentSpouse =
      lifeADDPlan?.spouseOptions[spouseSlider]?.coverageAmount;

    //Matching the capped coverage in the options arr, then setting the states accordingly
    if (spouseMax <= lifeADDPlan?.spouseMaximumAmount) {
      let count = 0;
      // for later, we could optimize this further by reversing the loop and breaking when we find the first match
      for (let option of lifeADDPlan?.spouseOptions) {
        if (option.coverageAmount <= spouseMax) {
          setSpouseMaxIndex(count);
          if (spouseMax < currentSpouse) {
            setSpouseSlider(count);
          }
        }
        count += 1;
      }
    } else {
      //THIS IS WHERE THE SPOUSE SLIDER IS BEING MAXED
      setSpouseMaxIndex(results?.lifeADDPlan.spouseOptions.length - 1);
      // setSpouseSlider(results?.lifeADDPlan.spouseOptions.length-1)
    }

    //DEPENDENT
    let _dependentMax =
      lifeADDPlan?.memberOptions[memberSlider]?.coverageAmount *
      lifeADDPlan.dependentPercentageCap;
    let currentDep =
      lifeADDPlan?.dependentOptions[dependentSlider]?.coverageAmount;

    //Matching the capped coverage in the options arr, then setting the states accordingly
    if (_dependentMax <= lifeADDPlan?.dependentMaximumAmount) {
      let count = 0;
      for (let option of lifeADDPlan?.dependentOptions) {
        if (option.coverageAmount == _dependentMax) {
          setDependentMaxIndex(count);
          if (_dependentMax < currentDep) {
            setDependentSlider(count);
          }
        }
        count += 1;
      }
    } else {
      //THIS IS WHERE THE SPOUSE SLIDER IS BEING MAXED
      setDependentMaxIndex(results?.lifeADDPlan?.dependentOptions.length - 1);
    }
  }, [memberSlider]);

  //Selection handler, state and effects
  const [checkboxes, setCheckboxes] = useState(
    props?.results?.lifeADDPlan?.memberIsDeclined
      ? {
          member: 'decline', //check if declined in db - set all to decline if so
          spouse: 'decline',
          dependent: 'decline',
        }
      : {
          member: 'truthy', //This truthy value lets us display as if member was selected, but still requires user interaction
          spouse: null,
          dependent: null,
        }
  );

  //handle decline selections
  const [memberLocalDecline, setMemberLocalDecline] = useState(false);
  const [spouseLocalDecline, setSpouseLocalDecline] = useState(false);
  const [dependentLocalDecline, setDependentLocalDecline] = useState(false);

  //set decline states and checkboxes from saved info in db
  useEffect(() => {
    //Member
    if (props?.results?.lifeADDPlan?.memberIsDeclined) {
      setMemberLocalDecline(true);
      setCheckboxes({
        ...checkboxes,
        member: 'decline',
        spouse: 'decline',
        dependent: 'decline',
      });
    }

    //Spouse
    if (props?.results?.lifeADDPlan?.spouseIsDeclined) {
      setSpouseLocalDecline(true);
      setCheckboxes({
        ...checkboxes,
        spouse: 'decline',
      });
    }

    //Dependent
    if (props?.results?.lifeADDPlan?.dependentIsDeclined) {
      setDependentLocalDecline(true);
      setCheckboxes({
        ...checkboxes,
        dependent: 'decline',
      });
    }
  }, [
    props?.results?.lifeADDPlan?.memberIsDeclined,
    props?.results?.lifeADDPlan?.spouseIsDeclined,
    props?.results?.lifeADDPlan?.dependentIsDeclined,
  ]);

  const handleLaddDeclineSelection = (e, person) => {
    //member
    if (person == 'member') {
      console.log('member decline selection running');
      //update all values for ladd selections
      props.setSelections({
        ...props.selections,
        laddId: lifeADDPlan?.planId,
        ladd: [0, 0, 0],
        laddVal: [0, 0, 0],
      });

      //decline all
      setMemberLocalDecline(true);
      setSpouseLocalDecline(true);
      setDependentLocalDecline(true);

      setCheckboxes({
        ...checkboxes,
        member: 'decline',
        spouse: 'decline',
        dependent: 'decline',
      });

      // potential - set member and spouse sliders to lowest amounts on decline member
      // setMemberSlider(0)
      // setSpouseSlider(0)
    }

    //new object copy from selections in state to be used to update spouse and deps
    let _updateSelections = {
      ...props?.selections,
      laddId: lifeADDPlan?.planId,
      laddVal: [...props?.selections?.laddVal],
      ladd: [...props?.selections?.ladd],
    };

    //spouse
    if (person == 'spouse') {
      setSpouseLocalDecline(true);
      //set checkbox value to decline
      setCheckboxes({
        ...checkboxes,
        spouse: 'decline',
      });

      //update the values of the new object for spouse only which is at index 1
      _updateSelections.laddVal[1] = 0;
      _updateSelections.ladd[1] = 0;
      //update selections in state
      props.setSelections({
        ...props.selections,
        ..._updateSelections,
      });
    }

    //dependent
    if (person == 'dependent') {
      setDependentLocalDecline(true);
      //set checkbox value to decline
      setCheckboxes({
        ...checkboxes,
        dependent: 'decline',
      });

      //update the values of the new object for dependent only which is at index 2
      _updateSelections.laddVal[2] = 0;
      _updateSelections.ladd[2] = 0;
      //update selections in state
      props.setSelections({
        ...props.selections,
        ..._updateSelections,
      });
    }
  };

  const laddSelectionHandler = (e, bool) => {
    let checkedBool = Boolean(bool);
    let value = parseFloat(e.target.getAttribute('data-amount'));
    let coverage = parseInt(e.target.getAttribute('data-coverage'));
    let person = e.target.getAttribute('data-person');
    let laddIndex = parseInt(e.target.name);

    console.log(
      'laddSelectionHandlerRunning \n\n',
      //  'e.tartet', e.target,
      'bool',
      bool,
      '\n value',
      value,
      '\ncoverage',
      coverage,
      '\nperson',
      person,
      '\nladdIndex',
      laddIndex,
      '\n\n'
    );

    //make sure decline option is false for whoever is selected
    if (person == 'member') {
      setMemberLocalDecline(false);
    }

    if (person == 'spouse') {
      setSpouseLocalDecline(false);
    }

    if (person == 'dependent') {
      setDependentLocalDecline(false);
    }

    //Member needs to be selected for spouse and dependent to be avail
    if (person == 'member' && !value) {
      setCheckboxes({
        member: false,
        spouse: false,
        dependent: false,
      });
      return; //exit and trigger useEffect above
    } else {
      setCheckboxes({
        ...checkboxes,
        [person]: checkedBool,
      });
    }

    if (bool) {
      if (person == 'dependent') {
        if (dependentMax == 0) {
          value = 0;
          coverage = 0;
        } else coverage = dependentMax;
      }

      let _laddVal = props.selections.laddVal.map((x) => x);
      let _ladd = props.selections.ladd.map((x) => x);
      _laddVal[laddIndex] = value;
      _ladd[laddIndex] = coverage;
      props.setSelections({
        ...props.selections,
        laddId: lifeADDPlan?.planId,
        laddVal: _laddVal,
        ladd: _ladd,
      });
    } else {
      let _laddVal = props.selections.laddVal.map((x) => x);
      let _ladd = props.selections.ladd.map((x) => x);
      _laddVal[laddIndex] = 0;
      _ladd[laddIndex] = 0;
      props.setSelections({
        ...props.selections,
        laddId: lifeADDPlan?.planId,
        laddVal: _laddVal,
        ladd: _ladd,
      });
    }
  };

  //As the sliders slide, if they're checked, let the value ride
  useEffect(() => {
    let _laddVal = props.selections.laddVal.map((x) => x);
    let _ladd = props.selections.ladd.map((x) => x);

    //member
    if (checkboxes.member === true && checkboxes.member !== 'decline') {
      // console.log('member checked',memberSlider,lifeADDPlan?.memberOptions[memberSlider])
      _laddVal[0] = lifeADDPlan?.memberOptions[memberSlider]?.premiumAmount;
      _ladd[0] = lifeADDPlan?.memberOptions[memberSlider]?.coverageAmount;
    }
    //spouse
    if (checkboxes.spouse && checkboxes.spouse !== 'decline') {
      _laddVal[1] = lifeADDPlan?.spouseOptions[spouseSlider]?.premiumAmount;
      _ladd[1] = lifeADDPlan?.spouseOptions[spouseSlider]?.coverageAmount;
    }
    //dependent
    if (checkboxes.dependent && checkboxes.dependent !== 'decline') {
      _laddVal[2] =
        results?.lifeADDPlan.dependentOptions[dependentSlider]?.premiumAmount;
      _ladd[2] =
        results?.lifeADDPlan.dependentOptions[dependentSlider]?.coverageAmount;
    }

    if (
      checkboxes.dependent &&
      checkboxes.dependent !== 'decline' &&
      dependentMax == 0
    ) {
      _laddVal[2] = 0;
      _ladd[2] = 0;
    } else if (
      (!checkboxes.dependent || checkboxes.dependent === 'decline') &&
      dependentMax == 0
    ) {
      _laddVal[2] = 0;
      _ladd[2] = 0;
    }
    // console.log('setting selections in ladd',_laddVal,_ladd)
    props.setSelections({
      ...props.selections,
      laddId: lifeADDPlan?.planId,
      laddVal: _laddVal,
      ladd: _ladd,
    });
  }, [memberSlider, spouseSlider, dependentSlider, dependentMax]);

  //save selections to db when required member, spouse and dependent selections/ changes are made
  //Keep timerId in state to be able to clear it if new Ladd selections are made before timer is up
  const [saveTick, setSaveTick] = useState(0);
  const [timer, setTimer] = useState(0);

  useEffect(() => {
    //saveTick will always be 1 when a checkbox is filled, so when current existing elections from the db are populated, saveTick will = 1, we don't want to hit db with this yet because these results came from the db, onlly want to hit with changes
    if (saveTick > 1 && props?.results?.lifeADDPlan) {
      console.log('saveTick', saveTick);
      clearTimeout(timer);
      let timerId = setTimeout(() => {
        props.saveLadd();
      }, 400);
      setTimer(timerId);
    }
  }, [saveTick]);

  /////NEXT ENABLE - Check that if member, spouse, and dependent ladd option was selected, even if decline - false allows next button enable, true will disable it
  useEffect(() => {
    //if member has been selected and not declined, also check that spouse and dependent selections or declines are made
    if (checkboxes.member != 'truthy' && checkboxes.member != 'decline') {
      //console.log('updates to checkboxes', checkboxes)
      if (checkboxes.spouse && checkboxes.dependent) {
        props.setLaddSelected(true); //important for decline all in EnrollmentPlans.js
        props.setLaddSelectionMade(true);

        //save selections to db when required member, spouse and dependent selections/ changes are made
        // clear setTimeout above if it is already running from a prev save
        setSaveTick((saveTick) => saveTick + 1);
      } else {
        props.setLaddSelected(false); //important for decline all in EnrollmentPlans.js
        props.setLaddSelectionMade(false);
        // clear setTimeout above if it is already running from a prev save
        setSaveTick((saveTick) => saveTick + 1);
      }
    }

    //if member decline option is selected, do not need to check spouse and dep selections
    if (checkboxes.member == 'decline') {
      props.setLaddSelected(true);
      props.setLaddSelectionMade(true);

      //save selections to db when required member, spouse and dependent selections/ changes are made
      // clear setTimeout above if it is already running from a prev save
      setSaveTick((saveTick) => saveTick + 1);
    }
  }, [
    props?.selections?.laddVal[0],
    props?.selections?.laddVal[1],
    props?.selections?.laddVal[2],
    checkboxes.member,
    checkboxes.spouse,
    checkboxes.dependent,
  ]);

  //Slider handlers

  //MEMBER
  const memberSliderChange = (e) => {
    if (checkboxes.member != true) {
      setCheckboxes({
        ...checkboxes,
        member: true,
      });
    }
    setMemberSlider(e.target.value);
  };

  //SPOUSE
  const spouseSliderChange = (e) => {
    if (e.target.value <= spouseMaxIndex) setSpouseSlider(e.target.value);
    else setSpouseSlider(spouseMaxIndex);
  };

  //DEPENDENT
  const dependentSliderChange = (e) => {
    if (e.target.value <= dependentMaxIndex) {
      setDependentSlider(e.target.value);
    } else setDependentSlider(dependentMaxIndex);
  };

  const [expanded, setExpanded] = useState(true);
  const handleExpandSection = () => {
    setExpanded(!expanded);
  };

  // useEffect(() => {
  //     if (props.scrollClose) {
  //         setExpanded(false)
  //     }
  // },[props.scrollClose])
  // useEffect(() => {
  //     if (!expanded) {
  //         // document.getElementById('ladd-calc-wrapper').scrollIntoView()
  //     }
  // }, [expanded])

  const [currRec, setCurrRec] = useState(0);
  const [currSpouseRec, setCurrSpouseRec] = useState(0);
  const [currDepRec, setCurrDepRec] = useState(false);

  let marks =
    0 < spouseMaxIndex &&
    spouseMaxIndex != lifeADDPlan?.spouseOptions.length - 1
      ? [
          // { value: 0, label: "$" + results?.lifeADDPlan.spouseOptions[0].coverageAmount / 1000 + "K" },
          {
            value: spouseMaxIndex,
            label:
              '$' +
              results?.lifeADDPlan.spouseOptions[spouseMaxIndex]
                ?.coverageAmount /
                1000 +
              'K',
          },
          // { value: results?.lifeADDPlan.spouseOptions.length - 1, label: "$" + results?.lifeADDPlan.spouseOptions[results?.lifeADDPlan.spouseOptions.length - 1].coverageAmount / 1000 + "K" },
        ]
      : [
          // { value: 0, label: "$" + results?.lifeADDPlan.spouseOptions[0].coverageAmount / 1000 + "K" },
          // { value: results?.lifeADDPlan.spouseOptions.length - 1, label: "$" + results?.lifeADDPlan.spouseOptions[results?.lifeADDPlan.spouseOptions.length - 1].coverageAmount / 1000 + "K" },
        ];

  useEffect(() => {
    let previousChoices = false;
    for (let val of props.selections.ladd) {
      if (val != 0) previousChoices = true;
    }
    let recommendedSpousePosition = checkSpouseSliderAtRec();
    let recommendedMemberPosition = checkMemberSliderAtRec();
    let recommendedDepPosition = checkDependentSliderAtRec();
    if (!previousChoices) {
      //set sliders to recommended position
      setSpouseSlider(recommendedSpousePosition);
      setMemberSlider(recommendedMemberPosition);
      setDependentSlider(recommendedDepPosition);
    } else {
      //Member
      let dbMemberPosition = results.lifeADDPlan.memberOptions.length - 1;
      let memberCheck = false;
      for (let i = 0; i < results.lifeADDPlan.memberOptions.length; i++) {
        if (results.lifeADDPlan.memberOptions[i].isSelected) {
          dbMemberPosition = i;
          memberCheck = true;
          setMemberSlider(dbMemberPosition);
        }
      }
      //Spouse
      let dbSpousePosition = results.lifeADDPlan.spouseOptions.length - 1;
      let spouseCheck = false;
      for (let i = 0; i < results.lifeADDPlan.spouseOptions.length; i++) {
        if (results.lifeADDPlan.spouseOptions[i].isSelected) {
          dbSpousePosition = i;
          spouseCheck = true;
          setSpouseSlider(dbSpousePosition);
        }
      }
      //Dependent
      let dbDependentPosition =
        results?.lifeADDPlan?.dependentOptions?.length - 1;
      let depCheck = false;
      for (let i = 0; i < results?.lifeADDPlan?.dependentOptions?.length; i++) {
        if (results?.lifeADDPlan?.dependentOptions[i].isSelected) {
          dbDependentPosition = i;
          depCheck = true;
          setDependentSlider(dbDependentPosition);
        }
      }

      //check for stored declines in db too and set checkboxes
      setCheckboxes({
        member: props?.results?.lifeADDPlan?.memberIsDeclined
          ? 'decline'
          : memberCheck,
        spouse: props?.results?.lifeADDPlan?.spouseIsDeclined
          ? 'decline'
          : spouseCheck,
        dependent: props?.results?.lifeADDPlan?.dependentIsDeclined
          ? 'decline'
          : depCheck,
      });
    }

    //set recommendations to display
    setCurrRec(results?.lifeADDPlan?.memberOptions[recommendedMemberPosition]);
    setCurrSpouseRec(
      results?.lifeADDPlan?.spouseOptions[recommendedSpousePosition]
    );
    //dep is true/false and not an amount
    let _depRec =
      props?.recommendations?.ShowDependent &&
      props?.recommendations?.DependentHighest;
    setCurrDepRec(_depRec);
  }, [props?.recommendations, props?.results?.lifeADDPlan]);

  //MEMBER rec slider position
  const checkMemberSliderAtRec = () => {
    let recommendationPosition = 0;
    if (results?.lifeADDPlan.memberOptions.length > 1) {
      recommendations.Lowest
        ? (recommendationPosition = 0)
        : //taking out the second lowest
          //!recommendations.Lowest && recommendations.ShowSpouse && recommendations.ShowDependent ? recommendationPosition = (results?.lifeADDPlan.memberOptions.length - 2) :
          (recommendationPosition =
            results?.lifeADDPlan.memberOptions.length - 1);
    }
    return recommendationPosition;
  };

  //Spouse rec slider position
  const checkSpouseSliderAtRec = () => {
    let recommendationPosition = 0;
    if (results?.lifeADDPlan.spouseOptions.length > 1) {
      recommendations.SpouseLowest
        ? (recommendationPosition = 0)
        : //taking out second lowest
          //recommendations.SpouseSecondHighest ? recommendationPosition = (results?.lifeADDPlan.spouseOptions.length - 2):
          (recommendationPosition =
            results?.lifeADDPlan.spouseOptions.length - 1);
    }
    return recommendationPosition;
  };

  //Dep rec slider position
  const checkDependentSliderAtRec = () => {
    let recommendationPosition = 0;
    if (results?.lifeADDPlan?.dependentOptions?.length > 1) {
      recommendations.DependentLowest
        ? (recommendationPosition = 0)
        : (recommendationPosition =
            results?.lifeADDPlan.dependentOptions?.length - 1);
    }
    return recommendationPosition;
  };

  return (
    <>
      <Box sx={enrollmentPlanStyles.planContainer(expanded)}>
        <Typography
          sx={enrollmentPlanStyles.planTitle}
          onClick={handleExpandSection}
        >
          Life Insurance
        </Typography>

        <Box
          onClick={handleExpandSection}
          sx={enrollmentPlanStyles.expandAndCollapseButton}
        >
          <ExpandAndCollapseIcon isExpanded={expanded} />
        </Box>
      </Box>

      <Collapse
        sx={enrollmentPlanStyles.collapseSection}
        in={expanded}
        unmountOnExit
      >
        {/* Current AC Line - display only to agent side if member already has this line type of active coverage */}
        {props?.agentEnroller &&
          props?.matchingACPlanType &&
          props?.matchingACPlanType?.length > 0 && (
            <CurrentAcLineChipDisplay plan={props.matchingACPlanType} />
          )}
        <Box sx={enrollmentPlanStyles.planExplanationInnerHtmlContainer}>
          <span
            className="plan-explanation-innerHTML"
            dangerouslySetInnerHTML={{ __html: lifeADDHelptext }}
          ></span>
        </Box>
        <RadioGroup
          value={checkboxes.member}
          // onChange={laddSelectionHandler}
        >
          {/* MEMBER */}
          <EnrollmentLaddMember
            memberSlider={memberSlider}
            setMemberSlider={setMemberSlider}
            memberSliderChange={memberSliderChange}
            checkboxes={checkboxes}
            setCheckboxes={setCheckboxes}
            results={results}
            dummyDisplay={props.dummyDisplay}
            dummyFontColor={props.dummyFontColor}
            currRec={currRec}
            calculated={props.calculated}
            memberLocalDecline={memberLocalDecline}
            laddSelectionHandler={laddSelectionHandler}
            handleLaddDeclineSelection={handleLaddDeclineSelection}
            dataUHTest="MemberLADDTrue_radio"
          />

          {/* SPOUSE */}
          {lifeADDPlan?.spouseOptions?.length > 0 && (
            <EnrollmentLaddSpouse
              spouse={props.spouse}
              setSpouse={props.setSpouse}
              genderTypes={props?.genderTypes}
              spouseSlider={spouseSlider}
              setSpouseSlider={setSpouseSlider}
              spouseSliderChange={spouseSliderChange}
              checkboxes={checkboxes}
              setCheckboxes={setCheckboxes}
              results={results}
              recommendations={recommendations}
              dummyDisplay={props.dummyDisplay}
              dummyFontColor={props.dummyFontColor}
              displayAlert={displayAlert}
              laddSelectionHandler={laddSelectionHandler}
              marks={marks}
              currSpouseRec={currSpouseRec}
              spouseMaxIndex={spouseMaxIndex}
              spouseMax={spouseMax}
              // medBreakpointHit={props?.medBreakpointHit}
              spouseLocalDecline={spouseLocalDecline}
              setSpouseLocalDecline={setSpouseLocalDecline}
              handleLaddDeclineSelection={handleLaddDeclineSelection}
              dataUHTest="SpouseLADDTrue_radio"
            />
          )}

          {/* DEPENDENT */}
          <EnrollmentLaddDependent
            checkboxes={checkboxes}
            setCheckboxes={setCheckboxes}
            results={results}
            recommendations={recommendations}
            currDepRec={currDepRec}
            dummyDisplay={props.dummyDisplay}
            dummyFontColor={props.dummyFontColor}
            displayAlert={displayAlert}
            laddSelectionHandler={laddSelectionHandler}
            expanded={expanded}
            dependentMax={dependentMax}
            dependents={props.dependents}
            setDependents={props.setDependents}
            genderTypes={props?.genderTypes}
            // medBreakpointHit={props?.medBreakpointHit}
            dependentLocalDecline={dependentLocalDecline}
            setDependentLocalDecline={setDependentLocalDecline}
            handleLaddDeclineSelection={handleLaddDeclineSelection}
            dependentSlider={dependentSlider}
            setDependentSlider={setDependentSlider}
            dependentSliderChange={dependentSliderChange}
            dependentMaxIndex={dependentMaxIndex}
          />
        </RadioGroup>
      </Collapse>
      {!expanded && (
        <Box sx={componentStyles.collapseSectionSummary}>
          <Box sx={componentStyles.selectionSummaryPoints}>
            {checkboxes.member === true && checkboxes.member !== 'decline' && (
              <>
                <Box>
                  <RadioButtonCheckedIcon
                    sx={enrollmentPlanStyles.radioButtionIconCollapse}
                  />
                  Member:{' '}
                  {UsdFormatter.format(
                    results?.lifeADDPlan.memberOptions[memberSlider]
                      ?.coverageAmount
                  )}
                </Box>

                <Box sx={enrollmentPlanStyles.electedOptionCollapsePlusSymbol}>
                  + {UsdFormatterDec.format(props.selections.laddVal[0])}
                </Box>
              </>
            )}
            {(!checkboxes.member ||
              checkboxes.member === 'truthy' ||
              checkboxes.member === 'decline') && (
              <>
                <Typography>
                  Member: <em>not elected</em>
                </Typography>
                <Typography style={enrollmentPlanStyles.notElectedZeroAmount}>
                  $0.00
                </Typography>
              </>
            )}
          </Box>
          <Box sx={componentStyles.selectionSummaryPoints}>
            {checkboxes.spouse && checkboxes.spouse !== 'decline' && (
              <>
                <Box>
                  <RadioButtonCheckedIcon
                    sx={enrollmentPlanStyles.radioButtionIconCollapse}
                  />
                  Spouse:{' '}
                  {UsdFormatter.format(
                    results?.lifeADDPlan.spouseOptions[spouseSlider]
                      ?.coverageAmount
                  )}
                </Box>

                <Box sx={enrollmentPlanStyles.electedOptionCollapsePlusSymbol}>
                  + {UsdFormatterDec.format(props.selections.laddVal[1])}
                </Box>
              </>
            )}
            {(!checkboxes.spouse || checkboxes.spouse === 'decline') && (
              <>
                <Typography>
                  Spouse: <em>not elected</em>
                </Typography>
                <Typography sx={enrollmentPlanStyles.notElectedZeroAmount}>
                  $0.00
                </Typography>
              </>
            )}
          </Box>
          <Box sx={componentStyles.selectionSummaryPoints}>
            {checkboxes.dependent && checkboxes.dependent !== 'decline' && (
              <>
                <Box>
                  <RadioButtonCheckedIcon
                    sx={enrollmentPlanStyles.radioButtionIconCollapse}
                  />
                  Dependent(s):{' '}
                  {UsdFormatter.format(
                    results?.lifeADDPlan.dependentOptions[dependentSlider]
                      ?.coverageAmount
                  )}
                </Box>

                <Box sx={enrollmentPlanStyles.electedOptionCollapsePlusSymbol}>
                  + {UsdFormatterDec.format(props.selections.laddVal[2])}
                </Box>
              </>
            )}
            {(!checkboxes.dependent || checkboxes.dependent === 'decline') && (
              <>
                <Typography>
                  Dependent(s): <em>not elected</em>
                </Typography>
                <Typography sx={enrollmentPlanStyles.notElectedZeroAmount}>
                  $0.00
                </Typography>
              </>
            )}
          </Box>
        </Box>
      )}

      <Divider sx={enrollmentPlanStyles.endSectionDivider} />
    </>
  );
}
