import React, {useState, useEffect} from 'react'
import { set, format } from 'date-fns';

//services
import optionService from '../../../../../../../../services/optionService';


//Mui icons
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

//Mui Components
import Chip from "@mui/material/Chip";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/system";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Collapse } from "@mui/material";
import Select from '@mui/material/Select';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from "@mui/icons-material/MoreVert";
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';



//our components
import InfinityLoader from "../../../../../../../UH-loading-animation/InfinityLoader";
import EditCoverageEffectiveDateStepper from '../EditCoverageEffectiveDateStepper';


export default function EnrollmentSummaryCardSTDIncremental (props) {

   let plan = props.plan

   let selectedOptionId = plan?.selectedOptionId
   let selectedOption = plan?.options?.find(option => option.memberOptionId === selectedOptionId)

   const componentStyles ={
      selectDropDown: {
         height: "40px", borderRadius: "4px", padding: "10px 0px", width: "100%",
         "&.MuiOutlinedInput-root.Mui-focused > fieldset": {
           border: "2px solid #195ffb",
         }, textAlign: "left", 
       }
   }

   const [edit, setEdit] = useState(false)
   //This is bolding the payment info on the right side when edit==true
   useEffect(() => {
      if (props.setCurrentEdit) {//Check if this is rendered in planSummary or enrollmentSummary
         if (edit) {
            props.setCurrentEdit({
               ...props.currentEdit,
               std: true
            })
         }
         else {
            props.setCurrentEdit({
               ...props.currentEdit,
               std: false
            })
         }
      }
   }, [edit])

   const [stdPlan, setStdPlan] = useState()
   useEffect(() => {
      if (plan) setStdPlan(plan)
   }, [plan])

   let handleStdPlanChange = (e) => {
      let value = e.target.value;
      // console.log(stdPlan)
      setStdPlan({
         ...stdPlan,
         selectedOptionId: value
      })
   }

   let updateSinglePlan = async () => {
      setLoading(true)
      await props.updateSinglePlan('stdPlan', stdPlan)
      await props.getThisPlan()
      //setTimeout(() => {
      //   setEdit(false)

      //   setLoading(false)
      //},[2000])
      setEdit(false)

      setLoading(false)
   }

   const [loading, setLoading] = useState(false)


   

     ///// ELIMINATION PERIOD & BENEFIT DURATION LOGIC /////

   //Data from services
   const [eliminationPeriodTypes, setEliminationPeriodTypes] = useState();
   const [benefitDurTypes, setBenefitDurTypes] = useState();
   // calling to services for elim period and benefit option type ids
   const getElimPeriodList = async () => {
      let elimPeriodTypeList;
      try {
         elimPeriodTypeList = await optionService.getEliminationPeriodTypes();
      } catch (error) {
         console.log("error getting elimination periods", error);
      }

      // console.log("ElilminationPeriod List \n\n", elimPeriodTypeList);
   };

   const getBenefitDurationList = async () => {
      let benefitDurTypeList;
      try {
         benefitDurTypeList = await optionService.getBenefitDurationTypes();
      } catch (error) {
         console.log("error getting elimination periods", error);
      }

      console.log("BenefitDuration List \n\n", benefitDurTypeList);
   };

   useEffect(() => {
      //getting lists from option service
      getElimPeriodList();
      getBenefitDurationList();
   }, []);

     //selected elimination period
  const [selectedEliminationPeriod, setSelectedEliminationPeriod] =
  useState(null);

   //get only unique values of elimination period from member options excluding duplicates
   const getUniqueEliminationPeriodList = () => {
   let elimPeriodInfo = [];

   for (let option of stdPlan?.options) {
      if (option.eliminationPeriod > 0) {
         let object = {
         eliminationPeriod: option.eliminationPeriod,
         type: option.eliminationPeriodType,
         typeId: option.eliminationPeriodTypeId,
         };
         elimPeriodInfo.push(object);
      }
   }

   let uniqueElimPeriodArr = [
      ...new Set(elimPeriodInfo.map((obj) => obj.eliminationPeriod)),
   ];

   let uniqueElimPeriodOptions = uniqueElimPeriodArr.map(
      (eliminationPeriod) => {
         return elimPeriodInfo.find(
         (obj) => obj.eliminationPeriod === eliminationPeriod
         );
      }
   );

   setEliminationPeriodTypes(uniqueElimPeriodOptions);
   setSelectedEliminationPeriod(uniqueElimPeriodOptions[0]?.eliminationPeriod);
   };

   ////// selected benefit duration /////
   const [selectedBenefitDuration, setSelectedBenefitDuration] = useState();

   const getUniqueBenefitDurationList = () => {
   let benefitDurationInfo = [];

   for (let option of stdPlan?.options) {
      if (option.benefitDuration > 0) {
         let object = {
         duration: option.benefitDuration,
         type: option.benefitDurationType,
         typeId: option.benefitDurationTypeId,
         };
         benefitDurationInfo.push(object);
      }
   }

   let uniqueBenefitDurList = [
      ...new Set(benefitDurationInfo.map((obj) => obj.duration)),
   ];

   let uniqueBenefitDurOptions = uniqueBenefitDurList.map((duration) => {
      return benefitDurationInfo.find((obj) => obj.duration === duration);
   });

   //set member benefit options off of unique options only
   setBenefitDurTypes(uniqueBenefitDurOptions);
   setSelectedBenefitDuration(uniqueBenefitDurOptions[0]?.duration);
   };

   const handleSelectBenefitDuration = (e) => {
   // console.log("benefit e.target", e.target);
   setSelectedBenefitDuration(e.target.value);
   console.log("benefit e.target", e.target);
    if (eliminationPeriodTypes?.length > 1) {
      let indexOfDuration = 0
      for (let i = 0; i < benefitDurTypes.length; i++) {
        if (benefitDurTypes[i].duration == e.target.value) {
          indexOfDuration = i
        }
      }
      setSelectedEliminationPeriod(eliminationPeriodTypes[indexOfDuration]?.eliminationPeriod);
      setSelectedBenefitDuration(e.target.value);
    } else {
      setSelectedBenefitDuration(e.target.value);
    }

   };

   useEffect(() => {
      if (stdPlan?.options){
         getUniqueEliminationPeriodList();
         getUniqueBenefitDurationList();
      }
   }, [stdPlan?.options]);

   const [stdOptionsExcludingDecline, setStdOptionsExcludingDecline] = useState(
   []
   );

   // useEffect(() => {
   //   if (stdOptionsExcludingDecline) {
   //     initialPlanAmountDisplay();
   //   }
   // }, [props.recommendations, props.results, expanded]);

   useEffect(() => {
   if (stdPlan?.options?.length > 0) {
      //array of all options excluding the $0 decline amount & benefit duration selected
      let filteredStdOptions = stdPlan?.options.filter((option) => {
         return (
         option.isDecline === false &&
         option.benefitDuration === selectedBenefitDuration &&
         option.eliminationPeriod === selectedEliminationPeriod
         );
      });

      setStdOptionsExcludingDecline(filteredStdOptions);
   }
   }, [stdPlan?.options, selectedBenefitDuration, selectedEliminationPeriod]);

   //change value of elim period w/ select drop down
   const handleSelectEliminationPeriod = (e) => {
   // console.log("elimination period clicked", e.target.value);
   //update value w/ selected elimination period value
   setSelectedEliminationPeriod(e.target.value);
   };

   const [editEffectiveDate, setEditEffectiveDate] = useState(false)
   const updateCoverageEffectiveDate = async () => {

      setLoading(true)
      // await props.updateCoverageEffectiveDate('stdPlan', stdPlan)
      // await props.getThisPlan()
      //setTimeout(() => {
      //   setEditEffectiveDate(false)
      //   setLoading(false)
      //},[2000])
      setEditEffectiveDate(false)
      setLoading(false)
   }

   
 //open verticle dot menu to edit or cancel coverage
  const [anchorEl, setAnchorEl] = useState({});

  const handleOpenRowMenu = (e) => {
    setAnchorEl({
      ...anchorEl,
      [e.currentTarget.value]: e.currentTarget,
    });
  };


  const handleCloseRowMenu = () => {
    setAnchorEl({});
  };

  const handleOpenCancelCoverageDrawer = (e, plan, planName) => {
   props.handleOpenDrawer(plan, planName);
   handleCloseRowMenu();
}
  

return (
   <>
      
      <Box className="flex-row" sx={{padding:'8px 18px', flexWrap:'wrap', position:'relative'}}>
         <div className="flex-row col-12" style={{marginBottom:'15.5px'}}>
            <div className="col-6">
               <Typography component={'span'} sx={{fontFamily:'Poppins', fontWeight:500, fontSize:'20px', lineHeight:'32px', letterSpacing:'.15px', color:'#195FFB'}}>
                  {props.planName.toUpperCase()}
               </Typography>
            </div>

            <div className="col-6" style={{display:'flex',justifyContent:'flex-end', alignItems:'center'}}>
               <Typography component={'span'} sx={{fontFamiily:'Poppins', fontWeight:400, fontSize:'24px', lineHeight:'32px', color:'#195FFB'}}>
                  {props.UsdFormatterDec.format(selectedOption?.memberPremiumAmount || 0)} 
               </Typography>
               <Typography component={'span'} sx={{fontFamily:'Archivo', fontWeight:400, fontSize:'14px', lineHeight:'20px', letterSpacing:'.17px', color:'text.secondary'}}>
                  /per month
               </Typography>

              { props?.activeCoveragePlanSummary && <>
                  <IconButton
                  onClick={(e) => handleOpenRowMenu(e, props?.plan)}
                  value={props?.plan?.planId}
                  sx={{ padding: "0px", marginLeft: "12px" }}
                  >
                  <MoreVertIcon
                     sx={{
                        color: "text.secondary",
                        float: "right",
                     }}
                  />
                  </IconButton>
                  <Menu
                  anchorEl={anchorEl?.[props?.plan.planId]}
                  open={Boolean(anchorEl?.[props?.plan.planId])}
                  onClose={handleCloseRowMenu}
                  anchorOrigin={{
                     vertical: "top",
                     horizontal: "left",
                  }}
                  transformOrigin={{
                     vertical: "top",
                     horizontal: "left",
                  }}
                  >
                  <MenuItem
                     onClick={(e) => {
                        if(props?.activePlansNotPendingCancel?.length > 1){
                           //cancel single line of coverage
                           handleOpenCancelCoverageDrawer(e, props?.plan, props?.planName);
                        }else if (props?.activePlansNotPendingCancel?.length === 1){
                           //cancel all coverage
                           props?.openCancelAllCoverageModal();
                        }
                     }}
                     disabled={!props.allowModify || props?.pendingCancellation}
                  >
                     {/* if not pending cancel and not last active line of coverage */}
                     {!props?.pendingCancellation && props?.planName && props?.activePlansNotPendingCancel?.length > 1 && `Cancel ${props?.planName.toUpperCase() } Coverage`}

                     {/* if not pending cancel and is last active line of coverage */}
                     {!props?.pendingCancellation && props?.planName && props?.activePlansNotPendingCancel?.length === 1 && 'Cancel All Coverage'}

                     {/* if pending cancel */}
                     {props?.pendingCancellation && 'Pending Cancellation'}
                  </MenuItem>

                  <MenuItem
                     //   value={}
                     onClick={() => {
                        setEdit(true);
                        handleCloseRowMenu();
                     }}
                     disabled={!props.allowModify}
                  >
                     Edit Coverage
                  </MenuItem>
                  {/* )} */}
                  </Menu>
          </>}
            </div>
         </div>

         {props.pendingCancellation && props.pendingCancellationObj && selectedOption?.memberPremiumAmount <= 0 &&
            props.pendingCancellationWarning(props.pendingCancellationObj)
         }

         <div className="flex-row col-12" style={{justifyContent: 'space-between'}}>
            <div className="col-6">
               <Typography variant="body1">
                  Benefit Amount
               </Typography>
            </div>

            <div className="col-5" style={{textAlign:'end'}}>
               {!edit &&
                  <Typography variant="body1" sx={{height:'40px'}}>
                     {props.UsdFormatterDec.format(selectedOption?.benefitAmount || 0)} {selectedOption?.benefitTypeFriendly}
                  </Typography>
               }
               {edit &&
                  <FormControl fullWidth sx={{marginBottom: '10px'}}>
                     <InputLabel id="benefitAmount">Benefit Amount</InputLabel>
                     <Select variant='outlined' size='small' fullWidth 
                      label="Benefit Amount" 
                      labelId="benefitAmount"
                        sx={componentStyles.selectDropDown}
                        IconComponent={() => <KeyboardArrowDownIcon sx={{ position: 'absolute', right: 7 }} />}
                        value={stdPlan.selectedOptionId || 0}
                        onChange={handleStdPlanChange}
                     >
                        {stdPlan?.options && stdPlan.options.map(option => {
                           if (option.eliminationPeriod == selectedEliminationPeriod && option.benefitDuration == selectedBenefitDuration && !option.isDecline) {
                              return (
                                 <MenuItem value={option.memberOptionId}>
                                    {props.UsdFormatterDec.format(option?.benefitAmount)} {option?.benefitTypeFriendly}
                                 </MenuItem>
                              )
                           } else if (option.isDecline) {
                              return (
                                 <MenuItem value={option.memberOptionId}>
                                    Decline
                                 </MenuItem>
                              )
                           }
                        })}
                     </Select>
                  </FormControl>
               }
            </div>
         </div>
         

         <div className="flex-row col-12" style={{justifyContent:"space-between"}}>
            <div className="col-6">
               <Typography variant="body1">
                  Elimination Period
               </Typography>
            </div>

            <div className="col-5" style={{textAlign:'end'}}>
               {!edit &&
                  <Typography variant="body1" sx={{height:'40px'}}>
                     {selectedOption?.eliminationPeriod}
                     &nbsp;
                     {selectedOption?.eliminationPeriodType}
                  </Typography>
               }
               {edit &&
                  <FormControl fullWidth sx={{marginBottom: '10px'}}>
                     <InputLabel id="eliminationPeriod">Elimination Period</InputLabel>
                     <Select variant='outlined'size='small' fullWidth
                        labelId="eliminationPeriod"
                        label="Elimination Period"
                        sx={componentStyles.selectDropDown}
                        IconComponent={() => <KeyboardArrowDownIcon sx={{ position: 'absolute', right: 7 }} />}
                        value={selectedEliminationPeriod}
                        onChange={handleSelectEliminationPeriod}
                        disabled
                     >
                        {eliminationPeriodTypes && eliminationPeriodTypes.map(option => {
                           return (
                              <MenuItem
                                 key={option.eliminationPeriod}
                                 value={option.eliminationPeriod}
                              >
                                 {option.eliminationPeriod} {option.type}
                              </MenuItem>
                           )
                        })}
                     </Select>
                  </FormControl>
               }
            </div>
         </div>

         <div className="flex-row col-12" style={{justifyContent: 'space-between'}}>
            <div className="col-6">
               <Typography variant="body1">
                  Benefit Duration
               </Typography>
            </div>

            <div className="col-5" style={{textAlign:'end'}}>
               {!edit &&
                  <Typography variant="body1" sx={{height:'40px'}}>
                     {selectedOption?.benefitDuration}
                     &nbsp;
                     {selectedOption?.benefitDurationType}
                  </Typography>
               }
               {edit &&
                  <FormControl fullWidth sx={{marginBottom: '10px'}}>
                     <InputLabel id="benefitDuration">Benefit Duration</InputLabel>
                     <Select variant='outlined' size='small' fullWidth
                     labelId="benefitDuration" label="Benefit Duration"
                        sx={componentStyles.selectDropDown}
                        IconComponent={() => <KeyboardArrowDownIcon sx={{ position: 'absolute', right: 7 }} />}
                        value={selectedBenefitDuration}
                        onChange={handleSelectBenefitDuration}
                     >
                        {benefitDurTypes && benefitDurTypes.map(option => {
                           return (
                              <MenuItem key={option.duration} value={option.duration}>
                                 {option.duration} {option.type}
                              </MenuItem>
                           )
                        })}
                     </Select>
                  </FormControl>
               }
            </div>
         </div>

         <div className="flex-row col-12" style={{marginBottom:'16px'}}>
            <div className="col-6">
               <Typography variant="body1">
                  Offset
               </Typography>
            </div>

            <div className="col-6" style={{textAlign:'end'}}>
               <Typography variant="body1">
                  {selectedOption?.benefitOffsets || "N/A"}
               </Typography>
            </div>
         </div>

         {props.allowModify &&
            <div className="flex-row col-12" style={{  }}>
               {!edit && !editEffectiveDate &&
               <div className="col-12 flex-row">
                   Effective Date: {props?.plan?.effectiveDate ? format(props?.formatDate(props?.plan?.effectiveDate), "MM/dd/yyyy") : "N/A"}

               </div>
                  
               }
               {(edit || editEffectiveDate) &&
                  <>
                       <div
                           className=""
                           style={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              marginTop: '10px'
                        }}
                        >
                        <EditCoverageEffectiveDateStepper currentEffectiveDate={props?.plan?.effectiveDate}/>
                        <div className="col-5 flex-row"   style={{ justifyContent: "flex-end"}}>
                           <Button
                              variant="outlined" 
                              // fullWidth
                              sx={{ borderRadius: '8px', height: '40px', fontWeight: '500!important', marginRight: "10px" }}
                              onClick={() => {
                                 setEdit(false)
                                 setEditEffectiveDate(false)
                              }} 
                              // disabled={!props.allowModify}
                           >
                              BACK
                           </Button>

                           <Button
                              variant="contained" 
                              // fullWidth
                              sx={{ borderRadius: '8px', height: '40px', fontWeight: '500!important' }}
                              onClick={edit ? updateSinglePlan : updateCoverageEffectiveDate}
                              disabled={loading}
                           >
                              {loading ?
                              <>
                                 <div style={{width:'100%',position:'absolute'}}>
                                    <InfinityLoader style={{
                                       zIndex:5,
                                       height:'38px',
                                    }}/> 
                                 </div>
                                 <span style={{opacity:0}}>SAVE CHANGES</span>
    
                              </>
                              :
                              <>
                                 SAVE CHANGES
                              </>
                              }
                           </Button>
                        </div>
                     </div>
                  </>
               }

            </div>
         }
      </Box>
      <hr style={{marginTop:'20px', color: "text.primary"}}/>
   </>
)
}