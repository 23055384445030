import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

//Services
import ticketService from "../../../../services/ticket-service";
import userService from '../../../../services/user-service';
//MuiIcons

//Mui Comonents
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { Box } from "@mui/system";
import { Typography } from "@mui/material";

//Our components
import SingleviewTopbar from "./singleview-topbar/SingleviewTopbar";
import SingleviewLeftside from "./singleview-leftside/SingleviewLeftside";
import SingleviewMain from "./singleview-main/SingleviewMain";
import SingleviewRightside from "./singleview-rightside/SingleviewRightside";
import memberService from "../../../../services/member-service";


export default function SingleviewTicket (props) {

const [thisTicket,setThisTicket] = useState({})
const [loading, setLoading] = useState(true)
const getThisTicket = async () => {
   let ticketId = window.location.pathname.split('/').reverse()[0]

   let thisTicket = await ticketService.getTicketById(ticketId)

   if (!thisTicket.requester) thisTicket.requester = ''
   setThisTicket(thisTicket)
}

//Ticket display/navigation if notification clicked 
const location = useLocation()
const [currentTicketLocation, setCurrentTicketLocation] = useState(location?.state)

//
useEffect(() => {
   if (location?.state) {
      setCurrentTicketLocation(location.state)
   }
},[location?.pathname])

useEffect(() => {
   getThisTicket()
},[currentTicketLocation])


const [member,setMember] = useState({})
const [memberPhones,setMemberPhones] = useState([])
const getThisMember = async () => {

   let memberId = thisTicket.memberId;
   let member = await memberService.getMemberByIdAsync(memberId)
   setMember(member)
   setLoading(false)
}
const getThisMembersPhones = async () => {
   let memberId = thisTicket.memberId
   let memberPhones = await memberService.getPhonesForMember(memberId)
   setMemberPhones(memberPhones)
}

useEffect(() => {

   if (thisTicket?.memberId > 0) {
      getThisMember()
      getThisMembersPhones()
   }  
},[thisTicket?.memberId])




const [thisUser, setThisUser] = useState()
let getThisUser = async () => {
    // let _thisUser = await memberService.getMe()
    let _thisUser = await userService.getMe()

    setThisUser(_thisUser)
   //  console.log('thisUser ', _thisUser)
}


//SNACKBAR LOGIC
const [message, setMessage] = useState({
   messageDisplay: false,
   autoHideDuration: "",
   severity: "",
   backgroundColor: "",
   message: ""
})

useEffect(() => {
   const timeId = setTimeout(() => {
      // After 3 seconds set the show value to false
      setMessage({
         ...message,
         messageDisplay: false,
         autoHideDuration: "",
         severity: "",
         backgroundColor: "",
         message: ""
      })
   }, 5000)

   return () => {
      clearTimeout(timeId)
   }
}, [message.messageDisplay])

const handleMessageClose = (event, reason) => {
   if (reason === 'clickaway') {
      return;
   }
   setMessage({
      ...message,
      messageDisplay: false,
      autoHideDuration: "",
      severity: "",
      backgroundColor: "",
      message: ""
   })
};

// useEffect(() => {
//     getThisUser()
// }, [])

   return (
   <> 
      {/* TOPBAR */}
      {/* <SingleviewTopbar ticket={thisTicket} member={member}/>
       */}
      <Box className="flex-row" sx={{
         bgcolor: 'background.background',
         color: 'text.primary',
         overflow: 'auto',
      }}  style={{backgroundColor:"white"}}>

         {/* LEFT */}
         <SingleviewLeftside loading={loading} refreshTickets={getThisTicket} ticket={thisTicket} setTicket={setThisTicket} member={member} memberPhones={memberPhones}/>

         {/* MIDDLE */}
         <SingleviewMain message={message} setMessage={setMessage} refreshTickets={getThisTicket}  ticket={thisTicket} member={member}/>

         {/* RIGHT */}
         <SingleviewRightside message={message} setMessage={setMessage} refreshTickets={getThisTicket} ticket={thisTicket} member={member}/>

         {/* Messages */}
         <Snackbar open={message.messageDisplay} autoHideDuration={message.autoHideDuration} onClose={handleMessageClose} sx={{ opacity: .8 }}>
               <MuiAlert
                  elevation={6} variant="filled"
                  // onClose={handleClose} 
                  severity={message.severity} sx={{ width: '100%', backgroundColor: message.backgroundColor }}
               >
                  {message.message}
               </MuiAlert>
            </Snackbar>
      </Box>
   </>
   )
}