import React, { useEffect, useState, useContext } from 'react';
import { format, differenceInCalendarYears } from 'date-fns';
import { Link, useHistory } from 'react-router-dom';

//Assets

//Services

//Mui icons
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

//Mui Components
import {
  Alert,
  Avatar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Typography,
  Paper,
  IconButton,
  Button,
  Tooltip,
} from '@mui/material';
import Box from '@mui/material/Box';

//Our Components
import InfinityLoader from '../../../../../../UH-loading-animation/InfinityLoader';
import EditNoteDrawer from '../../member-notes-tab/EditNoteDrawer';
import { thisUserContext } from '../../../../../nav-container/NavContainer';

//utils
import { parseUTCDateToZonedTime } from '../../../../../../utils';

export default function HistoryGrid(props) {
  //PROPS
  let { sortedHistoryData, unsortedHistoryData, loading } = props;

  const componentStyles = {
    componentBody: {
      height: 'calc(100vh - 270px)',
      width: '98%',
      margin: '0 auto',
      overflow: 'hidden',
      fontSize: '14px!important',
    },
    tableBody: {
      maxHeight: '100%',
      width: '100%',
      margin: '0 auto',
      overflow: loading ? 'hidden' : 'auto',
      position: 'relative',
    },
    tableHead: {
      backgroundColor: 'rgba(0, 0, 0, 0.025)',
      border: '1px solid rgba(0, 0, 0, 0.04)',
    },
    tableHeadTextCell: {
      fontWeight: 500,
      fontSize: '14px!important',
    },
    dateHeaderGroupRow: {
      backgroundColor: 'background.historyDateRow',
      height: '37px',
      padding: '0px',
    },
    dateHeaderGroupRowCell: {
      height: '37px',
      padding: '0px 0px 0px 10px',
      fontWeight: 500,
      fontSize: '14px!important',
      border: 'none',
      borderRadius: '8px',
    },
    eventDataRow: (firstIndex) => ({
      borderTop: firstIndex ? 'none' : '1.5px solid rgba(0, 0, 0, 0.1)',
    }),
    eventDataCell: {
      border: 'none',
      fontSize: '14px!important',
      fontWeight: 400,
      backgroundColor: 'background.default',
    },
    historyErrorAlert: {
      maxWidth: '300px',
    },
  };

  //get allUsers list from context - determine agent id
  const { allUsers } = useContext(thisUserContext);
  const { thisUser } = useContext(thisUserContext);
  const [noteDrawerOpen, setNoteDrawerOpen] = useState(false);
  const [editValue, setEditValue] = useState('');
  const [currentNote, setCurrentNote] = useState(null);

  const handleEdit = (event) => {
    setEditValue(event.description);
    setCurrentNote(event);
    setNoteDrawerOpen(true);
  };

  let history = useHistory();

  //BUILD ROWS
  const [gridRows, setGridRows] = useState([]);
  const buildRows = () => {
    let builtRows = [];
    sortedHistoryData.forEach((historyObj) => {
      //build row with grouped events by date as nested event
      let row = {
        id: historyObj.date,
        header: 'dateGroup',
        date: historyObj.date,
        type: '',
        description: '',
        origin: '',
        events: historyObj.events, //array of events
      };
      builtRows.push(row);
    });
    if (builtRows.length > 0) {
      setGridRows(builtRows);
    }
  };

  useEffect(() => {
    console.log('unsorted data has changed');
    if (sortedHistoryData?.length > 0) {
      buildRows();
    } else {
      setGridRows([]);
    }
  }, [
    unsortedHistoryData?.length,
    sortedHistoryData[0]?.events?.length,
    sortedHistoryData[sortedHistoryData?.length - 1]?.date,
    editValue,
  ]); //new events added to today, sortedHistoryData[0] will be most recent date

  //FORMAT DATA FOR TABLE DISPLAY

  //Activity Type
  const getActivityType = (activityTypeId) => {
    switch (activityTypeId) {
      case 1:
        return 'TICKET';
      case 2:
        return 'ENROLLMENT';
      case 3:
        // return "MEMBER"
        return 'PROFILE';
      case 4:
        return 'PAYMENT';
      case 5:
        return 'COMMUNICATION';
      case 6:
        return 'CANCELLATION';
      case 7:
        return 'MODIFICATION';
      case 8:
        return 'NOTE';
      case 9:
        // return "DEPENDENT"
        return 'PROFILE';
      case 10:
        // return "SPOUSE"
        return 'PROFILE';
      case 11:
        // return "SPOUSE"
        return 'UPDATE COVERAGE';
      default:
        return 'OTHER';
    }
  };

  //Time of event - convert to user timezone before formatting time
  const getTime = (date) => {
    let _formattedDate = parseUTCDateToZonedTime(date);
    return format(_formattedDate, 'h:mm a');
  };

  const getToolTipTimeForEditNote = (date) => {
    // adds 24 hours to the date and returns in the format as 08/12 at 11:54am CT
    var tomorrow = new Date(date);
    tomorrow.setDate(tomorrow.getDate() + 1);
    console.log('tomorrow', tomorrow);
    var dateFormat = format(tomorrow, 'MM/dd');
    var timeFormat = format(tomorrow, 'h:mmaaa');
    return `${dateFormat} at ${timeFormat}`;
  };

  //Format date:
  function formatDateDisplay(inputDate) {
    //input date format = 'yyyy-mm-dd'
    const [year, month, day] = inputDate.split('-').map(Number); // Parse components as numbers
    const date = new Date(inputDate);
    const months = [
      'JANUARY',
      'FEBRUARY',
      'MARCH',
      'APRIL',
      'MAY',
      'JUNE',
      'JULY',
      'AUGUST',
      'SEPTEMBER',
      'OCTOBER',
      'NOVEMBER',
      'DECEMBER',
    ];

    // Get month, day, and year
    const monthIndex = date.getMonth();

    // Format the date string
    const formattedDate = `${months[month - 1]} ${day}, ${year}`;
    return formattedDate.toUpperCase();
  }

  //Format description
  const formatDescription = (event) => {
    //Ticket
    switch (event.activityTypeId) {
      case 1:
        return (
          <Typography sx={{ fontSize: '14px!important' }}>
            <Link
              to={{
                pathname: `/tickets/${event?.activityKey}`,
                state: { prevPath: `/members/${event?.memberId}` }, // Preserve previous path
              }}
              style={{ marginRight: '8px' }}
            >
              Ticket #{event?.activityKey}
            </Link>
            {event?.description}
          </Typography>
        );
      default:
        return event?.description;
    }
  };

  //Format Origin - Member, Agent, System

  function formatOrigin(event) {
    if (event?.modifiedBy === 0) {
      return 'SYSTEM';
    } else if (event?.modifiedBy === event?.memberId) {
      return 'MEMBER';
    } else {
      let agent = allUsers?.find((user) => user.id === event?.modifiedBy);
      if (agent && agent?.firstName && agent?.lastName) {
        //format agent name
        let firstInitial = agent?.firstName
          ? agent?.firstName?.charAt(0)?.toUpperCase()
          : '';
        let lastInitial = agent?.lastName
          ? agent?.lastName?.charAt(0)?.toUpperCase()
          : '';

        let firstName = agent?.firstName
          ? firstInitial + agent?.firstName?.slice(1).toLowerCase()
          : '';

        let lastName = agent?.lastName
          ? lastInitial + agent?.lastName?.slice(1).toLowerCase()
          : '';

        return (
          <Box
            sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
          >
            {' '}
            <Avatar
              sx={{
                bgcolor: 'primary.light',
                color: 'primary.main',
                fontFamily: 'Archivo',
                fontWeight: '600',
                width: 30,
                height: 30,
                fontSize: 12,
                margin: '0px 10px',
              }}
              src={`api/image/user/${event?.modifiedBy}`}
            >
              {`${firstInitial}${lastInitial}`}
            </Avatar>{' '}
            {`${firstName} ${lastName}`}
          </Box>
        );
      } else {
        return 'MEMBER'; //need to revisit, currently appuserId and memberId are not the same, need to memberId from the appuser table if itis a member making changes
      }
    }
  }

  return (
    <Box sx={componentStyles.componentBody}>
      <TableContainer
        component={Paper}
        elevation={0}
        sx={componentStyles.tableBody}
      >
        {loading && (
          <>
            <InfinityLoader
              style={{
                position: 'absolute',
                zIndex: 15,
                width: '100%',
                height: '200%',
                backgroundColor: '#00000001',
                backdropFilter: 'blur(6px)',
              }}
            />
            <style>
              {`
                  div.lottie-div > svg {
                     height:55px!important;
                     position:absolute;
                     top:20%;
                  }
               `}
            </style>
          </>
        )}
        <Table>
          <TableHead sx={componentStyles.tableHead}>
            <TableRow>
              <TableCell sx={{ width: '92px' }}>Date</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Origin</TableCell>
            </TableRow>
          </TableHead>
          {gridRows?.length > 0 ? (
            <TableBody sx={{ overflow: 'auto', position: 'relative' }}>
              {gridRows.map((row) => (
                <React.Fragment key={row.id}>
                  {/* date group row */}
                  <TableRow sx={componentStyles.dateHeaderGroupRow}>
                    {/* This cell spans 4 columns*/}
                    <TableCell
                      colSpan={4}
                      sx={componentStyles.dateHeaderGroupRowCell}
                    >
                      {row?.date ? formatDateDisplay(row.date) : ''}
                    </TableCell>
                  </TableRow>

                  {/* events for each date */}
                  {row?.events
                    ?.sort(
                      (a, b) => new Date(b.creation) - new Date(a.creation)
                    )
                    ?.map((event, index) => {
                      let _firstIndex = index === 0; //check for first event in list, do not add top border if first
                      return (
                        <TableRow
                          sx={componentStyles.eventDataRow(_firstIndex)}
                          key={event.id}
                        >
                          <TableCell
                            sx={{
                              ...componentStyles.eventDataCell,
                              color: 'text.secondary',
                              padding: '0px 0px 0px 16px',
                            }}
                          >
                            {event?.creation ? getTime(event.creation) : ''}
                          </TableCell>
                          <TableCell
                            sx={{
                              ...componentStyles.eventDataCell,
                              color: 'text.secondary',
                            }}
                          >
                            {event?.activityTypeId
                              ? getActivityType(event?.activityTypeId)
                              : ''}
                          </TableCell>
                          <TableCell sx={componentStyles.eventDataCell}>
                            {formatDescription(event)}
                            {/* Edit Icon */}
                            {event.activityTypeId &&
                              event.activityTypeId == 8 &&
                              event.modifiedBy == thisUser.id &&
                              event.creation &&
                              new Date(event.creation) < new Date() &&
                              (new Date() - new Date(event.creation)) / 1000 <
                                86400 && (
                                <Tooltip
                                  title={`Can edit until ${getToolTipTimeForEditNote(
                                    event.creation
                                  )}`}
                                >
                                  <IconButton
                                    sx={{
                                      ...componentStyles.eventDataCell,
                                      color: 'primary.main',
                                      marginLeft: '5px',
                                      height: '25px',
                                    }}
                                    onClick={() => handleEdit(event)}
                                  >
                                    <EditOutlinedIcon />
                                  </IconButton>
                                </Tooltip>
                              )}
                            {new Date(event.creation).setMilliseconds(0) <
                              new Date(event.modifiedDate).setMilliseconds(
                                0
                              ) && (
                              <Typography
                                sx={{
                                  color: 'text.disabled',
                                  fontSize: '12px',
                                }}
                              >
                                EDITED
                              </Typography>
                            )}
                          </TableCell>
                          <TableCell
                            sx={{
                              ...componentStyles.eventDataCell,
                              width: '250px',
                            }}
                          >
                            {formatOrigin(event)}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </React.Fragment>
              ))}
            </TableBody>
          ) : (
            <TableBody>
              <TableRow>
                <TableCell colSpan={3}>
                  {props?.historyError ? (
                    <Alert
                      sx={componentStyles.historyErrorAlert}
                      severity="error"
                    >
                      Please select a valid date range
                    </Alert>
                  ) : (
                    <Alert
                      sx={componentStyles.historyErrorAlert}
                      severity="warning"
                    >
                      No events found in this date range
                    </Alert>
                  )}
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <EditNoteDrawer
        noteDrawerOpen={noteDrawerOpen}
        setNoteDrawerOpen={setNoteDrawerOpen}
        currentNote={currentNote}
      />
    </Box>
  );
}
