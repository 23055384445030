import React, { useEffect, useState } from "react";

//MuiIcons

//Mui Components

import Chip from "@mui/material/Chip";

export default function PaymentStatusChips(props) {
  // console.log("COLOR PROPS", props);
  

  const [chip, setChip] = useState({
    backgroundColor: "",
    color: "",
    border: "",
    borderColor: "",
    label: "",
  });

  const getStatusId = (statusName) => {
    if (props.paymentStatuses) {
      let statusId = props.paymentStatuses.find(status => status.name === statusName)?.id;
      return statusId;
    }
  }

  const buildChip = () => {
    switch (props.paymentStatusId) {
      case null:
        setChip({
          backgroundColor: "action.selected",
          color: "text.primary",
          label: "No Payment Status",
        });
        break;

      case 0:
        setChip({
          backgroundColor: "action.selected",
          color: "text.primary",
          label: "No Payment Status",
        });
        break;

      case getStatusId('Accepted'):
        setChip({
          backgroundColor: "rgba(0,0,0,0)",
          border: "solid 1px",
          borderColor: "secondary.payChips.acceptedChip",
          color: "primary.payChips.acceptedChip",
          label: "Accepted",
        });

        break;

      case getStatusId('Error'):
        setChip({
          backgroundColor: "rgba(0,0,0,0)",
          color: "primary.payChips.errorChip",
          border: "solid 1px",
          borderColor: "secondary.payChips.errorChip",
          label: "Error",
        });
        break;

      case getStatusId('Originated'):
        setChip({
          backgroundColor: "rgba(0,0,0,0)",
          color: "primary.payChips.originatedChip",
          border: "solid 1px",
          borderColor: "secondary.payChips.originatedChip",
          label: "Originated",
        });
        break;

      case getStatusId('Settled'):
        setChip({
          backgroundColor: "rgba(0,0,0,0)",
          border: "solid 1px",
          borderColor: "secondary.payChips.settledChip",
          color: "primary.payChips.settledChip",
          label: "Settled",
        });
        break;

      case getStatusId('Partial Settled'):
        setChip({
          backgroundColor: "rgba(0,0,0,0)",
          border: "solid 1px",
          borderColor: "secondary.payChips.settledChip",
          color: "primary.payChips.settledChip",
          label: "Partial Settled",
        });
        break;

      case getStatusId('Verifying'):
        setChip({
          backgroundColor: "rgba(0,0,0,0)",
          color: "primary.payChips.originatedChip",
          border: "solid 1px",
          borderColor: "secondary.payChips.originatedChip",
          label: "Verifying",
        });
        break;

      case getStatusId('Void'):
        setChip({
          backgroundColor: "rgba(0,0,0,0)",
          color: "primary.payChips.voidChip",
          border: "solid 1px",
          borderColor: "secondary.payChips.voidChip",
          label: "Void",
        });
        break;

      case getStatusId('Return'):
        setChip({
          backgroundColor: "rgba(0,0,0,0)",
          color: "primary.payChips.returnedChip",
          border: "solid 1px",
          borderColor: "secondary.payChips.returnedChip",
          label: "Returned",
        });
        break;
      case getStatusId('NSF Declined'):
        setChip({
          backgroundColor: "rgba(0,0,0,0)",
          color: "primary.payChips.errorChip",
          border: "solid 1px",
          borderColor: "secondary.payChips.errorChip",
          label: "NSF Declined",
        });
        break;
      case getStatusId('Refund'):
        setChip({
          backgroundColor: "rgba(0,0,0,0)",
          color: "primary.payChips.refundChip",
          border: "solid 1px",
          borderColor: "secondary.payChips.refundChip",
          label: "Refund",
        });
        break;
      default:
        console.warn("No matching chip found");
    }
  };

  useEffect(() => {
    if (props.paymentStatusId && props.allPayments && props.paymentStatuses)
    buildChip();
  }, [props.paymentStatusId, props.allPayments, props.paymentStatuses]);

  return (
    <>
      <Chip
        sx={{
          backgroundColor: chip.backgroundColor,
          color: chip.color,
          border: chip.border,
          borderColor: chip.borderColor,
          fontWeight: "500",
          fontFamily: "Archivo",
          fontSize: ".9rem",
        }}
        label={chip.label}
      />
    </>
  );
}
