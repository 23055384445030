import React from "react"

//Mui icons
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"

//Mui Components
import Typography from "@mui/material/Typography"
import { Box } from "@mui/system"
import FormControl from "@mui/material/FormControl"
import InputLabel from "@mui/material/InputLabel"
import MenuItem from "@mui/material/MenuItem"
import Select from "@mui/material/Select"

//Our styles
import activeCoverageStyles from "../activeCoverageStyles"

export default function NonIncrementalStdAndLtdActiveCoverage(props) {
  //destructure props
  let {
    benefitDurTypes,
    edit,
    plan,
    planOptions,
    handlePlanEdit,
    selectedOption,
  } = props
  //Edit Plan Info

  return (
    <>
      {/* Benefit Amount Row */}
      {plan?.benefitAmount && plan?.benefitAmount > 0 ? (
        <Box sx={activeCoverageStyles.planInfoRow}>
          <Typography variant="body1">Benefit Amount</Typography>
          {/* Non-edit view */}
          {!edit && (
            <Typography variant="body1" sx={activeCoverageStyles.planInfoText}>
              {props?.UsdFormatterDec.format(plan?.benefitAmount)}
              {plan?.benefitTypeFriendly &&
              plan?.benefitTypeFriendly?.toLowerCase() !== "fixed"
                ? ` ${plan?.benefitTypeFriendly}`
                : ""}
            </Typography>
          )}

          {/* EDIT benefit amount */}
          {edit && (
            <Box sx={activeCoverageStyles.editInputContainer}>
              <FormControl fullWidth>
                <InputLabel id="benefitAmount">Benefit Amount</InputLabel>
                <Select
                  variant="outlined"
                  size="small"
                  fullWidth
                  label="Benefit Amount"
                  // labelID="benefitAmount"
                  sx={activeCoverageStyles.selectDropDown}
                  IconComponent={() => (
                    <KeyboardArrowDownIcon
                      sx={activeCoverageStyles.selectDownArrowIcon}
                    />
                  )}
                  value={planOptions.selectedOptionId}
                  onChange={(e) => handlePlanEdit(e, "nonIncremental")}
                >
                  {planOptions.options &&
                    planOptions.options.map((option) => {
                      return (
                        <MenuItem
                          key={option.memberOptionId}
                          value={option.memberOptionId}
                        >
                          {props.UsdFormatterDec.format(
                            option?.benefitAmount || 0
                          )}{" "}
                          {option?.benefitTypeFriendly?.toLowerCase() !== "fixed"
                            ? option?.benefitTypeFriendly
                            : ""}
                          {option?.memberPremiumAmount
                            ? ` (${props?.UsdFormatterDec.format(
                                option?.memberPremiumAmount
                              )})`
                            : ""}
                        </MenuItem>
                      )
                    })}
                </Select>
              </FormControl>
            </Box>
          )}
        </Box>
      ) : null}

      {/* Elimination Period Row*/}
      {plan?.eliminationPeriod ? (
        <Box sx={activeCoverageStyles.planInfoRow}>
          <Typography variant="body1">Elimination Period</Typography>
          {!edit && (
            <Typography variant="body1" sx={activeCoverageStyles.planInfoText}>
              {plan?.eliminationPeriod}{" "}
              {plan?.eliminationPeriodTypeFriendly
                ? ` ${plan?.eliminationPeriodTypeFriendly}`
                : ""}
            </Typography>
          )}

          {/* EDIT view elimination period - can vary with different benefit amount selections */}
          {edit && (
            <Typography variant="body1" sx={activeCoverageStyles.planInfoText}>
              {`${selectedOption?.eliminationPeriod} ${
                selectedOption?.eliminationPeriodType
                  ? selectedOption?.eliminationPeriodType
                  : ""
              }`}
            </Typography>
          )}
        </Box>
      ) : null}

      {/* Benefit Duration Row*/}
      {plan?.benefitDuration ? (
        <Box sx={activeCoverageStyles.planInfoRow}>
          <Typography variant="body1">Benefit Duration</Typography>

          {!edit && (
            <Typography variant="body1" sx={activeCoverageStyles.planInfoText}>
              {plan?.benefitDuration}
              {plan?.benefitDurationTypeFriendly
                ? ` ${plan?.benefitDurationTypeFriendly}`
                : ""}
            </Typography>
          )}

          {/* EDIT view for benefit duration - can vary w/ benefit amount changes*/}
          {edit &&
            (!planOptions.isIncremental ||
              (planOptions?.isIncremental &&
                benefitDurTypes?.length === 1)) && (
              <Typography
                variant="body1"
                sx={activeCoverageStyles.planInfoText}
              >
                {`${selectedOption?.benefitDuration} ${
                  selectedOption?.benefitDurationType || ""
                }`}
              </Typography>
            )}
        </Box>
      ) : null}
    </>
  )
}
