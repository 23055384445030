import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

//Services


// Assets


//MuiIcons



//MuiComponents
import Button from "@mui/material/Button";
import { InputLabel, TextField } from "@mui/material";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';


export default function SettingsProfile(props) {
   // let history = useHistory()
   // const [thisUser,setThisUser] = useState()
   //  let getThisUser = async () => {
   //      let _thisUser = await memberService.getMe()
   //      setThisUser(_thisUser)
   //      // console.log('thisUser ',_thisUser)
   //  }
   //  useEffect(() => {
   //      getThisUser()
   //  },[])
   // let thisUser = props.thisUser;
   const [disableSave, setDisableSave] = useState(true)
   const [userInfo, setUserInfo] = useState()

   useEffect(() => {
      if (props.thisUser) {
         let _userInfo = {
            firstName: props.thisUser.firstName,
            lastName: props.thisUser.lastName,
            email: props.thisUser.email,
            id: props.thisUser.id,
            role: "Developer",
            timezone: "Mountain Standard Time (MST) UTC-07:00",
            permissions: "Admin"
         }

         setUserInfo(_userInfo)
      }
   }, [props.thisUser])

   useEffect(() => {
   }, [])


   let handleInfoChange = (e, property) => {
      let value = e.target.value
      setUserInfo({
         ...userInfo,
         [property]: value
      })
      setDisableSave(false)
   }


   return (
      <>
         {/* ACTUAL CONTENT */}

         <div className="container" style={{ height: 'calc(100vh - 219px)', backgroundColor: "FFFFFF", margin: 20 }}>
            <div className="row" style={{ height: 'auto' }}>
               <div className='col-md-12' style={{ marginBottom: "40px"}}>
                  <h5>Notification Settings</h5>
                  <p>We may still send you important notifications about your account outside of your notification settings.</p>
               </div>
               
               <div className={`col-md-3`} style={{ marginBottom: "40px"}}>
                  <h6>New Ticket Assignments</h6>
                  <p>These are notifications when you are assigned a new ticket.</p>

               </div>
               <div className={`col-md-9`}>
               <FormControlLabel control={<Switch defaultChecked />} label="System Notifications" />< br />
               <FormControlLabel control={<Switch defaultChecked />} label="Email Notifications" />
               </div>

               <div className={`col-md-3`} style={{ marginBottom: "40px"}}> 
                  <h6>Ticket Response</h6>
                  <p>These are notifications that a customer has responded to a ticket that is currently assigned to you or you are watching. </p>

               </div>
               <div className={`col-md-9`}>
               <FormControlLabel control={<Switch defaultChecked />} label="System Notifications" />< br />
               <FormControlLabel control={<Switch defaultChecked />} label="Email Notifications" />
               </div>

               <div className={`col-md-3`} style={{ marginBottom: "40px"}}>
                  <h6>Comments</h6>
                  <p>These are notifications that someone has commented on a ticket that is currently assigned to you or you are watching.</p>

               </div>
               <div className={`col-md-9`}>
               <FormControlLabel control={<Switch defaultChecked />} label="System Notifications" />< br />
               <FormControlLabel control={<Switch defaultChecked />} label="Email Notifications" />
               </div>

               <div className={`col-md-3`}>
                  <h6>Tags</h6>
                  <p>These are notifications that someone has tagged you in a comment on a ticket.</p>

               </div>
               <div className={`col-md-9`}>
               <FormControlLabel control={<Switch defaultChecked />} label="System Notifications" />< br />
               <FormControlLabel control={<Switch defaultChecked />} label="Email Notifications" />
               </div>
            </div>
         </div>


      </>
   )
}