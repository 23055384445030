import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';

//Services

//MuiComponents
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Drawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

//MuiIcons
import CloseIcon from '@mui/icons-material/Close';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import { border, flexbox, maxWidth } from '@mui/system';

const componentStyles = {
  //Message styles
  messageContainer: {
    marginBottom: '20px',
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  iconButton: {
    padding: '0px',
    borderRadius: '100%',
  },
  closeIcon: {
    color: '#195ffb',
    height: '36px',
    width: '36px',
    padding: '0px',
  },
  innerTextStyle: {
    width: '80%',
  },
  userInfoContainer: { margin: '20px 5px 20px 20px' },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '70%',
    maxWidth: '250px',
    margin: '0 auto',
  },
  buttonStyle: {
    minWidth: '100px',
  },
};
function InternalDuplicateEmailMessage(props) {
  return (
    <Box sx={componentStyles.messageContainer}>
      <Box sx={componentStyles.headerContainer}>
        <Typography variant="blueAccent">Is this you?</Typography>
        <IconButton
          onClick={props?.handleClose}
          sx={componentStyles.iconButton}
        >
          <CloseIcon sx={componentStyles.closeIcon} />
        </IconButton>
      </Box>

      <Typography variant="body1" sx={componentStyles.innerTextStyle}>
        The email you provided was located on the Teamsters 728 membership
        census. Help us confirm some information to connect your accounts.
      </Typography>

      <Box sx={componentStyles.userInfoContainer}>
        <Typography
          variant="body1"
          sx={{ ...componentStyles.innerTextStyle, marginBottom: '10px' }}
        >
          <strong>Name:</strong> John Smith
        </Typography>
        <Typography variant="body1" sx={componentStyles.innerTextStyle}>
          <strong>Email:</strong> yourEmail@noreply.com
        </Typography>
      </Box>
      <Box sx={componentStyles.buttonContainer}>
        <Button variant="outlined" sx={componentStyles.buttonStyle}>
          No
        </Button>
        <Button variant="contained" sx={componentStyles.buttonStyle}>
          Yes
        </Button>
      </Box>
    </Box>
  );
}

export default function DuplicateEmailDrawer(props) {
  //display drawer vs. modal for screen size
  if (window.innerWidth < 800) {
    return (
      <Drawer
        open={props.open}
        // open={true}
        onClose={props.handleClose}
        anchor="bottom"
        sx={{ maxHeight: '200px', zIndex: '10' }}
        PaperProps={{
          sx: {
            borderRadius: '12px 12px 0px 0px',
            padding: '16px 24px',
            bgcolor: 'background.default',
            color: 'text.primary',
          },
        }}
        disableScrollLock={true}
      >
        <InternalDuplicateEmailMessage
          open={props?.open}
          handleClose={props?.handleClose}
          handleOpen={props?.handleOpen}
        />
      </Drawer>
    );
  } else {
    return (
      <Modal
        open={props.open}
        // open={true}
        onClose={props.handleClose}
        onClick={(e) => e.preventDefault()}
        // keepMounted
        sx={{
          position: 'absolute',
          borderRadius: '12px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        container={props.container || document.body}
        hideBackdrop={props.agentEnroller ? true : false}
        disableScrollLock={true}
      >
        <Paper
          sx={{
            borderRadius: '12px 12px 12px 12px',
            padding: '16px 24px',
            bgcolor: 'background.default',
            color: 'text.primary',
            maxWidth: '425px',
            minHeight: '152px',
          }}
        >
          <Box
            sx={{
              zIndex: '-4',
              position: 'absolute',
              height: '100%',
              width: '100%',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              top: 0,
              left: 0,
            }}
          />
          <InternalDuplicateEmailMessage
            open={props?.open}
            handleClose={props?.handleClose}
            handleOpen={props?.handleOpen}
          />
        </Paper>
      </Modal>
    );
  }
}
