import React, { useState, useEffect, useLayoutEffect, useMemo } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import './StandaloneRateCalculator.css';

import WebFont from 'webfontloader';

//Assets
import unionHubLogo from '../../assets/images/favicon.ico';
import updatedFavicon from '../../assets/images/updatedFavicon.ico';
import PlaceHolderLogo from '../../assets/images/placeHolder.svg';
import longUhLogo from '../../assets/images/unionhub.png';
import BlueBlackUHLogo from '../../assets/images/BlueBlackUH.svg';

//Services
import calculatorService from '../../services/calculator-service';

//MuiComponents
import TextField from '@mui/material/TextField';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import Zoom from '@mui/material/Zoom';
import FormHelperText from '@mui/material/FormHelperText';
import InputAdornment from '@mui/material/InputAdornment';
import Box from '@mui/material/Box';

//MuiX
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

//Our components
import CalculatorLadd from './calculator-ladd/CalculatorLadd';
import CalculatorStd from './calculator-std/CalculatorStd';
import CalculatorLtd from './calculator-ltd/CalculatorLtd';
import RecommendationSurvey from './recommendation-survey/RecommendationSurvey';
import SaveChangesSnackbar from '../save-changes-snackbar/SaveChangesSnackbar';

//Our icons
import CustomCheckIcon from '../../assets/images/CustomCheckIcon.svg';
import { Divider, Typography } from '@mui/material';
import CalculatorCriticalIllness from './calculator-criticalIllness/CalculatorCriticalIllness';
import CalculatorMembership from './calculator-membership/CalculatorMembership';

export default function StandaloneRateCalculator(props) {
  const history = useHistory();

  //Currency formatting
  const UsdFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
  });
  let UsdFormatterDec = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
  });
  //Displaying greyed out on load
  const [dummyDisplay, setDummyDisplay] = useState(true);
  let dummyFontColor = dummyDisplay ? '#00000033' : 'black';

  const componentStyles = {
    divider: {
      width: '98%',
      height: '1px',
      margin: '0 auto',
      marginBottom: '20px',
      color: 'background.border',
      opacity: 0.6,
    },
  };

  //Plan info displayed to user
  const [subgroups, setSubgroups] = useState(null);
  const [results, setResults] = useState(null);

  const getGroupInfo = async () => {
    let subgroupsResults;
    try {
      subgroupsResults = await calculatorService.getSubGroups();
    } catch (error) {
      console.log('error getting subgroups', error);
    }
    setSubgroups(subgroupsResults);
    let GroupPlanId = null;
    let OrganizationId = null;

    console.log('subgroups', subgroupsResults);

    //If there's only one choice, then that's our id, else set to 0 to allow the dropdown
    if (subgroupsResults?.groupPlans.length == 1)
      GroupPlanId = subgroupsResults.groupPlans[0].id;
    if (
      subgroupsResults?.organizations.length == 1 ||
      GroupPlanId == 9 ||
      GroupPlanId == 19
    ) {
      OrganizationId = subgroupsResults?.organizations[0].id;
    } else {
      OrganizationId = subgroupsResults?.parentOrganizationId;
    }

    setUserInfo({
      ...userInfo,
      GroupPlanId: GroupPlanId,
      OrganizationId: OrganizationId,
    });

    if (
      (subgroupsResults?.organizations.length == 1 &&
        subgroupsResults.groupPlans.length == 1) ||
      GroupPlanId == 19 ||
      GroupPlanId == 34 ||
      (GroupPlanId > 0 && OrganizationId > 0)
    ) {
      //Got what we need, show us the plans
      let mockInfo = {
        GroupPlanId: GroupPlanId,
        Salary: 80000,
        DOB: '1984-08-10T18:25:43-05:00',
        OrganizationId: OrganizationId,
        IsFullTime: Boolean(survey?.isFullTime),
        FamilyPlanning: Boolean(survey?.FamilyPlanning),
        WorksInRail: Boolean(survey?.WorksInRail),
        IsPilot: Boolean(survey?.IsPilot),
      };

      let _results;
      try {
        _results = await calculatorService.calculateRates(mockInfo);
      } catch (error) {
        console.log('error calculating rates', error);
      }
      setResults(_results);
    }
  };

  useEffect(() => {
    getGroupInfo();
  }, []);

  useEffect(() => {
    getGroupInfo();
  }, []);

  let organizationSelectHandler = async (e, i) => {
    let value = e.target.value;

    setUserInfo({
      ...userInfo,
      OrganizationId: value,
    });

    if (userInfo.GroupPlanId != null) {
      let mockInfo = {
        GroupPlanId: userInfo.GroupPlanId,
        Salary: 80000,
        DOB: '1984-08-10T18:25:43-05:00',
        OrganizationId: value,
        IsFullTime: Boolean(survey?.isFullTime),
        FamilyPlanning: Boolean(survey?.FamilyPlanning),
        WorksInRail: Boolean(survey?.WorksInRail),
        IsPilot: Boolean(survey?.IsPilot),
      };
      let _results = await calculatorService.calculateRates(mockInfo);
      setResults(_results);
    }
  };
  let groupPlanSelectHandler = async (e, i) => {
    let value = e.target.value;

    setUserInfo({
      ...userInfo,
      GroupPlanId: value,
    });

    if (userInfo.OrganizationId != null) {
      let mockInfo = {
        GroupPlanId: value,
        Salary: 80000,
        DOB: '1984-08-10T18:25:43-05:00',
        OrganizationId: userInfo.OrganizationId,
        IsFullTime: Boolean(survey?.isFullTime),
        FamilyPlanning: Boolean(survey?.FamilyPlanning),
        WorksInRail: Boolean(survey?.WorksInRail),
        IsPilot: Boolean(survey?.IsPilot),
      };
      let _results = await calculatorService.calculateRates(mockInfo);
      setResults(_results);
    }
  };

  //LOCATION STUFF
  // console.log(window.location.hostname.split("."),window.location.hostname.split(".")[0])

  //Loading these here both for dynamic font, and this is the alternative to a font download in index.html
  useLayoutEffect(() => {
    WebFont.load({
      google: {
        families: ['Archivo:100,200,300,400,500,600,700,800,900', 'Roboto'],
      },
    });
    document.title = `UnionHub | ${groupPlanName}`;
    // let ico = document.querySelector("link[rel*='icon']") || document.createElement('link');
    // ico.href = (unionLogo || unionHubLogo);
    // ico.href = updatedFavicon
    // document.getElementsByTagName('head')[0].appendChild(ico);
  }, []);

  //This is swapping out the ugly Archivo ampersands
  useEffect(() => {
    const planOptionNames =
      document.getElementsByClassName('plan-option-title');

    for (const span of planOptionNames) {
      span.innerHTML = span.innerHTML
        .replace(/&/g, "<span style='font-family: Roboto, sans-serif'>&</span>")
        .replace(/amp;/g, '');
    }
    // console.log("results", results)
    // console.log(window.innerWidth)
    // console.log(document.documentElement.clientWidth)
  }, [results]);

  //User info state that we'll send to back
  const [userInfo, setUserInfo] = useState({
    GroupPlanId: null,
    Salary: '',
    DOB: null,
    OrganizationId: null,
  });

  //Salary handler, dynamic error styling
  const handleSalaryChange = (e) => {
    let newValue = e.target.value;
    // console.log(newValue)
    if (
      newValue !== '' &&
      newValue !== NaN &&
      newValue !== '$Na' &&
      newValue !== '$'
    ) {
      newValue = newValue.replace('$', '').replaceAll(',', '');
      newValue = parseInt(newValue);
    } else if (
      newValue == '' ||
      newValue == NaN ||
      newValue == '$Na' ||
      newValue == '$'
    ) {
      newValue = 0;
    }
    // console.log(newValue)

    setUserInfo({
      ...userInfo,
      Salary: newValue,
    });

    salaryBlur(newValue);
  };
  const [salaryErrorMsg, setSalaryErrorMsg] = useState();
  let salaryErrorBorder = salaryErrorMsg ? 'solid red' : '';

  const salaryBlur = (value = userInfo.Salary) => {
    if (value > results?.maximumSalary) {
      // setSalaryErrorMsg("Salary is capped at " + results.maximumSalary)
      setSalaryErrorMsg();
    } else if (value < results?.minimumSalary) {
      setSalaryErrorMsg(
        'Salary must be greater than ' +
          UsdFormatter.format(results?.minimumSalary || 0)
      );
    } else setSalaryErrorMsg();
  };

  //Dob handler (desktop & mobile) both use the same datepicker

  //Date logic
  let today = new Date();
  let todayMilli = today.getTime();

  let openEnrollmentEnd = subgroups ? subgroups.openEnrollmentEnd : null;
  let openEnrollmentEndMilli = null;

  if (openEnrollmentEnd) {
    openEnrollmentEndMilli = new Date(openEnrollmentEnd).getTime();
    openEnrollmentEnd = new Date(openEnrollmentEnd).toLocaleDateString();
  }

  let enrollmentDaysLeft = subgroups
    ? Math.round((openEnrollmentEndMilli - todayMilli) / (1000 * 60 * 60 * 24))
    : null;

  let openEnrollmentStart = subgroups ? subgroups.openEnrollmentStart : null;
  if (openEnrollmentStart) {
    today = new Date(openEnrollmentStart);
    openEnrollmentStart = new Date(openEnrollmentStart).toLocaleDateString();
  }

  let day = today.getDate();
  let month = today.getMonth() + 1;
  let year = today.getFullYear();
  day = day < 10 ? '0' + day : day;
  month = month < 10 ? '0' + month : month;
  let dateMax = `${year - 18}-${month}-${day}`;
  let dateMaxObj = new Date(dateMax);
  let dateMin = `${year - 100}-${month}-${day}`;
  let dateMinObj = new Date(dateMin);

  const [dobError, setDobError] = useState(false);

  const dobErrorMessage = useMemo(() => {
    switch (dobError) {
      case 'maxDate': {
        return 'Must be older than 18 years old';
      }
      case 'minDate': {
        return 'Must be less than 100 years old';
      }
      case 'disableFuture': {
        return 'Must be greater than 0 years old';
      }
      case 'invalidDate': {
        return 'Your date is not valid';
      }
      default: {
        return '';
      }
    }
  }, [dobError]);

  const handleDobChangeMobile = (e) => {
    let inputVal = e;
    // inputVal = format(new Date(e), 'yyyy-MM-dd')
    // let dateWithinRange = ((new Date(inputVal).getFullYear() >= new Date(dateMin).getFullYear()) && (new Date(inputVal).getFullYear() <= new Date(dateMax).getFullYear()))

    // if (dateWithinRange) {

    setUserInfo({
      ...userInfo,
      DOB: inputVal,
    });

    // }
    // checkAllow();
  };

  // const handleDobChange = (e) => {
  //     let newValue = e.target.value + "T00:00:00";
  //     // console.log('desktopDob',newValue)
  //     let dateWithinRange = ((new Date(newValue).getFullYear() >= new Date(dateMin).getFullYear()) && (new Date(newValue).getFullYear() <= new Date(dateMax).getFullYear()))
  //     if (dateWithinRange) {
  //         setUserInfo({
  //             ...userInfo,
  //             DOB: newValue
  //         })
  //         setDobErrorMsg()
  //     }
  //     else {
  //         setUserInfo({
  //             ...userInfo,
  //             DOB: newValue
  //         })
  //         setDobErrorMsg("Please select a valid date")
  //     }
  // }

  //Survey Questions
  const [survey, setSurvey] = useState({
    IsMarried: null,
    IsPrimary: null,
    HaveDependents: null,
    Mortgage: null,
    PolicyCount: 0,
    isFullTime: null,
    FamilyPlanning: null,
    WorksInRail: null,
    IsPilot: null,
    iOfficer: null,
  });
  let surveyHandler = (question, answer) => {
    if (survey[question] == answer) {
      setSurvey({
        ...survey,
        [question]: null,
      });
    } else {
      setSurvey({
        ...survey,
        [question]: answer,
      });
    }
  };

  //Recc state,effects
  const [recommendations, setRecomendations] = useState({});
  useEffect(() => {
    //we're no longer pre selecting based on recommendations
    // if(results){
    // let stdPlanOptions = results.stdPlan.options;
    // let stdId;
    // let stdVal;
    // for (let i=0;i<stdPlanOptions.length;i++){
    //     if (selectionRec(stdPlanOptions.length,i)){
    //         stdId = stdPlanOptions[i].memberOptionId;
    //         stdVal = stdPlanOptions[i].memberPremiumAmount;
    //     }
    // }
    // let ltdPlanOptions = results.ltdPlan.options;
    // let ltdId;
    // let ltdVal;
    // for (let i=0;i<ltdPlanOptions.length;i++){
    //     if (selectionRec(ltdPlanOptions.length,i)){
    //         ltdId = ltdPlanOptions[i].memberOptionId;
    //         ltdVal = ltdPlanOptions[i].memberPremiumAmount;
    //     }
    // }
    // let lifeADDPlan = results.lifeADDPlan;
    // setSelections({
    //     ...selections,
    //     std:stdId,
    //     stdVal:stdVal,
    //     ltd:ltdId,
    //     ltdVal:ltdVal
    // })
    // }
    // console.log(recommendations)
  }, [recommendations]);

  //Selections state,handlers,effects
  const [runningTotal, setRunningTotal] = useState(0);
  const [selections, setSelections] = useState({
    std: undefined, //optionId
    stdVal: 0, //PremiumAmount
    ltd: undefined, //optionId
    ltdVal: 0, //PremiumAmount
    ladd: [0, 0, 0], // Coverage Amounts
    laddVal: [0, 0, 0], //Premium amounts
  });
  const selectionHandler = (e) => {
    if (e.target != null) {
      let premium = parseFloat(e.target.getAttribute('data-amount'));
      let value = parseFloat(e.target.value);
      let plan = e.target.name;
      let planVal = plan + 'Val';
      setSelections({
        ...selections,
        [plan]: value,
        [planVal]: premium,
      });
    } else {
      let premium = parseFloat(e.getAttribute('data-amount'));
      let value = parseFloat(e.value);
      let plan = e.name;
      let planVal = plan + 'Val';

      // console.log("SelectionHandler", premium, value, plan, planVal)

      setSelections({
        ...selections,
        [plan]: value,
        [planVal]: premium,
      });
    }
  };

  const selectionRec = (options, index) => {
    let value = options[index];
    let newArray = options.map((x) => x);

    //assuming last one is the 'not now' option, pop it off the array.
    newArray.pop();

    newArray.sort((a, b) => {
      return a.memberPremiumAmount - b.memberPremiumAmount;
    });
    let sortedIndex = newArray.findIndex(
      (x) => x.memberOptionId === value.memberOptionId
    );

    if (recommendations.Lowest && sortedIndex == 0) return true;
    if (
      newArray.length > 2 &&
      !recommendations.Lowest &&
      sortedIndex == newArray.length - 2
    )
      return true;
    if (
      newArray.length <= 2 &&
      !recommendations.Lowest &&
      sortedIndex == newArray.length - 1
    )
      return true;
    else return false;
  };

  useEffect(() => {
    let selectionsAsArray = Object.entries(selections);
    let _total = 0;
    for (let [key, value] of selectionsAsArray) {
      //Adding up our monthly cost
      if (typeof value == 'object' && key.includes('Val')) {
        //It's a num array(ladd), so get the total
        let sum = value.reduce(
          (prevValue, currValue) => prevValue + currValue,
          0
        );
        _total += sum;
      } else if (key.includes('Val')) _total += parseFloat(value);
    }
    if (_total > 0) _total += results?.transactionFee;
    setRunningTotal(_total);
  }, [selections]);

  const goToEnroll = () => {
    // window.location.href = results.enrollmentURL;
    // window.open(subgroups.enrollmentURL)
    history.push('/enroll');
  };

  const handleCalculateClick = () => {
    setDummyDisplay(false);
    showZoom();
    calculateNewRates();
  };
  const calculateNewRates = async () => {
    let _userInfo = {
      GroupPlanId: userInfo.GroupPlanId,
      Salary: Math.min(userInfo.Salary, results?.maximumSalary),
      DOB: userInfo.DOB, //in date obj format from DatePicker
      OrganizationId: userInfo.OrganizationId,
      IsFullTime: Boolean(survey?.isFullTime),
      FamilyPlanning: Boolean(survey?.FamilyPlanning),
      WorksInRail: Boolean(survey?.WorksInRail),
      IsPilot: Boolean(survey?.IsPilot),
    };
    console.log('calculating new rates', _userInfo);
    // _userInfo.DOB += "T00:00:00"
    console.log(
      'standalone calcing rates',
      _userInfo,
      results?.maximumSalary,
      results
    );
    let _results = await calculatorService.calculateRates(_userInfo);
    setResults(_results);
    let _surveyResults = await calculatorService.getRecommendations(survey);
    setRecomendations(_surveyResults);
  };

  ////////////Dynamic variables

  //Dynamic style
  let salaryFontColor = userInfo.Salary == 0 ? '#195ffb54' : '#195ffb';
  let dobFontColor = userInfo.DOB == '' ? '#195ffb54' : '#195ffb';

  const [showLoading, setShowLoading] = useState(false);
  const [calculated, setCalculated] = useState(false);
  const showZoom = () => {
    setShowLoading(true);
    setCalculated(true);
    setTimeout(() => setShowLoading(false), 1000);
  };

  const [displayAlert, setDisplayAlert] = useState(false);
  const checkCalculateAllowed = () => {
    if (
      userInfo.Salary == 0 ||
      userInfo.DOB == '' ||
      !userInfo.OrganizationId ||
      !userInfo.GroupPlanId ||
      salaryErrorMsg
    ) {
      setDisplayAlert(true);
    }
  };

  let groupPlanName = subgroups?.groupPlans[0]?.name || 'Benefit Calculator';
  //let groupPlanName = "UnionHub"

  const weekdays = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];
  const shortWeekdays = ['Sun', 'Mon', 'Tues', 'Wed', 'Thurs', 'Fri', 'Sat'];
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  const shortMonths = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'June',
    'July',
    'Aug',
    'Sept',
    'Oct',
    'Nov',
    'Dec',
  ];
  const enrollmentEndsDate = `${
    shortWeekdays[new Date(subgroups?.openEnrollmentEnd).getDay()]
  } ${
    shortMonths[new Date(subgroups?.openEnrollmentEnd).getMonth()]
  } ${new Date(subgroups?.openEnrollmentEnd).getDate()}`;
  const hours = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11,
    12,
  ];
  const enrollmentEndsTime = `at ${new Date(subgroups?.openEnrollmentEnd)
    .toLocaleTimeString(undefined, {
      day: '2-digit',
      timeZoneName: 'short',
      hour: '2-digit',
      minute: '2-digit',
    })
    .substring(4)}`;
  console.log(enrollmentEndsTime);

  return (
    <Box
      id="rate-calculator-body"
      sx={{ margin: '0 auto', padding: '20px', maxWidth: '640px' }}
      className="flex-container"
    >
      {/* Title */}
      <div className="flex-row">
        <div className="col-8">
          <Typography
            variant="h4"
            fontWeight="600"
            fontFamily="Poppins"
            fontSize="32px"
            lineHeight="33px"
            marginBottom="12px"
          >
            {groupPlanName}
          </Typography>
          <Typography
            variant="h5"
            fontFamily="Poppins"
            lineHeight="33px"
            marginBottom="12px"
          >
            Open Enrollment
          </Typography>
        </div>
        <div
          className="col"
          style={{ display: 'flex', justifyContent: 'flex-end' }}
        >
          <img
            alt="logo"
            src={'/api/image/organization'}
            style={{
              maxHeight: '100px',
              maxWidth: '100%',
              objectFit: 'contain',
            }}
          />
        </div>
      </div>

      <div className="flex-row" style={{ marginBottom: '20px' }}>
        <Typography variant="body1">
          {results?.description ||
            "Your income is at risk if you cannot work due to illness, injury, surgery, or death. This plan is an easy, affordable way to ensure your income and your family's financial well-being are protected."}
        </Typography>
      </div>
      <Divider sx={componentStyles.divider} />
      {/* Enrollment Ends */}
      {/* {subgroups?.openEnrollmentEnd &&
                <div className="flex-row" style={{ marginBottom: '20px' }}>
                    <div className='col-6'>
                        <Typography variant="overline" fontWeight="400" textAlign="Left" fontSize="12px" fontFamily="Archivo">
                            Enrollment&nbsp;Window&nbsp;Ends:
                        </Typography><br />
                        <Typography variant="subtitle" fontWeight="500" textAlign="left" fontSize="30px" fontFamily="Poppins">
                            {subgroups && enrollmentEndsDate}
                        </Typography><br />
                        <Typography variant="subtitle2" fontWeight="400" textAlign="left" fontSize="16px" fontFamily="Archivo">
                            {subgroups && enrollmentEndsTime}
                        </Typography>

                    </div>
                    <div className='col-6'>
                        <div className="enrollment-countdown">

                            <Typography variant="body1" fontFamily="Poppins" fontWeight="600" fontSize="30px" lineHeight="35px" color="primary.main" >{(subgroups && enrollmentDaysLeft > 0 && enrollmentDaysLeft) || "0"}</Typography>
                            <Typography variant="body1" fontFamily="Archivo" fontWeight="600" fontSize="15px" lineHeight="35px" color="#646464" textTransform="uppercase">days left</Typography>
                        </div>
                    </div>
                </div>
            } */}

      {/* Input Fields */}

      <div className="flex-row">
        <div className="col-12">
          {!calculated && (
            <>
              <Typography variant="body1" sx={{ marginBottom: '10px' }}>
                Answer these questions to generate your custom benefits options.
              </Typography>
              {/* Grouo Plan Input */}
              {subgroups?.groupPlans.length > 1 && (
                <FormControl fullWidth sx={{ marginBottom: '15px' }}>
                  <InputLabel
                    id="calculator-group-select"
                    sx={{ fontFamily: 'Poppins!important' }}
                  >
                    Your Group
                  </InputLabel>
                  <Select
                    labelId="calculator-group-select"
                    value={userInfo?.GroupPlanId}
                    label="Your Group"
                    onChange={groupPlanSelectHandler}
                    sx={{ marginBottom: '10px' }}
                  >
                    {subgroups?.groupPlans.map((group) => {
                      return <MenuItem value={group.id}>{group.name}</MenuItem>;
                    })}
                  </Select>
                </FormControl>
              )}

              {/* Organization Input ////////HIDING PER ED REQUEST */}
              {/* {(subgroups?.organizations.length > 1 && subgroups?.groupPlans[0].id !== 9) &&
                        <><FormControl fullWidth >
                            <InputLabel id="calculator-organization-select" sx={{ fontFamily: 'Poppins!important' }} >Your Organization</InputLabel>
                            <Select
                                labelId="calculator-organization-select"
                                value={userInfo?.OrganizationId}
                                label="Your Organization"
                                onChange={organizationSelectHandler}
                                sx={{ marginBottom: '10px' }}

                            >
                               {subgroups?.organizations?.sort((a, b) => {
                                    const numA = a.name.match(/\d+/);
                                    const numB = b.name.match(/\d+/);
                                
                                    // If both have numbers, compare numerically
                                    if (numA && numB) {
                                      return parseInt(numA[0], 10) - parseInt(numB[0], 10);
                                    }
                                
                                    // If neither has numbers, compare alphabetically
                                    if (!numA && !numB) {
                                      return a.name.localeCompare(b.name);
                                    }
                                
                                    // If only one has a number, that comes first
                                    return numA ? -1 : 1;
                                }).map(organization => {
                                    return <MenuItem value={organization.id}>{organization.name}</MenuItem>
                                })}
                            </Select>
                        </FormControl>
                        </>
                    } */}

              {/* Salary Input */}
              <div className="col-12">
                <TextField
                  label="Estimated Annual Earnings"
                  id="SalaryUpdater"
                  onChange={handleSalaryChange}
                  onBlur={salaryBlur}
                  value={
                    userInfo.Salary !== ''
                      ? UsdFormatter.format(userInfo.Salary)
                      : ''
                  }
                  variant="outlined"
                  helperText={salaryErrorMsg}
                  error={salaryErrorMsg}
                  type={window.innerWidth < 500 ? 'tel' : ''}
                  fullWidth
                  sx={{ marginBottom: '15px' }}
                  InputProps={{
                    disableUnderline: true,
                  }}
                  disabled={
                    userInfo.OrganizationId == null ||
                    userInfo.GroupPlanId == null
                  }
                />
              </div>

              <div className="col-12">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    disableFuture
                    label="Date of Birth"
                    openTo="year"
                    views={['year', 'month', 'day']}
                    format="MM/dd/yyyy"
                    minDate={dateMinObj}
                    maxDate={dateMaxObj}
                    value={userInfo.DOB || null}
                    onChange={handleDobChangeMobile}
                    // onAccept={handleDobBlur}
                    onError={(error) => setDobError(error)}
                    sx={{ width: '100%' }}
                    slotProps={{
                      textField: {
                        helperText: dobErrorMessage,
                      },
                      openPickerButton: { sx: { visibility: 'hidden' } }, //hide calendar icon calandar
                    }}
                  />
                </LocalizationProvider>
              </div>
              {/* <div className='col-12 desktop-picker' >
                        <TextField id="DOBinput"
                            variant='outlined' label='Date of Birth' shrink
                            value={userInfo.DOB.replace('T00:00:00', '')} onChange={handleDobChange}
                            // placeholder="mm/dd/yyyy"
                            helperText={dobErrorMsg} error={dobErrorMsg}
                            fullWidth
                            type="date"
                            sx={{ marginBottom: '15px' }}
                            InputLabelProps={{ shrink: true, style: { fontFamily: 'Poppins' } }}
                            InputProps={{
                                inputProps: {
                                    min: dateMin,
                                    max: dateMax,
                                },
                                disableUnderline: true,
                                sx: {
                                    // backgroundColor: "#EFF5F9", 
                                    // color: dobFontColor,
                                    // fontWeight: 600,
                                    border: dobErrorBorder,
                                    // height:"45px"
                                },
                            }}
                            disabled={userInfo.OrganizationId == null || userInfo.GroupPlanId == null}
                        />

                    </div> */}
            </>
          )}

          {/* SURVEY */}
          <RecommendationSurvey
            survey={survey}
            surveyHandler={surveyHandler}
            calculated={calculated}
            setCalculated={setCalculated}
            userInfo={userInfo}
            results={results}
          />

          {/* DISABLED CALC CLICK */}
          <SaveChangesSnackbar
            displayAlert={displayAlert}
            setDisplayAlert={setDisplayAlert}
            text="You must provide salary and date of birth"
            severity="error"
            backgroundColor="#CD2026"
          />

          {/* CALCULATE */}
          <div style={{ marginTop: '20px', marginBottom: '20px' }}>
            <div className="flex-row" onClick={checkCalculateAllowed}>
              {/* <div style={{display:"block",margin:"auto"}}> */}
              <Button
                onClick={handleCalculateClick}
                fullWidth
                sx={{ height: 50, borderRadius: 100 }}
                variant="contained"
                disabled={
                  userInfo.Salary == 0 ||
                  userInfo.DOB == '' ||
                  !userInfo.DOB ||
                  !userInfo.OrganizationId ||
                  !userInfo.GroupPlanId ||
                  salaryErrorMsg
                }
              >
                {!calculated && !showLoading && 'Calculate'}

                {showLoading && <CircularProgress sx={{ color: 'white' }} />}

                {calculated && !showLoading && (
                  <Zoom in={calculated}>
                    <span style={{ color: '#8BFF62' }}>
                      CALCULATED!{' '}
                      <img
                        style={{
                          marginLeft: '5px',
                          position: 'relative',
                          top: '-2px',
                        }}
                        src={CustomCheckIcon}
                        alt="check icon"
                      />
                    </span>
                  </Zoom>
                )}
              </Button>
              {/* </div> */}
            </div>
            {calculated && !showLoading && (
              <div
                className="flex-row"
                style={{ justifyContent: 'center', marginTop: '10px' }}
              >
                <Typography fontSize="13px" className="recommendationText">
                  <img src={CustomCheckIcon} /> Recommendations below!*
                </Typography>
              </div>
            )}
          </div>
        </div>
      </div>
      <>
        {/* Membership */}
        {results?.membershipPlan && (
          <CalculatorMembership
            results={results}
            recommendations={recommendations}
            selections={selections}
            setSelections={setSelections}
            selectionHandler={selectionHandler}
            selectionRec={selectionRec}
            dummyFontColor={dummyFontColor}
            dummyDisplay={dummyDisplay}
            calculated={calculated}
          />
        )}
      </>
      <>
        {/* STD */}
        {results?.stdPlan && (
          <CalculatorStd
            results={results}
            recommendations={recommendations}
            selections={selections}
            setSelections={setSelections}
            selectionHandler={selectionHandler}
            selectionRec={selectionRec}
            dummyFontColor={dummyFontColor}
            dummyDisplay={dummyDisplay}
            calculated={calculated}
          />
        )}
      </>
      <>
        {/* LTD  */}
        {results?.ltdPlan && (
          <CalculatorLtd
            results={results}
            recommendations={recommendations}
            selections={selections}
            setSelections={setSelections}
            selectionHandler={selectionHandler}
            selectionRec={selectionRec}
            dummyFontColor={dummyFontColor}
            dummyDisplay={dummyDisplay}
            calculated={calculated}
          />
        )}
      </>
      <>
        {/* LADD  */}
        {results?.lifeADDPlan && (
          <CalculatorLadd
            results={results}
            recommendations={recommendations}
            dummyDisplay={dummyDisplay}
            dummyFontColor={dummyFontColor}
            selections={selections}
            setSelections={setSelections}
            survey={survey}
            calculated={calculated}
          />
        )}
      </>
      <>
        {results?.criticalIllnessPlan && (
          <CalculatorCriticalIllness
            results={results}
            recommendations={recommendations}
            dummyDisplay={dummyDisplay}
            dummyFontColor={dummyFontColor}
            selections={selections}
            setSelections={setSelections}
            survey={survey}
            calculated={calculated}
          />
        )}
      </>

      <Divider sx={componentStyles.divider} />
      {!dummyDisplay && (
        <Paper
          elevation={0}
          // sx={{width:"100%",padding:"10px",position:"sticky",bottom:"0",color:"#195ffb",textAlign:"center",fontSize:"20px"}}
          className="sticky-bottom-total"
        >
          {`${UsdFormatterDec.format(runningTotal)} monthly total`}
          <span
            style={{
              color: 'grey',
              fontSize: 8,
              position: 'absolute',
              left: 0,
              right: 0,
              bottom: 3,
            }}
          >
            **Transaction fee of{' '}
            {UsdFormatter.format(results?.transactionFee || 0)} applied, per
            transaction
          </span>
        </Paper>
      )}
      <CardContent>
        <Button
          onClick={goToEnroll}
          className="calculate-btn"
          sx={{
            display: 'block',
            margin: 'auto',
            marginTop: '0px',
            marginBottom: '30px',
            borderRadius: '100px',
            width: '100%',
            fontWeight: 700,
            letterSpacing: '1.3px',
            fontSize: '16px',
            boxShadow: 'none',
          }}
          variant="contained"
          size="large"
          // disabled={{}}
        >
          Register to enroll
        </Button>
        <FormHelperText sx={{ textAlign: 'center' }}>
          *Recommended benefits are suggestions based on the information
          provided. It is your responsibility to elect coverage that best fits
          your needs.
        </FormHelperText>
        <FormHelperText sx={{ textAlign: 'center' }}>
          **A $1.00 per payment banking transaction fee is included when your
          monthly cost is collected.
        </FormHelperText>
      </CardContent>
    </Box>
  );
}
