import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';

//Services
import authService from '../../../services/auth-service';
import registerService from '../../../services/register-service';

//MuiComponents
import { Box, TextField, Button, Typography, Checkbox } from '@mui/material';

//MuiIcons
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

//Our Components
import PasswordStrength from '../password-strength/PasswordStrength';
import DuplicateEmailDrawer from './DuplicateEmailDrawer';

//shared styles
import externalEnrollmentSharedStyles from '../externalEnrollmentSharedStyles';

//component styless
const componentStyles = {
  bodyContainer: {
    margin: '10px auto',
  },
  text: {
    margin: '20px 4px 35px',
  },
  input: {
    maxWidth: '575px',
  },
  rememberDeviceContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  largeBlueButton: {
    maxWidth: '575px',
    height: '50px',
  },
};

function ExternalRegisterSetup(props) {
  const [values, setValues] = useState({
    email: '',
    password: '',
    ageCheck: true,
    firstName: props.member.firstName,
    lastName: props.member.lastName,
    memberId: props.member.id,
    organizationId: props?.organization?.id,
  });
  // const fullName = `${props.member.firstName} ${props.member.lastName}`;

  const history = useHistory();

  //stop loading screen on page load
  useEffect(() => {
    props.setLoading(false);
  }, []);

  //Change handlers
  const handleValueChange = (e) => {
    let inputVal = e.target.value;
    let inputProperty = e.target.getAttribute('data-property');
    setValues({
      ...values,
      [inputProperty]: inputVal,
    });

    if (inputProperty === 'email') {
      checkEmailError(inputVal);
    }
  };

  const passwordKeyPressHandler = (e) => {
    if (e.key === 'Enter' && !disableButton) finishRegistration();
    if (e.key === ' ') e.preventDefault();
  };

  //Password strength
  const [disableButton, setDisableButton] = useState(true);

  const strengthPassed = (e) => {
    setDisableButton(false);
  };
  const strengthNotPassed = (e) => {
    setDisableButton(true);
  };

  const [emailSent, setEmailSent] = useState(false);

  const getCookie = (cookieName) => {
    let name = `${cookieName}=`;
    let cookieArray = document.cookie.split(';');
    let value = '';
    cookieArray.forEach((cookie) => {
      if (cookie.includes(name)) value = cookie.replace(name, '').trim();
    });
    return value;
  };

  const [showLoginButton, setShowLoginButton] = useState(false);

  const backToLogin = () => {
    history.push('/login');
  };

  const finishRegistration = async (e) => {
    //e.preventDefault();
    // console.log(values)
    props.setLoading(true);
    let allowContinue = true;
    await registerService.register(values).catch((error) => {
      console.log('registration error', error.response);
      allowContinue = false;
      if (error.response.status == 409) {
        props.setLoading(false);
        setEmailError('This email is already registered with an account');
        setShowEmailError(true);
        setShowLoginButton(true);
      }
    });
    // console.log(response)
    //Send the confirmation email
    // setEmailSent(true);

    //FOR THE DEMO
    // history.push("/calculator")
    if (allowContinue) {
      continueToEnrollments();
    }
    // continueToDashboard()
  };

  const continueToEnrollments = async (e) => {
    let isAuthenticated = false;
    const credentials = {
      username: values.email,
      password: values.password,
    };
    isAuthenticated = await authService.authenticate(credentials);

    if (isAuthenticated) {
      let domain = getCookie('subdomain');

      window.location.href = `https://${domain}/enrollments`;
    } else {
      console.log('not successful');
    }
  };

  // Email Error Handling
  const [showEmailError, setShowEmailError] = useState(false);
  const [emailError, setEmailError] = useState(null);

  const isValidEmail = (email) => {
    if (!/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(email)) {
      return false;
    } else return true;
  };

  const checkEmailError = (value = values.email) => {
    if (!isValidEmail(value)) {
      setShowEmailError(true);
      setEmailError('Please provide a valid email address');
    } else {
      setShowEmailError(false);
      setEmailError(null);
    }
  };

  //Duplicate Email Drawer/Modal Logic
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    //logic need to find if duplicate email found, set open true
  };
  const handleClose = () => setOpen(false);

  return (
    <>
      <DuplicateEmailDrawer
        open={open}
        handleClose={handleClose}
        handleOpen={handleOpen}
      />

      <Box sx={componentStyles.bodyContainer}>
        {/* Email input */}

        <TextField
          fullWidth
          sx={componentStyles.input}
          type="email"
          label="Email"
          variant="outlined"
          error={showEmailError && emailError}
          helperText={showEmailError && emailError}
          value={values.email}
          onChange={handleValueChange}
          inputProps={{ 'data-property': 'email' }}
        />

        {/* Login Button IF email already exists */}
        {showLoginButton && (
          <Button
            variant="contained"
            fullWidth
            sx={externalEnrollmentSharedStyles.largeBlueButton}
            onClick={backToLogin}
          >
            Take me to login <KeyboardArrowRightIcon />
          </Button>
        )}

        <Typography variant="body2" sx={componentStyles.text}>
          Please create a secure password including the criteria below.
        </Typography>
        {/* 
      Create Password Input */}
        <TextField
          fullWidth
          sx={componentStyles.input}
          label="Password"
          variant="outlined"
          value={values.password}
          autoComplete="off"
          onChange={handleValueChange}
          onKeyPress={passwordKeyPressHandler}
          inputProps={{ 'data-property': 'password' }}
        />

        <PasswordStrength
          passwordValue={values.password}
          strengthPassed={strengthPassed}
          strengthNotPassed={strengthNotPassed}
        />

        {/* Need logic for this featuer */}
        {/* <Box sx={componentStyles.rememberDeviceContainer}>
          <Checkbox />
          <Typography variant="body2">Remember this device</Typography>
        </Box> */}

        <Button
          variant="contained"
          fullWidth
          sx={externalEnrollmentSharedStyles.largeBlueButton}
          disabled={disableButton}
          onClick={finishRegistration}
        >
          Create account
        </Button>
      </Box>
    </>
  );
}

export default ExternalRegisterSetup;
