import React, { cloneElement, useState } from "react";

//Mui components
import {
  Card,
  CardHeader,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";

//Mui icons
import InfoIcon from "@mui/icons-material/Info";
import DownloadIcon from "@mui/icons-material/FileDownload";

export default function ReportCard(props) {
  const { title, subtitle, children, downloadClick, infoTip } = props;

  const [reportName, setReportName] = useState("");
  const [allowDownload, setAllowDownload] = useState(false);
  const [downloadFunction, setDownloadFunction] = useState(false);

  const downloadClickHandler = async () => {
    console.log("download click handler", downloadFunction);
    let result = await downloadFunction();
    if (props.createCsv) props.createCsv(result, reportName);

    console.log("download result", result);
  };

  return (
    <Card
      elevation={0}
      sx={{
        height: "100%",
        borderRadius: "0px",
        backgroundColor: "background.default",
        // position:'relative',
      }}
    >
      <Box
      //   className="flex-row"
        sx={{
          borderColor: "background.border",
          position: "relative",
          padding: "14px 24px",
          height: "70px",
        }}
      >
        <div className="col-12" style={{ position: "relative" }}>
          <Box>
            <Typography
              component={"span"}
              sx={{
                fontFamily: "Roboto",
                color: "text.Gray1",
                lineHeight: "16.75px",
              }}
            >
              {title}
            </Typography>

            <IconButton
              data-html2canvas-ignore
              disabled={!allowDownload}
              onClick={downloadClickHandler}
              sx={{ padding: "0px", margin: "0px 5px" }}
            >
              <DownloadIcon
                sx={{ color: !allowDownload ? "grey" : "primary.main" }}
              />
            </IconButton>
          </Box>

          <Box>
            <Typography
              component={"span"}
              sx={{
                fontFamily: "Roboto",
                color: "text.Gray2",
                lineHeight: "16.75px",
                fontSize: "14px",
              }}
            >
              {subtitle}
            </Typography>
          </Box>

          <div
            data-html2canvas-ignore
            className=""
            style={{ position: "absolute", right: 0, top: 0 }}
          >
          </div>
        </div>

        <Divider
         //  className="col-12 flex-row"
          sx={{
            width: "100%",
            marginBottom: "0px",
            color: "background.border",
            opacity: 0.5,
            bottom: 0,
            left: 0,
            position: "absolute",
          }}
        />
      </Box>
      <div style={{ maxHeight: "216px" }}>
        {cloneElement(children, {
          downloadFunction: downloadFunction,
          setDownloadFunction: setDownloadFunction,
          setAllowDownload: setAllowDownload,
          setReportName: setReportName,
        })}
        {/* {children}  */}
      </div>
    </Card>
  );
}
