import React, { useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router';

//Assets
import defaultLogo from '../../../../assets/images/FullColorOnlyLogo.png';

//Muicons

//MuiComponents
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';

//Services

//Our Components
import { ColorModeContext } from '../../../../ExternalApp';
import InfinityLoader from '../../../UH-loading-animation/InfinityLoader';
import PortalPersonalSummary from './PortalPersonalSummary';
import PortalPaymentSummary from './PortalPaymentSummary';
import PortalBenefitAdminSummary from './PortalBenefitAdminSummary';

//utils
import { capitalizeFirstLetter, formatPhoneNumber } from '../../../utils';

export default function PortalHome(props) {
  const [thisMember, setThisMember] = useState(props.thisMember);
  useEffect(() => setThisMember(props.thisMember), [props.thisMember]);

  const [enrollments, setEnrollments] = useState(props?.enrollments);

  let colorMode = useContext(ColorModeContext);

  const [loading, setLoading] = useState(false);

  const [pageSummaryObj, setPageSummaryObj] = useState({
    thisMember: {},
    thisMemberBenefits: {},
    thisMemberPayment: {},
  });

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  // useEffect(() => {
  //    setLoading(true);
  //    setTimeout(() => {
  //       setLoading(false);
  //    }, 2000);
  //    // return () => {
  //    //    setLoading(false);
  //    // }
  // }, [anchorEl]);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const goTo = (page) => {
    handleClose();
  };

  const swapTheme = () => {
    colorMode.ToggleColorMode();
  };

  // const [collapse, setCollapse] = useState(false);
  const [mostRecentEnrollment, setMostRecentEnrollment] = useState(
    props.mostRecentEnrollment
  );
  useEffect(
    () => setMostRecentEnrollment(props.mostRecentEnrollment),
    [props.mostRecentEnrollment]
  );
  const [mostRecentEnrollmentCommLabels, setMostRecentEnrollmentCommLabels] =
    useState(props.mostRecentEnrollmentCommLabels);
  useEffect(
    () =>
      setMostRecentEnrollmentCommLabels(props.mostRecentEnrollmentCommLabels),
    [props.mostRecentEnrollmentCommLabels]
  );

  const [imgFetchAttempts, setImgFetchAttempts] = useState(0);
  const handleLogoError = (e) => {
    // console.log('logo img error',FallbackLogo)
    setImgFetchAttempts((prev) => prev++);
    if (imgFetchAttempts <= 10) e.target.src = defaultLogo;
  };
  // console.log('mostRecentEnrollment:', mostRecentEnrollmentCommLabels);

  return (
    <>
      {loading && (
        <>
          <InfinityLoader
            style={{
              position: 'absolute',
              zIndex: 5,
              width: '100%',
              height: '100vh',
              backgroundColor: '#00000050',
              backdropFilter: 'blur(10px)',
            }}
          />
          <style>
            {`
                div.lottie-div > svg {
                  height:55px!important;
                  position:absolute;
                  top:50%;
                }
            `}
          </style>
        </>
      )}
      <Box
        className="flex-container"
        flex={1}
        style={{
          flexWrap: 'wrap',
          margin: 'auto',
        }}
        sx={{
          bgcolor: 'background.default',
          color: 'text.primary',
          paddingTop: '64px',
        }}
      >
        <Box
          style={{
            maxWidth: '575px',
            margin: '0 auto',
          }}
        >
          <Box
            className="flex-row"
            sx={{ padding: '18px', border: '1px #E0E0E0 solid' }}
          >
            <Box
              component={'img'}
              alt="logo"
              src={
                thisMember?.organizationId > 0
                  ? `api/image/organization/${thisMember?.organizationId}`
                  : '/api/image/organization'
              }
              style={{
                maxHeight: '86px',
                minHeight: '80px',
                objectFit: 'contain',
              }}
              onError={handleLogoError}
              className="col-3"
            />
            <Typography
              className="col-9"
              variant="subtitle1"
              sx={{ fontWeight: 500, fontSize: '20px' }}
            >
              Welcome back
              {thisMember?.firstName && ', '}
              <Fade in={thisMember?.firstName ? true : false}>
                <Typography
                  variant="subtitle1"
                  component={'div'}
                  sx={{
                    color: 'primary.main',
                    fontWeight: 500,
                    display: 'inline-block',
                    fontSize: '20px',
                  }}
                >
                  {capitalizeFirstLetter(thisMember?.firstName)}{' '}
                  {capitalizeFirstLetter(thisMember?.lastName)}
                </Typography>
              </Fade>
              <Typography
                variant="subtitle1"
                sx={{
                  fontSize: '16px !important',
                  fontFamily: 'Archivo',
                  lineHeight: '28px',
                  color: 'text.secondary',
                }}
              >
                {thisMember?.organizationName}
              </Typography>
            </Typography>
          </Box>

          <Box
            className="col-12"
            style={{
              display: 'flex',
              justifyContent: 'center',
              flex: 1,
              flexWrap: 'wrap',
              backgroundColor: '#fafafa',
              gap: '6px',
            }}
          >
            <Box sx={{ width: '100%', padding: '0px 14px', paddingTop: '6px' }}>
              <Typography variant="h6">Overview of your plan</Typography>
            </Box>
            <Box
              sx={{
                width: '100%',
                padding: '16px',
                backgroundColor: 'white',
                border: '1px #E0E0E0 solid',
              }}
            >
              <PortalPaymentSummary
                payment={props.payment}
                enrollment={mostRecentEnrollment}
              />
            </Box>

            <Box sx={{ width: '100%', padding: '0px 14px' }}>
              <Typography variant="h6">
                Benefit Administrator Information
              </Typography>
            </Box>

            <Box
              sx={{
                width: '100%',
                padding: '16px',
                backgroundColor: 'white',
                border: '1px #E0E0E0 solid',
              }}
            >
              <PortalBenefitAdminSummary
                payment={props.payment}
                enrollment={mostRecentEnrollment}
                commLabels={mostRecentEnrollmentCommLabels}
              />
            </Box>

            <Box sx={{ width: '100%', padding: '0px 14px' }}>
              <Typography variant="h6">Your information</Typography>
            </Box>

            <Box
              sx={{
                width: '100%',
                padding: '16px',
                backgroundColor: 'white',
                border: '1px #E0E0E0 solid',
              }}
            >
              <PortalPersonalSummary
                thisMember={thisMember}
                thisMemberPhone={props.thisMemberPhone}
                getThisMember={props.getThisMember}
              />
            </Box>
          </Box>
        </Box>
      </Box>

      {/* {!isAuthenticated && history.push('/login')} */}
    </>
  );
}
