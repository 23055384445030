import React, { useState, useEffect } from "react"
import { format, set } from "date-fns"
import { RadialBarChart, RadialBar, PolarAngleAxis } from "recharts"

//Mui components
import { useTheme } from "@mui/material"
import Box from "@mui/material/Box"

export default function RadialBarChartTemplate(props) {
  const componentStyles = {
    componentBody: {
      height: "200px",
      width: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      margin: "0 auto",
    },
    progressTitle: { fontSize: "16px", fontFamily: "Archivo" },
    progressLabel: {
      fontSize: "16px",
      fontFamily: "Archivo",
      lineHeight: "15px",
    },
    totalCount: {
      fontSize: "24px",
      fontWeight: "400px",
      fontFamily: "Poppins",
      color: "rgba(89, 37, 220, 1)",
    },
  }

  //Updated to abbreviate with M for millions or K for thousands
  function formatUsdToKM(amount) {
    if (amount >= 1000000) {
      return "$" + (amount / 1000000).toFixed(0) + "M"
    } else if (amount >= 1000) {
      return "$" + (amount / 1000).toFixed(0) + "K"
    } else {
      // Assuming props is defined elsewhere
      return props?.UsdFormatter.format(amount)
    }
  }

  const theme = useTheme()
  const circleSize = 300
  return (
    <Box sx={componentStyles.componentBody}>
      <RadialBarChart
        width={circleSize}
        height={200}
        cx={circleSize / 2}
        cy={circleSize / 2}
        innerRadius={circleSize / 2.8}
        outerRadius={circleSize / 1.8}
        barSize={25}
        data={[props?.data || 0]}
        startAngle={180}
        endAngle={0}
      >
        <PolarAngleAxis
          type="number"
          domain={[
            0,
            props?.data?.actual > props?.data?.goal
              ? props?.data?.actual
              : props?.data?.goal,
          ]}
          angleAxisId={0}
          dataKey="goal"
          cornerRadius={circleSize / 2}
          tick={false}
        />
        <RadialBar
          // minAngle={15}
          background={{
            fill:
              theme?.palette?.mode == "light"
                ? theme?.palette?.background?.background
                : theme?.palette?.background?.background,
          }}
          // cornerRadius={circleSize / 2}
          clockWise
          dataKey="actual"
          key={`cell-${props?.data.actual}`}
          fill={theme?.palette?.primary?.main}
        />
        <text
          x={circleSize / 2}
          y={circleSize / 2.7}
          style={componentStyles.totalCount}
          fill={theme?.palette?.primary?.enrollmentSubmittedChip || "#5925DC"}
          textAnchor="middle"
          dominantBaseline="middle"
          className="progress-label"
        >
          {props?.data?.name === "Total Enrollments"
            ? props?.formatNumWithCommas(props?.data?.actual) || 0
            : props?.UsdFormatter.format(props?.data?.actual || 0)}
        </text>
        <text
          x={circleSize / 2}
          y={circleSize / 2.1}
          textAnchor="middle"
          dominantBaseline="middle"
          className="progress-label"
          fill={theme?.palette?.text?.primary || "#49454F"}
          style={componentStyles.progressTitle}
        >
          {props?.data?.name === "Total Enrollments"
            ? "Submitted Enrollments"
            : "Total Premium"}
        </text>
        <text
          x={circleSize / 2}
          y={circleSize / 1.9}
          textAnchor="middle"
          dominantBaseline="middle"
          className="progress-label"
          fill={"#666666"}
          style={{
            fontSize: "10px",
            fontFamily: "Archivo",
            fontWeight: 400,
            letterSpacing: "1px",
          }}
        >
          {props?.data?.name === "Total Premium" ? "NOT INCLUDING FEES" : ""}
        </text>
        <svg height={50} width={50} x={18} y={circleSize / 1.85 - 25 / 2}>
          <ellipse
            cx={25}
            cy={0}
            fill={
              props?.data?.actual > 0
                ? theme?.palette?.primary?.main
                : theme?.palette?.background?.background
            }
            rx={25 / 2}
            ry={25 / 3}
          />
        </svg>
        <text
          x={circleSize / 7}
          y={circleSize / 1.85 + 5}
          textAnchor="middle"
          dominantBaseline="middle"
          className="progress-label"
          fill={theme?.palette?.text?.primary || "#49454F"}
          style={componentStyles.progressLabel}
        >
          0
        </text>

        <svg
          height={50}
          width={50}
          x={circleSize / 1.3 + 1}
          y={circleSize / 1.85 - 25 / 2}
        >
          <ellipse
            cx={25}
            cy={0}
            fill={
              props?.data?.actual > props?.data?.goal
                ? theme?.palette?.primary?.main
                : theme?.palette?.background?.background
            }
            rx={25 / 2}
            ry={25 / 3}
          />
        </svg>
        <text
          x={circleSize / 1.17}
          y={circleSize / 1.85 + 5}
          textAnchor="middle"
          dominantBaseline="middle"
          className="progress-label"
          fill={theme?.palette?.text?.primary || "#49454F"}
          style={componentStyles.progressLabel}
        >
          {props?.data?.name === "Total Enrollments"
            ? props?.formatNumWithCommas(props?.data?.goal) || 0
            : formatUsdToKM(props?.data?.goal || 0)}
        </text>
      </RadialBarChart>
    </Box>
  )
}
