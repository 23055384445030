import React, { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";

//Services
import userService from "../../../../services/user-service";
import permissionService from '../../../../services/permission-service'

// Assets

//MuiIcons

//MuiComponents
import Button from "@mui/material/Button";
import { InputLabel, TextField } from "@mui/material";
import { Select } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Avatar from '@mui/material/Avatar';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';


import { AgentColorModeContext } from "../../../../App";


export default function SettingsPersonalization (props) {
   const colorMode = useContext(AgentColorModeContext);
   
   return (
      <>
         {/* ACTUAL CONTENT */}
   
         <div className="container" style={{ height: 'calc(100vh - 222px)', backgroundColor: "FFFFFF", margin: 20, fontFamily: 'Archivo' }}>
            <div className="row" style={{ height: 'auto' }}>
            <div className={`col-md-3`} style={{alignSelf:'center'}}>
                  <h6>Color Scheme:</h6>

               </div>
               <div className={`col-md-9`}>
                  
                  <Button onClick={()=>colorMode.ToggleColorMode()} >
                     Set to {colorMode.colorMode == 'light' ? 'Dark' : 'Light'} Mode
                  </Button>
                  <span style={{fontSize:'13px', fontWeight: '600'}}>*Beta</span>

               </div>
            </div>
            {/* <Snackbar open={message.messageDisplay} autoHideDuration={message.autoHideDuration} onClose={handleMessageClose} sx={{ opacity: .8 }}>
               <MuiAlert
                  elevation={6} variant="filled"
                  // onClose={handleClose} 
                  severity={message.severity} sx={{ width: '100%', backgroundColor: message.backgroundColor }}
               >
                  {message.message}
               </MuiAlert>
            </Snackbar> */}
         </div>
      </>
   )
}

