import Settings from "../settings";
import { baseService } from "./baseService";

class internalQuestionService extends baseService {
    getAnswers() {
        const url = Settings.API_URL + "question/answer";
        return this.get(url);
    }
    getAnswersForMember(memberId) {
        const url = Settings.API_URL + "question/" + memberId + "/answer";
        return this.get(url);
    }
    saveAnswersForMember(memberId, answers) {
        const url = Settings.API_URL + "question/" + memberId + "/answer";
        return this.post(url, answers);
    }
    saveAnswers(answers) {
        const url = Settings.API_URL + "question/answer";
        return this.post(url, answers);
    }

    // 6 - Do you have cancer?
    // 7 - Do you have AIDS?
    // 8 - Skin Cancer?
    // 9 - Internal Cancer?
    // 10 - All cancer
}

const questionService = new internalQuestionService();

export default questionService;