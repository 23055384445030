import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

//Assets

//Services
import enrollmentService from '../../../../services/enrollment-service';
import optionService from '../../../../services/optionService';

//Mui Icons

//MuiComponents
import { Box, Typography } from '@mui/material';

//Our components
import PrimaryBeneficiaries from './PrimaryBeneficiaries';
import { ContingentBeneficiaries } from './ContingentBeneficiaries';
import AgentNavigation from '../../../app-internal/Member/member-profile/MemberTabs/agent-enroll-tab/AgentNavigation';
import ExternalEnrollmentHeader from '../ExternalEnrollmentHeader';

//utils

//Our global styles
import externalEnrollmentSharedStyles from '../../externalEnrollmentSharedStyles';

export default function EnrollmentBeneficiaries(props) {
  let history = useHistory();
  let percentFormatter = new Intl.NumberFormat('en-US', {
    style: 'percent',
    maximumFractionDigits: 2,
  });

  //GET initial bennies every time component mounts
  useEffect(() => {
    props?.getInitialBeneficiaries();
  }, []);

  const [relationshipTypes, setRelationshipTypes] = useState();
  const [relationshipTypeListForEdit, setRelationshipTypeListForEdit] =
    useState(null);

  const getRelationshipTypes = async () => {
    let _relationshipTypes = await optionService.getRelationshipTypes();
    // console.log(_relationshipTypes)
    //if a potential benny, primary benny, or contingent benny is already a spouse, do not give the option of adding another pouse
    let potentialSpouseExists = props?.potentialBeneficiaries?.find((benny) => {
      return benny.relationshipTypeId === 1;
    });
    let spouseExistsAsPrimary = props?.primaryBeneficiaries?.find((benny) => {
      return benny.relationshipTypeId === 1;
    });
    let spouseExistsAsContingent = props?.contingentBeneficiaries?.find(
      (benny) => {
        return benny.relationshipTypeId === 1;
      }
    );
    if (
      potentialSpouseExists ||
      spouseExistsAsPrimary ||
      spouseExistsAsContingent
    ) {
      let filteredRelationshipTypes = _relationshipTypes.filter(
        (rt) => rt.id !== 1
      );

      setRelationshipTypes(filteredRelationshipTypes);
    } else setRelationshipTypes(_relationshipTypes);

    //set full relationship type list for edit beneficiary
    setRelationshipTypeListForEdit(_relationshipTypes);
  };

  useEffect(() => {
    // props.getInitialBeneficiaries()
    getRelationshipTypes();
    // DEBUGCLICK()
    // checkHeight()
  }, [
    props?.primaryBeneficiaries?.length,
    props?.contingentBeneficiaries?.length,
  ]);

  const [disableSavePrimary, setDisableSavePrimary] = useState(true);
  let primaryAddArray = [
    props.primaryBennyAdd.FirstName,
    props.primaryBennyAdd.LastName,
    props.primaryBennyAdd.RelationshipTypeId,
  ];

  const handleInputChange = (e) => {
    let val = e.target.value;
    let key = e.target.getAttribute('data-update');
    let valWithoutNumbers = val.replace(/[0-9]/g, ''); // Remove any numbers

    //ADD the new benny info to the primaryBennyAdd object IF the user is not editing an existing benny
    //first name primary add
    if (key === 'firstNamePrimary' && !editBenny.id) {
      props.setPrimaryBennyAdd({
        ...props.primaryBennyAdd,
        FirstName: valWithoutNumbers,
      });
    } else if (
      (key === 'firstNamePrimary' || key === 'firstNameContingent') &&
      editBenny.id
    ) {
      //first name primary or cont. edit
      setEditBenny({
        ...editBenny,
        firstName: valWithoutNumbers,
      });
    }
    //last name primary add
    if (key === 'lastNamePrimary' && !editBenny.id) {
      props.setPrimaryBennyAdd({
        ...props.primaryBennyAdd,
        LastName: valWithoutNumbers,
      });
    } else if (
      (key === 'lastNamePrimary' || key === 'lastNameContingent') &&
      editBenny.id
    ) {
      //last name primary or cont. edit
      setEditBenny({
        ...editBenny,
        lastName: valWithoutNumbers,
      });
    }

    // first name contingent add
    if (key === 'firstNameContingent' && !editBenny.id) {
      props.setContingentBennyAdd({
        ...props.contingentBennyAdd,
        FirstName: valWithoutNumbers,
      });
    }
    //last name contingent add
    if (key === 'lastNameContingent' && !editBenny.id) {
      props.setContingentBennyAdd({
        ...props.contingentBennyAdd,
        LastName: valWithoutNumbers,
      });
    }
  };

  useEffect(() => {
    if (props.primaryBennyAdd) {
      let enableSave =
        props.primaryBennyAdd.FirstName?.length > 0 &&
        props.primaryBennyAdd.LastName?.length > 0 &&
        props.primaryBennyAdd.RelationshipTypeId > 0;
      setDisableSavePrimary(!enableSave);
    }
  }, [...primaryAddArray]);

  const [disableSaveContingent, setDisableSaveContingent] = useState(true);
  let contingentAddArray = [
    props.contingentBennyAdd.FirstName,
    props.contingentBennyAdd.LastName,
    props.contingentBennyAdd.RelationshipTypeId,
  ];
  useEffect(() => {
    if (props.contingentBennyAdd) {
      let enableSave =
        props.contingentBennyAdd.FirstName?.length > 0 &&
        props.contingentBennyAdd.LastName?.length > 0 &&
        props.contingentBennyAdd.RelationshipTypeId > 0;
      setDisableSaveContingent(!enableSave);
    }
  }, [...contingentAddArray]);

  useEffect(() => {
    if (
      (!disableSaveContingent && props.contingentBennyAdd) ||
      (!disableSavePrimary && props.primaryBennyAdd)
    ) {
      props.setAddedBennyNotSaved(true);
    } else {
      props.setAddedBennyNotSaved(false);
    }
  }, [disableSavePrimary, disableSaveContingent]);

  //define total errors here so it can be used in next validation
  const [primaryTotalError, setPrimaryTotalError] = useState(false);
  const [contingentTotalError, setContingentTotalError] = useState(false);

  const [addedBenny, setAddedBenny] = useState();

  const [autoListBuilt, setAutoListBuilt] = useState(false);

  const [storedAllocations, setStoredAllocations] = useState([]);
  //set from storedAllocations in db
  useEffect(() => {
    if (props?.storedAllocations) {
      setStoredAllocations(props.storedAllocations);
    } else {
      setStoredAllocations([]);
    }
  }, [props?.storedAllocations]);

  useEffect(() => {
    if (storedAllocations.length > 0) {
      // console.log('stored alocations before split', storedAllocations)
      // let _primaryCheckboxes = [...props.primaryCheckboxes];
      // let _contingentCheckboxes = [...props.contingentCheckboxes];
      // for (let allocation of storedAllocations) {
      //   if (!allocation.isDeleted) {
      //     if (allocation.isPrimary) {
      //       let bennyCheckInList = _primaryCheckboxes.find(
      //         (primaryCheck) => primaryCheck.id == allocation.id
      //       );

      //       if (bennyCheckInList && allocation?.amount) {
      //         bennyCheckInList.amount = allocation.amount;
      //         bennyCheckInList.checked = allocation.amount > 0;
      //       }
      //     } else {
      //       //find corresponding benny
      //       let bennyCheckInList = _contingentCheckboxes.find(
      //         (contingentCheck) => contingentCheck.id == allocation.id
      //       );
      //       if (bennyCheckInList && allocation?.amount) {
      //         bennyCheckInList.amount = allocation.amount;
      //         bennyCheckInList.checked = allocation.amount > 0;
      //       }
      //     }
      //   }
      // }
      console.log(
        'stored allocations',
        storedAllocations,
        '\n\n PRIMARY CHECKBOXES',
        props.primaryCheckboxes,
        '\n\n PRIMARY bennies',
        props.primaryBeneficiaries,
        '\n\n CONT CHECKBOXES',
        props.contingentCheckboxes,
        '\n\n Contingent Bennies',
        props.contingentBeneficiaries
      );
      // console.log('primary checkboxes after allocations', _primaryCheckboxes)
      // console.log('contingent checkboxes after allocations', _contingentCheckboxes)
      // props.setPrimaryCheckboxes(_primaryCheckboxes);
      // props.setContingentCheckboxes(_contingentCheckboxes);
    }
  }, [storedAllocations, props.beneficiariesInDB?.length]);

  const debugClick = () => {
    console.log('bennies in db', props.beneficiariesInDB);

    console.log('potential bennies', props.potentialBeneficiaries);

    console.log('results', props.results);

    console.log('primaryBennies', props.primaryBeneficiaries);

    console.log('primary checks', props.primaryCheckboxes);

    console.log('contingent checks', props.contingentCheckboxes);
  };

  //Edit and Delete Beneficiary -

  const [editBenny, setEditBenny] = useState({ id: null });

  useEffect(() => {
    setEditBenny({ id: null });
  }, []);

  //will need to update these open and cancel  functions to be used to edit both primaries and contingents
  const openEditBeneficiary = (e, beneficiary) => {
    //set the editBenny to the id of the benny editing
    console.log('editBenny BEFORE', editBenny);
    setEditBenny(beneficiary);
    console.log('editBenny AFTER', editBenny);
    console.log('primary checkboxes', props.primaryCheckboxes);
    console.log('open edit benny form', beneficiary);
  };

  const cancelEditBenny = () => {
    console.log('edit benny before cancel click', editBenny);
    setEditBenny({ id: null });
    props.setPrimaryBennyEdit(false);
    props.setContingentBennyEdit(false);
  };

  //Update an exisiting beneficiary in the list on the benny page
  const handleEditBeneficiary = async (beneficiary, val) => {
    let valueToUpdate = val;
    console.log('edit beneficiary', beneficiary);
    console.log('value to update', valueToUpdate);
    let _primaryBennyUpdated = {
      firstName: beneficiary.firstName,
      middleInitial: beneficiary?.middleInitial || '',
      lastName: beneficiary.lastName,
      dob: beneficiary?.dob,
      memberId: beneficiary.memberId,
      id: beneficiary?.id,
      isPrimary: true,
      relationshipTypeId: beneficiary?.relationshipTypeId,
      isDeleted: beneficiary?.isDeleted || false,
      lastModifiedBy: beneficiary?.lastModifiedBy,
      isPrimary: beneficiary?.isPrimary || true,
      // amount: beneficiary?.amount,
    };
    console.log('built primary benny to update', _primaryBennyUpdated);

    let contingentBennyUpdated = {
      firstName: beneficiary.firstName,
      middleInitial: beneficiary?.middleInitial || '',
      lastName: beneficiary.lastName,
      dob: beneficiary?.dob,
      memberId: beneficiary.memberId,
      id: beneficiary?.id,
      isPrimary: false,
      relationshipTypeId: beneficiary?.relationshipTypeId,
      isDeleted: beneficiary?.isDeleted || false,
      lastModifiedBy: beneficiary?.lastModifiedBy,
      isPrimary: beneficiary?.isPrimary || true,
      // amount: beneficiary?.amount,
    };

    console.log('primaryBennnyList BEFORE update', props.primaryBeneficiaries);

    //update local state for BENEFICIARY for either primary or contingent beneficiary display
    //check if primary or contingent exist with matching id or dependentIds and update individual beneficiary with new values
    if (valueToUpdate === 'isPrimary') {
      console.log('updating primary...');
      props.setPrimaryBeneficiaries((prevBeneficiaries) =>
        prevBeneficiaries?.map((prevBeneficiary) =>
          (prevBeneficiary.id && prevBeneficiary.id === beneficiary.id) ||
          (prevBeneficiary.dependentId &&
            prevBeneficiary.dependentId === beneficiary.dependentId)
            ? { ...prevBeneficiary, ...beneficiary }
            : prevBeneficiary
        )
      );
    } else if (valueToUpdate === 'isContingent') {
      console.log('updating contingent...');
      props.setContingentBeneficiaries((prevBeneficiaries) =>
        prevBeneficiaries?.map((prevBeneficiary) =>
          (prevBeneficiary.id && prevBeneficiary.id === beneficiary.id) ||
          (prevBeneficiary.dependentId &&
            prevBeneficiary.dependentId === beneficiary.dependentId)
            ? { ...prevBeneficiary, ...beneficiary }
            : prevBeneficiary
        )
      );
    }

    //Check if spouse info changed and  update for spouse table in DB
    //next click will save spouse OR update spouse back in DB w/ saveSpouseInfo() in wrapper component
    if (beneficiary.relationshipTypeId === 1) {
      //set spouse info here and save on next click in agent enrollment wrapper and single enrollment wrapper separately
      console.log('beneficiary on submit', beneficiary);
      props.setSpouse({
        ...props.spouse,
        firstName: beneficiary?.firstName,
        lastName: beneficiary?.lastName,
        dateOfBirth: beneficiary?.dob,
      });
    }

    //UPDATE BENEFICIARY IN DB
    // check if agent enroller AND if benny is in db yet, and update accordingly for agent or member side
    if (props.agentEnroller && _primaryBennyUpdated?.id) {
      try {
        await enrollmentService.updateBeneficiaryForMember(
          beneficiary.memberId,
          _primaryBennyUpdated
        );
        console.log('updating benny for agent');
      } catch (error) {
        console.log('error updating _primaryBennyUpdated by agent', error);
      }
    } else {
      try {
        await enrollmentService.updateBeneficiary(_primaryBennyUpdated);
        console.log('updating benny');
      } catch (error) {
        console.log('error updating beneficiary', error);
      }
    }

    setEditBenny({ id: null });
  };

  const handleMobileDobChange = (e, isPrimary) => {
    console.log('benny dob change', e);
    let value = e; //format = date object
    if (isPrimary) {
      props.setPrimaryBennyAdd({
        ...props.primaryBennyAdd,
        dob: value,
      });
    } else {
      props.setContingentBennyAdd({
        ...props.contingentBennyAdd,
        dob: value,
      });
    }
    //update spouse dob if spouse is being added as primary or contingent
    if (
      (props?.primaryBennyAdd &&
        props?.primaryBennyAdd?.relationshipTypeId === 1) ||
      (props?.contingentBennyAdd &&
        props?.contingentBennyAdd?.relationshipTypeId === 1)
    ) {
      props.setSpouse({
        ...props.spouse,
        dateOfBirth: value,
      });
    }
  };

  //THESE TWO USE EFFECTS HANDLE toggling checkboxes between primary and contingents

  //PRIMARY CHECKBOX CHANGES
  useEffect(() => {
    if (props?.contingentCheckboxes && props?.contingentBeneficiaries) {
      let _contingentCheckboxes = [...props?.contingentCheckboxes];
      let _contingentBeneficiaries = [...props?.contingentBeneficiaries];
      // console.log('start of contCheckboxes', _contingentCheckboxes,)
      // console.log('start of contBennies', _contingentBeneficiaries)
      // console.log('primary bennies in useEffect', props?.primaryBeneficiaries)
      // console.log('primary checkboxes in useEffect', props?.primaryCheckboxes)
      for (let primaryCheck of props?.primaryCheckboxes) {
        //if checked in primary list
        if (primaryCheck.checked) {
          // check all in primary array against all in contingent array
          if (primaryCheck.id) {
            //this section is good!
            _contingentCheckboxes = _contingentCheckboxes.filter(
              (contingentCheck) => {
                // console.log('checking IDDDDDDD',contingentCheck.id !== primaryCheck.id, contingentCheck.id, primaryCheck.id)
                return contingentCheck.id != primaryCheck.id;
              }
            );
            // console.log('\n\ncontingents after filtering from db', _contingentCheckboxes)
            _contingentBeneficiaries = _contingentBeneficiaries.filter(
              (contingentBeneficiary) =>
                contingentBeneficiary.id != primaryCheck.id
            );
          } else if (primaryCheck.dependentId) {
            _contingentCheckboxes = _contingentCheckboxes.filter(
              (contingentCheck) =>
                contingentCheck.dependentId != primaryCheck.dependentId
            );

            _contingentBeneficiaries = _contingentBeneficiaries.filter(
              (contingentBeneficiary) =>
                contingentBeneficiary.dependentId != primaryCheck.dependentId
            );
          }
        } else if (!primaryCheck.checked) {
          //I think this is the issue area
          //else if the primary benny is not checked
          let _primaryBenny;
          if (primaryCheck.id) {
            _primaryBenny = props?.primaryBeneficiaries.find(
              (primaryBenny) => primaryBenny.id == primaryCheck.id
            );
          } else if (primaryCheck.dependentId) {
            _primaryBenny = props?.primaryBeneficiaries.find(
              (primaryBenny) =>
                primaryBenny.dependentId == primaryCheck.dependentId
            );
          }
          //Push it back into the contingents IF id doesn't exist in contingents
          if (
            !_contingentCheckboxes.find((contingentCheck) => {
              let _tempCheck = { ...contingentCheck };
              delete _tempCheck.amount;
              let _tempPrimaryCheck = { ...primaryCheck };
              delete _tempPrimaryCheck.amount;
              // console.log('temp check',_tempCheck, JSON.stringify(_tempCheck) == JSON.stringify(_tempPrimaryCheck))
              return (
                JSON.stringify(_tempCheck) ==
                  JSON.stringify(_tempPrimaryCheck) &&
                (contingentCheck.id == primaryCheck.id ||
                  contingentCheck?.dependentId == primaryCheck?.dependentId)
              );
            })
          ) {
            _contingentCheckboxes.push({ ...primaryCheck });
          }

          if (
            !_contingentBeneficiaries.find((contingentBenny) => {
              return (
                JSON.stringify(contingentBenny) ==
                  JSON.stringify(_primaryBenny) &&
                (contingentBenny.id == primaryCheck.id ||
                  contingentBenny?.dependentId == primaryCheck?.dependentId)
              );
            })
          ) {
            _contingentBeneficiaries.push({ ..._primaryBenny });
          }
          //  _contingentCheckboxes = {..._contingentCheckboxes,primaryCheck}
        }
      }
      // console.log('checking contingents and setting in useEffect', _contingentCheckboxes)
      // console.log('checking cont bennies in useEffect', _contingentBeneficiaries)
      props?.setContingentCheckboxes(_contingentCheckboxes);
      props?.setContingentBeneficiaries(_contingentBeneficiaries);
    }
  }, [props?.primaryCheckboxes, props?.primaryCheckboxes?.length]);

  //track changes made to contingent checkboxes in state and only run below useEffect when this piece of state updates
  const [contingentCheckboxesChanged, setContingentCheckboxesChanged] =
    useState(false);

  const manageContingentCheckboxChanges = () => {
    setContingentCheckboxesChanged(!contingentCheckboxesChanged);
  };

  //CONTINGENT CHECKBOX CHANGES
  //update primary checkboxes and bennies if changes in contingent checkboxes - bool value contingentCheckboxesChanged in dependency array because keeping contingentCheckboxes in dep array caues infinte loop with above useEFfect
  useEffect(() => {
    if (props?.primaryCheckboxes && props?.primaryBeneficiaries) {
      let _primaryCheckboxes = [...props?.primaryCheckboxes];
      let _primaryBeneficiaries = [...props?.primaryBeneficiaries];
      // console.log('start of primCheckboxes', _primaryCheckboxes,)
      // console.log('start of primBennies', _primaryBeneficiaries)
      // console.log('cont bennies in useEffect', props?.contingentBeneficiaries)
      // console.log('contingent checkboxes in useEffect', props?.contingentCheckboxes)
      for (let contingentCheck of props?.contingentCheckboxes) {
        //if checked in contingent list
        if (contingentCheck.checked) {
          // check all in contingent array against all in primary array
          if (contingentCheck.id) {
            //this section is good!
            _primaryCheckboxes = _primaryCheckboxes.filter((primaryCheck) => {
              return primaryCheck.id != contingentCheck.id;
            });
            // console.log('\n\nprimaries after filtering from db', _primaryCheckboxes)
            _primaryBeneficiaries = _primaryBeneficiaries.filter(
              (primaryBenefeciary) =>
                primaryBenefeciary.id != contingentCheck.id
            );
          } else if (contingentCheck.dependentId) {
            _primaryCheckboxes = _primaryCheckboxes.filter(
              (primaryCheck) =>
                primaryCheck.dependentId != contingentCheck.dependentId
            );

            _primaryBeneficiaries = _primaryBeneficiaries.filter(
              (primaryBenefeciary) =>
                primaryBenefeciary.dependentId != contingentCheck.dependentId
            );
          }
        } else if (!contingentCheck.checked) {
          //I think this is the issue area
          //else if the contingent benny is not checked
          let _contingentBenny;
          if (contingentCheck.id) {
            _contingentBenny = props?.contingentBeneficiaries.find(
              (contingentBenny) => contingentBenny.id == contingentCheck.id
            );
          } else if (contingentCheck.dependentId) {
            _contingentBenny = props?.contingentBeneficiaries.find(
              (contingentBenny) =>
                contingentBenny.dependentId == contingentCheck.dependentId
            );
          }
          //Push it back into the primary IF id doesn't exist in primary
          if (
            !_primaryCheckboxes.find((primaryCheck) => {
              let _tempCheck = { ...primaryCheck };
              delete _tempCheck.amount;
              let _tempContingentCheck = { ...contingentCheck };
              delete _tempContingentCheck.amount;
              // console.log('temp check',_tempCheck, JSON.stringify(_tempCheck) == JSON.stringify(_tempCont))
              return (
                JSON.stringify(_tempCheck) ==
                  JSON.stringify(_tempContingentCheck) &&
                (primaryCheck.id == contingentCheck.id ||
                  primaryCheck?.dependentId == contingentCheck?.dependentId)
              );
            })
          ) {
            _primaryCheckboxes.push({ ...contingentCheck });
          }

          if (
            !_primaryBeneficiaries.find((primaryBenny) => {
              return (
                JSON.stringify(primaryBenny) ==
                  JSON.stringify(_contingentBenny) &&
                (primaryBenny.id == contingentCheck.id ||
                  primaryBenny?.dependentId == contingentCheck?.dependentId)
              );
            })
          ) {
            _primaryBeneficiaries.push({ ..._contingentBenny });
          }
          //  _primaryCheckboxes = {..._primaryCheckboxes,primaryCheck}
        }
      }
      props?.setPrimaryCheckboxes(_primaryCheckboxes);
      props?.setPrimaryBeneficiaries(_primaryBeneficiaries);
    }
  }, [contingentCheckboxesChanged]);

  return (
    <Box
      sx={externalEnrollmentSharedStyles.outerComponentContainerWithPossibleStickyProgress(
        props?.agentEnroller
      )}
    >
      <Box
        sx={externalEnrollmentSharedStyles.componentContainerWithPossibleStickyProgress(
          props?.agentEnroller
        )}
        id="enrollment-beneficiaries"
      >
        {/* Header */}
        {!props.agentEnroller && (
          <ExternalEnrollmentHeader
            fullMemberObj={props.thisMember}
            agentEnroller={props.agentEnroller}
            selectedEnrollment={props.results}
            basicHeader={true}
          />
        )}

        {props.agentEnroller && (
          <>
            <Box>
              <Typography
                variant="h5"
                sx={externalEnrollmentSharedStyles.agentEnrollTitleText}
              >
                Beneficiaries
              </Typography>
            </Box>

            <Box
              sx={externalEnrollmentSharedStyles.agentEnrollSubtitleContainer}
            >
              <Typography
                variant="body2"
                sx={externalEnrollmentSharedStyles.agentEnrollSubtitleText}
              >
                Input primary and contingent beneficiaries for the member.
              </Typography>
            </Box>
          </>
        )}
        <Box
          sx={externalEnrollmentSharedStyles.mainBodyContainer(
            props?.agentEnroller
          )}
        >
          {/* Primary Benny Section */}
          <PrimaryBeneficiaries
            primaryBeneficiaries={props.primaryBeneficiaries}
            setPrimaryBeneficiaries={props.setPrimaryBeneficiaries}
            primaryCheckboxes={props.primaryCheckboxes}
            setPrimaryCheckboxes={props.setPrimaryCheckboxes}
            primaryBennyAdd={props.primaryBennyAdd}
            setPrimaryBennyAdd={props.setPrimaryBennyAdd}
            contingentCheckboxes={props.contingentCheckboxes}
            autoListBuilt={autoListBuilt}
            relationshipTypes={relationshipTypes}
            relationshipTypeListForEdit={relationshipTypeListForEdit}
            primaryTotalError={primaryTotalError}
            setPrimaryTotalError={setPrimaryTotalError}
            editBenny={editBenny}
            setEditBenny={setEditBenny}
            cancelEditBenny={cancelEditBenny}
            openEditBeneficiary={openEditBeneficiary}
            handleEditBeneficiary={handleEditBeneficiary}
            handleInputChange={handleInputChange}
            handleMobileDobChange={handleMobileDobChange}
            disableSavePrimary={disableSavePrimary}
            setDisableSavePrimary={setDisableSavePrimary}
            addedBenny={addedBenny}
            setAddedBenny={setAddedBenny}
            setSpouse={props.setSpouse}
            // handleAddPrimaryBeneficiary={handleAddPrimaryBeneficiary}
          />

          {/* Contingent Benny Section */}

          <ContingentBeneficiaries
            contingentBeneficiaries={props.contingentBeneficiaries}
            setContingentBeneficiaries={props.setContingentBeneficiaries}
            contingentCheckboxes={props.contingentCheckboxes}
            setContingentCheckboxes={props.setContingentCheckboxes}
            contingentBennyAdd={props.contingentBennyAdd}
            setContingentBennyAdd={props.setContingentBennyAdd}
            primaryCheckboxes={props.primaryCheckboxes}
            storedAllocations={storedAllocations}
            relationshipTypeListForEdit={relationshipTypeListForEdit}
            relationshipTypes={relationshipTypes}
            contingentTotalError={contingentTotalError}
            setContingentTotalError={setContingentTotalError}
            manageContingentCheckboxChanges={manageContingentCheckboxChanges}
            autoListBuilt={autoListBuilt}
            handleInputChange={handleInputChange}
            handleMobileDobChange={handleMobileDobChange}
            disableSaveContingent={disableSaveContingent}
            setDisableSaveContingent={setDisableSaveContingent}

            // handleAddContingentBeneficiary={handleAddContingentBeneficiary}
          />
        </Box>
        {props.agentEnroller && (
          <AgentNavigation
            nextLocation={'personalInfo'}
            allowBack={true}
            lastLocation={'plans'}
            nextClickHandler={props.nextClickHandler}
            agentEnrollmentPageState={props.agentEnrollmentPageState}
            setAgentEnrollmentPageState={props.setAgentEnrollmentPageState}
            primaryBeneficiaries={props.primaryBeneficiaries}
            addedBenny={addedBenny}
            primaryTotalError={primaryTotalError}
            contingentTotalError={contingentTotalError}
          />
        )}
      </Box>
    </Box>
  );
}
