import React, { useContext, useEffect, useState, useRef } from 'react';

//MuiComponents
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import {
  Button,
  Paper,
  Typography,
  Tabs,
  Tab,
  TextField,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
} from '@mui/material';
import { Box } from '@mui/system';

//component styles
const componentStyles = {};

export default function SinglePlanRow(props) {
  const [verbalAgreementObj, setVerbalAgreementObj] = useState({
    firstName: '',
    lastName: '',
    enrolledIn: '',
    totalCost: '',
    salary: '',
    adminEmail: '',
    email: '',
  });

  return (
    <TableRow hover>
      <TableCell variant="head">{props?.getDisplayName(props?.row)}</TableCell>
      <TableCell align="left">
        {props?.row == 'verbalSignature' ? (
          <Box
            sx={{
              maxHeight: '400px',
              width: 'auto',
              overflow: 'auto',
            }}
          >
            <Box>
              <Typography fontSize={'.9rem'}>
                Please state your first and last name:&nbsp;
                {verbalAgreementObj.firstName
                  ? verbalAgreementObj.firstName
                  : '$First Name'}
                &nbsp;
                {verbalAgreementObj.lastName
                  ? verbalAgreementObj.lastName
                  : '$Last Name'}
                . Today you called and enrolled in:&nbsp;
                {verbalAgreementObj.enrolledIn
                  ? verbalAgreementObj.enrolledIn
                  : '$Benefit Summary'}
                &nbsp;
                <strong>
                  (Enroller reviewed the above selected coverages, applicable
                  offsets, payments and transaction fees)&nbsp;
                </strong>
                with a total monthly cost of&nbsp;
                {verbalAgreementObj.totalCost
                  ? props?.UsdFormatterDec.format(verbalAgreementObj.totalCost)
                  : '$Total Cost'}
                . It is your responsibility to thoroughly read the full policy
                booklet. To request a booklet, please email&nbsp;
                {verbalAgreementObj.adminEmail
                  ? verbalAgreementObj.adminEmail
                  : '$Admin Email'}
                . You have reported an annual income of&nbsp;
                {verbalAgreementObj.salary != ''
                  ? props?.UsdFormatter.format(verbalAgreementObj.salary)
                  : '$Salary'}
                ; &nbsp;
                <strong>
                  If your elected benefit is tied to your income, please be
                  aware if you report an income greater than what you make, you
                  will not receive benefits on the overstated income amount.
                </strong>
              </Typography>
            </Box>
            <Box
              dangerouslySetInnerHTML={{
                __html: props?.value,
              }}
            ></Box>
          </Box>
        ) : (
          <Box
            sx={{ maxHeight: '200px', overflow: 'auto' }}
            dangerouslySetInnerHTML={{
              __html: props?.value,
            }}
          ></Box>
        )}
      </TableCell>
      <TableCell align="right">
        <Box>
          <>
            <IconButton
              onClick={(e) => props?.handleOpenRowMenu(e)}
              value={props?.row}
            >
              <MoreVertIcon
                sx={{
                  color: 'text.secondary',
                  // float: "right",
                }}
              />
            </IconButton>
            <Menu
              id="edit"
              anchorEl={props.anchorEl?.[props?.row]}
              open={Boolean(props.anchorEl?.[props?.row])}
              onClose={props.handleCloseRowMenu}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              <MenuItem
                value={props.value}
                sx={{ fontSize: '14px' }}
                onClick={() =>
                  props.handleEdit(
                    props?.value,
                    'groupplan',
                    props?.groupPlan.id,
                    props?.row
                  )
                }
              >
                Edit
              </MenuItem>
            </Menu>
          </>
        </Box>
      </TableCell>
    </TableRow>
  );
}
