import './memberTopbar.css'

import LinkButton from "./topbar-link-button/topbar-link-button";
import TopbarBack from './topbar-back/topbar-back'

import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import SearchIcon from '@mui/icons-material/Search';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import AddIcon from '@mui/icons-material/Add';
import FilterListTwoToneIcon from '@mui/icons-material/FilterListTwoTone';
import { TextField } from "@mui/material";

const MuiSearch = <SearchIcon fontSize="large"/>;
const MuiNote = <NoteAddIcon fontSize="large"/>
const MuiAdd = <AddIcon fontSize="large"/>;

function MemberTopbar(props){

    
    return (
        <div className="flex-row member-topbar">
            <TopbarBack />
            <FormControl>
                {/*<InputLabel></InputLabel>*/}
                <TextField
                    label="Quick Search"
                    type="search"
                    id="topbar-searchfield"
                >
                 
                </TextField>
            </FormControl>
            <div type="button">
                <FilterListTwoToneIcon id="topbar-filter-icon" fontSize="large" />
            </div>
            <LinkButton text="Advanced Search" href="/member/search" image={MuiSearch}></LinkButton>
            <LinkButton text="Create New Task" href="/member/task" image={MuiNote}></LinkButton>
            <LinkButton text="Add Member" href="/member/add" image={MuiAdd}></LinkButton>
        </div>
    )
}

export default MemberTopbar