import React, { useEffect, useState } from 'react';

//MUI Components
import { Box, Button, Drawer, IconButton, Typography } from '@mui/material';

//MUI Icons
import CloseIcon from '@mui/icons-material/Close';

//component Styles
const componentStyles = {
  drawerPaperProps: {
    borderRadius: '12px 12px 0px 0px',
    padding: '0px 20px 20px',
    bgcolor: 'background.default',
    color: 'text.primary',
    maxHeight: '75%',
  },
  drawerContainer: {
    maxHeight: '230px',
    maxWidth: '575px',
    margin: '0 auto',
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '10px',
    position: 'sticky',
    top: 0,
    paddingTop: '16px',
  },
  closeIcon: {
    color: 'primary.main',
    padding: '0px',
  },
  bodyContainer: {
    width: '100%',
    marginBottom: '20px',
  },
  buttonRow: {
    width: '100%',
    maxWidth: '370px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '10px auto',
  },
  buttonContainer: {
    minWidth: '121px',
    display: 'flex',
  },
  surveyButton: {
    borderRadius: '100px',
    height: '42px',
    fontWeight: '700',
    width: '100%',
    maxWidth: '275px',
    '&.Mui-disabled': {
      color: 'primary.main',
      backgroundColor: 'white',
      border: '1px solid #195ffb',
      cursor: 'pointer',
    },
    '&.Mui-disabled:hover': {
      cursor: 'pointer',
    },
  },
};
function DrawerContainer(props) {
  return (
    <Drawer
      open={props?.drawerOpen}
      onClose={() => props?.setDrawerOpen(false)}
      anchor="bottom"
      sx={componentStyles.drawerContainer}
      PaperProps={{
        sx: componentStyles.drawerPaperProps,
      }}
      disableScrollLock={true}
    >
      <Box sx={componentStyles.drawerContainer}>
        {/* Drawer Header */}
        <Box sx={componentStyles.headerContainer}>
          <Typography variant="blueAccent">{props?.title}</Typography>
          <Box>
            <IconButton onClick={() => props?.setDrawerOpen(false)}>
              <CloseIcon sx={componentStyles.closeIcon} />
            </IconButton>
          </Box>
        </Box>

        <Box sx={componentStyles.bodyContainer}>
          {/* Content/message */}
          {props?.bodyText && (
            <Typography variant="body2" fontSize="14px!important">
              {props?.bodyText}
            </Typography>
          )}
          {/* NAW only buttons */}
          {props?.survey && !props?.survey?.isEmployed && (
            <Box sx={componentStyles.buttonRow}>
              <Box
                onClick={props?.handleDrawerYesClick}
                id="isEmployedTrue_Div"
                sx={componentStyles.buttonContainer}
              >
                <Button
                  variant="contained"
                  fullWidth
                  // disabled={survey.isEmployed == null || survey.isEmployed == false}
                  disabled={true}
                  sx={{
                    ...componentStyles.surveyButton,
                    fontSize: '11px',
                    marginRight: '10px',
                  }}
                >
                  YES, I AM EMPLOYED
                </Button>
              </Box>
              <Box onClick={props?.handleLocalNawExit}>
                <Button
                  variant="contained"
                  fullWidth
                  // disabled={survey.isEmployed == null || survey.isEmployed == true}
                  disabled={true}
                  sx={componentStyles.surveyButton}
                >
                  SAVE & EXIT
                </Button>
              </Box>
            </Box>
          )}

          {/* Decline All */}
          {props?.handleDecline && (
            <Box sx={componentStyles.buttonRow}>
              <Box
                onClick={props?.handleDeclineClose}
                sx={componentStyles.buttonContainer}
              >
                <Button
                  variant="outlined"
                  fullWidth
                  sx={{ ...componentStyles.surveyButton, marginRight: '10px' }}
                >
                  Cancel
                </Button>
              </Box>
              <Box onClick={props?.handleDecline}>
                <Button
                  variant="contained"
                  fullWidth
                  onClick={props.handleDecline}
                  sx={componentStyles.surveyButton}
                >
                  Decline
                </Button>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </Drawer>
  );
}

export default DrawerContainer;
