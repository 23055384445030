import Settings from "../settings";
import { baseService } from "./baseService";

class internalAttachmentService extends baseService {
    getAttachments() {
        const url = Settings.API_URL + "attachment";
        return this.get(url);
    }
    getAttachmentsForMember(memberId) {
        const url = Settings.API_URL + "attachment/" + memberId;
        return this.get(url);
    }
    getAttachmentsByTicketId(ticketId) {
        const url = Settings.API_URL + "attachment/ticket/" + ticketId;
        return this.get(url);
    }
    getAttachmentsByGroupPlanId(groupPlanId) {
        const url = Settings.API_URL + "attachment/groupplan/" + groupPlanId;
        return this.get(url);
    }
    uploadAttachment(memberId, attachmentTypeId, filename, base64) {
        const url = Settings.API_URL + "attachment/" + memberId;
        var objToPost = {
            attachmentTypeId: attachmentTypeId,
            filename: filename,
            file: base64
        }
        return this.post(url, objToPost);
    }
    uploadAttachmentTicket(ticketId, attachmentTypeId, filename, base64) {
        const url = Settings.API_URL + "attachment/ticket/" + ticketId;
        var objToPost = {
            attachmentTypeId: attachmentTypeId,
            filename: filename,
            file: base64
        }
        return this.post(url, objToPost);
    }
    /// Taskes the member id, the message you would like to send, and the attachmentId in GUID form -- let me know if you want to adjust. MB
    emailAttachmentToMember(memberId, message, attachmentId) {
        const url = Settings.API_URL + "attachment/" + memberId + "/send";

        var objToPost = {
            files: [],
            message: message
        };

        objToPost.files.push(attachmentId);

        return this.post(url, objToPost);
    }
}

const attachmentService = new internalAttachmentService();

export default attachmentService;