import React, { useState, useEffect } from "react"
import { useHistory } from "react-router-dom"

//services
import enrollmentService from "../../../../../../../services/enrollment-service"
import activeCoverageService from "../../../../../../../services/activeCoverage-service"

// Assets

//Mui Components
import Button from "@mui/material/Button"
import Drawer from "@mui/material/Drawer"
import TextField from "@mui/material/TextField"
import Select from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"
import { Alert, Box, Typography, Checkbox } from "@mui/material"

//Mui icons
import CloseIcon from "@mui/icons-material/Close"
import CheckBoxIcon from "@mui/icons-material/CheckBox"
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank"
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline"
import VisibilityIcon from "@mui/icons-material/Visibility"
import NoVisibilityIcon from "@mui/icons-material/VisibilityOff"

//Our Components
import InfinityLoader from "../../../../../../UH-loading-animation/InfinityLoader"
import { set, format } from "date-fns"

//component styles
const componentStyles = {
  componentBody: {
    fontFamily: "Poppins",
    minWidth: "538px",
    height: "100%",
    backgroundColor: "background.default",
    overflowY: "auto",
  },
  headerContainer: {
    width: "100%",
    padding: "10px 30px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "0 auto",
    borderBottom: "1px solid",
    borderColor: "primary.main",
  },
  drawerTitle: {
    color: "text.primary",
    fontFamily: "Poppins",
    fontSize: "22px",
    fontWeight: "400",
  },
  infoContainer: {
    width: "100%",
    padding: "10px 30px",
    display: "flex",
    flexDirection: "column",
    alignItems: "left",
    margin: "0 auto",
  },
  policyDetails: {
    fontFamily: "Archivo",
    fontSize: "13px!important",
    fontWeight: 400,
    color: "text.secondary",
    lineHeight: "20px",
  },
  description: {
    color: "text.primary",
    fontFamily: "Archivo",
    fontSize: "14px",
    fontWeight: "500",
    padding: "10px 16px 10px 20px",
  },
  closeDrawerButton: {
    display: "flex",
    justifyContent: "flex-end",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexWrap: "wrap",
  },
}
export default function CancelCoverageDrawer(props) {
  //PROPS
  let {
    dateOfCancelation,
    dateSelectOptions,
    setDateOfCancelation,
    handleCancelDateChange,
    cancelDateError,
    setCancelDateError,
    beginningOfNextMonthString,
    endOfCurrentMonth,
    member,
    setPlanToReinstate,
    planToReinstate,
    cancellationReasons,
    getPaymentSchedule,
    getActiveCoverage,
    getAllDocuments,
    setReinstateCoverageDrawerOpen,
    reinstateCoverageDrawerOpen,
    formatDate,
    cancelEnrollment,
  } = props

  //Drawer Logic
  const handleCloseDrawer = () => {
    setReinstateCoverageDrawerOpen(false)
  }

  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)

  //checkbox logic for download enrollment elections repots
  const [generateNewForm, setGenerateNewForm] = useState(true)

  const downloadEnrollmentElectionsReport = async () => {
    // console.log("downloading enrollment elections report")
    const link = document.createElement("a")
    let sendEmail = false

    try {
      let attachmentId = await enrollmentService.generateEnrollmentReport(
        planToReinstate?.enrollmentId,
        sendEmail
      )
      if (attachmentId) {
        link.download = `/api/attachment/${attachmentId}`
        link.href = `./api/attachment/${attachmentId}`
      }
    } catch (error) {
      console.log("error generating enrollment report", error)
    }

    setTimeout(() => {
      link.click()
      getAllDocuments()
    }, 500)
  }

  const submitReinstatement = () => {
    console.log("submit reinstatement")
  }

  //TO DO: potentially want to download in documents tab and documents section of plans tab  so it is updated
  // pass in getAllDocuments

  console.log(props)

  return (
    <>
      <Drawer
        anchor="right"
        open={reinstateCoverageDrawerOpen}
        onClose={handleCloseDrawer}
      >
        {loading && (
          <>
            <InfinityLoader
              style={{
                // position: "fixed",
                position: "absolute",
                zIndex: 5,
                width: "100%",
                height: "100%",
                backdropFilter: "blur(10px)",
              }}
            />
            <style>
              {`
                      div.lottie-div > svg {
                         height:55px!important;
                         position:absolute;
                         top:50%;
                      }
                   `}
            </style>
          </>
        )}
        <Box sx={componentStyles.componentBody}>
          <Box className="flex-row" sx={componentStyles.headerContainer}>
            <Typography variant="h5" sx={componentStyles.drawerTitle}>
              Re-Instate Coverage
            </Typography>
            <Box
              sx={componentStyles.closeDrawerButton}
              role="button"
              onClick={handleCloseDrawer}
            >
              <CloseIcon sx={{ height: "34px" }} />
            </Box>
          </Box>
          {/* Plan Info */}
          <Box sx={componentStyles.infoContainer}>
            <Typography
              variant="h5"
              sx={{
                fontFamily: "Poppins",
                fontSize: "18px",
                fontWeight: 400,
                marginBottom: "6px",
              }}
            >
              {/* {planToReinstate?.displayName.toUpperCase() || ""} */}
              {!props?.cancelEnrollment
                ? props?.planToReinstate?.displayName || ""
                : props?.singleEnrollment?.name}
            </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: "14px",
                    fontWeight: 400,
                    color: "text.secondary",
                  }}
                >
                  Policy #{planToReinstate?.policyNumber || ""}
                </Typography>
                <Typography variant="body2" sx={componentStyles.policyDetails}>
                  Carrier: {planToReinstate?.carrierName || ""}
                </Typography>
                <Typography variant="body2" sx={componentStyles.policyDetails}>
                  Effective Date:{" "} 
                  {planToReinstate?.beginDate
                    ? format(formatDate(planToReinstate?.beginDate), "MM/dd/yyyy")
                    : ""}
                </Typography>
          </Box>

          {/* Edit fields */}
          <Box sx={componentStyles.infoContainer}>
            {/* Hide Date */}
                {/* <TextField
                  select
                  fullWidth
                  id="date"
                  sx={{
                    "& fieldset": { borderRadius: "4px" },
                    marginBottom: "15px",
                  }}
                  label="Re-Instatement Date"
                  value={dateOfCancelation || ""}
                  // error={cancelDateError}
                  // helperText={cancelDateError}
                  onChange={handleCancelDateChange}
                  InputLabelProps={{ shrink: true }}
                >
                  {dateSelectOptions?.length > 0 &&
                    dateSelectOptions.map((date) => {
                      return (
                        <MenuItem key={date.value} value={date.value}>
                          {`${date.name} (${date.displayDate})`}
                        </MenuItem>
                      )
                    })}
                </TextField> */}

                <Box
                  className="flex-row container"
                  sx={{
                    padding: "20px 0px",
                    position: "relative",
                    marginBottom: "15px",
                    fontFamily: "Archivo",
                  }}
                >
                  <Checkbox
                    onChange={(e) => setGenerateNewForm(!generateNewForm)}
                    // value={}
                    checked={generateNewForm}
                    name={"generateNewForm"}
                    sx={{
                      padding: "0px",
                      marginRight: "12px",
                    }}
                    icon={<CheckBoxOutlineBlankIcon sx={{ height: "19px" }} />}
                    checkedIcon={<CheckBoxIcon sx={{ height: "19px" }} />}
                  />
                  Generate Enrollment Elections Report
                </Box>
          </Box>
          {/* Buttons */}
          <Box sx={componentStyles.buttonContainer}>
            <Button sx={{ marginRight: "10px" }} onClick={handleCloseDrawer}>
              CANCEL
            </Button>
            <Button
              variant="contained"
              //do not currently need cancelation reason for canceling enrollment durin open enrollment
              disabled={false}
              onClick={submitReinstatement}
            >
              RE-INSTATE COVERAGE
            </Button>
          </Box>
        </Box>
      </Drawer>
    </>
  )
}
