import React, { useEffect, useState } from "react"

export default function ChartLegend(props) {
  let COLORS = props?.colors
  let results = props?.results || []

  //display different data for each chart depending what is passed down as props
  return (
    <ul
      style={{
        listStyleType: "none",
        padding: "20px 5px 0px 10px",
        display: "flex",
        flexDirection: "column",
        flexWrap: "wrap",
        backgroundColor: "transparent",
      }}
    >
      {results?.length > 0 &&
        results?.map((entry, index) => (
          <li
            key={`item-${index}`}
            style={{
              position: "relative",
              marginBottom: "0px",
              minHeight: "20px",
              textAlign: "left",
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-start",
              backgroundColor: "transparent",
              // flexWrap: 'wrap'
            }}
          >
            <div
              style={{
                display: "inline-block",
                minWidth: "14px",
                minHeight: "7px",
                backgroundColor: COLORS[index % COLORS?.length],
                margin: "6px 6px 2px 0px",
              }}
            ></div>

            <div
              style={{
                color: "primary.text",
                fontFamily: "Archivo",
                fontSize: "12px",
                minHeight: "18px",
                display: "flex",
                flexWrap: "wrap",
                backgroundColor: "transparent",
              }}
            >
              {entry?.enrollmentMethod && entry.enrollmentMethod?.toUpperCase()}
              {entry?.type && entry?.type?.toUpperCase()}
              {entry?.status && entry?.status?.toUpperCase()}

              {entry?.metric > 0
                ? ` (${props?.formatNumWithCommas(entry?.metric)})`
                : ""}
              {entry?.count > 0
                ? ` (${props?.formatNumWithCommas(entry?.count)})`
                : ""}

              {/* display zero values */}
              {entry?.metric === 0 ? "(0)" : ""}
              {entry?.count === 0 ? "(0)" : ""}
            </div>
          </li>
        ))}
    </ul>
  )
}
