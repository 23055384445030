import React, { useContext, useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
// import { format, formatDistance, subMinutes } from "date-fns";

//services
import paymentsService from "../../../../../../services/payments-service"

//Mui Components
import Box from "@mui/material/Box"
// import { Typography } from '@mui/material';

// Assets
// import openSvg from '../../../../../../assets/images/openSvg.svg'
// import closedSvg from '../../../../../../assets/images/closedSvg.svg'

//Mui icons
// import ManageSearchIcon from '@mui/icons-material/ManageSearch';

//Our Components
import { thisUserContext } from "../../../../nav-container/NavContainer"
import { Card } from "@mui/material"
import PaymentPlanCard from "./card-components/PaymentPlanCard"
import PaymentWalletCard from "./card-components/PaymentWalletCard"
import PaymentGridCard from "./card-components/PaymentGridCard"
import MakePaymentDrawer from "./payment-drawers/make-payment-drawer/MakePaymentDrawer"

//Constants
import { permissionConstants } from "../../../../../../constants/permissionConstants"
import { set, format } from "date-fns"

//utils
import { formatDateObjYYYYMMDD } from "../../../../../utils"

export default function MemberTicketsTab(props) {
  const memberHasActiveCoverage = (props?.paymentSchedule[0]?.id > 0 || props?.currentActiveCoverage?.length > 0 )

  // STATE GETTERS AND CALLING EFFECTS
  const [permissionsEnabler, setPermissionsEnabler] = useState({})
  useEffect(() => {
    if (props.permissionsEnabler) {
      setPermissionsEnabler(props.permissionsEnabler)
    }
  }, [props.permissionsEnabler])

  let _thisUser = useContext(thisUserContext)
  const [thisUser, setThisUser] = useState({})
  const [allUsers, setAllUsers] = useState([])
  let getThisUser = async () => {
    setThisUser(_thisUser.thisUser)
    // setAllUsers(_thisUser.allUsers)
  }

  useEffect(() => {
    if (_thisUser) {
      getThisUser()
    }
  }, [_thisUser])

  //Date stuff

  let todayDateObj = new Date()
  let todayDateFormatted = formatDateObjYYYYMMDD(todayDateObj)
  let todayAt4PM = todayDateObj.setHours(16, 0, 0, 0)
  let currentDateAndTime = new Date().getTime()

  //GET ALL PAYMENTS - payment data grid
  const [allPayments, setAllPayments] = useState([])
  const getAllPayments = async () => {
    try {
      let _allPayments = await paymentsService.getAllPaymentsByMemberId(
        props.member?.id
      )
      // console.log("ALL PAYMENTS", _allPayments);
      setAllPayments(_allPayments)
    } catch (error) {
      console.error("error getting all payments", error)
    }
  }

  //get any one-time payments that have been scheduled
  const [oneTimePayments, setOneTimePayments] = useState([])
  const getOneTimePayments = async () => {
    try {
      let _oneTimePayments = await paymentsService.getOneTimePaymentsByMemberId(
        props.member?.id
      )

      setOneTimePayments(_oneTimePayments)
    } catch (error) {
      console.error("error getting one-time payments", error)
    }
  }

  const [outstandingBalance, setOutstandingBalance] = useState()
  // const getOutstandingBalance = async () => {
  //   try {
  //     let _balance = await paymentsService.getPaymentBalanceByMemberId(
  //       props.member?.id
  //     );
  //     console.log("PaymentBalance", _balance);
  //     setOutstandingBalance(_balance);
  //   } catch (error) {
  //     console.error("error with getting outstanding balances", error);
  //     //temporarily set balance to 0
  //     setOutstandingBalance({ ...outstandingBalance, balanceAmount: 0 });
  //   }
  // };
  useEffect(
    () => setOutstandingBalance(props.topOutstandingBalance),
    [props.topOutstandingBalance]
  )

  useEffect(() => {
    if (props.member) {
      // getOutstandingBalance();
      getOneTimePayments()
      getAllPayments()
    }
    //set this value to false because payment editing is being accessed from the payments tab
    if (props.setEditWalletFromPlans) props.setEditWalletFromPlans(false)
  }, [props.member])

  //handle opening payment drawer - this will also display the radio buttons to select a bank account to make a payment
  const [paymentDrawerOpen, setPaymentDrawerOpen] = useState(false)
  const [showSelectBankAccount, setShowSelectBankAccount] = useState(false)
  const [rerunPaymentAmount, setRerunPaymentAmount] = useState(0)

  const handleOpenMakePaymentDrawer = (e, reRunValue) => {
    //will have a reRunValue if making a one time payment from selecting re-run on a payment in the grid
    //otherwise, reRunValue will be undefined
    if (reRunValue) {
      setRerunPaymentAmount(reRunValue)
    }
    setShowSelectBankAccount(true)
    setPaymentDrawerOpen(true)
  }

  return (
    <Box
      sx={{
        // bgcolor: 'background.background',
        bgcolor: "background.border",
        color: "text.primary",
        height: "calc(100vh - 104px)",
      }}
    >
      {/* PAYMENT DRAWER */}
      <MakePaymentDrawer
        {...props}
        paymentDrawerOpen={paymentDrawerOpen}
        setPaymentDrawerOpen={setPaymentDrawerOpen}
        member={props.member}
        showSelectBankAccount={showSelectBankAccount}
        setShowSelectBankAccount={setShowSelectBankAccount}
        outstandingBalance={outstandingBalance}
        getOneTimePayments={getOneTimePayments}
        getPaymentSchedule={props.getPaymentSchedule}
        getAllPayments={getAllPayments}
        rerunPaymentAmount={rerunPaymentAmount}
        setRerunPaymentAmount={setRerunPaymentAmount}
        todayAt4PM={todayAt4PM}
        currentDateAndTime={currentDateAndTime}
        oneTimePayments={oneTimePayments}
      />

      <div className="flex-row" style={{ flexWrap: "wrap", gap: "1px" }}>
        {/* TOP LEFT PLAN CARD */}
        <div className="col-6">
          <PaymentPlanCard
            {...props}
            outstandingBalance={outstandingBalance}
            oneTimePayments={oneTimePayments}
            setOneTimePayments={setOneTimePayments}
            enrollments={props.enrollments} //these are not used in this component and can be removed
            getOneTimePayments={getOneTimePayments}
            getAllPayments={getAllPayments}
            getPaymentSchedule={props.getPaymentSchedule}
            permissionsEnabler={permissionsEnabler}
            editWalletFromPlans={props.editWalletFromPlans}
            paymentDrawerOpen={paymentDrawerOpen}
            setPaymentDrawerOpen={setPaymentDrawerOpen}
            showSelectBankAccount={showSelectBankAccount}
            setShowSelectBankAccount={setShowSelectBankAccount}
            handleOpenMakePaymentDrawer={handleOpenMakePaymentDrawer}
            memberHasActiveCoverage={memberHasActiveCoverage}
          />
        </div>

        {/* TOP RIGHT WALLET CARD */}
        <div className="col">
          <PaymentWalletCard
            getBankAccount={props.getBankAccount}
            bankAccount={props.bankAccount}
            setBankAccount={props.setBankAccount}
            bankAccountTypes={props.bankAccountTypes}
            setBankAccountTypes={props.setBankAccountTypes}
            member={props.member}
            thisUser={thisUser}
            permissionsEnabler={permissionsEnabler}
            editWalletFromPlans={props.editWalletFromPlans}
          />
        </div>

        {/* BOTTOM GRID CARD */}
        <div className="col-12">
          <PaymentGridCard
            {...props}
            thisUser={thisUser}
            allPayments={allPayments}
            oneTimePayments={oneTimePayments}
            setAllPayments={setAllPayments}
            getAllPayments={getAllPayments}
            permissionsEnabler={permissionsEnabler}
            paymentDrawerOpen={paymentDrawerOpen}
            setPaymentDrawerOpen={setPaymentDrawerOpen}
            showSelectBankAccount={showSelectBankAccount}
            setShowSelectBankAccount={setShowSelectBankAccount}
            handleOpenMakePaymentDrawer={handleOpenMakePaymentDrawer}
            memberHasActiveCoverage={memberHasActiveCoverage}
            getOneTimePayments={getOneTimePayments}
          />
        </div>
      </div>
    </Box>
  )
}
