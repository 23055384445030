import React, { useEffect, useState } from "react";

//MuiIcons
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

//Mui Components
import Chip from "@mui/material/Chip";

export default function PaymentHealthChips(props) {

  const UsdFormatterDec = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 2 });

  const [chip, setChip] = useState({
    icon: null,
    backgroundColor: "",
    color: "",
    border: "",
    borderColor: "",
    label: "",
    visibility: 'hidden'
  });


  //buildig chip based off of the object with an isDelinquent property of true or false
  const buildChip = () => {
    switch (props.paymentHealthStatus?.isDelinquent) {
      // switch(false){
      case true:
        setChip({
          icon: (
            <ErrorOutlineIcon
              sx={{ height: 20, color: `#D32F2F!important` }}
            />
          ),
          backgroundColor: "#FBEAEA",
          border: "solid 1px",
          borderColor: "secondary.furloughedChipBorder",
          color: "#D32F2F",
          label: `Outstanding Balance ${UsdFormatterDec.format(props?.paymentHealthStatus?.balanceAmount)}`,
          visibility: 'visible'
        });
        break;
      case false:
        setChip({
          icon: <CheckCircleIcon sx={{ height: 18, color: `#7B1FA2!important` }} />,
          backgroundColor: "background.onDisabilityChip",
          color: "primary.onDisabilityChip",
          label: "Balance up to date",
          visibility: 'visible'
        });
        break;
        //save this for potential Payback Schedule future use
      case 2:
        setChip({
          icon: (
            <AccessTimeIcon
              sx={{ height: 18, color: `rgb(50, 100, 155, 1)!important` }}
            />
          ),
          backgroundColor: "background.onMedicalLeaveChip",
          color: "primary.onMedicalLeaveChip",
          border: "solid 1px",
          borderColor: "secondary.onMedicalLeaveBorder",
          label: "Payback Schedule",
          visibility: 'visible'
        });
        break;
      default:
        console.warn("No matching chip found");
    }
  };

  useEffect(() => {
    buildChip();
  }, [props?.paymentHealthStatus]);

  return (
    <>
      <Chip
        icon={chip.icon}
        sx={{
          backgroundColor: chip.backgroundColor,
          color: chip.color,
          border: chip.border,
          borderColor: chip.borderColor,
          fontWeight: "400",
          fontFamily: "Archivo",
          fontSize: "13px",
          height: "24px",
          borderRadius: "9px",
          visibility: chip.visibility
        }}
        label={chip.label}
      />
    </>
  );
}
