import { fontFamily, fontSize, fontWeight, grid, height, letterSpacing, lineHeight } from "@mui/system"

const settingsTeamStyles = {
    employeeCardHeader: {
        fontVariant: 'h5',
        fontSize: "24px",
        fontWeight: "400",
    },
    employeeCardSubHeader: {
        fontVariant: 'h6',
        fontSize: "20px",
        fontWeight: "500",
    },
    employeeCardItemLabels: {
        fontFamily: "Archivo",
        fontSize: "12px !important",
        fontWeight: "400",
        color: "primary.main",
    },
    employeeCardItemData: {
        // fontFeatureSettings
        fontWeight: 500,
        fontSize: "14px",
        fontFamily: "Archivo",
        lineHeight: "150%",
        letterSpacing: "0.15px",
    },
    employeeCardItemContainer: {
        margin: "17px 0px 0px 0px",
    },
    employeeCardColumnContainer: {
        columnGap: "40px",
        display: "grid",
        gridTemplateColumns: "50% 50%",
    },
    employeeCardButtonContainer: {
        position: "relative",
        minHeight: "150px",
    },
    employeeCardButtonInnerContainer: {
        height: "100%",
    },
    employeeCardButton: {
        // fontFeatureSettings
        size: "large",
        fontFamily: "Archivo",
        fontSize: "13.5px",
        fontStyle: "normal",
        fontWeight: "500",
        lineHeight: "26px",
        letterSpacing: "0.36px",
        marginBottom: "15px",
    },


    settingsTeamEmployeeCardItemContainer: {
        margin: "17px 0px 0px 0px",
    },

    settingsTeamEmployeeCardColumnContainer: {
        columnGap: "40px",
        display: "grid",
        gridTemplateColumns: "50% 50%",
    },
    settingsTeamEmployeeCardButtonContainer: {
        
    },
    teamEmployeeColumnText: {
        fontSize: "16px",
    },
}

//.settings-team-employee-card-item-container {
//    margin: 17px 0px 0px 0px;
//}

//.settings-team-employee-card-column-container {
//    column-gap: 40px;
//    display: grid;
//    grid-template-columns: 50% 50%;
//}

//.settings-team-employee-card-button-container {
//    position: relative !important;
//    min-height: 150px !important;
//}
  
export default settingsTeamStyles
  