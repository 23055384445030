import React, { useEffect, useState } from 'react';

//MuiIcons
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ReceiptIcon from '@mui/icons-material/Receipt';

//Mui Components
import { Box, Button, Collapse, Typography } from '@mui/material';

//component Styles
const componentStyles = {
  componentContainer: {
    display: 'flex',
    backgroundColor: 'background.currentAcMessage',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    margin: '10px 0px',
    borderRadius: '4px',
  },
  titleAndMenuRow: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'flex-start',
    alignContent: 'center',
    padding: '16px 8px 16px 16px',
    cursor: 'pointer',
  },
  arrowIcon: {
    color: 'text.primary',
  },
};
export default function CurrentAcLineChipDisplay(props) {
  //format currency
  let UsdFormatterDec = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
  });

  const [expanded, setExpanded] = useState(false);

  const determineCoveredLives = (plan) => {
    let calculatedPlanType = plan.calculatedPlanTypeId;
    let coveredLives = plan.coveredLives;
    switch (calculatedPlanType) {
      case 4:
        coveredLives = 'Member';
        break;
      case 5:
        coveredLives = 'Spouse';
        break;
      case 6:
        coveredLives = 'Dependent';
        break;
      default:
        return coveredLives;
    }

    return coveredLives;
  };

  const handleExpandSection = () => {
    setExpanded(!expanded);
  };

  return (
    <Box sx={componentStyles.componentContainer}>
      <Box sx={componentStyles.titleAndMenuRow} onClick={handleExpandSection}>
        <ReceiptIcon sx={{ margin: '0px 10px 0px 0px', padding: '0px' }} />
        <Typography fontWeight={500}>Member has Active Coverage</Typography>
        <Button
          size="small"
          sx={{ width: '32px!important', padding: '0px', marginLeft: 'auto' }}
          //   disabled={activeCoveragePlansAndOptions.length < 1}
        >
          {expanded ? (
            <ArrowDropUpIcon sx={componentStyles.arrowIcon} />
          ) : (
            <ArrowDropDownIcon sx={componentStyles.arrowIcon} />
          )}
        </Button>
      </Box>

      <Collapse sx={{ width: '100%' }} in={expanded} unmountOnExit>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            padding: '0px 30px 20px 50px',
            // width: '100%',
            // border: '1px solid red',
          }}
        >
          {props?.plan?.length > 0 &&
            props?.plan?.map((plan, index) => {
              return (
                <Box key={index} sx={{ width: '100%', marginBottom: '10px' }}>
                  {/* show covered lives if anything other than Member */}
                  <Typography>
                    {determineCoveredLives(plan) === 'Member'
                      ? ''
                      : determineCoveredLives(plan)}
                  </Typography>
                  {plan?.benefitAmount > 0 ? (
                    <Typography>
                      Benefit Amount:{' '}
                      {UsdFormatterDec.format(plan.benefitAmount)}
                    </Typography>
                  ) : (
                    ''
                  )}
                  <Typography>
                    Cost: {UsdFormatterDec.format(plan?.premiumAmount)}
                  </Typography>

                  <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                    {plan?.eliminationPeriod ? (
                      <Typography>
                        Elimination Period: {plan.eliminationPeriod}{' '}
                        {plan?.eliminationPeriodTypeFriendly
                          ? ` ${plan?.eliminationPeriodTypeFriendly}`
                          : ''}
                      </Typography>
                    ) : (
                      ''
                    )}
                    {/* show separating | if both elim and benifit duration exist */}
                    {plan?.eliminationPeriod && plan?.benefitDuration ? (
                      <Typography sx={{ margin: '0px 10px' }}> | </Typography>
                    ) : (
                      ''
                    )}
                    {plan?.benefitDuration ? (
                      <Typography>
                        {'  '}
                        Benefit Duration: {plan.benefitDuration}{' '}
                        {plan?.benefitDurationTypeFriendly
                          ? ` ${plan?.benefitDurationTypeFriendly}`
                          : ''}
                      </Typography>
                    ) : (
                      ''
                    )}
                  </Box>
                </Box>
              );
            })}
          <Typography
            sx={{
              alignSelf: 'flex-end',
              textAlign: 'right',
              fontStyle: 'italic',
              fontSize: '13px',
            }}
          >
            {/* need to add in effective date for current enrollment */}
            *You can make changes or elect to cancel below. To keep current
            coverage, <strong>reselect</strong> existing options.
          </Typography>{' '}
        </Box>
      </Collapse>
    </Box>
  );
}
