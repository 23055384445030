import Settings from "../settings"
import { baseService } from "./baseService"

class internalActiveCoverageService extends baseService {
  getActiveCoveragebyMemberId(memberId) {
    const url = Settings.API_URL + "activecoverage/member/" + memberId
    return this.get(url)
  }
  updateActiveCoverageForMember(activeCoverage) {
    //accepts an activeCoverage object
    const url = Settings.API_URL + "activecoverage/"
    return this.put(url, activeCoverage)
  }
  insertActiveCoverageForMember(activeCoverage) {
    const url = Settings.API_URL + "activecoverage/"
    return this.post(url, activeCoverage)
  }
  //cancels line of coverage - for cancel ALL active coverage, will need to loop over existing lines of coverage and cancel each individually
  cancelActiveCoverageForMember(activeCoverageId, reason, cancelDate, cancelAll = false) {
    const url =
      Settings.API_URL +
      `activecoverage/cancel?activeCoverageId=${activeCoverageId}&canceldate=${cancelDate}&cancelAll=${cancelAll}`
    let requestBody = { reason: reason }
      return this.put(url, requestBody)
  }
}

const activeCoverageService = new internalActiveCoverageService()

export default activeCoverageService
