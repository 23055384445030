const enrollmentBeneficiariesStyles = {
  sectionHeaderRow: {
    display: 'flex',
    flexDirection: 'row',
    minHeight: '57px',
    fontSize: '20px',
    fontWeight: '600',
    lineHeight: '30px',
    position: 'relative',
    alignItems: 'center',
    padding: '0px',
    fontFamily: 'Poppins',
    color: 'primary.main',
  },
  sectionTitle: {
    cursor: 'pointer',
    position: 'relative',
    fontFamily: 'Poppins',
    fontSize: '20px!important',
    fontWeight: '600',
  },
  expandAndCollapseButton: {
    position: 'absolute',
    right: '10px',
    top: '0%',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    width: '50px',
    height: '100%',
    cursor: 'pointer',
  },
  divider: {
    color: 'background.border',
    margin: '0px, auto, 20px',
  },
  collapseSection: {
    marginBottom: '18px',
  },
  sectionInstructionRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    margin: '12px auto 16px',
  },
  sectionInstructionText: {
    fontSize: '15px',
    fontFamily: 'Archivo',
    fontWeight: 700,
    lineHeight: '25px',
  },
  infoTipContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  singleBennyRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginBottom: '15px',
  },
  checkboxStyle: { padding: '0px', marginRight: '10px' },
  uncheckedIcon: { height: '19px' },
  checkedIcon: { height: '19px', color: 'primary.main' },
  nameAndRelationshipContainer: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: '120px', ///UNSURE ON THIS HARD CODE
    marginRight: 'auto',
  },
  nameText: {
    fontSize: '15px',
    fontFamily: 'Archivo',
    fontWeight: 600,
    lineHeight: '25px',
  },
  relationshipText: {
    fontSize: '15px',
    fontFamily: 'Archivo',
    fontWeight: 500,
    color: 'text.lightGray',
  },
  textFieldInputProps: {
    sx: { height: '40px', minWidth: '150px' },
  },
  //ADD and EDIT beneficiary
  inputField: {
    width: '100%',
    marginBottom: '15px',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    margin: '0 10px 20px',
  },
  saveButton: {
    height: '40px',
    maxWidth: '100px',
    fontWeight: '500!important',
    marginLeft: '10px',
  },
  cancelButton: {
    height: '40px',
    fontWeight: '500!important',
    maxWidth: '100px',
  },
  addAdditionalBennyCheckRow: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '20px',
    // border: '1px solid green',
  },
  addAdditionalBennyText: {
    fontSize: '15px',
    fontFamily: 'Archivo',
    fontWeight: 600,
  },
  //collapsed vies
  collapsedRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: '5px 0px',
  },
  collapsedRowText: {
    fontSize: '15px',
    fontFamily: 'Archivo',
    fontWeight: 500,
    color: 'text.lightGray',
  },

  //add contingent question - Would you like to add?
  addContingentQuestionContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: '0px auto 15px',
    maxWidth: '350px',
  },
  addContingentQuestionText: {
    fontFamily: 'Archivo',
    fontWeight: 500,
    fontSize: '15px',
    lineHeight: '25px',
    color: '#646464',
    margin: '10px auto 23px',
    textAlign: 'center',
    textWrap: 'wrap',
  },
  addContingentButtonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    margin: '0px auto',
  },
  addContingentButton: {
    height: '50px',
    minWidth: '50%',
    maxWidth: '165px',
  },
};

export default enrollmentBeneficiariesStyles;
