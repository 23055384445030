const memberInfoStyles = {
  sectionContainer: {
    width: "100%",
    fontFamily: "Archivo",
    borderBottom: "1px solid",
    borderColor: "background.border",
    paddingBottom: "16px",
    margin: "0 auto",
  },
  sectionHeaderContainer: {
    borderBottom: "1px solid",
    borderColor: "background.border",
    padding: "16px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  sectionTitle: {
    fontSize: "20px",
    fontWeight: 500,
    fontFamily: "Poppins",
    color: "text.primary",
  },
  editButtonsContainer: {
    display: "flex",
    flexDirection: "row",
    // flexWrap: "wrap",
    justifyContent: "flex-end",
    paddingTop: "5px",
  },
  editButton: {
    fontSize: "13px",
    fontWeight: 500,
    padding: "10px",
    height: "30px",
    borderColor: "primary.main",
    "&.MuiButtonBase-root.MuiButton-root": {
      minWidth: "44px",
    },
  },
  cancelEditButton: {
    fontSize: "13px",
    fontWeight: 500,
    padding: "5px",
    height: "30px",
    width: "60px",
  },
  saveEditButton: {
    fontSize: "13px",
    fontWeight: 500,
    height: "30px",
    padding: "10px",
    borderColor: "primary.main",
    marginLeft: "6px",
    borderColor: "primary.main",
    "&.MuiButtonBase-root.MuiButton-root": {
      minWidth: "44px",
    },
  },
}

export default memberInfoStyles
