import React, { useState, useEffect, useMemo } from "react"

//Services
import enrollmentService from "../../../../../../../../services/enrollment-service"

//Mui icons
import DeleteIcon from "@mui/icons-material/Delete"
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline"

//MuiX
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"

//Mui Components
import {
  Alert,
  Box,
  Button,
  Chip,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputAdornment,
  MoreVertIcon,
  MenuItem,
  Menu,
  Select,
  Switch,
  TextField,
  Typography,
} from "@mui/material"

//our components
import InfinityLoader from "../../../../../../../UH-loading-animation/InfinityLoader"
import EditCoverageEffectiveDateStepper from "../EditCoverageEffectiveDateStepper"

//utils
import {
  formatUTCDateToObjWithTimeZone,
  formatDateObjMMDDYYYYDisplay,
  formatUTCToShortDate,
} from "../../../../../../../utils"

//Component Styles
const componentStyles = {
  componentContainer: {
    position: "relative",
    margin: "10px 0px",
  },
  bennyGroupRow: {
    marginBottom: "16px",
    justifyContent: "space-between",
    display: "flex",
    flexDirection: "row",
  },
  bennyTypeTitleLeftSide: (edit) => ({
    width: edit ? "100%" : "30%",
    display: "flex",
    flexDirection: edit ? "row" : "column",
    justifyContent: "space-between",
    alignItems: edit ? "center" : "flex-start",
  }),

  bennyGroupColumnRightSide: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
  },
  singleBennyViewRow: {
    margin: "0px 0px 5px 0px",
    textAlign: "right",
  },

  //EDIT EXISTING BENEFICIARY
  editBeneficiaryContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    margin: " 0px 0px 10px 0px",
    // border: "1px solid blue",
    width: "100%",
  },
  editBeneficiaryRow: {
    display: "flex",
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "0px 0px 15px 16px",
    alignItems: "center",
    // border: "1px solid green",
  },
  allocationInputStyles: {
    textField: { marginRight: "5px", minWidth: "100px" },
    inputPropsStyles: { height: "40px" },
    inputAdornment: { marginLeft: "-30px" },
  },
  nameRelationshipOnEditRow: {
    display: "flex",
    flexDirection: "row",
    width: "50%",
    flexWrap: "wrap",
  },
  editableFieldsRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "flex-start",
    // border: "1px solid green",
  },
  editViewBennyNameText: {
    fontWeight: "bold",
    fontSize: "14px",
  },
  editViewRelationshipText: {
    fontSize: "14px",
  },
  verticalLineTextSeparator: { margin: "0px 8px" },

  //ADD BENEFICIARY
  addAdditionalButton: { fontWeight: 600, fontSize: "15px" },
  beneficiaryChip: {
    margin: "5px 4px 0px 0px",
    backgroundColor: "transparent",
    color: "primary.main",
    border: "solid 1px",
    borderColor: "primary.main",
    fontWeight: "500",
    fontFamily: "Archivo",
    fontSize: "14px",
    height: "36px",
    borderRadius: "50px",
  },

  addBeneficiaryContainer: {
    display: "flex",
    flexDirection: "column",
    margin: "20px 0px",
    width: "100%",
  },
  potentialBeneficiaryRow: {
    display: "fled",
    flexDirection: "row",
    width: "100%",
    flexWrap: "wrap",
    margin: "5px 0px 10px",
  },
  addBeneficiaryInputs: {
    margin: "5px 0px",
  },
  customDatePicker: (editExisting) => ({
    padding: "0px",
    margin: editExisting ? "0px 8px 1px" : "5px 0px",
    "& .MuiOutlinedInput-input": {
      padding: "10px 12px 9px",
      fontSize: "14px", // Adjust the font size
      top: "-20px",
    },
    width: editExisting ? "160px" : "100%",
  }),
  cancelOrAddBeneficiaryButtons: {
    height: "23px",
    fontWeight: "500!important",
    fontSize: "13px",
    marginLeft: "10px",
  },

  //ALLOCATION ALERT STYLES
  editContingentAndDisplayAlertRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  allocationErrorAlert: {
    alertContainer: {
      minWidth: "270px",
      alignSelf: "flex-end",
    },
    alertBody: {
      padding: "5px 10px",
    },
    alertText: {
      fontSize: "12px!important",
    },
  },

  //SAVE CHANGES & BACK BUTTON BOTTOM ROW
  bottomRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    minHeight: "40px",
    marginRight: "8px",
    fontFamily: "Archivo",
  },
  saveAndCancelButtonContainer: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    padding: "20px 0px 10px",
    justifyContent: "flex-end",
  },
  saveAndCancelEditButtons: (save) => ({
    fontSize: "13px",
    borderRadius: "8px",
    height: "30px",
    fontWeight: "500!important",
    marginLeft: "10px",
    minWidth: save ? "142px" : "58px",
  }),
}

export default function BeneficiaryViewAndEdit(props) {
  //requirements for this component -
  //use in a plan that has benefeciaries associated
  //pas in setEdit state held in plan component - for ladd - have setEditBeneficiary state of true or false is passed in as setEdit to separate updating ladd coverage OR beneficiary info
  //pass in plan object
  //pass in member obj
  //pass in relationship types called in member profile

  
  const [loading, setLoading] = useState(false)
  //Benny Logic from Ladd component
  //should be getting plan from props as the parent plan object from active coverage
  const [storedAllocations, setStoredAllocations] = useState([])
  const [primaryBennies, setPrimaryBennies] = useState([])
  const [contingentBennies, setContingentBennies] = useState([])
  const [potentialBeneficiaries, setPotentialBeneficiaries] = useState([])
  const [totalAllocationError, setTotalAllocationError] = useState(false)

  const getAllocations = async () => {
    //TECHNICALLY A HARDCODE, CHECK FOR BENNY ON PLAN
    //  props.setLoading(true);
    //get allocations for agent-side single enrollment with planId and memberId
    const planId = props?.plan.planId
    const memberId = props.member?.id

    try {
      let _storedAllocations =
        await enrollmentService.getBeneficiaryAllocationsForMember(
          planId,
          memberId
        )

      if (!_storedAllocations || _storedAllocations.length === 0) {
        setStoredAllocations([])
      } else {
        //check for dob that is not null and format to date obj - if 00001 year, set dob to null
        _storedAllocations.forEach((allocation) => {
          if (allocation?.dob && allocation?.dob?.slice(0, 4) !== "0001") {
            allocation.dob = formatUTCDateToObjWithTimeZone(allocation.dob)
          } else if (
            allocation?.dob &&
            allocation?.dob?.slice(0, 4) === "0001"
          ) {
            allocation.dob = null
          }
        })
        // console.log("stored allocations", _storedAllocations)
        setStoredAllocations(_storedAllocations)
      }
    } catch (error) {
      console.error("error grabbing allocations for member side", error)
    }
  }

  const getPotentialBeneficiariesForMember = async () => {
    try {
      let _potentials =
        await enrollmentService.getPotentialBeneficiariesForMember(
          props?.member?.id
        )
      if (!_potentials || _potentials.length === 0) {
        setPotentialBeneficiaries([])
      } else {
        //check for dob that is not null and format to date obj - if 00001 year, set dob to null
        _potentials?.forEach((potentialBenny) => {
          if (
            potentialBenny?.dob &&
            potentialBenny?.dob?.slice(0, 4) !== "0001"
          ) {
            potentialBenny.dob = formatUTCDateToObjWithTimeZone(
              potentialBenny.dob
            )
          } else if (
            potentialBenny?.dob &&
            potentialBenny?.dob?.slice(0, 4) === "0001"
          ) {
            potentialBenny.dob = null
          }
        })
        // console.log("potentials", _potentials)
        setPotentialBeneficiaries(_potentials)
      }
    } catch (error) {
      console.log("error getting potential bennies", error)
    }
  }

  useEffect(() => {
    if (props.member && props?.plan?.planId) {
      getAllocations()
      getPotentialBeneficiariesForMember()
    }
  }, [props.member, props?.member?.id, props?.plan])

  useEffect(() => {
    if (storedAllocations && storedAllocations.length > 0) {
      //separating primary bennies
      let _primaryBennies = storedAllocations?.filter(
        (allocation) => allocation.isPrimary && !allocation.isDeleted
      )

      //separating contingent bennies
      let _contingentBennies = storedAllocations?.filter(
        (allocation) => !allocation.isPrimary && !allocation.isDeleted
      )
      setPrimaryBennies(_primaryBennies)
      setContingentBennies(_contingentBennies)
    }
  }, [storedAllocations])

  //ADDING new beneficiaries when even-split button is on: overall allocations are updated w/ new bennies added, update primary and contingent bennies amount allocated evenly
  //need to run this when switch is on

  //show if even split is on or off
  const [evenSplitPrimary, setEvenSplitPrimary] = useState(true)
  const [evenSplitContingent, setEvenSplitContingent] = useState(true)

  const handleEvenSplitOnAddBeneficiary = () => {
    //primary benny reallocation
    if (primaryBennies?.length > 0 && evenSplitPrimary) {
      let indexOfZeroBenny
      let bennyWithZeroAmount = primaryBennies?.find((benny, index) => {
        indexOfZeroBenny = index
        return benny.amount === 0
      })

      if (bennyWithZeroAmount) {
        //find new even allocation amount for benny with 0 amount
        let total = 100
        let _evenSplitAmount = Math.floor(total / primaryBennies?.length)
        //update the rest of the bennies taking into account this new amount
        handleAllocationChange(
          _evenSplitAmount,
          bennyWithZeroAmount,
          indexOfZeroBenny
        )
      }
    }
    //contingent benny reallocation
    if (contingentBennies?.length > 0 && evenSplitContingent) {
      let indexOfZeroBenny
      let bennyWithZeroAmount = contingentBennies?.find((benny, index) => {
        indexOfZeroBenny = index
        return benny.amount === 0
      })

      if (bennyWithZeroAmount) {
        //find new even allocation amount for benny with 0 amount
        let total = 100
        let _evenSplitAmount = Math.floor(total / contingentBennies?.length)
        //update the rest of the bennies taking into account this new amount
        handleAllocationChange(
          _evenSplitAmount,
          bennyWithZeroAmount,
          indexOfZeroBenny
        )
      }
    }
  }

  //check allocation totals on blur for each input
  const checkAllocationTotals = () => {
    // check totals of primary and contingent to make sure each add up to 100
    let _primaryTotal = primaryBennies.reduce((accumulator, currentValue) => {
      return accumulator + currentValue?.amount
    }, 0)
    let _contingentTotal = contingentBennies.reduce(
      (accumulator, currentValue) => {
        return accumulator + currentValue?.amount
      },
      0
    )

    if (
      primaryBennies?.length > 0 &&
      contingentBennies?.length > 0 &&
      _primaryTotal === 100 &&
      _contingentTotal === 100
    ) {
      //if both primariees and contingents exist, each must total 100
      setTotalAllocationError(false)
      //if only primaries exist, they must total 100
    } else if (
      primaryBennies?.length > 0 &&
      contingentBennies?.length === 0 &&
      _primaryTotal === 100
    ) {
      setTotalAllocationError(false)
      //if only contingents exist, they must total 100
    } else if (
      primaryBennies?.length === 0 &&
      contingentBennies?.length > 0 &&
      _contingentTotal === 100
    ) {
      setTotalAllocationError(false)
    } else {
      setTotalAllocationError({
        primaryTotal: _primaryTotal,
        contingentTotal: _contingentTotal,
      })
    }
  }

  const saveBeneficiaryChanges = async () => {
    setLoading(true)
    //only update if benny info besides allocations was changed
    if (recentlyUpdatedBennies?.length > 0) {
      try {
        recentlyUpdatedBennies.forEach(async (benny) => {
          await enrollmentService.updateBeneficiaryForMember(
            benny.memberId,
            benny
          )
        })
      } catch (error) {
        console.error("error updating bennies", error)
      }
    }

    //  only update if allocations were changed
    if (isAllocationUpdated && !totalAllocationError) {
      await updateAllocationsInDb()
    }

    //reset allocation update state after save
    setIsAllocationUpdated(false)

    //reset loading and edit beneficiary states
    setLoading(false)
    props?.setEdit(false)
  }

  //BENNY UPDAT
  const getRelationshipTypeFromId = (id) => {
    let relationshipType = props?.relationshipTypes.find(
      (type) => type.id === id
    )
    return relationshipType?.name
  }

  //states to keep track of beneficiaries updated
  const [recentlyUpdatedBennies, setRecentlyUpdatedBennies] = useState([]) //updates to name, dob, relationship type... future use
  const [isAllocationUpdated, setIsAllocationUpdated] = useState(false) //updates to allocations

  //Check for recently updated bennies
  const checkRecentBeneficiaryUpdates = (beneficiary, property, val) => {
    //add updated benny to recently updated array if it doesn't exist or update it if alreay in array

    if (recentlyUpdatedBennies.some((benny) => benny.id === beneficiary.id)) {
      let updatedArray = recentlyUpdatedBennies.map((item) =>
        item.id === beneficiary.id ? { ...item, [property]: val } : item
      )

      setRecentlyUpdatedBennies(updatedArray)
    } else {
      let updatedBenny = { ...beneficiary, [property]: val }

      setRecentlyUpdatedBennies([...recentlyUpdatedBennies, updatedBenny])
    }
  }
  //UPDATE NAME AND DOB - can use this to update these properties - input fields must be commented back in
  const handleBeneficiaryInputChange = (e, property, beneficiary) => {
    let val = e.target.value

    //update primary beneficiary OR contingent beneficiary
    if (beneficiary.isPrimary) {
      setPrimaryBennies([
        ...primaryBennies.map((benny) =>
          benny.id === beneficiary.id
            ? Object.assign(benny, { [property]: val })
            : benny
        ),
      ])
    } else {
      setContingentBennies([
        ...contingentBennies.map((benny) =>
          benny.id === beneficiary.id
            ? Object.assign(benny, { [property]: val })
            : benny
        ),
      ])
    }
    //add updated benny to recently updated array if it doesn't exist or update it if alreay in array
    checkRecentBeneficiaryUpdates(beneficiary, property, val)
  }

  //UPDATE ALLOCATION
  const handleAllocationChange = (e, beneficiary, i) => {
    setIsAllocationUpdated(true)
    let total = 100
    //check if e is a number equal to 0 (will get this from delete) othersiwse get value from e.target.value
    // let val = e === 0 ? 0 : Number(e.target.value);
    let val
    //check if e is an event or a number and assign value accordingly
    if (e?.target?.value) {
      val = Number(e.target.value)
    } else {
      val = e
    }

    let unhandledAmount = total - val

    let primaryBenniesRemaining = primaryBennies?.length - 1
    let contingentBenniesRemaining = contingentBennies?.length - 1

    // UPDATE PRIMARY beneficiary
    if (beneficiary.isPrimary) {
      // check that value is not greater than 100 or less than 0
      if (val <= 100 && val >= 0) {
        //update current primary beneficiary w/ new value
        setPrimaryBennies((prevPrimaryBennies) =>
          prevPrimaryBennies.map((benny) =>
            benny.id === beneficiary.id ? { ...benny, amount: val } : benny
          )
        )
      }
      //IF evenSplit = true, check if there are other beneficiaries to update w/ new remaining amount if unhandled amount > 0
      if (evenSplitPrimary) {
        if (primaryBenniesRemaining > 0 && unhandledAmount >= 0) {
          //update other primary beneficiaries w/ new value
          let evenSplitAmount = Math.floor(
            Math.abs(unhandledAmount) / primaryBenniesRemaining
          )
          let remainder = unhandledAmount % primaryBenniesRemaining

          //add remainder if greater than 0
          setPrimaryBennies((prePrimaryBennies) =>
            prePrimaryBennies.map((benny, index) => {
              if (index !== i) {
                // Check if the last beneficiary needs to receive the remainder
                if (index === primaryBenniesRemaining) {
                  return { ...benny, amount: evenSplitAmount + remainder }
                } else {
                  return { ...benny, amount: evenSplitAmount }
                }
              } else {
                return benny // Return the unchanged beneficiary
              }
            })
          )
        }
      }
    }

    //UPDATE CONTINGENT beneficiary
    if (!beneficiary.isPrimary) {
      // check that value is not greater than 100 or less than 0
      if (val <= 100 && val >= 0) {
        //update current primary beneficiary w/ new value
        setContingentBennies((prevContBennies) =>
          prevContBennies.map((benny) =>
            benny.id === beneficiary.id ? { ...benny, amount: val } : benny
          )
        )
      }
      //IF evenSplit = true, check if there are other beneficiaries to update w/ new remaining amount if unhandled amount > 0
      if (evenSplitContingent) {
        if (contingentBenniesRemaining > 0 && unhandledAmount >= 0) {
          //update other primary beneficiaries w/ new value
          let evenSplitAmount = Math.floor(
            Math.abs(unhandledAmount) / contingentBenniesRemaining
          )
          let remainder = unhandledAmount % contingentBenniesRemaining

          //add remainder if greater than 0
          setContingentBennies((prevContBennies) =>
            prevContBennies.map((benny, index) => {
              if (index !== i) {
                // Check if the last beneficiary needs to receive the remainder
                if (index === contingentBenniesRemaining) {
                  return { ...benny, amount: evenSplitAmount + remainder }
                } else {
                  return { ...benny, amount: evenSplitAmount }
                }
              } else {
                return benny // Return the unchanged beneficiary
              }
            })
          )
        }
      }
    }
  }

  //Update allocations in db
  const updateAllocationsInDb = async () => {
    let allAllocations = [...primaryBennies, ...contingentBennies]
    // Create new benny objects to fufill data requirements for back updating allocations
    let allocationsWithBennyIds = allAllocations.map((object) => {
      const {
        id,
        memberId,
        planId,
        amount,
        isPrimary,
        isDeleted,
        lastModifiedBy,
      } = object // Destructure 'id' from the object

      return {
        BeneficiaryId: id,
        planId,
        amount,
        isPrimary,
        isDeleted,
        memberId,
        lastModifiedBy,
      }
    })

    try {
      let results = await enrollmentService.saveBeneficiaryAllocationForMember(
        allocationsWithBennyIds,
        props?.member.id
      )
    } catch (error) {
      console.error("error updating allocations for beneficiaries", error)
    }
  }

  //Edit Existing Beneficiary DOB
  const handleBeneficiaryDob = (e, beneficiary) => {
    let _dob = e
    //update stored allocations benny w/ new dob
    setStoredAllocations((prevStoredAllocations) => {
      return prevStoredAllocations.map((benny) =>
        benny.id === beneficiary.id ? { ...benny, dob: _dob } : benny
      )
    })
    //add updated benny to recently updated array if it doesn't exist or update it if alreay in array
    checkRecentBeneficiaryUpdates(beneficiary, "dob", _dob)
  }

  //ADD NEW BENEFICIARY LOGIC
  const [addPrimaryBenny, setAddPrimaryBenny] = useState(false)
  const [addContingentBenny, setAddContingentBenny] = useState(false)
  const [newPrimaryBenny, setNewPrimaryBenny] = useState({})
  const [newContingentBenny, setNewContingentBenny] = useState({})

  const handleAddBeneficiaryInputChange = (e, property, type) => {
    let val = e.target.value

    if (type === "primary") {
      setNewPrimaryBenny({ ...newPrimaryBenny, [property]: val })
    }

    if (type === "contingent") {
      setNewContingentBenny({ ...newContingentBenny, [property]: val })
    }
  }

  const handleAddBeneficiaryRelationshipChange = (e, type) => {
    let val = e.target.value
    if (type === "primary") {
      setNewPrimaryBenny({ ...newPrimaryBenny, relationshipTypeId: val })
    }

    if (type === "contingent") {
      setNewContingentBenny({ ...newContingentBenny, relationshipTypeId: val })
    }
  }

  const handleAddBeneficiaryDob = (e, type) => {
    let _dob = e
    if (type === "primary") {
      setNewPrimaryBenny({ ...newPrimaryBenny, dob: _dob })
    }

    if (type === "contingent") {
      setNewContingentBenny({ ...newContingentBenny, dob: _dob })
    }
  }

  const handleAddDependentAsBenny = (selection) => {
    setNewPrimaryBenny({
      ...newPrimaryBenny,
      firstName: selection.firstName,
      lastName: selection.lastName,
      dob: selection.dob,
      relationshipTypeId: selection?.relationshipTypeId || "",
    })
  }

  const saveNewBeneficiary = async () => {
    //current total allocations before adding the new benny
    let allCurrentAllocations = [...primaryBennies, ...contingentBennies]
    let currentAllocationsWithBennyIds = allCurrentAllocations.map((object) => {
      const {
        id,
        memberId,
        planId,
        amount,
        isPrimary,
        isDeleted,
        lastModifiedBy,
        dob,
      } = object // Destructure 'id' from the object

      return {
        BeneficiaryId: id,
        planId,
        amount,
        isPrimary,
        isDeleted,
        memberId,
        lastModifiedBy,
        dob,
      }
    })

    //add new primary beneficiary to db
    if (addPrimaryBenny) {
      let _newPrimaryBennyObj = {
        ...newPrimaryBenny,
        middleInitial: "",
        planId: props?.plan?.planId,
        isPrimary: true,
        isDeleted: false,
        memberId: props?.member.id,
        amount: 0,
      }

      try {
        let newBennyInDb = await enrollmentService.addBeneficiaryForMember(
          props?.member.id,
          _newPrimaryBennyObj
        )

        //if new benny added suscessfully, create new benny allocation obj and add it to current benny allocations array to update allocation in db
        if (newBennyInDb.id) {
          //if there are no primary bennies, set new benny amount to 100, otherwise set to 0
          let newBennyAmount = primaryBennies.length > 0 ? 0 : 100
          let newBennyAllocation = {
            BeneficiaryId: newBennyInDb.id,
            planId: props?.plan?.planId,
            amount: newBennyAmount,
            isPrimary: true,
            isDeleted: false,
            memberId: newBennyInDb.memberId,
          }

          try {
            await enrollmentService.saveBeneficiaryAllocationForMember(
              [...currentAllocationsWithBennyIds, newBennyAllocation],
              props?.member.id
            )
          } catch (error) {
            console.error(
              "error updating allocations for new primary beneficiary",
              error
            )
          }
        }
      } catch (error) {
        console.error("error adding new primary beneficiary", error)
      }
    }
    //add new contingent beneficiary to db
    if (addContingentBenny) {
      let _newContingentBennyObj = {
        ...newContingentBenny,
        middleInitial: "",
        planId: props?.plan?.planId,
        isPrimary: false,
        isDeleted: false,
        memberId: props?.member.id,
        amount: 0,
      }

      try {
        let newBennyInDb = await enrollmentService.addBeneficiaryForMember(
          props?.member.id,
          _newContingentBennyObj
        )

        //if new benny added suscessfully, create new contingent benny allocation obj and add it to current benny allocations array to update allocation in db
        if (newBennyInDb.id) {
          //if there are no primary bennies, set new benny amount to 100, otherwise set to 0
          let newBennyAmount = contingentBennies.length > 0 ? 0 : 100
          let newBennyAllocation = {
            BeneficiaryId: newBennyInDb.id,
            planId: props?.plan?.planId,
            amount: newBennyAmount,
            isPrimary: false,
            isDeleted: false,
            memberId: newBennyInDb.memberId,
          }

          try {
            await enrollmentService.saveBeneficiaryAllocationForMember(
              [...currentAllocationsWithBennyIds, newBennyAllocation],
              props?.member.id
            )
          } catch (error) {
            console.error(
              "error updating allocations for new contingent beneficiary",
              error
            )
          }
        }
      } catch (error) {
        console.error("error adding new primary beneficiary", error)
      }
    }

    getAllocations()
    //close and clear add benny form
    if (addPrimaryBenny) {
      setAddPrimaryBenny(false)
      setNewPrimaryBenny({})
    }
    if (addContingentBenny) {
      setAddContingentBenny(false)
      setNewContingentBenny({})
    }
  }

  //DELETE BENEFICIARY LOGIC

  const [openConfirmDelete, setOpenConfirmDelete] = useState(false)
  const [bennyToDelete, setBennyToDelete] = useState({})

  const openDeleteModal = (e, beneficiary, i) => {
    setIsDeleted(false)
    console.log("index of delete benny", i)
    setOpenConfirmDelete(true)
    setBennyToDelete({ ...beneficiary, index: i })
  }
  const closeDeleteModal = () => {
    setOpenConfirmDelete(false)
    setBennyToDelete({})
  }

  // console.log("benny to delete", bennyToDelete);

  const [isDeleted, setIsDeleted] = useState(false)
  //confirm delete benny and update db
  const handleDeleteBenny = async () => {
    console.log("benny to delete after hidding confirm", bennyToDelete)
    setIsDeleted(bennyToDelete)
    //update local state allocations w/ deleted benny allocations = 0
    handleAllocationChange(0, bennyToDelete, bennyToDelete?.index)
    //delete them from db
    try {
      console.log("deleting benny", bennyToDelete)

      await enrollmentService.deleteBeneficiaryByMemberId(
        props?.member.id,
        bennyToDelete?.id
      )
    } catch (error) {
      console.log("error deleting beneficiary", error)
    }

    getAllocations()
    closeDeleteModal()
  }

  //function to update all other allocations in db minus the recently deleted benny (called in useEffect below)
  //still struggling w/ updating to current allocations (need a page reload to see changes - but it is working!)
  const updateCurrentAllocationsAfterDelete = async () => {
    let allAllocations = [...primaryBennies, ...contingentBennies]

    // Create new benny objects to fufill data requirements for back updating allocations
    let allocationsWithBennyIds = allAllocations.map((object) => {
      const {
        id,
        memberId,
        planId,
        amount,
        isPrimary,
        isDeleted,
        lastModifiedBy,
      } = object // Destructure 'id' from the object

      if (object.id !== isDeleted.id) {
        return {
          BeneficiaryId: id,
          planId,
          amount,
          isPrimary,
          isDeleted,
          memberId,
          lastModifiedBy,
        }
      }
    })

    try {
      enrollmentService.saveBeneficiaryAllocationForMember(
        allocationsWithBennyIds,
        props?.member.id
      )
    } catch (error) {
      console.error("error updating allocations for beneficiaries", error)
    }
    setIsDeleted(false)
    getAllocations()
  }

  //update allocations IF beneficiary is deleted
  useEffect(() => {
    if (isDeleted) {
      console.log("is deleted is true", isDeleted)

      updateCurrentAllocationsAfterDelete()
    }
  }, [isDeleted])

  //DOB error handling
  ////WANT TO REFACTOR THIS STUFF
  let today = new Date()
  let day = today.getDate()
  let month = today.getMonth() + 1
  let year = today.getFullYear()
  day = day < 10 ? "0" + day : day
  month = month < 10 ? "0" + month : month
  let dateMax = `${year - 0}-${month}-${day}`
  let dateMaxObj = new Date(dateMax)
  let dateMin = `${year - 100}-${month}-${day}`
  let dateMinObj = new Date(dateMin)

  console.log(dateMin, "max", dateMax)

  const [dobError, setDobError] = useState(false)

  const dobErrorMessage = useMemo(() => {
    switch (dobError) {
      case "minDate": {
        return "Must be less than 100"
      }
      case "disableFuture": {
        return "Must be greater than 0"
      }
      case "invalidDate": {
        return "Invalid date"
      }
      default: {
        return ""
      }
    }
  }, [dobError])

  //set error message for EXISTING primary or contingent benny
  const setDobErrorInExistingBeneficiaries = (benny, error) => {
    let _allExistingBeneficiaries = [...storedAllocations]

    //find benny in storedAllocations that matches benny.id and update dobError
    let _updatedExistingBeneficiaries = _allExistingBeneficiaries.map((b) => {
      if (b.id === benny.id) {
        return { ...b, dobError: !!(error && error.length > 0) }
      } else return b
    })

    //update stored allocations
    setStoredAllocations(_updatedExistingBeneficiaries)
    setDobError(error)
  }

  //set error message for NEW primary or contingent benny
  const setDobErrorMessageNewBeneficiary = (benny, type, error) => {
    if (type === "primary") {
      //update new primary benny
      setNewPrimaryBenny({
        ...newPrimaryBenny,
        dobError: !!(error && error.length > 0),
      })
    } else if (type === "contingent") {
      //update new contingent benny
      setNewContingentBenny({
        ...newContingentBenny,
        dobError: !!(error && error.length > 0),
      })
    }

    //set message to display as helper text
    setDobError(error)
  }

  //Cancel edit and/or add beneficiary
  const cancelEditOrAdd = () => {
    props?.setEdit(false)
    setIsAllocationUpdated(false)
    setRecentlyUpdatedBennies([])
    setAddContingentBenny(false)
    setAddPrimaryBenny(false)
    setTotalAllocationError(false)
    //refetch db allocations
    getAllocations()
  }

  return (
    <Box sx={componentStyles.componentContainer}>
      {/* PRIMARY BENEFICIARY */}
      <Box sx={componentStyles.bennyGroupRow}>
        <Box sx={componentStyles.bennyTypeTitleLeftSide(props?.edit)}>
          <Typography variant="body1">Primary Beneficiary</Typography>

          {props?.edit && primaryBennies?.length > 0 && (
            <FormGroup sx={{ marginLeft: "10px" }}>
              <FormControlLabel
                control={
                  <Switch
                    checked={evenSplitPrimary}
                    onChange={() => setEvenSplitPrimary(!evenSplitPrimary)}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                }
                label="Evenly split allocations"
              />
            </FormGroup>
          )}
        </Box>

        {!props?.edit && (
          <Box sx={componentStyles.bennyGroupColumnRightSide}>
            {primaryBennies?.map((benny) => (
              <Typography
                variant="body1"
                key={benny.id}
                sx={componentStyles.singleBennyViewRow}
              >
                {benny?.amount}% {benny?.firstName} {benny?.lastName} |{" "}
                {props.relationshipTypes &&
                  getRelationshipTypeFromId(benny?.relationshipTypeId)}{" "}
                {benny?.dob
                  ? `| ${formatDateObjMMDDYYYYDisplay(benny?.dob)}`
                  : ""}
              </Typography>
            ))}
          </Box>
        )}
      </Box>

      {/* Edit Primary Beneficiary    */}
      <Box sx={componentStyles.editBeneficiaryContainer}>
        {props?.edit &&
          primaryBennies.map((benny, i) => (
            <Box sx={componentStyles.editBeneficiaryRow} key={benny.id}>
              {/* Display Primary Name/Relationship */}
              <Box sx={componentStyles.nameRelationshipOnEditRow}>
                <Typography
                  variant="body1"
                  sx={componentStyles.editViewBennyNameText}
                >
                  {benny.firstName} {benny.lastName}
                </Typography>
                <Typography
                  variant="body1"
                  sx={componentStyles.verticalLineTextSeparator}
                >
                  |
                </Typography>
                <Typography
                  variant="body1"
                  sx={componentStyles.editViewRelationshipText}
                >
                  {
                    props.relationshipTypes.find(
                      (type) => type.id === benny.relationshipTypeId
                    )?.name
                  }
                </Typography>
              </Box>

              {/* editable fields for primary benny - dob and allocation */}
              <Box sx={componentStyles.editableFieldsRow}>
                {/* Edit primary benny DOB */}
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    disableFuture
                    label="Date of Birth"
                    openTo="year"
                    views={["year", "month", "day"]}
                    format="MM/dd/yyyy"
                    minDate={dateMinObj}
                    maxDate={dateMaxObj}
                    value={benny?.dob || null}
                    onChange={(e) => handleBeneficiaryDob(e, benny)}
                    onError={(error) =>
                      setDobErrorInExistingBeneficiaries(benny, error)
                    }
                    sx={componentStyles.customDatePicker("editExisting")}
                    slotProps={{
                      textField: {
                        helperText: benny?.dobError ? dobErrorMessage : "",
                        size: "small",
                      },
                      openPickerButton: { sx: { display: "none" } }, //hide calendar icon calandar
                    }}
                  />
                </LocalizationProvider>

                {/* Edit Primary Allocation */}
                <TextField
                  size="small"
                  sx={componentStyles.allocationInputStyles.textField}
                  label="Allocation"
                  variant="outlined"
                  value={Number(benny?.amount) || 0}
                  onChange={(e) => handleAllocationChange(e, benny, i)}
                  onBlur={() => checkAllocationTotals()}
                  InputProps={{
                    sx: componentStyles.allocationInputStyles.inputPropsStyles,
                    endAdornment: (
                      <InputAdornment
                        sx={
                          componentStyles.allocationInputStyles.inputAdornment
                        }
                        position="end"
                      >
                        %
                      </InputAdornment>
                    ),
                  }}
                  inputProps={{
                    max: "100",
                    min: "0",
                    type: "number",
                  }}
                  onFocus={(e) => e.target.select()}
                />

                {/* input fields w/ corresponding methods to update primary beneficiary name, dob, relationship, and delete benny */}
                {/* <TextField
                size="small"
                sx={{ marginRight: "5px", flex: 1 }}
                label="First Name"
                variant="outlined"
                value={benny?.firstName || ""}
                onChange={(e) =>
                  handleBeneficiaryInputChange(e, "firstName", benny)
                }
                inputProps={{
                  "data-update": "firstNamePrimary",
                }}
              />

              <TextField
                size="small"
                sx={{ marginRight: "5px", flex: 1 }}
                label="Last Name"
                variant="outlined"
                value={benny?.lastName || ""}
                onChange={(e) =>
                  handleBeneficiaryInputChange(e, "lastName", benny)
                }
                inputProps={{
                  "data-update": "lastNamePrimary",
                }}
              />

              <TextField
                // disabled
                size="small"
                sx={{ flex: 1 }}
                label="Birthdate"
                variant="outlined"
                type={benny.dob ? "date" : ""}
                value={benny?.dob || ""}
                onChange={(e) => handleBeneficiaryInputChange(e, "dob", benny)}
                inputProps={{
                  "data-update": "dob",
                }}
              />

              <TextField
                disabled
                select
                size="small"
                sx={{ flex: 1 }}
                label="Relationship"
                variant="outlined"
                value={benny?.relationshipTypeId || ""}
                onChange={(e) => handleRelationshipChange(e, benny)}
              >
                {props?.relationshipTypes &&
                  props?.relationshipTypes.map((relationshipType) => (
                    <MenuItem
                      key={relationshipType?.id}
                      value={relationshipType?.id}
                    >
                      {relationshipType?.name}
                    </MenuItem>
                  ))}
              </TextField> */}
                {/* 
              Delete Beneficiary */}
                {/* <IconButton onClick={(e) => openDeleteModal(e, benny, i)}>
                <DeleteIcon sx={{ height: "20px" }} />
              </IconButton> */}
              </Box>
            </Box>
          ))}

        {/* ADD NEW PRIMARY BENEFICIARY */}

        {props?.edit && !addPrimaryBenny && (
          <Button
            sx={componentStyles.addAdditionalButton}
            onClick={() => {
              setAddPrimaryBenny((prevVal) => !prevVal)
              //close add contingent benny if open
              setAddContingentBenny(false)
            }}
          >
            + Add additional primary beneficiary
          </Button>
        )}

        {addPrimaryBenny && (
          <Box sx={componentStyles.addBeneficiaryContainer}>
            <Typography sx={{ fontWeight: 500 }}>
              {" "}
              Add additional primary beneficiary{" "}
            </Typography>

            {/* Potential Beneficiaries to select */}
            <Box sx={componentStyles.potentialBeneficiaryRow}>
              {potentialBeneficiaries?.length > 0 &&
                potentialBeneficiaries?.map((potentialBen, i) => {
                  //map and compare current beneficiaries to potentials by first AND last name - only return potentials that are NOT already beneficiaries and who have a first and last name
                  const isCurrentBenny = primaryBennies?.find(
                    (benny) =>
                      benny?.firstName?.toLowerCase() ===
                        potentialBen?.firstName?.toLowerCase() &&
                      benny?.lastName?.toLowerCase() ===
                        potentialBen?.lastName?.toLowerCase()
                  )
                  if (
                    !isCurrentBenny &&
                    potentialBen?.firstName &&
                    potentialBen?.lastName
                  ) {
                    let firstName = potentialBen?.firstName
                      ? potentialBen?.firstName
                      : ""
                    let lastName = potentialBen?.lastName
                      ? potentialBen?.lastName
                      : ""
                    let relationship = potentialBen?.relationshipTypeId
                      ? getRelationshipTypeFromId(
                          potentialBen?.relationshipTypeId
                        )
                      : "Dependent"
                    return (
                      <IconButton
                        sx={{ margin: 0, padding: 0 }}
                        onClick={(e) => handleAddDependentAsBenny(potentialBen)}
                      >
                        <Chip
                          key={potentialBen?.id}
                          icon={
                            <AddCircleOutlineIcon
                              sx={{ color: `#195ffb!important` }}
                            />
                          }
                          sx={componentStyles.beneficiaryChip}
                          label={`${firstName} ${lastName} | ${relationship}`}
                        />
                      </IconButton>
                    )
                  }
                })}
            </Box>

            {/* Add new Form */}
            <TextField
              fullWidth
              size="small"
              label="First Name"
              variant="outlined"
              sx={componentStyles.addBeneficiaryInputs}
              value={newPrimaryBenny?.firstName || ""}
              onChange={(e) =>
                handleAddBeneficiaryInputChange(e, "firstName", "primary")
              }
            />

            <TextField
              fullWidth
              size="small"
              label="Last Name"
              variant="outlined"
              sx={componentStyles.addBeneficiaryInputs}
              value={newPrimaryBenny?.lastName || ""}
              onChange={(e) =>
                handleAddBeneficiaryInputChange(e, "lastName", "primary")
              }
              // InputProps={{
              //   sx: componentStyles.inputShape
              // }}
            />

            <TextField
              fullWidth
              size="small"
              select
              sx={componentStyles.addBeneficiaryInputs}
              variant="outlined"
              label="Relationship"
              value={newPrimaryBenny?.relationshipTypeId || ""}
              onChange={(e) =>
                handleAddBeneficiaryRelationshipChange(e, "primary")
              }
            >
              {props.relationshipTypes &&
                props.relationshipTypes.map((relationshipType) => (
                  <MenuItem
                    key={relationshipType.id}
                    value={relationshipType.id}
                  >
                    {relationshipType.name}
                  </MenuItem>
                ))}
            </TextField>

            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                disableFuture
                label="Date of Birth"
                openTo="year"
                views={["year", "month", "day"]}
                format="MM/dd/yyyy"
                minDate={dateMinObj}
                maxDate={dateMaxObj}
                value={newPrimaryBenny?.dob || null}
                onChange={(e) => handleAddBeneficiaryDob(e, "primary")}
                onError={(error) =>
                  setDobErrorMessageNewBeneficiary(
                    newPrimaryBenny,
                    "primary",
                    error
                  )
                }
                sx={componentStyles.customDatePicker(false)}
                slotProps={{
                  textField: {
                    helperText: newPrimaryBenny?.dobError
                      ? dobErrorMessage
                      : "",
                    size: "small",
                  },
                  openPickerButton: { sx: { display: "none" } }, //hide calendar icon calandar
                }}
              />
            </LocalizationProvider>

            <Box
              className="flex-row"
              sx={{
                textAlign: "right",
                margin: "10px 0px",
                justifyContent: "flex-end",
              }}
            >
              <Button
                sx={componentStyles.cancelOrAddBeneficiaryButtons}
                onClick={() => {
                  setAddPrimaryBenny((prevVal) => !prevVal)
                  setNewPrimaryBenny({})
                }}
              >
                CANCEL
              </Button>
              <Button
                variant="contained"
                sx={componentStyles.cancelOrAddBeneficiaryButtons}
                onClick={saveNewBeneficiary}
              >
                ADD
              </Button>
            </Box>
          </Box>
        )}
      </Box>

      {/* CONTINGENT BENEFICIARY */}

      <Box sx={componentStyles.bennyGroupRow}>
        <Box sx={componentStyles.bennyTypeTitleLeftSide(props?.edit)}>
          <Typography variant="body1">Contingent Beneficiary</Typography>

          {props?.edit && contingentBennies?.length > 0 && (
            <FormGroup sx={{ marginLeft: "10px" }}>
              <FormControlLabel
                control={
                  <Switch
                    checked={evenSplitContingent}
                    onChange={() =>
                      setEvenSplitContingent(!evenSplitContingent)
                    }
                    inputProps={{ "aria-label": "controlled" }}
                  />
                }
                label="Evenly split allocations"
              />
            </FormGroup>
          )}
        </Box>

        {!props?.edit && (
          <Box sx={componentStyles.bennyGroupColumnRightSide}>
            {contingentBennies?.map((benny) => (
              <Typography
                variant="body1"
                key={benny.id}
                sx={componentStyles.singleBennyViewRow}
              >
                {benny?.amount}% {benny?.firstName} {benny?.lastName} |{" "}
                {props.relationshipTypes &&
                  getRelationshipTypeFromId(benny?.relationshipTypeId)}{" "}
                {benny?.dob
                  ? `| ${formatDateObjMMDDYYYYDisplay(benny?.dob)}`
                  : ""}
              </Typography>
            ))}
          </Box>
        )}
      </Box>

      {/* Edit Contingent Beneficiary    */}
      <Box sx={componentStyles.editContingentAndDisplayAlertRow}>
        <Box sx={componentStyles.editBeneficiaryContainer}>
          {props?.edit &&
            contingentBennies.map((benny, i) => (
              <Box sx={componentStyles.editBeneficiaryRow} key={benny.id}>
                {/* Display Contingent Name/Relationship */}
                <Box sx={componentStyles.nameRelationshipOnEditRow}>
                  <Typography
                    variant="body1"
                    sx={componentStyles.editViewBennyNameText}
                  >
                    {benny.firstName} {benny.lastName}
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={componentStyles.verticalLineTextSeparator}
                  >
                    |
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={componentStyles.editViewRelationshipText}
                  >
                    {
                      props.relationshipTypes.find(
                        (type) => type.id === benny.relationshipTypeId
                      )?.name
                    }
                  </Typography>
                </Box>

                {/* editable fields for contingent benny - dob and allocation */}
                <Box sx={componentStyles.editableFieldsRow}>
                  {/* Edit contingent benny DOB */}
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      disableFuture
                      label="Date of Birth"
                      shrink={true}
                      openTo="year"
                      views={["year", "month", "day"]}
                      format="MM/dd/yyyy"
                      minDate={dateMinObj}
                      maxDate={dateMaxObj}
                      value={benny?.dob || null}
                      onChange={(e) => handleBeneficiaryDob(e, benny)}
                      onError={(error) =>
                        setDobErrorInExistingBeneficiaries(benny, error)
                      }
                      sx={componentStyles.customDatePicker("editExisting")}
                      slotProps={{
                        textField: {
                          helperText: benny?.dobError ? dobErrorMessage : "",
                          size: "small",
                        },
                        openPickerButton: { sx: { display: "none" } }, //hide calendar icon calandar
                      }}
                    />
                  </LocalizationProvider>

                  {/* Edit Contingent Allocation */}
                  <TextField
                    size="small"
                    sx={componentStyles.allocationInputStyles.textField}
                    label="Allocation"
                    variant="outlined"
                    value={Number(benny?.amount) || 0}
                    onChange={(e) => handleAllocationChange(e, benny, i)}
                    onBlur={() => checkAllocationTotals()}
                    InputProps={{
                      sx: componentStyles.allocationInputStyles
                        .inputPropsStyles,
                      endAdornment: (
                        <InputAdornment
                          sx={
                            componentStyles.allocationInputStyles.inputAdornment
                          }
                          position="end"
                        >
                          %
                        </InputAdornment>
                      ),
                    }}
                    inputProps={{
                      max: "100",
                      min: "0",
                      type: "number",
                    }}
                    onFocus={(e) => e.target.select()}
                  />

                  {/* input fields w/ corresponding methods to update contingent beneficiary name, dob, relationship, and delete benny */}
                  {/* <TextField
                size="small"
                sx={{ marginRight: "5px", flex: 1 }}
                label="First Name"
                variant="outlined"
                value={benny?.firstName || ""}
                onChange={(e) =>
                  handleBeneficiaryInputChange(e, "firstName", benny)
                }
              />

              <TextField
                size="small"
                sx={{ marginRight: "5px", flex: 1 }}
                label="Last Name"
                variant="outlined"
                value={benny?.lastName || ""}
                onChange={(e) =>
                  handleBeneficiaryInputChange(e, "lastName", benny)
                }
              />

              <TextField
                // disabled
                size="small"
                sx={{ flex: 1 }}
                label="Birthdate"
                variant="outlined"
                type={benny.dob ? "date" : ""}
                value={benny?.dob || ""}
                onChange={(e) => handleBeneficiaryInputChange(e, "dob", benny)}
              />

              <TextField
                disabled
                select
                size="small"
                sx={{ flex: 1 }}
                label="Relationship"
                variant="outlined"
                value={benny?.relationshipTypeId || ""}
                onChange={(e) => handleRelationshipChange(e, benny)}
              >
                {props?.relationshipTypes &&
                  props?.relationshipTypes.map((relationshipType) => (
                    <MenuItem
                      key={relationshipType?.id}
                      value={relationshipType?.id}
                    >
                      {relationshipType?.name}
                    </MenuItem>
                  ))}
              </TextField> */}

                  {/* Delete Beneficiary Button */}
                  {/* <IconButton onClick={(e) => openDeleteModal(e, benny, i)}>
                <DeleteIcon sx={{ height: "20px" }} />
              </IconButton> */}
                </Box>
              </Box>
            ))}

          {/* ADD A NEW CONTINGENT BENEFICIARY */}

          {props?.edit && !addContingentBenny && (
            <Button
              sx={componentStyles.addAdditionalButton}
              onClick={() => {
                setAddContingentBenny((prevVal) => !prevVal)
                //close add primary benny if open
                setAddPrimaryBenny(false)
              }}
            >
              + Add additional contingent beneficiary
            </Button>
          )}

          {addContingentBenny && (
            <Box sx={componentStyles.addBeneficiaryContainer}>
              <Typography sx={{ fontWeight: 500 }}>
                {" "}
                Add additional contingent beneficiary{" "}
              </Typography>

              {/* Add new Form */}
              <TextField
                fullWidth
                size="small"
                label="First Name"
                variant="outlined"
                sx={componentStyles.addBeneficiaryInputs}
                value={newContingentBenny?.firstName || ""}
                onChange={(e) =>
                  handleAddBeneficiaryInputChange(e, "firstName", "contingent")
                }
              />

              <TextField
                fullWidth
                size="small"
                label="Last Name"
                variant="outlined"
                sx={componentStyles.addBeneficiaryInputs}
                value={newContingentBenny?.lastName || ""}
                onChange={(e) =>
                  handleAddBeneficiaryInputChange(e, "lastName", "contingent")
                }
              />

              <TextField
                fullWidth
                size="small"
                select
                sx={componentStyles.addBeneficiaryInputs}
                variant="outlined"
                label="Relationship"
                value={newContingentBenny?.relationshipTypeId || ""}
                onChange={(e) =>
                  handleAddBeneficiaryRelationshipChange(e, "contingent")
                }
              >
                {props.relationshipTypes &&
                  props.relationshipTypes.map((relationshipType) => (
                    <MenuItem
                      key={relationshipType.id}
                      value={relationshipType.id}
                    >
                      {relationshipType.name}
                    </MenuItem>
                  ))}
              </TextField>

              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  disableFuture
                  label="Date of Birth"
                  openTo="year"
                  views={["year", "month", "day"]}
                  format="MM/dd/yyyy"
                  minDate={dateMinObj}
                  maxDate={dateMaxObj}
                  value={newContingentBenny?.dob || null}
                  onChange={(e) => handleAddBeneficiaryDob(e, "contingent")}
                  onError={(error) =>
                    setDobErrorMessageNewBeneficiary(
                      newContingentBenny,
                      "contingent",
                      error
                    )
                  }
                  sx={componentStyles.customDatePicker(false)}
                  slotProps={{
                    textField: {
                      helperText: newContingentBenny?.dobError
                        ? dobErrorMessage
                        : "",
                      size: "small",
                    },
                    openPickerButton: { sx: { display: "none" } }, //hide calendar icon calandar
                  }}
                />
              </LocalizationProvider>

              <Box
                className="flex-row"
                sx={{
                  textAlign: "right",
                  margin: "10px 0px",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  // disabled={disableSaveContingent}
                  sx={componentStyles.cancelOrAddBeneficiaryButtons}
                  onClick={() => {
                    setAddContingentBenny((prevVal) => !prevVal)
                    setNewContingentBenny({})
                  }}
                >
                  CANCEL
                </Button>
                <Button
                  // disabled={disableSaveContingent}
                  variant="contained"
                  sx={componentStyles.cancelOrAddBeneficiaryButtons}
                  onClick={saveNewBeneficiary}
                >
                  ADD
                </Button>
              </Box>
            </Box>
          )}
        </Box>

        {totalAllocationError && props?.edit && (
          <Box sx={componentStyles.allocationErrorAlert.alertContainer}>
            <Alert
              sx={componentStyles.allocationErrorAlert.alertBody}
              severity="error"
            >
              {primaryBennies?.length > 0 &&
                totalAllocationError?.primaryTotal != 100 && (
                  <Typography
                    variant="body2"
                    sx={componentStyles.allocationErrorAlert.alertText}
                  >
                    Primary allocations must total 100%.
                  </Typography>
                )}
              {contingentBennies?.length > 0 &&
                totalAllocationError?.contingentTotal != 100 && (
                  <Typography
                    variant="body2"
                    sx={componentStyles.allocationErrorAlert.alertText}
                  >
                    Contingent allocations must total 100%.
                  </Typography>
                )}
            </Alert>
          </Box>
        )}
      </Box>
      {/* 
      {totalAllocationError && props?.edit && (
        <Alert
          sx={{
            position: "absolute",
            bottom: "60px",
            right: "8px",
            padding: "5px 10px",
            opacity: "0.9",
          }}
          severity="error"
        >
          {primaryBennies?.length > 0 &&
            totalAllocationError?.primaryTotal != 100 && (
              <Typography variant="body2" sx={{ fontSize: "12px!important" }}>
                Primary allocations must total 100%.
              </Typography>
            )}
          {contingentBennies?.length > 0 &&
            totalAllocationError?.contingentTotal != 100 && (
              <Typography variant="body2" sx={{ fontSize: "12px!important" }}>
                Contingent allocations must total 100%.
              </Typography>
            )}
        </Alert>
      )} */}

      {/* SAVE AND CANCEL BUTTONS + display coverage effective date for uniform display in parent plan while editBeneficiary = true*/}
      {props?.edit && (
        <Box sx={componentStyles.bottomRow}>
          <Box sx={{ width: "100%" }}>{`Effective Date: ${
            props?.plan?.beginDate
              ? formatUTCToShortDate(props?.plan?.beginDate)
              : "N/A"
          }`}</Box>
          <Box sx={componentStyles.saveAndCancelButtonContainer}>
            <Button
              variant="outlined"
              sx={componentStyles.saveAndCancelEditButtons(false)}
              onClick={() => cancelEditOrAdd()}
            >
              BACK
            </Button>

            <Button
              variant="contained"
              sx={componentStyles.saveAndCancelEditButtons(true)}
              onClick={() => saveBeneficiaryChanges()}
              disabled={loading || totalAllocationError || dobError}
            >
              {loading ? (
                <>
                  <Box sx={{ width: "100%", position: "absolute" }}>
                    <InfinityLoader
                      style={{
                        zIndex: 5,
                        height: "30px",
                      }}
                    />
                  </Box>
                  <Typography sx={{ opacity: 0 }}>SAVE CHANGES</Typography>
                </>
              ) : (
                <>SAVE CHANGES</>
              )}
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  )
}
