import axios from "axios";
import Settings from "../settings";
import signalRConnection from "../signalr";


export function authHeader(){
    // const token = localStorage.getItem("token");
    const token = getCookie('token')
    if(token){
        return { Authorization: 'Bearer ' + token,
        };
    }
    else {
        return {};
    }
}

export function getToken() {
    const token = getCookie('token');
    return token;
}

const getCookie = (cookieName) => {
    let name = `${cookieName}=`
    let cookieArray = document.cookie.split(';')
    // console.log(cookieArray)
    let value = ''
    cookieArray.forEach(cookie => {
        if (cookie.includes(name)) value = cookie.replace(name,'').trim()
    })
    return value
}
const removeCookie = (cookieName) => {
    let hostArray = window.location.hostname.split('.').reverse()
    let domain = `.${hostArray[1]}.${hostArray[0]}`

    let name = `${cookieName}=`
    let cookieArray = document.cookie.split(';')

    cookieArray.forEach(cookie => {
        if (cookie.includes(name)) document.cookie = `${name};Domain=${domain};expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`
    })
}

const authService = {
    //isAuthenticated: false,
    async authenticate(credentials, callback) {
        let returnValue = false;
        try {
            const response = await axios.post(Settings.API_URL + "authentication", credentials);
            // console.log(response.data)
            const token = response.data.token;
            const expiration = new Date(response.data.expiration).toUTCString();
            const subdomain = response.data.domain;

            // console.log("Token is: " + token);
            // console.log(new Date(expiration).toUTCString())
            //LOCAL STORAGE
            localStorage.setItem("token", token);
            localStorage.setItem("expiration", expiration);

            //COOKIE
            let hostArray = window.location.hostname.split('.').reverse()
            let domain = `.${hostArray[1]}.${hostArray[0]}`
            //2024-08-23T15:43:48.000Z
            //2022-08-25T09:45:58.1998481-06:00
            document.cookie = `token=${token};Domain=${domain};expires=${expiration};path=/`
            document.cookie = `subdomain=${subdomain};Domain=${domain};expires=${expiration};path=/`
            document.cookie = `expiration=${expiration};Domain=${domain};expires=${expiration};path=/`
            // console.log('auth cookie',document.cookie)

            signalRConnection.connect();

            returnValue = true;
        }
        catch(err){
            returnValue = false;
            console.error(err);
        }
        
        return returnValue;
    },
    signout(){
        //LOCAL STORAGE
        //localStorage.removeItem("token");

        //COOKIE
        removeCookie('token');
        removeCookie('subdomain');
        removeCookie('expiration');
        signalRConnection.disconnect();
    },
    async checkStatus(){
        // const token = localStorage.getItem("token");
        // const token = localStorage.getItem("token") || getCookie('token');
        const token = getCookie('token');


        // console.log('Check Status: ' + token);
        let returnValue = false;

        try{
            const response = await axios.get(Settings.API_URL + "authentication", {headers: authHeader});
            returnValue = true;
        }
        catch(err){
            returnValue = false;
            console.error(err);
        }

        return returnValue;
    },
    isAuthenticated() {
        // const expirationDate = localStorage.getItem("expiration");
        const token = getCookie('token');
        const expirationDate = getCookie('expiration');
        const d = new Date();
        // console.log('Authentication ' + expirationDate + d);
        if (token) {
            const dExpiration = new Date(expirationDate);
            if (dExpiration > d) {
                return true;
            } else {
                return false;
            }
        }
        else {
            return false;
        }
        
    },
    getDomain() {
        return getCookie('subdomain').trim();
    },
    async emulateMember(memberId) {
        try {
            console.log("HEADER MY MAN",authHeader)
            let emulatedMember = await axios.get(Settings.API_URL + "authentication/emulatemember/" + memberId, {headers: authHeader});
            console.log(emulatedMember)
            // localStorage.setItem("token", emulatedMember.token);
            // localStorage.setItem("expiration", emulatedMember.expiration);

        } catch (error) {
            console.log('Unable to emulate member',error)
        }
    }
}

export default authService;