import React, { useEffect, useState } from "react"

//Services
import optionService from "../../../../../../services/optionService"

//Mui Icons
import AccountBalanceIcon from "@mui/icons-material/AccountBalance"
import CheckIcon from "@mui/icons-material/Check"
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"
import AddIcon from "@mui/icons-material/Add"
import { CardActionArea } from "@mui/material"

//Mui Components
import {
  Alert,
  Box,
  Card,
  ClickAwayListener,
  Collapse,
  Fade,
  IconButton,
  Menu,
  MenuItem,
  Radio,
  Typography,
} from "@mui/material"

//Component styles
const componentStyles = {
  componentContainer: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
  },
  accountCard:{ 
    actionArea:{
    padding: "15px",
    width: "100%",
    minHeight: "96px",
    margin: "0 auto",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    fontFamily: "Poppins",
    backgroundColor: "background.default",
  },
  cardBorder: {
    border: "1px solid",
    borderColor: "background.border",
  }
},
  iconContainer: {
    minWidth: "60px",
    margin: "0 auto",
    display: "flex",
    justifyContent: "center",
  },
  accountBalanceIcon: {
    color: "primary.main",
  },
  accountInfoContainer: {
    display: "flex",
    flexDirection: "column",
    paddingLeft: "6px",
    width: "100%",
  },
  bankInfoRow: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center",
  },
  accountInfoText: (bankName) => ({
    fontFamily: "Poppins",
    fontSize: "14px",
    color: bankName ? "text.primary" : "text.secondary",
    fontWeight: bankName ? 500 : 400,
    marginRight: "4px",
  }),
  addNewAccountBtn: {
    container: {
      display: "flex",
      flexDirection: "row",
      color: "primary.main",
      justifyContent: "flex-start",
      alignItems: "center",
      padding: "20px 20px 10px",
    },
    icon: { height: "20px" },
    text: {
      fontWeight: "500",
      fontFamily: "Archivo",
      fontSize: "14px!important",
    },
  },
  //account option list
  accountOptionListContainer: {
    backgroundColor: "background.default",
    maxHeight: "250px",
    borderRadius: "4px",
    overflowY: "auto",
  },
  accountOption: (selected) => ({
    padding: "15px",
    width: "100%",
    minHeight: "96px",
    margin: "0 auto",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    border: "none",
    borderRadius: "0px 0px 4px 4px",
    backgroundColor: selected
      ? "background.selectedBankAccount"
      : "background.default",
    borderBottom: "1px solid",
    borderColor: "background.border",
  }),
  checkMarkIcon: {
    color: "primary.main",
  },
}

export default function ExistingPaymentMethods(props) {
  //TO DO
  //onClick event handler to add new bank account (openAddNewAccount())

  //PROPS
  const { bank, setBank, bankArr, bankAccountTypes, handleAddNewBankAccount } =
    props

  //bank account type name
  const getMemberAccountType = (accountId) => {
    let type = props.bankAccountTypes?.find((type) => {
      return type?.id === accountId
    })

    return type?.name.toLowerCase()
  }

  //view collapsed bank account options
  const [viewAccounts, setViewAccounts] = useState(false)
  const handleViewBankAccounts = () => {
    setViewAccounts(!viewAccounts)
  }

  const handleSelectBankAccountFromList = (account) => {
    //check existing payment schedule and frequency - keep if they exist, othersiwe set to monthly on first
    let _currentPaymentFrequencyId = bank?.paymentFrequencyId
      ? bank?.paymentFrequencyId
      : 1
    let _currentPaymentOneValue = bank?.paymentOne ? bank?.paymentOne : 1
    let _currentPaymentTwoValue = bank?.paymentTwo ? bank?.paymentTwo : 15
    console.log(
      "existing payment frequency",
      _currentPaymentFrequencyId,
      "existing payment one value",
      _currentPaymentOneValue
    )
    setBank({
      ...account,
      paymentFrequencyId: _currentPaymentFrequencyId,
      paymentOne: _currentPaymentOneValue,
      paymentTwo: _currentPaymentTwoValue,
    })
    //close account list
    setViewAccounts(false)
  }

  return (
    <ClickAwayListener onClickAway={() => setViewAccounts(false)}>
      <Box sx={componentStyles.componentContainer}>
        {/* display bank account currently selected for payment */}
        <Card
          key={bank?.id}
          elevation={0}
          sx={componentStyles.accountCard.cardBorder}
        >
          <CardActionArea
            onClick={handleViewBankAccounts}
            sx={componentStyles.accountCard.actionArea}
            
          >
            {/* icon */}
            <Box sx={componentStyles.iconContainer}>
              <AccountBalanceIcon sx={componentStyles.accountBalanceIcon} />
            </Box>

            {/* Member Name and Account Type */}
            <Box sx={componentStyles.accountInfoContainer}>
              <Typography sx={componentStyles.accountInfoText(true)}>
                {bank?.description ? `${bank?.description} - ` : ""}{" "}
                {getMemberAccountType(bank.bankAccountTypeId)}
              </Typography>

              {/* Bank Info*/}
              <Box sx={componentStyles.bankInfoRow}>
                <Typography sx={componentStyles.accountInfoText(false)}>
                  {bank.bankName || "Account"}
                </Typography>
                <Typography sx={componentStyles.accountInfoText(false)}>
                  {` | Routing Number: ${bank?.routingNumber}`}
                </Typography>
              </Box>

              <Box sx={componentStyles.bankInfoRow}>
                <Typography sx={componentStyles.accountInfoText(false)}>
                  {`Account Number: XXXXXXXXXXXX${bank.accountNumber?.slice(
                    -4
                  )}`}
                </Typography>
              </Box>
            </Box>
            <Box>
              {/* <IconButton
                sx={{ borderRadius: "100%" }}
                onClick={handleViewBankAccounts}
              > */}
              <ArrowDropDownIcon />
              {/* </IconButton> */}
            </Box>
          </CardActionArea>
        </Card>

        {/* display all bank accounts in drop down*/}

        <Collapse
          timeout={{ appear: 10, enter: 10, exit: 1 }}
          in={viewAccounts}
          sx={{
            position: "absolute",
            zIndex: 10,
            top: "99%",
            right: "12px",
            left: "12px",
          }}
        >
          <Card elevation={4} sx={componentStyles.accountOptionListContainer}>
            <Box
              type="button"
              sx={componentStyles.addNewAccountBtn.container}
              onClick={handleAddNewBankAccount}
            >
              <AddIcon sx={componentStyles.addNewAccountBtn.icon} />
              <Typography sx={componentStyles.addNewAccountBtn.text}>
                ADD NEW PAYMENT METHOD
              </Typography>
            </Box>

            {/* bank account list */}
            {bankArr?.length > 0 &&
              bankArr
                ?.sort((a, b) => (a.id === bank.id ? -1 : 0))
                .map((account) => {
                  return (
                    <Card key={account?.id} elevation={0}>
                      <CardActionArea
                        sx={componentStyles.accountOption(
                          account?.id === bank?.id
                        )}
                        onClick={() => handleSelectBankAccountFromList(account)}
                      >
                        {/* Member Name and Account Type */}
                        <Box sx={componentStyles.accountInfoContainer}>
                          <Typography
                            sx={componentStyles.accountInfoText(true)}
                          >
                            {account?.description
                              ? `${account?.description} - `
                              : ""}{" "}
                            {getMemberAccountType(account.bankAccountTypeId)}
                          </Typography>

                          {/* Bank Info*/}
                          <Box sx={componentStyles.bankInfoRow}>
                            <Typography
                              sx={componentStyles.accountInfoText(false)}
                            >
                              {account.bankName || "Account"}
                            </Typography>
                            <Typography
                              sx={componentStyles.accountInfoText(false)}
                            >
                              {` | Routing Number: ${account?.routingNumber}`}
                            </Typography>
                          </Box>

                          <Box sx={componentStyles.bankInfoRow}>
                            <Typography
                              sx={componentStyles.accountInfoText(false)}
                            >
                              {`Account Number: XXXXXXXXXXXX${account.accountNumber?.slice(
                                -4
                              )}`}
                            </Typography>
                          </Box>
                        </Box>

                        {/* display check mark if account is selected */}
                        {account?.id === bank?.id && (
                          <Box>
                            <CheckIcon sx={componentStyles.checkMarkIcon} />
                          </Box>
                        )}
                      </CardActionArea>
                    </Card>
                  )
                })}
          </Card>
        </Collapse>
      </Box>
    </ClickAwayListener>
  )
}
