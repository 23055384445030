import React, {useLayoutEffect} from 'react'
import { useState } from 'react';

import App from './App';
import ExternalApp from './ExternalApp';
import accountService from './services/account-service'


export default function SwitchWrapper(props) {
    const [userRender,setUserRender] = useState()
    const [results,setResults] = useState()
    // console.log('process', process.env)

    useLayoutEffect(() => { 
        async function renderApp() {
            if (!results){
                let _results;
                try {
                    _results = await accountService.getMe()
                    setResults(_results)
                    if(_results.isInternal) setUserRender(<App />)
                    else setUserRender(<ExternalApp />)
                    
                } catch (error) {
                    // Error grabbing account, push to external
                    setUserRender(<ExternalApp />)
                }
            } else {
                if(results.isInternal) setUserRender(<App />)
                else setUserRender(<ExternalApp />)
            }

            
        }
        renderApp();
    },[])

    return(
        <>
        {/* {!userRender && <h1>LOADINGLOADINGLOADINGLOADINGLOADINGLOADING</h1>} */}
        {userRender && userRender}
        </>
    )
}