import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Switch, useHistory, useLocation } from 'react-router-dom';

//Mui Components
import Paper from '@mui/material/Paper';
import LinearProgress from '@mui/material/LinearProgress';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

//Component Styles
const componentStyles = {
  componentContainer: (agentEnroller) => ({
    padding: '10px 27px',
    display: 'block!important',
    position: 'fixed',
    bottom: '0px',
    color: '#1000000DE',
    textAlign: 'center',
    fontSize: '20px',
    overflowY: 'hidden',
    fontWeight: 500,
    height: '160px',
    boxShadow: '0px -1px 10px 0px rgba(0, 0, 0, 0.1)',
    zIndex: 6,
    minWidth: agentEnroller ? '-webkit-fill-available' : '100%',
  }),
  linearProgressBar: {
    boxShadow: '0px 4px 4px 0px #00000040 inset',
    background: '#f0f0f0',
    height: '12px',
    borderRadius: '100px',
    margin: '15px auto 20px',
    '& .MuiLinearProgress-bar1Determinate': {
      background: 'linear-gradient(90deg, #5421C9 0%, #195FFB 100%)',
    },
  },
  buttonContainer: (allowBack) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: allowBack ? 'space-between' : 'flex-end',
  }),
  backButtonStyle: {
    height: 50,
    width: '152px',
    border: '1px solid #195ffb!important',
    fontWeight: '700!important',
  },
  nextButtonStyle: (nextLocation, nextValidation) => ({
    height: 50,
    textAlign: 'flex-end',
    width: '152px',
    fontWeight: '600!important',
    fontSize: '15px',
    backgroundColor:
      nextLocation == 'payment' && !nextValidation
        ? 'rgba(0, 0, 0, 0.12)'
        : 'auto',
    color:
      nextLocation == 'payment' && !nextValidation
        ? 'rgba(0, 0, 0, 0.12)'
        : 'auto',
    boxShadow: nextLocation == 'payment' && !nextValidation ? 'none' : 'auto',
    '&:hover': {
      backgroundColor:
        nextLocation == 'payment' && !nextValidation
          ? 'rgba(0, 0, 0, 0.12)'
          : 'auto',
    },
  }),
};

export default function StickyProgressTotal(props) {
  let UsdFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
  });
  let UsdFormatterDec = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
  });

  const [progress, setProgress] = useState(20);
  useLayoutEffect(() => {
    if (props.progress) setProgress(props.progress);
  }, [props.progress]);
  const [timeLeft, setTimeLeft] = useState('About 3 minutes left');
  useLayoutEffect(() => {
    setTimeLeft(props.timeLeft);
  }, [props.timeLeft]);

  // useEffect(() => {
  //    if (props.selections){
  //       let total = 0;
  //       let _progress = 1 //Start with progress
  //       let _planCount = 0;
  //       let DEBUG_otherSteps = 1;
  //       for (let [key,val] of Object.entries(props.selections)){
  //          if (key.slice(key.length-3) == 'Val') _planCount +=1
  //          else {
  //             if (typeof val == 'object') {
  //                for (let arrayVal of val){
  //                   // console.log('LADD ARRAY',arrayVal)
  //                   //Selections are made on hitting next for ladd
  //                }
  //             }
  //             else if (val != undefined && key.slice(key.length-2) != 'Id') _progress +=1
  //          }
  //       }
  //       total = _planCount + DEBUG_otherSteps
  //       let progressPercent = (_progress / total) * 100
  //       setProgress(progressPercent)

  //       // if (props.selections.std != undefined) _progress
  //    }
  // },[props.selections])
  // useEffect(()=> {
  //    if (0<=progress && progress<33) setTimeLeft(3)
  //    else if (34<progress && progress<66) setTimeLeft(2)
  //    else if (67<progress && progress<=100) setTimeLeft(1)
  // },[progress])

  let history = useHistory();
  let location = useLocation();

  const backClickHandler = () => {
    if (props.agentEnroller) {
      props.setAgentEnrollmentPageState({ [props.lastLocation]: true });
    } else {
      history.push(props.lastLocation, { ...history.location.state });
    }
  };

  const [position, setPosition] = useState('sticky');

  const handlePosition = () => {
    if (props.position == 'fixed') {
      setPosition('fixed');
    } else {
      setPosition('sticky');
    }
  };

  useEffect(() => {
    handlePosition();
  }, [props.position]);

  return (
    <Paper
      elevation={0}
      sx={componentStyles.componentContainer(props.agentEnroller)}
    >
      {props.runningTotal != null && props.runningTotal != undefined && (
        <Box>
          {`${UsdFormatterDec.format(props.runningTotal)} Monthly Total`}
        </Box>
      )}

      <LinearProgress
        variant="determinate"
        value={progress}
        sx={componentStyles.linearProgressBar}
      />

      {/* <Box sx={{ fontSize: '15px', fontWeight: 700, marginBottom: '10px' }}>
          {timeLeft}
        </Box> */}

      <Box sx={componentStyles.buttonContainer(props?.allowBack)}>
        {props.allowBack && (
          <Button
            variant="outlined"
            sx={componentStyles.backButtonStyle}
            onClick={backClickHandler}
          >
            Back
          </Button>
        )}

        <Button
          variant="contained"
          sx={componentStyles.nextButtonStyle(
            props?.nextLocation,
            props?.nextValidation
          )}
          disabled={
            props?.nextLocation != 'payment' ? props?.nextValidation : false
          }
          // onClick={() => {
          //    if (!props?.notActivelyWorking){
          //       props.nextClickHandler(props.nextLocation)
          //    } else {
          //       if (props.handleNawExit) props.handleNawExit()
          //    }
          // }}
          onClick={() => props.nextClickHandler(props.nextLocation)}
        >
          {/* {(props?.notActivelyWorking && props?.nextLocation) == 'payment' ? 'Save & Exit' : 'Next'} */}
          Next
        </Button>
      </Box>
    </Paper>
  );
}
