import React, { useEffect, useState } from 'react';
import * as DateFns from 'date-fns'

import './UmpDatePicker.css'

//MuiX
//import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker"
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { InputLabel } from '@mui/material';

//Our Styles
import enrollmentPaymentStyles from '../../../external-enrollments/enrollment-plans/payment-components/enrollmentPaymentStyles';


export default function UmpDatePicker(props) {
    //use otherValue prop to compare current date chosen to second date if a second date exists

    const [value, setValue] = useState(props.oneOrTwo == 'One' ? 1 : 2);
    const [mounted, setMounted] = useState()
    useEffect(() => {
        if (props?.bank) {
            if (props.oneOrTwo == 'One' && props.bank?.paymentOne) {
                setValue(props.bank.paymentOne)
            } else if (props.oneOrTwo == 'Two' && props.bank?.paymentTwo) {
                setValue(props.bank.paymentTwo)
            }
        }
    }, [props.bank])

    let dateRange = [];
    let minDate = props?.bletGroupPlanId18 ? 10 : 1
    let maxDate = props?.bletGroupPlanId18 ? 21 : 27
    for (let i = minDate; i < maxDate; i++) {
        if (props.oneOrTwo == 'One' && props.schedule == 1) {
            dateRange.push(i)
        }
        else if (props.oneOrTwo == 'One' && props.schedule == 2) {
            if (i < maxDate - 1) {
                dateRange.push(i)
            }
        }
        else if (props.oneOrTwo == 'Two' && i > props.otherVal) {
            dateRange.push(i)
        }
    }

    const pickerHandler = (e) => {
        let date = e.target.value
        if (props.oneOrTwo == 'One' && props.schedule == 1) {
            setValue(date)
        }
        else if (props.oneOrTwo == 'One' && props.schedule == 2) {
            if (date > props.otherVal) {
                setValue(date)
                // props.payScheduleHandler(0, 'Two')
            } else {
                setValue(date)
            }
        } else if (props.oneOrTwo == 'Two') {
            setValue(date)
        }
    }

    useEffect(() => {
        if (mounted) {
            props.payScheduleHandler(value, props.oneOrTwo)
        } else {
            setMounted(true)
        }
    }, [value])



    return (
        <TextField fullWidth select
            // sx={{
            //     '& fieldset': {borderRadius:'8px'},
            //     '& fieldset > legend': { display: 'none' },
            //     '&.MuiOutlinedInput-root.Mui-focused > fieldset': { border: '2px solid #195ffb' },
            //  }}
            sx={{
                ...enrollmentPaymentStyles.selectInput
                // '& fieldset': { borderRadius: '8px' },
                // '& fieldset > legend': { display: 'none' },
                // '&.MuiOutlinedInput-root.Mui-focused > fieldset': { border: '2px solid #195ffb' },
            }}
            InputLabelProps={{ sx: { color: 'primary.main' } }}
            variant="outlined"
            label={props.schedule == 2 ? props.oneOrTwo == "One" ? 'First Payment' : 'Second Payment' : 'Payment Date'}
            value={value}
            onChange={pickerHandler}
        >
            {dateRange.map(date =>
                <MenuItem key={date} value={date} >
                    {props.dayFormatter(date)}
                </MenuItem>
            )}

            {/* <DesktopDatePicker 
                views={['day']}
                minDate={minDate}
                maxDate={maxDate}
                value={value}
                inputFormat="dd"
                disableMaskedInput
                onChange={pickerHandler}
                renderInput={(params) => <TextField {...params} sx={{width:"100%"}} />}
                /> */}
        </TextField>
    )
}