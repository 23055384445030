import axios from "axios";
import Settings from "../settings";
import authService, { authHeader } from "./auth-service";
import { baseService } from "./baseService";

class internalCalculatorService extends baseService {
    getSubGroups() {
        const url = Settings.API_URL + "calculator"
        return this.get(url)
    }


    calculateRates(basicInfo) {
        const url = Settings.API_URL + "calculator";
        //DOB, Salary, GroupPlanId
        return this.post(url, basicInfo)
    }

    updateMemberCalculateRates(basicInfo) {
        const url = Settings.API_URL + "calculator/enrollment";
        return this.post(url, basicInfo);
    }

    updateMemberCalculateRatesForMember(memberId, basicInfo) {
        const url = Settings.API_URL + "calculator/enrollment/member/" + memberId;
        return this.post(url, basicInfo);
    }

    getRecommendations (survey) {
        const url = Settings.API_URL + "calculator/getrecommendations";
        return this.post(url,survey)
    }

    getRecommendationsLoggedIn(survey) {
        const url = Settings.API_URL + "calculator/recommendation";
        return this.post(url, survey);
    }
    getRecommendationsForMember(memberId, survey) {
        const url = Settings.API_URL + "calculator/" + memberId + "/recommendation";
        return this.post(url, survey);
    }
    getRecommendationResponses() {
        const url = Settings.API_URL + "calculator/recommendation";
        return this.get(url);
    }
    getRecommendationResponsesForMember(memberId) {
        const url = Settings.API_URL + "calculator/" + memberId + "/recommendation";
        return this.get(url);
    }
}

const calculatorService = new internalCalculatorService();

export default calculatorService;