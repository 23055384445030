import React, { useEffect, useState } from 'react';

//services

//Mui X
import {
  DataGrid,
  GridToolbar,
  GridFilterPanel,
  GridFooterContainer,
  GridFooter,
} from '@mui/x-data-grid';

//Mui Components
import Checkbox from '@mui/material/Checkbox';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Box from '@mui/material/Box';
import SvgIcon from '@mui/material/SvgIcon';
import { Button, Chip } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import { Typography } from '@mui/material';

// Assets
import openSvg from '../../../assets/images/openSvg.svg';
import closedSvg from '../../../assets/images/closedSvg.svg';

//Mui icons
import FilterList from '@mui/icons-material/FilterList';

export default function OurDataGrid(props) {
  let { rows, columns, handleRowClick, pageSize, showToolbar } = props;

  // const [pushHeight,setPushHeight] = useState(0)

  Element.prototype.smartHeightAdjust = function () {
    let topOffset = Math.ceil(this.getBoundingClientRect().top) + 1;
    this.children[0].style.height = `calc(100vh - ${topOffset}px)`;
  };

  let ourDataGridElement = document.getElementById('our-data-grid');
  useEffect(() => {
    if (!props.manualHeight && ourDataGridElement)
      ourDataGridElement.smartHeightAdjust();
    // let topOffset = Math.ceil(ourDataGridElement.getBoundingClientRect().top) + 1
    // setPushHeight(topOffset)
  }, [ourDataGridElement]);

  const CustomFooter = () => {
    return (
      <GridFooterContainer>
        <Button
          // sx={componentStyles.exportButton}
          disabled={rows.length <= 0}
          onClick={exportSelectedRows}
        >
          <DownloadIcon fontSize="small" />

          <Typography>EXPORT</Typography>
        </Button>{' '}
        <GridFooter
          sx={{
            border: 'none', // To delete double border.
          }}
        />
      </GridFooterContainer>
    );
  };

  const exportSelectedRows = (e) => {
    if (props?.apiRef.current) {
      // let csv = apiRef.current.getDataAsCsv()
      let params = {
        skipHeader: false,
        skipFooters: true,
        allColumns: true,
        onlySelected: false,
        suppressQuotes: true,
        fileName: `${props?.dataSet}_${new Date().toDateString()}`,
        columnSeparator: ',',
      };
      console.log(props?.apiRef.current);
      let csv = props?.apiRef.current.exportDataAsCsv(params);
      console.log(props?.apiRef.current.getDataAsCsv());
      console.log(csv);
    }
  };

  function OurFilterPanel() {
    return (
      <Box>
        <GridFilterPanel />
        {props?.showSaveFilterModel && (
          <Box>
            <Button
              sx={{ float: 'right' }}
              onClick={() => props.saveFilterModel()}
            >
              Save Filter
            </Button>
          </Box>
        )}
      </Box>
    );
  }
  // console.log(props.manualStyle)

  useEffect(() => {
    console.log(props.filterModel);
  }, [props.filterModel]);

  return (
    <>
      <style>
        {/* {`
         .even {background-color: background.dataGridEven}
         `} */}
      </style>
      <div
        id="our-data-grid"
        style={{ height: props.manualHeight ? '100%' : '' }}
      >
        <DataGrid
          apiRef={props.apiRef ? props.apiRef : undefined}
          onCellEditCommit={props.cellEditCommit}
          columnVisibilityModel={props.columnVisibilityModel}
          onColumnVisibilityModelChange={(newModel) =>
            props.setGridCols(newModel)
          }
          getRowHeight={() => 'auto'}
          loading={props.loadingState}
          sx={{
            // height:`calc(100vh - ${pushHeight}px)`,
            bgcolor: 'background.default',
            color: 'text.primary',
            fontFamily: 'Archivo',
            '& .MuiDataGrid-filterForm': {
              padding: '10px',
            },
            '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': {
              py: '8px',
            },
            '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {
              py: '10px',
            },
            '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': {
              py: '15px',
            },
            '.MuiTablePagination-displayedRows': { marginBottom: 0 },
            '.left-padding': { paddingLeft: '16px' },
            ...props.manualStyle,
          }}
          components={{
            Toolbar: showToolbar ? GridToolbar : null,
            // ...props.gridComponents,
            NoRowsOverlay: props.noRowsComp,
            NoResultsOverlay: props.noResultsComp,
            FilterPanel: OurFilterPanel,
            Footer: props?.exportBtn && CustomFooter,
          }}
          rows={rows}
          columns={columns}
          onRowClick={handleRowClick}
          //getRowClassName={(params) =>
          //   // console.log(params)
          //   params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'

          getRowClassName={
            props.getRowClassName
              ? props.getRowClassName
              : (params) =>
                  // console.log(params)
                  params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
          }
          pageSize={!props.autoPageSize ? props.pageSize : 20}
          autoPageSize={props.autoPageSize}
          // rowsPerPageOptions={[5,10,15]}
          // page={props.page}
          onPageChange={(params) => {
            console.log('params in datagrid pagechange', params);
            if (props.handlePageChange) props.handlePageChange(params);
          }}
          initialState={{
            sorting: {
              sortModel: [
                {
                  field: props.initialSort || 'lastUpdated',
                  sort: props.initialSortDirection || 'desc',
                },
              ],
            },
            filter: {
              filterModel: props?.filterModel && props?.filterModel,
            },
            pagination: {
              paginationModel: props.paginationModel,
            },
          }}
          onPaginationModelChange={props.onPaginationModelChange}
          // filterModel={props.filterModel}
          onFilterModelChange={(newFilterModel) =>
            props?.filterModel?.items != newFilterModel?.items &&
            props.setFilterModel(newFilterModel)
          }
          filterModel={props?.filterModel}
          checkboxSelection={props.checkboxSelection}
          onSelectionModelChange={props.handleCheckboxClick}
          onStateChange={props.gridStateChangeHandler}
          disableSelectionOnClick
          componentsProps={{
            columnMenu: {
              className: 'popperOverride',
            },
          }}
        />
      </div>
    </>
  );
}
