import { Box } from '@mui/system';
import React, { useEffect, useRef, useState } from 'react';
// import {RadialBarChart, RadialBar, Legend, Tooltip} from 'recharts'
import {
  PieChart,
  Pie,
  Cell,
  LabelList,
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Bar,
  Label,
  ResponsiveContainer,
  Legend,
  Tooltip,
  PolarAngleAxis,
  RadialBarChart,
  RadialBar,
  LineChart,
  Line,
} from 'recharts';

import {
  Paper,
  useTheme,
  Typography,
  Tabs,
  Tab,
  Button,
  Icon,
} from '@mui/material';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';

//MuiX
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

//Mui icons
import { Circle } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';

//Services
import dashboardService from '../../../../services/dashboard-service';

//Our Components
import reportingDashboardStyles from '../reportingDashboardStyles';
import InfinityLoader from '../../../UH-loading-animation/InfinityLoader';

export default function EnrollmentReport(props) {
  const [loading, setLoading] = useState(false);
  const componentStyles = {
    dateAndButtonContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
    },
    dateSelectorContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      flexWrap: 'wrap',
      color: 'text.secondary',
    },
    preSelectedDatePicker: {
      margin: ' 4px 16px 4px 0px',
      width: '155px',
      '& fieldset': {
        borderRadius: '8px',
        height: '42px',
      },
      '& .MuiSelect-select': {
        padding: '8px 12px',
        fontSize: '14px',
      },
    },
    customDatePicker: {
      minWidth: '150px',
      margin: ' 4px 16px 4px 0px',
      padding: '0px',
      '& .MuiOutlinedInput-input': {
        padding: '8px 12px',
        fontSize: '14px', // Adjust the font size
      },
      '& fieldset': {
        borderRadius: '8px',
        height: '42px',
      },
    },
    addIcon: {
      height: '20px',
      marginRight: '2px',
    },
    clearDateButtonContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    clearDateButton: {
      borderRadius: '8px',
    },
    closeIcon: {
      color: 'text.secondary',
      height: '18px',
      marginRight: '4px',
    },
    clearDateText: { fontSize: '13px!important', color: 'text.secondary' },
  };
  const formatter = Intl.NumberFormat('en', { notation: 'compact' });
  const formatterSmall = Intl.NumberFormat('en', { notation: 'standard' });

  const [windowDateRange, setWindowDateRange] = useState({
    min: new Date(),
    max: new Date(),
  });
  const [report, setReport] = useState([]);
  const getReport = async () => {
    setLoading(true);
    let _report = await dashboardService.getEnrollmentReport(props.groupPlanId);
    if (_report) setReport(_report);
    setLoading(false);
    // console.log('EnrollmentReport report GET', _report);
  };
  const getAllGroupReports = async () => {
    setLoading(true);
    let _report = await dashboardService.getAllEnrollmentReports();
    if (_report) setReport(_report);
    setLoading(false);
    // console.log('EnrollmentReport report GET', _report);
  };

  useEffect(() => {
    if (props.groupPlanId == 'all') {
      getAllGroupReports();
    } else if (props.groupPlanId > 0) {
      getReport();
    }
  }, [props.groupPlanId]);

  const [enrollmentStatus, setEnrollmentStatus] = useState([
    {
      status: 0,
      count: 0,
    },
    {
      status: 1,
      count: 0,
    },
  ]);
  const [enrollDate, setEnrollDate] = useState({});

  const [std, setStd] = useState(0);
  const [ltd, setLtd] = useState(0);
  const [ladd, setLadd] = useState({
    member: 0,
    spouse: 0,
    dependent: 0,
  });

  const [lineOfCoverage, setLineOfCoverage] = useState([]);

  const createReports = () => {
    setLoading(true);
    // console.log('report, set loading to true', report);
    let windowDateRangeMax = new Date('01/01/2020');
    let windowDateRangeMin = new Date();
    let submitted = 0;
    let notSubmitted = 0;
    let submittedDates = {};
    let overallTotal = 0;
    let _std = 0;
    let _ltd = 0;
    let _laddMember = 0;
    let _laddSpouse = 0;
    let _laddDependent = 0;
    if (report) {
      for (let member of report) {
        if (member?.enrollmentStateId == 1) notSubmitted += 1;
        else if (member?.enrollmentStateId == 2) {
          // console.log('submissiondate', member?.submissionDate);
          let preFormatDate = new Date(
            `${
              member?.submissionDate && member.submissionDate.slice(0, 10)
            }T00:00:00`
          );
          let groupPlanId = member?.groupPlanId || props.groupPlanId;
          let cleanDate = `${
            preFormatDate.getMonth() + 1
          }/${preFormatDate.getDate()}/${preFormatDate.getFullYear()}`;
          // if (submittedDates[groupPlanId]) {
          //   if (submittedDates[groupPlanId][cleanDate]) {
          //     submittedDates[groupPlanId][cleanDate] += 1;
          //   } else {
          //     submittedDates[groupPlanId][cleanDate] = 1;
          //   }
          // } else {
          //   submittedDates[groupPlanId] = {};
          //   submittedDates[groupPlanId][cleanDate] = 1;
          // }
          if (submittedDates[cleanDate]) {
            submittedDates[cleanDate]['count'] += 1;
            if (submittedDates[cleanDate][groupPlanId]) {
              submittedDates[cleanDate][groupPlanId] += 1;
            } else {
              submittedDates[cleanDate][groupPlanId] = 1;
            }
          } else {
            submittedDates[cleanDate] = {};
            submittedDates[cleanDate]['count'] = 0;
            submittedDates[cleanDate][groupPlanId] = 1;
          }

          submitted += 1;
          if (member?.stdPlan != null) {
            _std += 1;
          }
          if (member?.ltdPlan != null) {
            _ltd += 1;
          }
          if (member?.memberCoverageAmount > 0) {
            _laddMember += 1;
          }
          if (member?.spouseCoverageAmount > 0) {
            _laddSpouse += 1;
          }
          if (member?.dependentCoverageAmount > 0) {
            _laddDependent += 1;
          }
        }
      }
    }

    setLadd({
      ...ladd,
      member: _laddMember,
      spouse: _laddSpouse,
      dependent: _laddDependent,
    });
    setStd(_std);
    setLtd(_ltd);
    setLineOfCoverage([
      { lineOfCoverage: 'Short Term Disability', count: _std },
      { lineOfCoverage: 'Long Term Disability', count: _ltd },
      { lineOfCoverage: 'Life & AD&D', count: _laddMember },
      { lineOfCoverage: 'Life & AD&D Spouse', count: _laddSpouse },
      { lineOfCoverage: 'Life & AD&D Dependent', count: _laddDependent },
    ]);

    setEnrollmentStatus([
      {
        status: 'Submitted',
        count: submitted,
      },
      {
        status: 'In Progress',
        count: notSubmitted,
      },
    ]);

    // let _enrollDateObj = {};
    let _enrollDate = [];
    // console.log('submittedDates', submittedDates);
    // for (let [key, topVal] of Object.entries(submittedDates)) {
    //   let groupPlanId = key;
    console.log('submittedDates', submittedDates);
    for (let [key, val] of Object.entries(submittedDates)) {
      let date = key;
      let obj = val;

      if (new Date(date).getTime() > new Date(windowDateRangeMax).getTime())
        windowDateRangeMax = new Date(date);
      if (
        new Date(date).getTime() < new Date(windowDateRangeMin).getTime() &&
        new Date(date).getTime() > new Date('01/01/2020').getTime()
      )
        windowDateRangeMin = new Date(date);
      let dateObj = {
        date: new Date(date).getTime(),
        count: 0,
        total: 0,
      };
      for (let [key, val] of Object.entries(obj)) {
        if (key == 'count') continue;
        let keyStr = `count${key}`;
        dateObj[keyStr] = val;
        dateObj['count'] += val;
      }
      overallTotal += dateObj.count;
      dateObj.total = overallTotal;
      _enrollDate.push(dateObj);
    }
    // }

    setEnrollDate(_enrollDate);
    setWindowDateRange({
      min: windowDateRangeMin,
      max: windowDateRangeMax,
    });
    if (dateRange.name == 'All') {
      setDateRange({
        name: 'All',
        startDate: windowDateRangeMin,
        endDate: windowDateRangeMax,
      });
    }
    // setEnrollDate(_enrollDateObj);
    // console.log('EnrollDate being fed to chart', _enrollDate);
    setLoading(false);
    // console.log('windowDateRange', windowDateRange);
  };

  const rebuildCoverageReport = () => {
    // console.log('rebuildCoverageReport');
    setLoading(true);
    let submitted = 0;
    let notSubmitted = 0;
    let _std = 0;
    let _ltd = 0;
    let _laddMember = 0;
    let _laddSpouse = 0;
    let _laddDependent = 0;
    if (report) {
      for (let member of report) {
        if (
          new Date(member?.validFrom).getTime() <
            new Date(dateRange.endDate).getTime() &&
          new Date(member?.validFrom).getTime() >
            new Date(dateRange.startDate).getTime()
        ) {
          if (member?.enrollmentStateId == 1) notSubmitted += 1;
          else if (member?.enrollmentStateId == 2) {
            // console.log('submissiondate', member?.submissionDate);
            let preFormatDate = new Date(
              `${
                member?.submissionDate && member.submissionDate.slice(0, 10)
              }T00:00:00`
            );
            let groupPlanId = member?.groupPlanId || props.groupPlanId;
            let cleanDate = `${
              preFormatDate.getMonth() + 1
            }/${preFormatDate.getDate()}/${preFormatDate.getFullYear()}`;
            // if (submittedDates[groupPlanId]) {
            //   if (submittedDates[groupPlanId][cleanDate]) {
            //     submittedDates[groupPlanId][cleanDate] += 1;
            //   } else {
            //     submittedDates[groupPlanId][cleanDate] = 1;
            //   }
            // } else {
            //   submittedDates[groupPlanId] = {};
            //   submittedDates[groupPlanId][cleanDate] = 1;
            // }

            submitted += 1;
            if (member?.stdPlan != null) {
              _std += 1;
            }
            if (member?.ltdPlan != null) {
              _ltd += 1;
            }
            if (member?.memberCoverageAmount > 0) {
              _laddMember += 1;
            }
            if (member?.spouseCoverageAmount > 0) {
              _laddSpouse += 1;
            }
            if (member?.dependentCoverageAmount > 0) {
              _laddDependent += 1;
            }
          }
        }
      }
      setLadd({
        ...ladd,
        member: _laddMember,
        spouse: _laddSpouse,
        dependent: _laddDependent,
      });
      setStd(_std);
      setLtd(_ltd);
      setLineOfCoverage([
        { lineOfCoverage: 'Short Term Disability', count: _std },
        { lineOfCoverage: 'Long Term Disability', count: _ltd },
        { lineOfCoverage: 'Life & AD&D', count: _laddMember },
        { lineOfCoverage: 'Life & AD&D Spouse', count: _laddSpouse },
        { lineOfCoverage: 'Life & AD&D Dependent', count: _laddDependent },
      ]);
      setEnrollmentStatus([
        {
          status: 'Submitted',
          count: submitted,
        },
        {
          status: 'In Progress',
          count: notSubmitted,
        },
      ]);
      // console.log('rebuildCoverageReport', submitted, notSubmitted);
    }
    setLoading(false);
  };

  useEffect(() => {
    createReports();
  }, [report]);

  let data = [
    {
      Id: 0,
      MemberId: 0,
      LTDPlan: '',
      STDPlan: '',
      MemberCoverageAmount: 0,
      SpouseCoverageAmount: 0,
      DependentCoverageAmount: 0,
      SubmissionDate: '',
      SubmissionStatusId: 0,
      SubmissionStatus: 'Submitted',
    },
    {
      Id: 1,
      MemberId: 1,
      LTDPlan: '',
      STDPlan: '',
      MemberCoverageAmount: 0,
      SpouseCoverageAmount: 0,
      DependentCoverageAmount: 0,
      SubmissionDate: '',
      SubmissionStatusId: 1,
      SubmissionStatus: 'In Progress',
    },
  ];
  let theme = useTheme();

  const COLORS = [
    theme?.palette?.primary?.main,
    '#906FDC',
    '#5387FC',
    theme?.palette?.secondary?.main,
  ];
  const circleSize = 300;

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      console.log('CustomTooltip', active, payload, label);
      let _label = new Date(label);
      if (_label == 'Invalid Date') _label = label;
      else {
        _label = `${
          _label.getMonth() + 1
        }/${_label.getDate()}/${_label.getFullYear()}`;
      }
      return (
        <Paper
          sx={{
            padding: '20px',
            '& p': { marginBottom: '10px' },
            '& span': { fontWeight: 600 },
          }}
        >
          <p className="label">{`${_label}`}</p>

          {payload[0].dataKey == 'total' ? (
            <p>
              {/* <Icon fontSize="small">
                  <Circle
                    sx={{ transform: 'scale(.4)', color: count[1].color }}
                  />
                </Icon> */}
              <span>{formatterSmall.format(payload[0].value)}</span> Total
              Enrollment(s)
            </p>
          ) : (
            Object.entries(payload[0].payload).map((key, value) => {
              console.log('MAPPPPPP', key, value);
              let groupPlanId = key[0].replace('count', '');

              let groupPlan = props.groupPlans.find(
                (groupPlan) => groupPlan.id == groupPlanId
              );
              if (!groupPlan) groupPlan = { name: 'Total' };
              if (key[0] == 'date') return null;
              if (key[0] == 'total') return null;
              if (key[0] == 'lineOfCoverage') return null;
              return (
                <p>
                  {/* <Icon fontSize="small">
                  <Circle
                    sx={{ transform: 'scale(.4)', color: count[1].color }}
                  />
                </Icon> */}
                  <span>{formatterSmall.format(key[1])}</span> {groupPlan?.name}{' '}
                  Enrollment(s)
                </p>
              );
            })
          )}
        </Paper>
      );
    }

    return null;
  };

  const [enrollmentGoal, setEnrollmentGoal] = useState([]);
  const getGoals = () => {
    let _enrollmentGoal = props?.goals?.find(
      (goal) => goal?.name == 'Total Enrollments'
    );
    setEnrollmentGoal(_enrollmentGoal?.goal);
  };

  const [value, setValue] = React.useState(1);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    getGoals();
  }, [props.goals]);

  //Date logic
  let today = new Date();
  const preSelectedDateRange = [
    {
      name: 'All',
      startDate: new Date(windowDateRange.min),
      endDate: new Date(windowDateRange.max),
    },
    {
      name: 'Past month',
      startDate: new Date(
        today.getFullYear(),
        today.getMonth() - 1,
        today.getDate()
      ),
      endDate: today,
    },
    {
      name: 'Past 6 months',
      startDate: new Date(
        today.getFullYear(),
        today.getMonth() - 6,
        today.getDate()
      ),
      endDate: today,
    },
    {
      name: 'Past 12 months',
      startDate: new Date(
        today.getFullYear(),
        today.getMonth() - 12,
        today.getDate()
      ),

      endDate: today,
    },
    {
      name: 'Past 24 months',
      startDate: new Date(
        today.getFullYear(),
        today.getMonth() - 24,
        today.getDate()
      ),

      endDate: today,
    },
    {
      name: 'Custom',
      startDate: null,
      endDate: null,
    },
  ];
  const [dateRange, setDateRange] = useState(preSelectedDateRange[0]);
  const [historyError, setHistoryError] = useState(null);

  const clearDateRangeFilter = async () => {
    setLoading(true);
    setDateRange({
      name: 'Past 24 months',
      startDate: preSelectedDateRange[4].startDate,
      endDate: preSelectedDateRange[4].endDate,
    });

    setLoading(false);
  };
  //handle pre-selected date range change
  const handleUpdatePreSelectedDateRange = async (e) => {
    setLoading(true);
    let selectedDateRange = preSelectedDateRange?.find(
      (range) => range.name === e.target.value
    );

    if (selectedDateRange?.name === 'Custom') {
      setDateRange({
        ...dateRange,
        name: 'Custom',
        startDate: dateRange.startDate,
        endDate: dateRange.endDate,
      });

      setLoading(false);
      return;
    }
    setDateRange(selectedDateRange);

    setLoading(false);
  };

  const [dateErrorOne, setDateErrorOne] = useState(null);
  const [dateErrorTwo, setDateErrorTwo] = useState(null);

  //Update history data with custom dates
  const handleDateRangeChange = async (e, type) => {
    setLoading(true);
    if (e?.getTime() != e?.getTime()) {
      // console.log('Invalid date entered....returning');
      // alert("Invalid date entered")
      return;
    }
    let dateSelected = e;
    let validDate = dateSelected ? dateSelected.toJSON() : null;
    //issue with dobErrorOne and two, both are a step behind showing the error
    // console.log("dateSelected TO JSON", validDate)
    // console.log("dateErrorOne", dateErrorOne)
    // console.log("dateErrorTwo", dateErrorTwo)

    if (
      validDate
      // &&  !dateErrorOne
      // &&  !dateErrorTwo
    ) {
      setLoading(true);
      //update date range state
      setDateRange({
        ...dateRange,
        [type]: dateSelected,
        name: 'Custom',
      });
    } else {
      //invalid date entered
      //update date range state
      setDateRange({
        ...dateRange,
        [type]: null,
        name: 'Custom',
      });
      setLoading(false);
    }
    setLoading(false);
  };

  useEffect(() => {
    rebuildCoverageReport();
  }, [dateRange.startDate, dateRange.endDate]);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  useEffect(() => {
    setLoading(true);
    window.addEventListener('resize', () => {
      setWindowWidth(window.innerWidth);
      setWindowHeight(window.innerHeight);
    });
    return () => {
      setLoading(false);
      window.removeEventListener('resize', () => {
        setWindowWidth(window.innerWidth);
        setWindowHeight(window.innerHeight);
      });
    };
  }, []);

  const [hoveredPlan, setHoveredPlan] = useState(null);

  return (
    <>
      {loading && (
        <>
          <InfinityLoader
            style={reportingDashboardStyles.individualReportInfinityLoader}
          />
          <style>
            {`
                  div.lottie-div > svg {
                     height:55px!important;
                     position:absolute;
                     top:30%;
                  }
               `}
          </style>
        </>
      )}
      {/* <Box sx={{ color: 'text.primary', fontFamily: 'Archivo' }}>
            <ResponsiveContainer width={"100%"} height={300} min-width={300}>
               <PieChart >
                  <Pie data={enrollmentStatus} dataKey='count' nameKey='status' cx='50%' cy='80%' fill={theme?.palette?.secondary?.main} label='status' outerRadius={90} innerRadius={60} startAngle={180} endAngle={0}>
                     {enrollmentStatus.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />

                     ))}
                  </Pie>
                  <Legend />
               </PieChart>
            </ResponsiveContainer>
            </Box> */}

      <Box
        sx={{
          color: 'text.primary',
          fontFamily: 'Archivo',
          margin: 'auto',
          display: 'flex',
          justifyContent: 'center',
          flexBasis: 'content',
          marginBottom: '20px',
        }}
      >
        <ResponsiveContainer
          width={circleSize}
          height={circleSize / 1.5}
          min-width={circleSize}
          min-height={400}
        >
          <RadialBarChart
            // width={circleSize}
            // height={circleSize}
            cx={circleSize / 2}
            cy={circleSize / 2}
            innerRadius={circleSize / 2.8}
            outerRadius={circleSize / 1.8}
            barSize={25}
            data={enrollmentStatus.filter(
              (enrollment) => enrollment.status == 'Submitted'
            )}
            startAngle={180}
            endAngle={0}
          >
            <>
              <PolarAngleAxis
                type="number"
                domain={[0, enrollmentGoal]}
                angleAxisId={0}
                dataKey="count"
                tick={false}
              />
              <RadialBar
                background={{
                  fill:
                    theme?.palette?.mode == 'light'
                      ? theme?.palette?.background?.background
                      : theme?.palette?.background?.background,
                }}
                clockWise
                dataKey="count"
                cornerRadius={circleSize / 2}
                key={`cell-${data.status}`}
                fill={theme?.palette?.primary?.main}
              />
            </>

            <text
              x={circleSize / 2}
              y={circleSize / 2.6}
              style={{ fontWeight: '600', fontSize: '25px' }}
              fill={theme?.palette?.secondary?.main}
              textAnchor="middle"
              dominantBaseline="middle"
              className="progress-label"
            >
              {enrollmentStatus &&
                enrollmentStatus?.find(
                  (enrollment) => enrollment.status == 'Submitted'
                )?.count}
            </text>
            <text
              x={circleSize / 2}
              y={circleSize / 2.1}
              textAnchor="middle"
              dominantBaseline="middle"
              className="progress-label"
              fill={theme?.palette?.text?.primary}
            >
              Submitted Enrollments
            </text>
            <text
              x={circleSize / 7}
              y={circleSize / 1.85}
              textAnchor="middle"
              dominantBaseline="middle"
              className="progress-label"
              fill={theme?.palette?.text?.primary}
              style={{ fontSize: '12px' }}
            >
              0
            </text>
            <text
              x={circleSize / 1.17}
              y={circleSize / 1.85}
              textAnchor="middle"
              dominantBaseline="middle"
              className="progress-label"
              fill={theme?.palette?.text?.primary}
              style={{ fontSize: '12px' }}
            >
              {enrollmentGoal}
            </text>
          </RadialBarChart>
        </ResponsiveContainer>
        <Paper elevation={0} sx={{ margin: 'auto', padding: '20px' }}>
          <Typography
            textAlign={'center'}
            color={theme?.palette?.secondary?.main}
            fontSize={25}
          >
            {enrollmentStatus &&
              enrollmentStatus?.find(
                (enrollment) => enrollment.status == 'In Progress'
              )?.count}
          </Typography>
          <Typography textAlign={'center'}>in progress enrollments</Typography>
          {/* <Button sx={{marginTop:'10px'}} variant='outlined' disabled={enrollmentStatus && enrollmentStatus?.find(enrollment => enrollment.status == 'In Progress')?.count < 1}>Download In Progress Report</Button> */}
        </Paper>
      </Box>
      <Box
        sx={{ marginBottom: '20px', justifyContent: 'space-between' }}
        className="flex-row"
      >
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="disabled tabs example"
        >
          <Tab value={1} label="Enrollments by Date" />
          <Tab value={2} label="Enrollments by Line of Coverage" />
          <Tab value={3} label="Total Growth" />
        </Tabs>

        <Box sx={componentStyles.dateAndButtonContainer}>
          <Box sx={componentStyles.dateSelectorContainer}>
            {/* pre selected date range menu */}
            <TextField
              id="date"
              label="Date Range"
              select
              value={dateRange?.name || 'Custom'}
              defaultValue={'All'}
              InputLabelProps={{
                shrink: true,
              }}
              sx={componentStyles.preSelectedDatePicker}
              onChange={handleUpdatePreSelectedDateRange}
            >
              {preSelectedDateRange.map((option, index) => (
                <MenuItem
                  key={index}
                  value={option?.name}
                  sx={{ fontSize: '14px' }}
                >
                  {option.name}
                </MenuItem>
              ))}
            </TextField>

            {/* start date*/}

            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                openTo="day"
                label="Start Date"
                views={['year', 'month', 'day']}
                minDate={windowDateRange.min}
                maxDate={today}
                value={dateRange?.startDate || null}
                disableFuture
                onChange={(e) => handleDateRangeChange(e, 'startDate')}
                format="MM/dd/yyyy"
                sx={componentStyles.customDatePicker}
                onError={(error) => setDateErrorOne(error)}
                slotProps={{
                  textField: {
                    InputLabelProps: {
                      shrink: true,
                    },
                  },
                }}
              />
            </LocalizationProvider>

            {/* end date */}
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                openTo="day"
                label="End Date"
                views={['year', 'month', 'day']}
                minDate={windowDateRange.min}
                maxDate={today}
                value={dateRange?.endDate || null}
                disableFuture
                onChange={(e) => handleDateRangeChange(e, 'endDate')}
                format="MM/dd/yyyy"
                sx={componentStyles.customDatePicker}
                onError={(error) => setDateErrorTwo(error)}
                slotProps={{
                  textField: {
                    InputLabelProps: {
                      shrink: true,
                    },
                  },
                }}
              />
            </LocalizationProvider>

            {/* Clear Custom Dates */}
            {dateRange?.name === 'Custom' && (
              <Box sx={componentStyles.clearDateButtonContainer}>
                <IconButton
                  onClick={clearDateRangeFilter}
                  sx={componentStyles.clearDateButton}
                >
                  <CloseIcon sx={componentStyles.closeIcon} />
                  <Typography sx={componentStyles.clearDateText}>
                    CLEAR FILTER
                  </Typography>
                </IconButton>
              </Box>
            )}
          </Box>
        </Box>
      </Box>

      {console.log('enrollDate', enrollDate)}

      {value == 1 && (
        <>
          {Object.keys(enrollDate)?.length > 0 && (
            <Box sx={{ color: 'text.primary', fontFamily: 'Archivo' }}>
              <ResponsiveContainer
                width={windowWidth * 0.93}
                // width="90%"
                // min-height={400}
                height={windowHeight * 0.5}
                // aspect={3.5}
                // min-width={600}
              >
                <BarChart
                  data={enrollDate}
                  margin={{ top: 20, bottom: 20, left: 20, right: 20 }}
                  barGap={0}
                >
                  <XAxis
                    dataKey={'date'}
                    type="number"
                    tickFormatter={(unixTime) => {
                      let date = new Date(unixTime);
                      return `${
                        date.getMonth() + 1
                      }/${date.getDate()}/${date.getFullYear()}
                      `;
                    }}
                    label={{
                      value: 'Submission Date',
                      offset: '2',
                      position: 'bottom',
                      fill: theme?.palette?.text?.primary,
                    }}
                    domain={[
                      dateRange.startDate.getTime() - 43200000,
                      dateRange.endDate.getTime() + 43200000,
                    ]}
                    allowDataOverflow={true}
                  ></XAxis>
                  <YAxis
                    interval={0}
                    // minTickGap={300}
                    tickCount={5}
                    // ticks={[1, 10, 50, 150, 500, 1000]}
                    allowDataOverflow={true}
                    label={{
                      value: 'Submitted Enrollments',
                      angle: -90,
                      position: 'insideBottomLeft',
                      fill: theme?.palette?.text?.primary,
                    }}
                    domain={[0.5, 'dataMax']}
                    scale={'sqrt'}
                  ></YAxis>
                  <Tooltip
                    // allowEscapeViewBox={{ x: false, y: true }}
                    position={{ y: 0 }}
                    isAnimationActive={false}
                    content={<CustomTooltip />}
                  />
                  {/* <Bar dataKey={'count'} fill={theme?.palette?.primary?.main}>
                    <LabelList dataKey="count" position="top" />{' '}
                  </Bar> */}
                  <Bar
                    // onAnimationStart={() => setLoading(true)}
                    // onAnimationEnd={() => setLoading(false)}
                    isAnimationActive={false}
                    // key={index}
                    // type={'monotone'}
                    dataKey={`count`}
                    // stroke={COLORS[index % COLORS.length]}
                    // fill={`#${Math.floor(Math.random() * 0x195ffb)
                    //   .toString(16)
                    //   .padStart(6, '0')}`}
                    fill={theme?.palette?.primary?.main}
                    // fill={
                    //   hoveredPlan == null
                    //     ? COLORS[index % COLORS.length]
                    //     : hoveredPlan == `count${entry.id}`
                    //     ? '#78000a'
                    //     : COLORS[index % COLORS.length] + '8A'
                    // }
                    // fill={color}
                    // dot={false}
                    barSize={200}
                  ></Bar>
                  {/* {props.groupPlans.map((entry, index) => {
                    return (
                      <Bar
                        // onAnimationStart={() => setLoading(true)}
                        // onAnimationEnd={() => setLoading(false)}
                        isAnimationActive={false}
                        key={index}
                        stackId={'a'}
                        // type={'monotone'}
                        dataKey={`count${entry.id}`}
                        // stroke={COLORS[index % COLORS.length]}
                        // fill={`#${Math.floor(Math.random() * 0x195ffb)
                        //   .toString(16)
                        //   .padStart(6, '0')}`}
                        fill={
                          hoveredPlan == null
                            ? COLORS[index % COLORS.length]
                            : hoveredPlan == `count${entry.id}`
                            ? '#78000a'
                            : COLORS[index % COLORS.length] + '8A'
                        }
                        // fill={color}
                        // dot={false}
                        barSize={200}
                      ></Bar>
                    );
                  })} */}
                  {/* <Line type={'monotone'} dataKey={'count'}> */}
                  {/* <LabelList dataKey="count" position="top" />{' '} */}
                  {/* </Line> */}
                </BarChart>
              </ResponsiveContainer>
            </Box>
          )}
        </>
      )}
      {value == 2 && (
        <>
          {lineOfCoverage.length > 0 && (
            // <Box sx={{ color: 'text.primary', fontFamily: 'Archivo' }}>
            //    <ResponsiveContainer width={"100%"} height={300} min-width={600}>
            //       <BarChart data={lineOfCoverage} margin={{ top: 20, bottom: 20, left: 20, right: 20 }}>
            //          {/* <CartesianGrid strokeDasharray={'3'} /> */}
            //          <XAxis dataKey={'lineOfCoverage'} label={{ value: 'Line of Coverage', position: 'bottom', fill: theme?.palette?.text?.primary }} >
            //          </XAxis>
            //          <YAxis dataKey={'count'} interval={0} tickCount={6} label={{ value: 'Submitted Enrollments', angle: -90, position: 'insideBottomLeft', fill: theme?.palette?.text?.primary }}>
            //          </YAxis>
            //          <Tooltip content={<CustomTooltip />} />
            //          <Bar dataKey={'count'} fill={theme?.palette?.primary?.main} >
            //             <LabelList dataKey='count' position="top" /> </Bar>
            //       </BarChart>
            //    </ResponsiveContainer>
            // </Box>

            <Box sx={{ color: 'text.primary', fontFamily: 'Archivo' }}>
              <ResponsiveContainer
                width={windowWidth * 0.9}
                // min-height={400}
                height={windowHeight * 0.5}
              >
                <BarChart
                  label={{ fill: theme?.palette?.text?.primary }}
                  layout="vertical"
                  data={lineOfCoverage}
                  margin={{ top: 20, bottom: 20, left: 20, right: 20 }}
                >
                  {/* <CartesianGrid strokeDasharray={'3'} /> */}
                  <XAxis
                    type="number"
                    dataKey={'count'}
                    interval={0}
                    tickCount={6}
                    label={{
                      value: 'Submitted Enrollments',
                      angle: 0,
                      position: 'bottom',
                      fill: theme?.palette?.text?.primary,
                    }}
                  ></XAxis>
                  <YAxis
                    width={200}
                    type="category"
                    dataKey={'lineOfCoverage'}
                    tick={{ fill: theme?.palette?.text?.primary }}
                  ></YAxis>
                  <Tooltip
                    isAnimationActive={false}
                    content={<CustomTooltip />}
                  />
                  <Bar
                    dataKey={'count'}
                    fill={theme?.palette?.primary?.main}
                  ></Bar>
                </BarChart>
              </ResponsiveContainer>
            </Box>
          )}
        </>
      )}
      {value == 3 && (
        <>
          {Object.keys(enrollDate)?.length > 0 && (
            <Box sx={{ color: 'text.primary', fontFamily: 'Archivo' }}>
              <ResponsiveContainer
                width={windowWidth * 0.93}
                // width="90%"
                // min-height={400}
                height={windowHeight * 0.5}
                // aspect={3.5}
                // min-width={600}
              >
                <LineChart
                  data={enrollDate}
                  margin={{ top: 20, bottom: 20, left: 20, right: 20 }}
                  barGap={0}
                >
                  <XAxis
                    dataKey={'date'}
                    type="number"
                    tickCount={8}
                    tickFormatter={(unixTime) => {
                      let date = new Date(unixTime);
                      return `${
                        date.getMonth() + 1
                      }/${date.getDate()}/${date.getFullYear()}
                      `;
                    }}
                    label={{
                      value: 'Submission Date',
                      offset: '2',
                      position: 'bottom',
                      fill: theme?.palette?.text?.primary,
                    }}
                    domain={[
                      dateRange.startDate.getTime() - 43200000,
                      dateRange.endDate.getTime() + 43200000,
                    ]}
                    allowDataOverflow={true}
                  ></XAxis>
                  <YAxis
                    interval={0}
                    // minTickGap={300}
                    tickCount={5}
                    // ticks={[1, 10, 50, 150, 500, 1000]}
                    // allowDataOverflow={true}
                    label={{
                      value: 'Submitted Enrollments',
                      angle: -90,
                      position: 'insideBottomLeft',
                      fill: theme?.palette?.text?.primary,
                    }}
                    tickFormatter={(value) => {
                      if (value > 1000) {
                        return formatter.format(value);
                      } else {
                        return value;
                      }
                    }}
                    // domain={[0.5, 'dataMax']}
                    // scale={'sqrt'}
                  ></YAxis>
                  {/* <Tooltip content={<CustomTooltip />} /> */}
                  <Tooltip
                    isAnimationActive={false}
                    content={<CustomTooltip />}
                  />
                  {/* <Bar dataKey={'count'} fill={theme?.palette?.primary?.main}>
                    <LabelList dataKey="count" position="top" />{' '}
                  </Bar> */}
                  <Line
                    // onAnimationStart={() => setLoading(true)}
                    // onAnimationEnd={() => setLoading(false)}
                    isAnimationActive={false}
                    // key={index}
                    type={'monotone'}
                    dataKey={`total`}
                    // stroke={COLORS[index % COLORS.length]}

                    stroke={theme?.palette?.primary?.main}
                    strokeWidth={'4px'}
                    dot={false}
                  ></Line>
                  {/* {props.groupPlans.map((entry, index) => {
                    return (
                      <Bar
                        // onAnimationStart={() => setLoading(true)}
                        // onAnimationEnd={() => setLoading(false)}
                        isAnimationActive={false}
                        key={index}
                        stackId={'a'}
                        // type={'monotone'}
                        dataKey={`count${entry.id}`}
                        // stroke={COLORS[index % COLORS.length]}
                        // fill={`#${Math.floor(Math.random() * 0x195ffb)
                        //   .toString(16)
                        //   .padStart(6, '0')}`}
                        fill={
                          hoveredPlan == null
                            ? COLORS[index % COLORS.length]
                            : hoveredPlan == `count${entry.id}`
                            ? '#78000a'
                            : COLORS[index % COLORS.length] + '8A'
                        }
                        // fill={color}
                        // dot={false}
                        barSize={200}
                      ></Bar>
                    );
                  })} */}
                  {/* <Line type={'monotone'} dataKey={'count'}> */}
                  {/* <LabelList dataKey="count" position="top" />{' '} */}
                  {/* </Line> */}
                </LineChart>
              </ResponsiveContainer>
            </Box>
          )}
        </>
      )}
    </>
  );
}
