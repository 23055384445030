//MuiIcons
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined"

//Mui Components
import Chip from "@mui/material/Chip"

export default function NonPeakPayChip(props) {
  return (
    <>
      <Chip
        icon={
          <InfoOutlinedIcon
            sx={{ height: 24, color: "rgba(255, 255, 255, 1)!important" }}
          />
        }
        sx={{
          backgroundColor: "error.main",
          color: "rgba(255, 255, 255, 1)",
          border: "none",
          fontWeight: "500",
          fontFamily: "Archivo",
          lineHeight: "18px",
          letterSpacing: "0.16px",
          fontSize: "13px",
          height: "30px",
          borderRadius: "8px",
        }}
        label={"Member not on PeakPay"}
      />
    </>
  )
}
