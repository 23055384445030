import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

//Services
import enrollmentService from '../../../../services/enrollment-service';

// //Mui Components
import { Alert, Box, Typography } from '@mui/material';

//Our Components
import EnrollmentStd from './plan-components/EnrollmentSTD';
import EnrollmentStdIncremental from './plan-components/EnrollmentSTDIncremental';
import EnrollmentLtd from './plan-components/EnrollmentLTD';
import EnrollmentLtdIncremental from './plan-components/EnrollmentLTDIncremental';
import EnrollmentLadd from './plan-components/EnrollmentLADD';
import EnrollmentPayment from './payment-components/payScheduleAndBank-components/EnrollmentPayment';
import AgentNavigation from '../../../app-internal/Member/member-profile/MemberTabs/agent-enroll-tab/AgentNavigation';
import DrawerContainer from '../enrollment-drawers/DrawerContainer';
import ExternalEnrollmentHeader from '../ExternalEnrollmentHeader';
import EnrollmentAccident from './plan-components/EnrollmentAccident';
import EnrollmentCancer from './plan-components/EnrollmentCancer';
import EnrollmentADD from './plan-components/EnrollmentADD';
import EnrollmentHospital from './plan-components/EnrollmentHospital';
import StickyProgressTotal from '../../../app-external/external-enrollments/StickyProgressTotal';

//Shared styles
import externalEnrollmentSharedStyles from '../../externalEnrollmentSharedStyles';
import EnrollmentCriticalIllness from './plan-components/EnrollmentCriticalIllness';
import EnrollmentMembership from './plan-components/EnrollmentMembership';

export default function EnrollmentsPlans(props) {
  let history = useHistory();

  // console.log("spouse info in memberPlans", props.spouse)

  const [expanded, setExpanded] = useState([]);

  const [showPayment, setShowPayment] = useState(false);

  const handleEnrollClick = async (memberOptId) => {
    let groupPlanId = props.results.groupPlanId;
    console.log('handleEnrollClick', memberOptId, '\n\n');
    // If accident plan option was selected and determine option id number
    // console.log("ID actually selected: \n\n", memberOptId);
    if (props.results?.accidentPlan) {
      let _accidentSelection = props.results?.accidentPlan?.options.map(
        (option) => {
          if (memberOptId === option.memberOptionId) return `member`;
          else if (memberOptId === option.memberAndSpouseOptionId)
            return `member and spouse`;
          else if (memberOptId === option.memberAndDependentsOptionId)
            return `member and dependents`;
          else if (memberOptId === option.familyOptionId) return `family`;
          else return null;
        }
      );

      props.setAccidentSelection(_accidentSelection);
    }

    if (props.results?.cancerPlan) {
      let _cancerSelection = props.results?.cancerPlan?.options.map(
        (option) => {
          if (memberOptId === option.memberOptionId) return `member`;
          else if (memberOptId === option.memberAndSpouseOptionId)
            return `member and spouse`;
          else if (memberOptId === option.memberAndDependentsOptionId)
            return `member and dependents`;
          else if (memberOptId === option.familyOptionId) {
            let onlyFamilyAndMemberAvailable =
              !option.memberAndDependentsOptionId > 0 &&
              !option.memberAndSpouseOptionId > 0 &&
              option.familyOptionId > 0 &&
              option.memberOptionId > 0;
            if (onlyFamilyAndMemberAvailable) return 'familyOptional';
            else return 'family';
          } else return null;
        }
      );

      props.setCancerSelection(_cancerSelection);
    }

    if (props.results?.addPlan) {
      let _addSelection = props.results?.addPlan?.options.map((option) => {
        if (memberOptId === option.memberOptionId) return `member`;
        else if (memberOptId === option.memberAndSpouseOptionId)
          return `member and spouse`;
        else if (memberOptId === option.memberAndDependentsOptionId)
          return `member and dependents`;
        else if (memberOptId === option.familyOptionId) {
          let onlyFamilyAndMemberAvailable =
            !option.memberAndDependentsOptionId > 0 &&
            !option.memberAndSpouseOptionId > 0 &&
            option.familyOptionId > 0 &&
            option.memberOptionId > 0;
          if (onlyFamilyAndMemberAvailable) return 'familyOptional';
          else return 'family';
        } else return null;
      });

      props.setAddSelection(_addSelection);
    }

    if (props.results?.hospitalPlan) {
      let _hospitalSelection = props.results?.hospitalPlan?.options.map(
        (option) => {
          if (memberOptId === option.memberOptionId) return `member`;
          else if (memberOptId === option.memberAndSpouseOptionId)
            return `member and spouse`;
          else if (memberOptId === option.memberAndDependentsOptionId)
            return `member and dependents`;
          else if (memberOptId === option.familyOptionId) {
            let onlyFamilyAndMemberAvailable =
              !option.memberAndDependentsOptionId > 0 &&
              !option.memberAndSpouseOptionId > 0 &&
              option.familyOptionId > 0 &&
              option.memberOptionId > 0;
            if (onlyFamilyAndMemberAvailable) return 'familyOptional';
            else return 'family';
          } else return null;
        }
      );

      props.setHospitalSelection(_hospitalSelection);
    }
    //response from POST to db
    let response;
    if (props.agentEnroller) {
      // console.log("is agent full member object info:\n\n", props.fullMemberObj);

      response = await enrollmentService.saveOptionForMember(
        props.fullMemberObj.id,
        groupPlanId,
        memberOptId
      );

      props.setEnrollmentCreated((prevState) => {
        if (prevState === null || prevState === true) {
          return prevState;
        } else {
          return true;
        }
      });
    } else {
      response = await enrollmentService.saveOption(groupPlanId, memberOptId);
    }
    return response;
  };

  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    if (props.userInfo && !loaded) {
      props.updateResults();
      window.scrollTo(0, 0);
      setLoaded(true);
      console.log('loadedplansprops', props);
    }
  }, [props.userInfo]);

  const [declinedAll, setDeclinedAll] = useState(false);
  const [laddSelected, setLaddSelected] = useState(false);

  const checkDeclineAll = () => {
    // console.log(props.selections, laddSelected);
    let declinedList = [];
    if (props.results?.stdPlan) {
      if (props.selections.std != undefined && props.selections.stdVal == 0)
        declinedList[0] = true;
      else declinedList[0] = false;
    } else declinedList[0] = null;

    if (props.results?.ltdPlan) {
      if (props.selections.ltd != undefined && props.selections.ltdVal == 0)
        declinedList[1] = true;
      else declinedList[1] = false;
    } else declinedList[1] = null;

    if (props.results?.lifeADDPlan) {
      if (laddSelected && props.selections.ladd[0] == 0) declinedList[2] = true;
      else declinedList[2] = false;
    } else declinedList[2] = null;

    if (props.results?.accidentPlan) {
      if (
        props.selections.accident != undefined &&
        props.selections.accidentVal == 0
      )
        declinedList[3] = true;
      else declinedList[3] = false;
    } else declinedList[3] = null;

    if (props.results?.cancerPlan) {
      if (
        props.selections.cancer != undefined &&
        props.selections.cancerVal == 0
      )
        declinedList[4] = true;
      else declinedList[4] = false;
    } else declinedList[4] = null;

    if (props.results?.addPlan) {
      if (props.selections.add != undefined && props.selections.addVal == 0)
        declinedList[5] = true;
      else declinedList[5] = false;
    } else declinedList[5] = null;

    if (props.results?.hospitalPlan) {
      if (
        props.selections.hospital != undefined &&
        props.selections.hospitalVal == 0
      )
        declinedList[5] = true;
      else declinedList[5] = false;
    } else declinedList[5] = null;

    if (props.results?.criticalIllnessPlan) {
      if (
        props.selections.criticalIllness != undefined &&
        props.selections.criticalIllnessVal == 0
      )
        declinedList[6] = true;
      else declinedList[6] = false;
    } else declinedList[6] = null;

    if (props.results?.membershipPlan) {
      if (
        props.selections.membership != undefined &&
        props.selections.membershipVal == 0
      )
        declinedList[7] = true;
      else declinedList[7] = false;
    } else declinedList[7] = null;

    let declinedAll = true;
    for (let bool of declinedList) {
      if (bool !== null && !bool) declinedAll = false;
    }

    setDeclinedAll(declinedAll);
  };

  const handleDeclineOpen = (e) => {
    setDeclinedAll(true);
  };
  const handleDeclineClose = (e) => {
    setDeclinedAll(false);
  };

  const handleDecline = async () => {
    let emptyCanvas = document.createElement('canvas');
    let signatureObj = {
      enrollmentWindowId: props?.results?.enrollmentWindowId,
      enrollmentId: props?.results?.enrollmentId,
      signatureText: 'Declined',
      signature: emptyCanvas.toDataURL('image/png'),
      signatureDate: new Date().toLocaleString().slice(0, 8),
      groupPlanId: props?.results?.groupPlanId,
      isMail: false,
      isVerbal: props.agentEnroller ? true : false,
      workStatusId: props?.fullMemberObj?.workStatusId,
      nawReason:
        props?.fullMemberObj?.workStatusId == 20
          ? props?.nawReason
          : props.fullMemberObj.workStatus,
    };
    let enrollmentResponse;
    try {
      await props.saveLadd();
    } catch (error) {
      console.log('error saving ladd in decline', error);
    }
    //submit declined enrollment
    try {
      if (props?.agentEnroller) {
        enrollmentResponse = await enrollmentService.submitEnrollmentForMember(
          signatureObj,
          props.fullMemberObj.id
        );
      } else {
        enrollmentResponse = await enrollmentService.submitEnrollment(
          signatureObj
        );
      }
    } catch (error) {
      console.log('error submitting ddeclined enrollment for member', error);
    }

    props.setGuid(enrollmentResponse);

    handleDeclineClose();

    if (props.agentEnroller) {
      props.refreshEnrollments();
      props.setAgentEnrollmentPageState({ complete: true });
    } else {
      history.push('success', { ...history.location.state });
    }
    localStorage.clear('recommendations');
    localStorage.clear('laddDecline');
  };

  useEffect(() => {
    checkDeclineAll();
  }, [props.selections]);

  // useEffect(() => {
  //    if(!expanded.std && !expanded.ltd && !expanded.ladd){
  //       props.setPosition("fixed")
  //    } else {
  //       props.setPosition("sticky")
  //    }
  // },[expanded])

  const scrollListener = (e) => {
    // console.log('scroll listener',e)
    // let _scrollAtBottom = Math.abs(e.target?.scrollHeight - e.target?.scrollTop - e.target?.clientHeight) < 1
    // props.setScrollAtBottom(_scrollAtBottom)
  };

  const [planList, setPlanList] = useState([]);
  const [laddLastPlan, setLaddLastPlan] = useState(true);

  //RE-ENROLLMENT AUTO SELECT EXISTING AC for agent enrollment only
  //auto-elect existing AC at start of re-enrollment by matching coverages - only do this once before any selections are made

  useEffect(() => {
    if (
      props?.results &&
      props?.agentEnroller &&
      props?.currentActiveCoverage?.length > 0 &&
      props?.results?.enrollmentId === 0
    ) {
      let results = props?.results;
      console.log('results in auto select', results);
      //build selection obj to build and set if matches found
      let buildSelections = {};
      if (results?.membershipPlan) {
        let returnedSelections = findAndSelectMatchingCurrentAC(
          results.membershipPlan.planId,
          'membership',
          'membershipPlan'
        );
        buildSelections = { ...buildSelections, ...returnedSelections };
      }
      if (results?.stdPlan && !results.stdPlan?.isIncremental) {
        let returnedSelections = findAndSelectMatchingCurrentAC(
          results.stdPlan.planId,
          'std',
          'stdPlan'
        );
        buildSelections = { ...buildSelections, ...returnedSelections };
      }
      if (results?.stdPlan && results.stdPlan?.isIncremental) {
        let returnedSelections = findAndSelectMatchingCurrentAC(
          results.stdPlan.planId,
          'std',
          'stdPlan'
        );
        buildSelections = { ...buildSelections, ...returnedSelections };
      }
      if (results?.ltdPlan && !results.ltdPlan?.isIncremental) {
        let returnedSelections = findAndSelectMatchingCurrentAC(
          results.ltdPlan.planId,
          'ltd',
          'ltdPlan'
        );
        buildSelections = { ...buildSelections, ...returnedSelections };
      }
      if (results?.ltdPlan && results.ltdPlan?.isIncremental) {
        let returnedSelections = findAndSelectMatchingCurrentAC(
          results.ltdPlan.planId,
          'ltd',
          'ltdPlan'
        );
        buildSelections = { ...buildSelections, ...returnedSelections };
      }

      if (results?.accidentPlan) {
        let returnedSelections = findAndSelectMatchingCurrentAC(
          results.accidentPlan.planId,
          'accident',
          'accidentPlan'
        );
        buildSelections = { ...buildSelections, ...returnedSelections };
      }
      if (results?.lifeADDPlan) {
        let returnedSelections = findAndSelectMatchingCurrentAC(
          results.lifeADDPlan.planId,
          'ladd',
          'lifeADDPlan'
        );
        buildSelections = { ...buildSelections, ...returnedSelections };
      }
      if (results?.criticalIllnessPlan) {
        let returnedSelections = findAndSelectMatchingCurrentAC(
          results.criticalIllnessPlan.planId,
          'criticalIllness',
          'criticalIllnessPlan'
        );
        buildSelections = { ...buildSelections, ...returnedSelections };
      }
      if (results?.cancerPlan) {
        let returnedSelections = findAndSelectMatchingCurrentAC(
          results.cancerPlan.planId,
          'cancer',
          'cancerPlan'
        );
        buildSelections = { ...buildSelections, ...returnedSelections };
      }
      if (results?.addPlan) {
        let returnedSelections = findAndSelectMatchingCurrentAC(
          results.addPlan.planId,
          'add',
          'addPlan'
        );
        buildSelections = { ...buildSelections, ...returnedSelections };
      }
      if (results?.hospitalPlan) {
        let returnedSelections = findAndSelectMatchingCurrentAC(
          results.hospitalPlan.planId,
          'hospital',
          'hospitalPlan'
        );
        buildSelections = { ...buildSelections, ...returnedSelections };
      }
      //set selections state
      //if matching selections returned, set parent selections state and localPrevAcSelections to true
      if (Object.keys(buildSelections)?.length > 0) {
        console.log('final build selections FOUND', buildSelections);
        props.setSelections({ ...props.selections, ...buildSelections });
        setLocalPrevAcSelections(true);
      }
    }
  }, [
    props?.results,
    props?.results?.enrollmentId,
    props?.currentActiveCoverage,
  ]);

  //find and return any matching AC line of coverage where planId matches current election  planId
  const [localPrevAcSelections, setLocalPrevAcSelections] = useState(null);

  //right now matching on planId and planOptionId except for ladd where we match on planId and coverage amount
  //need to figure out where to hit db with updated selections
  const findAndSelectMatchingCurrentAC = (planId, planType, planTypeName) => {
    //planId is from enrollment results
    console.log(
      'planId run',
      planId,
      'planType',
      planType,
      'planName',
      planTypeName,
      '\n\n matching results',
      props?.results[planTypeName]
    );
    let enrollmentResults = props?.results[planTypeName];
    let currentActiveCoverage = props?.currentActiveCoverage;
    let matchingCoverage = null; //for non-ladd plans
    let matchingMemberLadd = null;
    let matchingSpouseLadd = null;
    let matchingDependentLadd = null;
    let buildMatchingSelections = {};

    //find matching coverage in currentActiveCoverage
    if (currentActiveCoverage && currentActiveCoverage.length > 0) {
      currentActiveCoverage.forEach((coverage) => {
        //look through lines of AC and compare to planId argument
        if (coverage?.linesOfCoverage?.length > 0) {
          for (let singleCoverage in coverage?.linesOfCoverage) {
            //filter out matcing plan that is NOT ladd, CI has planOptionId so should work ok right here
            if (
              coverage?.linesOfCoverage[singleCoverage]?.planId === planId &&
              coverage?.linesOfCoverage[singleCoverage]
                ?.calculatedPlanTypeId !== 4 && //non member ladd
              coverage?.linesOfCoverage[singleCoverage]
                ?.calculatedPlanTypeId !== 5 && //non spouse ladd
              coverage?.linesOfCoverage[singleCoverage]
                ?.calculatedPlanTypeId !== 6 //non dependent laddd
            ) {
              matchingCoverage = coverage?.linesOfCoverage[singleCoverage];
            } //filter out matcing ladd member
            else if (
              coverage?.linesOfCoverage[singleCoverage]?.planId === planId &&
              coverage?.linesOfCoverage[singleCoverage]
                ?.calculatedPlanTypeId === 4
            ) {
              matchingMemberLadd = coverage?.linesOfCoverage[singleCoverage];
            } //filter out matching ladd spouse
            else if (
              coverage?.linesOfCoverage[singleCoverage]?.planId === planId &&
              coverage?.linesOfCoverage[singleCoverage]
                ?.calculatedPlanTypeId === 5
            ) {
              matchingSpouseLadd = coverage?.linesOfCoverage[singleCoverage];
            } //filter out matching ladd dependent
            else if (
              coverage?.linesOfCoverage[singleCoverage]?.planId === planId &&
              coverage?.linesOfCoverage[singleCoverage]
                ?.calculatedPlanTypeId === 6
            ) {
              matchingDependentLadd = coverage?.linesOfCoverage[singleCoverage];
            }
          }
        }
      });
    }

    //NON-LADD or NON-CI matching planId found AND no current selections exist for this enrollmentId, select associated matching planOptionId
    //update setSelections
    //treat ladd and CI differently as they do not have planOptionId
    if (
      matchingCoverage &&
      !props?.selections[`${planType}`] &&
      planType !== 'ladd' &&
      planType !== 'criticalIllness' //keeping this here for now depending on how we decide to store CI in future
    ) {
      let matchingPlanOption = props?.results[planTypeName]?.options.find(
        (option) => option.memberOptionId === matchingCoverage?.planOptionId
      );
      console.log(
        'matchingPlanOption in find matching option',
        matchingPlanOption,
        planType,
        `${planType}Val`
      );
      //select any plans that are not ladd or critical illness (these are stored differently)
      if (matchingPlanOption) {
        buildMatchingSelections = {
          ...buildMatchingSelections,
          [`${planType}Val`]: matchingPlanOption.memberPremiumAmount,
          [planType]: matchingPlanOption.memberOptionId,
        };
      }
    }

    //matching planId for LADD or CI
    if (
      // matching coverage found for member, spouse or dep but corrent selection is 0, need to auto select matching plan and coverage amount
      ((matchingMemberLadd && props?.selections[`${planType}Val`][0] === 0) || //member ladd AC exists but member selection is 0
        (matchingSpouseLadd && props?.selections[`${planType}Val`][1] === 0) || //spouse ladd AC exists but spouse selection is 0
        (matchingDependentLadd &&
          props?.selections[`${planType}Val`][2] === 0)) && //dependentAC exists but dependent selection is 0 //spouse ladd AC exists but spouse selection is 0
      (planType === 'ladd' || planType === 'criticalIllness')
    ) {
      //set ladd buildMatchingSelections to [0,0,0], same structure as how we store in selections
      buildMatchingSelections = {
        ...buildMatchingSelections,
        [`${planType}Val`]: [0, 0, 0],
        [planType]: [0, 0, 0],
      };
      //ladd plan
      //on AC record, member calculatedPlanTypeId = 4, spouse = 5, dep = 6
      let matchingMemberLaddObj = findMatchingLaddAmount(
        matchingMemberLadd,
        enrollmentResults
      );
      let matchingSpouseLaddObj = findMatchingLaddAmount(
        matchingSpouseLadd,
        enrollmentResults
      );
      let matchingDependentLaddObj = findMatchingLaddAmount(
        matchingDependentLadd,
        enrollmentResults
      );

      //member
      if (matchingMemberLaddObj?.coverageAmount > 0) {
        buildMatchingSelections = {
          ...buildMatchingSelections,
          // Update planTypeVal array at index 0
          [`${planType}Val`]: [
            matchingMemberLaddObj?.premiumAmount,
            ...buildMatchingSelections[`${planType}Val`]?.slice(1),
          ],
          // Update planType array at index 0
          [planType]: [
            matchingMemberLaddObj?.coverageAmount,
            ...buildMatchingSelections[planType]?.slice(1),
          ],
        };
      }
      //spouse
      if (matchingSpouseLaddObj?.coverageAmount > 0) {
        buildMatchingSelections = {
          ...buildMatchingSelections,
          [`${planType}Val`]: [
            ...buildMatchingSelections[`${planType}Val`]?.slice(0, 1),
            matchingSpouseLaddObj?.premiumAmount,
            ...buildMatchingSelections[`${planType}Val`]?.slice(2),
          ],
          // Update planType array at index 2
          [planType]: [
            ...buildMatchingSelections[planType]?.slice(0, 1),
            matchingSpouseLaddObj?.coverageAmount,
            ...buildMatchingSelections[planType]?.slice(2),
          ],
        };
      }
      //dependent
      if (matchingDependentLaddObj?.coverageAmount > 0) {
        buildMatchingSelections = {
          ...buildMatchingSelections,
          // Update planTypeVal array at index 2
          [`${planType}Val`]: [
            ...buildMatchingSelections[`${planType}Val`]?.slice(0, 2),
            matchingDependentLaddObj?.premiumAmount,
          ],
          // Update planType array at index 2
          [planType]: [
            ...buildMatchingSelections[planType]?.slice(0, 2),
            matchingDependentLaddObj?.coverageAmount,
          ],
        };
      }
    }

    //return matching plan info object
    return buildMatchingSelections;
  };

  //find matching amount enrollment results for ladd matched on planId and coverage amount - called above to return matching ladd amount
  const findMatchingLaddAmount = (matchingPlan, enrollmentResults) => {
    let matchingPlanAmount = null;
    //member
    if (matchingPlan?.calculatedPlanTypeId === 4) {
      matchingPlanAmount = enrollmentResults?.memberOptions?.find(
        (option) => option?.coverageAmount === matchingPlan?.benefitAmount
      );
    } //spouse
    else if (matchingPlan?.calculatedPlanTypeId === 5) {
      matchingPlanAmount = enrollmentResults?.spouseOptions?.find(
        (option) => option?.coverageAmount === matchingPlan?.benefitAmount
      );
    } //dependent
    else if (matchingPlan?.calculatedPlanTypeId === 6) {
      matchingPlanAmount = enrollmentResults?.dependentOptions?.find(
        (option) => option?.coverageAmount === matchingPlan?.benefitAmount
      );
    }
    return matchingPlanAmount;
  };

  ///update db with current AC matching selections, called below in useEffect if localPrevAcSelections is true
  const savePrevAcSelectionsToDb = async () => {
    //go over selections and save to db
    let totalKeys = Object.keys(props?.selections)?.length;
    let processedKeys = 0; // start counter for processed keys
    let allKeysProcessed = false; //track when all keys are processed
    if (
      props?.selections &&
      localPrevAcSelections &&
      Object?.keys(props?.selections)?.length > 0
    ) {
      props?.setLoading(true);
      let response = null;
      let laddResponse = null;
      //iterate over each key value pair
      for (let key in props?.selections) {
        processedKeys++;

        //store non ladd in db with optionId
        if (
          !key.includes('Val') &&
          !key.includes('ladd') &&
          !key.includes('criticalIllness') &&
          props?.selections[key] //check that value exists
        ) {
          console.log('key in option', key, 'value', props?.selections[key]);
          response = await handleEnrollClick(props?.selections[key]);
        } //store ladd coverage amounts in db as long as member amount > 0
        else if (key === 'ladd' && props?.selections[key][0] > 0) {
          console.log('key in ladd', key, 'value', props?.selections[key]);
          laddResponse = await props?.saveLadd();
        }
        // After each iteration, check if all keys are processed
        if (processedKeys === totalKeys) {
          allKeysProcessed = true; // Set the boolean to true
        }
      }
      if (allKeysProcessed) {
        //check if ladd member amount > 0 was present and processed and handleEnrollClick for saving other plan options was processed
        if (props?.selections.ladd[0] > 0 && laddResponse) {
          props?.updateResults();
        }
        if (response) {
          props?.updateResults();
        }
        setLocalPrevAcSelections(false);
        setTimeout(() => {
          props?.setLoading(false);
        }, 5000);
      }
    }
  };

  //save matching AC selections to db
  useEffect(() => {
    if (localPrevAcSelections && props?.agentEnroller) {
      savePrevAcSelectionsToDb();
    }
  }, [localPrevAcSelections]); //set at build selections in bottom of other useEffect

  //Filter AC based on line of coverage plan type - return array of matching AC
  const findCurrentACByPlanType = (planType) => {
    console.log(
      'checking current active coverage',
      props?.currentActiveCoverage,
      'and plan type',
      planType
    );
    let matchedAC = null;
    let lowerCasePlanType = planType?.toLowerCase();
    let currentActiveCoverageLinesArray =
      props?.currentActiveCoverage?.length > 0
        ? [...props?.currentActiveCoverage[0]?.linesOfCoverage]
        : [];

    switch (lowerCasePlanType) {
      case 'std':
        matchedAC = currentActiveCoverageLinesArray?.filter(
          (coverage) => coverage?.calculatedPlanTypeId === 1
        );
        break;
      case 'ltd':
        matchedAC = currentActiveCoverageLinesArray?.filter(
          (coverage) => coverage?.calculatedPlanTypeId === 2
        );
        break;
      case 'accident':
        matchedAC = currentActiveCoverageLinesArray?.filter(
          (coverage) => coverage?.calculatedPlanTypeId === 3
        );
        break;
      case 'ladd':
        matchedAC = currentActiveCoverageLinesArray?.filter((coverage) => {
          return (
            coverage?.calculatedPlanTypeId === 4 ||
            coverage?.calculatedPlanTypeId === 5 ||
            coverage?.calculatedPlanTypeId === 6
          );
        });
        break;
      case 'add':
        matchedAC = currentActiveCoverageLinesArray?.filter(
          (coverage) => coverage?.calculatedPlanTypeId === 7
        );
        break;
      case 'cancer':
        matchedAC = currentActiveCoverageLinesArray?.filter(
          (coverage) => coverage?.calculatedPlanTypeId === 8
        );
        break;
      case 'hospital':
        matchedAC = currentActiveCoverageLinesArray?.filter(
          (coverage) => coverage?.calculatedPlanTypeId === 9
        );
        break;
      case 'membership':
        matchedAC = currentActiveCoverageLinesArray?.filter(
          (coverage) => coverage?.calculatedPlanTypeId === 10
        );
        break;
      case 'criticalIllness':
        matchedAC = currentActiveCoverageLinesArray?.filter(
          (coverage) => coverage?.calculatedPlanTypeId === 'criticalIllness' //need to update once we have CalculatedPlanType filled out
        );
        break;
      default:
        console.log('no matching plan type found');
    }

    console.log('matchedAC', matchedAC);
    return matchedAC;
  };

  const buildOrderedPlanList = () => {
    let results = props.results;
    //Build array of nodes with sort order, then map over to display
    let unorderedList = [
      // {plan: ReactNode, order: int}
    ];
    if (results?.membershipPlan) {
      unorderedList.push({
        plan: (
          <EnrollmentMembership
            key={'membership'}
            // medBreakpointHit={props?.medBreakpointHit}
            handleEnrollClick={handleEnrollClick}
            results={props.results}
            recommendations={props.recommendations}
            selections={props.selections}
            setSelections={props.setSelections}
            selectionHandler={props.selectionHandler}
            selectionRec={props.selectionRec}
            dummyFontColor={props.dummyFontColor}
            dummyDisplay={props.dummyDisplay}
            calculated={props.calculated}
            expanded={expanded}
            setExpanded={setExpanded}
            dependents={props.dependents}
            setDependents={props.setDependents}
            spouse={props.spouse}
            setSpouse={props.setSpouse}
            genderTypes={props?.genderTypes}
            matchingACPlanType={findCurrentACByPlanType('membership')}
            agentEnroller={props.agentEnroller}
          />
        ),
        order: results.membershipPlan.sortOrder,
        planId: results.membershipPlan.planId,
      });
    }
    if (results?.stdPlan && !results.stdPlan?.isIncremental) {
      unorderedList.push({
        plan: (
          <EnrollmentStd
            key={'std'}
            handleEnrollClick={handleEnrollClick}
            results={props.results}
            recommendations={props.recommendations}
            selections={props.selections}
            setSelections={props.setSelections}
            selectionHandler={props.selectionHandler}
            selectionRec={props.selectionRec}
            dummyFontColor={props.dummyFontColor}
            dummyDisplay={props.dummyDisplay}
            calculated={props.calculated}
            expanded={expanded}
            setExpanded={setExpanded}
            matchingACPlanType={findCurrentACByPlanType('std')}
            agentEnroller={props.agentEnroller}
          />
        ),
        order: results.stdPlan.sortOrder,
        planId: results.stdPlan.planId,
      });
    }
    if (results?.stdPlan && results.stdPlan?.isIncremental) {
      unorderedList.push({
        plan: (
          <EnrollmentStdIncremental
            key={'stdInc'}
            handleEnrollClick={handleEnrollClick}
            results={props.results}
            recommendations={props.recommendations}
            selections={props.selections}
            setSelections={props.setSelections}
            selectionHandler={props.selectionHandler}
            selectionRec={props.selectionRec}
            dummyFontColor={props.dummyFontColor}
            dummyDisplay={props.dummyDisplay}
            calculated={props.calculated}
            expanded={expanded}
            setExpanded={setExpanded}
            matchingACPlanType={findCurrentACByPlanType('std')}
            agentEnroller={props.agentEnroller}
          />
        ),
        order: results.stdPlan.sortOrder,
        planId: results.stdPlan.planId,
      });
    }
    if (results?.ltdPlan && !results.ltdPlan?.isIncremental) {
      unorderedList.push({
        plan: (
          <EnrollmentLtd
            key={'ltd'}
            handleEnrollClick={handleEnrollClick}
            results={props.results}
            recommendations={props.recommendations}
            selections={props.selections}
            setSelections={props.setSelections}
            selectionHandler={props.selectionHandler}
            selectionRec={props.selectionRec}
            dummyFontColor={props.dummyFontColor}
            dummyDisplay={props.dummyDisplay}
            calculated={props.calculated}
            expanded={expanded}
            setExpanded={setExpanded}
            matchingACPlanType={findCurrentACByPlanType('ltd')}
            agentEnroller={props.agentEnroller}
          />
        ),
        order: results.ltdPlan.sortOrder,
        planId: results.ltdPlan.planId,
      });
    }
    if (results?.ltdPlan && results.ltdPlan?.isIncremental) {
      unorderedList.push({
        plan: (
          <EnrollmentLtdIncremental
            key={'ltdInc'}
            handleEnrollClick={handleEnrollClick}
            results={props.results}
            recommendations={props.recommendations}
            selections={props.selections}
            setSelections={props.setSelections}
            selectionHandler={props.selectionHandler}
            selectionRec={props.selectionRec}
            dummyFontColor={props.dummyFontColor}
            dummyDisplay={props.dummyDisplay}
            calculated={props.calculated}
            expanded={expanded}
            setExpanded={setExpanded}
            matchingACPlanType={findCurrentACByPlanType('ltd')}
            agentEnroller={props.agentEnroller}
          />
        ),
        order: results.ltdPlan.sortOrder,
        planId: results.ltdPlan.planId,
      });
    }
    if (results?.accidentPlan) {
      unorderedList.push({
        plan: (
          <EnrollmentAccident
            key={'accident'}
            // medBreakpointHit={props?.medBreakpointHit}
            handleEnrollClick={handleEnrollClick}
            results={props.results}
            recommendations={props.recommendations}
            selections={props.selections}
            setSelections={props.setSelections}
            selectionHandler={props.selectionHandler}
            selectionRec={props.selectionRec}
            dummyFontColor={props.dummyFontColor}
            dummyDisplay={props.dummyDisplay}
            calculated={props.calculated}
            expanded={expanded}
            setExpanded={setExpanded}
            dependents={props.dependents}
            setDependents={props.setDependents}
            spouse={props.spouse}
            setSpouse={props.setSpouse}
            genderTypes={props?.genderTypes}
            matchingACPlanType={findCurrentACByPlanType('accident')}
            agentEnroller={props.agentEnroller}
          />
        ),
        order: results.accidentPlan.sortOrder,
        planId: results.accidentPlan.planId,
      });
    }
    if (results?.lifeADDPlan) {
      unorderedList.push({
        plan: (
          <EnrollmentLadd
            key={'ladd'}
            // medBreakpointHit={props?.medBreakpointHit}
            results={props.results}
            recommendations={props.recommendations}
            selections={props.selections}
            setSelections={props.setSelections}
            selectionHandler={props.selectionHandler}
            selectionRec={props.selectionRec}
            dummyFontColor={props.dummyFontColor}
            dummyDisplay={props.dummyDisplay}
            calculated={props.calculated}
            expanded={expanded}
            setExpanded={setExpanded}
            dependents={props.dependents}
            setDependents={props.setDependents}
            spouse={props.spouse}
            setSpouse={props.setSpouse}
            genderTypes={props?.genderTypes}
            setLaddSelected={setLaddSelected}
            setLaddSelectionMade={props.setLaddSelectionMade}
            laddLastPlan={laddLastPlan}
            saveLadd={props.saveLadd}
            matchingACPlanType={findCurrentACByPlanType('ladd')}
            agentEnroller={props.agentEnroller}
          />
        ),
        order: results.lifeADDPlan.sortOrder,
        planId: results.lifeADDPlan.planId,
      });
    }
    if (results?.criticalIllnessPlan) {
      unorderedList.push({
        plan: (
          <EnrollmentCriticalIllness
            key={'criticalIllness'}
            // medBreakpointHit={props?.medBreakpointHit}
            results={props.results}
            recommendations={props.recommendations}
            selections={props.selections}
            setSelections={props.setSelections}
            selectionHandler={props.selectionHandler}
            selectionRec={props.selectionRec}
            dummyFontColor={props.dummyFontColor}
            dummyDisplay={props.dummyDisplay}
            calculated={props.calculated}
            expanded={expanded}
            setExpanded={setExpanded}
            dependents={props.dependents}
            setDependents={props.setDependents}
            spouse={props.spouse}
            setSpouse={props.setSpouse}
            genderTypes={props?.genderTypes}
            // setLaddSelected={setLaddSelected}
            // setLaddSelectionMade={props.setLaddSelectionMade}
            // laddLastPlan={laddLastPlan}
            // saveLadd={props.saveLadd}
            matchingACPlanType={findCurrentACByPlanType('criticalIllness')}
            agentEnroller={props.agentEnroller}
          />
        ),
        order: results.criticalIllnessPlan.sortOrder,
        planId: results.criticalIllnessPlan.planId,
      });
    }
    if (results?.cancerPlan) {
      unorderedList.push({
        plan: (
          <EnrollmentCancer
            key={'cancer'}
            handleEnrollClick={handleEnrollClick}
            results={props.results}
            recommendations={props.recommendations}
            selections={props.selections}
            setSelections={props.setSelections}
            selectionHandler={props.selectionHandler}
            selectionRec={props.selectionRec}
            dummyFontColor={props.dummyFontColor}
            dummyDisplay={props.dummyDisplay}
            calculated={props.calculated}
            expanded={expanded}
            setExpanded={setExpanded}
            dependents={props.dependents}
            setDependents={props.setDependents}
            spouse={props.spouse}
            setSpouse={props.setSpouse}
            spouseRatesLoading={props.spouseRatesLoading}
            genderTypes={props?.genderTypes}
            fullMemberObj={props.fullMemberObj}
            matchingACPlanType={findCurrentACByPlanType('cancer')}
            agentEnroller={props.agentEnroller}
          />
        ),
        order: results.cancerPlan.sortOrder,
        planId: results.cancerPlan.planId,
      });
    }
    if (results?.addPlan) {
      unorderedList.push({
        plan: (
          <EnrollmentADD
            key={'add'}
            handleEnrollClick={handleEnrollClick}
            results={props.results}
            recommendations={props.recommendations}
            selections={props.selections}
            setSelections={props.setSelections}
            selectionHandler={props.selectionHandler}
            selectionRec={props.selectionRec}
            dummyFontColor={props.dummyFontColor}
            dummyDisplay={props.dummyDisplay}
            calculated={props.calculated}
            expanded={expanded}
            setExpanded={setExpanded}
            dependents={props.dependents}
            setDependents={props.setDependents}
            spouse={props.spouse}
            setSpouse={props.setSpouse}
            genderTypes={props?.genderTypes}
            // medBreakpointHit={props?.medBreakpointHit}
            matchingACPlanType={findCurrentACByPlanType('add')}
            agentEnroller={props.agentEnroller}
          />
        ),
        order: results.addPlan.sortOrder,
        planId: results.addPlan.planId,
      });
    }

    if (results?.hospitalPlan) {
      unorderedList.push({
        plan: (
          <EnrollmentHospital
            key={'hospital'}
            handleEnrollClick={handleEnrollClick}
            results={props.results}
            recommendations={props.recommendations}
            selections={props.selections}
            setSelections={props.setSelections}
            selectionHandler={props.selectionHandler}
            selectionRec={props.selectionRec}
            dummyFontColor={props.dummyFontColor}
            dummyDisplay={props.dummyDisplay}
            calculated={props.calculated}
            expanded={expanded}
            setExpanded={setExpanded}
            dependents={props.dependents}
            setDependents={props.setDependents}
            spouse={props.spouse}
            setSpouse={props.setSpouse}
            genderTypes={props?.genderTypes}
            matchingACPlanType={findCurrentACByPlanType('hospital')}
            agentEnroller={props.agentEnroller}
          />
        ),
        order: results.hospitalPlan.sortOrder,
        planId: results.hospitalPlan.planId,
      });
    }

    let orderedList = unorderedList.sort((a, b) => a.order - b.order);
    setPlanList(orderedList);

    //find index of ladd is in the list of plans
    let laddLocation = orderedList.findIndex(
      (planObj) => planObj.planId === props?.results?.lifeADDPlan?.planId
    );

    //if it is the last plan, set state to true, else false
    if (laddLocation === orderedList.length - 1) {
      setLaddLastPlan(true);
    } else {
      setLaddLastPlan(false);
    }

    let _expanded = [];
    let _i = 0;
    for (let planObj of orderedList) {
      if (_i == 0) {
        _expanded.push({ [planObj.planId]: true });
      } else {
        _expanded.push({ [planObj.planId]: false });
      }
      _i++;
    }
    let newPlansDetected = false;
    for (let existingPlanId of Object.keys(expanded)) {
      let found = false;
      for (let newPlanId of Object.keys(_expanded)) {
        if (existingPlanId == newPlanId) {
          found = true;
        }
      }
      if (!found) {
        newPlansDetected = true;
      }
    }
    if (expanded?.length <= 0 || newPlansDetected) {
      if (history.location.state && history.location.state?.planId) {
        let expandedWithHistory = [];
        for (let expandObj of _expanded) {
          let planId = Object.keys(expandObj)[0];
          expandObj = { [planId]: false };
          if (planId == history.location.state.planId) {
            expandObj = { [planId]: true };
          }
          expandedWithHistory.push(expandObj);
        }
        _expanded = expandedWithHistory;
      }
      setExpanded(_expanded);
    }
  };

  useEffect(() => {
    if (props.results && history.location) buildOrderedPlanList();
  }, [
    props.results,
    history.location,
    expanded,
    props.selections,
    props.spouse,
    props.dependents,
  ]);

  const closeSnackbar = () => {
    props.setBackendValidation(true);
  };

  return (
    <Box
      sx={externalEnrollmentSharedStyles.outerComponentContainerWithPossibleStickyProgress(
        props?.agentEnroller
      )}
    >
      <Box
        sx={externalEnrollmentSharedStyles.componentContainerWithPossibleStickyProgress(
          props?.agentEnroller
        )}
        onScroll={!props.agentEnroller ? scrollListener : null}
      >
        {/* Header */}
        {!props.agentEnroller && (
          <ExternalEnrollmentHeader
            fullMemberObj={props.thisMember}
            agentEnroller={props.agentEnroller}
            selectedEnrollment={props.results}
            basicHeader={true}
          />
        )}

        {props.agentEnroller && (
          <>
            <Box>
              <Typography
                variant="h5"
                sx={externalEnrollmentSharedStyles.agentEnrollTitleText}
              >
                Benefit Options
              </Typography>
            </Box>

            <Box
              sx={externalEnrollmentSharedStyles.agentEnrollSubtitleContainer}
            >
              <Typography
                variant="body2"
                sx={externalEnrollmentSharedStyles.agentEnrollSubtitleText}
              >
                Select benefit options for the member.
              </Typography>
            </Box>
          </>
        )}

        {props.backendValidation === false && (
          <Alert severity="error">
            Changes in your personal information have affected some selections,
            please reselect your coverage options
          </Alert>
        )}

        {!showPayment ? (
          <>
            <Box
              sx={externalEnrollmentSharedStyles.mainBodyContainer(
                props?.agentEnroller
              )}
            >
              {/* {
                        props.results?.stdPlan && !props.results.stdPlan?.isIncremental &&
                        // props.results?.stdPlan && !practiceIncrementalOptions &&
                           <EnrollmentStd handleEnrollClick={handleEnrollClick}
                              results={props.results} recommendations={props.recommendations}
                              selections={props.selections} setSelections={props.setSelections}
                              selectionHandler={props.selectionHandler} selectionRec={props.selectionRec}
                              dummyFontColor={props.dummyFontColor} dummyDisplay={props.dummyDisplay}
                              calculated={props.calculated} expanded={expanded} setExpanded={setExpanded}
                           />
                        }
                        {
                        props.results?.stdPlan && props.results.stdPlan?.isIncremental &&
                        // props.results?.stdPlan && practiceIncrementalOptions &&
                           <EnrollmentStdIncremental handleEnrollClick={handleEnrollClick}
                              results={props.results} recommendations={props.recommendations}
                              selections={props.selections} setSelections={props.setSelections}
                              selectionHandler={props.selectionHandler} selectionRec={props.selectionRec}
                              dummyFontColor={props.dummyFontColor} dummyDisplay={props.dummyDisplay}
                              calculated={props.calculated} expanded={expanded} setExpanded={setExpanded}
                           />
                        }
                        
                        {
                        props.results?.accidentPlan &&
                           <EnrollmentAccident
                           handleEnrollClick={handleEnrollClick}
                           results={props.results} recommendations={props.recommendations}
                           selections={props.selections} setSelections={props.setSelections}
                           selectionHandler={props.selectionHandler} selectionRec={props.selectionRec}
                           dummyFontColor={props.dummyFontColor} dummyDisplay={props.dummyDisplay}
                           calculated={props.calculated} expanded={expanded} setExpanded={setExpanded}
                           dependents={props.dependents} setDependents={props.setDependents}
                           spouse={props.spouse} setSpouse={props.setSpouse}
                           />
                        }
                        
                        {props.results?.ltdPlan &&
                           <EnrollmentLtd handleEnrollClick={handleEnrollClick}
                              results={props.results} recommendations={props.recommendations}
                              selections={props.selections} setSelections={props.setSelections}
                              selectionHandler={props.selectionHandler} selectionRec={props.selectionRec}
                              dummyFontColor={props.dummyFontColor} dummyDisplay={props.dummyDisplay}
                              calculated={props.calculated} expanded={expanded} setExpanded={setExpanded}
                           />
                        }
                        {props.results?.lifeADDPlan &&
                           <EnrollmentLadd
                              results={props.results} recommendations={props.recommendations}
                              selections={props.selections} setSelections={props.setSelections}
                              selectionHandler={props.selectionHandler} selectionRec={props.selectionRec}
                              dummyFontColor={props.dummyFontColor} dummyDisplay={props.dummyDisplay}
                              calculated={props.calculated} expanded={expanded} setExpanded={setExpanded}
                              dependents={props.dependents} setDependents={props.setDependents}
                              spouse={props.spouse} setSpouse={props.setSpouse} setLaddSelected={setLaddSelected}
                           />
                        } */}

              {planList.map((planObj) => planObj.plan)}
            </Box>
          </>
        ) : (
          <>
            <EnrollmentPayment
              results={props.results}
              selections={props.selections}
              expanded={expanded}
              setExpanded={setExpanded}
              showPayment={showPayment}
              setShowPayment={setShowPayment}
              fullMemberObj={props.fullMemberObj}
            />
          </>
        )}

        <DrawerContainer
          drawerOpen={declinedAll}
          setDrawerOpen={setDeclinedAll}
          handleDecline={handleDecline}
          handleDeclineClose={handleDeclineClose}
          title="Decline all coverage?"
          bodyText="It looks like you’ve declined all coverage options. If you didn’t mean to do this, you can go back and change that now. Otherwise, select “Decline” below to submit your enrollment."
        />

        {props.agentEnroller && (
          <AgentNavigation
            nextLocation={'beneficiaries'}
            allowBack={false}
            nextValidation={props.nextValidation}
            nextClickHandler={props.nextClickHandler}
          />
        )}
      </Box>
    </Box>
  );
}
