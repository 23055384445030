//need to figure out how to pass in value to accountCard for failed prenote vs. archived vs. active

const paymentsTabStyles = {
  //Bank Account display shared styles
  accountCard: {
    padding: "8px 10px 8px 8px",
    width: "93%",
    minHeight: "96px",
    margin: "15px auto 0px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    fontFamily: "Poppins",
    border: "1px solid ",
    borderColor: "background.border",
    backgroundColor: "background.default",
  },
  iconContainer: {
    display: "flex",
    minWidth: "60px",
    margin: "0 auto",
  },
  accountBalanceIcon:(showDisabled) => ({
    color: showDisabled ? "text.disabled" : 'primary.main',
    margin: "6px auto",
  }),
  accountInfo: {
    display: "flex",
    flexDirection: "column",
    paddingLeft: "6px",
    width: "100%",
  },
  accountNameText:(showDisabled) => ({
    fontFamily: "Poppins",
    fontWeight: "500",
    color: showDisabled ? "text.disabled" : 'text.primary',
    fontSize: "14px!important",
  }),
  menuContainer: {
    display: "flex",
    flexDirection: "row",
    fontFamily: "Archivo",
    fontSize: "15px",
    fontWeight: 500,
    justifyContent: "flex-end",
    margin: "0px 20px 0px 0px",
  },
  moreVertIcon: {
    color: "text.secondary",
    float: "right",
  },
  bankAccountInfoContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  bankAccountText: (showDisabled) => ({
    color: showDisabled ? "text.disabled" : 'text.primary',
    fontSize: "14px!important",
    marginRight: "5px",
  }),
}

export default paymentsTabStyles
