import React, { useRef, useState, useEffect } from "react"
import TextField from "@mui/material/TextField"
import Box from "@mui/material/Box"
import AddIcon from "@mui/icons-material/Add"
import Autocomplete from "@mui/material/Autocomplete"

const componentStyles = {
  addInputButton: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    fontSize: "13px",
    color: "primary.main",
    textAlign: "right",
    fontWeight: 500,
  },
  addIcon: {
    height: "18px",
    marginRight: "5px",
  },
  input: {
    margin: "5px 0px",
    width: "100%",
  },
}

export default function AutoCompleteAddress(props) {
  const [options, setOptions] = useState([])
  const previousController = useRef()

  let locationApi = "api/location?query="
  const [addressValue, setAddressValue] = useState({})

  useEffect(() => {
    if (addressValue?.street1 != (null || undefined)) {
      let member = props.memberInfo
      props.setMemberInfo({
        ...member,
        city: addressValue?.city && addressValue?.city,
        state: addressValue?.state && addressValue?.state,
        street1: addressValue?.street1 && addressValue?.street1,
        zipCode: addressValue?.zipCode && addressValue?.zipCode,
      })
    }
    console.log(addressValue)
  }, [addressValue])

  useEffect(() => {
    let member = props.memberInfo
    setAddressValue(member)
  }, [])

  const getData = (searchTerm) => {
    if (previousController.current) {
      previousController.current.abort()
    }
    const controller = new AbortController()
    const signal = controller.signal
    previousController.current = controller
    try {
      fetch(locationApi + searchTerm, {
        signal,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
        .then(function (response) {
          return response.json()
        })
        .then(function (myJson) {
          setOptions(myJson)
          console.log(myJson)
        })
    } catch (error) {
      if (error.name === "AbortError") {
        console.log("Fetch aborted - Addresslookup")
      } else {
        console.error("Fetch error:", error)
      }
    }
  }

  const onInputChange = (event, value, reason) => {
    if (value && value.length > 2 && value != "null") {
      console.log(value)
      let member = props.memberInfo
      setAddressValue({
        ...member,
        street1: value,
      })
      //setOptions(addressValue);
      getData(value)
    } else {
      setOptions([])
    }
  }
  return (
    <Box>
      <Autocomplete
        id="address-autocomplete"
        freeSolo
        options={options}
        defaultValue={{
          street1: props.memberInfo.street1 ? props.memberInfo.street1 : "",
          id: 1,
          freeFormAddress: props.memberInfo.street1
            ? props.memberInfo.street1
            : "",
        }}
        onInputChange={onInputChange}
        getOptionLabel={(option) => option.street1}
        renderOption={(props, option) => {
          return (
            <li {...props} key={option.id ?? 0}>
              {option.freeFormAddress}
            </li>
          )
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label="Address Line 1"
            size="small"
            fullWidth
            id="street1"
            sx={componentStyles.input}
          />
        )}
        onChange={(event, newValue) => {
          if (newValue?.street1 != "null") {
            setAddressValue(newValue)
          }
        }}
      />
      {/* Add Second Address Iput - if no second address exists, show button to add one and give dropdown */}
      {!props.showCollapsedField.street2 && !props.memberInfo.street2 && (
        <Box
          type="button"
          sx={componentStyles.addInputButton}
          onClick={() => props.toggleField("street2")}
        >
          <AddIcon sx={componentStyles.addIcon} />
          Add address line 2
        </Box>
      )}
      {(props.showCollapsedField.street2 || props.memberInfo.street2) && (
        <TextField
          variant="outlined"
          label="Address Line 2"
          size="small"
          fullWidth
          sx={componentStyles.input}
          value={props.memberInfo?.street2 || ""}
          onChange={(e) => props.handleMemberInfoChange(e, "street2")}
          InputProps={{
            sx: {
              "&.MuiOutlinedInput-root.Mui-focused > fieldset": {
                border: "2px solid #195FFB",
              },
            },
          }}
        />
      )}

      <TextField
        variant="outlined"
        label="City"
        size="small"
        fullWidth
        id="city"
        sx={componentStyles.input}
        value={props.memberInfo.city || ""}
        onChange={(e) => props.handleMemberInfoChange(e, "city")}
        InputProps={{
          sx: {
            "&.MuiOutlinedInput-root.Mui-focused > fieldset": {
              border: "2px solid #195FFB",
            },
          },
        }}
      />

      <TextField
        variant="outlined"
        label="State"
        size="small"
        fullWidth
        id="state"
        sx={componentStyles.input}
        value={props.memberInfo.state || ""}
        onChange={(e) => props.handleMemberInfoChange(e, "state")}
        InputProps={{
          sx: {
            "&.MuiOutlinedInput-root.Mui-focused > fieldset": {
              border: "2px solid #195FFB",
            },
          },
        }}
      />

      <TextField
        variant="outlined"
        label="Zip Code"
        size="small"
        fullWidth
        id="zipCode"
        sx={componentStyles.input}
        value={props.memberInfo.zipCode || ""}
        onChange={(e) => props.handleMemberInfoChange(e, "zipCode")}
        InputProps={{
          sx: {
            "&.MuiOutlinedInput-root.Mui-focused > fieldset": {
              border: "2px solid #195FFB",
            },
          },
        }}
      />
    </Box>
  )
}
