import React, { useContext, useEffect, useState } from "react"

//services

//Mui Components
import { Box, Button, Card, Paper, Typography } from "@mui/material"
import Alert from "@mui/material/Alert"

// Assets

//Mui icons
import AccountBalanceIcon from "@mui/icons-material/AccountBalance"

//Our Components
import EditWalletDrawer from "../payment-drawers/EditWalletDrawer"
import PaymentBankAccounts from "./PaymentBankAccounts"

//Component Styles
const componentStyles = {
  componentBody: {
    height: "266px",
    borderRadius: "0px",
    fontFamily: "Poppins",
    backgroundColor: "background.default",
    overflowY: "auto",
    paddingBottom: "20px",
  },
  headerContainer: {
    padding: "18px",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "0 auto",
  },
  title: {
    color: "text.primary",
    fontFamily: "Poppins",
    fontSize: "20px",
    fontWeight: "500",
  },
  description: {
    color: "text.secondary",
    fontFamily: "Archivo",
    fontSize: "14px",
    fontWeight: "400",
  },
  editWalletButton: {
    height: "36px",
    borderColor: "primary.main",
    borderRadius: "8px",
  },
}
export default function PaymentWalletCard(props) {
  const allowEdit = props?.permissionsEnabler?.editBankAccounts
  const allowView = props?.permissionsEnabler?.viewBankAccounts


  //set default account
  const [defaultAccount, setDefaultAccount] = useState()
  //bank account type name
  const getMemberAccountType = (accountId) => {
    let type = props.bankAccountTypes?.find((type) => {
      return type?.id === accountId
    })
    return type?.name.toLowerCase()
  }

  // get bank names and update bankAccount objects in state
  useEffect(() => {
    setDefaultAccount(props.bankAccount?.find((account) => account.isPrimary))
  }, [props.bankAccount])

  // Edit Wallet Drawer Logic
  const [editWalletDrawerOpen, setEditWalletDrawerOpen] = useState(false)
  const handleOpenWalletDrawer = () => {
    setEditWalletDrawerOpen(true)
  }

  return (
    <Paper className="" elevation={0} sx={componentStyles.componentBody}>
      <Box sx={componentStyles.headerContainer}>
        <Box>
          <Typography variant="h6" sx={componentStyles.title}>
            Wallet
          </Typography>
          <Typography variant-="body2" sx={componentStyles.description}>
            Payment accounts on file
          </Typography>
        </Box>
        <Box>
          <Button
            disabled={!allowEdit}
            fullWidth
            variant="contained"
            size="medium"
            onClick={handleOpenWalletDrawer}
            sx={componentStyles.editWalletButton}
          >
            EDIT WALLET
          </Button>
        </Box>
        <EditWalletDrawer
          editWalletDrawerOpen={editWalletDrawerOpen}
          setEditWalletDrawerOpen={setEditWalletDrawerOpen}
          bankAccount={props.bankAccount}
          setBankAccount={props.setBankAccount}
          member={props.member}
          getBankAccount={props.getBankAccount}
          getMemberAccountType={getMemberAccountType}
          defaultAccount={defaultAccount}
          setDefaultAccount={setDefaultAccount}
          editWalletFromPlans={props.editWalletFromPlans}
        />
      </Box>

      {allowView && (
        <PaymentBankAccounts
          bankAccount={props.bankAccount}
          member={props.member}
          getMemberAccountType={getMemberAccountType}
          defaultAccount={defaultAccount}
          editWalletFromPlans={props.editWalletFromPlans}
          permissionsEnabler={props.permissionsEnabler}
        />
      )}
      {!allowView && (
        <>
          <Alert sx={{ width: "95%", margin: "0 auto" }} severity="error">
            You do not have the required permissions to view bank accounts.
          </Alert>
        </>
      )}
    </Paper>
  )
}
