import React, { useContext, useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';

//MuiIcons
import {
  KeyboardBackspaceOutlined,
  MoreVertOutlined,
} from '@mui/icons-material';

//MuiComponents
import InputLabel from '@mui/material/InputLabel';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import {
  Button,
  Paper,
  Typography,
  Tabs,
  Tab,
  TextField,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
} from '@mui/material';
import { Box } from '@mui/system';
import Fade from '@mui/material/Fade';

//Assets

//Our components
import { thisUserContext } from '../nav-container/NavContainer';
import PlanManagementDrawer from './planManagementDrawer';
import planBuildService from '../../../services/planBuild-service';
import SinglePlanRow from './singlePlanRow';

export default function SinglePlanManagement(props) {
  let history = useHistory();

  let myContext = useContext(thisUserContext);
  const [thisUser, setThisUser] = useState();
  const [permissions, setPermissions] = useState();

  let getThisUser = async () => {
    setThisUser(myContext.thisUser);
    setPermissions(myContext?.permissionsEnabler);
  };

  useEffect(() => {
    if (myContext?.thisUser && thisUser == null) getThisUser();
  }, [myContext?.thisUser]);

  const [groupPlan, setGroupPlan] = useState({
    groupPlan: {},
    plans: [],
  });

  const getGroupPlan = async () => {
    console.log('getting group plan...propssssss', props);
    const results = await planBuildService.getGroupPlanById(
      props.match.params.id
    );
    if (results) {
      let builtGroupPlan = {
        ...results,
        plans: JSON.parse(results.plans),
        enrollmentWindows: JSON.parse(results.enrollmentWindows),
        // .map((plan) => {
        //   return {
        //     plan: {
        //       ...plan,
        //       helpText: plan.helpText.replace(/(?:\r\n|\r|\n)/g, '<br>'),
        //     },
        //   };
        // }),
      };
      console.log(builtGroupPlan);
      setGroupPlan(builtGroupPlan);
      if (builtGroupPlan?.name?.includes('smart-td')) {
        setVerbalAgreementObj({
          ...verbalAgreementObj,
          adminEmail: ' info@smart-vltd.com',
        });
      } else {
        setVerbalAgreementObj({
          ...verbalAgreementObj,
          adminEmail: ' admin@unionone.com',
        });
      }
    }
  };
  useEffect(() => {
    getGroupPlan();
  }, []);

  const [selectedGroupPlan, setSelectedGroupPlan] = useState(
    props.match?.params?.id
  );

  const handleChange = (event) => {
    setSelectedGroupPlan(event.target.value);
    console.log(event.target.value);
    // console.log(groupPlans.filter(groupPlan => groupPlanId == selectedGroupPlan))
  };

  const handleBack = (event) => {
    history.push(`/planmanagement/`);
  };

  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    console.log(newValue);
    setTabValue(newValue);
  };

  const buildRateTable = (rate) => {
    return (
      <>
        <style>{`
    table{
      border-radius: 8px;
     border:1px solid rgba(224, 224, 224, 1),;
    }
    th{
      text-align:left;
      border:1px solid rgba(224, 224, 224, 1);
      padding:10px;
    }
    tr{
      text-align:left;
      border:1px solid rgba(224, 224, 224, 1);
      padding:10px;
    }
    td {
      text-align:left;
      border:1px solid rgba(224, 224, 224, 1);
      padding: 5px;
    }
  `}</style>
        <Box sx={{ display: 'block' }}>
          <Typography variant="body1">{rate.title}</Typography>
          <table>
            <tr>
              <th>Age Band</th>
              <th>Rate</th>
            </tr>
            {rate.rateOptions.map((rateOption) => (
              <tr>
                <td>{`${rateOption.begin} - ${rateOption.end}`}</td>
                <td>{rateOption.rate}</td>
              </tr>
            ))}
            <tr></tr>
          </table>
        </Box>
      </>
    );
  };

  const [activeTab, setActiveTab] = useState(0);

  const planTypeLabels = [
    {
      label: 'Accident',
      Id: 1,
    },
    {
      label: 'Critical Illness',
      Id: 2,
    },
    {
      label: 'Long Term Disability',
      Id: 3,
    },
    {
      label: 'Short Term Disability',
      Id: 4,
    },
    {
      label: 'Life and AD&D',
      Id: 5,
    },
    {
      label: 'Hospital',
      Id: 6,
    },
    {
      label: 'Cancer',
      Id: 7,
    },
    {
      label: 'AD&D',
      Id: 8,
    },
    {
      label: 'Union Membership',
      Id: 9,
    }
  ];

  const buildTabs = () => {
    return (
      <Tabs
        value={tabValue}
        onChange={handleTabChange}
        textColor="primary"
        indicatorColor="primary"
        aria-label="group plan navigator"
      >
        <Tab value={0} label="General" />
        {groupPlan.plans.map((plan, index) => (
          <Tab
            value={index + 1}
            label={
              planTypeLabels.filter(
                (planType) => planType.Id == plan.plan.typeId
              )[0].label
            }
            id={plan.plan.id}
          />
        ))}
      </Tabs>
    );
  };

  // Edit Menu
  const [anchorEl, setAnchorEl] = useState({});

  const handleCloseRowMenu = () => {
    setAnchorEl({});
  };

  const handleOpenRowMenu = (e) => {
    setAnchorEl({
      ...anchorEl,
      [e.currentTarget.value]: e.currentTarget,
    });
  };

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [editValue, setEditValue] = useState('');
  const [updateObject, setUpdateObject] = useState({});

  const handleEdit = (value, entity, id, attribute) => {
    setEditValue(value);
    setDrawerOpen(true);
    handleCloseRowMenu();
    setUpdateObject({
      type: entity,
      id: id,
      valueType: attribute,
    });
  };

  const [planRows, setPlanRows] = useState([
    'description',
    'termsAndConditions',
    'achTerms',
    'verbalSignature',
  ]);

  const getDisplayName = (value) => {
    if (value == 'achTerms') {
      return 'ACH Terms';
    } else if (value == 'verbalSignature') {
      return 'Verbal Signature';
    } else if (value == 'termsAndConditions') {
      return 'Terms and Conditions';
    } else if (value == 'description') {
      return 'Description';
    } else if (value == 'helpText') {
      return 'Plan Description';
    }
  };

  const [verbalAgreementObj, setVerbalAgreementObj] = useState({
    firstName: '',
    lastName: '',
    enrolledIn: '',
    totalCost: '',
    salary: '',
    adminEmail: '',
    email: '',
  });

  let UsdFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
  });
  let UsdFormatterDec = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
  });

  return (
    <>
      <Box
        sx={{
          bgcolor: 'background.default',
          color: 'text.primary',
          borderColor: 'background.border',
        }}
        style={{
          height: 'calc(100vh - 56px)',
          padding: '20px 40px',
          overflowX: 'auto',
        }}
      >
        <Button
          variant="text"
          sx={{
            marginBottom: '10px',
            fontSize: '.8rem',
            textTransform: 'uppercase',
          }}
          onClick={handleBack}
        >
          <KeyboardBackspaceOutlined />
          Back to all group plans
        </Button>
        <Typography variant="h4" sx={{ marginBottom: '10px' }}>
          {groupPlan.name}
        </Typography>
        <Box
          className=""
          sx={{ justifyContent: 'space-between', marginBottom: '10px' }}
        >
          {buildTabs()}

          {/* General Tab */}
          {tabValue == 0 && (
            <>
              <Table
                sx={{
                  minWidth: 650,
                  border: '1px solid #D6D6D6',
                  marginTop: '10px',
                  borderRadius: '4px',
                }}
              >
                <TableBody>
                  <TableRow hover>
                    <TableCell variant="head" width="200px">
                      Enrollment URL
                    </TableCell>
                    <TableCell align="left">{`https://${groupPlan.subdomain}.unionhub.com/enroll`}</TableCell>
                    <TableCell align="right"></TableCell>
                  </TableRow>

                  <TableRow hover>
                    <TableCell variant="head">Group Plan ID</TableCell>
                    <TableCell align="left">
                      {props.match?.params?.id}
                    </TableCell>
                    <TableCell align="right"></TableCell>
                  </TableRow>

                  {planRows.map((row) => {
                    return (
                      <SinglePlanRow
                        getDisplayName={getDisplayName}
                        row={row}
                        value={groupPlan[row]}
                        handleOpenRowMenu={handleOpenRowMenu}
                        handleCloseRowMenu={handleCloseRowMenu}
                        anchorEl={anchorEl}
                        handleEdit={handleEdit}
                        groupPlan={groupPlan}
                        UsdFormatter={UsdFormatter}
                        UsdFormatterDec={UsdFormatterDec}
                      />
                    );
                  })}
                  <TableRow hover>
                    <TableCell variant="head">Enrollment Windows</TableCell>
                    <TableCell align="left">
                      {groupPlan?.enrollmentWindows?.map((enrollmentWindow) => {
                        return (
                          <Typography variant="body1">
                            {`${new Date(
                              enrollmentWindow?.OpenEnrollmentStart
                            ).toDateString()} - ${new Date(
                              enrollmentWindow?.OpenEnrollmentEnd
                            ).toDateString()}`}
                          </Typography>
                        );
                      })}
                    </TableCell>
                    <TableCell align="right"></TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </>
          )}

          {/* Plan Tabs */}
          {tabValue > 0 && (
            <>
              <Table
                sx={{
                  minWidth: 650,
                  border: '1px solid #D6D6D6',
                  marginTop: '10px',
                  borderRadius: '4px',
                }}
              >
                <TableBody>
                  <TableRow hover>
                    <TableCell variant="head" width="200px">
                      Plan ID
                    </TableCell>
                    <TableCell align="left">
                      {groupPlan.plans[tabValue - 1].plan.id}
                    </TableCell>
                    <TableCell align="right"></TableCell>
                  </TableRow>

                  <TableRow hover>
                    <TableCell variant="head">Insurance Carrier</TableCell>
                    <TableCell align="left">
                      {groupPlan.plans[tabValue - 1].plan.carrierName}
                    </TableCell>
                    <TableCell align="right"></TableCell>
                  </TableRow>

                  <TableRow hover>
                    <TableCell variant="head">Policy Number</TableCell>
                    <TableCell align="left">
                      {groupPlan.plans[tabValue - 1].plan.policyNumber}
                    </TableCell>
                    <TableCell align="right"></TableCell>
                  </TableRow>

                  <TableRow hover>
                    <TableCell variant="head">Plan Description</TableCell>
                    <TableCell align="left">
                      <Box
                        dangerouslySetInnerHTML={{
                          __html: groupPlan.plans[tabValue - 1].plan.helpText,
                        }}
                      ></Box>{' '}
                    </TableCell>
                    <TableCell align="right">
                      <Box>
                        <>
                          <IconButton
                            onClick={(e) => handleOpenRowMenu(e)}
                            value={tabValue}
                          >
                            <MoreVertIcon
                              sx={{
                                color: 'text.secondary',
                                // float: "right",
                              }}
                            />
                          </IconButton>
                          <Menu
                            id="edit"
                            anchorEl={anchorEl?.[tabValue]}
                            open={Boolean(anchorEl?.[tabValue])}
                            onClose={handleCloseRowMenu}
                            anchorOrigin={{
                              vertical: 'top',
                              horizontal: 'left',
                            }}
                            transformOrigin={{
                              vertical: 'top',
                              horizontal: 'left',
                            }}
                          >
                            <MenuItem
                              value={
                                groupPlan.plans[tabValue - 1].plan.helpText
                              }
                              sx={{ fontSize: '14px' }}
                              onClick={() =>
                                handleEdit(
                                  groupPlan.plans[tabValue - 1].plan.helpText,
                                  'plan',
                                  groupPlan.plans[tabValue - 1].plan.id,
                                  'helpText'
                                )
                              }
                            >
                              Edit
                            </MenuItem>
                          </Menu>
                        </>
                      </Box>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </>
          )}
        </Box>

        <PlanManagementDrawer
          getDisplayName={getDisplayName}
          groupPlan={groupPlan}
          setGroupPlan={setGroupPlan}
          update={planBuildService.update}
          updateObject={updateObject}
          tabValue={tabValue}
          setTabValue={setTabValue}
          drawerOpen={drawerOpen}
          setDrawerOpen={setDrawerOpen}
          editValue={editValue}
          setEditValue={setEditValue}
          getGroupPlan={getGroupPlan}
        />
      </Box>
    </>
  );
}
