import './SaveChangesSnackbar.css';

import React from 'react';

//MuiComponents
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

export default function SaveChangesSnackbar(props) {
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        props.setDisplayAlert(false);
    };
    const innerText = props.text ? props.text : "Changes have been saved!" 
    const autoHideDuration = props.autoHideDuration ? props.autoHideDuration : 1500
    const backgroundColor = props.backgroundColor ? props.backgroundColor : '#202023'
    const severity = props.severity ? props.severity : "success"

    return (
        <Snackbar open={props.displayAlert} autoHideDuration={autoHideDuration} onClose={handleClose} sx={{ opacity: .8 }}>
            <MuiAlert
                elevation={6} variant="filled"
                // onClose={handleClose} 
                severity={severity} sx={{ width: '100%', backgroundColor: backgroundColor }}
            >
                {innerText}
            </MuiAlert>
        </Snackbar>
        )

}