import Settings from '../settings';
import authService, { authHeader } from './auth-service';
import { baseService } from './baseService';

class internalSearchService extends baseService {
  search(searchTerm) {
    const url = Settings.API_URL + 'search' + '/?searchterm=' + searchTerm;
    return this.get(url);
  }
  /* Below is an example object
     * {
    "memberId": 0,
    "organizations": [2],
    "subject": "",
    "requester": "",
    "statusId":1,
    "priorityId":3
}
     */
  ticketSearch(ticketSearch) {
    const url = Settings.API_URL + 'search/ticket/advanced';
    return this.post(url, { ticketSearch });
  }
  advancedMemberSearch(memberSearch) {
    const url = Settings.API_URL + 'search/advanced';
    return this.post(url, memberSearch);
  }
}

const searchService = new internalSearchService();

export default searchService;
