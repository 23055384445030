import React, {useState, useEffect} from 'react'
import { set, format } from 'date-fns';


//Mui icons
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

//Mui Components
import Chip from "@mui/material/Chip";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/system";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Collapse } from "@mui/material";
import Select from '@mui/material/Select';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from "@mui/icons-material/MoreVert";


//our components
import InfinityLoader from "../../../../../../../UH-loading-animation/InfinityLoader";
import EditCoverageEffectiveDateStepper from '../EditCoverageEffectiveDateStepper';


export default function EnrollmentSummaryCardLTD (props) {

   let plan = props.plan

   let selectedOptionId = plan?.selectedOptionId
   let selectedOption = plan?.options?.find(option => option.memberOptionId === selectedOptionId)


   const [loading,setLoading] = useState(false)

   const [edit, setEdit] = useState(false)
   //This is bolding the payment info on the right side when edit==true
   useEffect(() => {
      if (props.setCurrentEdit){ //Check if this is rendered in planSummary or enrollmentSummary
         if (edit) {
            props.setCurrentEdit({
               ...props.currentEdit,
               ltd: true
            })
         }
         else {
            props.setCurrentEdit({
               ...props.currentEdit,
               ltd: false
            })
         }
      }
   }, [edit])

   const [ltdPlan, setLtdPlan] = useState()
   useEffect(() => {
      if (plan) setLtdPlan(plan)
   }, [plan])

   let handleLtdPlanChange = (e) => {
      let value = e.target.value;
      // console.log(ltdPlan)
      setLtdPlan({
         ...ltdPlan,
         selectedOptionId: value
      })
   }

   let updateSinglePlan = async () => {
      setLoading(true)
      await props.updateSinglePlan('ltdPlan', ltdPlan)
      await props.getThisPlan()
      //setTimeout(() => {
      //   setEdit(false)
   
      //   setLoading(false)
      //},[2000])
      setEdit(false)
   
      setLoading(false)
   }


   const [editEffectiveDate, setEditEffectiveDate] = useState(false)
   const updateCoverageEffectiveDate = async () => {

      setLoading(true)
      // await props.updateCoverageEffectiveDate('stdPlan', stdPlan)
      // await props.getThisPlan()
      //setTimeout(() => {
      //   setEditEffectiveDate(false)
      //   setLoading(false)
      //},[2000])
      setEditEffectiveDate(false)
      setLoading(false)
   }

     //open verticle dot menu to edit or cancel coverage
  const [anchorEl, setAnchorEl] = useState({});

  const handleOpenRowMenu = (e) => {
    setAnchorEl({
      ...anchorEl,
      [e.currentTarget.value]: e.currentTarget,
    });
  };

  const handleCloseRowMenu = () => {
    setAnchorEl({});
  };

  const handleOpenCancelCoverageDrawer = (e, plan, planName) => {
      props.handleOpenDrawer(plan, planName);
      handleCloseRowMenu();
  }
  

   return (
   <>
   <Box className="flex-row" sx={{padding:'8px 18px', flexWrap:'wrap'}}>
      <div className="flex-row col-12" style={{marginBottom:'15.5px'}}>
         <div className="col-6">
            <Typography component={'span'} sx={{fontFamily:'Poppins', fontWeight:500, fontSize:'20px', lineHeight:'32px', letterSpacing:'.15px', color:'#195FFB'}}>
               {props.planName.toUpperCase()}
            </Typography>
         </div>

         <div className="col-6" style={{display:'flex',justifyContent:'flex-end', alignItems:'center'}}>
            <Typography component={'span'} sx={{fontFamiily:'Poppins', fontWeight:400, fontSize:'24px', lineHeight:'32px', color:'#195FFB'}}>
               {props.UsdFormatterDec.format(selectedOption?.memberPremiumAmount || 0)}
            </Typography>
            <Typography component={'span'} sx={{fontFamily:'Archivo', fontWeight:400, fontSize:'14px', lineHeight:'20px', letterSpacing:'.17px', color:'text.secondary'}}>
               /per month
            </Typography>

            {props?.activeCoveragePlanSummary && 
            <>
            <IconButton
              onClick={(e) => handleOpenRowMenu(e, props?.plan)}
              value={props?.plan?.planId}
              sx={{ padding: "0px", marginLeft: "12px" }}
            >
              <MoreVertIcon
                sx={{
                  color: "text.secondary",
                  float: "right",
                }}
              />
            </IconButton>
            <Menu
              anchorEl={anchorEl?.[props?.plan.planId]}
              open={Boolean(anchorEl?.[props?.plan.planId])}
              onClose={handleCloseRowMenu}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              <MenuItem
                 onClick={(e) => {
                  if(props?.activePlansNotPendingCancel?.length > 1){
                    //cancel single line of coverage
                    handleOpenCancelCoverageDrawer(e, props?.plan, props?.planName);
                  }else if (props?.activePlansNotPendingCancel?.length === 1){
                    //cancel all coverage
                    props?.openCancelAllCoverageModal();
                  }
               }}
               disabled={!props.allowModify || props?.pendingCancellation}
              >
               {/* if not pending cancel and not last active line of coverage */}
               {!props?.pendingCancellation && props?.planName && props?.activePlansNotPendingCancel?.length > 1 && `Cancel ${props?.planName.toUpperCase() } Coverage`}

               {/* if not pending cancel and is last active line of coverage */}
               {!props?.pendingCancellation && props?.planName && props?.activePlansNotPendingCancel?.length === 1 && 'Cancel All Coverage'}

               {/* if pending cancel */}
               {props?.pendingCancellation && 'Pending Cancellation'}
              </MenuItem>

              <MenuItem
                //   value={}
                onClick={() => {
                  setEdit(true);
                  handleCloseRowMenu();
                }}
                disabled={!props.allowModify}
              >
                Edit Coverage
              </MenuItem>
              {/* )} */}
            </Menu>
          </>}
         </div>
      </div>

      {props.pendingCancellation && props.pendingCancellationObj && selectedOption?.memberPremiumAmount <= 0 &&
      
         props.pendingCancellationWarning(props.pendingCancellationObj)
      }

      <div className="flex-row col-12" style={{justifyContent: 'space-between'}}>
         <div className="col-6">
            <Typography variant="body1">
               Benefit Amount
            </Typography>
         </div>

         <div className="col-5" style={{textAlign:'end'}}>
            {!edit &&
               <Typography variant="body1" sx={{height:'40px'}}>
                  {props.UsdFormatterDec.format(selectedOption?.benefitAmount || 0)} {selectedOption?.benefitTypeFriendly}
               </Typography>
            }
            {edit &&
               <FormControl fullWidth sx={{ marginBottom: "10px" }}>
                  <InputLabel id='benefitAmount'>Benefit Amount</InputLabel>
                  <Select variant='outlined' size='small' fullWidth  label="Benefit Amount"
                  labelID="benefitAmount"
                     sx={{
                        height: "40px", padding: "10px 0px", width: "100%",
                        "&.MuiOutlinedInput-root.Mui-focused > fieldset": {
                          border: "2px solid #195ffb",
                        }, textAlign: "left", 
                      }}
                     IconComponent={() => <KeyboardArrowDownIcon sx={{ position: 'absolute', right: 7 }} />}
                     value={ltdPlan.selectedOptionId}
                     onChange={handleLtdPlanChange}
                  >
                     {ltdPlan.options && ltdPlan.options.map(option => {
                        return (
                           <MenuItem value={option.memberOptionId}>
                              {props.UsdFormatterDec.format(option?.benefitAmount || 0)} {option.benefitTypeFriendly}
                           </MenuItem>
                        )
                     })}
                  </Select>
               </FormControl>
            }
         </div>
      </div>

      <div className="flex-row col-12" style={{marginBottom:'16px'}}>
         <div className="col-6">
            <Typography variant="body1">
               Elimination Period
            </Typography>
         </div>

         <div className="col-6" style={{textAlign:'end'}}>
            <Typography variant="body1">
               {selectedOption?.eliminationPeriod}
               &nbsp;
               {selectedOption?.eliminationPeriodType}
            </Typography>
         </div>
      </div>

      <div className="flex-row col-12" style={{marginBottom:'16px'}}>
         <div className="col-6">
            <Typography variant="body1">
               Benefit Duration
            </Typography>
         </div>

         <div className="col-6" style={{textAlign:'end'}}>
            <Typography variant="body1">
               {selectedOption?.benefitDuration}
               &nbsp;
               {selectedOption?.benefitDurationType}
            </Typography>
         </div>
      </div>

      <div className="flex-row col-12" style={{marginBottom:'16px'}}>
         <div className="col-6">
            <Typography variant="body1">
               Offset
            </Typography>
         </div>

         <div className="col-6" style={{textAlign:'end'}}>
            <Typography variant="body1">
               {selectedOption?.benefitOffsets}
            </Typography>
         </div>
      </div>

      {props.allowModify &&
         <div className="flex-row col-12" style={{  }}>
            {!edit && !editEffectiveDate &&
            <div className="col-12 flex-row">
              Effective Date: {props?.plan?.effectiveDate ? format(props?.formatDate(props?.plan?.effectiveDate), "MM/dd/yyyy") : "N/A"}
            </div>
               
            }
            {(edit || editEffectiveDate) &&
               <>
                  <div className=""
                     style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginTop: '10px'
                     }}>

                     <EditCoverageEffectiveDateStepper currentEffectiveDate={props?.plan?.effectiveDate}/>
                     <div className="col-5 flex-row"  style={{ justifyContent: "flex-end"}}>
                     <Button
                           variant="outlined" 
                          
                           sx={{ borderRadius: '8px', height: '40px', fontWeight: '500!important', marginRight: "10px", }}
                           onClick={() => {
                              setEdit(false)
                              setEditEffectiveDate(false)
                           }} 
                      
                        >
                           BACK
                        </Button>

                        <Button
                           variant="contained" 
                           // fullWidth
                           sx={{ borderRadius: '8px', height: '40px', fontWeight: '500!important', }}
                           onClick={edit ? updateSinglePlan : updateCoverageEffectiveDate}
                           disabled={loading}
                        >
                           {loading ?
                           <>
                              <div style={{width:'100%',position:'absolute'}}>
                                 <InfinityLoader style={{
                                    zIndex:5,
                                    height:'38px',
                                 }}/> 
                              </div>
                              <span style={{opacity:0}}>SAVE CHANGES</span>
                              
                           </>
                           :
                           <>
                              SAVE CHANGES
                           </>
                           }
                        </Button>
                     </div>
                  </div>
               </>
            }

         </div>
      }
   </Box>
    <hr style={{mmarginTop:'20px', color: "text.primary"}}/>
   </>
   )
}