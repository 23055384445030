//Mui Components
import Typography from "@mui/material/Typography"

const componentStyles = {
  placeHolderMessage: {
    fontFamily: "Archivo",
    fontSize: "14px",
    fontWeight: 400,
    color: "text.disabled",
    lineHeight: "32px",
    letterSpacing: "1px",
  },
}

export default function PlaceHolderMessage(props) {
  const returnMessage = () => {
    switch (props?.type) {
      case "noPeakPay":
        return "MEMBER IS NOT ON PEAKPAY"
      case "noActiveCoverage":
        return "NO ACTIVE COVERAGE"
      default:
        return null
    }
  }

  return (
    <>
      <Typography sx={componentStyles.placeHolderMessage}>
        {returnMessage()}
      </Typography>
    </>
  )
}
