import React, { Suspense, createContext, useState } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
// import signalRConnection from './signalr';
import SwitchWrapper from './SwitchWrapper';






// signalRConnection.onNotification((message) => {
//     console.log('In Event Notification + ' + JSON.stringify(message));
//     return JSON.stringify(message)
// });

// signalRConnection.onChat((message) => {
//     console.log('In Chat Notification ' + JSON.stringify(message));
// });

// signalRConnection.onChat((message) => {
//     console.log('On Chat Me Too! + ' + JSON.stringify(message));
// });

// signalRConnection.onReportNotification((message) => {
//     console.log('On Report Notification:' + JSON.stringify(message));
// });

// signalRConnection.connect(() => {
//     // send message after connecting
//     // commented out for now, no need to send messages
//     //signalRConnection.sendMessage();
// });

if (process.env.NODE_ENV === 'production') {
    console.log = () => {}
    console.error = () => {}
    console.debug = () => {}
}

ReactDOM.render(
    <React.StrictMode>
        <SwitchWrapper />
    </React.StrictMode>,
document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();