import React, { useContext, useEffect, useLayoutEffect, useState } from "react";
import { Prompt, useHistory } from "react-router-dom";

//email editor
import { Editor, EditorState, convertFromHTML } from 'draft-js'
import 'draft-js/dist/Draft.css'
import { convertToHTML } from 'draft-convert'

//Date Adapter 
import { format, formatDistance } from "date-fns";

//MuiIcons
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import RefreshIcon from '@mui/icons-material/Refresh';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SmartToyOutlinedIcon from '@mui/icons-material/SmartToyOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import Create from "@mui/icons-material/CreateOutlined";
import PlayCircleIcon from '@mui/icons-material/PlayCircleFilledWhiteOutlined';
import CloseIcon from '@mui/icons-material/Close';
import ShortcutIcon from '@mui/icons-material/Shortcut';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import MapsUgcOutlinedIcon from '@mui/icons-material/MapsUgcOutlined';

//Mui Components
import TextField from "@mui/material/TextField";
import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Avatar from '@mui/material/Avatar';

// Assets
import openSvg from '../../../../../assets/images/openSvg.svg';
import closedSvg from '../../../../../assets/images/closedSvg.svg';

//Services
import ticketService from '../../../../../services/ticket-service'
import optionService from "../../../../../services/optionService";


import { thisUserContext } from "../../../nav-container/NavContainer";
import { Box } from "@mui/system";
import { Paper, Typography } from "@mui/material";
import { Comment, ReplyOutlined } from "@mui/icons-material";

export default function SingleviewMain(props) {
   let ticket = props.ticket;
   let member = props.member;
   // console.log('Ticket member',member,ticket)
   let myContext = useContext(thisUserContext)
   let user = myContext.thisUser;


   const [ticketOrigins, setTicketOrigins] = useState([])
   const getTicketOrigins = async () => {
      let _origins = await optionService.getTicketOrigins()
      // console.log('origins',_origins)
      setTicketOrigins(_origins)
   }
   useEffect(() => {
      getTicketOrigins()
   }, [])



   //Setting our height for the ticket message area
   const [pushHeight, setPushHeight] = useState(0)
   let ticketMessageDiv = document.getElementById('ticket-messages')
   useLayoutEffect(() => {
      if (ticketMessageDiv) {
         let lengthFromTop = Math.ceil(ticketMessageDiv.getBoundingClientRect().top)
         setPushHeight(lengthFromTop)
      }
   })


   //Get all of our drafts on load
   useEffect(async () => {
      getDrafts()



      // let unload = (e) => {
      //    console.log(e)
      //    e.preventDefault()
      //    e.returnValue = "Have you saved!!!???"
      //    return e.returnValue
      // }

      // window.addEventListener("beforeunload", unload)

      // return () => {
      //    window.removeEventListener("beforeunload",unload)
      // }
   }, [])

   //Drafts from (localStorage/Database)
   const [drafts, setDrafts] = useState([])

   const getDrafts = () => {
      let drafts = localStorage.getItem("messageDrafts")
      if (drafts === 'undefined') {
         setDrafts([])
      } else {
         drafts = JSON.parse(drafts)
         setDrafts(drafts)
      }
   }
   const saveDrafts = () => {
      localStorage.setItem('messageDrafts', JSON.stringify(drafts))
      setMessageInput('')
      setInputUid('')
      setExpandReply(false)
   }
   const saveDraft = (e) => {
      let _drafts
      if (drafts == null) {
         _drafts = []
      } else {
         _drafts = drafts?.map(x => x)
      }
      // console.log("savedraft",e.target.value)
      if (e.target.value.length > 0) {
         //if we have a uid, overwrite that draft
         let draftUid = e.target.value
         _drafts.find(draft => draft.uid == draftUid).message = messageInput
         _drafts.find(draft => draft.uid == draftUid).timeSince = new Date()
         _drafts.find(draft => draft.uid == draftUid).msgType = messageType
      } else {
         //else we're saving a new draft
         let newDraft = {
            'userId': user.id,
            'ticketId': ticket.id,
            'senderFirstName': user.firstName,
            'senderLastName': user.lastName, //internal user
            'msgType': messageType,
            'sendDate': format(new Date(), 'MMM d h:mm a'),
            'timeSince': new Date(),
            'message': messageInput,
            'uid': crypto.randomUUID()
         }
         _drafts.unshift(newDraft)
      }

      localStorage.setItem('messageDrafts', JSON.stringify(_drafts))
      getDrafts()
   }
   const editDraft = (e, id, incomingType) => {
      let value = id
      let messageType = incomingType
      setMessageType(incomingType)
      setExpandReply(true)
      if (messageType == 'Note') {
         setMessageDisplayType('Internal Comment')
      }
      if (messageType == 'Email') {
         setMessageDisplayType(`Email: ${member?.email}`)
      }
      if (messageType == 'Text Message') {
         setMessageDisplayType('Text: 303-222-2222')
      }

      let _drafts
      if (drafts == null) {
         _drafts = []
      } else {
         _drafts = drafts?.map(x => x)
      }

      //Check for unsaved changes

      //replace current email with draft
      let draftMsg = (_drafts.find(draft => draft?.uid == value)?.message || '')
      setMessageInput(draftMsg)
      setInputUid(value)
   }


   const deleteDraft = (id) => {
      let value = id
      setExpandReply(false)

      let _drafts
      if (drafts == null) {
         _drafts = []
      } else {
         _drafts = drafts?.map(x => x)
      }

      //remove from drafts
      _drafts = _drafts.filter(draft => draft.uid != value)

      _drafts.forEach(draft => {
         setAnchorElDraft({
            ...anchorElDraft,
            [draft.uid]: null
         })
      })
      setDrafts(_drafts)
   }

   useEffect(() => {
      saveDrafts()
   }, [drafts])

   const [editId, setEditId] = useState(0)
   const [editInput, setEditInput] = useState('')
   const editComment = (entry) => {
      console.log('editing comment')
      setEditId(entry.id)
      setEditInput(entry.message)
   }

   const updateComment = async (entry) => {
      console.log('updating comment')
      entry.message = editInput
      entry.cc = ''
      entry.subject = ''
      console.log('comment', entry)
      await ticketService.updateTicketNote(entry)
      cancelEdit()
      props.refreshTickets()

   }

   const deleteComment = async (id) => {
      // console.log(id)
      console.log(ticketEntries.find(ticket => ticket.id == id))
      await ticketService.deleteTicketNote(id)
      props.refreshTickets()

   }

   const cancelEdit = () => {
      setEditId(0)
      setEditInput('')
   }

   //Current user message
   const [messageInput, setMessageInput] = useState()
   const [inputUid, setInputUid] = useState('')
   const [messageType, setMessageType] = useState('Email')
   const messageInputHandler = (e) => {
      let input = e.target.value
      setMessageInput(input)
   }

   //Message type
   const [anchorEl, setAnchorEl] = useState(null);
   const open = Boolean(anchorEl);
   const handleMessageTypeClick = (e) => {
      setAnchorEl(e.currentTarget);
   };

   const [messageDisplayType, setMessageDisplayType] = useState()

   const handleMessageTypeClose = (e) => {
      let messageType = e.target.getAttribute('value')
      if (messageType == null) {
         setAnchorEl(null);
      } else {
         setMessageType(messageType)
         setAnchorEl(null);
         setMessageDisplayType(e.target.innerText)
      }
   };

   const [destination, setDestination] = useState('')

   const handleResponseButton = (e, val) => {
      setExpandReply(true)
      setMessageType(val)
      if (val == 'Note') {
         setMessageDisplayType('Internal Comment')
         setDestination('')
         setMessageInput('')
      }
      if (val == 'Email') {
         setMessageDisplayType(`Email: ${member?.email}`)
         setDestination(member?.email)
      }
      if (val == 'Text Message') {
         setMessageDisplayType('Text: 303-222-2222')
         setDestination('8179142311')
      }
   }


   const handleAddTicketEntry = async (e) => {
      let ticketEntryTypeId = parseInt(e.currentTarget.getAttribute('data-ticketTypeId'))

      let newTicketEntry = {
         message: messageInput,
         destination: destination,
         ticketEntryTypeId: ticketEntryTypeId,
         subject: `New response on Ticket #${ticket.id}`,
         groupPlanName: ticket.groupPlan,
         userId: user.id,
         memberId: member.id
      }
      if (messageInput) {
         await ticketService.insertTicketEntry(ticket.id, newTicketEntry)
         setExpandReply(false)
         setMessageInput('')
         props.refreshTickets()
         if (ticketEntryTypeId == 4) {
            props.setMessage({
               ...props.message,
               messageDisplay: true,
               autoHideDuration: 1500,
               backgroundColor: '#195FFB',
               severity: "success",
               message: "Comment successfully posted"
            })
         }

         if (ticketEntryTypeId == 1 || ticketEntryTypeId == 2) {
            props.setMessage({
               ...props.message,
               messageDisplay: true,
               autoHideDuration: 1500,
               backgroundColor: '#195FFB',
               severity: "success",
               message: "Message successfully sent"
            })
         }

      } else
         props.setMessage({
            ...props.message,
            messageDisplay: true,
            autoHideDuration: 1500,
            backgroundColor: 'error',
            severity: "error",
            message: "Message cannot be blank"
         })




   }


   //Unsaved changes
   const [unsavedChanges, setUnsavedChanges] = useState(true)
   //onBeforeUnload

   // Expand/Collapse Messages
   const [expandReply, setExpandReply] = useState(false)


   const [ticketEntries, setTicketEntries] = useState([])
   useEffect(() => {
      setTicketEntries(ticket.ticketEntries)
      console.log(ticket)

   }, [ticket])



   const history = useHistory();
   let unblock;
   if (unsavedChanges) {
      //.block returns a cleanup function
      // unblock = history.block((attempt) => {
      //    console.log(attempt)
      //    console.log(history)
      //    console.log(window.location)
      //    console.log(unblock)
      //    if (window.confirm(`Are you sure you want to go?`)) {
      //       unblock();

      //    } else {
      //       console.log("else")
      //       return false
      //    }
      // });
   }

   const handleCancel = () => {
      setExpandReply(false);
      setMessageInput('');
   }

   const makeAvatar = (fullName) => {
      let names = fullName.split(" ")
      let firstName = names[0]
      let lastName = names[names.length - 1]

      return `${firstName[0].toUpperCase()}${lastName[0].toUpperCase()}`
   }


   const [hover, setHover] = useState({
      isHovering: false,
      id: 0,
   })

   const handleMouseEnter = (e, id) => {
      setHover({
         isHovering: true,
         id: id
      })
   }

   const handleMouseLeave = (e, id) => {
      setHover({
         isHovering: false,
         id: 0
      })
   }

   //Different anchor per message
   const [anchorElEntry, setAnchorElEntry] = useState({
      //uid:anchor
   });
   const handleEntryMenuClick = (event) => {
      setAnchorElEntry({
         ...anchorElEntry,
         [event.currentTarget.value]: event.currentTarget
      });
   };
   const handleEntryMenuClose = () => {
      setAnchorElEntry(null);
   };

   //Different anchor per message
   const [anchorElDraft, setAnchorElDraft] = useState({
      //uid:anchor
   });
   // const draftMenuOpen = Boolean(anchorElDraft);
   const handleDraftMenuClick = (event) => {
      setAnchorElDraft({
         ...anchorElDraft,
         [event.currentTarget.value]: event.currentTarget
      });
   };
   const handleDraftMenuClose = () => {
      setAnchorElDraft(null);
   };


   const [subject, setSubject] = useState('')
   useEffect(() => setSubject(ticket.subject), [ticket.subject])

   const handleTicketInfoChange = async (e, property, action) => {
      let value = e.target.value
      if (property == 'subject') {
         setSubject(value)
      }
   }

   const handleSaveTextField = async (e, property) => {
      if (property == "subject" && subject !== ticket.subject) {
         ticket.subject = subject
         // await ticketService.updateSubject(ticket?.id, subject)
         //leaving generic updateTicket method in place for now b/c updateSubject is not triggering a notification
         await ticketService.updateTicket(ticket)
         props.refreshTickets()
         props.setMessage({
            ...props.message,
            messageDisplay: true,
            autoHideDuration: 1500,
            backgroundColor: '#195FFB',
            severity: "success",
            message: "Successfully Updated"
         })
      }

   }

   const [expand, setExpand] = useState(false)

   const handleExpandClick = () => {
      setExpand(prev => !prev)
   }

   const [editorState, setEditorState] = useState(() => EditorState.createEmpty())
   useEffect(() => {
      let currContent = editorState.getCurrentContent()
      let html = convertToHTML(currContent)
      if (html && html != '<p></p>') setMessageInput(html)
   }, [editorState])
   // console.log('editorState',editorState)

   useEffect(() => {
      if(messageInput == ''){
         setEditorState(() => EditorState.createEmpty())
      }
   },[messageInput])

   const debugClick = () => {
      let currContent = editorState.getCurrentContent()
      console.log('currContent', currContent)
      let html = convertToHTML(currContent)
      console.log('email as html\n', html)

      let selection = editorState.getSelection()
      console.log('selection', selection)
      let currInlineStyle = editorState.getCurrentInlineStyle()
      console.log('currInlineStyle', currInlineStyle)
      let blockTree = editorState.getBlockTree()
      console.log('blockTree', blockTree)
   }

   return (
      <Box sx={{
         bgcolor: 'background.default',
         color: 'text.primary',
         borderColor: 'background.border',
         height: 'calc(100vh - 107px)', 
         fontFamily: 'Archivo',
         minWidth: '700px',
      }} className='col-6' 

      >

         {/* CONVERSATION HEADER */}
         <Box sx={{
            bgcolor: 'background.default',
            color: 'text.primary',
            // border: 'solid 1px',
            borderColor: 'background.border'
         }} className="flex-row" style={{ padding: '21px 23px 16px' }}
            onClick={e => debugClick()}>
            <div className='col-10'>

               <div className="flex-row">
                  <div style={{ width: 'auto', alignSelf: 'center' }}>
                     <Box sx={{ height: 40, width: 40, backgroundColor: ticket?.ticketStatusId === 2 ? 'text.secondary' : 'primary.main', color: 'white', borderRadius: '8px', display: 'flex', alignItems: 'center', justifyContent: 'center', margin: 'auto' }}><Typography textAlign='center' variant='h5' fontWeight='600'>{ticket?.ticketStatusId === 2 ? 'C' : 'O'}</Typography></Box>
                     {/* <Typography variant='overline'>OPEN</Typography> */}
                  </div>
                  <div style={{ display: 'block', marginLeft: '10px' }}>
                     {/* <span style={{ fontFamily: "Archivo", fontSize: "20px", fontWeight: "500" }}>{ticket?.subject}</span><br /> */}


                     <TextField variant='standard' fullWidth
                        value={subject}
                        onChange={e => handleTicketInfoChange(e, 'subject')}
                        onBlur={e => handleSaveTextField(e, 'subject')}
                        InputLabelProps={{
                           style: { fontFamily: 'Archivo', padding: 0 },
                        }}
                        InputProps={{
                           sx: {
                              fontFamily: "Archivo",
                              cursor: 'text',
                              fontSize: '20px',
                              fontWeight: "500",
                              padding: 0,
                              margin: 0,
                              '&.MuiInput-underline:before': { borderBottom: 'none' },
                              '&.MuiInput-input': { padding: 0 }
                           },
                        }}
                     />
                     <Typography color='text.secondary' variant='caption'>
                        {/* Has the ticket been edited since it was created */}
                        Ticket ID #{ticket?.id} |
                        {ticket.lastModifiedDate && <span> Last Updated {formatDistance(new Date(ticket.lastModifiedDate), new Date())} Ago</span>
                        }
                        {!ticket.lastModifiedDate && ticket.dateCreated && <span> Last Updated {formatDistance(new Date(ticket?.dateCreated), new Date())} Ago </span>
                        }
                     </Typography>
                  </div>



               </div>
            </div>
            <div className='col-2 flex-row' style={{ alignItems: 'center', display: 'flex', justifyContent: 'flex-end' }}>
               <div><IconButton
                  onClick={e => { props.refreshTickets() }}>
                  <RefreshIcon sx={{ color: 'text.secondary' }} />
               </IconButton></div>
               {/* <div><MoreVertIcon sx={{ color: '#79747E' }} /></div> */}
            </div>
         </Box>

         {/* MESSAGE REPLY BUTTONS */}
         <div className="flex-row" style={{ padding: "20px", justifyContent: "end" }}>
            <Button
               startIcon={<MapsUgcOutlinedIcon sx={{ color: "primary.main" }} />}
               variant='text'
               sx={{ textTransform: "uppercase", marginRight: '16px' }}
               onClick={e => handleResponseButton(e, 'Note')}
            >
               Leave Internal Comment
            </Button>
            <Button
               endIcon={<ReplyOutlined sx={{ color: 'inherit' }} />}
               variant='contained'
               onClick={e => handleResponseButton(e, 'Email')}
            >
               Reply
            </Button>

         </div>

         {/* MESSAGE INPUT */}
         <div style={{ position: 'relative', padding: '20px 30px', height: '', display: expandReply ? "inherit" : "none", }}>
            <Box sx={{ display: 'flex', alignItems: 'center', color: '#00000099', border: "1px solid #66666680", borderBottom: 'none', height: "44px", borderLeft: messageType == 'Note' ? '5px solid' : '1px solid', borderLeftColor: messageType == 'Note' ? 'primary.main' : '#66666680', borderRadius: '5px 5px 0px 0px', cursor: messageType == 'Note' ? 'default' : 'pointer' }}>
               {messageType == 'Email' &&
                  <EmailOutlinedIcon sx={{ color: "text.secondary", marginLeft: "10px" }} />
               }
               {messageType == 'Text Message' &&
                  <PhoneAndroidIcon sx={{ color: "text.secondary", marginLeft: "10px" }} />
               }

               <div onClick={handleMessageTypeClick}>
                  <Typography color="text.secondary" variant="body2" sx={{ marginLeft: "10px", pointer: messageType == 'Note' ? 'none' : 'cursor' }}>{messageDisplayType} {messageType !== 'Note' ? <KeyboardArrowDownIcon sx={{ marginLeft: '20px', color: 'text.secondary', pointerEvents: 'none' }} /> : ''}</Typography>
               </div>
               {messageType !== 'Note' &&
                  <Menu
                     anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                     transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                     anchorEl={anchorEl}
                     open={open}
                     onClose={handleMessageTypeClose}
                     style={{ marginLeft: "20px" }}
                  >
                     <MenuItem value='Email' onClick={handleMessageTypeClose}><EmailOutlinedIcon sx={{ color: "text.secondary" }} /><Typography value="Email" color="text.primary" style={{ marginLeft: "10px", fontFamily: "Archivo", }}>Email: {member?.email}</Typography></MenuItem>
                     <MenuItem disabled value='Text Message' onClick={handleMessageTypeClose}><PhoneAndroidIcon sx={{ color: "text.secondary" }} /><Typography value="Text Message" color="text.primary" style={{ marginLeft: "10px", fontFamily: "Archivo", }}>Text: (303) 425-1234</Typography></MenuItem>
                     {/* <MenuItem value='Note' onClick={handleMessageTypeClose}>Internal Comment</MenuItem> */}
                  </Menu>

               }


            </Box>

            <div style={{ height: '75%', overflowY: 'auto', border: '1px #66666680 solid', marginBottom: '20px', borderRadius: '0px 0px 5px 5px' }}>
               {messageType == 'Email'
                  ?
                  <div style={{ padding: '11px 15px', height: 'calc(20vh - 14px)', display: 'block', fontSize: 15, borderRadius: '30px', fontFamily: 'Archivo' }}>
                     <Editor editorState={editorState} onChange={setEditorState} />
                  </div>
                  :
                  <TextField variant='standard' multiline fullWidth
                     placeholder="Type message..."
                     value={messageInput} onChange={messageInputHandler}
                     InputProps={{
                        // inputProps:{'data-uid':inputUid},
                        disableUnderline: true,
                        sx: { padding: '11px 15px', height: 'calc(20vh - 14px)', display: 'block', fontSize: 15, borderRadius: '30px', fontFamily: 'Archivo' }
                     }}
                  />
               }

               {/* <Prompt
                     when={unsavedChanges}
                     message={() => 'NOOOOOOOOOO'}
                  /> */}
            </div>

            <div className="flex-row" style={{ height: '23%', alignItems: 'center' }}>
               <div className="col-6" >
                  {messageType != 'Note' &&
                     <Button variant='outlined' sx={{ marginRight: '16px' }} onClick={(e) => handleCancel()}>
                        Cancel
                     </Button>
                     // <IconButton sx={{ color: 'text.secondary' }}
                     //    onClick={handleCancel} >
                     //    <DeleteOutlineIcon />
                     // </IconButton>
                  }
               </div>
               <div className="col-6" style={{ float: "right", textAlign: "right" }}>
                  {/* <SentimentSatisfiedAltIcon fontSize='small' sx={{ color: "#79747E", marginRight: '10px' }} /> */}

                  {messageType !== 'Note' &&
                     <>
                        {/* <AttachFileIcon fontSize='small' sx={{ color: "text.secondary", marginRight: '10px' }} /> */}
                        <Button
                           onClick={saveDraft}
                           variant='text'
                           sx={{ marginRight: '10px', color: 'text.secondary' }}
                           value={inputUid}
                        >
                           Save as draft
                        </Button>
                     </>

                  }


                  {messageType !== 'Note' &&
                     <Button variant='contained' data-ticketTypeId={messageType == 'Email' ? '1' : '2'}
                        onClick={e => handleAddTicketEntry(e)}>
                        Send
                     </Button>
                  }

                  {messageType == 'Note' &&
                     <>
                        <Button variant='outlined' sx={{ marginRight: '16px' }} onClick={(e) => handleCancel()}>
                           Cancel
                        </Button>
                        <Button variant='contained' data-ticketTypeId='4'
                           onClick={e => handleAddTicketEntry(e)}>
                           Comment
                        </Button>
                     </>
                  }

               </div>
            </div>
         </div>


         {/* INDIVIDUAL MESSAGES & DRAFTS */}
         <Box sx={{
            bgcolor: 'background.background',
            color: 'text.primary',
            borderTop: '1px solid',
            borderColor: 'background.border'
         }}
            id='ticket-messages'
            style={{ height: `calc(100vh - ${pushHeight}px)`, overflowX: 'hidden', overflowY: 'auto' }}>
            {/* DRAFTS */}
            {drafts?.map(entry => {
               if (entry.ticketId == ticket.id) {
                  return (
                     <Paper elevation={0} sx={{ backgroundColor: 'background.default', borderRadius: '0', opacity: hover.isHovering && hover.id == entry.id ? "100%" : "95%", border: 'solid 1px', borderColor: 'background.border', borderTop: '0', borderRight: '0', borderLeft: entry.ticketEntryType == 'Note' ? 'solid 3px' : '0px', borderLeftColor: entry.ticketEntryType == 'Note' ? 'primary.main' : '' }} key={entry.id} className='flex-row'
                        style={{ padding: '27px 10px 20px 27px', transition: "box-shadow .5s, background-color .5s", minHeight: "100px" }}
                        onMouseEnter={e => handleMouseEnter(e, entry.id)}
                        onMouseLeave={e => handleMouseLeave(e, entry.id)}
                     >
                        {entry.sender == 'Automated Message' &&
                           <div style={{ width: 54, height: 50, backgroundColor: '#195FFB26', borderRadius: 4, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                              <SmartToyOutlinedIcon fontSize="medium" sx={{ color: '#195FFB' }} />
                           </div>
                        }
                        {entry.sender !== 'Automated Message' &&
                           <Avatar sx={{ bgcolor: "#195FFB17", color: "#195FFB", fontFamily: "Archivo", fontWeight: "600", width: 44, height: 44, fontSize: 22 }} src={entry?.userId > 0 ? `api/image/user/${entry?.userId}` : ''}>
                              {entry.senderFirstName && `${entry.senderFirstName[0].toUpperCase()}${entry.senderLastName[0].toUpperCase()}`}
                           </Avatar>
                        }
                        <div style={{ width: '100%', marginLeft: '14px' }}>
                           <div className="row">
                              <div className="col-8" style={{ fontSize: 16, fontWeight: 500 }}>
                                 <span>{entry.senderFirstName} {entry.senderLastName}</span>
                                 <span style={{ color: 'rgb(255, 138, 0)', fontSize: 14 }}> via {entry.msgType} Draft</span>
                                 {/* <IconButton
                                 value={entry.uid} onClick={editDraft}>
                                    <Create />
                                 </IconButton>
                                 <IconButton
                                 value={entry.uid} onClick={deleteDraft} >
                                    <DeleteOutlineIcon />
                                 </IconButton> */}
                                 <br />
                                 <span style={{ fontSize: 15, lineHeight: '24px', opacity: "75%" }}>
                                    <div dangerouslySetInnerHTML={{ __html: entry?.message?.replace(/(<? *script)/gi, 'illegalscript') }} >
                                    </div>
                                 </span>
                              </div>
                              <div className="col-3" style={{ fontSize: 16, fontWeight: 400, textAlign: 'end' }}>
                                 <span style={{ color: 'rgb(255, 138, 0)', fontSize: 14 }}>Draft</span>
                                 <p style={{ fontSize: 13, marginTop: 0 }}>Last updated {formatDistance(new Date(entry.timeSince), new Date())}</p>
                              </div>
                              <div className="col-1">
                                 <IconButton
                                    // key={entry.uid}
                                    value={entry.uid}
                                    onClick={handleDraftMenuClick}
                                 >
                                    <MoreVertIcon />
                                 </IconButton>
                                 <Menu
                                    // key={entry.uid}
                                    value={entry.uid}
                                    anchorEl={anchorElDraft?.[entry.uid]}
                                    open={Boolean(anchorElDraft?.[entry.uid])}
                                    onClose={handleDraftMenuClose}
                                    PaperProps={{
                                       style: {
                                          backgroundColor: "#F9F9F9",
                                          boxShadow: "-1px 1px 4px rgba(0, 0, 0, 0.05)",
                                          borderRadius: "10px",
                                          color: "#646464"
                                       }
                                    }}
                                 >
                                    {/* {console.log(entry.uid,anchorElDraft)} */}
                                    <MenuItem
                                       onClick={(e) => {
                                          handleDraftMenuClose()
                                          editDraft(e, entry.uid, entry.msgType)
                                       }}
                                       style={{ borderBottom: "1px solid #F0F0F0" }}
                                       disableRipple >
                                       <Create /><span style={{ padding: "0px 10px", fontFamily: "Archivo" }}>Edit</span>
                                    </MenuItem>
                                    <MenuItem
                                       onClick={() => {
                                          handleDraftMenuClose()
                                          deleteDraft(entry.uid)
                                       }}
                                       disableRipple >
                                       <DeleteOutlineIcon /><span style={{ padding: "0px 10px", fontFamily: "Archivo" }}>Delete</span>
                                    </MenuItem>
                                 </Menu>
                                 {/* <IconButton
                                    value={entry.uid} onClick={deleteDraft} >
                                    <DeleteOutlineIcon />
                                 </IconButton>
                                 <IconButton
                                    value={entry.uid} data-messageType={entry.msgType} onClick={editDraft}>
                                    <Create />
                                 </IconButton> */}
                              </div>

                           </div>
                        </div>
                     </Paper>
                  );
               }
            })}

            {/* MESSAGES */}
            {ticketEntries?.sort((a, b) => new Date(b.entryDate) - new Date(a.entryDate)).map(entry => {
               return (
                  <Paper elevation={0} sx={{ backgroundColor: 'background.default', borderRadius: '0', opacity: hover.isHovering && hover.id == entry.id ? "100%" : "95%", border: 'solid 1px', borderColor: 'background.border', borderTop: '0', borderRight: '0', borderLeft: entry.ticketEntryType == 'Note' ? 'solid 3px' : '0px', borderLeftColor: entry.ticketEntryType == 'Note' ? 'primary.main' : '' }} key={entry.id} className='flex-row'
                     style={{ padding: '27px 10px 20px 27px', transition: "box-shadow .5s, background-color .5s", minHeight: "100px" }}
                     onMouseEnter={e => handleMouseEnter(e, entry.id)}
                     onMouseLeave={e => handleMouseLeave(e, entry.id)}
                  >
                     {entry.from == 'Automated Message' &&
                        <div style={{ width: 54, height: 50, backgroundColor: 'primary.light', borderRadius: 4, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                           <SmartToyOutlinedIcon fontSize="medium" sx={{ color: 'primary.main' }} />
                        </div>
                     }
                     {entry.from !== 'Automated Message' &&
                        <Avatar sx={{ bgcolor: "primary.light", color: "primary.main", fontFamily: "Archivo", fontWeight: "600", width: 44, height: 44, fontSize: 22 }} src={entry?.userId > 0 ? `api/image/user/${entry?.userId}` : ''}>
                           {entry?.userDisplayName && makeAvatar(entry?.userDisplayName)}
                           {/* {entry.from?.firstName && `${entry.from?.firstName[0].toUpperCase()}${entry.from?.lastName[0].toUpperCase()}`} */}
                        </Avatar>
                     }
                     <div style={{ width: '100%', marginLeft: '14px' }}>
                        <div className="row">
                           <div className="col-8" style={{ position: 'relative' }}>
                              {entry.from &&
                                 <>
                                    <span style={{ fontSize: 15, fontWeight: 500 }}>{entry?.userDisplayName}</span>
                                 </>
                              }
                              {!entry.from &&
                                 <span>Unknown Agent</span>}
                              <span> via&nbsp;
                                 {entry.ticketEntryType == 'Note' &&
                                    <Typography variant='body1' sx={{ color: "primary.main", display: 'inherit' }}>Private Internal Comment</Typography>
                                 }
                                 {entry.ticketEntryType !== 'Note' &&
                                    <>
                                       <span> {entry.ticketEntryType} {entry.from}</span>
                                       <IconButton onClick={(e) => handleExpandClick()}>
                                          {expand ?
                                             <KeyboardArrowDownIcon />
                                             :
                                             <KeyboardArrowUpIcon />
                                          }
                                       </IconButton>
                                       {expand ?
                                          <>
                                             <br />
                                             <span>To: {entry.memberDisplayName} <span style={{ color: "#195FFB", cursor: "pointer" }}>{entry.to}</span></span><br />
                                             <span>CC: {entry.cc} </span><br />
                                             <span>Subject Line: {entry.subject}</span>
                                          </>
                                          :
                                          <>
                                          </>}


                                    </>
                                 }
                              </span>
                              <br />
                              {entry.ticketEntryType == 'Email' &&

                                 // <iframe
                                 //    src='api/ticket/test'
                                 // />
                                 <span style={{ fontSize: 15, lineHeight: '24px', fontWeight: 400 }}>
                                    <div dangerouslySetInnerHTML={{ __html: entry?.message?.replace(/(<? *script)/gi, 'illegalscript') }} >
                                    </div></span>
                              }
                              {editId != entry.id && entry.ticketEntryType !== 'Email' &&
                                 <span style={{ fontSize: 15, lineHeight: '24px', fontWeight: 400 }}> {entry?.message}</span>
                              }
                              {editId == entry.id &&
                                 <>
                                    <TextField variant='filled' multiline fullWidth
                                       placeholder="Type message..."
                                       value={editInput} onChange={e => setEditInput(e.target.value)}
                                       sx={{ marginTop: '10px' }}
                                       InputProps={{
                                          // inputProps:{'data-uid':inputUid},
                                          // disableUnderline: true,
                                          // sx: { padding: '11px 15px', height: 'calc(20vh - 14px)', display: 'block', fontSize: 15, borderRadius: '30px', fontFamily: 'Archivo' }
                                       }}
                                    />
                                    <div style={{ marginTop: '5px', display: 'flex', justifyContent: 'space-between' }}>
                                       <Button onClick={cancelEdit}>
                                          Cancel
                                       </Button>

                                       <Button onClick={() => updateComment(entry)}>
                                          Save
                                       </Button>
                                    </div>
                                 </>
                              }
                              {entry.msgType == 'Voicemail' &&
                                 <div><PlayCircleIcon sx={{ width: '20px', height: '20px' }} /></div>
                              }
                           </div>
                           <div className="col-3" style={{ fontSize: 15, fontWeight: 400, textAlign: 'end' }}>
                              <span>{format(new Date(entry.entryDate), 'MMM d h:mm a')}</span>
                              <p style={{ fontSize: 14, marginTop: 4 }}>{formatDistance(new Date(entry.entryDate), new Date())} ago</p>
                           </div>
                           <div className="col-1">
                              <IconButton
                                 onClick={handleEntryMenuClick}
                                 value={entry.id}
                                 disabled={entry.userId == user?.id ? false : true}
                              >
                                 <MoreVertIcon />
                              </IconButton>
                              <Menu
                                 value={entry.id}
                                 anchorEl={anchorElEntry?.[entry.id]}
                                 open={Boolean(anchorElEntry?.[entry.id])}
                                 onClose={handleEntryMenuClose}
                                 PaperProps={{
                                    style: {
                                       backgroundColor: "#F9F9F9",
                                       boxShadow: "-1px 1px 4px rgba(0, 0, 0, 0.05)",
                                       borderRadius: "10px",
                                       color: "#646464"
                                    }
                                 }}
                              >

                                 <MenuItem
                                    onClick={(e) => {
                                       handleEntryMenuClose()
                                       editComment(entry)
                                    }}
                                    style={{ borderBottom: "1px solid #F0F0F0" }}
                                    // disabled
                                    disableRipple
                                 >
                                    <Create /><span style={{ padding: "0px 10px", fontFamily: "Archivo" }}>Edit</span>
                                 </MenuItem>
                                 <MenuItem
                                    onClick={() => {
                                       handleEntryMenuClose()
                                       deleteComment(entry.id)
                                    }}
                                    disableRipple
                                    disabled
                                 >
                                    <DeleteOutlineIcon /><span style={{ padding: "0px 10px", fontFamily: "Archivo" }}>Delete</span>
                                 </MenuItem>
                              </Menu>
                           </div>
                           {/* <div className='col-12' style={{ fontSize: 15, lineHeight: '24px', fontWeight: 400 }}>
                              {entry.message}
                           </div> */}
                        </div>
                     </div>
                  </Paper>
               );
            })}
         </Box>

      </Box >
   );
}