import React, { useEffect, useState, useContext, createContext } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { GlobalStyles } from '@mui/material';
import './App.css';
import signalRConnection from './signalr';
import { formatDistance } from 'date-fns';

//Services
import accountService from './services/account-service';
import authService from './services/auth-service';
import optionService from './services/optionService';
import userService from './services/user-service';
//Our Components
import SingleviewTicket from './components/app-internal/Ticketing/SingleView/SingleviewTicket.js';
import TicketOverview from './components/app-internal/Ticketing/OverView/TicketOverview.js';
import SettingsRouter from './components/app-internal/Settings/SettingsRouter';
import Dashboard from './components/app-internal/Dashboard/Dashboard.js';
import NavContainer, {
  thisUserContext,
} from './components/app-internal/nav-container/NavContainer.js';
import Login from './components/app-internal/login/login.js';
import NotFound from './components/notfound/NotFound';
import ResetPassword from './components/app-internal/reset-password/reset-password.js';
import MemberProfile from './components/app-internal/Member/member-profile/MemberProfile';
import AllMembersView from './components/app-internal/Member/all-members-view/AllMembersView';
import Messaging from './components/app-internal/Messaging/messaging.js';
import ReportingDashboard from './components/app-internal/Reporting/ReportingDashboard';

//MUI Components
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import Slide from '@mui/material/Slide';
import Box from '@mui/material/Box';
import PlanManagement from './components/app-internal/planManagement/planManagement.js';
import SinglePlanManagement from './components/app-internal/planManagement/singlePlanManagement.js';

// import UserSettings from "./components/app-internal/User/user-settings/UserSettings";
// import StandaloneSearch from './components/standalone-search/StandaloneSearch'

export let AgentColorModeContext = createContext();

export default function AppRouter() {
  let lightTheme = createTheme({
    palette: {
      mode: 'light',
      background: {
        default: '#FFFFFF',
        paper: '#F9F9F9',
        background: '#F3F5F9',
        // border: "rgba(224, 224, 224, 1)",
        border: 'rgba(0,0,0, 0.23)',
        activeWorking: '#EAF2EA',
        onMedicalLeaveChip: 'rgba(2, 136, 209, 0.12)',
        onDisabilityChip: 'rgba(206, 147, 216, 0.16)',
        furloughedChip: '#FCE4EC',
        retiredChip: '#90CAF9',
        notActivelyWorking: 'rgba(0, 0, 0, 0.08)',
        inProgressChip: 'rgba(251, 140, 15, 0.08)',
        enrollmentNotStartedChip: 'rgba(0, 0, 0, 0.08)',
        enrollmentSubmittedChip: '#EAF2EA',
        enrollmentCancelledChip: 'rgba(211, 47, 47, 0.12)',
        paymentScheduleChip: 'rgba(2, 136, 209, 0.12)',
        refundChip: 'rgba(237, 108, 2, 0.12)',
        newPaymentSchedule: 'rgba(237, 108, 2, 0.08)',
        notification: {
          regular: '#E5F3FA',
          critical: '#FBEAEA',
        },
        recommendation: '#E9F6F5',
        historyDateRow: 'rgba(245, 250, 253, 1)',
        snackbar: '#e8efff',
        selectedBankAccount: '#f6f9ff',
        enrollmentState: {
          start: 'rgba(84, 33, 201, .06)',
          submitted: 'rgba(46, 125, 50, .1)',
          inProgress: 'rgba(237, 108, 2, 0.04)',
          cancelled: '#FBEAEA',
        },
        currentAcMessage: 'rgba(0, 0, 0, 0.04)',
      },
      primary: {
        main: '#195ffb',
        light: '#195ffb20',
        accent: '#195ffb',
        activeChip: '#1B5E20',
        onMedicalLeaveChip: 'rgba(1, 87, 155, 1)',
        onDisabilityChip: 'rgba(123, 31, 162, 1)',
        furloughedChip: '#E91E69',
        retiredChip: '#01579B',
        notActivelyWorking: 'rgba(0, 0, 0, 0.87)',
        inProgressChip: 'rgba(230, 81, 0, 1)',
        enrollmentNotStartedChip: 'rgba(0, 0, 0, 0.87)',
        enrollmentSubmittedChip: 'rgba(27, 94, 32, 1)',
        enrollmentCancelledChip: 'rgba(198, 40, 40, 1)',
        paymentScheduleChip: '#01579B',
        refundChip: 'rgba(237, 108, 2, 1)',
        refundIcon: 'rgba(255, 152, 0, 1)',
        newPaymentSchedule: 'rgba(230, 81, 0, 1)',
        payChips: {
          settledChip: '#1B5E20',
          acceptedChip: '#1B5E20',
          errorChip: '#C62828',
          failedChip: '#C62828',
          originatedChip: '#7B1FA2',
          returnedChip: '#005FCC',
          refundChip: '#005FCC',
          voidChip: '#005FCC',
          disabledChip: '#D32F2F',
        },
        notification: {
          regular: '#013654',
          critical: 'rgba(198, 40, 40, 1)',
        },
        secureDataMsg: '#0335A5',
        grayInfoTip: 'rgba(0, 0, 0, 0.54)',
      },
      secondary: {
        main: '#5521c9',
        onMedicalLeaveBorder: 'rgba(0,0,0,0)',
        furloughedChipBorder: 'rgba(0,0,0,0)',
        payChips: {
          settledChip: '#1B5E20',
          acceptedChip: '#1B5E20',
          errorChip: '#C62828',
          failedChip: '#C62828',
          originatedChip: '#7B1FA2',
          returnedChip: '#005FCC',
          refundChip: '#005FCC',
          voidChip: '#005FCC',
          disabledChip: '#F443364D',
        },
        recommendationOutline: 'rgba(38, 166, 154, 1)',
      },
      text: {
        gray: '#49454F',
        lightGray: '#646464',
        Gray1: '#828282',
        Gray2: '#4F4F4F',
        Gray3: '#BDBDBD',
        recommendation: 'rgba(15, 66, 62, 1)',
        secondary: 'rgba(0, 0, 0, 0.6)',
        disabled: 'rgba(0, 0, 0, 0.48)',
        enrollmentState: {
          start: 'rgba(34, 13, 80, 1)',
          submitted: 'rgba(18, 50, 20, 1)',
          submittedCheckMark: 'rgba(27, 94, 32, 1)',
          inProgress: 'rgba(230, 81, 0, 1)',
          cancelled: 'rgba(198, 40, 40, 1)',
        },
      },
    },
    typography: {
      default: {
        fontFamily: 'Archivo',
        fontSize: '15px',
      },
      h1: {
        fontFamily: 'Poppins',
      },
      h2: {
        fontFamily: 'Poppins',
      },
      h3: {
        fontFamily: 'Poppins',
      },
      h4: {
        fontFamily: 'Poppins',
        fontWeight: 400,
        fontSize: '34px',
        lineHeight: '42px',
        letterSpacing: '0.25px',
      },
      h6: {
        fontFamily: 'Poppins',
        fontWeight: 500,
        fontSize: '20px!important',
        lineHeight: '32px',
        letterSpacing: '0.15px',
      },
      subtitle: {
        fontFamily: 'Poppins',
        lineHeight: '33px',
        fontSize: '25px',
        '@media (min-width:600px)': {
          fontSize: '30px',
        },
      },
      subtitle1: {
        fontFamily: 'Poppins',
        lineHeight: '33px',
        fontSize: '22px',
        '@media (min-width:600px)': {
          fontSize: '25px',
        },
      },
      subtitle2: {
        fontFamily: 'Poppins',
        lineHeight: '25px',
        fontSize: '20px',
        '@media (min-width:600px)': {
          fontSize: '20px',
        },
      },
      subtitle3: {
        fontFamily: 'Poppins',
        lineHeight: '25px',
        fontSize: '15px',
        '@media (min-width:600px)': {
          fontSize: '17px',
        },
        color: '#646464',
      },
      blueAccent: {
        fontFamily: 'Poppins',
        lineHeight: '45px',
        fontWeight: '700',
        color: '#195FFB',
        fontSize: '24px',
        '@media (min-width:600px)': {
          fontSize: '30px',
        },
      },
      blueAccentMed: {
        fontFamily: 'Archivo',
        lineHeight: '25px',
        fontWeight: '500',
        color: '#195FFB',
        fontSize: '25px',
        '@media (min-width:600px)': {
          fontSize: '25px',
        },
      },
      smallAccent: {
        fontFamily: 'Archivo',
        lineHeight: '25px',
        fontWeight: '800',
        color: '#000000',
        fontSize: '10px',
        '@media (min-width:600px)': {
          fontSize: '10px',
        },
      },
      overline: {
        fontFamily: 'Archivo',
        lineHeight: '25px',
        letterSpacing: '0',
        fontSize: '15px',
        '@media (min-width:600px)': {
          fontSize: '15px',
        },
      },
      body1: {
        fontFamily: 'Archivo',
      },
      body2: {
        fontFamily: 'Archivo',
        fontSize: '14px',
        '@media (min-width:600px)': {
          fontSize: '14px',
        },
        lineHeight: '25px',
      },
      body3: {
        fontFamily: 'Archivo',
        fontSize: '15px',
        lineHeight: '25px',
        opacity: '87%',
        fontWeight: '600',
      },
      button: {
        fontFamily: 'Archivo',
      },
    },
    shape: {
      borderRadius: 8,
    },
    components: {
      MuiButton: {
        variants: [
          {
            props: { variant: 'lightContained' },
            style: {
              backgroundColor: 'rgba(25, 95, 251, 0.04)',
              color: '#195FFB',
              borderRadius: '8px',
            },
          },
        ],
        styleOverrides: {
          root: {
            borderRadius: '8px',
            fontFamily: 'Archivo',
            textTransform: 'none',
            fontSize: '1rem',
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            fontFamily: 'Poppins',
            // textTransform: "none",
            fontSize: '1rem',
            alignItems: 'flex-end',
            '&.Mui-selected': {
              backgroundColor: '#195FFB10',
            },
          },
        },
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            textAlign: 'right',
          },
        },
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            colorPrimary: '#ffffff',
          },
        },
      },
      MuiDataGrid: {
        styleOverrides: {
          root: {
            // "& .MuiDataGrid-row.even": {
            //   backgroundColor: "#F9FAFB",
            // },
          },
        },
      },
    },
  });

  let darkTheme = createTheme({
    palette: {
      mode: 'dark',
      background: {
        default: '#1E1E1E',
        paper: '#393939',
        shadow: '#00000010',
        background: '#121212',
        border: 'rgba(81, 81, 81, 1)',
        dataGridEven: '#F9FaFB',
        another: '#F3F5F9',
        activeWorking: '#388e3c',
        onMedicalLeaveChip: 'rgba(2, 136, 209, 0.12)',
        onDisabilityChip: 'rgba(206, 147, 216, 1)',
        furloughedChip: 'rgba(206, 147, 216, 0.12)',
        retiredChip: 'rgba(144, 202, 249, 1)',
        notActivelyWorking: 'rgba(255, 255, 255, 0.16)',
        inProgressChip: 'rgba(255, 183, 77, 1)',
        enrollmentNotStartedChip: 'rgba(255, 255, 255, 0.16)',
        enrollmentSubmittedChip: 'rgba(102, 187, 106, 1)',
        enrollmentCancelledChip: 'rgba(229, 115, 115, 1)',
        paymentScheduleChip: '#E1F1F9',
        refundChip: 'rgba(255, 183, 77, 1)',
        newPaymentSchedule: 'rgba(255, 183, 77, 1)',
        notification: {
          regular: '#E5F3FA',
          critical: '#FBEAEA',
        },
        recommendation: '#E9F6F5',
        historyDateRow: 'rgba(245, 250, 253, 0.3)',
        snackbar: 'rgba(245, 250, 253, 0.3)',
        selectedBankAccount: 'rgba(246, 249, 255, 0.2)',
        enrollmentState: {
          start: '#A98FE3',
          submitted: '#388e3c',
          inProgress: 'rgba(255, 183, 77, 1)',
          cancelled: '#FBEAEA',
        },
        currentAcMessage: 'rgba(255, 255, 250, 0.4)',
      },
      primary: {
        main: '#8BAEFC',
        dark: '#5387FC',
        light: '#E8EFFF50',
        accent: '#42A5F5',
        activeChip: 'rgba(0,0,0,0.8)',
        onMedicalLeaveChip: 'rgba(41, 182, 246, 1)',
        onDisabilityChip: 'rgba(0, 0, 0, 0.87)',
        furloughedChip: '#CE93D8',
        retiredChip: 'rgba(0, 0, 0, 0.7)',
        notActivelyWorking: 'rgba(255, 255, 255, 1)',
        inProgressChip: 'rgba(0, 0, 0, 0.87)',
        enrollmentNotStartedChip: 'rgba(255, 255, 255, 1)',
        enrollmentSubmittedChip: 'rgba(0, 0, 0, 0.87)',
        enrollmentCancelledChip: 'rgba(0, 0, 0, 0.87)',
        paymentScheduleChip: '#01579B',
        refundChip: 'rgba(0, 0, 0, 0.87)',
        refundIcon: 'rgba(255, 152, 0, 1)',
        newPaymentSchedule: 'rgba(0, 0, 0, 0.87)',
        payChips: {
          settledChip: '#81C784',
          acceptedChip: '#81C784',
          errorChip: '#E57373',
          failedChip: '#E57373',
          originatedChip: '#A98FE3',
          returnedChip: '#29B6F6',
          refundChip: '#29B6F6',
          voidChip: '#29B6F6',
          disabledChip: '#000000DE',
        },
        notification: {
          regular: '#013654',
          critical: 'rgba(198, 40, 40, 1)',
        },
        pendingModifiedCoverage: 'rgba(255, 183, 77, 1)',
        grayInfoTip: '#C4C4C4',
      },
      secondary: {
        main: '#A98FE3',
        dark: '#7C54D6',
        light: '#EEE9FA40',
        onMedicalLeaveBorder: 'rgba(41, 182, 246, 1)',
        furloughedChipBorder: '#CE93D8',
        payChips: {
          settledChip: '#81C784',
          acceptedChip: '#81C784',
          errorChip: '#E57373',
          failedChip: '#E57373',
          originatedChip: '#A98FE3',
          returnedChip: '#29B6F6',
          refundChip: '#29B6F6',
          voidChip: '#29B6F6',
          disabledChip: '#E57373',
        },
        recommendationOutline: 'rgba(38, 166, 154, 1)',
      },
      text: {
        other: '#49454F',
        lightGray: '#646464',
        gray: '#C4C4C4',
        recommendation: 'rgba(15, 66, 62, 1)',
        secondary: '#C4C4C4',
        disabled: '#E8EFFF50',
        enrollmentState: {
          start: 'rgba(34, 13, 80, 1)',
          submitted: 'rgba(0,0,0,0.8)',
          submittedCheckMark: 'rgba(0,0,0,0.8)',
          inProgress: 'rgba(0, 0, 0, 0.87)',
          cancelled: 'rgba(198, 40, 40, 1)',
        },
      },
    },
    typography: {
      default: {
        fontFamily: 'Archivo',
      },
      h1: {
        fontFamily: 'Poppins',
      },
      h2: {
        fontFamily: 'Poppins',
      },
      h3: {
        fontFamily: 'Poppins',
      },
      h4: {
        fontFamily: 'Poppins',
        fontWeight: 400,
        fontSize: '34px',
        lineHeight: '42px',
        letterSpacing: '0.25px',
      },
      h6: {
        fontFamily: 'Poppins',
        fontWeight: 500,
        fontSize: '20px',
        lineHeight: '32px',
        letterSpacing: '0.15px',
      },
      subtitle: {
        fontFamily: 'Poppins',
        lineHeight: '33px',
        fontSize: '25px',
        '@media (min-width:600px)': {
          fontSize: '30px',
        },
      },
      subtitle1: {
        fontFamily: 'Poppins',
        lineHeight: '33px',
        fontSize: '22px',
        '@media (min-width:600px)': {
          fontSize: '25px',
        },
      },
      subtitle2: {
        fontFamily: 'Poppins',
        lineHeight: '25px',
        fontSize: '20px',
        '@media (min-width:600px)': {
          fontSize: '20px',
        },
      },
      subtitle3: {
        fontFamily: 'Poppins',
        lineHeight: '25px',
        fontSize: '15px',
        '@media (min-width:600px)': {
          fontSize: '17px',
        },
        color: '#646464',
      },
      blueAccent: {
        fontFamily: 'Poppins',
        lineHeight: '45px',
        fontWeight: '700',
        color: '#8BAEFC',
        fontSize: '24px',
        '@media (min-width:600px)': {
          fontSize: '30px',
        },
      },
      blueAccentMed: {
        fontFamily: 'Archivo',
        lineHeight: '25px',
        fontWeight: '500',
        color: '#8BAEFC',
        fontSize: '25px',
        '@media (min-width:600px)': {
          fontSize: '25px',
        },
      },
      smallAccent: {
        fontFamily: 'Archivo',
        lineHeight: '25px',
        fontWeight: '800',
        color: '#000000',
        fontSize: '10px',
        '@media (min-width:600px)': {
          fontSize: '10px',
        },
      },
      overline: {
        fontFamily: 'Archivo',
        lineHeight: '25px',
        letterSpacing: '0',
        fontSize: '15px',
        '@media (min-width:600px)': {
          fontSize: '15px',
        },
      },
      body1: {
        fontFamily: 'Archivo',
      },
      body2: {
        fontFamily: 'Archivo',
        fontSize: '14px',
        '@media (min-width:600px)': {
          fontSize: '14px',
        },
        fontWeight: '500',
        lineHeight: '25px',
      },
      body3: {
        fontFamily: 'Archivo',
        fontSize: '15px',
        lineHeight: '25px',
        opacity: '87%',
        fontWeight: '600',
      },
      button: {
        fontFamily: 'Archivo',
      },
    },
    shape: {
      borderRadius: 8,
    },
    components: {
      MuiButton: {
        variants: [
          {
            props: { variant: 'lightContained' },
            style: {
              backgroundColor: 'rgba(139, 174, 252, 0.16)',
              color: '#8BAEFC',
              borderRadius: '8px',
            },
          },
        ],
        styleOverrides: {
          root: {
            borderRadius: '8px',
            fontFamily: 'Archivo',
            textTransform: 'none',
            fontSize: '1rem',
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            fontFamily: 'Poppins',
            textTransform: 'none',
            fontSize: '1rem',
            alignItems: 'flex-end',
            '&.Mui-selected': {
              backgroundColor: '#195FFB10',
            },
          },
        },
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            textAlign: 'right',
          },
        },
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            colorPrimary: '#ffffff',
            borderRadius: '8px',
          },
        },
      },
      MuiDataGrid: {
        styleOverrides: {
          root: {
            // "& .MuiDataGrid-row.even": {
            //   backgroundColor: "#121212",
            // },
            // "& .MuiDataGrid-row.even:hover": {
            //   backgroundColor: "#1E1E1E",
            // },
          },
        },
      },
    },
  });

  // const notificationPopupStyles = {
  //   notificationBodyDisplay: {
  //     fontSize: "13px!important",
  //     marginRight: "8px",
  //   },
  // };

  const [colorMode, setColorMode] = useState('light');

  useEffect(() => {
    //   //Look for stored choice, then look for browser choice
    let storedTheme = localStorage.getItem('colorMode');
    if (storedTheme) {
      setColorMode(storedTheme);
    }
    // console.log(colorMode);
    // else {
    //     if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
    //       setColorMode('dark')
    //     } else {
    //       setColorMode('light')
    //     }
    //   }
  }, []);

  function ToggleColorMode() {
    // console.log("TOGGLING");
    if (colorMode === 'light') {
      localStorage.setItem('colorMode', 'dark');
      // document.body.appendChild(bgBodyColorStyleTag)
    } else {
      localStorage.setItem('colorMode', 'light');
      // for (let node of document.body.childNodes){
      //   if ( node.innerHTML == bgBodyColorStyleTag.innerHTML) document.body.removeChild(node)
      // }
    }
    setColorMode((prev) => (prev === 'light' ? 'dark' : 'light'));

    // theme.palette = colorScheme

    // console.log('CHANGED THEME?',theme)
  }

  let ColorModeContextProps = {
    colorMode: colorMode,
    // theme:theme,
    ToggleColorMode: ToggleColorMode,
  };

  // const [isAuthenticated,setIsAuthenticated] = useState()
  // const checkAuthentication = async () => {
  //   let isAuthenticated = await authService.isAuthenticated();

  //   setIsAuthenticated(isAuthenticated)
  // }
  // const [isInternal,setIsInternal] = useState()
  // const checkIsInternal = async () => {
  //   let _results = await accountService.getMe()
  //   console.log('isInternal',_results)
  //   if(_results.isInternal) setIsInternal(true)
  //   else setIsInternal(false)
  // }
  // useEffect(()=>{
  //   checkAuthentication()
  //   checkIsInternal()
  // },[])
  // useEffect(() => {
  //   if (isAuthenticated === false || isInternal === false) history.push('/login')
  // },[isAuthenticated,isInternal])

  return (
    <AgentColorModeContext.Provider value={ColorModeContextProps}>
      <ThemeProvider theme={colorMode === 'light' ? lightTheme : darkTheme}>
        {colorMode === 'dark' ? (
          <GlobalStyles
            styles={{
              body: { colorScheme: 'dark' },
            }}
          />
        ) : (
          <GlobalStyles
            styles={{
              body: { colorScheme: 'light' },
            }}
          />
        )}
        <Router History>
          <NavContainer>
            <Switch>
              <Route
                path="/tickets/:id"
                exact={true}
                render={() => (
                  <SingleviewTicket
                  // notificationTicketClicked={notificationTicketClicked}
                  />
                )}
              />
              <Route
                path="/tickets"
                exact={true}
                render={() => <TicketOverview />}
              />
              <Route
                path="/settings"
                exact={true}
                render={() => <SettingsRouter />}
              />
              <Route
                path="/members/:id"
                exact={true}
                render={(props) => <MemberProfile {...props} />}
              />
              <Route
                path="/members"
                exact={true}
                render={(props) => <AllMembersView {...props} />}
              />
              <Route path="/login" exact={true} render={() => <Login />} />
              <Route
                path="/messaging"
                exact={true}
                render={() => <Messaging />}
              />
              <Route
                path="/planmanagement/:id"
                exact={true}
                render={(props) => <SinglePlanManagement {...props} />}
              />
              <Route
                path="/planmanagement"
                exact={true}
                render={() => <PlanManagement />}
              />

              <Route path="/resetlink/:guid" render={() => <ResetPassword />} />
              {/* <Route path="/search" exact={true} render={() => <StandaloneSearch />} /> */}
              <Route
                path="/dashboard-reports"
                exact={true}
                render={(props) => <ReportingDashboard {...props} />}
              />
              <Route
                path="/"
                exact={true}
                render={(props) => <Dashboard {...props} />}
              />
              {/* {TopLevelRoutes.map(route =>
            <PrivateRoute key={route.path} path={route.path} exact={route.exact} component={route.component} isAuthenticated={isAuthenticated} />
          )}              */}
              <Route path="*" render={() => <NotFound></NotFound>} />
            </Switch>
          </NavContainer>
        </Router>
      </ThemeProvider>
    </AgentColorModeContext.Provider>
  );
}
// function About() {
//   return (
//     <div>
//       <h2>About</h2>
//     </div>
//   );
// }

// function Forgot() {
//   return (
//     <div>
//       <h2>Forgot</h2>

//     </div>
//   )
// }

// export function Dashboard() {
//   return (
//     <h2>Dashboard</h2>
//   )
// }

// export function Group() {
//   return (
//     <div>
//       <h2>Group</h2>
//     </div>
//   );
// }

// export function Enrollment() {
//   return (
//     <div>
//       <h2>Enrollment</h2>
//     </div>
//   );
// }

// export function Communication() {
//   return (
//     <div>
//       <h2>Communication</h2>
//     </div>
//   );
// }

// export function Analytics() {
//   return (
//     <div>
//       <h2>Analytics</h2>
//     </div>
//   );
// }
