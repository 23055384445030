import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

//Services
import userService from "../../../../services/user-service";
import permissionService from '../../../../services/permission-service'

// Assets

//MuiIcons

//MuiComponents
import Button from "@mui/material/Button";
import { InputLabel, TextField } from "@mui/material";
import { Select } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Avatar from '@mui/material/Avatar';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';


export default function SettingsProfile(props) {
   // let history = useHistory()
   // const [thisUser,setThisUser] = useState()
   //  let getThisUser = async () => {
   //      let _thisUser = await memberService.getMe()
   //      setThisUser(_thisUser)
   //      // console.log('thisUser ',_thisUser)
   //  }
   //  useEffect(() => {
   //      getThisUser()
   //  },[])
   // let thisUser = props.thisUser;
   const [disableSave, setDisableSave] = useState(true)
   const [userInfo, setUserInfo] = useState({
      firstName: "",
      lastName: "",
      email: "",
      id: 0,
      title: "",
      timezoneId: 0,
      roleId: 0,
   })
   const [editPassword, setEditPassword] = useState(false)
   const [passwordInfo, setPasswordInfo] = useState({
      oldPassword: '',
      newPassword: '',
      confirmNewPassword: ''
   })

   // Error / Success Messages
   const [message, setMessage] = useState({
   })

   useEffect(() => {
      const timeId = setTimeout(() => {
         // After 3 seconds set the show value to false
         setMessage({
            ...message,
            messageDisplay: false,
            autoHideDuration: "",
            severity: "",
            backgroundColor: "",
            message: ""
         })
      }, 5000)

      return () => {
         clearTimeout(timeId)
      }
   }, [message.messageDisplay])

   const handleMessageClose = (event, reason) => {
      if (reason === 'clickaway') {
         return;
      }
      setMessage({
         ...message,
         messageDisplay: false,
         autoHideDuration: "",
         severity: "",
         backgroundColor: "",
         message: ""
      })
   };


   useEffect(() => {
      if (props.thisUser) {
         let _userInfo = {
            firstName: props.thisUser.firstName,
            lastName: props.thisUser.lastName,
            email: props.thisUser.email,
            id: props.thisUser.id,
            memberId: props.thisUser.memberId,
            title: props.thisUser.title,
            timezoneId: 2,
            roleId: 1,
         }

         setUserInfo(_userInfo)
         setImage(userInfo.id)
      }
   }, [props.thisUser])

   useEffect(() => {
      if (editPassword) {
         setDisableSave(false)
      }
   }, [editPassword])


   let handleInfoChange = (e, property) => {
      let value = e.target.value
      setUserInfo({
         ...userInfo,
         [property]: value
      })
      setDisableSave(false)
   }

   let handlePasswordInfoChange = (e, property) => {
      let value = e.target.value
      setPasswordInfo({
         ...passwordInfo,
         [property]: value
      })
      setDisableSave(false)
   }
   const passwordKeyPressHandler = (e) => {
      // if (e.key === 'Enter') loginAsync()
      if (e.key === ' ') e.preventDefault()
  }

   // console.log(props.roles && props.roles?.find(x => x.roleId == userInfo?.roleId)?.roleId)


   //PROFILE PIC LOGIC
   let getFileAsBase64 = (file, cb) => {
      let reader = new FileReader()
      //reader is async, so we have to handle promise below
      reader.readAsDataURL(file)

      //callback to use result elsewhere
      reader.onload = function() {
         cb(reader.result)
      }
      reader.onerror = function(err) {
         console.log(err)
      }
   }
   
   let handlePictureDrop = (e) => {
      //default behavior is open the file, prevent it
      e.preventDefault()

      //obj expected by service
      let newPicObj = {
         image: ''
      }
      
      if(e.dataTransfer.files){
         for (let file of e.dataTransfer.files) {

            getFileAsBase64(file, (result)=>{
               //just the string, not the encoding
               newPicObj.image = result.replace(`data:${file.type};base64,`,'')
               try {
                  userService.updateUserImage(userInfo.id, newPicObj)}
                  
               catch {
               }
               console.log(userInfo.id)
            })
            setImage(userInfo.id)

         }
      }
   }

   const [image, setImage] = useState()


   let handleUploadClick = (e) => {
      // e.preventDefault()
      
      //obj expected by service
      let newPicObj = {
         image: ''
      }
      
      if(e.target.files){
         for (let file of e.target.files) {

            getFileAsBase64(file, (result)=>{
               //just the string, not the encoding
               newPicObj.image = result.replace(`data:${file.type};base64,`,'')
               userService.updateUserImage(userInfo.id, newPicObj)
               setImage(new Date())
               setMessage({
                  ...message,
                  messageDisplay: true,
                  autoHideDuration: "1600",
                  severity: "success",
                  backgroundColor: "",
                  message: "Successfully updated picture"
               })
            })

         }
      }
   }

   

   let handleSave = async(e) => {
      //HANDLE PASSWORD SAVE
      if(editPassword){
         if(passwordInfo?.newPassword == passwordInfo?.confirmNewPassword && passwordInfo?.newPassword !== passwordInfo?.oldPassword){
            try {
               let result = await userService.updatePassword(passwordInfo?.newPassword, passwordInfo?.oldPassword)
               setMessage({
               ...message,
               messageDisplay: true,
               autoHideDuration: 1500,
               backgroundColor: '#195FFB',
               severity: "success",
               message: "Password Successfully Changed"
            })
            setEditPassword(false)
            setPasswordInfo({oldPassword: '', newPassword: '', confirmNewPassword: ''})
            }  catch (err) {
               setMessage({
                  ...message,
                  messageDisplay: true,
                  autoHideDuration: 1500,
                  backgroundColor: '',
                  severity: "error",
                  message: err.response.data.message
               })
            }
         } else {
            if(passwordInfo?.newPassword !== passwordInfo?.confirmNewPassword){
               setMessage({
               ...message,
               messageDisplay: true,
               autoHideDuration: 1500,
               backgroundColor: '',
               severity: "error",
               message: "New password values must match"
            })
            }
            if(passwordInfo?.newPassword == passwordInfo?.oldPassword){
               setMessage({
                  ...message,
                  messageDisplay: true,
                  autoHideDuration: 1500,
                  backgroundColor: '',
                  severity: "error",
                  message: "New password must be different from your old password"
               })
            }
         }
      }

       //Handle info save
      try {
         await userService.updateMe(userInfo)
         
      } catch (err) {
         console.log(err)
         setMessage({
            ...message,
            messageDisplay: true,
            autoHideDuration: 1500,
            backgroundColor: '',
            severity: "error",
            message: err.response.data.message
         }) 
      }
      setDisableSave(true)
      setMessage({
         ...message,
         messageDisplay: true,
         autoHideDuration: 1500,
         backgroundColor: '',
         severity: "success",
         message: "Successfully Updated"
      }) 
      props.refreshUser()

   }
   

   
      


   return (
      <>
         {/* ACTUAL CONTENT */}

         <div className="container" style={{ height: 'calc(100vh - 222px)', backgroundColor: "FFFFFF", margin: 20, fontFamily: 'Archivo' }}>
            <div className="row" style={{ height: 'auto' }}>
               <div className='col-md-12' style={{ marginBottom: "40px" }}>
                  <h5>Personal Information</h5>
                  <p>Update your personal details and photo here.</p>
               </div>

               <div className={`col-md-3`}>
                  <h6>Name</h6>

               </div>
               <div className={`col-md-9`}>
                  <TextField variant='outlined' label='First Name' size='small' required
                     value={userInfo?.firstName}
                     error={userInfo?.firstName === ""}
                     sx={{ marginBottom: '40px', marginRight: '10px' }}
                     onChange={e => handleInfoChange(e, 'firstName')}

                  />
                  <TextField variant='outlined' label='Last Name' size='small' required
                     value={userInfo?.lastName}
                     error={userInfo?.lastName === ""}
                     sx={{ marginBottom: '20px' }}
                     onChange={e => handleInfoChange(e, 'lastName')}

                  />

               </div>

               <div className={`col-md-3`}>
                  <h6>Email Address</h6>
               </div>
               <div className={`col-md-9`}>
                  <TextField variant='outlined' label='Email' size='small' required
                     value={userInfo?.email}
                     error={userInfo?.email === ""}
                     sx={{ marginBottom: '40px' }}
                     onChange={e => handleInfoChange(e, 'email')}

                  />
               </div>
               <div className={`col-md-3`}>
                  <h6>Your Photo</h6>
               </div>
               <div className={`col-md-9 flex-row`} style={{ margin: "auto" }}>

                 
                  <Avatar sx={{ bgcolor: "#195FFB17", color: "#195FFB", fontFamily: "Archivo", fontWeight: "600", width: 100, height: 100, fontSize: 40, marginRight: '20px' }} src={`api/image/user/${userInfo.id}?${image}`}>
                     {props.thisUser?.firstName && `${props.thisUser?.firstName[0]?.toUpperCase()}${props.thisUser?.lastName[0]?.toUpperCase()}`}
                  </Avatar>

                  {/* UPLOAD AREA */}

                  <div style={{ width: "300px", height: "auto", padding: "30px", border: "1px solid #D9D9D9", borderRadius: "33px", textAlign: "center", marginBottom: '40px' }} 
                  //Need to prevent the browsers handling of ondragover to get the ondrop
                  onDragOver={e=>e.preventDefault()} 
                  onDrop={handlePictureDrop}>
                     <span style={{ fontSize: "16px" }}>
                        <Button style={{ color: "primary.main", fontWeight: "600", textDecoration: "none", padding:"0" }} component='label' >
                           Click to upload
                           <input hidden accept='image/svg,image/png,image/jpeg,image/gif' type='file' onChange={handleUploadClick}/>
                        </Button> or drag and drop
                     </span>
                     <br />
                     <span style={{ fontSize: "14px" }}>SVG, PNG, JPG, or GIF (max.800x400px)</span>
                  </div>
               </div>
               <div className={`col-md-3`}>
                  <h6>Job Title</h6>
               </div>
               <div className={`col-md-9`}>
                  <TextField variant='outlined' label='Job Title' size='small'
                     value={userInfo?.title}
                     sx={{ marginBottom: '40px' }}
                     onChange={e => handleInfoChange(e, 'title')}
                  />
               </div>

               {/* Hide Timezone */}
               {/* <div className={`col-md-3`}>
                  <h6>Timezone</h6>
               </div>
               <div className={`col-md-9`}>
                  <FormControl sx={{ width: "300px", marginBottom: "20px" }}>
                     <InputLabel>Timezone</InputLabel>
                     <Select variant='outlined' size='large' fullWidth
                        value={userInfo?.timezoneId}
                        label="Timezone"
                        onChange={e => handleInfoChange(e, 'timezoneId')}
                     >

                        <MenuItem value={1}>Pacific Time (PT) UTC-08:00</MenuItem>
                        <MenuItem value={2}>Mountain Time (MT) UTC-07:00</MenuItem>
                        <MenuItem value={3}>Central Time (CT) UTC-06:00</MenuItem>
                        <MenuItem value={4}>Eastern Time (ET) UTC-05:00</MenuItem>
                     </Select>
                  </FormControl>
               </div> */}
               <div className={`col-md-3`}>
                  <h6>Role</h6>
               </div>
               <div className={`col-md-9`}>
                  <FormControl sx={{ width: "300px", marginBottom: "20px" }}>
                     <InputLabel>Role</InputLabel>
                     <Select variant='outlined' size='large' fullWidth disabled={true}
                        value={userInfo?.roleId && props?.roles && props.roles?.find(x => x.roleId == userInfo?.roleId)?.roleId}
                        label="Role"
                        onChange={e => handleInfoChange(e, 'roleId')}
                     >
                        {props?.roles && props.roles?.map(role => {
                           return <MenuItem value={role.roleId}>
                              <span style={{ fontFamily: "Archivo", paddingLeft: '10px', margin: 'auto 0px' }}>{role.roleName}</span>
                           </MenuItem>
                        })}
                     </Select>
                  </FormControl>
               </div>

               {/* <div className={`col-md-3`}>
                  <h6>Email Signature</h6>
               </div>
               <div className={`col-md-9`}>
                  <TextField variant='outlined' label='Email Signature' size='small'
                     multiline
                     rows={4}
                     value={userInfo?.emailSignature}
                     sx={{ marginBottom: '40px' }}
                     onChange={e => handleInfoChange(e, 'emailSignature')}
                     InputProps={{
                        sx: {
                           '&.MuiOutlinedInput-root.Mui-focused > fieldset': { border: '2px solid #195FFB' },
                        },
                     }}
                  />
               </div> */}
               <div className={`col-md-3`}>
                  <h6>Password</h6>
               </div>
               <div className={`col-md-9`}>
                  {editPassword &&
                     <>
                        <TextField variant='outlined' label='Old Password' size='small' type='password'

                           value={passwordInfo?.oldPassword}
                           sx={{ marginBottom: '40px' }}
                           onChange={e => handlePasswordInfoChange(e, 'oldPassword')}

                        />
                        < br />
                        <TextField variant='outlined' label='New Password' size='small' type='password'

                           value={passwordInfo?.newPassword}
                           sx={{ marginBottom: '40px' }}
                           onChange={e => handlePasswordInfoChange(e, 'newPassword')}
                           onKeyDown={passwordKeyPressHandler}

                        />
                        < br />
                        <TextField variant='outlined' label='Confirm New Password' size='small' type='password'

                           value={passwordInfo?.confirmNewPassword}
                           sx={{ marginBottom: '40px' }}
                           onChange={e => handlePasswordInfoChange(e, 'confirmNewPassword')}
                           onKeyDown={passwordKeyPressHandler}

                        />
                     </>
                  }
                  {!editPassword &&

                     <>
                        <Button fullWidth size='small' variant='outlined'
                           onClick={() => setEditPassword(true)}
                           style={{ fontSize: 16, fontWeight: 400, padding: 20, height: '20px', width: 'auto', borderColor: 'primary.main', borderRadius: '33px', marginBottom: '10px', width:'auto' }}
                        >
                           Edit Password
                        </Button>
                     </>}


               </div>
               <div style={{ position: "fixed", bottom: 40, right: 200, textAlign: "right", width:"auto" }}>
                  <Button fullWidth size='small' variant='contained' disabled={disableSave}
                     onClick={() => handleSave()}
                     style={{ fontSize: 16, fontWeight: 400, padding: 20, height: '20px', width: 'auto', }}
                  >
                     Save Changes
                  </Button>
               </div>
            </div>
            <Snackbar open={message.messageDisplay} autoHideDuration={message.autoHideDuration} onClose={handleMessageClose} sx={{ opacity: .8 }}>
               <MuiAlert
                  elevation={6} variant="filled"
                  // onClose={handleClose} 
                  severity={message.severity} sx={{ width: '100%', backgroundColor: message.backgroundColor }}
               >
                  {message.message}
               </MuiAlert>
            </Snackbar>
         </div>


      </>
   )
}