import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";


//MuiIcons
import VisibilityIcon from '@mui/icons-material/Visibility';
import NoVisibilityIcon from '@mui/icons-material/VisibilityOff';

//Services
import authService from '../../../services/auth-service';
// import memberService from "../../../services/member-service";
import registerService from '../../../services/register-service';

//MuiComponents
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Alert from '@mui/material/Alert';
import CircularProgress from "@mui/material/CircularProgress";
// import InputAdornment from '@mui/material/InputAdornment';
// import IconButton from '@mui/material/IconButton';

//Assets 
import fullColorLogo from '../../../assets/images/FullColorOnlyLogo.png'
import fullColorLogoDarkMode from '../../../assets/images/FullColorOnlyLogoDarkMode.png'
import { Box } from "@mui/system";
import { Typography, useTheme } from "@mui/material";


export default function Login(props) {


   const history = useHistory();

   // Username/Password Input
   const [values, setValues] = useState({
      userName: '',
      password: ''
   });

   //Forgot password
   const [forgotPassword, setForgotPassword] = useState(false)
   const [forgotEmailSent, setforgotEmailSent] = useState(false)

   //Authentication Error
   const [authError, setAuthError] = useState(false)

   const forgetClick = () => {
      setForgotPassword(prev => !prev)
   }

   // Forgot Email
   const [forgotState, setForgotState] = useState({
      verificationCode: (Math.round(Math.random(0) * 1000) + 1000).toString(),
      emailSent: false,
   })

   // New Password
   const [credentialState, setCredentialState] = useState({
      email: "",
      code: "",
      newPassword: "",
   })



   //Password Visibility
   const [displayPassword, setDisplayPassword] = useState(false)

   const swapIcon = (e) => {
      //e.preventDefault();
      let inputElement = e.target.parentNode.children[0];
      if (displayPassword) {
         inputElement.setAttribute("type", "password");
         setDisplayPassword(false);
      } else {
         inputElement.setAttribute("type", "text");
         setDisplayPassword(true);
      }
   }

   // Password Icon
   const visibilityIcon = <div style={{ position: "absolute", right: "5%" }} type="button" onMouseDown={swapIcon} onMouseUp={swapIcon} onTouchStart={swapIcon} onTouchEnd={swapIcon}><VisibilityIcon sx={{ color: "#7D7987", cursor: "pointer" }} /></div>
   const noVisibilityIcon = <div style={{ position: "absolute", right: "5%" }} type="button" onClick={swapIcon}><NoVisibilityIcon sx={{ color: "#7D7987", cursor: "pointer" }} /></div>
   const passwordIcon = displayPassword ? noVisibilityIcon : visibilityIcon;


   //Handlers
   const handlePasswordChange = (event) => {
      event.persist();
      setValues((values) => ({
         ...values,
         password: event.target.value,
      }));
   };

   const handleEmailChange = (event) => {
      event.persist();
      setValues((values) => ({
         ...values,
         userName: event.target.value,
      }));
   };

   const handleForgotEmail = async () => {
      setforgotEmailSent(true);

      setForgotState({
         ...forgotState,
         emailSent: true,
      })

      setCredentialState({
         ...credentialState,
         email: values.userName,
         code: forgotState.verificationCode,
      })
      await registerService.forgotPassword(values.userName, forgotState.verificationCode);

   }

   const handleBackToLogIn = (event) => {
      setForgotPassword(false)
      setforgotEmailSent(false)
   }

   const handleOpenEmailApplication = (event) => {
      window.open('mailto:')

   }

   const handleAuthError = () => {
      const timeId = setTimeout(() => {
         // After 5 seconds set the show value to false
         setAuthError(false)
      }, 5000)

      return () => {
         clearTimeout(timeId)
      }
   }

   const handleKeypress = (e) => {
      //it triggers by pressing the enter key
      if (e.key === "Enter") {
         loginAsync();
      }
   };

   const [loading, setLoading] = useState(false)
   // Login Function
   const loginAsync = async (e) => {
      //e.preventDefault();
      setLoading(true)

      let isAuthenticated = false;
      const credentials = {
         username: values.userName,
         password: values.password
      }
      isAuthenticated = await authService.authenticate(credentials);
      // props.checkAuthentication()
      console.log('isAuthenticated', isAuthenticated)

      if (isAuthenticated) {

         // let domain = getCookie('subdomain')
         console.log(window.matchMedia('(prefers-color-scheme: dark)'));
         //console.log(authService.getDomain());
         //alert(authService.getDomain());
         let domain = 'https://' + authService.getDomain();
         console.log(domain);
         window.location.href = domain;
         //if (process.env.NODE_ENV === 'development') {
         //   window.location.href = 'https://cornerstone.uh.com:44456'
         //} else {
         //    // WE NEED TO FIX THIS!
         //   window.location.href = authService.getDomain(); //'https://cornerstone.unionhub-uat.com'
         //}
      }
      else {
         console.log('not successful');
         setAuthError(true);
         handleAuthError();
      }
      setLoading(false)
   }
   let theme = useTheme()




   return (
      <>
         <Box sx={{
            bgcolor: 'background.default',
            color: 'text.primary'
         }} className="flex-row" style={{ height: "100vh" }}>
            <div style={{ margin: "auto", textAlign: "center" }}>
               <img src={theme?.palette?.mode == 'light' ? fullColorLogo : fullColorLogoDarkMode} style={{ maxWidth: "250px" }} /><br />
               {!forgotPassword &&
                  <>
                     <Typography variant="subtitle" style={{ marginBottom: '20px' }}>Log in to your account</Typography>
                     <Typography variant="body1" style={{ marginBottom: '20px' }}>Welcome back! Please enter your details.</Typography>
                     <TextField
                        sx={{ borderRadius: "8px", paddingBottom: "20px" }}
                        onChange={handleEmailChange}
                        value={values.userName}
                        // id="outlined-basic"
                        label="Email"
                        variant="outlined"
                        placeholder="Enter your email"
                        type="email"
                        InputProps={{
                           disableUnderline: true,
                           sx: { borderRadius: "8px" }
                        }}

                        fullWidth
                     />

                     <br />

                     <TextField
                        sx={{ borderRadius: "8px", paddingBottom: "20px" }}
                        value={values.password}
                        onChange={handlePasswordChange}
                        // id="outlined-basic"
                        label="Password"
                        variant="outlined"
                        placeholder="Enter your password"
                        type="password"
                        onKeyPress={handleKeypress}
                        InputProps={{
                           disableUnderline: true,
                           sx: { borderRadius: "8px", paddingRight: 0 },
                           endAdornment: passwordIcon
                        }}
                        fullWidth
                     />
                     <Button
                        onClick={loginAsync}
                        sx={{ color: '195FFB', float: 'right', borderRadius: '8px', height: '58px' }}
                        variant='contained' disabled={loading}
                        fullWidth>
                        {loading && <CircularProgress sx={{ color: 'white', height: '28px', width: '28px' }} />}
                        {!loading && <span style={{ fontSize: 16, fontWeight: 400 }}>Login</span>}
                     </Button>
                     <p role="button" style={{ textAlign: "right" }} onClick={forgetClick} >Forgot your password?</p>
                     {authError &&
                        <Alert severity="error">Incorrect email or password</Alert>
                     }

                  </>}
               {forgotPassword && !forgotEmailSent &&
                  <>
                     <h3>Forgot your password?</h3>
                     <p>No worries, we'll send you reset instructions.</p>

                     <TextField
                        sx={{ borderRadius: "100px", paddingBottom: "20px" }}
                        onChange={handleEmailChange}
                        value={values.userName}
                        // id="outlined-basic"
                        label="Email"
                        variant="outlined"
                        placeholder="Enter your email"
                        type="email"
                        InputProps={{
                           disableUnderline: true,
                        }}

                        fullWidth
                     />

                     <br />
                     <Button
                        onClick={handleForgotEmail}
                        sx={{ color: '195FFB', float: 'right', borderRadius: '33px' }}
                        variant='contained'
                        fullWidth>
                        <span style={{ fontSize: 16, fontWeight: 400 }}>Reset Password</span>
                     </Button>
                     <p role="button" style={{ textAlign: "right" }} onClick={forgetClick} >Back to log in</p>
                  </>}

               {forgotPassword && forgotEmailSent &&
                  <>
                     <h3>Check your email</h3>
                     <p>We sent a password reset link to {values.userName} </p>

                     <br />
                     <Button
                        onClick={handleOpenEmailApplication}
                        sx={{ color: '195FFB', float: 'right', borderRadius: '33px' }}
                        variant='contained'
                        fullWidth>
                        <span style={{ fontSize: 16, fontWeight: 400 }}>Open Email Application</span>
                     </Button>
                     <Button
                        onClick={handleBackToLogIn}
                        sx={{ marginTop: '20px', color: '195FFB', float: 'right', borderRadius: '33px' }}
                        variant='outlined'
                        fullWidth>
                        <span style={{ fontSize: 16, fontWeight: 400 }}>Back to Log In</span>
                     </Button>
                  </>}
            </div>
         </Box>

      </>
   )
}