import axios from "axios";
import Settings from "../settings";
import authService, { authHeader } from "./auth-service";
import { baseService } from "./baseService";

class internalTicketService extends baseService {
    getTicketById(ticketId) {
        const url = Settings.API_URL + "ticket/" + ticketId;
        return this.get(url)
    }

    // This will also send the communication
    insertTicketEntry(ticketId, ticketEntry) {
        const url = Settings.API_URL + "ticket/" + ticketId + "/entry";
        return this.post(url, ticketEntry);
    }

    insertTicket(ticket) {
        const url = Settings.API_URL + "ticket";
        return this.post(url, ticket);
    }

    assignTicket(ticketId, userId) {
        const url = Settings.API_URL + "ticket/" + ticketId + "/assign/" + userId;
        return this.put(url);
    }

    updateOrigin(ticketId, originId) {
        const url = Settings.API_URL + "ticket/" + ticketId + "/origin/" + originId;
        return this.put(url);
    }

    updatePriority(ticketId, priorityId) {
        const url = Settings.API_URL + "ticket/" + ticketId + "/priority/" + priorityId;
        return this.put(url);
    }

    updateSubject(ticketId, subject) {
        const url = Settings.API_URL + "ticket/" + ticketId + "/updatesubject/" + subject;
        return this.put(url);
    }

    updateType(ticketId, typeId) {
        const url = Settings.API_URL + "ticket/" + ticketId + "/tickettype/" + typeId;
        return this.put(url);
    }

    updateDueDate(ticket) {
        const url = Settings.API_URL + "ticket/due";
        return this.put(url, ticket);
    }

    getAllTickets() {
        const url = Settings.API_URL + "ticket";
        return this.get(url);
    }

    getOpenTicketsByMemberId(memberId) {
        const url = Settings.API_URL + "ticket/member/open/" + memberId;
        return this.get(url);
    }
    getTicketsByMemberId(memberId) {
        const url = Settings.API_URL + "ticket/member/" + memberId;
        return this.get(url);
    }

    getOpenTickets() {
        const url = Settings.API_URL + "ticket/open";
        return this.get(url);
    }

    getUnassignedTickets() {
        const url = Settings.API_URL + "ticket/unassigned";
        return this.get(url);
    }
    closeTicket(ticketId, details) {
        const url = Settings.API_URL + "ticket/close/" + ticketId;
        console.log("test:" + ticketId)
        var putObject = {
            details: details
        }
        return this.put(url, putObject);
    }
    reopenTicket(ticketId) {
        const url = Settings.API_URL + "ticket/reopen/" + ticketId;
        return this.put(url);
    }
    updateTicket(ticket) {
        const url = Settings.API_URL + "ticket";
        return this.put(url, ticket);
    }
    //createTicketNote(memberId, note) {
    //    const url = Settings.API_URL + `ticket/createnote/${memberId}`
    //    var postNote = {
    //        note: note
    //    }
    //    return this.post(url, postNote);
    //}
    //getMemberNotesByMemberId(memberId) {
    //    const url = Settings.API_URL + `ticket/note/${memberId}`
    //    return this.get(url)
    //}
    updateTicketNote(ticketEntryVMAPI) {
        const url = Settings.API_URL + `ticket/note`
        return this.put(url, ticketEntryVMAPI)
    }
    deleteTicketNote(ticketNoteEntryId) {
        const url = Settings.API_URL + `ticket/note/${ticketNoteEntryId}`
        return this.delete(url)
    }
    /*
     * {
    "enrollmentId": "123456",
    "dob": "2012-04-23T18:25:43.511Z"
}
     */
}

const ticketService = new internalTicketService()

export default ticketService
//const memberService = new internalMemberService();

//const memberService = {
//    async getMembersAsync(){
//        let returnValue;
//        try{
//            console.log('Get Members Async');
//            const result = await axios.get(Settings.API_URL + "member", {headers: authHeader()} )
//            returnValue = await result.data;
//            console.log('Get Members:' + returnValue);
//            return returnValue;
//        }
//        catch(err){

//        }
//        return returnValue;
//    },
//    async getMemberByIdAsync(memberId){
//        let returnValue;
//        try {
//            console.log('Get Member Async');
//            const result = await axios.get(Settings.API_URL + "member" + "/" + memberId, { headers: authHeader() });
//            returnValue = await result.data;
//            console.log('Get Member:' + returnValue);
//            return returnValue;
//        }
//        catch(err){

//        }
//        return returnValue;
//    }
//}

//export default memberService;