import React, { useEffect } from "react"

//MUI components
import Snackbar from "@mui/material/Snackbar"
import Button from "@mui/material/Button"

//MUI icons
import IconButton from "@mui/material/IconButton"
import CloseIcon from "@mui/icons-material/Close"

//component styles
const componentStyles = {
  undoButton: { color: "primary.main", fontSize: "14px!important" },
  closeSnackbarButton: { width: "30px" },
  closeIcon: { color: "text.primary", width: "20px" },
}

export default function UndoActionSnackbar(props) {
  //PROPS
  const {
    undoSnackbarInfo,
    setUndoSnackbarInfo,
    clearUndoSnackbar,
    getBankAccount,
  } = props

  useEffect(() => {
    const timer = setTimeout(() => {
      //check if undo has been clicked, if not proceed with actionFunction
      if (undoSnackbarInfo?.confirmAction) {
        undoSnackbarInfo?.actionFunction()
      }
      //reset snackbar
      clearUndoSnackbar()
    }, 5000)

    return () => clearTimeout(timer)
  }, [undoSnackbarInfo?.action])

  //Handle Undo Click
  const handleUndoClick = () => {
    console.log("undo clicked")
    //reset snackbar
    clearUndoSnackbar()
    //refresh bankAccounts to update local states that were modified with 'soft' action
    getBankAccount()
  }

  //Close Snackbar
  const closeSnackbar = () => {
    //run confirm action sooner if snackbar is closed
    undoSnackbarInfo?.actionFunction()
    //reset snackbar
    clearUndoSnackbar()
  }

  return (
    <Snackbar
      open={undoSnackbarInfo?.message}
      message={undoSnackbarInfo?.message}
      action={
        // undoSnackbarInfo?.action
        true ? (
          <>
            <Button onClick={handleUndoClick} sx={componentStyles.undoButton}>
              UNDO
            </Button>

            <IconButton
              onClick={closeSnackbar}
              sx={componentStyles.closeSnackbarButton}
            >
              <CloseIcon sx={componentStyles.closeIcon} />
            </IconButton>
          </>
        ) : null
      }
      sx={{
        ".MuiSnackbar-root": { borderRadius: "4px" },
        ".MuiSnackbarContent-root": {
          minWidth: "390px",
          backgroundColor: "background.snackbar",
          color: "text.primary",
          fontSize: "14px!important",
        },
        bottom: { sm: 59 },
        right: { xs: 65 },
      }}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
    />
  )
}
