import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { format, formatDistance, subMinutes } from "date-fns";


//services
import ticketService from '../../../../../../services/ticket-service';
import optionService from '../../../../../../services/optionService';
import userService from '../../../../../../services/user-service';
import registerService from '../../../../../../services/register-service'


//Mui Components
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import { Alert, Paper, Typography, Switch } from '@mui/material';
import { Box } from '@mui/system';


// Assets
import openSvg from '../../../../../../assets/images/openSvg.svg'
import closedSvg from '../../../../../../assets/images/closedSvg.svg'

//Mui icons
import ManageSearchIcon from '@mui/icons-material/ManageSearch';

//Our Components
import { thisUserContext } from '../../../../nav-container/NavContainer';
import MemberMergeTool from '../../../../admin-tool-components/MemberMergeTool';

export default function MemberSettingsTab(props) {

   const [forgotState, setForgotState] = useState({
      verificationCode: (Math.round(Math.random(0) * 1000) + 1000).toString(),
      emailSent: false,
      email: props?.member?.email
   })

   const sendResetLink = async () => {
      setForgotState({
         ...forgotState,
         emailSent: true,
      })


      props.setMessage({
         ...props.message,
         messageDisplay: true,
         autoHideDuration: 1500,
         backgroundColor: '',
         severity: "success",
         message: "Successfully Sent Email"
      })
      await registerService.forgotPasswordByMemberId(props.member.id);
   }

   const [emailEnabled, setEmailEnabled] = useState(props?.member?.allowEmailContact)
   const [smsEnabled, setSmsEnabled] = useState(props?.member?.allowSMSContact)

   const handleCommunicationSettingsChange = (type) => {
      if (type == 'email') {
         setEmailEnabled(prev => !prev)
         props.setMessage({
            ...props.message,
            messageDisplay: true,
            autoHideDuration: 1500,
            backgroundColor: '',
            severity: "success",
            message: "Successfully updated preferences"
         })
      }
      if (type == 'sms') {
         setSmsEnabled(prev => !prev)
         props.setMessage({
            ...props.message,
            messageDisplay: true,
            autoHideDuration: 1500,
            backgroundColor: '',
            severity: "success",
            message: "Successfully updated preferences"
         })
      }
   }

   //update db with new preferences
   useEffect(()=> {
      //check for changes to member preferences before updating in db
   let memberInfoChanged = props?.member?.allowEmailContact !== emailEnabled || props?.member?.allowSMSContact !== smsEnabled
    if (memberInfoChanged)
      { props?.updateMember({
         ...props?.member,
         allowEmailContact: emailEnabled,
         allowSMSContact: smsEnabled
      })}
   },[emailEnabled, smsEnabled])
   

   return (
      <Box sx={{
         bgcolor: 'background.background',
         color: 'text.primary',
      }} style={{ padding: '20px', height: 'calc(100vh - 104px)' }}>
         <div className='col' style={{ paddingLeft: '20px', justifyContent: 'flex-start', paddingBottom: '20px' }}>
            <span style={{ fontSize: '32px', fontWeight: 500, fontFamily: 'Poppins' }}>Settings</span>
         </div>

         <Paper elevation={0} sx={{
            height: '100% ', border: '1px solid',
            borderColor: 'background.border', backgroundColor: 'background.default', height: 'auto', padding: '20px', borderRadius: '15px', marginBottom: '20px'
         }}>

            <Typography variant='h5'>Password Settings</Typography>
            <Button variant="contained" sx={{ height: 50, width: 300, marginTop: '15px' }} onClick={sendResetLink}>Send Password Reset Email</Button>

         </Paper>

         <Paper elevation={0} sx={{
            height: '100% ', border: '1px solid',
            borderColor: 'background.border', backgroundColor: 'background.default', height: 'auto', padding: '20px', borderRadius: '15px', marginBottom: '20px'
         }}>

            <Typography variant='h5'>Communication Settings</Typography>
            <Typography variant='body2' sx={{marginBottom:'20px'}}>Manage what kind of communications this user can recieve.</Typography>
            <Box sx={{ display: 'flex', flexDirection: 'row', marginBottom:'10px'}}>
               <Box sx={{ flexDirection: 'column' }}>
                  <Switch
                     checked={emailEnabled}
                     onChange={() => handleCommunicationSettingsChange('email')}
                     inputProps={{ 'aria-label': 'Email Opt Status' }}
                  />
               </Box>
               <Box sx={{ flexDirection: 'column' }}>
                  <Typography variant='body1'>Allow Email Communications</Typography>
                  <Typography variant='body2'>By opting out of email communications, this user will not receive marketing campaigns or in-progress communications for outstanding applications.  Users cannot opt out of transactional emails, such as enrollment submissions, payment receipts, or payment failure communications.</Typography>
               </Box>
            </Box>

            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
               <Box sx={{ flexDirection: 'column' }}>
                  <Switch
                     checked={smsEnabled}
                     onChange={() => handleCommunicationSettingsChange('sms')}
                     inputProps={{ 'aria-label': 'SMS Opt Status' }}
                  />
               </Box>
               <Box sx={{ flexDirection: 'column' }}>
                  <Typography variant='body1'>Allow SMS Communications</Typography>

                  <Typography variant='body2'>By opting out of SMS communications, this user will not receive any SMS campaigns for marketing or transactional purposes.</Typography>
               </Box>
            </Box>
         </Paper >

         {/* <Paper elevation={0} sx={{
            height: '100% ', border: '1px solid',
            borderColor: 'background.border', backgroundColor: 'background.default', height: 'auto', padding: '20px', borderRadius:'15px'
         }}>

            <Typography>Data Management</Typography>
            
            <MemberMergeTool member={props.member} setMessage={props.setMessage} />

         </Paper> */}
      </Box >
   )
}