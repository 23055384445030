import React from 'react';

//MuiIcons
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

//Component Styles
const componentStyles = {
  //Expand and Collapse Icons
  iconStyle: (isExpanded, smallVersion) => ({
    height: smallVersion ? '22px' : '30px',
    width: smallVersion ? '22px' : '30px',
    // height: '30px',
    // width: '30px',
    color: isExpanded ? 'white' : '#195ffb',
    backgroundColor: isExpanded ? '#195ffb' : 'white',
    borderRadius: '100px',
    boxShadow: '-1px 1px 4px 0px #00000040',
  }),
};
const ExpandAndCollapseIcon = ({ isExpanded, smallVersion }) => {
  return (
    <>
      {isExpanded ? (
        <RemoveIcon sx={componentStyles.iconStyle(isExpanded, smallVersion)} />
      ) : (
        <AddIcon sx={componentStyles.iconStyle(isExpanded, smallVersion)} />
      )}
    </>
  );
};

export default ExpandAndCollapseIcon;
