import React from "react"

//Mui icons
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"

//Mui Components
import Typography from "@mui/material/Typography"
import { Box } from "@mui/system"
import FormControl from "@mui/material/FormControl"
import InputLabel from "@mui/material/InputLabel"
import MenuItem from "@mui/material/MenuItem"
import Select from "@mui/material/Select"


//Our styles
import activeCoverageStyles from "../activeCoverageStyles"

export default function IncrementalStdAndLtdActiveCoverage(props) {
  //destructure props
  let {
    benefitDurTypes,
    edit,
    eliminationPeriodTypes,
    plan,
    planOptions,
    selectedOption,
    selectedEliminationPeriod,
    selectedBenefitDuration,
    handlePlanEdit,
    handleSelectBenefitDuration,
    handleSelectEliminationPeriod,
  } = props
  
  console.log('props from incremental', props)

  return (
    <>
      {/* Benefit Amount Row */}
      {plan?.benefitAmount && plan?.benefitAmount > 0 ? (
        <Box sx={activeCoverageStyles.planInfoRow}>
          <Typography variant="body1">Benefit Amount</Typography>
          {/* Non-edit view */}
          {!edit && (
            <Typography variant="body1" sx={activeCoverageStyles.planInfoText}>
              {props?.UsdFormatterDec.format(plan?.benefitAmount)}
              {plan?.benefitTypeFriendly &&
              plan?.benefitTypeFriendly?.toLowerCase() !== "fixed"
                ? ` ${plan?.benefitTypeFriendly}`
                : ""}
            </Typography>
          )}

          {/* EDIT benefit amount */}
          {edit && (
            <Box sx={activeCoverageStyles.editInputContainer}>
              <FormControl fullWidth>
                <InputLabel id="benefitAmount">Benefit Amount</InputLabel>
                <Select
                  variant="outlined"
                  size="small"
                  fullWidth
                  label="Benefit Amount"
                  labelId="benefitAmount"
                  sx={activeCoverageStyles.selectDropDown}
                  IconComponent={() => (
                    <KeyboardArrowDownIcon
                      sx={activeCoverageStyles.selectDownArrowIcon}
                    />
                  )}
                  value={planOptions.selectedOptionId || 0} //???
                  onChange={(e) => handlePlanEdit(e, "incremental")}
                >
                  {planOptions?.options &&
                    planOptions?.options.map((option) => {
                      if (
                        option.eliminationPeriod == selectedEliminationPeriod &&
                        option.benefitDuration == selectedBenefitDuration &&
                        !option.isDecline
                      ) {
                        return (
                          <MenuItem
                            key={option.memberOptionId}
                            value={option.memberOptionId}
                          >
                            {props.UsdFormatterDec.format(
                              option?.benefitAmount
                            )}{" "}
                            {option?.benefitTypeFriendly?.toLowerCase() !== "fixed"
                            ? option?.benefitTypeFriendly
                            : ""}
                            {option?.memberPremiumAmount
                              ? ` (${props?.UsdFormatterDec.format(
                                  option?.memberPremiumAmount
                                )})`
                              : ""}
                          </MenuItem>
                        )
                      } else if (option.isDecline) {
                        return (
                          <MenuItem
                            key={option.memberOptionId}
                            value={option.memberOptionId}
                          >
                            Decline
                          </MenuItem>
                        )
                      }
                    })}
                </Select>
              </FormControl>
            </Box>
          )}
        </Box>
      ) : null}

      {/* Elimination Period Row*/}
      {plan?.eliminationPeriod ? (
        <Box sx={activeCoverageStyles.planInfoRow}>
          <Typography variant="body1">Elimination Period</Typography>
          {!edit && (
            <Typography variant="body1" sx={activeCoverageStyles.planInfoText}>
              {plan?.eliminationPeriod}{" "}
              {plan?.eliminationPeriodTypeFriendly
                ? ` ${plan?.eliminationPeriodTypeFriendly}`
                : ""}
            </Typography>
          )}

          {/* EDIT view elimination period for  eliminationPeriod options.length = 1 */}
          {edit && eliminationPeriodTypes?.length === 1 && (
            <Typography variant="body1" sx={activeCoverageStyles.planInfoText}>
              {`${selectedOption?.eliminationPeriod} ${
                selectedOption?.eliminationPeriodType
                  ? selectedOption?.eliminationPeriodType
                  : ""
              }`}
            </Typography>
          )}

          {/* EDIT Incremental elimination period w/ > 1 elim period option */}
          {edit &&
            eliminationPeriodTypes?.length > 1 && ( //multiple elim periods
              <Box sx={activeCoverageStyles.editInputContainer}>
                <FormControl fullWidth>
                  <InputLabel id="eliminationPeriod">
                    Elimination Period
                  </InputLabel>
                  <Select
                    variant="outlined"
                    size="small"
                    fullWidth
                    labelId="eliminationPeriod"
                    label="Elimination Period"
                    sx={activeCoverageStyles.selectDropDown}
                    IconComponent={() => (
                      <KeyboardArrowDownIcon
                        sx={activeCoverageStyles.selectDownArrowIcon}
                      />
                    )}
                    value={selectedEliminationPeriod}
                    onChange={(e) => handleSelectEliminationPeriod(e)}
                  >
                    {eliminationPeriodTypes &&
                      eliminationPeriodTypes.map((option) => {
                        return (
                          <MenuItem
                            key={option.eliminationPeriod}
                            value={option.eliminationPeriod}
                          >
                            {option.eliminationPeriod} {option.type}
                          </MenuItem>
                        )
                      })}
                  </Select>
                </FormControl>
              </Box>
            )}
        </Box>
      ) : null}

      {/* Benefit Duration Row*/}
      {plan?.benefitDuration ? (
        <Box sx={activeCoverageStyles.planInfoRow}>
          <Typography variant="body1">Benefit Duration</Typography>

          {!edit && (
            <Typography variant="body1" sx={activeCoverageStyles.planInfoText}>
              {plan?.benefitDuration}
              {plan?.benefitDurationTypeFriendly
                ? ` ${plan?.benefitDurationTypeFriendly}`
                : ""}
            </Typography>
          )}

          {/* EDIT view for benefit dur options.length = 1 */}
          {edit && benefitDurTypes?.length === 1 && (
            <Typography variant="body1" sx={activeCoverageStyles.planInfoText}>
              {`${selectedOption?.benefitDuration} ${
                selectedOption?.benefitDurationType || ""
              }`}
            </Typography>
          )}

          {/* EDIT benefit duration w/ > 1 benefit dur option*/}
          {edit && benefitDurTypes?.length > 1 && (
            <Box sx={activeCoverageStyles.editInputContainer}>
              <FormControl fullWidth>
                <InputLabel id="benefitDuration">Benefit Duration</InputLabel>
                <Select
                  variant="outlined"
                  size="small"
                  fullWidth
                  labelId="benefitDuration"
                  label="Benefit Duration"
                  sx={activeCoverageStyles.selectDropDown}
                  IconComponent={() => (
                    <KeyboardArrowDownIcon
                      sx={activeCoverageStyles.selectDownArrowIcon}
                    />
                  )}
                  value={selectedBenefitDuration}
                  onChange={(e) => handleSelectBenefitDuration(e)}
                >
                  {benefitDurTypes &&
                    benefitDurTypes?.map((option) => {
                      return (
                        <MenuItem key={option.duration} value={option.duration}>
                          {option.duration} {option.type}
                        </MenuItem>
                      )
                    })}
                </Select>
              </FormControl>
            </Box>
          )}
        </Box>
      ) : null}
    </>
  )
}
