import React from 'react';

//MuiComponents
import Slider from '@mui/material/Slider'
import Radio from '@mui/material/Radio'

//Our icons
import CustomCheckIcon from '../../../../assets/images/CustomCheckIcon.svg'



export default function CalculatorLaddMember (props) {
   let UsdFormatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits:0 });
   let UsdFormatterDec = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits:2 });

   let results = props.results
   let lifeADDPlan = results?.lifeADDPlan

   const memberValuetext = (value) => `${UsdFormatter.format(lifeADDPlan?.memberOptions[value]?.coverageAmount)}`
// console.log(UsdFormatter.format(results?.lifeADDPlan.memberOptions[props.memberSlider].coverageAmount))
   return(
      <>
      {/* MEMBER */}
      <div className='flex-row container' style={{padding:"0px 0px 0px 0vw", position:"relative",margin:"40 0 0 0"}}>
         <Radio 
         value={true}
         name={0}
         inputProps={{
               'data-amount':lifeADDPlan?.memberOptions[props.memberSlider]?.premiumAmount,
               'data-person':"member"
         }} 
         />

         <div className='col-6' style={{fontSize:"24px", fontWeight:(props.checkboxes.member === true ? 800 : 600), color:"#195ffb"}}>
               {UsdFormatter.format(results?.lifeADDPlan.memberOptions[props.memberSlider]?.coverageAmount)}
               {props.calculated && props.memberSlider == props.recCheck() && 
                  <img src={CustomCheckIcon} style={{marginLeft:"5px"}}/>
               }
               <p style={{fontSize:"16px",fontWeight:400, color:"black",marginBottom:"0px"}}>Member</p>
         </div>
         <div className='col-6' style={{textAlign:"right", position:"absolute",right:"8%",top:"10%", fontSize:"16px"}}>
               <span style={{fontSize:"18px", fontWeight:(props.checkboxes.member === true ? 800 : 500), color:props.dummyFontColor}}>
                  {props.checkboxes.member === true && "+"}{!props.dummyDisplay && UsdFormatterDec.format(results?.lifeADDPlan.memberOptions[props.memberSlider]?.premiumAmount)}{props.dummyDisplay && "$0.00 "}
               </span>
         </div>
      </div>
      <div className='flex-row container' style={{padding:"0px 0px", marginBottom:"0px"}}>
         <Slider className="flex-row"
         onChange={props.memberSliderChange} value={props.memberSlider} 
         marks={[
               { value: 0, label:"$" + results?.lifeADDPlan.memberOptions[0]?.coverageAmount/1000 + "K" },
               { value: results?.lifeADDPlan.memberOptions.length - 1, label:"$" + results?.lifeADDPlan.memberOptions[results?.lifeADDPlan.memberOptions.length-1]?.coverageAmount/1000 + "K" }
         ]} 
         componentsProps={{
               markLabel:{style:{color:"#195ffb", top:"-50%",fontWeight:600,fontSize:'16px'}},
               input:{"aria-label":"member amount"}
         }}
         step={1} min={0} max={results?.lifeADDPlan.memberOptions.length-1} 
         sx={{display:"block",margin:"auto", marginBottom: "0px",marginTop:"30px", width:"80%"}} 
         valueLabelDisplay="auto" valueLabelFormat={memberValuetext}
         />
      </div>
      <div className="flex-row container" style={{padding:"0 8%", marginBottom:"40px"}}>
         <div className="ladd-explanation">
            {lifeADDPlan?.memberExplanationOfBenefits}
         </div>
      </div>
      </>
   )
}