import React from "react";

//Mui icons
import AddIcon from '@mui/icons-material/Add'
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import Typography from "@mui/material/Typography";
import Divider from '@mui/material/Divider';


export default function EnrollmentSummaryADD (props) {
   let UsdFormatterDec = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 2 });

   let option = props.results.addPlan.options.find((option) => {
      if (props.selections.add == option.memberOptionId) return option
      else if (props.selections.add == option.memberAndSpouseOptionId) return option
      else if (props.selections.add == option.memberAndDependentsOptionId) return option
      else if (props.selections.add == option.familyOptionId) return option
   })
   

   return (
      <>
         <div className="flex-row"
         style={{
            minHeight: "57px",
            fontSize: "20px", fontWeight: 600,lineHeight:'30px',
            position: "relative",justifyContent:'flex-start',alignItems:'center',padding:'0px'
         }}>
            <span className='' style={{ cursor: "pointer", position: "relative" }}>
               {/* {props.results?.stdPlan?.title}  */}
               
               <Typography variant="subtitle2" fontWeight="600">AD&D</Typography>

               {/* bigger clickable area */}
               {/* <div style={{ position: "absolute", width: "100%", height: "300%", top: "-100%" }} />   */}
            </span>

            <div onClick={() => props.handleExpandClick(props.results.addPlan)}
            style={{
               position: "absolute", right: "0%", top: "0%",
               color: "#195ffb", width: "18%", height: "100%",
               cursor: "pointer"
            }}>
               <CreateOutlinedIcon fontSize="large" sx={{ position: "absolute", right: "14%", top: "20%", color: "primary.main", borderRadius:'100px',boxShadow:'-1px 1px 4px 0px #00000040', padding:'6px' }} />
            </div>
         </div>

         <hr style={{marginLeft:'-2%',marginBottom:'10px',minWidth:'104%',backgroundColor:'#F0F0F0',opacity:1}}/>

         <div className="flex-row" style={{ margin: "0px", fontSize: "15px", fontWeight: "500",padding:'0px',color:'#646464' }}>
            {props.results.addPlan && props.results.addPlan?.carrierName}
         </div>

         <div className="flex-row" style={{ margin: "0px", fontSize: "15px", fontWeight: "500",padding:'0px',color:'#646464' }}>
            Coverage Effective: {props.results.addPlan && props.formatDate(props.results.addPlan?.effectiveDate)}
         </div>

         <div className='flex-row' style={{ margin: "0px",marginBottom:'24px', fontSize: "15px", fontWeight: "500",padding:'0px',justifyContent:'space-between',color:'#646464',alignItems:'center' }}>
            {props.selections.add && props.selections.addVal > 0 &&
            <>
               <div style={{display:'flex',alignItems:'center'}}>
                     {props.results.addPlan && props.results.addPlan.options.map((option) => {
                     if (props.selections.add == option.memberOptionId) return 'Member'
                     else if (props.selections.add == option.memberAndSpouseOptionId) return 'Member and Spouse'
                     else if (props.selections.add == option.memberAndDependentsOptionId) return 'Member and Dependents'
                     else if (props.selections.add == option.familyOptionId) return 'Member and Family'
                  })}
                  &nbsp;
                  <span>
                     {props.results.addPlan && props.results.addPlan.options.map((option) => {
                        if (props.selections.add == option.memberOptionId) return ` (${option?.name})`
                        else if (props.selections.add == option.memberAndSpouseOptionId) return ` (${option?.name})`
                        else if (props.selections.add == option.memberAndDependentsOptionId) return ` (${option?.name})`
                        else if (props.selections.add == option.familyOptionId) return ` (${option?.name})`
                     })}
                  </span>
               </div>

               <div style={{marginRight: "10px", fontWeight: "700"}}>
                     + {UsdFormatterDec.format(props.selections.addVal)}
               </div>
            </>
            }
            {props.selections.add && props.selections.addVal == 0  &&
            <>
               <span><em>not elected</em></span>
               <span style={{float:"right", marginRight: "10px"}}>$0.00</span>
            </>
            }
            {!props.selections.add && 
            <>
            <span><em>No plan selected</em></span>
            <span style={{float:"right", marginRight: "10px"}}>$0.00</span>
            </>
            }
         </div>
      </>
   )
}