//INPUT BORDER 

let inputFieldBorder = {
   borderColor: "primary.main",
   borderWidth: '1.5px'
}

let inputFieldBorderHover = {
   borderColor:"primary.main",
   borderWidth: '1.8px'
}

const enrollmentPaymentStyles = {

   selectInput: {
      "& fieldset": inputFieldBorder,

      '&:hover :not(.Mui-error) fieldset': inputFieldBorderHover,

      '& .MuiSvgIcon-root': {
         color: 'primary.main',
      }
   },

   activeBlueDropdownField: {
      "& fieldset": inputFieldBorder,
      '&:hover fieldset': {
         borderColor: "#195ffb !important",
         borderWidth: '1.8px',
      }
   },

   CheckboxIcon: {
      height: "19px",
      color: 'primary.main'
   },

}

export default enrollmentPaymentStyles
