import React, {useRef, useState} from "react";

import {
  PieChart,
  Pie,
  Cell,
  LabelList,
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Bar,
  Label,
  ResponsiveContainer,
  Legend,
  Tooltip,
  PolarAngleAxis,
  RadialBarChart,
  RadialBar,
} from "recharts";

//Mui components
import { Card, CardHeader, Slide } from "@mui/material";
import { Tooltip as MuiTooltip } from "@mui/material/";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import MobileStepper from "@mui/material/MobileStepper";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

//Mui icons
import InfoIcon from "@mui/icons-material/Info";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";

export default function EnrollmentsByDate(props) {
  const { theme, enrollDate, maxEnrollmentCount } = props;

  const CustomTooltip = ({ active, payload, label }) => {
    // console.log('CustomTooltip', active, payload, label)
    if (active && payload && payload.length) {
      return (
        <Paper sx={{ padding: "20px" }}>
          <p className="label">{`${label}`}</p>
          <p>{`${props?.formatNumWithCommas(payload[0]?.payload?.count?.member)}`} Member Enrollment(s)</p>
          <p>{`${props?.formatNumWithCommas(payload[0]?.payload?.count?.agent)}`} Agent Enrollment(s)</p>

          {/* <p>{`${payload[0]?.payload?.count?.total}`} Enrollment(s)</p> */}
        </Paper>
      );
    }
    return null;
  };

  let renderDiffs = {}
  const renderBarShape = (props) => {
    if (props.dataKey && !props.dataKey.includes('agent')){

      let original = (props.background.height / maxEnrollmentCount) * props.count.member
      const getPath = (x, y, width, height, date) => {

  
  
  
        // if (date =='1/05') {
        //   console.log('doin some math mem', date,
        //   'height',height,
        //   'background',props.background.height,
        //   'maxEnrollmentCount',maxEnrollmentCount,
        //   'count.agent',props.count.agent,
        //   'original',original,
        //   'diff',renderDiffs[date],
        //   )
        //   // console.log('BOTGGGG agent 2',date, original,height, y, renderDiffs[date])
        //   // y = 200
        // }
        // if (height - original > 1) {
        //   let diff = 50 - original
        //   renderDiffs[date] = diff
        // if (date =='1/05') console.log('height greater than original', height,original, diff, renderDiffs[date] )
        // } else {
        //   let diff = original - height
        //   renderDiffs[date] = diff
        // if (date =='1/05') console.log('else', height,original, diff, renderDiffs[date] )

        // }
  
        // if (original > 50){
        //   let diff = 50 - original;
        //   renderDiffs[date] = diff
        // }
  
        // if (original < 50 && height > 0 && height < 50) {
        //   let diff = 50 - original;
        //   renderDiffs[date] = diff
  
        // }
        // if ( height > 0 && height < 50) {
        //   let diff = 50 - height;
        //   renderDiffs[date] = diff
  
        //   y = y - diff;
  
        //   height = 50
        // }
        let rounding = ""
        let rounding2 = []
        let roundingInt = 0
        if (props.count.member > 0 && props.count.agent > 0) {
          rounding = "1,1"
          rounding2 = ["-1,-1", "-1,1"]
          roundingInt = 1
        } else {
          rounding = "4,4"
          rounding2 = ["-4,-4", "-4,4"]
          roundingInt = 4
        }
        
        return `M${x},${y + roundingInt}
           v${height - roundingInt}
           h${width}
           v${-height + roundingInt}
           a${rounding} 0 0 0 ${rounding2[0]}
           h${-width + (roundingInt*2)}
           a${rounding} 0 0 0 ${rounding2[1]}
           z
           `;
       
        // return `M${x},${y + height}
        // h${width} a20,20 0 0 1 20,20
        // v${-height}
        // h${-width}
        // z
        // `
      };
      
      return (
        <path
          {...props}
          d={getPath(props.x, props.y, props.width, props.height, props.date)}
          stroke={props.stroke}
          fill={props.fill}
          strokeLinejoin="round"
          strokeWidth={props.strokeWidth}
          strokeLinecap="round"
          style={{display: props.height > 0 ? 'block' : 'none'}}
        />
      );

    } else {

      let original =  (props.background.height / maxEnrollmentCount) * props.count.agent

      const getPath = (x, y, width, height, date) => {
        
        
        // if ( height > 0 && height <= 50) {
        //   let diff = 50 - height;
        //   // console.log('BOTGGGG agent',date, original,height, y, renderDiffs[date])
        //   y = y - diff;
        //   if (renderDiffs[date]) y = y - renderDiffs[date]
          
        //   height = 50
        // }
        // if (date =='1/05') {
        //   console.log('doin some math', date,
        //   'height',height,
        //   'background',props.background.height,
        //   'maxEnrollmentCount',maxEnrollmentCount,
        //   'count.agent',props.count.agent,
        //   'original',original,
        //   'diff',renderDiffs[date],
        //   )
        //   // y = y-10
        // }

        return `M${x},${y + 4}
           v${height - 4}
           h${width}
           v${-height + 4}
           a4,4 0 0 0 -4,-4
           h${-width + 8}
           a4,4 0 0 0 -4,4
           z
           `;
      };
      return (
        <path
          {...props}
          d={getPath(props.x, props.y, props.width, props.height, props.date)}
          stroke={props.stroke}
          fill={props.fill}
          strokeLinejoin="round"
          strokeWidth={props.strokeWidth}
          strokeLinecap="round"
          style={{display: props.height > 0 ? 'block' : 'none'}}
        />
      );
    }

  };

  const maxHeightRender = () => {
    let firstCount = [];
    let hadDiff = false;
    return (
      <>
      <Bar
        dataKey={"count.member"}
        stackId={"pv"}
        fill={theme?.palette?.primary?.main}
        stroke="white"
        strokeWidth={"2px"}
        shape={renderBarShape}
        background={false}
        // minPointSize={(count,i,e) => {
        //   // console.log('minpoint mem', count,firstCount, i,e)

        //   if (count > 0) {
        //     firstCount.push(count);
        //     return 50;
        //   }
        //   else return 0
        // }}
      >
        <LabelList
          dataKey="count.member"
          position="insideTop"
          content={(props) => {
            if (props.value == 0) return null;
            let height = props.height;
            let y = props.y;
            if (height < 30) return null;
            // if ( height > 0 && height <= 50) {
            //   let diff = 50 - height;
      
            //   y = y - diff;
            //   if (renderDiffs[props.date]) y = y - renderDiffs[props.date]
      
            //   height = 50
            // }
            return (
              <g>
                <text
                  x={props.x + props.width / 2}
                  y={y + 14}
                  style={{
                    stroke: "#FFF",
                    fill: "#FFF",
                    fontSize: "14px",
                    fontFamily: "Poppins",
                    fontWeight: 500,
                    marginTop: "5px",
                  }}
                  textAnchor="middle"
                  dominantBaseline={"middle"}
                >
                  {props.value}
                </text>
              </g>
            );
          }}
        />
        {/* {
          enrollDate.map((entry, index) => {
            console.log('entry', entry)
            if (entry.count.member > 0)
            return <Cell key={`cell-${index}`} />
          })
        } */}
      </Bar>
      <Bar
        dataKey={"count.agent"}
        stackId={"pv"}
        fill={"#5421C9A6"}
        stroke="white"
        strokeWidth={"2px"}
        shape={renderBarShape}
        background={false}
        // minPointSize={(count) => {
        //   // console.log('minpoint', count, firstCount)
        //   if ((count - firstCount[0]) > 0) {
        //     firstCount.shift();
        //     return 50;
        //   }

        //   else {
        //     firstCount.shift();
        //     return 0
        //   }
        // }}
      >
        <LabelList
          dataKey="count.agent"
          position=""
          content={(props) => {
            if (props.value == 0) return null;
            let height = props.height;
            let y = props.y;
            if (height < 30) return null;
            // if ( height > 0 && height <= 50) {
            //   let diff = 50 - height;
      
            //   y = y - diff;
            //   if (renderDiffs[props.date]) y = y - renderDiffs[props.date]
      
            //   height = 50
            // }
            return (
              <g>
                <text
                  x={props.x + props.width / 2}
                  y={y + 14}
                  style={{
                    stroke: "#FFF",
                    fill: "#FFF",
                    fontSize: "14px",
                    fontFamily: "Poppins",
                    fontWeight: 500,
                  }}
                  textAnchor="middle"
                  dominantBaseline={"middle"}
                >
                  {props.value}
                </text>
              </g>
            );
          }}
        />
        {/* {
          enrollDate.map((entry, index) => {
            console.log('entry', entry)
            if (entry.count.agent > 0){
              console.log('agent cell',document.querySelector(`cell-${index}`))
              return <Cell key={`cell-${index}`} stroke={'#fff'}  strokeWidth={index === 2 ? 4 : 1}/>
            }

            
          })
        } */}
      </Bar>
      </>

    )
  }

  return (
    <>
      {enrollDate?.length > 0 ? (
        <ResponsiveContainer minWidth={600} minHeight={300} >
          <BarChart
            
            data={enrollDate}
            height={"100%"}
            margin={{ top: 60, bottom: 40, left: 55, right: 55 }}
            barGap={"0%"}
            barCategoryGap={"5%"}
            maxBarSize={200}
            background={false}
            
          >
            {/* <CartesianGrid strokeDasharray={'3'} /> */}
            <XAxis
              dataKey={"date"}
              height={50}
              label={{
                value: "Submission Date",
                offset: "2",
                position: "bottom",
                fill: theme?.palette?.text?.primary,
              }}
              
            ></XAxis>
            <YAxis
              interval={1}
              // tickCount={6}
              scale={"linear"}
              label={{
                value: "Submitted Enrollments",
                angle: -90,
                position: "insideBottomLeft",
                fill: theme?.palette?.text?.primary,
              }}
              id='enrollmentsByDateBarChart'
              padding={{bottom:1.1}}
            ></YAxis>
            <Tooltip
              position={{ y: 0 }}
              isAnimationActive={false}
              cursor={{ fill: "transparent" }}
              content={
                <CustomTooltip isAnimationActive={false} offset={{ y: 0 }} />
              }
            />
            {maxHeightRender()}
            
            
          </BarChart>
        </ResponsiveContainer>
      ) : (
        <Typography
          variant="body2"
          sx={{
            textAlign: "center",
            marginTop: "50px",
            fontSize: "16px!important",
            color: "#4F4F4F",
          }}
        >
          No enrollment data found.
        </Typography>
      )}
    </>
  );
}
