import React from 'react';

//MUI Components
import { Box, Typography } from '@mui/material';

//Assets
import FullColorBlackText from '../../../assets/images/FullColorBlackText.svg';

export default function PoweredBy(props) {
  //component styles
  const componentStyles = {
    componentContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      fontWeight: 800,
      width: '100%',
      minHeight: '50px',
      marginTop: 'auto',
      ...props.containerStyle,
    },
    text: {
      margin: ' 15px auto 25px',
      fontWeight: 800,
      alignItems: 'center',
      fontSize: '10px!important',
      ...props.textStyle,
    },
  };
  return (
    <>
      <Box sx={componentStyles.componentContainer}>
        <Typography sx={componentStyles.text}>
          POWERED BY &nbsp;{' '}
          <img
            style={{ height: '21px' }}
            src={FullColorBlackText}
            alt="UnionHub logo"
          />
        </Typography>
      </Box>
    </>
  );
}
