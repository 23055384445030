import React, { useEffect, useLayoutEffect, useState } from 'react';

//MuiComponents
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

//Our Icons
import CustomRecBenefitCheck from '../../../assets/images/CustomRecBenefitCheck.svg';

//HOW TO USE THE THEMES FROM APP

export default function RecommendedBenefitDisplay(props) {
  const componentStyles = {
    defaultCalculatorDisplay: {
      container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        padding: '10px 20px',
        margin: '0 auto',
        maxWidth: '600px',
        borderRadius: '8px',
        backgroundColor: 'background.recommendation',
        color: 'text.recommendation',
        border: '1px solid',
        borderColor: 'secondary.recommendationOutline',
      },
      title: {
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: '22px',
        textAlign: 'center',
        letterSpacing: '0.10px',
        marginBottom: '4px',
      },
      description: {
        fontSize: '12px',
        fontWeight: 400,
        lineHeight: '20px',
        textAlign: 'center',
        letterSpacing: '0.40px',
      },
    },
    allPlans: {
      container: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '8px 15px 8px 13px ',
        margin: '8px auto 10px',
        width: '99%',
        minHeight: '42px',
        borderRadius: '8px',
        backgroundColor: 'background.recommendation',
        color: 'text.recommendation',
        border: '1px solid',
        borderColor: 'secondary.recommendationOutline',
      },
      amount: {
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: '22px',
        textAlign: 'center',
        letterSpacing: '0.10px',
      },
    },
    checkMark: {
      height: '20px',
      color: 'text.recommendation',
    },
  };

  /////USD formatting - dynamic fraction digits

  function formatNumberWithDynamicFraction(number) {
    // Check if there are any decimal places
    let hasDecimalPlaces = number % 1 !== 0;

    // Set minimumFractionDigits
    let minimumFractionDigits = hasDecimalPlaces ? 2 : 0;

    // Create formatter with dynamic minimumFractionDigits
    let formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: minimumFractionDigits,
    });

    return formatter.format(number);
  }

  let currentRecAmount =
    props?.currRec?.benefitAmount || props?.currRec?.coverageAmount;

  return (
    <>
      {/* generic display for calculator page */}
      {props?.calculatorDisplay && (
        <Box sx={componentStyles.defaultCalculatorDisplay.container}>
          <Typography sx={componentStyles.defaultCalculatorDisplay.title}>
            Recommended Benefit
          </Typography>
          <Typography sx={componentStyles.defaultCalculatorDisplay.description}>
            Recommended benefits are highlighted in green and based on the
            answers you provided above.
          </Typography>
        </Box>
      )}

      {/* display for all plans if rec is > $0 */}
      {currentRecAmount > 0 && (
        <Box sx={componentStyles.allPlans.container}>
          <Typography sx={componentStyles.allPlans.amount}>
            {`${formatNumberWithDynamicFraction(
              currentRecAmount
            )} Recommended Benefit`}
          </Typography>
          <img
            src={CustomRecBenefitCheck}
            alt="check mark"
            style={componentStyles.checkMark}
          />
        </Box>
      )}

      {/* display for spouse ladd if > $0 */}
      {props?.currSpouseRec?.coverageAmount > 0 && (
        <Box sx={componentStyles.allPlans.container}>
          <Typography sx={componentStyles.allPlans.amount}>
            {`${formatNumberWithDynamicFraction(
              props?.currSpouseRec?.coverageAmount
            )} Recommended Benefit`}
          </Typography>
          <img
            src={CustomRecBenefitCheck}
            alt="check mark"
            style={componentStyles.checkMark}
          />
        </Box>
      )}

      {/* display for dependent ladd */}
      {props?.currDepRec > 0 && (
        <Box sx={componentStyles.allPlans.container}>
          <Typography sx={componentStyles.allPlans.amount}>
            Dependent Coverage Recommended
          </Typography>
          <img
            src={CustomRecBenefitCheck}
            alt="check mark"
            style={componentStyles.checkMark}
          />
        </Box>
      )}
    </>
  );
}
