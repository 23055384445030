import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

//Services


// Assets


//MuiIcons



//MuiComponents
import Button from "@mui/material/Button";
import { InputLabel, TextField } from "@mui/material";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { Select } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Avatar from '@mui/material/Avatar';


export default function SettingsProfile(props) {



   const [activeRole, setActiveRole] = useState(1)


   let handleRoleChange = (e, property) => {
      let value = e.target.value
      setActiveRole(value)
   }
   console.log(props.roles?.find(x => x.roleId == activeRole).permissions)

   return (
      <>
         {/* ACTUAL CONTENT */}

         <div className="container" style={{ height: 'calc(100vh - 219px)', backgroundColor: "FFFFFF", margin: 20 }}>
            <div className="row" style={{ height: 'auto' }}>
               <div className='col-md-12' style={{ marginBottom: "40px" }}>
                  <h5>Permissions Settings</h5>
                  <p>Customize the permissions settings for users here.</p>
               </div>

               <div className={`col-md-3`} style={{ marginBottom: "40px" }}>
                  <FormControl sx={{ width: "300px", marginBottom: "20px" }}>
                     <InputLabel>Role</InputLabel>
                     <Select variant='outlined' size='large' fullWidth
                        value={activeRole}
                        label="Timezone"
                        onChange={e => handleRoleChange(e, 'activeRole')}
                     >
                        {props.roles && props.roles.map(role => {
                           return <MenuItem value={role.roleId}>
                              <span style={{ fontFamily: "Archivo", paddingLeft: '10px', margin: 'auto 0px' }}>{role.roleName}</span>
                           </MenuItem>
                        })}
                     </Select>
                  </FormControl>
                  {activeRole == 1 &&
                     <>
                        <p>The most powerful role in UnionHub.</p>
                        <p>System administrators can manage permissions and change fields that other agents can not.  Magage further settings here.</p>
                     </>

                  }

                  {activeRole == 2 &&
                     <>
                     <p>Your most common power users of UnionHub.</p>
                     <p>Enrollers have basic access of UnionHub and can be managed further here.</p>
                  </>
                  }


               </div>
               <div className={`col-md-9 row`}>
                  <FormGroup className="col-6">
                  {props.roles && (props.roles?.find(x => x.roleId == activeRole).permissions).map(permission => {
                        if(permission.id <= 11){
                                                      return <><FormControlLabel control={<Switch checked={permission.isSelected} />} label={permission.name} />
                           <span>{permission.description}</span> </>
                        }

                        })}
                  </FormGroup>

                  <FormGroup className="col-6">
                  {props.roles && (props.roles?.find(x => x.roleId == activeRole).permissions).map(permission => {
                        if(permission.id > 11){
                                                      return <><FormControlLabel control={<Switch checked={permission.isSelected} />} label={permission.name} />
                           <span>{permission.description}</span> </>
                        }

                        })}
                  </FormGroup>


               </div>

            </div>
         </div>


      </>
   )
}