import axios from "axios";
import Settings from "../settings";
import authService, { authHeader } from "./auth-service";
import { baseService } from "./baseService";

class internalNotificationService extends baseService {
    // Pass in offset to get the next 20 notifications
    //current page size = 20 rows
    getMyNotifications(offset) {
        const url = Settings.API_URL + "notification/" + offset;
        return this.get(url);
    }
    markNotificationAsViewed(notificationId) {
        const url = Settings.API_URL + "notification/" + notificationId;
        return this.put(url);
    }
    markNotificationUnread(notificationId) {
        const url = Settings.API_URL + "notification/unread/" + notificationId;
        return this.put(url);
    }
}

const notificationService = new internalNotificationService();

export default notificationService;