import Settings from "../settings";
import { baseService } from "./baseService";


class internalOptionService extends baseService {
    getWorkStatus() {
        return this.get(Settings.API_URL + 'option/workstatus');
    }
    getGenderTypes() {
        return this.get(Settings.API_URL + 'option/gendertypes');
    }
    getTaskStatus() {
        return this.get(Settings.API_URL + 'option/taskstatus');
    }
    getTaskSource() {
        return this.get(Settings.API_URL + 'option/tasksource');
    }
    getRelationshipTypes() {
        return this.get(Settings.API_URL + 'option/relationshiptypes');
    }
    getPremiumTypes() {
        return this.get(Settings.API_URL + 'option/premiumtypes');
    }
    getEnrollmentStates() {
        return this.get(Settings.API_URL + 'option/enrollmentstates');
    }
    getOrganizationTypes() {
        return this.get(Settings.API_URL + 'option/organizationtypes');
    }
    getUSStates() {
        return this.get(Settings.API_URL + "option/usstates");
    }
    getTicketTypes() {
        return this.get(Settings.API_URL + "option/ticket/type");
    }
    getTicketOrigins() {
        return this.get(Settings.API_URL + "option/ticket/origin");
    }
    getTicketSLA() {
        return this.get(Settings.API_URL + "option/ticket/sla");
    }
    getTicketStatus() {
        return this.get(Settings.API_URL + "option/ticket/status");
    }
    getTicketPriority() {
        return this.get(Settings.API_URL + "option/ticket/priority");
    }
    getTicketEntryTypes() {
        return this.get(Settings.API_URL + "option/ticket/ticketentrytype");
    }
    getPaymentFrequencies() {
        return this.get(Settings.API_URL + "option/paymentfrequencies");
    }
    getTimezones() {
        return this.get(Settings.API_URL + "option/timezone");
    }
    getNotificationTypes() {
        return this.get(Settings.API_URL + "option/notificationtypes");
    }
    getPhoneNumberTypes() {
        return this.get(Settings.API_URL + "option/phonenumbertypes");
    }
    getBenefitDurationTypes() {
        return this.get(Settings.API_URL + "option/benefitdurationtypes");
    }
    getEliminationPeriodTypes() {
        return this.get(Settings.API_URL + "option/eliminationperiodtypes")
    }
    getBankAccountTypes() {
        return this.get(Settings.API_URL + "option/bankaccounttypes")
    }
    getPaymentTransactionTypes() {
        return this.get(Settings.API_URL + "option/paymenttransactiontypes")
    }
    getPaymentStatuses() {
        return this.get(Settings.API_URL + "option/paymentstatuses")
    }
    getBankAccountPrenoteStatuses() {
        return this.get(Settings.API_URL + "option/bankaccountprenotestatuses")
    }
    getAttachmentTypes() {
        return this.get(Settings.API_URL + "option/attachmenttypes")
    }
    getCancellationReasons() {
        return this.get(Settings.API_URL + "option/cancellationreasons")
    }
    getLanguageTypes() {
        return this.get(Settings.API_URL + "option/languagetypes")
    }
}

const optionService = new internalOptionService();
export default optionService;