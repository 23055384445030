import React, { useContext, useEffect, useState } from 'react';
// import { useHistory } from 'react-router-dom';
import {
    format,
    getDate,
    getMonth,
    addMonths,
    subMonths,
    addYears,
    subYears,
} from "date-fns"

//services
// import ticketService from '../../../../../../services/ticket-service';
// import optionService from '../../../../../../services/optionService';
// import userService from '../../../../../../services/user-service';
import paymentsService from '../../../../../../../services/payments-service';
import walletService from '../../../../../../../services/wallet-service';

//Mui Components
import {
  Alert,
  Box,
  Button,
  Card,
  Chip,
  Fade,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Tooltip,
  Typography,
} from '@mui/material';

// Assets

//Mui icons
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';

//Our Components
import ManagePaymentScheduleDrawer from '../payment-drawers/ManagePaymentScheduleDrawer';
import DisabledPaymentScheduleChip from '../../../../DisplayChips/DisabledPaymentScheduleChip';
import PlaceHolderMessage from '../../../PlaceHolderMessages/PlaceHolderMessage';

//utils
import { formatUTCToShortDate } from '../../../../../../utils';
const componentStyles = {
  componentBody: {
    borderColor: 'background.border',
    height: '266px',
    borderRadius: '0px',
    fontFamily: 'Poppins',
    backgroundColor: 'background.default',
    overflowY: 'auto',
    paddingBottom: '20px',
  },
  headerContainer: {
    padding: '18px',
    display: 'flex',
    // flexWrap: "wrap",
    justifyContent: 'space-between',
    alignItems: 'top',
    margin: '0 auto',
  },
  titleContainer: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  title: {
    color: 'text.primary',
    fontFamily: 'Poppins',
    fontSize: '20px',
    fontWeight: '500',
    width: '100%',
  },
  description: {
    color: 'text.secondary',
    fontFamily: 'Archivo',
    fontSize: '14px',
    fontWeight: '400',
  },
  paymentScheduelCard: (disabledSchedule) => ({
    border: 'background.border',
    backgroundColor: 'background.default',
    padding: '10px 8px 4px 8px',
    maxWidth: '500px',
    margin: '10px 18px',
    display: 'flex',
    flexWrap: 'wrap',
    // justifyContent: "flex-start",
    alignItems: 'top',
    fontFamily: 'Poppins',
    minHeight: '110px',
    color: disabledSchedule ? 'text.primary' : 'text.disabled',
  }),
  iconContainer: {
    display: 'flex',
    width: '48px',
    margin: '0 auto',
  },
  chipDesign: {
    backgroundColor: 'background.paymentScheduleChip',
    height: '24px',
    fontWeight: '500',
    lineHeight: '18px',
    fontFamily: 'Archivo',
    fontSize: '13px',
    borderRadius: '4px',
    marginRight: '5px',
  },
  paymentInfo: {
    flex: '2',
    paddingLeft: '6px',
    // border: "2px solid red",
  },
  paymentAmountText: {
    color: 'text.secondary',
    fontWeight: '400',
    lineHeight: '18px',
    fontSize: '13px!important',
  },

  amountTotalContainer: {
    display: 'flex',
    flexDirection: 'column',
    // justifyContent: "center",
    padding: '0 4px',
    // border: "2px solid red",
  },
  amountTotal: {
    fontSize: '20px',
    fontWeight: '500',
    height: '24px',
    fontFamily: 'Poppins',
    alignSelf: 'bottom',
    // border: "2px solid red",
  },

  scheduleChipContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignContent: 'top',
    margin: '0px 4px 4px 48px',
    width: '100%',
  },

  perMonth: {
    fontSize: '12px',
    fontWeight: '400px',
    height: '24px',
    fontFamily: 'Poppins',
    alignSelf: 'bottom',
    marginTop: '10px',
    paddingLeft: '5px',
  },

  //-----One Time Payment Card styles-----//
  oneTimePaymentCard: {
    border: 'background.border',
    backgroundColor: 'background.default',
    padding: '8px',
    maxWidth: '500px',
    margin: '10px 18px',
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
    fontFamily: 'Poppins',
  },
  otpIconContainer: {
    display: 'flex',
    justifyContent: 'center',
    padding: '0px',
    minWidth: '50px',
  },
  oneTimeTransactionIcon: (transactionTypeId) => ({
    color:
      transactionTypeId === 2
        ? 'primary.refundIcon'
        : transactionTypeId === 1
        ? 'primary.main'
        : 'primary.main',
    margin: '3px auto',
  }),
  otpInfoContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  otpTopRowContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '30px',
  },
  otpTopInfo: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  otpChipDesign: (transactionTypeId) => ({
    backgroundColor:
      transactionTypeId === 2
        ? 'background.refundChip'
        : transactionTypeId === 1
        ? 'background.paymentScheduleChip'
        : 'background.paymentScheduleChip', //may need to consider switch statement for chip styles in future
    color:
      transactionTypeId === 2
        ? 'primary.refundChip'
        : transactionTypeId === 1
        ? 'primary.paymentScheduleChip'
        : 'primary.paymentScheduleChip',
    height: '24px',
    fontWeight: '500',
    lineHeight: '18px',
    fontFamily: 'Archivo',
    fontSize: '13px',
    borderRadius: '4px',
    marginRight: '10px',
  }),
  otpSecondRowContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  otpAmountContainer: {
    padding: '0px 4px',
    maxWidth: '193px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignContent: 'center',
    marginBottom: '5px',
    // border: "2px solid red",
  },
  oneTimeAmountTotal: {
    fontSize: '20px',
    fontWeight: '500',
    fontFamily: 'Poppins',
    margin: '0 12px',
  },

  bankAccountText: {
    color: 'text.primary',
    marginRight: '5px',
    fontSize: '13px!important',
  },

  //Placeholder message
  placeHolderMessageContainer: {
    width: '100%',
    minHeight: '120px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  successAlert: {
    color: '#1B5E20',
    backgroundColor: 'transparent',
    alignItems: 'center',
    lineHeight: '18px',
    fontWeight: 500,
    textAlign: 'center',
    height: '30px',
    maxWidth: '360px',
    margin: '0 auto',
    // border: "1px solid #1B5E20",
  },
};

export default function PaymentPlanCard(props) {

    //determine Chip fill (monthly vs semi-monthly based on paymentFrequencyId)
    const getMemberPaymentFrequency = (frequencyId) => {
        let paymentFrequency = props.paymentFrequencies?.find(
            (scheduleFrequency) => {
                //if member paymentFrequencyId = 1, frequency is monthly, if 2, semi-monthly
                return scheduleFrequency.id === frequencyId
            }
        )
        return paymentFrequency?.name?.toUpperCase() || ""
    }

  //list of payment dates (ex. [1, 15] would be 1st and 15th of month, add suffix for display)
  const getDaySuffix = (day) => {
    if (typeof day !== 'number' || isNaN(day)) {
      return `No date(s) selected`;
    }
    if (day >= 4 && day <= 20) {
      return `${day}th`;
    }
    switch (day % 10) {
      case 1:
        return `${day}st`;
      case 2:
        return `${day}nd`;
      case 3:
        return `${day}rd`;
      default:
        return `${day}th`;
    }
  };

    // //handle opening payment drawer - this will also display the radio buttons to select a bank account to make a payment
    // const [paymentDrawerOpen, setPaymentDrawerOpen] = useState(false);
    // const [showSelectBankAccount, setShowSelectBankAccount] = useState(false);

    // const handleOpenMakePaymentDrawer = () => {
    //   setShowSelectBankAccount(true);
    //   setPaymentDrawerOpen(true);
    // };

  let todayDateObj = new Date();
  //set to midnight
  todayDateObj.setUTCHours(0, 0, 0, 0);
  //convert to unix timestamp in seconds
  let todayAtMidnightUnix = Math.floor(todayDateObj.getTime() / 1000);

  //handle cancel/edit payment schedule
  const [anchorEl, setAnchorEl] = useState(null);
  const [editAnchorEl, setEditAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const openEdit = Boolean(editAnchorEl);
  const [openEditScheduleDrawer, setOpenEditScheduleDrawer] = useState(false);
  const [oneTimePaymentClicked, setOneTimePaymentClicked] = useState(false);
  const [editScheduleClicked, setEditScheduleClicked] = useState(false);
  const [editScheduleSuccess, setEditScheduleSuccess] = useState(false);

  useEffect(() => {
    setEditScheduleClicked(false);
    setEditScheduleSuccess(false);
  }, []);

  //EDIT PAYMENT SCHEDULE
  // //open vertical menu option to 'manage schedule'
  const openScheduleVerticalMenu = (e, schedule) => {
    setEditAnchorEl(e.currentTarget);
    setEditScheduleClicked(schedule);
  };

  //click on manage-schedule menu option
  const handleOpenEditScheduleDrawer = (e) => {
    setOpenEditScheduleDrawer(true);
    setEditAnchorEl(null);
  };

  const handleEnableOrDisableClick = async (e, _paymentSchedule, action) => {
    //find payment schedule from array of payment schedules  that matches to update
    let _updatedPaymentSchedule;
    if (props?.paymentSchedule?.length > 0) {
      _updatedPaymentSchedule = props?.paymentSchedule?.find((schedule) => {
        return schedule.id === _paymentSchedule.id;
      });
    }
    if (action === 'disable') {
      _updatedPaymentSchedule = {
        ..._paymentSchedule,
        isEnabled: false,
      };
    } else {
      _updatedPaymentSchedule = {
        ..._paymentSchedule,
        isEnabled: true,
      };
    }

    try {
      let updatedSchedule = await walletService.updatePaymentScheduleForMember(
        _updatedPaymentSchedule
      );
      if (updatedSchedule) {
        setEditScheduleSuccess(true);
        setTimeout(() => {
          setEditScheduleSuccess(false);
        }, 4000);
      }
    } catch (error) {
      console.error(`error updating payment scheduled to ${action} `);
    }

    props?.getPaymentSchedule();
    handleCloseEditOrCancelMenu();
  };

    const handleCloseEditOrCancelMenu = () => {
        //reset account selected to false
        setEditScheduleClicked(false)
        setOneTimePaymentClicked(false)
        setAnchorEl(null)
        setEditAnchorEl(null)
    }

  //CANCEL ONE TIME PAYMENT
  const handleCancelMenuClick = (e, payment) => {
    //hold payment menu selected in state and change with updated most recent payment selected to cancel
    setOneTimePaymentClicked(payment);
    setAnchorEl(e.currentTarget);
  };

  const cancelOneTimePayment = async (e) => {
    if (oneTimePaymentClicked) {
      try {
        await paymentsService.cancelOneTimePayment(
          oneTimePaymentClicked.id,
          oneTimePaymentClicked.paymentTransactionTypeId
        );
      } catch (error) {
        console.error('error canceling payment', error);
      }
    }

    //delay to allow for payment to be canceled before refreshing list
    setTimeout(() => {
      //refresh one time payments listed and transaction list
      props.getOneTimePayments();
      props?.getAllPayments();
      props?.getPaymentSchedule();
      //close menu
      setAnchorEl(null);
    }, 300);
  };

  let allowEditSchedule = props?.permissionsEnabler?.editScheduledPayments;

  let allowEditPayment = props?.permissionsEnabler?.editPayments;

  let allowView = props?.permissionsEnabler?.viewScheduledPayments;

  let paySchedulePendingCancelMessage =
    'Pay schedule reflects changes that never went into effect due to cancelation. Schedule will cancel with coverage on';

  console.log('paymentPlan props', props);
  return (
    <Paper className="" elevation={0} sx={componentStyles.componentBody}>
      {/* Edit Schedule DRAWER */}
      <ManagePaymentScheduleDrawer
        openEditScheduleDrawer={openEditScheduleDrawer}
        setOpenEditScheduleDrawer={setOpenEditScheduleDrawer}
        editScheduleClicked={editScheduleClicked}
        setEditScheduleClicked={setEditScheduleClicked}
        editScheduleSuccess={editScheduleSuccess}
        setEditScheduleSuccess={setEditScheduleSuccess}
        getDaySuffix={getDaySuffix}
        UsdFormatterDec={props.UsdFormatterDec}
        paymentFrequencies={props?.paymentFrequencies}
        getPaymentSchedule={props?.getPaymentSchedule}
      />

      <Box sx={componentStyles.headerContainer}>
        <Box sx={componentStyles.titleContainer}>
          <Typography variant="h6" sx={componentStyles.title}>
            Schedule
          </Typography>
          <Typography variant="body2" sx={componentStyles.description}>
            View and edit current schedules, and one-time payments.
          </Typography>
        </Box>
        <Box>
          <Button
            disabled={
              !allowEditPayment ||
              props?.paymentSchedule?.length === 0 ||
              !props?.paymentSchedule[0]?.id > 0 ||
              !props?.paymentSchedule[0]?.isPeakPay
            }
            //disabled={true} //disabled currently to only allow one time payments through clicking 'rerun' on grid item for qualifying transactions
            fullWidth
            variant="contained"
            size="medium"
            onClick={props?.handleOpenMakePaymentDrawer}
            sx={{
              height: '36px',
              width: '180px',
              borderColor: 'primary.main',
              borderRadius: '8px',
              marginTop: '8.5px',
            }}
          >
            MAKE A PAYMENT
          </Button>
        </Box>
      </Box>

      {/* View this content if member has payment schedule, is enrolled, and is on PeakPay*/}
      {props?.paymentSchedule[0]?.isPeakPay &&
        props?.paymentSchedule[0]?.id > 0 && (
          <>
            {/* Payment Schedule(s) - iterate over array of objects of potential payment schedules - only allow view w/ permissions*/}
            {allowView ? (
              <>
                {props?.paymentSchedule?.length > 0 &&
                props?.paymentSchedule ? (
                  props?.paymentSchedule?.map((schedule) => {
                    let greyOutText =
                      schedule?.isEnabled &&
                      schedule?.beginPaymentDate < schedule?.endPaymentDate
                        ? 'text.primary'
                        : 'text.disabled';

                    return (
                      <Card
                        elevation={0}
                        variant="outlined"
                        key={schedule?.id}
                        sx={componentStyles.paymentScheduelCard(
                          schedule?.isEnabled &&
                            schedule?.beginPaymentDate <
                              schedule?.endPaymentDate
                        )}
                      >
                        {schedule?.id !== 0 && schedule ? (
                          <>
                            <Box style={componentStyles.iconContainer}>
                              <CalendarTodayIcon
                                sx={{
                                  color:
                                    schedule?.isEnabled &&
                                    schedule?.beginPaymentDate <
                                      schedule?.endPaymentDate
                                      ? 'primary.main'
                                      : 'text.disabled',
                                  margin: '6px auto',
                                }}
                              />
                            </Box>

                                                        {/* Payment Schedule & amount(s) */}

                            <Box style={componentStyles.paymentInfo}>
                              <Box className="flex-row">
                                <Chip
                                  sx={{
                                    ...componentStyles.chipDesign,
                                    color:
                                      schedule?.isEnabled &&
                                      schedule?.beginPaymentDate <
                                        schedule?.endPaymentDate
                                        ? 'primary.paymentScheduleChip'
                                        : 'text.disabled',
                                    backgroundColor:
                                      schedule?.isEnabled &&
                                      schedule?.beginPaymentDate <
                                        schedule?.endPaymentDate
                                        ? 'background.paymentScheduleChip'
                                        : 'background.disabled',
                                  }}
                                  label={getMemberPaymentFrequency(
                                    schedule?.paymentFrequencyId
                                  )}
                                />
                                <Box
                                  className="flex-row"
                                  sx={{ flexWrap: 'wrap' }}
                                >
                                  {/* Single Date */}
                                  {schedule?.scheduledPaymentDates?.length ===
                                    1 && (
                                    <>
                                      <Typography
                                        variant="body2"
                                        sx={{
                                          fontFamily: 'Poppins',
                                          fontSize: '14px!important',
                                        }}
                                      >
                                        {getDaySuffix(
                                          schedule?.scheduledPaymentDates[0]
                                            ?.dayOfMonth
                                        )}
                                      </Typography>
                                      {/* <Typography
                                    variant="body2"
                                    sx={{ fontFamily: "Poppins", fontSize: '14px!important', marginLeft: '4px', lineHeight: '24px' }}
                                  >
                                    of the month
                                  </Typography> */}
                                                                            </>
                                                                        )}

                                  {/* Multiple dates */}
                                  {schedule?.scheduledPaymentDates?.length >
                                    1 &&
                                    schedule?.scheduledPaymentDates?.map(
                                      (date, index) => {
                                        if (
                                          index ===
                                          schedule?.scheduledPaymentDates
                                            .length -
                                            1
                                        ) {
                                          return (
                                            <Typography
                                              key={index}
                                              variant="body2"
                                              sx={{
                                                fontFamily: 'Poppins',
                                                fontSize: '14px!important',
                                                lineHeight: '24px',
                                              }}
                                            >
                                              {getDaySuffix(date?.dayOfMonth)}
                                            </Typography>
                                          );
                                        } else {
                                          return (
                                            <Typography
                                              key={index}
                                              variant="body2"
                                              sx={{
                                                fontFamily: 'Poppins',
                                                marginRight: '4px',
                                                fontSize: '14px!important',
                                              }}
                                            >
                                              {`${getDaySuffix(
                                                date?.dayOfMonth
                                              )} &`}
                                            </Typography>
                                          );
                                        }
                                      }
                                    )}
                                </Box>
                              </Box>

                              {/* Payment amounts - split if multiple payments */}
                              <Box
                                sx={{ padding: '2px 0px', color: greyOutText }}
                              >
                                {schedule?.scheduledPaymentDates?.map(
                                  (date, index) => {
                                    //if only 1 payment date
                                    if (
                                      schedule?.scheduledPaymentDates
                                        ?.length === 1
                                    ) {
                                      return (
                                        <Typography
                                          key={index}
                                          variant="body2"
                                          sx={{
                                            ...componentStyles.paymentAmountText,
                                            color: greyOutText,
                                          }}
                                        >
                                          Payment -{' '}
                                          {(schedule?.paymentAmount &&
                                            schedule?.transactionFee &&
                                            props.UsdFormatterDec.format(
                                              schedule?.paymentAmount +
                                                schedule?.transactionFee
                                            )) ||
                                            ''}
                                        </Typography>
                                      );
                                    }

                                    // if 2 or MORE dates
                                    // using payments object that is within the scheduledPayment object - it has separate payments and rounding already calculated from back
                                    if (
                                      schedule?.scheduledPaymentDates?.length >
                                        1 &&
                                      index === 0
                                    ) {
                                      return (
                                        <Typography
                                          key={index}
                                          variant="body2"
                                          sx={{
                                            ...componentStyles.paymentAmountText,
                                            color: greyOutText,
                                          }}
                                        >
                                          First Payment -{' '}
                                          {(schedule?.payments[1] &&
                                            schedule?.transactionFee &&
                                            props.UsdFormatterDec.format(
                                              schedule?.payments[1] +
                                                schedule?.transactionFee
                                            )) ||
                                            ''}
                                        </Typography>
                                      );
                                    } else if (index === 1) {
                                      return (
                                        <Typography
                                          key={index}
                                          variant="body2"
                                          sx={{
                                            ...componentStyles.paymentAmountText,
                                            color: greyOutText,
                                          }}
                                        >
                                          Second Payment -{' '}
                                          {(schedule?.payments[2] &&
                                            schedule?.transactionFee &&
                                            props.UsdFormatterDec.format(
                                              schedule?.payments[2] +
                                                schedule?.transactionFee
                                            )) ||
                                            ''}
                                        </Typography>
                                      );
                                    } else {
                                      return (
                                        <Typography
                                          key={index}
                                          variant="body2"
                                          sx={{
                                            ...componentStyles.paymentAmountText,
                                            color: greyOutText,
                                          }}
                                        >
                                          Additional Payment -{' '}
                                          {(schedule?.paymentAmount &&
                                            schedule?.transactionFee &&
                                            props.UsdFormatterDec.format(
                                              Math.floor(
                                                (schedule?.paymentAmount /
                                                  schedule
                                                    ?.scheduledPaymentDates
                                                    ?.length) *
                                                  100
                                              ) /
                                                100 +
                                                schedule?.transactionFee
                                            )) ||
                                            ''}
                                        </Typography>
                                      );
                                    }
                                  }
                                )}
                              </Box>
                              <Box sx={componentStyles.otpSecondRowContainer}>
                                <Typography
                                  variant="body2"
                                  sx={componentStyles.bankAccountText}
                                >
                                  {props?.bankAccount?.filter(
                                    (bankAccount) =>
                                      bankAccount?.id == schedule?.bankAccountId
                                  )[0]?.bankName || 'Account'}
                                </Typography>
                                <Typography
                                  variant="body2"
                                  sx={componentStyles.bankAccountText}
                                >
                                  {` *${props?.bankAccount
                                    ?.filter(
                                      (bankAccount) =>
                                        bankAccount?.id ==
                                        schedule?.bankAccountId
                                    )[0]
                                    ?.accountNumber?.slice(-4)}`}
                                </Typography>
                              </Box>
                            </Box>

                            {/* Total Monthly Payment Amount */}
                            <Box sx={componentStyles.amountTotalContainer}>
                              <Box
                                sx={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  justifyContent: 'space-between',
                                  height: '30px',
                                }}
                              >
                                <Box className="flex-row">
                                  <Typography
                                    variant="body1"
                                    sx={{
                                      ...componentStyles.amountTotal,
                                      color: greyOutText,
                                    }}
                                  >
                                    {(schedule?.paymentAmount &&
                                      props.UsdFormatterDec.format(
                                        schedule?.paymentAmount +
                                          schedule?.totalTransactionFee
                                      )) ||
                                      ''}
                                  </Typography>

                                                                    <Typography
                                                                        variant="body1"
                                                                        sx={{
                                                                            ...componentStyles.perMonth,
                                                                            color: greyOutText,
                                                                        }}
                                                                    >
                                                                        /per&nbsp;month
                                                                    </Typography>
                                                                </Box>

                                                                {/* edit schedule menu - can only do this on active schedule (endPaymentDate = far future)*/}
                                                                <Box>
                                                                    <IconButton
                                                                        disabled={!allowEditSchedule}
                                                                        onClick={(e) => {
                                                                            openScheduleVerticalMenu(e, schedule)
                                                                        }}
                                                                    >
                                                                        <MoreVertIcon />
                                                                    </IconButton>
                                                                    {/* EDIT SCHEDULE FUNCTIONALITY - can edit active schedule w/ endPaymentDate in far future, can disable any sctive schedules */}
                                                                    {editAnchorEl && (
                                                                    <Menu
                                                                        id="edit-payment-schedule"
                                                                        anchorEl={editAnchorEl}
                                                                        open={openEdit}
                                                                        value={schedule?.id || ""}
                                                                        // disabled={true}
                                                                        onClose={handleCloseEditOrCancelMenu}
                                                                        anchorOrigin={{
                                                                            vertical: "top",
                                                                            horizontal: "left",
                                                                        }}
                                                                        transformOrigin={{
                                                                            vertical: "top",
                                                                            horizontal: "left",
                                                                        }}
                                                                    >
                                                                            <>
                                                                                {editScheduleClicked?.canEditPaymentDate && (
                                                                                    <MenuItem
                                                                                        value={editScheduleClicked?.id || ""}
                                                                                        onClick={(e) =>
                                                                                            handleOpenEditScheduleDrawer(e)
                                                                                        }
                                                                                    >
                                                                                        Manage Schedule
                                                                                    </MenuItem>
                                                                                )}
                                                                                {editScheduleClicked?.isEnabled ? (
                                                                                    <MenuItem
                                                                                        value={editScheduleClicked?.id || ""}
                                                                                        onClick={(e) =>
                                                                                            handleEnableOrDisableClick(
                                                                                                e,
                                                                                                editScheduleClicked,
                                                                                                "disable"
                                                                                            )
                                                                                        }
                                                                                    >
                                                                                        Disable Schedule
                                                                                    </MenuItem>
                                                                                ) : (
                                                                                    <MenuItem
                                                                                        value={editScheduleClicked?.id || ""}
                                                                                        onClick={(e) =>
                                                                                            handleEnableOrDisableClick(
                                                                                                e,
                                                                                                editScheduleClicked,
                                                                                                "enable"
                                                                                            )
                                                                                        }
                                                                                    >
                                                                                        Resume Schedule
                                                                                    </MenuItem>
                                                                                )}
                                                                            </>
                                                                    </Menu>)
                                                                    }

                                                                </Box>
                                                            </Box>

                              <Box>
                                <Typography
                                  variant="body2"
                                  sx={{
                                    ...componentStyles.paymentAmountText,
                                    fontSize: '11px!important',
                                    color: greyOutText,
                                  }}
                                >
                                  {schedule?.paymentFrequencyId
                                    ? `* INCLUDES $${schedule?.paymentFrequencyId} TRANS FEE`
                                    : ''}
                                </Typography>
                              </Box>
                            </Box>

                                                        {/* SCHEDULE CHIP DISPLAY */}

                                                        <Box sx={componentStyles.scheduleChipContainer}>
                                                            {/* display alert if scheduled paymensts are dsiabled */}
                                                            {!schedule?.isEnabled && (
                                                                <DisabledPaymentScheduleChip />
                                                            )}

                              {/* display IF this schedule has an end date - is active until end of month because schedule has been changed - new changes take effect in new month and schedule is NOT DISABLED */}
                              {schedule?.endPaymentDate?.slice(0, 4) !==
                                '9999' &&
                                schedule?.isEnabled &&
                                schedule?.beginPaymentDate <
                                  schedule?.endPaymentDate && (
                                  <Chip
                                    icon={
                                      <AccessTimeIcon
                                        sx={{
                                          height: '24px',
                                          color:
                                            'rgba(123, 31, 162, 1)!important',
                                        }}
                                      />
                                    }
                                    sx={{
                                      //  minWidth: "200px",
                                      backgroundColor:
                                        'background.onDisabilityChip',
                                      color: 'primary.onDisabilityChip',
                                      fontFamily: 'Archivo',
                                      margin: '0',
                                      fontWeight: '500',
                                      alignItems: 'center',
                                      fontSize: '12px',
                                      alignSelf: 'self-end',
                                    }}
                                    label={
                                      `Schedule ENDS ${formatUTCToShortDate(
                                        schedule?.endPaymentDate
                                      )}` || ''
                                    }
                                  />
                                )}
                              {/* display IF this schedule has a start date in future- is not active until start of new month and schedule is NOT DISABLED and if begin payment date < end date*/}
                              {schedule?.beginPaymentDate >
                                todayDateObj?.toISOString() &&
                                schedule?.isEnabled &&
                                schedule?.beginPaymentDate <
                                  schedule?.endPaymentDate && (
                                  <Chip
                                    icon={
                                      <AccessTimeIcon
                                        sx={{
                                          height: '24px',
                                          color:
                                            'rgba(230, 81, 0, 1)!important',
                                        }}
                                      />
                                    }
                                    sx={{
                                      // minWidth: "200px",
                                      backgroundColor:
                                        'background.newPaymentSchedule',
                                      color: 'primary.newPaymentSchedule',
                                      fontFamily: 'Archivo',
                                      margin: '0',
                                      fontWeight: '500',
                                      alignItems: 'center',
                                      fontSize: '12px',
                                      alignSelf: 'self-end',
                                    }}
                                    label={
                                      `Schedule BEGINS ${formatUTCToShortDate(
                                        schedule?.beginPaymentDate
                                      )}` || ''
                                    }
                                  />
                                )}

                              {/* display IF all active coverage has been canecled - only if there is a pending upcoming pay schedule that will not go into effect because of a full cancel*/}
                              {schedule?.beginPaymentDate >
                                schedule?.endPaymentDate &&
                                schedule?.isEnabled && (
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      flexDirection: 'column',
                                      justifyContent: 'flex-start',
                                    }}
                                  >
                                    <Chip
                                      icon={
                                        <AccessTimeIcon
                                          sx={{
                                            height: '24px',
                                            color: '#E57373!important',
                                          }}
                                        />
                                      }
                                      sx={{
                                        // minWidth: "200px",
                                        backgroundColor:
                                          'secondary.payChips.disabledChip',
                                        color: 'primary.payChips.disabledChip',
                                        fontFamily: 'Archivo',
                                        margin: '0',
                                        fontWeight: '500',
                                        alignItems: 'center',
                                        fontSize: '12px',
                                        width: '180px',
                                      }}
                                      label={`Pending cancellation` || ''}
                                    />
                                    <Typography
                                      variant="body2"
                                      sx={{
                                        fontSize: '9px!important',
                                        color: 'text.primary',
                                        padding: '5px 0px 2px 7px',
                                        lineHeight: '12px',
                                        maxWidth: '370px',
                                      }}
                                    >
                                      {`${paySchedulePendingCancelMessage.toUpperCase()} ${formatUTCToShortDate(
                                        schedule?.endPaymentDate
                                      )}.`}
                                    </Typography>
                                  </Box>
                                )}
                            </Box>
                          </>
                        ) : (
                          <Typography
                            variant="h6"
                            sx={{ padding: '10px', margin: 'auto 0' }}
                          >
                            No Schedule Information
                          </Typography>
                        )}
                      </Card>
                    );
                  })
                ) : (
                  <Card
                    elevation={0}
                    variant="outlined"
                    sx={componentStyles.paymentScheduelCard}
                  >
                    <Typography
                      variant="h6"
                      sx={{ padding: '10px', margin: 'auto 0' }}
                    >
                      No Schedule Information
                    </Typography>
                  </Card>
                )}
              </>
            ) : (
              <Card
                elevation={0}
                variant="outlined"
                sx={componentStyles.paymentScheduelCard}
              >
                {' '}
                You do not have permissions to view payments.
              </Card>
            )}

            {/* Display update schedule successful message */}
            <Fade
              in={editScheduleSuccess && !editScheduleClicked}
              mountOnEnter
              unmountOnExit
            >
              <Alert
                severity="success"
                sx={componentStyles.successAlert}
                icon={<CheckIcon />}
              >
                <span style={{ display: 'inline-block', fontSize: '14px' }}>
                  Payment Schedule successfully updated!
                </span>
              </Alert>
            </Fade>

            {/* Display one time transactions (payments or refunds) if any exist */}
            {allowView &&
              props.oneTimePayments &&
              props?.oneTimePayments?.map((payment) => {
                console.log('payment', payment);
                let hasBeenProcessed = payment?.hasBeenProcessed ? true : false;
                let bankAccount = props?.bankAccount?.find(
                  (acc) => acc.id === payment?.bankAccountId
                );
                //check if payment has been processed or cancelled and do not display if payment meets either of these conditions: cancelled payments have occurrence of 9999-12-31T23:59:59.9999999+00:00
                if (
                  !payment?.hasBeenProcessed &&
                  payment.occurrence.slice(0, 4) !== '9999'
                ) {
                  return (
                    <Card
                      key={payment.id}
                      elevation={0}
                      variant="outlined"
                      sx={componentStyles.oneTimePaymentCard}
                    >
                      <Box sx={componentStyles.otpIconContainer}>
                        <AccessTimeIcon
                          sx={componentStyles.oneTimeTransactionIcon(
                            payment?.paymentTransactionTypeId
                          )}
                        />
                      </Box>

                      <Box sx={componentStyles.otpInfoContainer}>
                        <Box sx={componentStyles.otpTopRowContainer}>
                          <Box sx={componentStyles.otpTopInfo}>
                            <Chip
                              sx={componentStyles.otpChipDesign(
                                payment?.paymentTransactionTypeId
                              )}
                              label={
                                payment?.paymentTransactionTypeId === 1
                                  ? 'ONE TIME PAYMENT'
                                  : payment?.paymentTransactionTypeId === 2
                                  ? 'PENDING REFUND'
                                  : 'Pending Payout'
                              }
                            />

                            {/* Scheduled Date of Payment */}
                            <Typography
                              variant="body2"
                              sx={{
                                fontFamily: 'Poppins',
                                alignSelf: 'center',
                              }}
                            >
                              {formatUTCToShortDate(payment.occurrence)}
                            </Typography>

                            {/* Transaction Amount */}
                            <Typography
                              variant="body1"
                              sx={componentStyles.oneTimeAmountTotal}
                            >
                              {props.UsdFormatterDec.format(
                                payment.paymentAmount
                              ) || ''}
                            </Typography>
                          </Box>

                          <Box sx={componentStyles.otpAmountContainer}>
                            <IconButton
                              disabled={!allowEditSchedule || hasBeenProcessed}
                              onClick={(e) => handleCancelMenuClick(e, payment)}
                            >
                              <MoreVertIcon
                                sx={{
                                  color: 'text.secondary',
                                  float: 'right',
                                }}
                              />
                            </IconButton>
                            <Menu
                              id="cancel-one-time-payment"
                              anchorEl={anchorEl}
                              open={open}
                              onClose={handleCloseEditOrCancelMenu}
                              anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                              }}
                              transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                              }}
                            >
                              <MenuItem
                                value={oneTimePaymentClicked?.id}
                                onClick={cancelOneTimePayment}
                                disabled={payment?.paymentAmount === 0} //disable if refund, currently not allowing refunds to be cancelled
                              >
                                Cancel
                              </MenuItem>
                            </Menu>
                          </Box>
                        </Box>

                        <Box sx={componentStyles.otpSecondRowContainer}>
                          <Typography
                            variant="body2"
                            sx={componentStyles.bankAccountText}
                          >
                            {bankAccount?.bankName || 'Account'}
                          </Typography>
                          <Typography
                            variant="body2"
                            sx={componentStyles.bankAccountText}
                          >
                            {` *${bankAccount?.accountNumber?.slice(-4)}`}
                          </Typography>
                        </Box>
                      </Box>
                    </Card>
                  );
                }
              })}

            {!allowView && (
              <>
                <Alert sx={{ width: '95%', margin: '0 auto' }} severity="error">
                  You do not have the required permissions to view scheduled
                  payments.
                </Alert>
              </>
            )}
          </>
        )}

      {/* Display if member is NOT on PeakPay but has valid payment schedule/ is enrolled */}
      {!props?.paymentSchedule[0]?.isPeakPay &&
      props?.memberHasActiveCoverage ? (
        <Box sx={componentStyles.placeHolderMessageContainer}>
          {/* <PlaceHolderMessage type={'noPeakPay'}/> */}
          <PlaceHolderMessage type={'noPeakPay'} />
        </Box>
      ) : (
        ''
      )}

      {/* Display if member is NOT enrolled */}
      {!props?.memberHasActiveCoverage ? (
        <Box sx={componentStyles.placeHolderMessageContainer}>
          <PlaceHolderMessage type={'noActiveCoverage'} />
        </Box>
      ) : (
        ''
      )}
    </Paper>
  );
}
