import React from "react";

//Mui icons
import AddIcon from '@mui/icons-material/Add'
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import {  Typography } from "@mui/material";


export default function EnrollmentSummaryLAAD (props) {
   let UsdFormatterDec = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 2 });
   let UsdFormatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });



   return (
   <>
      <div className="flex-row"
      style={{
         minHeight: "57px", 
         fontSize: "20px", fontWeight: 600,lineHeight:'30px',
         position: "relative",justifyContent:'flex-start',alignItems:'center',padding:'0px'
      }}>
         <span className='' style={{ cursor: "pointer", position: "relative" }}>
            {/* {props.results?.lifeADDPlan?.title}  */}
            <Typography variant="subtitle2" fontWeight="600">Life Insurance</Typography>
            {/* bigger clickable area */}
            {/* <div style={{ position: "absolute", width: "100%", height: "300%", top: "-100%" }} />   */}
         </span>

         <div onClick={() => props.handleExpandClick(props.results.lifeADDPlan)}
         style={{
            position: "absolute", right: "0%", top: "0%",
            color: "#195ffb", width: "18%", height: "100%",
            cursor: "pointer"
         }}>
            <CreateOutlinedIcon fontSize="large" sx={{ position: "absolute", right: "14%", top: "20%", color: "primary.main", borderRadius:'100px',boxShadow:'-1px 1px 4px 0px #00000040', padding:'6px' }} />
         </div>
      </div>

      <hr style={{marginLeft:'-2%',marginBottom:'10px',minWidth:'104%',backgroundColor:'#F0F0F0',opacity:1}}/>

      <div className="flex-row" style={{ margin: "0px", fontSize: "15px", fontWeight: "500",padding:'0px',color:'#646464' }}>
         {props.results.lifeADDPlan && props.results.lifeADDPlan?.carrierName}
      </div>

      <div className="flex-row" style={{ margin: "0px", fontSize: "15px", fontWeight: "500",padding:'0px',color:'#646464' }}>
         Coverage Effective: {props.results.lifeADDPlan && props.formatDate(props.results.lifeADDPlan?.effectiveDate)}
      </div>


      <div className='flex-row' style={{ margin: "0px 0px", fontSize: "15px", fontWeight: "500",padding:'0px',justifyContent:'space-between',color:'#646464',alignItems:'center' }}>
         {props.selections.ladd[0] > 0 &&
         <>
            <div style={{display:'flex',alignItems:'center'}}>
                  Member - {UsdFormatter.format(props.selections.ladd[0])}
            </div>  

            <div style={{marginRight: "15px", fontWeight: "700"}}>
                  + {UsdFormatterDec.format(props.selections.laddVal[0])}
            </div>
         </>
         }
         {props.selections.ladd[0] == 0 && 
         <>
            <span >Member - <em>not elected</em></span>
            <span style={{ float: "right", marginRight: "15px", }}>$0.00</span>
         </>
         }
      </div>
      <div className='flex-row' style={{ margin: "0px 0px", fontSize: "15px", fontWeight: "500",padding:'0px',justifyContent:'space-between',color:'#646464',alignItems:'center' }}>
         {props.selections.ladd[1] > 0 &&
         <>
         <div style={{display:'flex',alignItems:'center'}}>
            Spouse - {UsdFormatter.format(props.selections.ladd[1])}
         </div>

         <div style={{marginRight: "15px", fontWeight: "700"}}>
            + {UsdFormatterDec.format(props.selections.laddVal[1])}
         </div>

         </>
         }
         {props.selections.ladd[1] == 0 && 
         <>
            <span>Spouse - <em>not elected</em></span>
            <span style={{ float: "right", marginRight: "15px"}}>$0.00</span>
         </>
         }
      </div>
      <div className='flex-row' style={{ margin: "0px 0px", fontSize: "15px", fontWeight: "500",padding:'0px',justifyContent:'space-between',color:'#646464',alignItems:'center' }}>
         {props.selections.ladd[2] > 0 && 
         <>
         <div style={{display:'flex',alignItems:'center'}}>
         Dependent(s) - {UsdFormatter.format(props.selections.ladd[2])}
         </div>

         <div style={{marginRight: "15px", fontWeight: "700"}}>
            + {UsdFormatterDec.format(props.selections.laddVal[2])}
         </div>
         </>
         }
         {props.selections.ladd[2] == 0 && 
         <>
            <span>Dependent - <em>not elected</em></span>
            <span style={{ float: "right", marginRight: "15px" }}>$0.00</span>
         </>
         }
      </div>
   </>
   )
}