import React, { useEffect, useState } from "react"

//Services
import walletService from "../../../../../../services/wallet-service"

//Mui Components
import { Box, Button, MenuItem, TextField } from "@mui/material"

//Our Components
import ButtonInfinityLoader from "../../../../../UH-loading-animation/ButtonInfinityLoader"

//Our styles
import enrollmentPaymentStyles from "../enrollmentPaymentStyles"

//Component styles
const componentStyles = {
  //Payment inputs
  inputContainer: {
    padding: "0px",
    marginBottom: "15px",
  },
  saveButtonContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    padding: "0px",
  },
  saveAndCancelButtons: {
    height: "30px",
    fontSize: "13px",
    width: "100px",
  },
}

export default function BankInfoForm(props) {
  //PROPS
  const {
    bank,
    bankHandler,
    bankAccountTypes,
    bankAccountHandler,
    routingBankInfo,
    routingError,
    matchingAccountError,
    checkMatchingAccount,
    cancelAddNewBankAccount,
    showAddNewBankAccount,
    setShowAddNewBankAccount,
    disabled,
  } = props

  //SAVE Logic
  const [loading, setLoading] = useState(false)

  const saveNewAccount = async () => {
    setLoading(true)
    //submit only needed bank acount info
    let _bank = {
      memberId: bank.memberId,
      paymentFrequencyId: parseInt(bank?.paymentFrequencyId),
      bankAccountTypeId: parseInt(bank?.bankAccountTypeId),
      routingNumber: bank.routingNumber,
      accountNumber: bank.accountNumber,
      isPrimary: true,
      bankName:
        routingBankInfo[0]?.status === "success"
          ? routingBankInfo[0].data.name
          : "",
      description: bank?.description,
    }
    try {
      await walletService.addBankAccountForMember(_bank)
    } catch (error) {
      console.error("Error adding bank account: ", error)
    }
    setLoading(false)
    setShowAddNewBankAccount(false)

    //refresh bank account list
    await props?.getBank()
  }

  return (
    <>
      <Box sx={componentStyles.inputContainer}>
        <TextField
          fullWidth
          id="descriptionUpdater"
          onChange={bankHandler}
          sx={{
            "& fieldset": { borderRadius: "8px" },
            ...enrollmentPaymentStyles.selectInput,
          }}
          // onBlur={checkRoutingNumber}
          label="Name on Account"
          value={bank?.description || ""}
          placeholder="Name on Account"
          InputLabelProps={{ sx: { color: "primary.main" } }}
        />
      </Box>
      <Box sx={componentStyles.inputContainer}>
        <TextField
          fullWidth
          id="routingNumberUpdater"
          onChange={bankHandler}
          error={routingError.error}
          helperText={routingError.message}
          required
          sx={{
            "& fieldset": { borderRadius: "8px" },
            ...enrollmentPaymentStyles.selectInput,
          }}
          // onBlur={checkRoutingNumber}
          label="Routing Number"
          value={bank?.routingNumber || ""}
          placeholder="Routing Number"
          InputLabelProps={{ sx: { color: "primary.main" } }}
          InputProps={{
            type: "tel",
          }}
        />
      </Box>

      <Box sx={componentStyles.inputContainer}>
        <TextField
          fullWidth
          // disabled={!bank?.routingBankInfo ? true : false}
          id="accountNumberUpdater"
          onChange={bankHandler}
          required
          sx={{
            "& fieldset": { borderRadius: "8px" },
            ...enrollmentPaymentStyles.selectInput,
          }}
          label="Account Number"
          value={bank?.accountNumber || ""}
          placeholder="Account Number"
          onBlur={checkMatchingAccount}
          InputLabelProps={{ sx: { color: "primary.main" } }}
          InputProps={{
            type: "tel",
          }}
        />
      </Box>

      <Box sx={componentStyles.inputContainer}>
        <TextField
          fullWidth
          id="confirmAccountUpdater"
          onChange={bankHandler}
          required
          error={matchingAccountError}
          sx={{
            "& fieldset": { borderRadius: "8px" },
            ...enrollmentPaymentStyles.selectInput,
          }}
          label="Confirm Account Number"
          value={bank?.confirmAccount || ""}
          onBlur={checkMatchingAccount}
          placeholder="Confirm Account Number"
          InputLabelProps={{ sx: { color: "primary.main" } }}
          InputProps={{
            type: "tel",
          }}
        />
      </Box>

      <Box sx={componentStyles.inputContainer}>
        <TextField
          fullWidth
          required
          select
          sx={{
            "& fieldset": { borderRadius: "8px" },
            "&.MuiOutlinedInput-root.Mui-focused > fieldset": {
              border: "2px solid #195ffb",
            },
            ...enrollmentPaymentStyles.selectInput,
          }}
          variant="outlined"
          value={bank?.bankAccountTypeId || ""}
          onChange={bankAccountHandler}
          label="Account Type"
          InputLabelProps={{ sx: { color: "primary.main" } }}
        >
          {bankAccountTypes?.map((account) => (
            <MenuItem key={account.id} value={account.id}>
              {account.name}
            </MenuItem>
          ))}
        </TextField>
      </Box>

      {/* save new bank account IF user already has other saved bank accounts */}
      {showAddNewBankAccount && (
        <Box sx={componentStyles.saveButtonContainer}>
          <Button
            sx={{
              ...componentStyles.saveAndCancelButtons,
              marginRight: "10px",
            }}
            onClick={cancelAddNewBankAccount}
          >
            CANCEL
          </Button>

          <Button
            variant="contained"
            sx={componentStyles.saveAndCancelButtons}
            disabled={disabled || loading}
            onClick={saveNewAccount}
          >
            {loading ? <ButtonInfinityLoader buttonTitle={"SAVE"} /> : "SAVE"}
          </Button>
        </Box>
      )}
    </>
  )
}
