import axios from "axios";
import Settings from "../settings";
import authService, { authHeader } from "./auth-service";
import { baseService } from "./baseService";

class internalRegisterService extends baseService {
    register(registrationData) {
        const url = Settings.API_URL + "register";
        return this.post(url, registrationData);
    }

    forgotPassword(emailAddress, code) {
        const url = Settings.API_URL + "register/forgot";
        const forgotCredentials = {
            email: emailAddress,
            code: code,
        }
        return this.post(url, forgotCredentials);
    }
    forgotPasswordByMemberId(memberId) {
        const url = Settings.API_URL + "register/" + memberId + "/forgot";
        return this.post(url);
    }

    updatePassword(guid, newPassword) {
        const url = Settings.API_URL + "register/change";
        var updatePassword = {
            email:"",
            password: newPassword,
            code: guid,
        }
        return this.post(url, updatePassword);
    }

    confirmEmail(guid) {
        const url = Settings.API_URL + "register/confirm";
        const confirmEmail = {
            code: guid
        };
        return this.put(url, confirmEmail);
    }
    /*
     * {
    "email":"test@no-reply.com",
    "password":"Password",
    "firstName": "Test",
    "lastName": "Bowden"
    */
}

const registerService = new internalRegisterService();

export default registerService;