// import React, { useEffect, useState } from "react"

//Services

//Mui Components
import {
  Box,
  Card,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material"

// Assets

//Mui icons
import AccountBalanceIcon from "@mui/icons-material/AccountBalance"
import MoreVertIcon from "@mui/icons-material/MoreVert"
import { set } from "date-fns"

// Our shared styles for payments tab
import paymentsTabStyles from "../paymentsTabStyles"

export default function ArchivedBankAccounts(props) {
  //PROPS
  const {
    archivedBankAccounts,
    member,
    getMemberAccountType,
    editWalletDrawerOpen,
    editArchivedAccountMenu,
    setEditArchivedAccountMenu,
    handleCloseArchivedAccountMenu,
    handleUnarchiveAccount,
  } = props

  //handle three dot menu botton
  const handleEditArchivedAccountMenu = (e) => {
    setEditArchivedAccountMenu({
      ...editArchivedAccountMenu,
      [e.currentTarget.value]: e.currentTarget,
    })
  }

  //Future logic for full delete and unarchive still needed

  return (
    <>
      {/* Archived Account(s)  - display all*/}
      {archivedBankAccounts?.length > 0 &&
        archivedBankAccounts?.map((account, index) => {
          return (
            <Card
              key={account?.id}
              elevation={0}
              variant="outlined"
              sx={paymentsTabStyles.accountCard}
            >
              {/* icon */}
              <Box style={paymentsTabStyles.iconContainer}>
                <AccountBalanceIcon
                  sx={paymentsTabStyles.accountBalanceIcon(account?.isDeleted)}
                />
              </Box>

              {/* Member Name and Account Type */}
              <Box sx={paymentsTabStyles.accountInfo}>
                <Typography
                  variant="body2"
                  sx={paymentsTabStyles.accountNameText(account?.isDeleted)}
                >
                  {account?.description ? `${account?.description} - ` : ""}{" "}
                  {getMemberAccountType(account.bankAccountTypeId)} account
                </Typography>

                {/* Bank Info 4 digit account*/}
                <Box sx={paymentsTabStyles.bankAccountInfoContainer}>
                  <Typography
                    variant="body2"
                    sx={paymentsTabStyles.bankAccountText(account?.isDeleted)}
                  >
                    {account.bankName || "Account"}
                  </Typography>

                  <Typography
                    sx={paymentsTabStyles.bankAccountText(account?.isDeleted)}
                  >
                    {` *${account.accountNumber?.slice(-4)}`}
                  </Typography>
                </Box>
              </Box>

              {/* Three dot menu */}
              <Box sx={paymentsTabStyles.menuContainer}>
                <IconButton
                  onClick={handleEditArchivedAccountMenu}
                  value={account.id}
                  sx={{ borderRadius: "100%" }}
                >
                  <MoreVertIcon sx={paymentsTabStyles.moreVertIcon} />
                </IconButton>
                <Menu
                  id="edit-bankAccount-menu"
                  anchorEl={editArchivedAccountMenu?.[account.id]}
                  open={Boolean(editArchivedAccountMenu?.[account.id])}
                  onClose={handleCloseArchivedAccountMenu}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                >
                  <MenuItem value={account} disabled={true}>
                    Delete
                  </MenuItem>

                  <MenuItem
                    value={account}
                    onClick={(e) => handleUnarchiveAccount(e, account)}
                    disabled={props?.undoSnackbarInfo?.action}
                  >
                    Unarchive
                  </MenuItem>
                </Menu>
              </Box>
            </Card>
          )
        })}
    </>
  )
}
