import React, { useEffect, useState } from 'react'


//MuiComponents
import Collapse from '@mui/material/Collapse';
import Slider from '@mui/material/Slider'
import Checkbox from '@mui/material/Checkbox';

//Our components
import SaveChangesSnackbar from '../../../save-changes-snackbar/SaveChangesSnackbar'

//Our icons
import CustomCheckIcon from '../../../../assets/images/CustomCheckIcon.svg'



export default function CalculatorLaddSpouse (props) {
   let UsdFormatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits:0 });
   let UsdFormatterDec = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits:2 });
   let percentFormatter = new Intl.NumberFormat('en-US',{ style:'percent' })


   let results = props.results
   let lifeADDPlan = results?.lifeADDPlan

   const [opacity,setOpacity] = useState(props.survey.IsMarried ? 1 : .6)
   useEffect(() => {
      if (props.checkboxes.spouse) setOpacity(1)
      else if (!props.checkboxes.spouse && !props.survey.IsMarried) setOpacity(.6)
   },[props.checkboxes.spouse])

   const spouseValuetext = (value) => `${UsdFormatter.format(lifeADDPlan?.spouseOptions[value]?.coverageAmount)}`

   const [displayAlert,setDisplayAlert] = useState(false)
   const checkMemberIsSelected = () => {
      if (props.checkboxes.member !== true){
         setDisplayAlert(true)
      }
   }

   return (
      <>
      <SaveChangesSnackbar 
      displayAlert={displayAlert} setDisplayAlert={setDisplayAlert} 
      text="Member must be selected to enroll spouse coverage" severity="error"
      backgroundColor="#CD2026"
      />
      <div style={{opacity: opacity}} onClick={checkMemberIsSelected}>
         <div className='flex-row container' style={{padding:"0px 0px 0px 0vw", position:"relative",margin:"40 0 10 0"}}>
               {/* <div className="col-1"></div> */}
               <Checkbox 
               onChange={props.laddSelectionHandler} 
               value={props.checkboxes.spouse} checked={props.checkboxes.spouse}
               name={1}
               inputProps={{
                  'data-amount':lifeADDPlan?.spouseOptions[props.spouseSlider]?.premiumAmount,
                  'data-person':"spouse"
               }}
               disabled={props.checkboxes.member !== true}
               />

               <div className='col-6' style={{fontSize:"24px", fontWeight:(props.checkboxes.spouse ? 800 : 600), color:"#195ffb"}}>
                  {UsdFormatter.format(results?.lifeADDPlan.spouseOptions[props.spouseSlider]?.coverageAmount)}
                  {props.calculated && props.survey.IsMarried && props.spouseSlider == props.recCheck() && 
                     <img src={CustomCheckIcon} style={{marginLeft:"5px"}}/>
                  }
                  <p style={{fontSize:"16px",fontWeight:(props.checkboxes.spouse ? 800 : 400), color:"black",marginBottom:"0px",whiteSpace:"nowrap"}}>Spouse </p>
                  {props.displayAlert && <p style={{fontSize:"10px",fontWeight:400, color:"black",marginBottom:"0px",whiteSpace:"nowrap"}}>(cannot exceed {percentFormatter.format(lifeADDPlan.spousePercentageCap)} of member election)</p>}
               </div>
               <div className='col-6' style={{textAlign:"right", position:"absolute",right:"8%",top:"10%", fontSize:"16px"}}>
                  <span style={{fontSize:"18px", fontWeight:(props.checkboxes.spouse ? 800 : 500), color:props.dummyFontColor}}>
                     {props.checkboxes.spouse && "+"}{!props.dummyDisplay && UsdFormatterDec.format(results?.lifeADDPlan.spouseOptions[props.spouseSlider]?.premiumAmount)}{props.dummyDisplay && "$0.00"}
                  </span>
               </div>
         </div>
         <div className='flex-row container' style={{ padding: "0px 0px", marginBottom: "0px" }}>
               <Slider className="flex-row" id="spouse-slider"
                  onChange={props.spouseSliderChange} value={props.spouseSlider}
                  marks={props.marks}
                  componentsProps={{
                     markLabel: { style: { color: "#195ffb", top: "-50%", fontWeight: 600, fontSize: "16px" } },
                  }}
                  step={1} min={0} max={results?.lifeADDPlan.spouseOptions.length - 1}
                  sx={{ display: "block", margin: "auto", marginBottom: "0px", marginTop: "20px", width: "80%" }}
                  valueLabelDisplay="auto" valueLabelFormat={spouseValuetext}
               />
         </div> 
         <div className="flex-row container" style={{padding:"0 8%", marginBottom:"40px"}}>
            <div className="ladd-explanation">
               {lifeADDPlan.spouseExplanationOfBenefits}
            </div>
         </div>
      </div>
      </>
   )
}