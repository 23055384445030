import React, { useState, useEffect } from "react";
import { add, format, set } from "date-fns";

//Services
import enrollmentService from "../../../../../../../../services/enrollment-service";

//Mui icons
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

//Mui Components
import Chip from "@mui/material/Chip";
import Alert from "@mui/material/Alert";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import { Box, border, textAlign } from "@mui/system";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Collapse, InputLabel } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { FormGroup, FormControlLabel, Switch } from "@mui/material";

//our components
import InfinityLoader from "../../../../../../../UH-loading-animation/InfinityLoader";
import { is } from "date-fns/locale";
import EditCoverageEffectiveDateStepper from "../EditCoverageEffectiveDateStepper";

export default function EnrollmentSummaryCardLADD(props) {

  const componentStyles = {
    addBeneficiaryInputs: {
      margin: "5px 0px",
    },
    editBeneficiaryContainer: {
        display: "flex",
        width: "100%",
        flexDirection: "column",
        alignItems: "flex-start",
        margin: " 0px 0px 10px 10px",
    },
    editBeneficiaryRow: {
      display: "flex",
      flexDirection: "row",
      width: "50%",
      marginBottom: "16px",
      alignItems: "center",
    },
    allocationInput: { marginRight: "5px", minWidth: "80px" },
    beneficiaryChip: {
      margin: "5px 4px 0px 0px",
      backgroundColor: "transparent",
      color: "primary.main",
      border: "solid 1px",
      borderColor: "primary.main",
      fontWeight: "500",
      fontFamily: "Archivo",
      fontSize: "14px",
      height: "36px",
      borderRadius: "50px"
    },
    selectDropDown: {
      height: "40px", borderRadius: "4px", padding: "10px 0px", width: "100%",
      "&.MuiOutlinedInput-root.Mui-focused > fieldset": {
        border: "2px solid #195ffb",
      }, textAlign: "left", 
    },


  };

  let plan = props.plan;

  const [loading, setLoading] = useState(false);

  const [storedAllocations, setStoredAllocations] = useState([]);
  const [primaryBennies, setPrimaryBennies] = useState([]);
  const [contingentBennies, setContingentBennies] = useState([]);
  const [potentialBeneficiaries, setPotentialBeneficiaries] = useState([]);
  const [totalAllocationError, setTotalAllocationError] = useState(false);

  const getAllocations = async () => {
    //TECHNICALLY A HARDCODE, CHECK FOR BENNY ON PLAN
    //  props.setLoading(true);
    let _storedAllocations;

    //get allocations for agent-side single enrollment with planId and memberId
    const planId = plan.planId;
    const memberId = props.member?.id;

    try {
      _storedAllocations =
        await enrollmentService.getBeneficiaryAllocationsForMember(
          planId,
          memberId
        );
    
      if (!_storedAllocations || _storedAllocations.length === 0) {
        setStoredAllocations([]);
      } else {
        //format dob
        let formattedDobBennies = [];
        _storedAllocations.forEach((benny) => {
          if (benny.dob && (benny?.dob?.slice(0,4) !== "0001" || benny?.dob?.slice(0,4) !== "9999")) {
            benny.dob = format(props?.formatDate(benny.dob), "yyyy-MM-dd");
          }
          formattedDobBennies.push({ ...benny });
        });

        setStoredAllocations(formattedDobBennies);
      }
    } catch (error) {
      console.error("error grabbing allocations for member side", error);
    }
  };

  const getPotentialBeneficiariesForMember = async () => {
    let _potentials;
    try {
      _potentials = await enrollmentService.getPotentialBeneficiariesForMember(
        props?.member?.id
      ); //FOR MEMBER
      setPotentialBeneficiaries(_potentials);
    } catch (error) {
      console.log("error getting potential bennies", error);
    }

  }

  useEffect(() => {
    if (props.member && plan) {
      getAllocations();
      getPotentialBeneficiariesForMember();
    }
  }, [props.member, props?.member?.id, plan]);

  
  useEffect(() => {
    if (storedAllocations && storedAllocations.length > 0) {
      //separating primary bennies
      let _primaryBennies = storedAllocations?.filter(
        (allocation) => allocation.isPrimary && !allocation.isDeleted
      );

      //separating contingent bennies
      let _contingentBennies = storedAllocations?.filter(
        (allocation) => !allocation.isPrimary && !allocation.isDeleted
      );
      setPrimaryBennies(_primaryBennies);
      setContingentBennies(_contingentBennies);
    }
  }, [storedAllocations]);

  //ADDING new beneficiaries when even-split button is on: overall allocations are updated w/ new bennies added, update primary and contingent bennies amount allocated evenly
  //need to run this when switch is on

  //show if even split is on or off
  const [evenSplitPrimary, setEvenSplitPrimary] = useState(true);
  const [evenSplitContingent, setEvenSplitContingent] = useState(true);

  const handleEvenSplitOnAddBeneficiary = () => {
    //primary benny reallocation
    if(primaryBennies?.length > 0 && evenSplitPrimary){
      let indexOfZeroBenny;
      let bennyWithZeroAmount = primaryBennies?.find((benny, index) => {
        indexOfZeroBenny = index;
        return benny.amount === 0
      })

      if(bennyWithZeroAmount){
        //find new even allocation amount for benny with 0 amount 
        let total = 100;
        let _evenSplitAmount = Math.floor(total / primaryBennies?.length);
        //update the rest of the bennies taking into account this new amount
        handleAllocationChange(_evenSplitAmount, bennyWithZeroAmount, indexOfZeroBenny)
      }

    }
      //contingent benny reallocation
      if(contingentBennies?.length > 0 && evenSplitContingent){
        let indexOfZeroBenny;
        let bennyWithZeroAmount = contingentBennies?.find((benny, index) => {
          indexOfZeroBenny = index;
          return benny.amount === 0
        })

        if(bennyWithZeroAmount){
          //find new even allocation amount for benny with 0 amount 
          let total = 100;
          let _evenSplitAmount = Math.floor(total / contingentBennies?.length);
          //update the rest of the bennies taking into account this new amount
          handleAllocationChange(_evenSplitAmount, bennyWithZeroAmount, indexOfZeroBenny)
        }

      }
  }

  let memberSelection =
    plan.memberOptions &&
    plan.memberOptions.find((option) => option.isSelected);
  let spouseSelection =
    plan.spouseOptions &&
    plan.spouseOptions.find((option) => option.isSelected);
  let dependentSelection =
    plan.dependentOptions &&
    plan.dependentOptions.find((option) => option.isSelected);

  //open verticle dot menu to edit or cancel coverage
  const [anchorEl, setAnchorEl] = useState({});

  const handleOpenRowMenu = (e) => {
    setAnchorEl({
      ...anchorEl,
      [e.currentTarget.value]: e.currentTarget,
    });
  };

  const handleCloseRowMenu = () => {
    setAnchorEl({});
  };

  //Open cancel Coverage drawer
  const handleOpenCancelCoverageDrawer = (e, plan, planName) => {
    props.handleOpenDrawer(plan, planName);
    handleCloseRowMenu();
}

  const [edit, setEdit] = useState();
  //EDIT STATES
  //This is bolding the payment info on the right side when edit==true
  useEffect(() => {
    if (props.setCurrentEdit) {
      let _currentEdit = { ...props.currentEdit };

      let editArray = [
        ["laddMember", edit],
        ["laddSpouse", edit],
        ["laddDependent", edit],
      ];

      editArray.forEach((edit) => {
        let key = edit[0];
        let val = edit[1];
        _currentEdit[key] = val;
      });
      props.setCurrentEdit(_currentEdit);
    }
    //reset allocation error
    setTotalAllocationError(false)
  }, [edit]);

  //AMOUNTS FOR EDIT
  const [laddAmounts, setLaddAmounts] = useState({
    memberAmt: 0,
    spouseAmt: 0,
    dependentAmt: 0,
  });
  const [isLaddAmountsUpdated, setIsLaddAmountsUpdated] = useState(false);

  useEffect(() => {
    if (plan) {
      setLaddAmounts({
        memberAmt:
          plan.memberOptions.find((option) => option.isSelected)
            ?.coverageAmount || 0,
        spouseAmt:
          plan.spouseOptions.find((option) => option.isSelected)
            ?.coverageAmount || 0,
        dependentAmt:
          plan.dependentOptions.find((option) => option.isSelected)
            ?.coverageAmount || 0,
      });
    }
  }, [plan]);

  let handleLaddAmountChange = (e, personAmt) => {
    setIsLaddAmountsUpdated(true);
    let value = e.target.value;
    setLaddAmounts({
      ...laddAmounts,
      [personAmt]: value,
    });
  };

  let saveNewAmounts = async () => {
    let memberId = props?.member.id;
    let groupPlanId = props?.groupPlanId;
    let planId = plan.planId;
    let enrollmentId = props.planEnrollmentId;
    let cancellationReasonId = 0;
    let memberCoverageAmount = laddAmounts.memberAmt;
    let spouseCoverageAmount = laddAmounts.spouseAmt;
    let dependentCoverageAmount = laddAmounts.dependentAmt;

    await enrollmentService.editLADDForMember(
      memberId,
      groupPlanId,
      planId,
      enrollmentId,
      cancellationReasonId,
      memberCoverageAmount,
      spouseCoverageAmount,
      dependentCoverageAmount
    );

    // props.getPlanCosts(props.singleEnrollment.enrollmentId)

    //update payment schedules
    props?.getPaymentSchedule()
    //reset isLaddAmountsUpdated to false
    setIsLaddAmountsUpdated(false);
  };

  //check allocation totals on blur for each input
  const checkAllocationTotals = () => {
    // check totals of primary and contingent to make sure each add up to 100 
    let _primaryTotal = primaryBennies.reduce((accumulator, currentValue) => {
      return accumulator + currentValue?.amount;
    }, 0);
    let _contingentTotal = contingentBennies.reduce((accumulator, currentValue) => {
      return accumulator + currentValue?.amount;
    }, 0)

    if(primaryBennies?.length > 0 && contingentBennies?.length > 0 && _primaryTotal === 100 && _contingentTotal === 100){
      //if both primariees and contingents exist, each must total 100
      setTotalAllocationError(false);
      //if only primaries exist, they must total 100
    }else if (primaryBennies?.length > 0 && contingentBennies?.length === 0 && _primaryTotal === 100){
      setTotalAllocationError(false);
      //if only contingents exist, they must total 100
    }else if (primaryBennies?.length === 0 && contingentBennies?.length > 0 && _contingentTotal === 100){
      setTotalAllocationError(false);
    }else{
      setTotalAllocationError({primaryTotal: _primaryTotal, contingentTotal: _contingentTotal});
    }  

  }

  const saveBeneficiaryChanges = async () => {
    //only update if benny info besides allocations was changed
    if (recentlyUpdatedBennies?.length > 0) {
      try {
        recentlyUpdatedBennies.forEach(async (benny) => {
          await enrollmentService.updateBeneficiaryForMember(
            benny.memberId,
            benny
          );
        });
      } catch (error) {
        console.error("error updating bennies", error);
      }
    }

    //  only update if allocations were changed
    if (isAllocationUpdated && !totalAllocationError) {
      await updateAllocationsInDb()  
    }

    //reset allocation update state after save
    setIsAllocationUpdated(false);
  };

  let updateSinglePlan = async () => {
    setLoading(true);
    //check permissions before updating beneficiary changes and plan amount/ coverage effective changes 
    if(props?.allowEditEnrollment){
       await saveBeneficiaryChanges();
    }
    //check permissions and that amounts have changed before updating plan amounts
    if(props?.allowModify && isLaddAmountsUpdated){
      await saveNewAmounts();
    }
    await props.getThisPlan();

    //setTimeout(() => {
    //   setEdit(false)

    //   setLoading(false)
    //},[2000])
    setEdit(false);
    setLoading(false);
  };

  const [editEffectiveDate, setEditEffectiveDate] = useState(false);
  const updateCoverageEffectiveDate = async () => {
    setLoading(true);
    // await props.updateCoverageEffectiveDate('stdPlan', stdPlan)
    // await props.getThisPlan()
    //setTimeout(() => {
    //   setEditEffectiveDate(false)
    //   setLoading(false)
    //},[2000])
    setEditEffectiveDate(false);
    setLoading(false);
  };

  //BENNY UPDATES

  const getRelationshipTypeFromId = (id) => {
    let relationshipType = props?.relationshipTypes.find(
      (type) => type.id === id
    );
    return relationshipType?.name;
  };

  //states to keep track of beneficiaries updated
  const [recentlyUpdatedBennies, setRecentlyUpdatedBennies] = useState([]); //updates to name, dob, relationship type... future use
  const [isAllocationUpdated, setIsAllocationUpdated] = useState(false); //updates to allocations

  //UPDATE NAME AND DOB - can use this to update these properties - input fields must be commented back in
  const handleBeneficiaryInputChange = (e, property, beneficiary) => {
    let val = e.target.value;

    //update primary beneficiary OR contingent beneficiary
    if (beneficiary.isPrimary) {
      setPrimaryBennies([
        ...primaryBennies.map((benny) =>
          benny.id === beneficiary.id
            ? Object.assign(benny, { [property]: val })
            : benny
        ),
      ]);
    } else {
      setContingentBennies([
        ...contingentBennies.map((benny) =>
          benny.id === beneficiary.id
            ? Object.assign(benny, { [property]: val })
            : benny
        ),
      ]);
    }

    //add updated benny to recently updated array if it doesn't exist or update it if alreay in array

    if (recentlyUpdatedBennies.some((benny) => benny.id === beneficiary.id)) {
      let updatedArray = recentlyUpdatedBennies.map((item) =>
        item.id === beneficiary.id ? { ...item, [property]: val } : item
      );

      setRecentlyUpdatedBennies(updatedArray);
    } else {
      let updatedBenny = { ...beneficiary, [property]: val };

      setRecentlyUpdatedBennies([...recentlyUpdatedBennies, updatedBenny]);
    }
  };

  //UPDATE RELATIONSHIP type for primary and contingent
  const handleRelationshipChange = (e, beneficiary) => {
    //update relationship type if it is changed
    if (beneficiary.isPrimary) {
      setPrimaryBennies([
        ...primaryBennies.map((benny) =>
          benny.id === beneficiary.id
            ? Object.assign(benny, { relationshipTypeId: e.target.value })
            : benny
        ),
      ]);
    } else {
      setContingentBennies([
        ...contingentBennies.map((benny) =>
          benny.id === beneficiary.id
            ? Object.assign(benny, { relationshipTypeId: e.target.value })
            : benny
        ),
      ]);
    }

    //add updated benny to recently updated array if it doesn't exist or update it if alreay in array

    if (recentlyUpdatedBennies.some((benny) => benny.id === beneficiary.id)) {
      let updatedArray = recentlyUpdatedBennies.map((item) =>
        item.id === beneficiary.id
          ? { ...item, relationshipTypeId: e.target.value }
          : item
      );

      setRecentlyUpdatedBennies(updatedArray);
    } else {
      let updatedBenny = { ...beneficiary, relationshipTypeId: e.target.value };

      setRecentlyUpdatedBennies([...recentlyUpdatedBennies, updatedBenny]);
    }
  };

  //UPDATE ALLOCATION
  const handleAllocationChange = (e, beneficiary, i) => {
    setIsAllocationUpdated(true);
    let total = 100;
    //check if e is a number equal to 0 (will get this from delete) othersiwse get value from e.target.value
    // let val = e === 0 ? 0 : Number(e.target.value);
    let val;
    //check if e is an event or a number and assign value accordingly
    if(e?.target?.value){
      val = Number(e.target.value);
    }else{
      val = e;
    }

    let unhandledAmount = total - val;

    let primaryBenniesRemaining = primaryBennies?.length - 1;
    let contingentBenniesRemaining = contingentBennies?.length - 1;

    // UPDATE PRIMARY beneficiary
      if (beneficiary.isPrimary) {
        // check that value is not greater than 100 or less than 0
        if (val <= 100 && val >= 0) {
          //update current primary beneficiary w/ new value
          setPrimaryBennies((prevPrimaryBennies) =>
            prevPrimaryBennies.map((benny) =>
              benny.id === beneficiary.id ? { ...benny, amount: val } : benny
            )
          );
        }
        //IF evenSplit = true, check if there are other beneficiaries to update w/ new remaining amount if unhandled amount > 0
        if(evenSplitPrimary){
          if (primaryBenniesRemaining > 0 && unhandledAmount >= 0) {
            //update other primary beneficiaries w/ new value
            let evenSplitAmount = Math.floor(
              Math.abs(unhandledAmount) / primaryBenniesRemaining
            );
            let remainder = unhandledAmount % primaryBenniesRemaining;

            //add remainder if greater than 0
            setPrimaryBennies((prePrimaryBennies) =>
              prePrimaryBennies.map((benny, index) => {
                if (index !== i) {
                  // Check if the last beneficiary needs to receive the remainder
                  if (index === primaryBenniesRemaining) {
                    return { ...benny, amount: evenSplitAmount + remainder };
                  } else {
                    return { ...benny, amount: evenSplitAmount };
                  }
                } else {
                  return benny; // Return the unchanged beneficiary
                }
              })
            );
          }
      }
    }

    //UPDATE CONTINGENT beneficiary
    if (!beneficiary.isPrimary) {
      // check that value is not greater than 100 or less than 0
      if (val <= 100 && val >= 0) {
        //update current primary beneficiary w/ new value
        setContingentBennies((prevContBennies) =>
          prevContBennies.map((benny) =>
            benny.id === beneficiary.id ? { ...benny, amount: val } : benny
          )
        );
      }
      //IF evenSplit = true, check if there are other beneficiaries to update w/ new remaining amount if unhandled amount > 0
      if(evenSplitContingent){
        if (contingentBenniesRemaining > 0 && unhandledAmount >= 0) {
          //update other primary beneficiaries w/ new value
          let evenSplitAmount = Math.floor(
            Math.abs(unhandledAmount) / contingentBenniesRemaining
          );
          let remainder = unhandledAmount % contingentBenniesRemaining;

          //add remainder if greater than 0
          setContingentBennies((prevContBennies) =>
            prevContBennies.map((benny, index) => {
              if (index !== i) {
                // Check if the last beneficiary needs to receive the remainder
                if (index === contingentBenniesRemaining) {
                  return { ...benny, amount: evenSplitAmount + remainder };
                } else {
                  return { ...benny, amount: evenSplitAmount };
                }
              } else {
                return benny; // Return the unchanged beneficiary
              }
            })
          );
        }
      }
    }
  };


  //Update allocations in db
  const updateAllocationsInDb = async () => {
    let allAllocations = [...primaryBennies, ...contingentBennies];
    // Create new benny objects to fufill data requirements for back updating allocations
    let allocationsWithBennyIds = allAllocations.map((object) => {
      const {
        id,
        memberId,
        planId,
        amount,
        isPrimary,
        isDeleted,
        lastModifiedBy,
      } = object; // Destructure 'id' from the object

      return {
        BeneficiaryId: id,
        planId,
        amount,
        isPrimary,
        isDeleted,
        memberId,
        lastModifiedBy,
      };
    });

    try {
     let results =  await enrollmentService.saveBeneficiaryAllocationForMember(
        allocationsWithBennyIds,
        props?.member.id
      );
    } catch (error) {
      console.error("error updating allocations for beneficiaries", error);
    }
  };

  //ADD NEW BENEFICIARY LOGIC
  const [addPrimaryBenny, setAddPrimaryBenny] = useState(false);
  const [addContingentBenny, setAddContingentBenny] = useState(false);
  const [newPrimaryBenny, setNewPrimaryBenny] = useState({});
  const [newContingentBenny, setNewContingentBenny] = useState({});

  const handleAddBeneficiaryInputChange = (e, property, type) => {
    let val = e.target.value;

    if (type === "primary") {
      setNewPrimaryBenny({ ...newPrimaryBenny, [property]: val });
    }

    if (type === "contingent") {
      setNewContingentBenny({ ...newContingentBenny, [property]: val });
    }
  };

  const handleAddBeneficiaryRelationshipChange = (e, type) => {
    let val = e.target.value
    if (type === "primary") {
      setNewPrimaryBenny({ ...newPrimaryBenny, relationshipTypeId: val });
    }

    if (type === "contingent") {
      setNewContingentBenny({ ...newContingentBenny, relationshipTypeId: val });
    }
  };

  const handleAddDependentAsBenny = (selection) =>{
      setNewPrimaryBenny({ ...newPrimaryBenny, firstName: selection.firstName, lastName: selection.lastName, relationshipTypeId: selection?.relationshipTypeId || "" });
  }

  const saveNewBeneficiary = async () => {
    //current total allocations before adding the new benny
    let allCurrentAllocations = [...primaryBennies, ...contingentBennies];
    let currentAllocationsWithBennyIds = allCurrentAllocations.map((object) => {
      const {
        id,
        memberId,
        planId,
        amount,
        isPrimary,
        isDeleted,
        lastModifiedBy,
      } = object; // Destructure 'id' from the object

      return {
        BeneficiaryId: id,
        planId,
        amount,
        isPrimary,
        isDeleted,
        memberId,
        lastModifiedBy,
      };
    });

    //add new primary beneficiary to db
    if (addPrimaryBenny) {
      let _newPrimaryBennyObj = {
        ...newPrimaryBenny,
        middleInitial: "",
        planId: props?.plan?.planId,
        isPrimary: true,
        isDeleted: false,
        memberId: props?.member.id,
        amount: 0,
      };

      try {
        let newBennyInDb = await enrollmentService.addBeneficiaryForMember(
          props?.member.id,
          _newPrimaryBennyObj
        );

        //if new benny added suscessfully, create new benny allocation obj and add it to current benny allocations array to update allocation in db
        if (newBennyInDb.id) {
          //if there are no primary bennies, set new benny amount to 100, otherwise set to 0
          let newBennyAmount = primaryBennies.length > 0 ? 0 : 100;
          let newBennyAllocation = {
            BeneficiaryId: newBennyInDb.id,
            planId: props?.plan?.planId,
            amount: newBennyAmount,
            isPrimary: true,
            isDeleted: false,
            memberId: newBennyInDb.memberId,
          };

          try {
            await enrollmentService.saveBeneficiaryAllocationForMember(
              [...currentAllocationsWithBennyIds, newBennyAllocation],
              props?.member.id
            );
          } catch (error) {
            console.error(
              "error updating allocations for new primary beneficiary",
              error
            );
          }
        }
      } catch (error) {
        console.error("error adding new primary beneficiary", error);
      }
    }
    //add new contingent beneficiary to db
    if (addContingentBenny) {
      let _newContingentBennyObj = {
        ...newContingentBenny,
        middleInitial: "",
        planId: props?.plan?.planId,
        isPrimary: false,
        isDeleted: false,
        memberId: props?.member.id,
        amount: 0,
      };

      try {
        let newBennyInDb = await enrollmentService.addBeneficiaryForMember(
          props?.member.id,
          _newContingentBennyObj
        );

        //if new benny added suscessfully, create new contingent benny allocation obj and add it to current benny allocations array to update allocation in db
        if (newBennyInDb.id) {
          //if there are no primary bennies, set new benny amount to 100, otherwise set to 0
          let newBennyAmount = contingentBennies.length > 0 ? 0 : 100;
          let newBennyAllocation = {
            BeneficiaryId: newBennyInDb.id,
            planId: props?.plan?.planId,
            amount: newBennyAmount,
            isPrimary: false,
            isDeleted: false,
            memberId: newBennyInDb.memberId,
          };

          try {
            await enrollmentService.saveBeneficiaryAllocationForMember(
              [...currentAllocationsWithBennyIds, newBennyAllocation],
              props?.member.id
            );
          } catch (error) {
            console.error(
              "error updating allocations for new contingent beneficiary",
              error
            );
          }
        }
      } catch (error) {
        console.error("error adding new primary beneficiary", error);
      }
    }

    getAllocations();
    //close and clear add benny form
    if (addPrimaryBenny) {
      setAddPrimaryBenny(false);
      setNewPrimaryBenny({});
    }
    if (addContingentBenny) {
      setAddContingentBenny(false);
      setNewContingentBenny({});
    }
  };

  //DELETE BENEFICIARY LOGIC

  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const [bennyToDelete, setBennyToDelete] = useState({});

  const openDeleteModal = (e, beneficiary, i) => {
    setIsDeleted(false);
    console.log("index of delete benny", i);
    setOpenConfirmDelete(true);
    setBennyToDelete({ ...beneficiary, index: i });
  };
  const closeDeleteModal = () => {
    setOpenConfirmDelete(false);
    setBennyToDelete({});
  };

  // console.log("benny to delete", bennyToDelete);

  const [isDeleted, setIsDeleted] = useState(false);
  //confirm delete benny and update db
  const handleDeleteBenny = async () => {
    console.log("benny to delete after hidding confirm", bennyToDelete);
    setIsDeleted(bennyToDelete);
    //update local state allocations w/ deleted benny allocations = 0
    handleAllocationChange(0, bennyToDelete, bennyToDelete?.index);
    //delete them from db
    try {
      console.log("deleting benny", bennyToDelete);

      await enrollmentService.deleteBeneficiaryByMemberId(
        props?.member.id,
        bennyToDelete?.id
      );
    } catch (error) {
      console.log("error deleting beneficiary", error);
    }

    getAllocations();
    closeDeleteModal();
  };

  //function to update all other allocations in db minus the recently deleted benny (called in useEffect below)
  //still struggling w/ updating to current allocations (need a page reload to see changes - but it is working!)
  const updateCurrentAllocationsAfterDelete = async () => {
    let allAllocations = [...primaryBennies, ...contingentBennies];
    console.log("all allocations in update allocations after a delete", allAllocations);
    // Create new benny objects to fufill data requirements for back updating allocations
    let allocationsWithBennyIds = allAllocations.map((object) => {
      const {
        id,
        memberId,
        planId,
        amount,
        isPrimary,
        isDeleted,
        lastModifiedBy,
      } = object; // Destructure 'id' from the object

      if (object.id !== isDeleted.id) {
        return {
          BeneficiaryId: id,
          planId,
          amount,
          isPrimary,
          isDeleted,
          memberId,
          lastModifiedBy,
        };
      }
    });

    try {
      enrollmentService.saveBeneficiaryAllocationForMember(
        allocationsWithBennyIds,
        props?.member.id
      );
    } catch (error) {
      console.error("error updating allocations for beneficiaries", error);
    }
    setIsDeleted(false);
    getAllocations();
  };
  

  //update allocations IF beneficiary is deleted
  useEffect(() => {
    if (isDeleted) {
      console.log("is deleted is true", isDeleted);

      updateCurrentAllocationsAfterDelete();
    }
  }, [isDeleted]);
  //   console.log('isDeleted value', isDeleted)

  //Cancel edit and/or add beneficiary
  const cancelEditOrAdd = () => {
    setEdit(false);
    setEditEffectiveDate(false);
    setIsAllocationUpdated(false);
    setIsLaddAmountsUpdated(false);
    setRecentlyUpdatedBennies([]);
    getAllocations();

    if (addPrimaryBenny) {
      setAddPrimaryBenny(false);
    }
    if (addContingentBenny) {
      setAddContingentBenny(false);
    }
  };

  
  return (
   <>
    <Box className="flex-row" sx={{ padding: "8px 18px", flexWrap: "wrap", position: 'relative' }}>
      {/* pop-up to be used later when implementing delete beneficiary functionality */}
      {/* <Dialog
        open={openConfirmDelete}
        onClose={closeDeleteModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Delete"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {bennyToDelete.id
              ? `Are you sure you want to delete the beneficiary ${bennyToDelete.firstName}  ${bennyToDelete.lastName}?`
              : "Error: unable to delete beneficiary at this time."}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDeleteModal}>Cancel</Button>
          {bennyToDelete?.id && (
            <Button onClick={handleDeleteBenny} autoFocus>
              Delete
            </Button>
          )}
        </DialogActions>
      </Dialog> */}

      <div className="flex-row col-12" style={{ marginBottom: "15.5px" }}>
        <div className="col-6">
          <Typography
            component={"span"}
            sx={{
              fontFamily: "Poppins",
              fontWeight: 500,
              fontSize: "20px",
              lineHeight: "32px",
              letterSpacing: ".15px",
              color: "#195FFB",
            }}
          >
            {props.planName.toUpperCase()}
          </Typography>
        </div>

        <div
          className="col-6"
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <Typography
            component={"span"}
            sx={{
              fontFamiily: "Poppins",
              fontWeight: 400,
              fontSize: "24px",
              lineHeight: "32px",
              color: "#195FFB",
            }}
          >
            {props.UsdFormatterDec.format(plan?.totalPremiumAmount || 0)}
          </Typography>
          <Typography
            component={"span"}
            sx={{
              fontFamily: "Archivo",
              fontWeight: 400,
              fontSize: "14px",
              lineHeight: "20px",
              letterSpacing: ".17px",
              color: "text.secondary",
            }}
          >
            /per month
          </Typography>
          
      
       {props?.activeCoveragePlanSummary && <>
            <IconButton
              onClick={(e) => handleOpenRowMenu(e, props?.plan)}
              value={props?.plan?.planId}
              sx={{ padding: "0px", marginLeft: "12px" }}
            >
              <MoreVertIcon
                sx={{
                  color: "text.secondary",
                  float: "right",
                }}
              />
            </IconButton>
            <Menu
              
              anchorEl={anchorEl?.[props?.plan.planId]}
              open={Boolean(anchorEl?.[props?.plan.planId])}
              onClose={handleCloseRowMenu}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              <MenuItem
                 onClick={(e) => {
                  if(props?.activePlansNotPendingCancel?.length > 1){
                     //cancel single line of coverage
                     handleOpenCancelCoverageDrawer(e, props?.plan, props?.planName);
                  }else if (props?.activePlansNotPendingCancel?.length === 1){
                     //cancel all coverage
                     props?.openCancelAllCoverageModal();
                  }
                  }}
               disabled={!props.allowModify || props?.pendingCancellation}
              >
                {/* if not pending cancel and not last active line of coverage */}
                {!props?.pendingCancellation && props?.planName && props?.activePlansNotPendingCancel?.length > 1 && `Cancel ${props?.planName.toUpperCase() } Coverage`}

                {/* if not pending cancel and is last active line of coverage */}
                {!props?.pendingCancellation && props?.planName && props?.activePlansNotPendingCancel?.length === 1 && 'Cancel All Coverage'}

                {/* if pending cancel */}
                {props?.pendingCancellation && 'Pending Cancellation'}
              </MenuItem>

              <MenuItem
                //   value={}
                onClick={() => {
                  setEdit(true);
                  //reset this to empty if starting to edit
                  setRecentlyUpdatedBennies([]);
                  handleCloseRowMenu();
                }}
                disabled={!props.allowModify}
              >
                Edit Coverage
              </MenuItem>

              <MenuItem
                //   value={}
                onClick={() => {
                  setEdit(true);
                  //reset this to empty if starting to edit
                  setRecentlyUpdatedBennies([]);
                  handleCloseRowMenu();
                }}
                disabled={!props.allowEditEnrollment}
              >
                Edit Beneficiaries
              </MenuItem>
              {/* )} */}
            </Menu>
          </>}
        </div>
      </div>

      {props.pendingCancellation && props.pendingCancellationObj && plan?.totalPremiumAmount <= 0 &&
        props.pendingCancellationWarning(props.pendingCancellationObj)
      }

      <div className="flex-row col-12" style={{justifyContent: "space-between"}}>
        <div className="col-6">
          <Typography variant="body1">Benefit Amount</Typography>
        </div>

        <div className="col-5" style={{ textAlign: "end"}}>
          {!edit && (
            <Typography variant="body1" sx={{ height: "100px" }}>
              <div>
                Member -{" "}
                {props.UsdFormatterDec.format(
                  memberSelection?.coverageAmount || 0
                )}{` (${props.UsdFormatterDec.format(memberSelection?.premiumAmount || 0)})`}
              </div>
              <div>
                Spouse -{" "}
                {memberSelection &&
                  props.UsdFormatterDec.format(
                    spouseSelection?.coverageAmount || 0
                  )}{` (${props.UsdFormatterDec.format(spouseSelection?.premiumAmount || 0)})`}
              </div>
              <div>
                Dependent(s) -{" "}
                {memberSelection &&
                  props.UsdFormatterDec.format(
                    dependentSelection?.coverageAmount || 0
                  )}{` (${props.UsdFormatterDec.format(dependentSelection?.premiumAmount || 0)})`}
              </div>
            </Typography>
          )}
          {edit && props?.allowModify && (
            <div style={{width: "100%"}}>
              <FormControl fullWidth sx={{ marginBottom: "10px" }}>
                <InputLabel id="memberBenefit">Member Benefit</InputLabel>
                <Select
                  variant="outlined"
                  size="small"
                  fullWidth
                  label="Member Benefit"
                  labelID="memberBenefit"
                  sx={componentStyles.selectDropDown}
                  IconComponent={() => (
                    <KeyboardArrowDownIcon
                      sx={{ position: "absolute", right: 7 }}
                    />
                  )}
                  value={laddAmounts.memberAmt}
                  onChange={(e) => handleLaddAmountChange(e, "memberAmt")}
                >
                  <MenuItem value={0} key={0}>
                    {props.UsdFormatterDec.format(0)}
                  </MenuItem>
                  {plan.memberOptions.map((option) => {
                    return (
                      <MenuItem
                        value={option.coverageAmount}
                        key={option.coverageAmount}
                      >
                        {props.UsdFormatterDec.format(
                          option?.coverageAmount || 0
                        )} {` (${props.UsdFormatterDec.format(option?.premiumAmount || 0)})`}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>

              <FormControl fullWidth sx={{marginBottom: "10px"}}>
              <InputLabel id="spouseBenefit">Spouse Benefit</InputLabel>
                <Select
                  variant="outlined"
                  size="small"
                  fullWidth
                  label="Spouse Benefit"
                  labelID="spouseBenefit"
                  sx={componentStyles.selectDropDown}
                  IconComponent={() => (
                    <KeyboardArrowDownIcon
                      sx={{ position: "absolute", right: 7 }}
                    />
                  )}
                  value={laddAmounts.spouseAmt}
                  onChange={(e) => handleLaddAmountChange(e, "spouseAmt")}
                >
                  <MenuItem value={0} key={0}>
                    {props.UsdFormatterDec.format(0)}
                  </MenuItem>
                  {plan.spouseOptions.map((option) => {
                    return (
                      <MenuItem
                        value={option.coverageAmount}
                        key={option.coverageAmount}
                      >
                        {props.UsdFormatterDec.format(option.coverageAmount)}{` (${props.UsdFormatterDec.format(option?.premiumAmount || 0)})`}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>

              <FormControl fullWidth sx={{marginBottom: "10px"}}>
              <InputLabel id="dependentBenefit">Dependent Benefit</InputLabel>
                <Select
                  variant="outlined"
                  size="small"
                  fullWidth
                  label="Dependent Benefit"
                  labelID="dependentBenefit"
                  sx={componentStyles.selectDropDown}
                  IconComponent={() => (
                    <KeyboardArrowDownIcon
                      sx={{ position: "absolute", right: 7 }}
                    />
                  )}
                  value={laddAmounts.dependentAmt}
                  onChange={(e) => handleLaddAmountChange(e, "dependentAmt")}
                >
                  <MenuItem value={0} key={0}>
                    {props.UsdFormatterDec.format(0)}
                  </MenuItem>
                  {plan.dependentOptions.map((option) => {
                    return (
                      <MenuItem
                        value={option.coverageAmount}
                        key={option.coverageAmount}
                      >
                        {props.UsdFormatterDec.format(option.coverageAmount)}{` (${props.UsdFormatterDec.format(option?.premiumAmount || 0)})`}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
          )}
        </div>
      </div>

      {/* PRIMARY BENEFICIARY */}

      <div className="flex-row col-12" style={{ marginBottom: "16px", justifyContent: 'space-between'}}>
        <Box className="col-6" sx={{}} >
          <Typography variant="body1">Primary Beneficiary</Typography>
          {edit && primaryBennies?.length > 0 &&   
          <FormGroup sx={{marginLeft: '10px'}}>
              <FormControlLabel
                control={
                  <Switch
                    checked={evenSplitPrimary}
                    onChange={() => setEvenSplitPrimary(!evenSplitPrimary)}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                }
                label="Evenly split allocations"
              />
          </FormGroup>}
        </Box>
        {/* {console.log('DEBUG', props.relationShipTypes)} */}
             
        {!edit && (
          <div className="col-6" style={{ textAlign: "end" }}>
            <Typography variant="body1">
              {primaryBennies.map((benny) => (
                <div key={benny.id}>
                  {benny?.amount}% {benny?.firstName} {benny?.lastName} |{" "}
                  {props.relationshipTypes &&
                    getRelationshipTypeFromId(benny?.relationshipTypeId)}
                </div>
              ))}
            </Typography>
          </div>
        )}

  
      </div>

      {/* Edit Primary Beneficiary    */}
      <Box
        sx={componentStyles.editBeneficiaryContainer}
      >
        {edit &&
          primaryBennies.map((benny, i) => (
            <Box 
              sx={componentStyles.editBeneficiaryRow}
              key={benny.id}
            >
               {/* Edit Primary Allocation */}
              <TextField
                size="small"
                sx={componentStyles.allocationInput}
                label="Allocation"
                variant="outlined"
                value={Number(benny?.amount) || 0}
                onChange={(e) => handleAllocationChange(e, benny, i)}
                onBlur={() => checkAllocationTotals()}
                InputProps={{
                  sx: { height: "40px" },
                  endAdornment: (
                    <InputAdornment
                      style={{ marginLeft: "-30px" }}
                      position="end"
                    >
                      %
                    </InputAdornment>
                  ),
                }}
                inputProps={{
                  max: "100",
                  min: "0",
                  type: "number",
                }}
                onFocus={(e) => e.target.select()}
              />

              {/* Display Primary Name/Relationship */}
              <div
                className="flex-row"
                style={{ marginLeft: "20px", width: '100%', flexWrap: 'wrap',}}
              >
                <Typography sx={{ fontWeight: "bold" }} variant="body1">
                  {benny.firstName} {benny.lastName}{" | "}
                </Typography>
             
                <Typography variant="body1" sx={{ marginLeft: "5px" }}>
                  {
                    props.relationshipTypes.find(
                      (type) => type.id === benny.relationshipTypeId
                    )?.name
                  }
                </Typography>
              </div>
              {/* input fields w/ corresponding methods to update primary beneficiary name, dob, relationship, and delete benny */}
              {/* <TextField
                size="small"
                sx={{ marginRight: "5px", flex: 1 }}
                label="First Name"
                variant="outlined"
                value={benny?.firstName || ""}
                onChange={(e) =>
                  handleBeneficiaryInputChange(e, "firstName", benny)
                }
                inputProps={{
                  "data-update": "firstNamePrimary",
                }}
              />

              <TextField
                size="small"
                sx={{ marginRight: "5px", flex: 1 }}
                label="Last Name"
                variant="outlined"
                value={benny?.lastName || ""}
                onChange={(e) =>
                  handleBeneficiaryInputChange(e, "lastName", benny)
                }
                inputProps={{
                  "data-update": "lastNamePrimary",
                }}
              />

              <TextField
                // disabled
                size="small"
                sx={{ flex: 1 }}
                label="Birthdate"
                variant="outlined"
                type={benny.dob ? "date" : ""}
                value={benny?.dob || ""}
                onChange={(e) => handleBeneficiaryInputChange(e, "dob", benny)}
                inputProps={{
                  "data-update": "dob",
                }}
              />

              <TextField
                disabled
                select
                size="small"
                sx={{ flex: 1 }}
                label="Relationship"
                variant="outlined"
                value={benny?.relationshipTypeId || ""}
                onChange={(e) => handleRelationshipChange(e, benny)}
              >
                {props?.relationshipTypes &&
                  props?.relationshipTypes.map((relationshipType) => (
                    <MenuItem
                      key={relationshipType?.id}
                      value={relationshipType?.id}
                    >
                      {relationshipType?.name}
                    </MenuItem>
                  ))}
              </TextField> */}
              {/* 
              Delete Beneficiary */}
              {/* <IconButton onClick={(e) => openDeleteModal(e, benny, i)}>
                <DeleteIcon sx={{ height: "20px" }} />
              </IconButton> */}
            </Box>
          ))}

        {/* ADD NEW PRIMARY BENEFICIARY */}
        {edit && !addPrimaryBenny && (
          <Button
            className=""
            style={{ fontWeight: 600, fontSize: "15px" }}
            onClick={() => {
              setAddPrimaryBenny((prevVal) => !prevVal);
              //close add contingent benny if open
              setAddContingentBenny(false);
            }}
          >
            + Add additional primary beneficiary
          </Button>
        )}

        {addPrimaryBenny && (
          <div
            className="col-6"
            style={{
              display: "flex",
              flexDirection: "column",
              margin: "20px 0px",
            }}
          >
            <Typography sx={{ fontWeight: 500 }}>
              {" "}
              Add additional primary beneficiary{" "}
            </Typography>
            
            {/* Potential Beneficiaries to select */}
            <div className='flex-row' style={{width: '100%', flexWrap: 'wrap', margin: "5px 0px 10px"}}>


            {potentialBeneficiaries?.length > 0 && potentialBeneficiaries?.map((potentialBen, i) => {
              //map and compare current beneficiaries to potentials by first AND last name - only return potentials that are NOT already beneficiaries and who have a first and last name
              const isCurrentBenny = primaryBennies?.find(benny => benny?.firstName?.toLowerCase() === potentialBen?.firstName?.toLowerCase() && benny?.lastName?.toLowerCase() === potentialBen?.lastName?.toLowerCase())
              if(!isCurrentBenny && potentialBen?.firstName && potentialBen?.lastName){
          
                let firstName = potentialBen?.firstName ? potentialBen?.firstName : "";
                let lastName = potentialBen?.lastName ? potentialBen?.lastName : "";
                let relationship = potentialBen?.relationshipTypeId ? getRelationshipTypeFromId(potentialBen?.relationshipTypeId) : "Dependent";
                return (
                  <IconButton sx={{margin: 0, padding: 0}} onClick={(e)=> handleAddDependentAsBenny(potentialBen)}>
                    <Chip
                      key={potentialBen?.id}
                      icon={<AddCircleOutlineIcon sx={{color: `#195ffb!important`}} />}
                      sx={componentStyles.beneficiaryChip}
                      label={`${firstName} ${lastName} | ${relationship}`}
                    />
                  </IconButton>
                )
              }
       
            })}
            </div>
           
            
            <TextField
              fullWidth
              size="small"
              label="First Name"
              variant="outlined"
              sx={{
                ...componentStyles.addBeneficiaryInputs,
              }}
              value={newPrimaryBenny?.firstName || ""}
              onChange={(e) =>
                handleAddBeneficiaryInputChange(e, "firstName", "primary")
              }
           
            />

            <TextField
              fullWidth
              size="small"
              label="Last Name"
              variant="outlined"
              sx={{
                ...componentStyles.addBeneficiaryInputs,
              }}
              value={newPrimaryBenny?.lastName || ""}
              onChange={(e) =>
                handleAddBeneficiaryInputChange(e, "lastName", "primary")
              }
              // InputProps={{
              //   sx: componentStyles.inputShape
              // }}
            />

            {/* Add a new beneficiary with birthdate in the future - uncomment and this will work w/ current method */}
            {/* <TextField
              fullWidth
              size="small"
              label="Birthdate"
              type={newPrimaryBenny?.dob ? "date" : ""}
              // type='date'
              variant="outlined"
              sx={componentStyles.addBeneficiaryInputs}
              value={newPrimaryBenny?.dob || ""}
              onChange={(e) =>
                handleAddBeneficiaryInputChange(e, "dob", "primary")
              }
            /> */}

            <TextField
              fullWidth
              size="small"
              select
              sx={{
                ...componentStyles.addBeneficiaryInputs,
                //   '& fieldset > legend': { display: 'none' },
                "&.MuiOutlinedInput-root.Mui-focused > fieldset": {
                  border: "2px solid #195ffb",
                },
              }}
              variant="outlined"
              label="Relationship"
              value={newPrimaryBenny?.relationshipTypeId || ""}
              onChange={(e) =>
                handleAddBeneficiaryRelationshipChange(e, "primary")
              }
            >
              {props.relationshipTypes &&
                props.relationshipTypes.map((relationshipType) => (
                  <MenuItem
                    key={relationshipType.id}
                    value={relationshipType.id}
                  >
                    {relationshipType.name}
                  </MenuItem>
                ))}
            </TextField>

            <div
              className="flex-row"
              style={{
                textAlign: "right",
                margin: "10px 0px",
                justifyContent: "flex-end",
              }}
            >
              <Button
                // disabled={disableSaveContingent}

                sx={{
                  height: "30px",
                  fontWeight: "500!important",
                  marginRight: "10px",
                }}
                onClick={() => {
                  setAddPrimaryBenny((prevVal) => !prevVal);
                  setNewPrimaryBenny({});
                }}
              >
                CANCEL
              </Button>
              <Button
                variant="contained"
                sx={{
                  height: "30px",
                  fontWeight: "500!important",
                }}
                onClick={saveNewBeneficiary}
              >
                ADD
              </Button>
            </div>
          </div>
        )}
      </Box>

      {/* CONTINGENT BENEFICIARY */}

      <div className="flex-row col-12" style={{ marginBottom: "16px" , justifyContent: 'space-between'}}>
        <div className="col-6">
          <Typography variant="body1">Contingent Beneficiary</Typography>
          {edit && contingentBennies?.length > 0 &&      
            <FormGroup sx={{marginLeft: '10px'}}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={evenSplitContingent}
                      onChange={() => setEvenSplitContingent(!evenSplitContingent)}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                  label="Evenly split allocations"
                />
            </FormGroup>}
        </div>

        {!edit && (
          <div className="col-6" style={{ textAlign: "end" }}>
            <Typography variant="body1">
              {contingentBennies?.length > 0 ? (
                <>
                  {contingentBennies.map((benny) => (
                    <div key={benny.id}>
                      {benny.amount}% {benny.firstName} {benny.lastName} |{" "}
                      {
                        props.relationshipTypes.find(
                          (type) => type.id === benny.relationshipTypeId
                        )?.name
                      }
                    </div>
                  ))}
                </>
              ) : (
                <>N/A</>
              )}
            </Typography>
          </div>
        )}
      </div>

      {/* Edit Contingent Beneficiary    */}
      <Box sx={componentStyles.editBeneficiaryContainer}>
        {edit &&
          contingentBennies.map((benny, i) => (
            <Box
              sx={componentStyles.editBeneficiaryRow}
              key={benny.id}
            >
              {/* Edit Contingent Allocation */}
              <TextField
                size="small"
                sx={componentStyles.allocationInput}
                label="Allocation"
                variant="outlined"
                value={Number(benny?.amount) || 0}
                onChange={(e) => handleAllocationChange(e, benny, i)}
                onBlur={() => checkAllocationTotals()}
                InputProps={{
                  sx: { height: "40px" },
                  endAdornment: (
                    <InputAdornment
                      style={{ marginLeft: "-30px" }}
                      position="end"
                    >
                      %
                    </InputAdornment>
                  ),
                }}
                inputProps={{
                  max: "100",
                  min: "0",
                  type: "number",
                }}
                onFocus={(e) => e.target.select()}
              />

              {/* Display Contingent Name/Relationship */}
              <div
                className="flex-row"
                style={{ marginLeft: "20px", width: '100%', flexWrap: 'wrap', }}
              >
                <Typography sx={{ fontWeight: "bold" }} variant="body1">
                  {benny.firstName} {benny.lastName} {" | "}
                </Typography>{" "}
                <Typography variant="body1" sx={{ marginLeft: "5px" }}>
                  {
                    props.relationshipTypes.find(
                      (type) => type.id === benny.relationshipTypeId
                    )?.name
                  }
                </Typography>
              </div>

              {/* input fields w/ corresponding methods to update contingent beneficiary name, dob, relationship, and delete benny */}
              {/* <TextField
                size="small"
                sx={{ marginRight: "5px", flex: 1 }}
                label="First Name"
                variant="outlined"
                value={benny?.firstName || ""}
                onChange={(e) =>
                  handleBeneficiaryInputChange(e, "firstName", benny)
                }
              />

              <TextField
                size="small"
                sx={{ marginRight: "5px", flex: 1 }}
                label="Last Name"
                variant="outlined"
                value={benny?.lastName || ""}
                onChange={(e) =>
                  handleBeneficiaryInputChange(e, "lastName", benny)
                }
              />

              <TextField
                // disabled
                size="small"
                sx={{ flex: 1 }}
                label="Birthdate"
                variant="outlined"
                type={benny.dob ? "date" : ""}
                value={benny?.dob || ""}
                onChange={(e) => handleBeneficiaryInputChange(e, "dob", benny)}
              />

              <TextField
                disabled
                select
                size="small"
                sx={{ flex: 1 }}
                label="Relationship"
                variant="outlined"
                value={benny?.relationshipTypeId || ""}
                onChange={(e) => handleRelationshipChange(e, benny)}
              >
                {props?.relationshipTypes &&
                  props?.relationshipTypes.map((relationshipType) => (
                    <MenuItem
                      key={relationshipType?.id}
                      value={relationshipType?.id}
                    >
                      {relationshipType?.name}
                    </MenuItem>
                  ))}
              </TextField> */}

              {/* Delete Beneficiary Button */}
              {/* <IconButton onClick={(e) => openDeleteModal(e, benny, i)}>
                <DeleteIcon sx={{ height: "20px" }} />
              </IconButton> */}
            </Box>
          ))}

        {/* ADD A NEW CONTINGENT BENEFICIARY */}

        {edit && !addContingentBenny && (
          <Button
            className=""
            style={{ fontWeight: 600, fontSize: "15px" }}
            onClick={() => {
              setAddContingentBenny((prevVal) => !prevVal);
              //close add primary benny if open
              setAddPrimaryBenny(false);
            }}
          >
            + Add additional contingent beneficiary
          </Button>
        )}

        {addContingentBenny && (
          <div
            className="col-6"
            style={{
              display: "flex",
              flexDirection: "column",
              margin: "20px 0px",
            }}
          >
            <Typography sx={{ fontWeight: 500 }}>
              {" "}
              Add additional contingent beneficiary{" "}
            </Typography>
      
            <TextField
              fullWidth
              size="small"
              label="First Name"
              variant="outlined"
              sx={{
                ...componentStyles.addBeneficiaryInputs,
              }}
              value={newContingentBenny?.firstName || ""}
              onChange={(e) =>
                handleAddBeneficiaryInputChange(e, "firstName", "contingent")
              }
            />

            <TextField
              fullWidth
              size="small"
              label="Last Name"
              variant="outlined"
              sx={{
                ...componentStyles.addBeneficiaryInputs,
              }}
              value={newContingentBenny?.lastName || ""}
              onChange={(e) =>
                handleAddBeneficiaryInputChange(e, "lastName", "contingent")
              }
            />

            {/* Add a new beneficiary with birthdate in the future - uncomment and this will work w/ current method */}
            {/* <TextField
              fullWidth
              size="small"
              label="Birthdate"
              type={newContingentBenny?.dob ? "date" : ""}
              //   type='date'
              variant="outlined"
              sx={componentStyles.addBeneficiaryInputs}
              value={newContingentBenny?.dob || ""}
              onChange={(e) =>
                handleAddBeneficiaryInputChange(e, "dob", "contingent")
              }
            /> */}

            <TextField
              fullWidth
              size="small"
              select
              sx={{
                ...componentStyles.addBeneficiaryInputs,
                //   '& fieldset > legend': { display: 'none' },
                "&.MuiOutlinedInput-root.Mui-focused > fieldset": {
                  border: "2px solid #195ffb",
                },
              }}
              variant="outlined"
              label="Relationship"
              value={newContingentBenny?.relationshipTypeId || ""}
              onChange={(e) =>
                handleAddBeneficiaryRelationshipChange(e, "contingent")
              }
            >
              {props.relationshipTypes &&
                props.relationshipTypes.map((relationshipType) => (
                  <MenuItem
                    key={relationshipType.id}
                    value={relationshipType.id}
                  >
                    {relationshipType.name}
                  </MenuItem>
                ))}
            </TextField>

            <div
              className="flex-row"
              style={{
                textAlign: "right",
                margin: "10px 0px",
                justifyContent: "flex-end",
              }}
            >
              <Button
                // disabled={disableSaveContingent}
                sx={{
                  height: "30px",
                  fontWeight: "500!important",
                  marginRight: "10px",
                }}
                onClick={() => {
                  setAddContingentBenny((prevVal) => !prevVal);
                  setNewContingentBenny({});
                }}
              >
                CANCEL
              </Button>
              <Button
                // disabled={disableSaveContingent}
                variant="contained"
                sx={{
                  height: "30px",
                  fontWeight: "500!important",
                }}
                onClick={saveNewBeneficiary}
              >
                ADD
              </Button>
            </div>
          </div>
        )}
      </Box>

      {totalAllocationError && edit &&
      <Alert sx={{position: 'absolute', bottom: '60px', right: "18px"}} severity="error">
        {primaryBennies?.length > 0 && totalAllocationError?.primaryTotal != 100 && <Typography variant='body2'>Primary allocations must total 100%.</Typography>}
        {contingentBennies?.length > 0 && totalAllocationError?.contingentTotal != 100 &&  <Typography variant='body2'>Contingent allocations must total 100%.</Typography>}
      </Alert>}

      {props.allowEditEnrollment && (
        <div className="flex-row col-12" style={{}}>
          {!edit && !editEffectiveDate && (
            <div className="col-12 flex-row">
            Effective Date: {props?.plan?.effectiveDate ? format(props?.formatDate(props?.plan?.effectiveDate), "MM/dd/yyyy") : "N/A"}
              </div>

          )}
          {(edit || editEffectiveDate) && (
            <div
              className=""
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: '10px',
              }}
            >
             
              <EditCoverageEffectiveDateStepper currentEffectiveDate={props?.plan?.effectiveDate}/>
            
              <div
                className="col-5 flex-row"
                style={{ justifyContent: "flex-end" }}
              >
                <Button
                  variant="outlined"
                  // fullWidth
                  sx={{
                    borderRadius: "8px",
                    height: "40px",
                    fontWeight: "500!important",
                    marginRight: "10px",
                  }}
                  onClick={() => {
                    cancelEditOrAdd();
                  }}
                >
                  BACK
                </Button>

                <Button
                  variant="contained"
                  // fullWidth
                  sx={{
                    borderRadius: "8px",
                    height: "40px",
                    fontWeight: "500!important",
                  }}
                  onClick={
                    edit ? updateSinglePlan : updateCoverageEffectiveDate
                  }
                  disabled={loading || addPrimaryBenny || addContingentBenny || totalAllocationError}
                >
                  {loading ? (
                    <>
                      <div style={{ width: "100%", position: "absolute" }}>
                        <InfinityLoader
                          style={{
                            zIndex: 5,
                            height: "38px",
                          }}
                        />
                      </div>
                      <span style={{opacity:0}}>SAVE CHANGES</span>
                   
                    </>
                  ) : (
                    <>
                        SAVE CHANGES
                    </>
                  )}
                </Button>
              </div>
            </div>
          )}
        </div>
      )}
    </Box>
    <hr style={{marginTop:'20px', color: "text.primary"}}/>
    </>
  );
}
