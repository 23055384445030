import React, { useEffect, useState } from "react";
import {
  PieChart,
  Pie,
  Sector,
  Cell,
  ResponsiveContainer,
  Label,
  Legend,
  Tooltip,
} from "recharts";

//Mui components
import { Card, CardHeader, Slide, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import MobileStepper from "@mui/material/MobileStepper";
import Button from "@mui/material/Button";

//Mui icons
import InfoIcon from "@mui/icons-material/Info";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";

//Services
import dashboardService from "../../../../../services/dashboard-service";

//Our Components
import InfinityLoader from "../../../../UH-loading-animation/InfinityLoader";
import ChartLegend from "../ChartLegend";

//Our Styles
import reportingDashboardStyles from "../../reportingDashboardStyles";

export default function PostEnrollmentTickets(props) {
  const [loading, setLoading] = useState(false);

  //for pie chart
  const COLORS = [
    "rgba(25, 95, 251, 1)",
    "rgba(234, 75, 63, 1)",
    "rgba(144, 111, 220, 1)",
  ];

  const [results, setResults] = useState({});
  const getReportResults = async () => {
    setLoading(true);
    try {
      let _results = await dashboardService.getPostEnrollTickets(
        props.groupPlanId
      );
      setResults(_results);
      if (_results) setLoading(false);
    } catch (error) {
      console.log("error getting PostEnrollmentTickets", error);
      if (error) setLoading(false);
    }
  };
  useEffect(() => {
    if (props.groupPlanId) {
      getReportResults();
    }
  }, [props.groupPlanId]);

  // console.log('results for PostEnrollmentTickets', results)

  //Pie chart hover label
  const [hover, setHover] = useState(null);

  let totalCount =
    results?.length > 0 ? results?.reduce((a, b) => a + b.count, 0) : 0;

  return (
    <>
      <Box sx={reportingDashboardStyles.smallReportContainer}>
        {loading && (
          <>
            <InfinityLoader
              style={reportingDashboardStyles.individualReportInfinityLoader}
            />
            <style>
              {`
                  div.lottie-div > svg {
                     height:55px!important;
                     position:absolute;
                     top:30%;
                  }
               `}
            </style>
          </>
        )}

        {totalCount > 0 ? (
          <div
            style={{
              height: "100%",
              padding: "0px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "relative",
            }}
          >
            <div>
              <PieChart
                width={220}
                height={200}
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
              >
                <Pie
                  data={results}
                  cx={110}
                  cy={100}
                  innerRadius={45}
                  outerRadius={80}
                  fill="#8884d8"
                  paddingAngle={1}
                  dataKey="count"
                  labelLine={false}
                  // display count as percentage over corresponding pie slice
                  label={
                    hover
                      ? ({ cx, cy, midAngle, value, index }) => {
                          const RADIAN = Math.PI / 180;
                          const radius = 80;
                          const x = cx + radius * Math.cos(-midAngle * RADIAN);
                          const y = cy + radius * Math.sin(-midAngle * RADIAN);

                          return (
                            <>
                              <rect
                                x={x - 20} // Adjust the x position to center the background
                                y={y - 10} // Adjust the y position to center the background
                                width={40} // Width of the background rectangle
                                height={24} // Height of the background rectangle
                                fill={
                                  results[index].count > 0
                                    ? "rgba(97,97, 97, 0.8)"
                                    : "transparent"
                                } // Background color
                                rx={4} // Border radius for rounded corners
                              />
                              <text
                                x={x}
                                y={y}
                                textAnchor={"middle"}
                                dominantBaseline="central"
                                fill={hover ? "white" : ""}
                                fontSize={12}
                                fontWeight="500"
                              >
                                {results[index].count > 0 &&
                                  Math.round(
                                    (results[index].count / totalCount) * 100
                                  ) + "%"}
                              </text>
                            </>
                          );
                        }
                      : false
                  }
                >
                  {results?.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}

                  <Tooltip />
                </Pie>
              </PieChart>
            </div>
            <div
              style={{
                minWidth: "100px",
                minHeight: "200px",
                position: "relative",
                backgroundColor: "transparent",
              }}
            >
              <ChartLegend results={results} colors={COLORS} formatNumWithCommas={props?.formatNumWithCommas}/>
            </div>
          </div>
        ) : (
          <Typography
            variant="body2"
            sx={{ textAlign: "center", padding: "20px 0px" }}
          >
            No enrollment data found for this plan.
          </Typography>
        )}
      </Box>
    </>
  );
}
