import './ExternalConfirmEmail.css';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
//Assets
import Logo from '../../../assets/images/LogoVector.svg';
import thumbsUp from '../../../assets/images/thumbsUp.png';

//MuiComponents
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

//Services
import registerService from '../../../services/register-service';
import Typography from "@mui/material/Typography";


function ExternalConfirmEmail(props) {
    const location = useLocation();
    useEffect(() => {
        
        const guid = location.pathname.replace("/confirmemail/", "");
        console.log("Making confirmEmail() call.  Guid:" + guid)
         const makeCallAsync = async (guid) => {
             return await registerService.confirmEmail(guid)
         }

        makeCallAsync(guid)
    },[])

    return (
        <React.Fragment>
            <div className="email-gray-box-container">
                <div className="email-gray-box-inner-container">
                <div className="email-thumbs-up-container">
                    <div className="email-inner-thumbs-up-container">
                            <img className="thumbsUp-img" src={thumbsUp} alt="Great success!" />
                            <div className="email-header-container">
                                <Typography component={'span'} sx={{ fontFamily: 'Poppins', fontWeight: 400, fontStyle: 'normal', fontSize: '34px', lineHeight: '123.5%', letterSpacing: '.25px', color: '#000' }}>
                                    You are all set!
                                </Typography>
                            </div>
                            <div>
                                <Typography component={'span'} sx={{ fontFamily: 'Archivo', fontWeight: 400, fontStyle: 'normal', fontSize: '16px', lineHeight: '175%', letterSpacing: '.15px', color: '#000' }}>
                                    Thank you for verifying your email with UnionHub, you can close this window now. 
                                </Typography>
                            </div>
                    </div>
                </div>
            </div>
        </div>
        </React.Fragment>
        );
}
export default ExternalConfirmEmail;