import React, { useContext, useEffect, useRef, useState } from 'react';
import { format, formatDistance, set } from 'date-fns';

//services
import memberService from '../../../../../../services/member-service';
import timelineService from '../../../../../../services/timeline-service';

// Assets

//Mui Components
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

//Mui icons
import CloseIcon from '@mui/icons-material/Close';
import { Divider, Typography } from '@mui/material';

//Our Components
import ButtonInfinityLoader from '../../../../../UH-loading-animation/ButtonInfinityLoader';

export default function EditNoteDrawer(props) {
  const [note, setNote] = useState('');
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [noteDescription, setNoteDescription] = useState(
    props?.currentNote?.description
  );
  console.log('EditNoteDrawer -> note', props);

  useEffect(() => {
    if (props?.noteDrawerOpen) {
      setNoteDescription(props?.currentNote?.description);
    }
  }, [props?.noteDrawerOpen]);

  const editNote = async () => {
    setLoading(true);
    let _note;
    try {
      _note = await memberService.updateMemberNoteDescription(
        props?.currentNote?.activityKey,
        noteDescription
      );
      props.currentNote.description = noteDescription;
      var timelineUpdated = await timelineService.updateTimeline(
        props.currentNote
      );
      props.setNoteDrawerOpen(false);
    } catch (error) {
      console.error('Error creating note', error);
      setError(true);
    }
    setLoading(false);
  };

  //Drawer Logic
  const handleDrawerClose = (e) => {
    props.setNoteDrawerOpen(false);
    setError(false);
  };

  return (
    <>
      <Drawer
        sx={{ position: 'relative' }}
        hideBackdrop={true}
        anchor="right"
        open={props.noteDrawerOpen}
        onClose={handleDrawerClose}
      >
        <Box
          sx={{
            width: '545px',
            padding: '19px 38px',
            backgroundColor: 'background.default',
            height: '100%',
          }}
        >
          <Box className="flex-row">
            <Box
              className="col-6"
              sx={{ fontFamily: 'Poppins', fontWeight: 600 }}
            >
              <Typography
                variant="h5"
                sx={{
                  fontFamily: 'Poppins',
                  fontSize: '24px',
                  fontWeight: 400,
                  color: 'text.primary',
                }}
              >
                Edit Note
              </Typography>
            </Box>

            <Box
              className="col-6"
              sx={{ display: 'flex', justifyContent: 'flex-end' }}
              role="button"
              onClick={handleDrawerClose}
            >
              <CloseIcon />
            </Box>
          </Box>
          <Divider
            sx={{
              color: 'primary.main',
              margin: '17px -38px 30px',
              opacity: 1,
              height: '2px',
            }}
          />

          <TextField
            fullWidth
            multiline
            minRows={10}
            variant="standard"
            placeholder="Write your note here"
            InputProps={{ disableUnderline: true }}
            value={noteDescription}
            onChange={(e) => setNoteDescription(e.target.value)}
            sx={{
              marginBottom: '40px',
              border: '1px solid',
              padding: '16px',
            }}
          />

          <Box
            className="flex-row"
            sx={{ justifyContent: 'center', marginBottom: '50px' }}
          >
            <Button
              variant="contained"
              disableRipple
              disabled={loading}
              sx={{
                height: 40,
                minWidth: 200,
                borderRadius: '8px',
                width: '152px',
                fontWeight: '500!important',
              }}
              onClick={editNote}
            >
              {loading ? (
                <ButtonInfinityLoader buttonTitle={'Edit Note'} />
              ) : (
                'Edit Note'
              )}
            </Button>
          </Box>

          {error && (
            <Alert sx={{ paddingRight: '20px' }} severity="error">
              <AlertTitle>Error editing note</AlertTitle>
              There was an error editing this note. Please try again and if the
              problem persists, contact your system administrator.
            </Alert>
          )}
        </Box>
      </Drawer>
    </>
  );
}
