import React, { useContext, useEffect, useState } from 'react';
// import { useHistory } from 'react-router-dom';
// import { format, formatDistance, subMinutes } from "date-fns";

//Assets
import FallbackLogo from '../../../../../../assets/images/SourceFileonly icon.png';
import airLogo from '../../../../../../assets/images/TeamstersAirLogo.png';

//services
// import ticketService from '../../../../../../services/ticket-service';

//Mui Components
import Button from '@mui/material/Button';
// import Avatar from '@mui/material/Avatar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';

//Mui icons
// import ManageSearchIcon from '@mui/icons-material/ManageSearch';
// import Check from '@mui/icons-material/Check';

//Our Components
import SignatureModal from '../../../../../app-external/external-enrollments/signature-modal/SignatureModal';
import AgentEnrollmentWrapper from './AgentEnrollmentWrapper';
import { IconButton } from '@mui/material';

//Utils
import { isAirPlan } from '../../../../../utils';

export default function MemberEnrollTab(props) {
  // State starts closed
  const [openEnrollment, setOpenEnrollment] = useState(false);
  const [modalConfig, setModalConfig] = useState('');

  const handleEnrollmentClick = (enrollmentStateId) => {
    if (enrollmentStateId == 2) {
      //Show sig modal for reopen
      setModalConfig('Reopen');
      setSigModalOpen(true);
      setEnrollmentMenu(null);
    } else if (enrollmentStateId == 3) {
      setSigModalOpen(true);
      setModalConfig('Cancel');
      setEnrollmentMenu(null);
    } else {
      setOpenEnrollment(true);
      setTabValue(4);
    }
  };

  const [sigModalOpen, setSigModalOpen] = useState(false);
  const handleReopenEnrollment = () => {};

  const [save, setSave] = useState(false);
  const handleSaveForLater = () => {
    setSave(true);
    // setOpenEnrollment(false)
    setMessage({
      ...message,
      messageDisplay: true,
      autoHideDuration: '1500',
      severity: 'success',
      backgroundColor: '',
      message: 'Enrollment Successfully Saved',
    });
  };

  const [pageComplete, setPageComplete] = useState({
    calculator: false,
    plans: false,
    beneficiaries: false,
    personalInformation: false,
    payment: false,
  });
  // console.log(props.member)

  const handlePageComplete = () => {
    if (
      props?.member?.firstName != '' &&
      props?.member?.lastName != '' &&
      props?.member?.gender > 0 &&
      props?.member?.street1 != '' &&
      props?.member?.city != '' &&
      props?.member?.state != '' &&
      props?.member?.zipCode != '' &&
      props?.member?.workStatusId != null &&
      props?.member?.jobTitle != ''
    ) {
      // console.log("Should work")
      setPageComplete({
        ...pageComplete,
        personalInformation: true,
      });
    } else {
      setPageComplete({
        ...pageComplete,
        personalInformation: false,
      });
      // console.log("Should not work")
    }
  };

  const handleDownloadPDF = async () => {
    const link = document.createElement('a');
    link.download = `/api/attachment/${props?.documents[0]?.uniqueId}`;
    link.href = `./api/attachment/${props?.documents[0]?.uniqueId}`;
    link.click();
  };

  // console.log(props)

  //check for enrollment status to return current state
  const setChip = (value) => {
    switch (value) {
      case null:
        return (
          <Chip
            sx={{
              backgroundColor: 'background.enrollmentNotStartedChip',
              color: 'primary.enrollmentNotStartedChip',
              fontWeight: '500',
              fontFamily: 'Archivo',
              fontSize: '.9rem',
            }}
            label="Not Started"
          />
        );
        break;
      case 0:
        return (
          <Chip
            sx={{
              backgroundColor: 'background.enrollmentNotStartedChip',
              color: 'primary.enrollmentNotStartedChip',
              fontWeight: '500',
              fontFamily: 'Archivo',
              fontSize: '.9rem',
            }}
            label="Not Started"
          />
        );
        break;
      case 1:
        return (
          <Chip
            sx={{
              backgroundColor: 'background.inProgressChip',
              color: 'primary.inProgressChip',
              fontWeight: '500',
              fontFamily: 'Archivo',
              fontSize: '.9rem',
            }}
            label="In Progress"
          />
        );
        break;
      case 2:
        return (
          <Chip
            sx={{
              backgroundColor: 'background.enrollmentSubmittedChip',
              color: 'primary.enrollmentSubmittedChip',
              fontWeight: '500',
              fontFamily: 'Archivo',
              fontSize: '.9rem',
            }}
            label="Submitted"
          />
        );
        break;
      case 3:
        return (
          <Chip
            sx={{
              backgroundColor: 'background.enrollmentCancelledChip',
              color: 'primary.enrollmentCancelledChip',
              fontWeight: '500',
              fontFamily: 'Archivo',
              fontSize: '.9rem',
            }}
            label="Canceled"
          />
        );
        break;
      default:
        console.warn('No matching chip found');
    }
    if (props.newEnrollment) {
      return (
        <Chip
          sx={{
            backgroundColor: '#F2F4F7',
            color: '#3D485B',
            fontWeight: '500',
            fontFamily: 'Archivo',
            fontSize: '.9rem',
          }}
          label="Not Started"
        />
      );
    }
  };

  const setButton = (value) => {
    switch (value) {
      case null:
        return (
          <Button variant="contained" onClick={() => handleEnrollmentClick()}>
            Start Enrollment
          </Button>
        );
        break;
      case 0:
        return (
          <Button variant="contained" onClick={() => handleEnrollmentClick()}>
            Start Enrollment
          </Button>
        );
        break;
      case 1:
        return (
          <Button variant="contained" onClick={() => handleEnrollmentClick()}>
            Complete Enrollment
          </Button>
        );
        break;
      case 2:
        return (
          <Button
            variant="outlined"
            sx={{ marginRight: '10px' }}
            onClick={() => handleDownloadPDF()}
            disabled={props?.documents.length < 1}
          >
            Download PDF
          </Button>
        );
        break;
      case 3:
        return <></>;
        break;
      default:
        console.warn('No matching value for Button found');
    }

    if (props.newEnrollment) {
      return (
        <Button variant="contained" onClick={handleEnrollmentClick}>
          Start Enrollment
        </Button>
      );
    }
  };

  const [enrollmentStartDate, setEnrollmentStartDate] = useState('');
  const [enrollmentEndDate, setEnrollmentEndDate] = useState('');
  const [coverageEffectiveDates, setCoverageEffectiveDates] = useState([]);

  const getEnrollmentStartDate = () => {
    if (props?.singleEnrollment?.enrollmentId == 0) {
      let date = formatDate(
        props?.singleEnrollment?.enrollmentWindowStart
      ).toLocaleDateString('en-us', {
        weekday: 'short',
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      });
      console.log(date);
      setEnrollmentStartDate(date);
    } else {
      let date = formatDate(
        props?.singleEnrollment?.openEnrollmentStart
      ).toLocaleDateString('en-us', {
        weekday: 'short',
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      });
      // console.log(props?.singleEnrollment?.openEnrollmentStart)
      setEnrollmentStartDate(date);
    }
  };

  const getEnrollmentEndDate = () => {
    if (props?.singleEnrollment?.enrollmentId == 0) {
      let date = formatDate(
        props?.singleEnrollment?.enrollmentWindowEnd
      ).toLocaleDateString('en-us', {
        weekday: 'short',
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
      });
      setEnrollmentEndDate(date);
    } else {
      let date = formatDate(
        props.singleEnrollment.openEnrollmentEnd
      ).toLocaleDateString('en-us', {
        weekday: 'short',
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
      });
      setEnrollmentEndDate(date);
    }
  };

  const getCoverageEffectiveDates = () => {
    if (props.singleEnrollment?.enrollmentId == 0) {
      let date = formatDate(
        props.singleEnrollment.coverageEffectiveDate
      ).toLocaleDateString('en-us', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      });
      setCoverageEffectiveDates(date);
    } else {
      let date = formatDate(
        props.singleEnrollment.coverageEffectiveDate
      ).toLocaleDateString('en-us', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      });
      setCoverageEffectiveDates(date);
    }
  };
  console.log('enroll tab ', props);

  const [nav, setNav] = useState({
    click: false,
    page: '',
  });

  const handleNavChange = (page) => {
    setNav({
      ...nav,
      click: true,
      page: page,
    });
  };

  useEffect(() => {
    getEnrollmentStartDate();
    getEnrollmentEndDate();
    getCoverageEffectiveDates();
    handlePageComplete();
  }, []);

  const [tabValue, setTabValue] = useState(4);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  // Error / Success Messages
  const [message, setMessage] = useState({});

  useEffect(() => {
    const timeId = setTimeout(() => {
      // After 3 seconds set the show value to false
      setMessage({
        ...message,
        messageDisplay: false,
        autoHideDuration: '',
        severity: '',
        backgroundColor: '',
        message: '',
      });
    }, 5000);

    return () => {
      clearTimeout(timeId);
    };
  }, [message.messageDisplay]);

  const handleMessageClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setMessage({
      ...message,
      messageDisplay: false,
      autoHideDuration: '',
      severity: '',
      backgroundColor: '',
      message: '',
    });
  };

  const [runningTotal, setRunningTotal] = useState(0);

  const [enrollmentMenu, setEnrollmentMenu] = React.useState(null);
  const open = Boolean(enrollmentMenu);
  const handleEnrollmentMenu = (event) => {
    setEnrollmentMenu(event.currentTarget);
  };
  const handleClose = () => {
    setEnrollmentMenu(null);
  };

  const handleSendEmailClick = () => {
    console.log('send the email');
    setEnrollmentMenu(null);
  };

  const handleLogoError = (e) => {
    e.target.src = FallbackLogo;
  };

  const formatDate = (rawDate) => {
    let [yyyy, mm, dd, hh, m, s] = rawDate?.split(/[^\d]+/);
    let date = new Date();
    date.setUTCFullYear(+yyyy);
    date.setUTCDate(+dd);
    date.setUTCMonth(mm - 1);
    date.setUTCHours(+hh);
    date.setUTCMinutes(+m + date.getTimezoneOffset());
    date.setUTCSeconds(+s);
    return date;
  };

  return (
    <Box
      className="member-router-container"
      style={{ height: 'calc(100vh - 105px)', position: 'relative' }}
      sx={{
        bgcolor: 'background.background',
        color: 'text.primary',
        flexWrap: 'wrap',
      }}
    >
      <Paper
        elevation={0}
        sx={{
          border: '0px solid',
          borderRadius: '0px',
          borderColor: 'background.border',
          backgroundColor: 'background.default',
          width: '100%',
          minHeight: '200px',
          height: openEnrollment ? '-webkit-fill-available' : 'auto',
          padding: '20px',
        }}
      >
        <div className="row">
          {/* {!openEnrollment ? */}
          {/* <>
                     <div className="col-2" style={{}}>
                        <img src={props?.member && `api/image/organization/${props.member?.organizationId}`} style={{ objectFit:'contain', maxWidth:'100%' }} onError={handleLogoError}/>

                     </div>
                     <div className="col-10" style={{ margin: 'auto 0' }}>
                        <div className="flex-row" style={{ marginBottom: '20px', justifyContent: 'space-between' }} >
                           <Typography variant="subtitle">{props?.singleEnrollment?.name}</Typography>
                           <IconButton onClick={handleEnrollmentMenu}><MoreVertIcon /></IconButton>
                           <Menu
                              id="enrollment-menu"
                              anchorEl={enrollmentMenu}
                              open={open}
                              onClose={handleClose}
                              anchorOrigin={{
                                 vertical: 'top',
                                 horizontal: 'left',
                              }}
                              transformOrigin={{
                                 vertical: 'top',
                                 horizontal: 'left',
                              }}
                           >
                              <MenuItem disabled={true} onClick={handleSendEmailClick}>Send Enrollment Invitation Email</MenuItem>
                              <MenuItem disabled={true} onClick={handleSendEmailClick}>Send Enrollment Confirmation Email</MenuItem>
                              <MenuItem disabled={props?.singleEnrollment?.enrollmentStateId != 2} onClick={() => handleEnrollmentClick(2)} >Re-Open Enrollment</MenuItem>
                              <MenuItem disabled={props?.singleEnrollment?.enrollmentStateId != 2} onClick={() => handleEnrollmentClick(3)} >Cancel Enrollment</MenuItem>
                           </Menu>

                        </div>
                        <div className="flex-row" style={{ marginBottom: '20px' }}>
                           <Typography variant="body1"><span style={{ fontWeight: 600 }}>Open Enrollment Window:</span><br /> {enrollmentStartDate} - {enrollmentEndDate}</Typography>
                        </div>
                        <div className="flex-row" >
                           {setChip(props?.singleEnrollment?.enrollmentStateId)}
                        </div>
                     </div>

                     <div className="flex-row" style={{ justifyContent: 'flex-end' }}>
                        {setButton(props?.singleEnrollment?.enrollmentStateId)}
                     </div>

                  </> */}
          {/* : */}
          <>
            <div className="col-12 flex-row" style={{ height: '131px' }}>
              <div
                className="col-10"
                style={{
                  marginBottom: '20px',
                }}
              >
                <Typography variant="h4" sx={{ marginBottom: '6px' }}>
                  {/* check for IBT air plan and display title */}
                  {props?.singleEnrollment?.name ? (
                    isAirPlan(
                      props?.singleEnrollment,
                      props?.surveyResponses
                    ) ? (
                      'Teamsters Voluntary Income Protection VIP+ Air'
                    ) : (
                      props?.singleEnrollment?.name || 'Enrollment'
                    )
                  ) : (
                    <></>
                  )}{' '}
                  {setChip(
                    props?.singleEnrollment?.enrollmentStateId ||
                      props?.singleEnrollment?.enrollmentState
                  )}
                </Typography>
                {/* <div style={{ marginLeft: '20px' }}>
                              {setChip(props?.singleEnrollment?.enrollmentStateId)}
                           </div> */}
                {/* <br/> */}

                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography variant="body1" sx={{ color: 'text.lightGray' }}>
                    <div style={{ fontWeight: 700, marginBottom: '10px' }}>
                      Open Enrollment Window:
                    </div>
                    {enrollmentStartDate} - {enrollmentEndDate}
                  </Typography>

                  <Typography
                    variant="body1"
                    component={'div'}
                    sx={{ color: 'text.lightGray', alignContent: 'flex-end' }}
                  >
                    Coverage effective {coverageEffectiveDates}
                  </Typography>
                </Box>
              </div>

              {/* <div className="flex-row" style={{ marginBottom: '20px' }}>
                           <Typography variant="body1"><span style={{ fontWeight: 600 }}>Open Enrollment Window:</span><br /> {enrollmentStartDate} - {enrollmentEndDate}</Typography>
                        </div> */}

              <div
                className="col-2"
                style={{ display: 'flex', justifyContent: 'center' }}
              >
                {/* check for IBT air plan and display logo */}
                <img
                  src={
                    isAirPlan(props?.singleEnrollment, props?.surveyResponses)
                      ? airLogo
                      : props.member?.organizationId > 0
                      ? `api/image/organization/${props.member?.organizationId}`
                      : '/api/image/organization'
                  }
                  style={{
                    height: '100%',
                    maxWidth: '100%',
                    objectFit: 'contain',
                  }}
                  alt="union logo"
                  onError={handleLogoError}
                />
              </div>
            </div>
            <Divider
              sx={{
                width: '99%',
                marginLeft: '1px',
                marginBottom: '0px',
                color: 'background.border',
                opacity: 0.5,
              }}
            />

            <div
              className="col"
              style={{
                borderRight: '1px solid rgba(224, 224, 224, 1)',
                paddingRight: '0px',
                paddingLeft: '0px',
                marginLeft: '-8px',
                marginBottom: '-20px',
                position: 'relative',
                maxWidth: '240px',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  marginTop: '20px',
                }}
              >
                <Tabs
                  orientation="vertical"
                  variant="scrollable"
                  value={tabValue}
                  onChange={handleTabChange}
                  aria-label="Vertical tabs example"
                  sx={{
                    width: '100%',
                    '& button': {
                      paddingLeft: '0px',
                    },
                  }}
                >
                  <Tab
                    value={4}
                    onClick={(e) => {
                      handleNavChange('calculator');
                    }}
                    label={tabValue == 4 ? 'CALCULATOR >' : 'CALCULATOR'}
                  />
                  <Tab
                    value={0}
                    onClick={(e) => {
                      handleNavChange('plans');
                    }}
                    label={tabValue == 0 ? 'PLANS >' : 'PLANS'}
                  />
                  <Tab
                    value={1}
                    onClick={(e) => {
                      handleNavChange('beneficiaries');
                    }}
                    label={tabValue == 1 ? 'BENEFICIARIES >' : 'BENEFICIARIES'}
                  />
                  <Tab
                    value={2}
                    onClick={(e) => {
                      handleNavChange('personalInfo');
                    }}
                    label={
                      tabValue == 2
                        ? 'PERSONAL INFORMATION >'
                        : 'PERSONAL INFORMATION'
                    }
                  />
                  <Tab
                    value={3}
                    onClick={(e) => {
                      handleNavChange('payment');
                    }}
                    label={tabValue == 3 ? 'PAYMENT >' : 'PAYMENT'}
                  />
                </Tabs>
              </div>
              <div
                className="flex-row"
                style={{
                  position: 'absolute',
                  bottom: '0',
                  marginBottom: '40px',
                  flexWrap: 'wrap',
                  textAlign: 'center',
                  justifyContent: 'center',
                }}
              >
                <Typography
                  className="col-12"
                  variant="body1"
                  sx={{ fontSize: '12px', fontWeight: 500 }}
                >
                  MONTHLY TOTAL:&nbsp;
                  <span
                    style={{
                      color: '#195ffb',
                      fontWeight: 700,
                      fontSize: '14px',
                    }}
                  >
                    {props.UsdFormatterDec.format(runningTotal || 0)}
                  </span>
                </Typography>
                <br />
                <Button variant="outlined" onClick={handleSaveForLater}>
                  SAVE FOR LATER
                </Button>
              </div>
            </div>
          </>

          {/* } */}

          {/* {openEnrollment && */}
          <AgentEnrollmentWrapper
            nav={nav}
            setNav={setNav}
            setTabValue={setTabValue}
            member={props.member}
            getThisMember={props.getThisMember}
            save={save}
            setSave={setSave}
            setOpenEnrollment={setOpenEnrollment}
            singleEnrollment={props.singleEnrollment}
            setSelectedEnrollment={props.setSelectedEnrollment}
            // updateSingleEnrollment={props.updateSingleEnrollment} //This isn't passed down any further from AgentEnrollmentWrapper
            getEnrollments={props.getEnrollments}
            getOneEnrollment={props.getOneEnrollment}
            setRunningTotal={setRunningTotal}
            permissionsEnabler={props.permissionsEnabler}
            setActivePage={props.setActivePage}
            getPaymentSchedule={props?.getPaymentSchedule}
            currentActiveCoverage={props?.currentActiveCoverage}
            getActiveCoverage={props?.getActiveCoverage}
          />
          {/* } */}
        </div>
      </Paper>

      {/* <SignatureModal agentEnroller={true} setAgentEnrollmentPageState={props.setAgentEnrollmentPageState} fullMemberObj={props.member} guid={props.guid} setGuid={props.setGuid} results={props.singleEnrollment} open={sigModalOpen} handleClose={() => setSigModalOpen(false)} handleOpen={() => setSigModalOpen(false)} setEnrollmentSelected={props.setEnrollmentSelected} signClick={modalConfig}
            setOpenEnrollment={setOpenEnrollment} setTabValue={setTabValue} enrollmentId={props?.singleEnrollment?.enrollmentId} setSigModalOpen={setSigModalOpen}
            // displayPay={displayPay}
            refreshEnrollments={props.getEnrollments} 
            refreshEnrollment={props.getOneEnrollment}/> */}

      <Snackbar
        open={message.messageDisplay}
        autoHideDuration={message.autoHideDuration}
        onClose={handleMessageClose}
        sx={{ opacity: 0.8 }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          // onClose={handleClose}
          severity={message.severity}
          sx={{ width: '100%', backgroundColor: message.backgroundColor }}
        >
          {message.message}
        </MuiAlert>
      </Snackbar>
    </Box>
  );
}
