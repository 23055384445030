import React, { useState } from 'react';

//MUI Components
import { AccordionSummary, Collapse } from '@mui/material';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';

//MUI Icons
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';

export default function SupportCollapse(props) {
  const [expanded, setExpanded] = useState(false);
  const handleExpandSection = () => {
    setExpanded(!expanded);
  };

  let expandIcon = expanded ? (
    <AddIcon
      fontSize="medium"
      sx={{
        //  position: "absolute",
        //  right: "14%",
        //  top: "20%",
        color: '#195ffb',
        //   backgroundColor: '#195ffb',
        borderRadius: '100px',
        //   boxShadow: '-1px 1px 4px 0px #00000040',
        transform: 'rotate(45deg)',
      }}
    />
  ) : (
    <AddIcon
      fontSize="medium"
      sx={{
        //  position: "absolute",
        //  right: "14%",
        //  top: "20%",
        color: '#0000008A',
        borderRadius: '100px',
        //   boxShadow: '-1px 1px 4px 0px #00000040',
      }}
    />
  );
  return (
    <>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            // minHeight: "57px",
            // position: 'relative',
            alignItems: 'center',
            padding: '16px',
            fontFamily: 'Archivo',
            fontSize: '16px',
            lineHeight: '24px',
            color: expanded ? 'primary.main' : 'text.primary',
            width: '100%',
            height: '72px',
            justifyContent: 'space-between',
            backgroundColor: '#195FFB0A',
          }}
          onClick={handleExpandSection}
        >
          <Box
            style={{
              cursor: 'pointer',
              //   position: 'relative',
            }}
            onClick={handleExpandSection}
          >
            {props.title}
            {/* bigger clickable area */}
          </Box>

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'end',
              width: ' 18%',
              height: '100%',
              cursor: 'pointer',
              alignItems: 'center',
            }}
            onClick={handleExpandSection}
          >
            {expandIcon}
          </Box>
        </Box>

        <Collapse in={expanded}>
          <Box
            sx={{
              backgroundColor: 'white',
              padding: '16px',
              paddingTop: '8px',
              fontSize: '14px',
              fontFamily: 'archivo',
              lineHeight: '20px',
              letterSpacing: '0.17px',
              color: 'text.secondary',
            }}
          >
            {props.content}
          </Box>

          <Box
            sx={{
              backgroundColor: 'white',
              padding: '16px',
              paddingTop: '8px',
              fontSize: '14px',
              fontFamily: 'archivo',
              lineHeight: '20px',
              letterSpacing: '0.17px',
              color: 'text.secondary',
            }}
          >
            <Link>{props.contact}</Link>
          </Box>
        </Collapse>
        <Divider
          sx={{
            // width: 'calc(100% + 32px)',
            // marginBottom: '16px',
            //  marginTop: '16px',
            // marginLeft: '-16px',
            color: 'background.border',
            opacity: 0.5,
          }}
        />
    </>
  );
}
